import React, { Component } from 'react';
import { connect } from 'react-redux';
import FormGroup from '@material-ui/core/FormGroup';
import Grid from '@material-ui/core/Grid';
import Input from "../UI/Input/Input";
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
    handlePasswordtextField,
    changeUserPassword
} from "../../actions/ChangePassword";

class ChangePassword extends Component {
    render() {
        let {
            changePasswordForm,
            changepasswordLoaderStatus
        } = this.props.changePasswordState;
        const changePasswordFormElementArray = [];
        for (let key in changePasswordForm) {
            changePasswordFormElementArray.push({
                id: key,
                config: changePasswordForm[key]
            })
        }
        let changePasswordform = (
            <FormGroup row>
                <Grid container spacing={24}>
                    {
                        changePasswordFormElementArray.map(formElement => (
                            <Grid item xs={12} sm={12} key={formElement.id}>
                                <Input
                                    key={formElement.id}
                                    name={formElement.config.elementConfig.label}
                                    elementType={formElement.config.elementType}
                                    elementConfig={formElement.config.elementConfig}
                                    value={formElement.config.value}
                                    menuItems={formElement.config.menuItems}
                                    error={formElement.config.errorStatus}
                                    helperText={formElement.config.errorMessage}
                                    changed={(event) => this.props.handlePasswordtextField(formElement.id, event)}
                                    maxLength={formElement.config.maxLength}
                                />
                            </Grid>
                        )
                        )
                    }
                </Grid>
                <Grid
                    container
                    justify="flex-end"
                    spacing={24}
                    className="pt-2"
                >
                    <Grid item className="d-flex justify-content-end">
                        {
                            <div className="d-flex flex-row pt-4 pb-2">
                                {
                                    changepasswordLoaderStatus ? <CircularProgress color="primary" /> :
                                        <span>

                                            <Button
                                                variant="contained"
                                                color="primary"
                                                style={{ marginRight: "1rem" }}
                                                onClick={() => this.props.changeUserPassword(changePasswordForm)}
                                            >
                                                Change Password
                                        </Button>

                                            <Button
                                                variant="contained"
                                                onClick={() => window.location.href = "/"}
                                            >
                                                Cancel
                                        </Button>


                                        </span>
                                }
                            </div>
                        }
                    </Grid>
                </Grid>
            </FormGroup>
        )
        return (
            <div>
                <Grid container spacing={24}>

                    <Grid item xs={12} sm={12}>
                        <Card className="pl-3 pr-3 pt-3">
                            <div>
                                <Grid container spacing={24} className="pt-2">
                                    <Grid item xs={10}>
                                        <CardHeader title="Change Password" />
                                    </Grid>
                                </Grid>
                            </div>
                            <CardContent>
                                <FormGroup row>
                                    <Grid container spacing={24}>
                                        {changePasswordform}
                                    </Grid>
                                </FormGroup>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        changePasswordState: state.changePassword
    }
}

const mapDispatchToProps = dispatch => {
    return {
        handlePasswordtextField: (formElementId, event) => {
            return dispatch(handlePasswordtextField(formElementId, event))
        },
        changeUserPassword: (changePasswordForm) => {
            return dispatch(changeUserPassword(changePasswordForm))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);