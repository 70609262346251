import React, { Component } from 'react';
import MaterialTable from 'material-table';
import {connect} from 'react-redux';
import {withRouter} from "react-router";

import SGPaperContainer from '../../../components/UI/PaperContainer/PaperContainer';
import SGLoader from "../../../components/UI/Loader/SGLoader";
import {
    getTemplates,
    updateTemplate
} from "../../../actions/Checklist";
class ListChecklist extends Component{
    componentDidMount(){
        this.props.getTemplates()
    }
    test = () => {
    }
    render(){
        let {
            templateColumn,
            templateInformations,
            getTemplateLoaderStatus
        } = this.props.checkListState;
        let{
            menuItems
        } = this.props.sidebarState;
        return(
            <React.Fragment>
                <SGPaperContainer
                    title = "Template List"
                    buttons = {[
                        {
                            name : "Add Template",
                            icon : "add_circle_outline",
                            color : "primary",
                            action : () => this.props.history.push(menuItems['business_checklist'].submenu['template_create'].urlpattern)
                        }
                    ]}
                >
                    {
                        getTemplateLoaderStatus ?
                        <SGLoader /> :
                        <MaterialTable
                            columns={templateColumn}
                            data={templateInformations}
                            title=""
                            editable={{
                                onRowUpdate: (newData, oldData) =>
                                    new Promise((resolve, reject) => {
                                        resolve(this.props.updateTemplate(newData, templateInformations, menuItems['business_checklist'].submenu['template_list'].urlpattern))
                                    }),
                            }}
                            actions={[
                                {
                                    icon: 'call_made',
                                    tooltip: 'Checkitems',
                                    onClick: (event, rowData) => {
                                        localStorage.setItem('template_id', rowData.template_id);
                                        let url = menuItems['business_checklist'].submenu['list_checklist_items'].urlpattern;
                                        if(url.indexOf(":tempid") >= 0){
                                            url = url.replace(":tempid",rowData.template_id);
                                        }
                                        this.props.history.push(url);
                                    }
                                },
                            ]}
                        />
                    }

                </SGPaperContainer>
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => {
    return{
        checkListState : state.checkList,
        sidebarState : state.sidebarState,
    }
}

const mapDispatchToProps = dispatch => {
    return{
        getTemplates : () => {
            return dispatch(getTemplates())
        },
        updateTemplate : (templateInfo, templateInformations, url) => {
            return dispatch(updateTemplate(templateInfo, templateInformations, url))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ListChecklist));
