import React, { Component } from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import AddDistributor from './AddDistributor';
import Contact from './Contact';
import Billing from './Billing';

import { connect } from 'react-redux';

import {
  addDistributorTabHandleChange
} from "../../../../actions/Distributor";
const styles = theme => ({
    active_tabStyle:{
    color: 'white',
    fontWeight : 'bold',
    backgroundColor: '#3b3c3c'
   }
  });
  function TabContainer(props) {
    return (
      <Typography component="div" style={{ padding: 8 * 3 }}>
        {props.children}
      </Typography>
    );
  }

  TabContainer.propTypes = {
    children: PropTypes.node.isRequired,
  };
class AddDistributorAdmin extends Component{
      render() {

        const { classes } = this.props;
        let {
          addDistributorTabValue,
          addDistributorTabDisableStatus,
          distributorAdminEditStatus
        } = this.props.distributorState;

        let editDistributorSearchFoundStatus = (window.location.href).search('editdistributor');
        let editProfileSearchFoundStatus = (window.location.href).search('editprofile');
        return (
          <div className = "container">
            <AppBar position="static">
              <Tabs
                value={addDistributorTabValue}
                onChange={this.props.addDistributorTabHandleChange}
                variant="fullWidth"
              >
                <Tab
                  label={editDistributorSearchFoundStatus != -1 ? "Edit Reseller" : editProfileSearchFoundStatus != -1 ? "Edit Profile" : "Add Reseller"}
                  className = {addDistributorTabValue === 0 ? classes.active_tabStyle :null}
                />
                <Tab
                  label="Contacts"
                  disabled = {addDistributorTabDisableStatus}
                  className = {addDistributorTabValue === 1 ? classes.active_tabStyle :null}
                />
                {/*<Tab */}
                {/*  label="Billing" */}
                {/*  disabled = {addDistributorTabDisableStatus}*/}
                {/*  className = {addDistributorTabValue === 2 ? classes.active_tabStyle :null} */}
                {/*/>*/}
              </Tabs>
            </AppBar>
            {
                addDistributorTabValue === 0 && <AddDistributor />
            }
            {
                addDistributorTabValue === 1 && <Contact />
            }
            {
                addDistributorTabValue === 2 && <Billing />
            }
          </div>
        );
      }
}
AddDistributor.propTypes = {
    classes: PropTypes.object.isRequired,
  };
const mapStateToProps = state => {
  return{
    distributorState : state.distributor
  }
}

const mapDispatchToprops = dispatch => {
  return{
    addDistributorTabHandleChange : (event, value) => {
      return dispatch(addDistributorTabHandleChange(event, value))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToprops)(withStyles(styles)(AddDistributorAdmin));
