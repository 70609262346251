import axios from '../axios';
import { GET_PARTNERS_FAIL, GET_PARTNERS_SUCCESS, SAVE_PARTNER_FAIL, SAVE_PARTNER_SUCCESS } from './ActionType';
import { history } from "../index";
import { toast } from 'react-toastify';

export const getPartners = () => {

  return (dispatch) => {
    return axios.get('/sg/partners')
      .then(resp => {
        dispatch(getPartnersSuccess(resp.data.data));
      })
      .catch(error => {
        toast.error(error.response.data.message, {
          containerId: 'toastMsg',
        });
        dispatch(getPartnersFail());
      });
  }
}

export const getPartnersSuccess = (partners) => ({
  type: GET_PARTNERS_SUCCESS,
  partners
});

export const getPartnersFail = (partners) => ({
  type: GET_PARTNERS_FAIL,
  partners
});


export const savePartner = (partnerObj) => {
  return (dispatch) => {
    return axios.post('/sg/partners', partnerObj)
      .then(resp => {
        history.goBack();
        toast.success("Partner created succesfully", {
          containerId: 'toastMsg',
        });
        dispatch(savePartnerSuccess())
      })
      .catch((error) => {
        toast.error(error.response.data.message, {
          containerId: 'toastMsg',
        });
        dispatch(savePartnerFail())
      });
  }
}

export const savePartnerSuccess = () => {
  return { type: SAVE_PARTNER_SUCCESS };
}

export const savePartnerFail = () => {
  return { type: SAVE_PARTNER_FAIL };
}

export const updatePartner = (partnerObj, partnerId) => {
  
  return (dispatch) => {
    const url = `/sg/partners/${partnerId}`
    return axios.put(url, partnerObj)
      .then(resp => {
        history.goBack();
        toast.success("Partner updated succesfully", {
          containerId: 'toastMsg',
        });
        dispatch(savePartnerSuccess());
        dispatch(getPartners());
      })
      .catch((error) => {
        toast.error(error.response.data.message, {
          containerId: 'toastMsg',
        });
        dispatch(savePartnerFail())
      });
  }
}


