import React, { Component } from 'react'
import MaterialTable from 'material-table'
import { connect } from "react-redux";
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import { toast } from 'react-toastify';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  editFatigueRule,
  getFatigueRuleList,
} from '../../actions/FatigueRule';

class ListFatigueRule extends Component {
  componentDidMount(){
    this.props.getFatigueRuleList();
}
  render() {
    let { fatigueListcols, fatigueListrows } = this.props.fatigueRule;
    return (
       <div style = {{marginTop : '2rem', marginBottom : "2rem"}} className = "container">
      <Paper>
        <div className = "pt-1 pl-3">
            <span className = "d-flex">
              <div className = "mr-auto" style = {{paddingTop : "0"}}>
                  <h4 style = {{marginTop : "1rem", textTransform : "uppercase"}}>Fatigue Rule Group List</h4>
              </div>
              <div className = "d-flex flex-wrap align-content-center">
                  <Button 
                      variant="contained" 
                      color="primary"
                      onClick = {() => this.props.history.push("/sgadminportal/fatiguerules/newfatiguerule")}
                      className = "mr-2"
                  >
                      <i className="material-icons" style = {{paddingRight : "0.5rem"}}>
                          add_circle_outline
                      </i>
                      Add Fatigue Rule Group
                  </Button>
              </div>
            </span>
          </div>
      {fatigueListrows.length == 0 ? 
        <div style = {{height : "20rem"}} className = "d-flex justify-content-center align-items-center">
            <CircularProgress color = "primary" />
        </div>:
          <MaterialTable
            columns={fatigueListcols}
            data={fatigueListrows}
            title=""
            editable={{
              onRowUpdate: (newData, oldData) =>
                new Promise((resolve, reject) => {
                  if(newData.description !== ''){
                    resolve(this.props.editFatigueRule(newData, fatigueListrows));
                  }else{
                    toast.error("Description field should not be blank", {containerId: 'toastMsg'});
                    reject();
                  }
                  
                }),
            }}
            options={{ actionsColumnIndex: -1}}
          />
        }
        </Paper>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    fatigueRule: state.fatigueRule
  };
};
const mapDispatchToProps = dispatch => {
  return{
    getFatigueRuleList : () => {
          return dispatch(getFatigueRuleList())
      },
    editFatigueRule : (newData, fatigueListrows) => {
        return dispatch(editFatigueRule(newData, fatigueListrows))
      }
  }
}


export default connect(mapStateToProps,mapDispatchToProps)(ListFatigueRule);