import React from 'react';
import ReactDOM from 'react-dom';
import {
	Router,
	Route
} from 'react-router-dom';
import { createStore, applyMiddleware, compose } from 'redux';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import reducer from './reducers';
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
import { createMuiTheme } from '@material-ui/core/styles';
import { ToastContainer, toast } from 'react-toastify';
import axios from './axios';

import Header from "./containers/Header";
import Appbody from "./containers/Appbody";
import Login from "./containers/Login";
import Confirmation from "./containers/Confirmation";
import FirstTimeLoginChangePassword from "./containers/Login";
import TermNCondtion from "./components/termncondition"
import PrivacyPolicy from "./components/privacypolicy"
import Help from "./components/help"
import DriverSignup from "./components/Signup/DriverSignup"

import './styles/common.css';
import './styles/animate.css';
import 'react-toastify/dist/ReactToastify.css';
import {createBrowserHistory} from 'history';
import NCReport from "./containers/NCReport/NCReport";

export const history = createBrowserHistory();

let store = createStore(reducer, compose(applyMiddleware(thunk)))
 //let store = createStore(reducer, compose(applyMiddleware(thunk),window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()),)

let themeColor = null;
if(localStorage.getItem('role') !== '1'){
	themeColor = localStorage.themeColor?JSON.parse(localStorage.themeColor):null;
}

var theme = createMuiTheme({
	typography: {
		// In Japanese the characters are usually larger.
		fontSize: 13,
		useNextVariants: true,
	},
	palette: {
		primary: {
			main: themeColor?themeColor.buttoncolor:'#656464',
			dark: themeColor?themeColor.buttonhovercolor:'#3b3c3c',
			contrastText: '#fff'
		},

		secondary: {
			main: themeColor?themeColor.bannercolor:'#3b3c3c',
			dark: '#3b3c3c',
			contrastText: '#fff'
		},
	},
})

class SiteMap extends React.Component {
	constructor(props) {
		super(props);
		this.state={
			themeColor:null
		}
	}

	componentDidMount = () => {
		if(localStorage.getItem('distributor_id')){
			if(localStorage.getItem('role') !== '1'){
				this.getDistributorTheme();
			}
		}
	}

	getDistributorTheme  = () => {
		axios.get('distributor/' + localStorage.distributor_id + '/themes')
		.then(response => {
			if(response.data.data)
			theme = createMuiTheme({
				palette: {
					primary: {
						main: response.data.data.distributor_themes.button_color?response.data.data.distributor_themes.button_color:'#3b3c3c',
						dark: response.data.data.distributor_themes.button_hover_color?response.data.data.distributor_themes.button_hover_color:'#3b3c3c',
						contrastText: '#fff'
					},
					secondary: {
						main: response.data.data.distributor_themes.banner_color?response.data.data.distributor_themes.banner_color:'#3b3c3c',
						dark: '#3b3c3c',
						contrastText: '#fff'
					},
				},
				useNextVariants: true,
			})
			if(response.data.data !== null){
				this.setState({themeColor:{
					bannercolor:response.data.data.distributor_themes.banner_color,
					buttoncolor:response.data.data.distributor_themes.button_color,
					buttonhovercolor:response.data.data.distributor_themes.button_hover_color
				}},()=>{localStorage.setItem('themeColor',JSON.stringify(this.state.themeColor))})
			}
		})
	}

	appRootRoutes = [
		'/',
		'/login',
		'/404',
		'/termncondtion',
		'/privacypolicy',
		'/privacypolicy',
		'/help',
		'/driversignup',
		'/ncreport'
	];

	render() {
		return (
			<div>
				<ToastContainer
					enableMultiContainer
					containerId={'toastMsg'}
					position={toast.POSITION.TOP_RIGHT}
					autoClose={2000}
				/>
				<Provider store={store}>
					<Router history={history} >
						<MuiThemeProvider theme={theme}>
							<Route exact path="/" component={Login} />
							<Route exact path="/login" component={Login} />
							<Route exact path="/confirmation/:id" component={Confirmation} />
							<Route exact path="/change-password" component={FirstTimeLoginChangePassword} />
							<Route path="/reset-password" component={FirstTimeLoginChangePassword} />
							<Route path="/termncondtion" component={TermNCondtion} />
							<Route path="/privacypolicy" component={PrivacyPolicy}/>
							<Route path="/help" component={Help}/>
							<Route path="/driversignup" component={DriverSignup} />
							<Route exact path="/ncreport" component={NCReport}></Route>
							{
								!this.appRootRoutes.includes(window.location.pathname) &&
								((window.location.pathname).split('/'))[1] != 'confirmation' &&
								((window.location.pathname).split('/'))[1] != 'reset-password' &&
								((window.location.pathname).split('/'))[1] != 'change-password' ?
								<div>
									<Header themeColor={this.state.themeColor} />
									<Appbody />
								</div> : null

							}
						</MuiThemeProvider>
					</Router>

				</Provider>
			</div>

		)
	}
}
ReactDOM.render(
	<SiteMap />,
	document.getElementById('root')
)
