import React, { Component } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import FormGroup from '@material-ui/core/FormGroup';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import Input from "../../../../components/UI/Input/Input";
import SGLoader from "../../../../components/UI/Loader/SGLoader";
import {
    handleTextFieldChange,
    addDistributorNext,
    onBlurTextfield,
    initializeFormFields,
    handleEditDistributorDialogClose,
    updateDistributor,
    getDistributorInfoById,
} from '../../../../actions/Distributor';

class AddDistributor extends Component {
    componentDidMount() {
        let editDistributorSearchFoundStatus = (window.location.href).search('editdistributor');
        let editProfileSearchFoundStatus = (window.location.href).search('editprofile');
        if (!this.props.distributorState.distributorAdminEditStatus && this.props.distributorState.addDistributorTabDisableStatus) {
            this.props.initializeFormFields();
        }
        if (editDistributorSearchFoundStatus != -1 || editProfileSearchFoundStatus != -1) {
            this.props.getDistributorInfoById()
        }
    }
    render() {
        let {
            addDistributorAdminForm,
            addDistributorLogoBase64,
            editDistributorDialogOpenStatus,
            getDistributorAdminByIdLoaderStatus,
            myob_account_number
        } = this.props.distributorState;

        let editDistributorSearchFoundStatus = (window.location.href).search('editdistributor');
        let editProfileSearchFoundStatus = (window.location.href).search('editprofile');
        const formElementArray = [];
        for (let key in addDistributorAdminForm) {
            formElementArray.push({
                id: key,
                config: addDistributorAdminForm[key]
            })
        }
        const myob_account_num = myob_account_number;
        let form = (
            <FormGroup row>
                <Grid container spacing={24}>
                    {
                        formElementArray.map(formElement => {

                            // if(editDistributorDialogOpenStatus){
                            if (formElement.id !== 'distributor_email' && formElement.id !== 'abn') {

                                return (
                                    <Grid item xs={12} sm={6} key={formElement.id}>
                                        <Input
                                            key={formElement.id}
                                            name={formElement.config.elementConfig.label}
                                            elementType={formElement.config.elementType}
                                            elementConfig={formElement.config.elementConfig}
                                            value={formElement.config.value}
                                            menuItems={formElement.config.menuItems}
                                            error={formElement.config.errorStatus}
                                            helperText={formElement.config.errorMessage}
                                            changed={(event) => this.props.handleTextFieldChange(formElement.id, event)}
                                            touched={(event) => this.props.handleTextFieldChange(formElement.id, event)}
                                            maxLength={formElement.config.maxLength}
                                        />
                                    </Grid>
                                )
                            }
                            // }
                            else {

                                return (
                                    <Grid item xs={12} sm={6} key={formElement.id}>
                                        <Input
                                            key={formElement.id}
                                            name={formElement.config.elementConfig.label}
                                            elementType={formElement.config.elementType}
                                            elementConfig={formElement.config.elementConfig}
                                            value={formElement.config.value}
                                            menuItems={formElement.config.menuItems}
                                            error={formElement.config.errorStatus}
                                            helperText={formElement.config.errorMessage}
                                            changed={(event) => this.props.handleTextFieldChange(formElement.id, event)}
                                            touched={(event) => this.props.handleTextFieldChange(formElement.id, event)}
                                            maxLength={formElement.config.maxLength}
                                            disabledStatus={(editProfileSearchFoundStatus != -1 || editDistributorSearchFoundStatus != -1) ? true : false}
                                        />
                                    </Grid>
                                )
                            }
                        }
                        )
                    }
                </Grid>
                <Grid
                    container
                    spacing={24}
                    className="pt-2"
                    direction="row"
                    justify="flex-end"
                    alignItems="center"
                >
                    <Grid item className="d-flex justify-content-end">
                        <Button
                            variant="contained"
                            color="primary"
                            disabled={false}
                            onClick={() => { this.props.addDistributorNext(addDistributorAdminForm, this.props, addDistributorLogoBase64, (editDistributorSearchFoundStatus != -1 || editProfileSearchFoundStatus != -1)) }}
                        >
                            Next
                        </Button>
                    </Grid>
                </Grid>
            </FormGroup>
        )

        return (
            <Card className="pl-3 pr-3 pt-3 mb-3">
                <Grid container spacing={24} className="pt-2">
                    <Grid item xs={12} sm={10}>
                        <CardHeader title={editDistributorSearchFoundStatus != -1 ? "Edit Reseller" : editProfileSearchFoundStatus != -1 ? "Edit Profile" : "Add Reseller"} />
                        {editDistributorSearchFoundStatus != -1 ? 
                        <span style={{ textAlign: "center", marginLeft: 17, paddingTop: 25 }}>MyOb Account Number:
                        <input style={{marginLeft: 15, textAlign: "center"}} value={myob_account_num} disabled /></span> :
                         null}
                    </Grid>
                </Grid>
                <CardContent>
                    {
                        getDistributorAdminByIdLoaderStatus ? <SGLoader /> : form
                    }
                </CardContent>
            </Card>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        distributorState: state.distributor
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getDistributorInfoById: () => {
            return dispatch(getDistributorInfoById())
        },
        handleTextFieldChange: (textField, event) => {
            return dispatch(handleTextFieldChange(textField, event))
        },
        addDistributorNext: (addDistributorAdminForm, props, addDistributorLogoBase64, editDistributor) => {
            return dispatch(addDistributorNext(addDistributorAdminForm, props, addDistributorLogoBase64, editDistributor))
        },
        onBlurTextfield: (textField, event) => {
            return dispatch(onBlurTextfield(textField, event))
        },
        initializeFormFields: () => {
            return dispatch(initializeFormFields())
        },
        handleEditDistributorDialogClose: () => {
            return dispatch(handleEditDistributorDialogClose())
        },
        updateDistributor: (distributorIdForEdit, addDistributorAdminForm, addDistributorLogoBase64, distributorInformations) => {
            return dispatch(updateDistributor(distributorIdForEdit, addDistributorAdminForm, addDistributorLogoBase64, distributorInformations))
        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AddDistributor));