import React, { Component } from 'react';

class TermNCondition extends Component{
    render(){
        return(
            <div style = {{height : "400px", overflow : "auto"}} className = "pt-3 pb-2">
                <ol>
                    <li>
                        <p>
                            These Terms and Conditions ("Agreement") governs the use of the services ("Service") that are made available by Website.com Solutions Inc. 
                            ("Website.com", "we" or "us"). These Terms and Conditions represent the whole agreement and understanding between Website.com and the individual 
                            or entity who subscribes to our service ("Subscriber" or "you").
                        </p>
                    </li>
                    <li>
                        <p>
                            All Website.com web hosting accounts will display a "Coming Soon" web page by default when activated. 
                            This page informs visitors that the Subscriber has recently setup their account with Website.com. 
                            The "Coming Soon" default index web page may be removed by the Subscriber at any time once they have access to the web server.
                        </p>
                    </li>
                    <li>
                        <p>
                            Website.com provides Subscribers with a non-exclusive, non-transferable, limited license to use the Website Builder. 
                            The Website Builder is developed and owned by Website.com, and any reproduction, modification, 
                            creation of derivative works from or redistribution of the Website Builder is expressly prohibited. 
                            The Website Builder, its structure, sequence, organization and source code are considered trade secrets 
                            ebsite.com and are protected by trade secret laws.
                        </p>
                    </li>
                    <li>
                        <p>
                            Website.com's email service provides customers with the capability to send and receive email via the Internet. Website.com retains the right, at our sole discretion, 
                            to restrict the volume of messages transmitted or received by you in order to maintain the quality of our 
                            email services to other Subscribers and to protect our computer systems
                        </p>
                    </li>
                </ol>
            </div>
        )
    }
}
export default TermNCondition;