import React, { Component } from "react";
import AppBar from "@material-ui/core/AppBar";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import SGLogo from '../../images/sg_logo.png';
import Grid from '@material-ui/core/Grid';
import Tooltip from "@material-ui/core/Tooltip";
import smartEdriverLogo from "../../images/smart_edriver_logo.png"
import yourLogo from "../../images/your-logo-here.png"
import axios from '../../axios';

function arrowGenerator(color) {
  return {
    '&[x-placement*="bottom"] $arrow': {
      top: 0,
      left: 0,
      marginTop: "-0.95em",
      width: "3em",
      height: "1em",
      "&::before": {
        borderWidth: "0 1em 1em 1em",
        borderColor: `transparent transparent ${color} transparent`
      }
    },
    '&[x-placement*="top"] $arrow': {
      bottom: 0,
      left: 0,
      marginBottom: "-0.95em",
      width: "3em",
      height: "1em",
      "&::before": {
        borderWidth: "1em 1em 0 1em",
        borderColor: `${color} transparent transparent transparent`
      }
    },
    '&[x-placement*="right"] $arrow': {
      left: 0,
      marginLeft: "-0.95em",
      height: "3em",
      width: "1em",
      "&::before": {
        borderWidth: "1em 1em 1em 0",
        borderColor: `transparent ${color} transparent transparent`
      }
    },
    '&[x-placement*="left"] $arrow': {
      right: 0,
      marginRight: "-0.95em",
      height: "3em",
      width: "1em",
      "&::before": {
        borderWidth: "1em 0 1em 1em",
        borderColor: `transparent transparent transparent ${color}`
      }
    }
  };
}

const styles = theme => ({
  button: {
    margin: theme.spacing.unit
  },
  lightTooltip: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11
  },
  arrowPopper: arrowGenerator(theme.palette.grey[700]),
  arrow: {
    position: "absolute",
    fontSize: 6,
    width: "3em",
    height: "3em",
    "&::before": {
      content: '""',
      margin: "auto",
      display: "block",
      width: 0,
      height: 0,
      borderStyle: "solid"
    }
  },
  bootstrapPopper: arrowGenerator(theme.palette.common.black),
  bootstrapTooltip: {
    backgroundColor: theme.palette.common.black
  },
  bootstrapPlacementLeft: {
    margin: "0 8px"
  },
  bootstrapPlacementRight: {
    margin: "0 8px"
  },
  bootstrapPlacementTop: {
    margin: "8px 0"
  },
  bootstrapPlacementBottom: {
    margin: "8px 0"
  },
  htmlPopper: arrowGenerator("#dadde9"),
  htmlTooltip: {
    backgroundColor: "#fff",
    color: "rgba(0, 0, 0, 0.87)",
    minWidth: 300,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
    "& b": {
      fontWeight: theme.typography.fontWeightMedium
    }
  },
  root: {
    display: "flex"
  },
  appBar: {
    marginLeft: drawerWidth,
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`
    }
  },
  menuButton: {
    marginRight: 20,
    [theme.breakpoints.up("sm")]: {
      display: "none"
    }
  }
});

const drawerWidth = 270;
class Appbar extends Component {
  state = {
    mobileOpen: false,
    anchorEl: null,
    arrowRef: null,
    businessProfile: null,
    distributorProfile: null,
    sgProfile: {
      logo: SGLogo,
      id: "SG-0001",
      name: "SG Admin",
      address: "Unit 7, 444 Warrigal Road,Heatherton VIC 3202,AUSTRALIA",
      phone: "+61 3 9551 7334",
      email: "support@stepglobal.com"
    }
  };

  componentDidMount = () => {
    if(localStorage.business_id){
      this.getBusinessProfile();
    }
    if(localStorage.distributor_id){
      this.getDistributorProfile();
    }

  }

  getDistributorProfile = () => {
    axios.get('distributor/' + localStorage.distributor_id + '/logo')
      .then(response => {
        if (response.data && response.data.data && response.data.data.document) {
          this.setState({
            distributorProfile: {
              logo: response.data.data.document?response.data.data.document:yourLogo,
              id: response.data.data.distributorObject.distributor_id,
              name: response.data.data.distributorObject.distributor_name,
              address: response.data.data.distributorObject.distributor_address,
              phone: response.data.data.distributorObject.distributor_support_contactnumber,
              email: response.data.data.distributorObject.email
            }
          })
        }
      }).catch(() => {})
  }

  getBusinessProfile = () => {
    axios.get('businesses/' + localStorage.business_id + '/logo')
      .then(response => {
        this.setState({
          businessProfile: {
            logo: response.data.data.document?response.data.data.document:yourLogo,
            id: response.data.data.businessObject.business_id,
            name: response.data.data.businessObject.business_name,
            address: response.data.data.businessObject.business_address,
            phone: response.data.data.businessObject.business_support_contactnumber,
            email: response.data.data.businessObject.email
          }
        })
      })
      .catch(error => {
      })
  }

  handleArrowRef1 = node => {
    this.setState({
      arrowRef1: node
    });
  }

  handleArrowRef2 = node => {
    this.setState({
      arrowRef2: node
    });
  }

  handleDrawerToggle = () => {
    this.setState(state => ({ mobileOpen: !state.mobileOpen }));
  }

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  }

  handleClose = () => {
    this.setState({ anchorEl: null });
  }

  handleLogout = () => {
    this.setState({ anchorEl: null });
    window.location.href = "/";
  }

  render() {
    const { classes, theme } = this.props;
    const { anchorEl } = this.state;
    let rightProfile, leftProfile
    if ([1].includes(Number(localStorage.role))) {
      rightProfile = null
      leftProfile = this.state.sgProfile
    } else if ([2, 3, 4].includes(Number(localStorage.role))) {
      leftProfile = this.state.distributorProfile
      rightProfile = this.state.sgProfile
    } else if ([5, 6, 7].includes(Number(localStorage.role))) {
      leftProfile = this.state.businessProfile
      rightProfile = this.state.distributorProfile
    }
    return (
      <AppBar position="fixed" color='secondary' className={classes.appBar}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="Open drawer"
            onClick={this.props.handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
            spacing={24}
          >
            <Grid item xs={4}>
              <Grid container>
                {leftProfile &&
                  <Tooltip
                    classes={{
                      popper: classes.htmlPopper,
                      tooltip: classes.htmlTooltip
                    }}
                    PopperProps={{
                      popperOptions: {
                        modifiers: {
                          arrow: {
                            enabled: Boolean(this.state.arrowRef1),
                            element: this.state.arrowRef1
                          }
                        }
                      }
                    }}
                    title={
                      <React.Fragment>
                        <Grid container style={{padding:5}}>
                          <Grid item xs={3}>
                            <span><b>Id : </b></span>
                          </Grid>
                          <Grid item xs={9}>
                            <span>{leftProfile.id}</span>
                          </Grid>
                          <Grid item xs={3}>
                            <span><b>Name : </b></span>
                          </Grid>
                          <Grid item xs={9}>
                            <span>{leftProfile.name}</span>
                          </Grid>
                          <Grid item xs={3}>
                            <span><b>E-Mail : </b></span>
                          </Grid>
                          <Grid item xs={9}>
                            <span>{leftProfile.email}</span>
                          </Grid>
                          <Grid item xs={3}>
                            <span><b>Address : </b></span>
                          </Grid>
                          <Grid item xs={9}>
                            <span>{leftProfile.address}</span>
                          </Grid>
                          <Grid item xs={3}>
                            <span><b>Contact : </b></span>
                          </Grid>
                          <Grid item xs={9}>
                            <span>{leftProfile.phone}</span>
                          </Grid>
                        </Grid>
                        <span className={classes.arrow} ref={this.handleArrowRef2} />
                      </React.Fragment>
                    }
                  >
                    <img style={{ height: 50, float: "right" }} src={leftProfile.logo} />
                  </Tooltip>
                }
                <Grid item xs={4}>
                </Grid>
                <Grid item xs={4}>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <Grid container>
                <Grid item xs={3}>
                </Grid>
                <Grid item xs={9}>
                  <div className="edriverText">
                    <a href = "/help" target = "_blank">
                    <img src={smartEdriverLogo} />
                    </a>
                  </div>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <Grid container>
                <Grid item xs={4}>
                </Grid>
                <Grid item xs={4}>
                </Grid>
                <Grid item xs={4}>
                  {rightProfile &&
                    <Tooltip
                      classes={{
                        popper: classes.htmlPopper,
                        tooltip: classes.htmlTooltip
                      }}
                      PopperProps={{
                        popperOptions: {
                          modifiers: {
                            arrow: {
                              enabled: Boolean(this.state.arrowRef2),
                              element: this.state.arrowRef2
                            }
                          }
                        }
                      }}
                      title={
                        <React.Fragment>
                          <Grid container style={{padding:5}}>
                            <Grid item xs={3}>
                              <span><b>Id : </b></span>
                            </Grid>
                            <Grid item xs={9}>
                              <span>{rightProfile.id}</span>
                            </Grid>
                            <Grid item xs={3}>
                              <span><b>Name : </b></span>
                            </Grid>
                            <Grid item xs={9}>
                              <span>{rightProfile.name}</span>
                            </Grid>
                            <Grid item xs={3}>
                              <span><b>E-Mail : </b></span>
                            </Grid>
                            <Grid item xs={9}>
                              <span>{rightProfile.email}</span>
                            </Grid>
                            <Grid item xs={3}>
                              <span><b>Address : </b></span>
                            </Grid>
                            <Grid item xs={9}>
                              <span>{rightProfile.address}</span>
                            </Grid>
                            <Grid item xs={3}>
                              <span><b>Contact : </b></span>
                            </Grid>
                            <Grid item xs={9}>
                              <span>{rightProfile.phone}</span>
                            </Grid>
                          </Grid>
                          <span className={classes.arrow} ref={this.handleArrowRef2} />
                        </React.Fragment>
                      }
                    >
                      <img style={{ height: 50, float: "right" }} src={rightProfile.logo} />
                    </Tooltip>
                  }
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    );
  }
}

Appbar.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles, { withTheme: true })(Appbar);
