import {
    DISTRIBUTOR_TEXTFIELD_CHANGE,
    SUBMIT_DISTRIBUTOR_INFO,
    UPDATE_DISTRIBUTOR_INFO,
    SET_DEFAULT_VALUE_TO_ALL_FORM_FIELD,
    SET_DISTRIBUTOR_LIST,
    SET_DISTRIBUTOR_USER_INPUT_FIELD,
    SET_SUBMITTED_DISTRIBUTOR_USER,
    SET_DEFAULT_VALUE_TO_ADD_DISTRIBUTOR_USER,
    SET_DISTRIBUTOR_USER_LIST,
    SET_UPDATED_DISTRIBUTOR_USER,
    SET_INPUTHANDLER_UPLOAD_DISTRIBUTOR,
    POST_DISTRIBUTOR_UPLOADED_DATA,
    SET_DEFAULT_VALUE_TO_DISTRIBUTOR_UPLOAD_FORM,
    POST_HANDLER_EDIT_DISTRIBUTOR_DIALOG_OPEN,
    POST_HANDLER_EDIT_DISTRIBUTOR_DIALOG_CLOSE,
    POST_ADD_DISTRIBUTOR_TAB_HANDLE_CHANGE,
    POST_ADD_ADD_DISTRIBUTOR_CONTACT_INFO,
    POST_UPDATE_DISTRIBUTOR_CONTACT_INFO,
    POST_DELETE_ADD_DISTRIBUTOR_CONTACT_INFO,
    POST_ADD_DISTRIBUTOR_CONTACT_NEXT,
    POST_HANDLE_ADD_DISTRIBUTOR_BILLING_FORM_FIELD,
    POST_ADD_DISTRIBUTOR_ADMIN_ON_SUBMIT,
    POST_ADD_DISTRIBUTOR_CONTACT_BACK,
    POST_ADD_DISTRIBUTOR_ADMIN_BILLING_BACK,
    POST_ADD_DISTRIBUTOR_ADMIN_ON_SUBMIT_AFTER_CLOSE_TOASTR,
    POST_GET_DISTRIBUTOR_INFO_BY_ID,
    POST_ADD_DISTRIBUTOR_ADMIN_ON_UPDATE,
    POST_NAVIGATOR_TO_ADD_DISTRIBUTOR,
    POST_NAVIGATE_TO_EDIT_DISTRIBUTOR_ADMIN
} from './ActionType';
import { toast } from 'react-toastify';
import axios from '../axios';
import { convertCSVToJSONUser } from "./Common";
import {removeFakePath, convertToBase64, getFileExtension, getActulaURL} from "../utils/Helper";
import { history } from '../index';


export const addNewDistributor = (addDistributorReqParams) => {
        
    return (dispatch, getState) => {
        let successUrl = '/';
        const state = getState()
        if (state.sidebarState.menuItems) {
            successUrl = state.sidebarState.menuItems['distributor_list']?.urlpattern || '/'
        }
        
        const partnerId = localStorage.getItem('partner_id');
        let reqURL = `/partners/${partnerId}/distributors`;
    
        return axios.post(reqURL, addDistributorReqParams).then((resp) => {
            toast.success("Reseller Created Successfully", {
                containerId: 'toastMsg',
                onClose : () => {
                    dispatch(postAddDistributorAdminOnSubmitAfterCloseToastr());
                    history.push(successUrl);
                }
            });
        }).catch(error => {
            if(error.response.status == 403){
                localStorage.clear();
                toast.error("Session Expired! Please login again", {containerId: 'toastMsg'});
                window.location.href = "/";
            } else {
                toast.error(error.response.data.message, {containerId: 'toastMsg'});
            }
        });
    }
}

export const submitUpdateDistributor = (addDistributorReqParams) => {
    
    return (dispatch, getState) => {
        let successUrl = '/';
        const state = getState();
        if (state.sidebarState.menuItems) {
            successUrl = state.sidebarState.menuItems['distributor_list']?.urlpattern;
            if (!successUrl){
                // If here then have just edited profile as Dist admin
                successUrl = state.sidebarState.menuItems['my_profile']?.urlpattern;
            }
        }

        let reqURL = "/dist/"+localStorage.getItem('distributor_id');
    
        return axios.put(reqURL, addDistributorReqParams)
            .then(response => {
                toast.success("Reseller Updated Successfully", {
                    containerId: 'toastMsg',
                    onClose : () => {
                        dispatch(postAddDistributorAdminOnSubmitAfterCloseToastr());
                        history.push(successUrl);
                    }
                });
            })
            .catch(error => {
                if(error && error.response && error.response.status == 403){
                    localStorage.clear();
                    toast.error("Session Expired! Please login again", {containerId: 'toastMsg'});
                    window.location.href = "/";
                } else {
                    toast.error(error.response.data.message, {containerId: 'toastMsg'});
                }
            })
    }
}



export const navigateToEditDistributorAdmin = (url) => {
    return (dispatch) => {
        url = getActulaURL(url);
        history.push(url);
        return dispatch(postNavigateToEditDistributorAdmin())
    }
}
export const postNavigateToEditDistributorAdmin = () => {
    return{
        type : POST_NAVIGATE_TO_EDIT_DISTRIBUTOR_ADMIN
    }
}
export const navigateToAddDistributor = (url) => {
    return (dispatch) => {
        url = getActulaURL(url);
        history.push(url);
        return dispatch(postNavigateToAddDistributor())
    }
}
export const postNavigateToAddDistributor = () => {
    return{
        type : POST_NAVIGATOR_TO_ADD_DISTRIBUTOR
    }
}
export const addDistributorAdminOnUpdate = (billingForm, addDistributorReqParams, props, url = '/sgadminportal/distributors',editProfileSearchFoundStatus) => {
    let billingFieldsValidationStatus = true;
    Object.keys(billingForm).map((textField) => {
        if(
            billingForm[textField].value  == '' &&
            (
                textField !== 'address_line' &&
                textField !== 'city' &&
                textField !== 'country' &&
                textField !== 'company_name' &&
                textField !== 'currency' &&
                textField !== 'email' &&
                textField !== 'phone' &&
                textField !== 'state' &&
                textField !== 'postalcode'
            )){
                billingFieldsValidationStatus = false;
                billingForm[textField].errorStatus = true;
            }
    })
    if(billingForm['email'].value != ''){
        if(!(billingForm['email'].value).match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)){
            billingForm['email'].errorStatus = true;
            billingFieldsValidationStatus = false;
            toast.error("The email is invalid", {containerId: 'toastMsg'});
        }
    }

    return (dispatch) => {
        if (billingFieldsValidationStatus) {
            let billingFormReqParams = {};
            let addDistributorAdminLoaderStatus = true;
            billingFormReqParams['billing_address'] = billingForm['address_line'].value;
            billingFormReqParams['billing_city'] = billingForm['city'].value;
            billingFormReqParams['billing_country'] = billingForm['country'].value;
            // SMED-1354 billingFormReqParams['billing_company_name'] = billingForm['company_name'].value;
            billingFormReqParams['billing_company_name'] = addDistributorReqParams["distributorname"];
            billingFormReqParams['billing_currency'] = billingForm['currency'].value;
            billingFormReqParams['billing_email'] = billingForm['email'].value;
            billingFormReqParams['billing_phone'] = billingForm['phone'].value;
            billingFormReqParams['billing_state'] = billingForm['state'].value;
            billingFormReqParams['billing_postalcode'] = billingForm['postalcode'].value;
            addDistributorReqParams['distributor_billing_data'] = billingFormReqParams;
            let reqURL;

            const distributorId = localStorage.getItem('distributor_id');
            if (editProfileSearchFoundStatus != -1){
                reqURL = `/dist/${distributorId}`;
            }
            else {
                const partnerId = localStorage.getItem('partner_id');
                reqURL = `/partners/${partnerId}/distributors/${distributorId}`;
            }


            dispatch(postAddDistributorAdminOnUpdate(addDistributorAdminLoaderStatus));
            return axios.put(reqURL, addDistributorReqParams)
            .then(response => {
                addDistributorAdminLoaderStatus = false;
                toast.success("Reseller Updated Successfully", {
                    containerId: 'toastMsg',
                    onClose : () => {
                        props.history.push(editProfileSearchFoundStatus!=-1?'/distributorportal/'+localStorage.getItem('distributor_id')+'/profile':'/sgadminportal/distributors');
                        // if(url === '/sgadminportal/distributors'){
                            dispatch(postAddDistributorAdminOnSubmitAfterCloseToastr())
                        // }
                    }
                });
                dispatch(postAddDistributorAdminOnUpdate(addDistributorAdminLoaderStatus));
            })
            .catch(error => {
                addDistributorAdminLoaderStatus = false;
                if(error && error.response && error.response.status == 403){
                    localStorage.clear();
                    toast.error("Session Expired ! Please login again", {containerId: 'toastMsg'});
                    window.location.href = "/";
                }else{
                    toast.error(error.response.data.message, {containerId: 'toastMsg'});
                    dispatch(postAddDistributorAdminOnUpdate(addDistributorAdminLoaderStatus));
                }

            })
        }
    }
}
export const postAddDistributorAdminOnUpdate = (addDistributorAdminLoaderStatus) => {
    return{
        type : POST_ADD_DISTRIBUTOR_ADMIN_ON_UPDATE,
        addDistributorAdminLoaderStatus : addDistributorAdminLoaderStatus
    }
}
export const getDistributorInfoById = () => {
    return (dispatch) => {
        let reqURL = "/sg/distributors/"+localStorage.getItem('distributor_id');
        let getDistributorAdminByIdLoaderStatus = true;
        let distributorInfo = {};
        dispatch(postGetDistributorInfoById(distributorInfo, getDistributorAdminByIdLoaderStatus));
        return axios.get(reqURL)
        .then(response => {
            getDistributorAdminByIdLoaderStatus = false;
            distributorInfo = response.data.data;
            dispatch(postGetDistributorInfoById(distributorInfo, getDistributorAdminByIdLoaderStatus));
        })
        .catch(error => {
            getDistributorAdminByIdLoaderStatus = false;
            dispatch(postGetDistributorInfoById(distributorInfo, getDistributorAdminByIdLoaderStatus));
        })
    }
}

export const postGetDistributorInfoById = (distributorInfo, getDistributorAdminByIdLoaderStatus) => {
    return{
        type : POST_GET_DISTRIBUTOR_INFO_BY_ID,
        distributorInfo : distributorInfo,
        getDistributorAdminByIdLoaderStatus : getDistributorAdminByIdLoaderStatus
    }
}

export const addDistributorAdminBillingBack = () => {
    return (dispatch) => {
        dispatch(postAddDistributorAdminBillingBack())
    }
}

export const postAddDistributorAdminBillingBack = () => {
    return{
        type : POST_ADD_DISTRIBUTOR_ADMIN_BILLING_BACK
    }
}
export const addDistributorContactBack = () => {
    return (dispatch) => {
        return dispatch(postAddDistributorContactBack())
    }
}
export const postAddDistributorContactBack = () => {
    return{
        type : POST_ADD_DISTRIBUTOR_CONTACT_BACK
    }
}
export const addDistributorAdminOnSubmit = (billingForm, addDistributorReqParams, props) => {
    let billingFieldsValidationStatus = true;
    Object.keys(billingForm).map((textField) => {
        if(
            billingForm[textField].value  == '' &&
            (
                textField !== 'address_line' &&
                textField !== 'city' &&
                textField !== 'country' &&
                textField !== 'company_name' &&
                textField !== 'currency' &&
                textField !== 'email' &&
                textField !== 'phone' &&
                textField !== 'state' &&
                textField !== 'postalcode'
            )){
                billingFieldsValidationStatus = false;
                billingForm[textField].errorStatus = true;
            }
    })
    if(billingForm['email'].value != ''){
        if(!(billingForm['email'].value).match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)){
            billingForm['email'].errorStatus = true;
            billingFieldsValidationStatus = false;
            toast.error("The email is invalid", {containerId: 'toastMsg'});
        }
    }

    return (dispatch) => {
        if (billingFieldsValidationStatus) {
            let billingFormReqParams = {};
            let addDistributorAdminLoaderStatus = true;
            billingFormReqParams['billing_address'] = billingForm['address_line'].value;
            billingFormReqParams['billing_city'] = billingForm['city'].value;
            billingFormReqParams['billing_country'] = billingForm['country'].value;
            // SMED-1354 billingFormReqParams['billing_company_name'] = billingForm['company_name'].value;
            billingFormReqParams['billing_company_name'] = addDistributorReqParams["distributorname"]
            billingFormReqParams['billing_currency'] = billingForm['currency'].value;
            billingFormReqParams['billing_email'] = billingForm['email'].value;
            billingFormReqParams['billing_phone'] = billingForm['phone'].value;
            billingFormReqParams['billing_state'] = billingForm['state'].value;
            billingFormReqParams['billing_postalcode'] = billingForm['postalcode'].value;

            addDistributorReqParams['distributor_billing_data'] = billingFormReqParams;
            
            const partnerId = localStorage.getItem('partner_id');
            let reqURL = `/partners/${partnerId}/distributors`;

            dispatch(postAddDistributorAdminOnSubmit(addDistributorAdminLoaderStatus));
            return axios.post(reqURL, addDistributorReqParams).then(response => {

                addDistributorAdminLoaderStatus = false;
                toast.success("Reseller Created Successfully", {
                    containerId: 'toastMsg',
                    onClose : () => {
                        props.history.push('/sgadminportal/distributors');
                        dispatch(postAddDistributorAdminOnSubmitAfterCloseToastr())
                    }
                });
                dispatch(postAddDistributorAdminOnSubmit(addDistributorAdminLoaderStatus));
            }).catch(error => {
                addDistributorAdminLoaderStatus = false;
                if(error.response.status == 403){
                    localStorage.clear();
                    toast.error("Session Expired ! Please login again", {containerId: 'toastMsg'});
                    window.location.href = "/";
                }else{
                    toast.error(error.response.data.message, {containerId: 'toastMsg'});
                    dispatch(postAddDistributorAdminOnSubmit(addDistributorAdminLoaderStatus));
                }

            })
            return dispatch(postAddDistributorAdminOnSubmit(addDistributorAdminLoaderStatus))
        }
    }
}

export const postAddDistributorAdminOnSubmitAfterCloseToastr = () => {
    return{
        type : POST_ADD_DISTRIBUTOR_ADMIN_ON_SUBMIT_AFTER_CLOSE_TOASTR
    }
}

export const postAddDistributorAdminOnSubmit = (addDistributorAdminLoaderStatus) => {
    return{
        type : POST_ADD_DISTRIBUTOR_ADMIN_ON_SUBMIT,
        addDistributorAdminLoaderStatus : addDistributorAdminLoaderStatus
    }
}

export const handleAddDistributorBillingFormField = (formFieldId, event) => {
    return (dispatch) => {
        let textFieldValue = event.target.value;
        if(
            formFieldId === 'phone'|| formFieldId==='postalcode'
        ){
            textFieldValue = (event.target.value).replace(/[^0-9]/g, '');
        }

        return dispatch(postHandleAddDistributorBillingFormField(formFieldId, textFieldValue, event))
    }
}

export const postHandleAddDistributorBillingFormField = (formFieldId, textFieldValue, event) => {
    return{
        formFieldId : formFieldId,
        data : textFieldValue,
        type : POST_HANDLE_ADD_DISTRIBUTOR_BILLING_FORM_FIELD
    }
}

export const addDistributorContactNext = (addDistributorContactinformations) => {
    return (dispatch) => {
        return dispatch(postAddDistributorContactNext(addDistributorContactinformations))
    }
}
export const postAddDistributorContactNext = (addDistributorContactinformations) => {
    return{
        type : POST_ADD_DISTRIBUTOR_CONTACT_NEXT,
        addDistributorContactinformations : addDistributorContactinformations
    }
}
export const deleteAddDistributorContactInfo = (addDistributorContactinformations) => {
    return (dispatch) => {
        return dispatch(postDeleteAddDistributorContactInfo(addDistributorContactinformations))
    }
}
export const postDeleteAddDistributorContactInfo = (addDistributorContactinformations) =>{
    return{
        type : POST_DELETE_ADD_DISTRIBUTOR_CONTACT_INFO,
        addDistributorContactinformations : addDistributorContactinformations
    }
}
export const updateDistributorContactInfo = (addDistributorContactinformations) => {
    return (dispatch) => {
        return dispatch(postUpdateDistributorContactInfo(addDistributorContactinformations))
    }
}
export const postUpdateDistributorContactInfo = (addDistributorContactinformations) => {
    return{
        type : POST_UPDATE_DISTRIBUTOR_CONTACT_INFO,
        addDistributorContactinformations : addDistributorContactinformations
    }
}
export const addAddDistributorContactInfo = (addDistributorContactinformations) => {
    return (dispatch) => {
        return dispatch(postAddAddDistributorContactInfo(addDistributorContactinformations))
    }
}
export const postAddAddDistributorContactInfo = (addDistributorContactinformations) => {
    return{
        type : POST_ADD_ADD_DISTRIBUTOR_CONTACT_INFO,
        addDistributorContactinformations : addDistributorContactinformations
    }
}
export const addDistributorTabHandleChange = (event, value) => {
    return (dispatch) => {
        let addDistributorTabValue = value;
        return dispatch(postAddDistributorTabhandleChange(addDistributorTabValue))
    }
}
export const postAddDistributorTabhandleChange = (addDistributorTabValue) => {
    return{
        type : POST_ADD_DISTRIBUTOR_TAB_HANDLE_CHANGE,
        addDistributorTabValue : addDistributorTabValue
    }
}
export const handleEditDistributorDialogClose = () => {
    return (dispatch) => {
        return dispatch(postHandleEditDistributorDialogClose())
    }
}

export const postHandleEditDistributorDialogClose = () => {
    return{
        type : POST_HANDLER_EDIT_DISTRIBUTOR_DIALOG_CLOSE
    }
}

export const handleEditDistributorDialogOpen = (rowData) => {
    return (dispatch) => {
        return dispatch(postHandleEditDistributorDialogOpen(rowData))
    }
}
export const postHandleEditDistributorDialogOpen = (rowData) => {
    return{
        type : POST_HANDLER_EDIT_DISTRIBUTOR_DIALOG_OPEN,
        data : rowData
    }
}
export const initializeDistributorUploadForm = () => {
    return (dispatch) => {
        return dispatch(setDefaultValueToDistributorUploadForm())
    }
}

export const setDefaultValueToDistributorUploadForm = () => {
    return{
        type : SET_DEFAULT_VALUE_TO_DISTRIBUTOR_UPLOAD_FORM
    }
}

export const ondistributorUploadSubmit = (bulkUploadedDistributors, uploadDistributorForms) => {
    return (dispatch) => {
        let distributorBulkUploadSubmitLoaderStatus = false;
        let distributorUploadStatusList = [];
        let distributorUserUploadFormValidationStatus = true;
        let reqPath = "/distributor/"+localStorage.getItem('distributor_id')+"/users/bulk";
        Object.keys(uploadDistributorForms).map(inputField => {
            if(uploadDistributorForms[inputField].value === ''){
                distributorUserUploadFormValidationStatus = false;
            }
        })
        dispatch(postdistributorUploadedData(distributorUploadStatusList, distributorBulkUploadSubmitLoaderStatus));
        if(bulkUploadedDistributors.length > 0 && distributorUserUploadFormValidationStatus){
            distributorBulkUploadSubmitLoaderStatus = true;
            dispatch(postdistributorUploadedData(distributorUploadStatusList, distributorBulkUploadSubmitLoaderStatus));
            return axios.post(reqPath, bulkUploadedDistributors)
            .then(response => {
                distributorBulkUploadSubmitLoaderStatus = false;
                toast.success("Reseller Users Uploaded Successfully", {
                    containerId: 'toastMsg'
                });
                distributorUploadStatusList = response.data.data
                dispatch(postdistributorUploadedData(distributorUploadStatusList, distributorBulkUploadSubmitLoaderStatus));
            })
            .catch(error => {
                distributorBulkUploadSubmitLoaderStatus = false;
                if(error.response.status == 403){
                    localStorage.clear();
                    window.location.href = "/";
                }else{
                    toast.error(error.response.data.message, {
                        containerId: 'toastMsg',
                    });
                }
                dispatch(postdistributorUploadedData(distributorUploadStatusList, distributorBulkUploadSubmitLoaderStatus));
            })
        }
    }
}
export const postdistributorUploadedData = (distributorUploadStatusList, distributorBulkUploadSubmitLoaderStatus) => {
    return {
        type : POST_DISTRIBUTOR_UPLOADED_DATA,
        distributorUploadStatusList : distributorUploadStatusList,
        distributorBulkUploadSubmitLoaderStatus : distributorBulkUploadSubmitLoaderStatus
    }
}
export const inputHandlerUploadDistributor = (formElementId, event) => {
    let bulkUploadedDistributors;
    let formData = (event.target.value).replace(/^.*\\/, "");
    return (dispatch) => {
        convertCSVToJSONUser(event.target.files[0]).then(data =>{
            bulkUploadedDistributors = data;
            dispatch(setInputHandlerUploadDistributor(formElementId, formData, bulkUploadedDistributors));
        })
        .catch(error => {
            toast.error(error, {
                containerId: 'toastMsg'
            });
            formData = '';
            dispatch(setInputHandlerUploadDistributor(formElementId, formData, bulkUploadedDistributors));
        })
    }
}
export const setInputHandlerUploadDistributor = (formElementId, formData, bulkUploadedDistributors) => {
    return{
        type : SET_INPUTHANDLER_UPLOAD_DISTRIBUTOR,
        formElementId : formElementId,
        data : formData,
        bulkUploadedDistributors : bulkUploadedDistributors
    }
}
export const updateDistributorUser = (rowData, email, distributorUserInformations) => {
    return (dispatch) => {
        let reqURL = "distributor/"+localStorage.getItem('distributor_id')+"/users/"+email;
        let reqParams = {};
        reqParams['userfirstname'] = rowData.distributor_first_name;
        reqParams['userlastname'] = rowData.distributor_last_name;
        reqParams['accesslevel'] = Number(rowData.distributor_role);
        reqParams['useremailaddress'] = rowData.distributor_email;
        return axios.put(reqURL, reqParams)
        .then(response => {
            const updatedDistributorUserIndex = distributorUserInformations.findIndex(d => {
                return d.distributor_email === email;
            });
            distributorUserInformations[updatedDistributorUserIndex].distributor_first_name = response.data.data.user.userfirstname;
            distributorUserInformations[updatedDistributorUserIndex].distributor_last_name = response.data.data.user.userlastname;
            distributorUserInformations[updatedDistributorUserIndex].distributor_role = response.data.data.accesslevel;
            distributorUserInformations[updatedDistributorUserIndex].distributor_email = response.data.data.useremailaddress;
            toast.success("User Updated Successfully", {
                containerId: 'toastMsg'
            });
            dispatch(setUpdatedDistributorUser(distributorUserInformations))
        })
        .catch(error => {
            toast.error(error.response.data.message, {
                containerId: 'toastMsg'
            });
            dispatch(setUpdatedDistributorUser(distributorUserInformations))
        })
    }
}

export const deleteDistributorUser = (rowData, email, distributorUserInformations) => {
    return (dispatch) => {
        let reqURL = "distributor/"+localStorage.getItem('distributor_id')+"/users/"+email;
        return axios.delete(reqURL)
        .then(response => {
            const deletedDistributorUserIndex = distributorUserInformations.findIndex(d => {
                return d.distributor_email == rowData.distributor_email;
            });
            distributorUserInformations.splice(deletedDistributorUserIndex,1)
            toast.success("User Deleted Successfully", {
                containerId: 'toastMsg'
            });
            dispatch(setUpdatedDistributorUser(distributorUserInformations))
        })
        .catch(error => {
            toast.error(error.response.data.message, {
                containerId: 'toastMsg'
            });
            dispatch(setUpdatedDistributorUser(distributorUserInformations))
        })
    }
}

export const setUpdatedDistributorUser = (distributorUserInformations) => {
    return{
        type : SET_UPDATED_DISTRIBUTOR_USER,
        distributorUserInformations : distributorUserInformations
    }
}

export const getDistributorUser = () => {
    return (dispatch) => {
        let listDistributorUserLoaderStatus = true;
        let reqUrl = "/distributor/"+localStorage.getItem('distributor_id')+"/users";
        let distributorUsers = [];
        let distributorUser = {};
        dispatch(setDistributorUserList(distributorUsers, listDistributorUserLoaderStatus))
        return axios.get(reqUrl)
        .then(response => {
            listDistributorUserLoaderStatus = false;
            for(let i = 0; i < response.data.data.length; i++){
                distributorUser = {};
                distributorUser['distributor_first_name'] = response.data.data[i].user_first_name;
                distributorUser['distributor_last_name'] = response.data.data[i].user_last_name;
                distributorUser['distributor_email'] = response.data.data[i].user_id.user_id;
                distributorUser['distributor_role'] = response.data.data[i].user_id.access_level.accesslevelid;
                distributorUsers.push(distributorUser);
            }
            dispatch(setDistributorUserList(distributorUsers, listDistributorUserLoaderStatus))
        })
        .catch(error => {
            listDistributorUserLoaderStatus = false;
            dispatch(setDistributorUserList(distributorUsers, listDistributorUserLoaderStatus))
        })
    }
}
export const setDistributorUserList = (distributorUsers, listDistributorUserLoaderStatus) => {
    return {
        type : SET_DISTRIBUTOR_USER_LIST,
        distributorUsers : distributorUsers,
        listDistributorUserLoaderStatus : listDistributorUserLoaderStatus
    }
}
//Initialize Add Distributor User Form
export const initializeAddDistributorUserForm = (distributorUserForm) => {
    return (dispatch) => {
        return dispatch(setDefaultValueToAddDistributorUser())
    }
}
export const setDefaultValueToAddDistributorUser = () => {
    return{
        type : SET_DEFAULT_VALUE_TO_ADD_DISTRIBUTOR_USER
    }
}
//onSubmit Action of Add Distributor User
export const onSubmitDistributorUser = (distributorUserForm, props, url) => {
    return (dispatch) => {
        let distributorUserLoaderStatus = false;
        let distributorUserFormValidationStatus = true;
        Object.keys(distributorUserForm).map(inputField => {
            if(distributorUserForm[inputField].value === ''){
                distributorUserFormValidationStatus = false;
            }
        })
        if(distributorUserForm['distributor_user_email'].value != ''){
            if(!(distributorUserForm['distributor_user_email'].value).match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)){
                distributorUserFormValidationStatus = false;
            }
        }
        dispatch(setSubmittedDistributorUser(distributorUserLoaderStatus));
        if(distributorUserFormValidationStatus){
            distributorUserLoaderStatus = true;
            let reqUrl = "/distributor/"+localStorage.getItem('distributor_id')+"/users";
            let reqParams = {};
            reqParams['userfirstname'] = distributorUserForm['distributor_user_fname'].value;
            reqParams['userlastname'] = distributorUserForm['distributor_user_lname'].value;
            reqParams['accesslevel'] = Number(distributorUserForm['distributor_user_role'].value);
            reqParams['useremailaddress'] = distributorUserForm['distributor_user_email'].value;
            dispatch(setSubmittedDistributorUser(distributorUserLoaderStatus));
            return axios.post(reqUrl, reqParams)
            .then(response => {

                distributorUserLoaderStatus = false;
                toast.success("User Created Successfully", {
                    containerId: 'toastMsg',
                    onClose : () => props.history.push(url)
                });
                dispatch(setSubmittedDistributorUser(distributorUserLoaderStatus));
            })
            .catch(error => {
                distributorUserLoaderStatus = false;
                toast.error(error.response.data.message, {containerId: 'toastMsg'});
                dispatch(setSubmittedDistributorUser(distributorUserLoaderStatus));
            })
        }

    }
}
export const setSubmittedDistributorUser = (distributorUserLoaderStatus) => {
    return{
        type : SET_SUBMITTED_DISTRIBUTOR_USER,
        distributorUserLoaderStatus : distributorUserLoaderStatus
    }
}

//Input Handler Action of Distributor User
export const distributorUserInputHandler = (inputFiledId, event) => {
    return (dispatch) => {
        return dispatch(setDistributorUserInputField(inputFiledId, event));
    }
}

export const setDistributorUserInputField = (inputFiledId, event) => {
    return{
        type : SET_DISTRIBUTOR_USER_INPUT_FIELD,
        inputFiledId : inputFiledId,
        data : event.target.value
    }
}

//Update Distributor Action of Distributor User
export const updateDistributor = (distributorIdForEdit, addDistributorForm, addDistributorLogoBase64, distributorInformations) => {
    return (dispatch) => {
    let distributorFieldsValidationStatus = true;
    const partnerId = localStorage.getItem('partner_id');
    let reqURL = `/partners/${partnerId}/distributors/${distributorIdForEdit}`;
    let updateDistributorReq = {};
    let distributorLogoInfo = {};
    let updateDistributorLoaderStatus;

    Object.keys(addDistributorForm).map((textField) => {
        if(addDistributorForm[textField].value  == '' && (textField !== 'distributor_email' && textField !== 'support_email' && textField !== 'support_contact_number' && textField !== 'distributor_address' && textField !== 'distributor_logo')){
            distributorFieldsValidationStatus = false;
            addDistributorForm[textField].errorStatus = true;
        }
    })
    if(addDistributorForm['distributor_email'].value != ''){
        if(!(addDistributorForm['distributor_email'].value).match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)){
            addDistributorForm['distributor_email'].errorStatus = true;
            distributorFieldsValidationStatus = false;
        }
    }
    if(addDistributorForm['myob_account_number'].value != ''){
        if(Number(addDistributorForm['myob_account_number'].value) === 0){
            addDistributorForm['myob_account_number'].errorStatus = true;
            distributorFieldsValidationStatus = false;
        }
    }
    dispatch(updateDistributorInfo(updateDistributorLoaderStatus, distributorInformations));
        if(distributorFieldsValidationStatus){
            updateDistributorLoaderStatus = true;
            if(addDistributorLogoBase64 != ''){
                distributorLogoInfo['base64_string'] = addDistributorLogoBase64;
                let fileType = getFileExtension(addDistributorForm['distributor_logo'].value);
                let mimeType = '';
                if(fileType.toLowerCase() === 'jpeg'){
                    mimeType = 'application/jpeg';
                }
                if(fileType.toLowerCase() === 'jpg'){
                    mimeType = 'application/jpeg';
                }
                if(fileType.toLowerCase() === 'png'){
                    mimeType = 'image/png';
                }
                distributorLogoInfo['file_type'] = mimeType;
            }
            updateDistributorReq["country"] = addDistributorForm['country'].value;
            // updateDistributorReq["distributoremailaddress"] = addDistributorForm['distributor_email'].value;
            updateDistributorReq["distributorname"] = addDistributorForm['distributor_name'].value;
            updateDistributorReq["distributorsupportnumber"] = addDistributorForm['support_contact_number'].value;
            updateDistributorReq["distributorsupportemail"] = addDistributorForm['support_email'].value;
            updateDistributorReq["distributoraddress"] = addDistributorForm['distributor_address'].value;
            if(addDistributorLogoBase64 != ''){
                updateDistributorReq["distributorlogo"] = distributorLogoInfo;
            }
            updateDistributorReq["accesslevel"] = 2;
            // updateDistributorReq["abn"] = addDistributorForm['abn'].value;
            updateDistributorReq["myobaccountnumber"] = addDistributorForm['myob_account_number'].value;

            dispatch(updateDistributorInfo(updateDistributorLoaderStatus, distributorInformations));
            return axios.put(reqURL, updateDistributorReq)
            .then(response => {
                updateDistributorLoaderStatus = false;
                toast.success("Reseller Updated Successfully", {containerId: 'toastMsg'});
                const updatedDistributorIndex = distributorInformations.findIndex(d => {
                    return d.distributor_id === distributorIdForEdit;
                });

                let abnPrefix = "";
                let country = response.data.data['country'];

                if(country.length > 0) {
                    abnPrefix = country.substring(0,3).toUpperCase();
                }

                distributorInformations[updatedDistributorIndex].abn = abnPrefix + "-" + response.data.data['abn'];
                distributorInformations[updatedDistributorIndex].country_name = country;
                distributorInformations[updatedDistributorIndex].distributor_address = response.data.data['distributor_address'];
                distributorInformations[updatedDistributorIndex].distributor_id = response.data.data['distributor_id'];
                distributorInformations[updatedDistributorIndex].distributor_logo = "Logo";
                distributorInformations[updatedDistributorIndex].distributor_name = response.data.data['distributor_name'];
                distributorInformations[updatedDistributorIndex].distributor_support_contactnumber = response.data.data['distributor_support_contactnumber'];
                distributorInformations[updatedDistributorIndex].distributor_support_email = response.data.data['distributor_support_email'];
                distributorInformations[updatedDistributorIndex].myob_account_number = response.data.data['myob_account_number'];
                dispatch(updateDistributorInfo(updateDistributorLoaderStatus, distributorInformations));
            })
            .catch(error => {
                updateDistributorLoaderStatus = false;
                if(error.response.status == 403){
                    localStorage.clear();
                    toast.error("Session Expired ! Please login again", {containerId: 'toastMsg'});
                    window.location.href = "/";
                }else{
                    toast.error(error.response.data.message, {containerId: 'toastMsg'});
                }
                dispatch(updateDistributorInfo(updateDistributorLoaderStatus, distributorInformations));
            })
        }
    }
}
export const updateDistributorInfo = (updateDistributorLoaderStatus, distributorInformations) => {
    return{
        type : UPDATE_DISTRIBUTOR_INFO,
        updateDistributorLoaderStatus : updateDistributorLoaderStatus,
        distributorInformations : distributorInformations
    }
}

//Initialize Distributor Admin Form Fields Action
export const initializeFormFields = () => {
    return (dispatch) => {
        return dispatch(setDefaultValueToAllFormField())
    }
}
export const setDefaultValueToAllFormField = () => {
    return{
        type : SET_DEFAULT_VALUE_TO_ALL_FORM_FIELD
    }
}

//Get All Distributor Admin Action
export const getDistributors = () => {
    let showDistributorListLoaderStatus = false;
    let distributor = {};
    let distributorList = [];
    return (dispatch) => {
        const partnerId = localStorage.getItem('partner_id');
        if (!partnerId){
          throw new Error('partner id not set');
        }
        return axios.get(`/partners/${partnerId}/distributors`)
        .then(response => {
                showDistributorListLoaderStatus = true;
                for(let i = 0; i < response.data.data.length; i++){
                    distributor = {};
                    distributor['distributor_name'] = response.data.data[i].distributor_name;
                    distributor['abn'] = response.data.data[i].abn;
                    distributor['country_name'] = response.data.data[i].country;
                    distributor['myob_account_number'] = response.data.data[i].myob_account_number;
                    if(response.data.data[i].distributor_address !== undefined){
                        distributor['distributor_address'] = response.data.data[i].distributor_address;
                    }else{
                        distributor['distributor_address'] = '';
                    }

                    distributor['distributor_logo'] = "Logo";
                    if(response.data.data[i].distributor_support_contactnumber !== undefined){
                        distributor['distributor_support_contactnumber'] = response.data.data[i].distributor_support_contactnumber;
                    }else{
                        distributor['distributor_support_contactnumber'] = '';
                    }

                    if(response.data.data[i].distributor_support_email !== undefined){
                        distributor['distributor_support_email'] = response.data.data[i].distributor_support_email;
                    }else{
                        distributor['distributor_support_email'] = '';
                    }
                    distributor['distributor_id'] = response.data.data[i].distributor_id;
                    distributorList.push(distributor);
                }
                dispatch(setDistributorList(distributorList, showDistributorListLoaderStatus));
        })
        .catch(error => {
            if(error.response.status == 403){
                localStorage.clear();
                window.location.href = "/";
            }
        })
    }
}

export const setDistributorList = (distributerList,showDistributorListLoaderStatus) => {
    return{
        type : SET_DISTRIBUTOR_LIST,
        distributerList : distributerList,
        showDistributorListLoaderStatus : showDistributorListLoaderStatus
    }
}

//Handle Distributor Admin TextField Change
export const handleTextFieldChange = (inputFieldId, event) => {
    return (dispatch) => {
        let addDistributorLogoBase64 = '';
        let textFieldValue = event.target.value;
        if(
            inputFieldId === 'abn' ||
            inputFieldId === 'myob_account_number' ||
            inputFieldId === 'support_contact_number' ||
            inputFieldId === 'phone'|| inputFieldId==='postalcode'
        ){
            textFieldValue = (event.target.value).replace(/[^0-9]/g, '');
        }
        if(inputFieldId === 'distributor_logo'){
            textFieldValue = removeFakePath(event.target.value);
            if(event.target.files !== null){
                convertToBase64(event.target.files[0]).then(data =>{
                    let addDistributorLogoBase64 = data;
                    if((((new Blob([addDistributorLogoBase64]).size)/1024)/1024) > 1.5){
                        toast.error("File size too large", {containerId: 'toastMsg'});
                    }
                    dispatch(setTextFieldData(inputFieldId, textFieldValue, addDistributorLogoBase64))
                });


            }
        }
        return dispatch(setTextFieldData(inputFieldId, textFieldValue, addDistributorLogoBase64));
    }
}

export const onBlurTextfield=(inputFieldId,textFieldValue, addDistributorLogoBase64)=>{
    return (dispatch) => {
        return dispatch(setTextFieldData(inputFieldId, textFieldValue, addDistributorLogoBase64));
    }
}

export const setTextFieldData = (textField, textFieldValue, addDistributorLogoBase64) => {
    return {
        type: DISTRIBUTOR_TEXTFIELD_CHANGE,
        textFieldId: textField,
        textFieldData: textFieldValue,
        addDistributorLogoBase64 : addDistributorLogoBase64
    }
}


//onSubmit Action of AddDistributor Admin Form
export const addDistributorNext = (addDistributorForm, props, addDistributorLogoBase64, editDistributor) => {
    return (dispatch) => {
        let distributorFieldsValidationStatus = true;
        let addDistributorTabValue = 0;
        let addDistributorReq = {};
        let addDistributorTabDisableStatus = true;
        let tabSwitchStatus = false;
        Object.keys(addDistributorForm).map((textField) => {
            if(
                addDistributorForm[textField].value  == '' &&
                (
                    textField !== 'support_email' &&
                    textField !== 'support_contact_number' &&
                    textField !== 'distributor_address' &&
                    textField !== 'distributor_logo' &&
                    textField !== 'company_name' &&
                    textField !== 'phone' &&
                    textField !== 'payment_method'
                )){
                    distributorFieldsValidationStatus = false;
                    addDistributorForm[textField].errorStatus = true;
            }
        })
        if(addDistributorForm['distributor_email'].value != ''){
            if(!(addDistributorForm['distributor_email'].value).match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)){
                addDistributorForm['distributor_email'].errorStatus = true;
                distributorFieldsValidationStatus = false;
            }
        }
        if(addDistributorForm['support_email'].value != ''){
            if(!(addDistributorForm['support_email'].value).match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)){
                addDistributorForm['support_email'].errorStatus = true;
                distributorFieldsValidationStatus = false;
            }
        }
        // validation of indexed fields
        var allPromises = [];
        // TODO calls missing for distributor profile edit by distributor user
        var promise = checkDistributorFields(addDistributorForm, editDistributor);
        allPromises.push(promise);
        if (!editDistributor){
            if(addDistributorForm['distributor_email'].value != ''){
                // check user does not exist
                // promise = checkEmailInUse(addDistributorForm['distributor_email'].value);
                // allPromises.push(promise);
            }
        }

        // all checks completed
        Promise.all(allPromises)
        .then((data) => {
            dispatch(setSubmittedData(addDistributorTabValue, addDistributorReq, addDistributorTabDisableStatus, tabSwitchStatus));
            if(distributorFieldsValidationStatus){
                addDistributorReq = {};
                let distributorLogoInfo = {};
                let fileType = getFileExtension(addDistributorForm['distributor_logo'].value);
                let mimeType = '';
                if(fileType.toLowerCase() === 'jpeg'){
                    mimeType = 'application/jpeg';
                }
                if(fileType.toLowerCase() === 'jpg'){
                    mimeType = 'application/jpeg';
                }
                if(fileType.toLowerCase() === 'png'){
                    mimeType = 'image/png';
                }

                let abnPrefix = "";
                let country = addDistributorForm['country'].value;

                if(country.length > 0) {
                    abnPrefix = country.substring(0,3).toUpperCase();
                }

                distributorLogoInfo['file_type'] = mimeType;
                distributorLogoInfo['base64_string'] = addDistributorLogoBase64;

                addDistributorReq["country"] = country;
                addDistributorReq["distributoremailaddress"] = addDistributorForm['distributor_email'].value;
                addDistributorReq["distributorname"] = addDistributorForm['distributor_name'].value;
                addDistributorReq["distributorsupportnumber"] = addDistributorForm['support_contact_number'].value;
                addDistributorReq["distributorsupportemail"] = addDistributorForm['support_email'].value;
                addDistributorReq["distributoraddress"] = addDistributorForm['distributor_address'].value;
                addDistributorReq["distributorlogo"] = distributorLogoInfo;
                addDistributorReq["accesslevel"] = 2;
                addDistributorReq["abn"] = abnPrefix + "-" + addDistributorForm['abn'].value;
                addDistributorReq["state"] = addDistributorForm['state'].value;
                addDistributorReq["postalcode"] = addDistributorForm['postalcode'].value;

                let mainAttribute = {};
                mainAttribute['email'] = addDistributorForm['distributor_email'].value;
                mainAttribute['phone'] = addDistributorForm['phone'].value;
                addDistributorReq["main_attributes"] = mainAttribute;

                // Default some billing fields, on the add
                //if (props.distributorState.billingForm['company_name'].value === '' && props.distributorState.billingForm['email'].value === '' && props.distributorState.billingForm['phone'].value === '') {
                if (props.distributorState.billingForm['email'].value === '' && props.distributorState.billingForm['phone'].value === '') {
                    // SMED-1354 props.distributorState.billingForm['company_name'].value = addDistributorForm['company_name'].value;
                    props.distributorState.billingForm['email'].value = addDistributorForm['distributor_email'].value;
                    props.distributorState.billingForm['phone'].value = addDistributorForm['phone'].value;
                }

                addDistributorTabValue = 1;
                addDistributorTabDisableStatus = false;
                tabSwitchStatus = true;
                dispatch(setSubmittedData(addDistributorTabValue, addDistributorReq, addDistributorTabDisableStatus, tabSwitchStatus));
            }
        })
        .catch((err) => {
            // validation failed
            dispatch(setSubmittedData(addDistributorTabValue, addDistributorReq, addDistributorTabDisableStatus, tabSwitchStatus));
        });
    }
}

const checkEmailInUse = function(distributorEmail) {
    return new Promise((resolve, reject) => {
        let reqURL = "/sg/admin/user/" + distributorEmail;
        var user = axios.get(reqURL).then(response => {
            if (response && response.data && response.data.status == 200) {
                // email found, so return error
                toast.error("The distrubutor email is in use", {containerId: 'toastMsg'});
                reject(distributorEmail);
            } else {
                resolve(distributorEmail);
            }
        }).catch(error => {
            if(error && error.response && error.response.status == 403){
                localStorage.clear();
                toast.error("Session Expired ! Please login again", {containerId: 'toastMsg'});
                window.location.href = "/";
                reject(distributorEmail);
            }else{
                // not found, so return success (actually 500 error)
                resolve(distributorEmail);
            }
        });

    })
}

const checkDistributorFields = function(addDistributorForm, editDistributor) {
    return new Promise((resolve, reject) => {
        let addDistributorReq = {};
        let abnPrefix = "";
        let country = addDistributorForm['country'].value;
        if(country.length > 0) {
            abnPrefix = country.substring(0,3).toUpperCase();
        }
        addDistributorReq["country"] = country;
        addDistributorReq["distributoremailaddress"] = addDistributorForm['distributor_email'].value;
        addDistributorReq["distributorname"] = addDistributorForm['distributor_name'].value;
        addDistributorReq["distributorsupportnumber"] = addDistributorForm['support_contact_number'].value;
        addDistributorReq["distributorsupportemail"] = addDistributorForm['support_email'].value;
        addDistributorReq["distributoraddress"] = addDistributorForm['distributor_address'].value;
        addDistributorReq["abn"] = abnPrefix + "-" + addDistributorForm['abn'].value;
        addDistributorReq["state"] = addDistributorForm['state'].value;
        addDistributorReq["postalcode"] = addDistributorForm['postalcode'].value;

        let reqURL = "";
        if(!editDistributor) {
            // add mode
            reqURL = "/partner/distributorchecks";
        }else{
            // edit mode
            reqURL = "/sg/distributorchecks/" + localStorage.getItem('distributor_id'); //TODO update this one too
        }
        var checks = axios.post(reqURL, addDistributorReq)
        .then(response => {
            resolve(addDistributorForm);
        }).catch(error => {
            if(error.response && error.response.status == 403){
                localStorage.clear();
                toast.error("Session Expired ! Please login again", {containerId: 'toastMsg'});
                window.location.href = "/";
                reject(addDistributorForm);
            } else {
                if (error.response) {
                    toast.error(error.response.data.message, {containerId: 'toastMsg'});
                    reject(addDistributorForm);
                }
            }
        });

    })
}

export const setSubmittedData = (addDistributorTabValue, addDistributorReqParams, addDistributorTabDisableStatus, tabSwitchStatus) => {
    return {
        type: SUBMIT_DISTRIBUTOR_INFO,
        addDistributorTabValue : addDistributorTabValue,
        addDistributorReqParams : addDistributorReqParams,
        addDistributorTabDisableStatus : addDistributorTabDisableStatus,
        tabSwitchStatus : tabSwitchStatus
    }
}
