import {
  SEARCH_MAILLOG_TEXTFIELD_CHANGE,
  SEARCH_MAILLOG_DATEFIELD_CHANGE,
  SET_MAILLOG_DEFAULT_VALUE,
  SET_MAILLOG_LIST_DEFAULT,
  MANAGE_VIEW_MAILLOG_DIALOG_CLOSE,
  MANAGE_VIEW_MAILLOG_OPEN_DIALOG,
  POST_CLEAR_ALL_SEARCH_MAILLOG_FIELD,
  SET_DEFAULT_VALUE_TO_SEARCH_MAILLOG_COMPONENT
} from './ActionType';


import { toast } from 'react-toastify';
import {
    getActulaURL
} from "../utils/Helper";

import axios from "../axios";
const moment = require('moment');

export const initializeMaillogComponent = () => {
  return (dispatch) => {
    return dispatch(setMaillogDefault())
  }
}

export const setMaillogDefault = () => {
  return {
    type: SET_MAILLOG_DEFAULT_VALUE
  }
}

export const initializeMaillogListComponent = () => {
  return (dispatch) => {
    return dispatch(setMaillogListDefault())
  }
}
  
export const getMaillogsList = (searchMaillogForm) => {
    return(dispatch) => {
      let maillog = {};
      let maillogRowData = [];
      let searchMaillogLoaderStatus = false;
      let seperator = '?';
      let url = '/sg/searchmaillogs';

      if (searchMaillogForm.mail_state && searchMaillogForm.mail_state.value) {
        url = url + seperator + 'state=' + searchMaillogForm.mail_state.value;
        seperator = '&';
      }
      if (searchMaillogForm.from_date && searchMaillogForm.from_date.value) {
        url = url + seperator + 'sentFrom=' + moment(searchMaillogForm.from_date.value).format('YYYY-MM-DD');
        seperator = '&';
      }
      if (searchMaillogForm.to_date && searchMaillogForm.to_date.value) {
        var d = new Date(searchMaillogForm.to_date);
        url = url + seperator + 'sentTo=' + moment(searchMaillogForm.to_date.value).format('YYYY-MM-DD');
        seperator = '&';
      }
      searchMaillogLoaderStatus = true;
      dispatch(setMaillogListDefault(maillogRowData, searchMaillogLoaderStatus));
      return axios.get(url)
      .then(response => {
            searchMaillogLoaderStatus = false;
            for(let i = 0; i < response.data.data.length; i++){
              maillog = {};
              maillog['ID'] = response.data.data[i]._id;
              //maillog['state'] = response.data.data[i].sendFailed;
              maillog['sentat'] = moment(response.data.data[i].sentAt).format('DD/MM/YYYY h:mm a');
              if (response.data.data[i].sendFailed) {
                maillog['state'] = 'Failed';
                //if (response.data.data[i].failureReason) { 
                //  maillog['state'] = response.data.data[i].failureReason;
                //}
              } else {
                maillog['state'] = 'Sent';
              }
              maillog['mailsubject'] = response.data.data[i].Maildata.subject;
              maillog['mailto'] = response.data.data[i].Maildata.to;
              maillogRowData.push(maillog);
            }
            dispatch(setMaillogListDefault(maillogRowData, searchMaillogLoaderStatus));
      })
      .catch(error => {
        searchMaillogLoaderStatus = false;
        if(error.response.status == 403){
            localStorage.clear();
            window.location.href = "/";
        }else{
            // searchFoundStatus = true;
            toast.error(error.response.data.message, {
                containerId: 'toastMsg'
            }); 
        }
        dispatch(setMaillogListDefault(maillogRowData, searchMaillogLoaderStatus));
      })
    }
}

export const setMaillogListDefault = (maillogRowData, searchMaillogLoaderStatus) => {
  return {
    type: SET_MAILLOG_LIST_DEFAULT,
    maillogRowData: maillogRowData,
    searchMaillogLoaderStatus : searchMaillogLoaderStatus
  }
}

export const handleViewMaillogOpenDialog = (rowData) => {
  return (dispatch) => {
    let reqUrl = "/sg/maillog/"+rowData.ID;
    let openViewMaillogDialogStatus = false;
    let maillogInfo = {};
    dispatch(manageViewMaillogOpenDialog(maillogInfo, openViewMaillogDialogStatus));
    return axios.get(reqUrl)
      .then(response => {
        openViewMaillogDialogStatus = false;
        maillogInfo = response.data.data;
        dispatch(manageViewMaillogOpenDialog(maillogInfo, openViewMaillogDialogStatus));
      })
      .catch(error => {
        openViewMaillogDialogStatus = false;
        dispatch(manageViewMaillogOpenDialog(maillogInfo, openViewMaillogDialogStatus));
      })
  }
}

export const manageViewMaillogOpenDialog = (maillogInfo, openViewMaillogDialogStatus) => {
  return{
      type : MANAGE_VIEW_MAILLOG_OPEN_DIALOG,
      maillogInfo : maillogInfo,
      openViewMaillogDialogStatus : openViewMaillogDialogStatus
  }
}

export const handleViewMaillogDialogClose = () => {
  return (dispatch) => {
      return dispatch(manageViewMaillogDialogClose());
  }
}

export const manageViewMaillogDialogClose = () => {
  return{
      type : MANAGE_VIEW_MAILLOG_DIALOG_CLOSE
  }
}

export const initializeSearchMaillog = () => {
  return (dispatch) => {
      return dispatch(setDefaultValueToSearchMaillog())
  }
}
export const setDefaultValueToSearchMaillog = () => {
  return{
      type : SET_DEFAULT_VALUE_TO_SEARCH_MAILLOG_COMPONENT
  }
}

export const clearAllSearchMaillogField = () => {
  return (dispatch) => {
      return dispatch(postClearAllSearchMaillogField())
  }
}
export const postClearAllSearchMaillogField = () => {
  return{
      type : POST_CLEAR_ALL_SEARCH_MAILLOG_FIELD
  }
}

export const handleTextFieldChange = (textField, event) => {
  return (dispatch) => {
      return dispatch(setTextFieldData(textField, event));
  }
}

export const setTextFieldData = (textField, event) => {
  return{
      type : SEARCH_MAILLOG_TEXTFIELD_CHANGE,
      textFieldId : textField,
      textFieldData : event.target.value
  }
}

export const handleDateFieldChange = (dateElementId, date) => {
  return (dispatch) => {
      return dispatch(setFormDateHandler(dateElementId, date))
  }
}
export const setFormDateHandler = (dateElementId, date) => {
  return{
      type : SEARCH_MAILLOG_DATEFIELD_CHANGE,
      dateElementId : dateElementId,
      date : date
  }
}


