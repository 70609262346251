import React, { Component } from "react";
import MaterialTable from "material-table";
import { connect } from "react-redux";
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import { toast } from 'react-toastify';
import Button from '@material-ui/core/Button';
import { withRouter } from 'react-router-dom';
import {
  editaxelConfig,
  getaxelConfigList,
} from '../../actions/AxelConfig';

class ListAxelConfig extends Component {

  componentDidMount() {
    this.props.getaxelConfigList();
  }
  render() {
    let {
      menuItems
    } = this.props.sidebarState;
    let {
      axelConfigListcols,
      axelConfigListrows,
      listaxelConfigLoaderStatus
    } = this.props.axelConfigState;
    return (
      <div
        style={{ marginTop: '2rem', marginBottom: "2rem" }}
        className="container"
      >
        <Paper>
          <div className="pt-1 pl-3">
            <span className="d-flex">
              <div className="mr-auto" style={{ paddingTop: "0" }}>
                <h4 style={{ marginTop: "1rem", textTransform: "uppercase" }}>Axle Config List</h4>
              </div>
              <div className="d-flex flex-wrap align-content-center">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => this.props.history.push(menuItems['axel_config_create'].urlpattern)}
                  className="mr-2"
                >
                  <i className="material-icons" style={{ paddingRight: "0.5rem" }}>
                    add_circle_outline
                          </i>
                  Add Axle Group Type
                      </Button>
              </div>
            </span>
          </div>
          {
            listaxelConfigLoaderStatus ?
              <div style={{ height: "20rem" }} className="d-flex justify-content-center align-items-center">
                <CircularProgress color="primary" />
              </div> :
              <MaterialTable
                columns={axelConfigListcols}
                data={axelConfigListrows}
                title=""
                editable={{
                  onRowUpdate: (newData, oldData) =>
                    new Promise((resolve, reject) => {
                      if (newData.description != '') {
                        resolve(this.props.editaxelConfig(newData, axelConfigListrows));
                      } else {
                        toast.error("Description field should not be blank", { containerId: 'toastMsg' });
                        reject();
                      }
                    }),
                }}
                options={{ actionsColumnIndex: -1 }}
              />
          }
        </Paper>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    axelConfigState: state.axelConfig,
    sidebarState: state.sidebarState
  };
}

const mapDispatchToProps = dispatch => {
  return {
    getaxelConfigList: () => {
      return dispatch(getaxelConfigList())
    },
    editaxelConfig: (newData, axelConfigListrows) => {
      return dispatch(editaxelConfig(newData, axelConfigListrows))
    }
    
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ListAxelConfig));