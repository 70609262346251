import {
    SUBMIT_VEHICLE_INFO,
    UPDATE_VEHICLE_INFO,
    VEHICLE_TEXTFIELD_CHANGE,
    VEHICLE_AXLE_TEXTFIELD_CHANGE,
    VEHICLE_CHECKBOX_CHANGE,
    VEHICLE_OPEN_DIALOG,
    VEHICLE_CLOSE_DIALOG,
    SET_CHECKBOX_VALUE,
    SET_UPLOAD_INPUT_CHANGE,
    SET_VEHICLE_TYPES,
    SET_VEHICLE_TYPES_FOR_LISTING,
    SET_VEHICLES,
    // OPEN_MASS_CERTIFICATION,
    SET_UPDATE_VEHICLE_DATA,
    SET_ADD_VEHICLE_INITIAL_STATE,
    SET_INPUTHANDLER_UPLOAD_VEHICLE,
    POST_VEHICLE_UPLOADED_DATA,
    SET_DEFAULT_VALUE_TO_VEHICLE_UPLOAD_FORM,
    POST_HANDLE_VEHICLE_LICENSING_CHECKBOX_CHANGE,
    MEASUREMENT_TYPE_LIST,
    GET_ALL_AXEL_GROUP
} from '../actions/ActionType';
// import React from 'react';
const initialState = {
    uploadVehicleForm: {
        upload_vehicle: {
            elementType: 'upload_csv',
            elementConfig: {
                type: 'text',
                label: 'Upload CSV'
            },
            value: '',
            errorStatus: false,
            errorMessage: "Upload CSV"
        }
    },
    addVehicleForm: {
        vehicle_plate: {
            disabled: true,
            elementType: 'input',
            elementConfig: {
                type: 'text',
                disabled: true,
                label: 'Vehicle Plate'
            },
            value: '',
            errorStatus: false,
            errorMessage: "Enter Vehicle Plate",
            disabledStatus: false,
            maxLength: 10
        },
        vehicle_types: {
            elementType: 'select',
            elementConfig: {
                type: 'text',
                label: 'Vehicle Type ID'
            },
            value: '',
            errorStatus: false,
            disabledStatus: false,
            errorMessage: "Enter Vehicle Type",
            menuItems: {
                'select_vehicle': {
                    name: "Select Vehicle Type",
                    value: ''
                },
            },
        },
        measurement_type: {
            elementType: 'select',
            elementConfig: {
                type: 'text',
                label: 'Measurement Type'
            },
            value: '',
            errorStatus: false,
            errorMessage: "Enter Measurement Type",
            menuItems: {
                'select_measurement_type': {
                    name: "Select Measurement Type",
                    value: ''
                },
            },
        },

        vehicle_state: {
            elementType: 'select',
            elementConfig: {
                type: 'text',
                label: 'Vehicle State'
            },
            value: '',
            errorStatus: false,
            disabledStatus: false,
            errorMessage: "Enter Vehicle State",
            menuItems: {
                'select_state': {
                    name: "Select State",
                    value: ''
                },
                'nsw': {
                    name: "NSW",
                    value: "NSW"
                },
                'qld': {
                    name: "QLD",
                    value: "QLD"
                },
                'sa': {
                    name: "SA",
                    value: "SA"
                },
                'tas': {
                    name: "TAS",
                    value: "TAS"
                },
                'vic': {
                    name: "VIC",
                    value: "VIC"
                },
                'act': {
                    name: 'ACT',
                    value: 'ACT'
                },
                'nt': {
                    name: 'NT',
                    value: 'NT'
                },
                'wa': {
                    name: "WA",
                    value: "WA"
                },
                'ashmore island': {
                    name: "ASHMORE ISLAND",
                    value: "ASHMORE ISLAND"
                },
                'cartier island': {
                    name: "CARTIER ISLAND",
                    value: "CARTIER ISLAND"
                },
                'christmas island': {
                    name: 'CHRISTMAS ISLAND',
                    value: 'CHRISTMAS ISLAND'
                },
                'cocos island': {
                    name: 'COCOS ISLAND',
                    value: 'COCOS ISLAND'
                },
                'coral sea island': {
                    name: 'CORAL SEA ISLAND',
                    value: 'CORAL SEA ISLAND'
                },
                'heard island': {
                    name: 'HEARD ISLAND',
                    value: 'HEARD ISLAND'
                },
                'mcdonald island': {
                    name: 'MCDONALD ISLAND',
                    value: 'MCDONALD ISLAND'
                },
                'oth': {
                    name: "OTH",
                    value: "OTH"
                }
            },
        },
        vehicle_status: {
            elementType: 'select',
            elementConfig: {
                type: 'text',
                label: 'Vehicle Status'
            },
            value: '',
            errorStatus: false,
            disabledStatus: false,
            errorMessage: "Enter Vehicle Status",
            menuItems: {
                'select_status': {
                    name: "Select Status",
                    value: ''
                },
                'active': {
                    name: "Active",
                    value: "active"
                },
                'inactive': {
                    name: "Inactive",
                    value: "inactive"
                },
            },
        }
    },
    licensingcheckBoxes: {
        "select_all": {
            elementType: 'checkbox',
            elementConfig: {
                checked: false,
                color: 'primary',
                label: "Select All"
            },
        },
        "mass_management": {
            elementType: 'checkbox',
            elementConfig: {
                checked: false,
                color: 'primary',
                label: "Mass Management"
            },
        },
        "fleet_management": {
            elementType: 'checkbox',
            elementConfig: {
                checked: false,
                color: 'primary',
                label: "Fleet Maintenance"
            },
        },
        "vehicle_doc": {
            elementType: 'checkbox',
            elementConfig: {
                checked: false,
                color: 'primary',
                label: "Vehicle Document"
            },
        },
        "check_list": {
            elementType: 'checkbox',
            elementConfig: {
                checked: false,
                color: 'primary',
                label: "Check List"
            },
        },
        "trip_purpose": {
            elementType: 'checkbox',
            elementConfig: {
                checked: false,
                color: 'primary',
                label: "Trip Purpose"
            },
        },
    },
    axleGroupForm: {
        vehicle_steer: {
            elementType: 'select',
            elementConfig: {
                type: 'text',
                label: 'Steer'
            },
            value: '',
            errorStatus: false,
            disabledStatus: false,
            errorMessage: "Select Steer",
            menuItems: {
                'select_steer': {
                    name: "Select Steer",
                    value: ''
                },
            },
        },
        vehicle_front: {
            elementType: 'select',
            elementConfig: {
                type: 'text',
                label: 'Front'
            },
            value: '',
            errorStatus: false,
            disabledStatus: false,
            errorMessage: "Select Front",
            menuItems: {
                'select_front': {
                    name: "Select Front",
                    value: ''
                },
            },
        },
        vehicle_rear: {
            elementType: 'select',
            elementConfig: {
                type: 'text',
                label: 'Rear'
            },
            value: '',
            errorStatus: false,
            disabledStatus: false,
            errorMessage: "Select Rear",
            menuItems: {
                'select_rear': {
                    name: "Select Rear",
                    value: ''
                },
            },
        },
    },
    // vehicleCheckBoxes : {
    //     mass_exp : {
    //         elementType : 'checkbox',
    //         elementConfig : {
    //             checked : false,
    //             color : 'primary',
    //             label : "Mass Exp"
    //         },
    //     },
    //     pre_exp : {
    //         elementType : 'checkbox',
    //         elementConfig : {
    //             checked : false,
    //             color : 'primary',
    //             label : "Pre Exp"
    //         },
    //     },
    //     asset_exp : {
    //         elementType : 'checkbox',
    //         elementConfig : {
    //             checked : false,
    //             color : 'primary',
    //             label : "Asset Exp"
    //         },
    //     },
    //     trip_exp : {
    //         elementType : 'checkbox',
    //         elementConfig : {
    //             checked : false,
    //             color : 'primary',
    //             label : "Trip Exp"
    //         },
    //     },
    // },
    vehicleTypeLookup: {},
    fleetStatus: 'no',
    vehicleInformations: [],
    bulkUploadVehicles: [],
    editVehicleDialogOpen: false,
    activeRecordForEdit: {},
    mode: '',
    massEventBase64String: '',
    listVehicleLoaderStatus: false,
    addVehicleLoaderStatus: false,
    currentlyEditableVehicleId: '',
    updateVehicleLoaderStatus: false,
    vehicleUploadStatusList: [],
    vehicleUploadStatusTableColumns: [
        {
            title: 'Vehicle Plate',
            field: 'vehicle_plate'
        },
        {
            title: 'Vehicle State',
            field: 'vehicle_state'
        },
        {
            title: 'Status',
            field: 'status'

        }
    ],
    vehicleBulkUploadVehicleLoaderStatus: false,
    getLicenseFieldStatusLoader: false

}
//POST_VEHICLE_UPLOADED_DATA Reducer
const postVehicleUploadedData = (state, action) => {
    state.vehicleBulkUploadVehicleLoaderStatus = action.vehicleBulkUploadVehicleLoaderStatus;
    state.vehicleUploadStatusList = action.vehicleUploadStatusList;
    state.uploadVehicleForm = action.uploadVehicleForm;
    return state;
}

//SET_INPUTHANDLER_UPLOAD_VEHICLE Reducer
const setInputhandlerUploadVehicle = (state, action) => {
    state.uploadVehicleForm[action.formElementId].value = action.data;
    if (state.uploadVehicleForm[action.formElementId].value === '') {
        state.uploadVehicleForm[action.formElementId].errorStatus = true;
    } else {
        state.uploadVehicleForm[action.formElementId].errorStatus = false;
    }
    if (action.bulkUploadVehicles === undefined) {
        state.bulkUploadVehicles = [];
    } else {
        state.bulkUploadVehicles = action.bulkUploadVehicles;
    }
    return state;
}

//SET_ADD_VEHICLE_INITIAL_STATE Reducer
const setAddVehicleInitialState = (state, action) => {
    // Object.keys(state.vehicleCheckBoxes).map(checkBox => {
    //     state.vehicleCheckBoxes[checkBox].elementConfig.checked = false;
    // })
    Object.keys(state.addVehicleForm).map((formField) => {
        state.addVehicleForm[formField].errorStatus = false;
        state.addVehicleForm[formField].value = '';
        state.addVehicleForm[formField].disabledStatus = false;
    })
    Object.keys(state.licensingcheckBoxes).map(checkBox => {
        state.licensingcheckBoxes[checkBox].elementConfig.checked = false;
        state.licensingcheckBoxes[checkBox].elementConfig.disabled = false;
    })
    return state;
}

//SET_UPDATE_VEHICLE_DATA Reducer
const setUpdateVehicleData = (state, action) => {
    state.editVehicleDialogOpen = action.editVehicleDialogOpen;
    if (!action.editVehicleDialogOpen) {
        state.mode = '';
        // Object.keys(state.vehicleCheckBoxes).map(checkBox => {
        //     state.vehicleCheckBoxes[checkBox].elementConfig.checked = false;
        //     state.vehicleCheckBoxes[checkBox].elementConfig.disabled = false;
        // })
        Object.keys(state.addVehicleForm).map(inputField => {
            state.addVehicleForm[inputField].value = '';
            state.addVehicleForm[inputField].disabledStatus = false
        })
    }
    state.updateVehicleLoaderStatus = action.updateVehicleLoaderStatus;
    state.vehicleInformations = action.vehicleInformations;
    return state;
}

// //OPEN_MASS_CERTIFICATION Reducer
// const openMassCertification = (state, action) => {
//     return state;
// }

//SET_VEHICLES Reducer
const setVehicles = (state, action) => {
    // console.log("state", state, "action", action)
    state.listVehicleLoaderStatus = action.listVehicleLoaderStatus;
    state.vehicleInformations = action.vehicleInformations;
    return state
}

//SET_VEHICLE_TYPES_FOR_LISTING Reducer
const setVehicleTypesForListing = (state, action) => {
    state.vehicleTypeLookup = action.vehicleTypeLookup;
    return state;
}

//POST_HANDLE_VEHICLE_LICENSING_CHECKBOX_CHANGE
const postHandleVehicleLicensingCheckBoxChange = (state, action) => {
    state.licensingcheckBoxes[action.formElementId].elementConfig.checked = !state.licensingcheckBoxes[action.formElementId].elementConfig.checked;
    let checkBoxesWithoutSelectAll = { ...state.licensingcheckBoxes };
    delete checkBoxesWithoutSelectAll.select_all;
    if (action.formElementId === 'fleet_management') {
        if (state.licensingcheckBoxes['fleet_management'].elementConfig.checked) {
            state.licensingcheckBoxes['vehicle_doc'].elementConfig.checked = true;
        } else {
            state.licensingcheckBoxes['vehicle_doc'].elementConfig.checked = false;
        }
    }
    if (action.formElementId === 'vehicle_doc') {
        if (!state.licensingcheckBoxes['vehicle_doc'].elementConfig.checked) {
            if (state.licensingcheckBoxes['fleet_management'].elementConfig.checked) {
                state.licensingcheckBoxes['vehicle_doc'].elementConfig.checked = true;
            } else {
                state.licensingcheckBoxes['vehicle_doc'].elementConfig.checked = false;
            }
        }
    }
    if (action.formElementId === 'select_all') {
        if (state.licensingcheckBoxes['select_all'].elementConfig.checked) {
            Object.keys(state.licensingcheckBoxes).map(checkBox => {
                if (checkBox !== 'select_all') {
                    state.licensingcheckBoxes[checkBox].elementConfig.checked = true;
                }
            })
        } else {
            Object.keys(state.licensingcheckBoxes).map(checkBox => {
                if (checkBox !== 'select_all') {
                    state.licensingcheckBoxes[checkBox].elementConfig.checked = false;
                }
            })
        }
    }
    if (action.formElementId !== 'select_all') {
        state.licensingcheckBoxes['select_all'].elementConfig.checked = true;
        Object.keys(checkBoxesWithoutSelectAll).map(checkBox => {
            if (checkBoxesWithoutSelectAll[checkBox].elementConfig.checked === false) {
                state.licensingcheckBoxes['select_all'].elementConfig.checked = false;
            }
        })
    }
    return state;
}

// SET_DEFAULT_VALUE_TO_VEHICLE_UPLOAD_FORM
const setDefaultValueToVehicleUploadForm = (state, action) => {
    Object.keys(state.uploadVehicleForm).map(inputField => {
        state.uploadVehicleForm[inputField].errorStatus = false;
        state.uploadVehicleForm[inputField].value = '';
    })
    return state;
}
//SET_VEHICLE_TYPES
const setVehicleTypes = (state, action) => {
    state.addVehicleForm['vehicle_types'].menuItems = action.vehicleTypeMenuItems;
    return state;
}
//SET_UPLOAD_INPUT_CHANGE
// const setUploadInputChanges = (state, action) => {
//     if (action.data === '' && action.textField === 'mass_certification') {
//         state.addVehicleForm[action.textField].errorStatus = true;
//     } else {
//         state.addVehicleForm[action.textField].errorStatus = false;
//     }
//     state.addVehicleForm[action.textField].value = action.data;
//     state.massEventBase64String = action.base64;
//     return state;
// }
//SET_CHECKBOX_VALUE
const setCheckBoxValue = (state, action) => {
    // state.vehicleCheckBoxes[action.checkBoxId].elementConfig.checked = !state.vehicleCheckBoxes[action.checkBoxId].elementConfig.checked;
    return state;
}

// SUBMIT_VEHICLE_INFO
const submitVehicleInfo = (state, action) => {
    state.addedVehicleInformation = action.addedVehicleInformation;
    state.addVehicleLoaderStatus = action.addVehicleLoaderStatus;
    Object.keys(state.addVehicleForm).map((formField) => {
        if (formField !== 'mass_certification') {
            if (state.addVehicleForm[formField].value === '') {
                state.addVehicleForm[formField].errorStatus = true;
            }
        }

    })
    return state;
}
// VEHICLE_TEXTFIELD_CHANGE
const vehicleTextfieldChange = (state, action) => {
    // console.log("check", state.axleGroupForm[action.textFieldId], action.textFieldId)
    state.addVehicleForm[action.textFieldId].value = action.textFieldData;
    // state.axleGroupForm[action.textFieldId].value = action.textFieldData;
    if (action.textFieldData === '' && action.textFieldId !== 'vehicle_status') {
        state.addVehicleForm[action.textFieldId].errorStatus = true;
    }
    else {
        state.addVehicleForm[action.textFieldId].errorStatus = false;
    }
    // if (action.textFieldId === 'vehicle_steer' || 'vehicle_front' || 'vehicle_rear') {
    //     state.axleGroupForm[action.textFieldId].value = action.textFieldData;
    // }

    if (action.textFieldId = 'vehicle_status') {
        if (state.addVehicleForm['vehicle_status'].value === 'inactive') {
            state.addVehicleForm.vehicle_plate.disabledStatus = true;
            state.addVehicleForm.vehicle_state.disabledStatus = true;
            state.addVehicleForm.vehicle_types.disabledStatus = true;
            // state.addVehicleForm.mass_certification.disabledStatus = true;
            // Object.keys(state.vehicleCheckBoxes).map(checkbox => {
            //     state.vehicleCheckBoxes[checkbox].elementConfig.disabled = true;
            // })
            Object.keys(state.licensingcheckBoxes).map(checkBox => {
                state.licensingcheckBoxes[checkBox].elementConfig.disabled = true;
            })
        } else {
            state.addVehicleForm.vehicle_plate.disabledStatus = false;
            state.addVehicleForm.vehicle_state.disabledStatus = false;
            state.addVehicleForm.vehicle_types.disabledStatus = false;
            // state.addVehicleForm.mass_certification.disabledStatus = false;
            // Object.keys(state.vehicleCheckBoxes).map(checkbox => {
            //     state.vehicleCheckBoxes[checkbox].elementConfig.disabled = false;
            // })
            Object.keys(state.licensingcheckBoxes).map(checkBox => {
                state.licensingcheckBoxes[checkBox].elementConfig.disabled = false;
            })
        }

    }
    return state;
}
// VEHICLE_CHECKBOX_CHANGE
const vehicleCheckboxChange = (state, action) => {
    // state.vehicleCheckBoxes[action.checkBoxId].checkedStatus = !action.checkedStatus;
    return state;
}


//AXLE
const setAxleTextFieldData = (state, action) => {
    state.axleGroupForm[action.textFieldId].value = action.textFieldData;
    return state;
}
// VEHICLE_OPEN_DIALOG
const vehicleOpenDialog = (state, action) => {
    state.getLicenseFieldStatusLoader = action.getLicenseFieldStatusLoader;
    Object.keys(state.addVehicleForm).map(inputField => {
        state.addVehicleForm[inputField].errorStatus = false;
    })

    //Handle licensing checkboxes
    state.licensingcheckBoxes['mass_management'].elementConfig.checked = action.licensingStatus['mass_management'];
    state.licensingcheckBoxes['fleet_management'].elementConfig.checked = action.licensingStatus['fleet_management'];
    state.licensingcheckBoxes['vehicle_doc'].elementConfig.checked = action.licensingStatus['vehicle_doc'];
    state.licensingcheckBoxes['check_list'].elementConfig.checked = action.licensingStatus['check_list'];
    state.licensingcheckBoxes['trip_purpose'].elementConfig.checked = action.licensingStatus['trip_purpose'];
    state.licensingcheckBoxes['select_all'].elementConfig.checked = true;
    let licensingCheckBoxesWithoutSelectAll = { ...state.licensingcheckBoxes };
    delete licensingCheckBoxesWithoutSelectAll.select_all;
    Object.keys(licensingCheckBoxesWithoutSelectAll).map(checkBox => {
        if (!licensingCheckBoxesWithoutSelectAll[checkBox].elementConfig.checked) {
            state.licensingcheckBoxes['select_all'].elementConfig.checked = false;
            // break;
        }
    })


    state.massEventBase64String = '';
    state.editVehicleDialogOpen = true;
    state.mode = 'edit';
    // state.vehicleCheckBoxes.asset_exp.elementConfig.checked = action.data.asset_exp;
    // state.vehicleCheckBoxes.mass_exp.elementConfig.checked = action.data.mass_exp;
    // state.vehicleCheckBoxes.pre_exp.elementConfig.checked = action.data.pre_exp;
    // state.vehicleCheckBoxes.trip_exp.elementConfig.checked = action.data.trip_exp;
    state.addVehicleForm.vehicle_plate.value = action.data.vehicle_plate;
    if (action.data.vehicle_status) {
        state.addVehicleForm.vehicle_status.value = 'active';
    } else {
        state.addVehicleForm.vehicle_status.value = "inactive";
    }
    state.addVehicleForm.vehicle_state.value = action.data.vehicle_state;
    state.addVehicleForm.vehicle_types.value = action.data.vehicle_type;
    state.addVehicleForm.measurement_type.value = action.data.measurement_type;
    // if (action.data.document_extension !== '') {
    //     state.addVehicleForm.mass_certification.value = action.data.mass_certification;
    // } else {
    //     state.addVehicleForm.mass_certification.value = '';
    // }
    if ( action.data.axel_group) {
        if (action.data.axel_group.steer) {
            state.axleGroupForm['vehicle_steer'].value = action.data.axel_group.steer;
        }
        if (action.data.axel_group.front) {
            state.axleGroupForm['vehicle_front'].value = action.data.axel_group.front;
        }
        if (action.data.axel_group.rear) {
            state.axleGroupForm['vehicle_rear'].value = action.data.axel_group.rear;
        }
    }

    

    state.currentlyEditableVehicleId = action.data.vehicle_id;
    if (action.data.vehicle_status === false) {
        state.addVehicleForm.vehicle_plate.disabledStatus = true;
        state.addVehicleForm.vehicle_state.disabledStatus = true;
        state.addVehicleForm.vehicle_types.disabledStatus = true;
        // state.addVehicleForm.mass_certification.disabledStatus = true;
        // Object.keys(state.vehicleCheckBoxes).map(checkbox => {
        //     state.vehicleCheckBoxes[checkbox].elementConfig.disabled = true;
        // })
        Object.keys(state.licensingcheckBoxes).map(checkBox => {
            state.licensingcheckBoxes[checkBox].elementConfig.disabled = true;
        })

    } else {
        state.addVehicleForm.vehicle_plate.disabledStatus = false;
        state.addVehicleForm.vehicle_state.disabledStatus = false;
        state.addVehicleForm.vehicle_types.disabledStatus = false;
        // state.addVehicleForm.mass_certification.disabledStatus = false;
        // Object.keys(state.vehicleCheckBoxes).map(checkbox => {
        //     state.vehicleCheckBoxes[checkbox].elementConfig.disabled = false;
        // })
        Object.keys(state.licensingcheckBoxes).map(checkBox => {
            state.licensingcheckBoxes[checkBox].elementConfig.disabled = false;
        })
    }
    return state;
}

const vehicleCloseDialog = (state, action) => {
    state.editVehicleDialogOpen = false;
    state.mode = '';
    // Object.keys(state.vehicleCheckBoxes).map(checkbox => {
    //     state.vehicleCheckBoxes[checkbox].elementConfig.checked = false;
    // })
    Object.keys(state.addVehicleForm).map(inputField => {
        state.addVehicleForm[inputField].value = ''
    })
    return state;
}
const vehicleReducer = (state = initialState, action) => {
    let localState = Object.assign({}, state);
    switch (action.type) {
        case POST_HANDLE_VEHICLE_LICENSING_CHECKBOX_CHANGE: return postHandleVehicleLicensingCheckBoxChange(localState, action);
        case SET_DEFAULT_VALUE_TO_VEHICLE_UPLOAD_FORM: return setDefaultValueToVehicleUploadForm(localState, action);
        case POST_VEHICLE_UPLOADED_DATA: return postVehicleUploadedData(localState, action);
        case SET_INPUTHANDLER_UPLOAD_VEHICLE: return setInputhandlerUploadVehicle(localState, action);
        case SET_ADD_VEHICLE_INITIAL_STATE: return setAddVehicleInitialState(localState, action);
        case SET_UPDATE_VEHICLE_DATA: return setUpdateVehicleData(localState, action)
        // case OPEN_MASS_CERTIFICATION: return openMassCertification(localState, action);
        case SET_VEHICLES: return setVehicles(localState, action);
        case SET_VEHICLE_TYPES_FOR_LISTING: return setVehicleTypesForListing(localState, action);
        case SET_VEHICLE_TYPES: return setVehicleTypes(localState, action);
        // case SET_UPLOAD_INPUT_CHANGE: return setUploadInputChanges(localState, action);
        case SET_CHECKBOX_VALUE: return setCheckBoxValue(localState, action);
        case SUBMIT_VEHICLE_INFO: return submitVehicleInfo(localState, action);
        case VEHICLE_TEXTFIELD_CHANGE: return vehicleTextfieldChange(localState, action);
        case VEHICLE_AXLE_TEXTFIELD_CHANGE: return setAxleTextFieldData(localState, action);
        case VEHICLE_CHECKBOX_CHANGE: return vehicleCheckboxChange(localState, action);
        case VEHICLE_OPEN_DIALOG: return vehicleOpenDialog(localState, action);
        case VEHICLE_CLOSE_DIALOG: return vehicleCloseDialog(localState, action);
        case MEASUREMENT_TYPE_LIST:
            localState.addVehicleForm.measurement_type.menuItems = action.measurementtypelist;
            return localState;
        case GET_ALL_AXEL_GROUP:
            localState.axleGroupForm.vehicle_steer.menuItems = action.axlelist;
            localState.axleGroupForm.vehicle_front.menuItems = action.axlelist;
            localState.axleGroupForm.vehicle_rear.menuItems = action.axlelist;
            return localState;
        default:
            return state;
    }
}

export default vehicleReducer;