import {
    SET_MENU_ITEMS,
    SET_MENU_ITEMS_FOR_DISTRIBUTOR_SELECT,
    SET_SG_MENUS,
    SET_BUSINESS_SELECT_MENU,
    SET_BUSINESS_MENUS,
    SET_MENU_ITEMS_FOR_PARTNER_SELECT
  } from "../actions/ActionType";

  import { roles } from '../constants/roles';
  import { isPartnerRole } from '../utils/RoleHelper';

  //AFM Stat
  const initialState = {
    menuItems : {},
    roleMenuitems : []
  };

  const setMenuItems = (state, action) => {
    const childRole = localStorage.getItem('child_role');
    const role = localStorage.getItem('role');
    
    let menuItems = {};
    state.menuItems = {...action.menuItems};
    state.roleMenuitems = {...state.menuItems};
    if(childRole != null){

      if (childRole === roles.DISTRIBUTOR_ADMIN && role === roles.SG_ADMIN){
        menuItems['sg_portal'] = {
          "label" : "SG Portal",
          "menu_icon" : "supervised_user_circle",
          "pageowner" : Number(roles.DISTRIBUTOR_ADMIN),
          "urlpattern" : "/sgadminportal/distributors"
        };
      }

      if(childRole === roles.BUSINESS_ADMIN && (role === roles.SG_ADMIN || role === roles.DISTRIBUTOR_EDITOR)){
        menuItems['dist_portal'] = {
          "label" : "Reseller Portal",
          "menu_icon" : "supervised_user_circle",
          "pageowner" : Number(roles.BUSINESS_ADMIN),
          "urlpattern" : "/sgadminportal/distributorportal/"+localStorage.getItem('distributor_id')+"/businesses"
        };
      }

      Object.keys(state.roleMenuitems).map(menuItem => {
        if(state.roleMenuitems[menuItem].pageowner === Number(childRole) && state.roleMenuitems[menuItem].label !== ''){
          menuItems[menuItem] = state.roleMenuitems[menuItem];
        } 
      })

    } else {
      Object.keys(state.roleMenuitems).map(menuItem => {
        if(state.roleMenuitems[menuItem].pageowner === Number(role) && state.roleMenuitems[menuItem].label !== ''){
          menuItems[menuItem] = state.roleMenuitems[menuItem];
        }
      })
    }

    if(Object.keys(menuItems).length > 0){
      Object.keys(state.roleMenuitems).map(menuItem => {
        if(localStorage.getItem('partner_id') !== null){
          if((state.roleMenuitems[menuItem].urlpattern).indexOf(":partner_id") >= 0){
            state.roleMenuitems[menuItem].urlpattern = (state.roleMenuitems[menuItem].urlpattern).replace(":partner_id",localStorage.getItem('partner_id'))
          }
        }
      })
      state.roleMenuitems = menuItems;
    }

    if(Object.keys(state.menuItems).length > 0){
      Object.keys(state.menuItems).map(menuItem => {
        if(localStorage.getItem('partner_id') !== null){
          if((state.menuItems[menuItem].urlpattern).indexOf(":partner_id") >= 0){
            state.menuItems[menuItem].urlpattern = (state.menuItems[menuItem].urlpattern).replace(":partner_id",localStorage.getItem('partner_id'))
          }
        }
      })
    }

    if(Object.keys(state.roleMenuitems).length > 0){
      Object.keys(state.roleMenuitems).map(menuItem => {
        if(localStorage.getItem('distributor_id') !== null){
          if((state.roleMenuitems[menuItem].urlpattern).indexOf(":dist_id") >= 0){
            state.roleMenuitems[menuItem].urlpattern = (state.roleMenuitems[menuItem].urlpattern).replace(":dist_id",localStorage.getItem('distributor_id'))
          }
        }
      })
    }
    return state;
  }

  const setMenuItemsForDistributorSelect = (state, action) => {
    const role = localStorage.getItem('role');
    
    let menuItems = {};
    if(role === roles.SG_ADMIN ){
      menuItems['sg_portal'] = {
        "label" : "SG Portal",
        "menu_icon" : "supervised_user_circle",
        "pageowner" : Number(roles.DISTRIBUTOR_ADMIN),
        "urlpattern" : "/sgadminportal/partners"
      };
    }
    if(role === roles.PARTNER_ADMIN || role === roles.PARTNER_EDITOR){
      menuItems['sg_portal'] = {
        "label" : "Partner Portal",
        "menu_icon" : "supervised_user_circle",
        "pageowner" : Number(roles.DISTRIBUTOR_ADMIN),
        "urlpattern" : "/partnerportal/:partner_id/distributors"
      };
    }
    
    state.roleMenuitems = {
      ...state.menuItems,
      ...menuItems
    };
    
    Object.keys(state.roleMenuitems).map(menuItem => {
      if(localStorage.getItem('distributor_id') !== null){
        if((state.roleMenuitems[menuItem].urlpattern).indexOf(":dist_id") >= 0){
          state.roleMenuitems[menuItem].urlpattern = (state.roleMenuitems[menuItem].urlpattern).replace(":dist_id",localStorage.getItem('distributor_id'))
        }
      }
      if(localStorage.getItem('partner_id') !== null){
        if((state.roleMenuitems[menuItem].urlpattern).indexOf(":partner_id") >= 0){
          state.roleMenuitems[menuItem].urlpattern = (state.roleMenuitems[menuItem].urlpattern).replace(":partner_id",localStorage.getItem('partner_id'))
        }
      }
    })


    Object.keys(state.roleMenuitems).map(menuItem => {
      if(state.roleMenuitems[menuItem].pageowner === Number(roles.DISTRIBUTOR_ADMIN) && state.roleMenuitems[menuItem].label !== ''){
        menuItems[menuItem] = state.roleMenuitems[menuItem];
      }
    })

    if(Object.keys(menuItems).length > 0){
      state.roleMenuitems = menuItems;
    }
    return state;
  }

  const setMenuItemsForPartnerSelect = (state, action) => {
    const role = localStorage.getItem('role');
    const partnerId = localStorage.getItem('partner_id');
    
    let menuItems = {};
    if(role === roles.SG_ADMIN){
      menuItems['sg_portal'] = {
        "label" : "SG Portal",
        "menu_icon" : "supervised_user_circle",
        "pageowner" : Number(roles.DISTRIBUTOR_ADMIN),
        "urlpattern" : "/sgadminportal/partners"
      };
    }

    state.roleMenuitems = {...state.menuItems};

    if(partnerId){
      Object.keys(state.roleMenuitems).forEach(menuItem => {
          
        state.roleMenuitems[menuItem].urlpattern = (state.roleMenuitems[menuItem].urlpattern).replace(":partner_id", partnerId);
        
        if(isPartnerRole(state.roleMenuitems[menuItem].pageowner) && state.roleMenuitems[menuItem].label){
          menuItems[menuItem] = state.roleMenuitems[menuItem];
        }
      });
    }


    if(Object.keys(menuItems).length > 0){
      state.roleMenuitems = menuItems;
    }

    return state;
  }


  const setSGMenus = (state, action) => {
    let menuItems = {};
    state.roleMenuitems = {...state.menuItems};
    Object.keys(state.roleMenuitems).map(menuItem => {
      if(state.roleMenuitems[menuItem].pageowner === Number(roles.SG_ADMIN) && state.roleMenuitems[menuItem].label !== ''){
        menuItems[menuItem] = state.roleMenuitems[menuItem];
      }
    })
    
    if(Object.keys(menuItems).length > 0){
      state.roleMenuitems = menuItems;
    }
    return state;
  }

  const setBusinessSelectMenu = (state, action) => {
    const role = localStorage.getItem('role');

    let menuItems = {};
    if(role === roles.SG_ADMIN || role === roles.DISTRIBUTOR_ADMIN || role === roles.PARTNER_ADMIN){
      menuItems['dist_portal'] = {
        "label" : "Reseller Portal",
        "menu_icon" : "supervised_user_circle",
        "pageowner" : Number(roles.BUSINESS_ADMIN),
        "urlpattern" : state.menuItems['business_list'].urlpattern
      };
    }
    state.roleMenuitems = {...state.menuItems};
    Object.keys(state.roleMenuitems).map(menuItem => {
      if(localStorage.getItem('business_id') !== null){
        if((state.roleMenuitems[menuItem].urlpattern).indexOf(":buss_id") >= 0){
          state.roleMenuitems[menuItem].urlpattern = (state.roleMenuitems[menuItem].urlpattern).replace(":buss_id", localStorage.getItem('business_id'));
        }
      }
    })
    Object.keys(state.roleMenuitems).map(menuItem => {
      if(state.roleMenuitems[menuItem].pageowner === Number(roles.BUSINESS_ADMIN) && state.roleMenuitems[menuItem].label !== ''){
        menuItems[menuItem] = state.roleMenuitems[menuItem];
      }
    })
    if(Object.keys(menuItems).length > 0){
      state.roleMenuitems = menuItems;
    }
    return state;
  }

  const setBusinessMenus = (state, action) => {
    const role = localStorage.getItem('role');
    
    let menuItems = {};
    if(role === roles.SG_ADMIN || role === roles.DISTRIBUTOR_ADMIN || role === roles.DISTRIBUTOR_EDITOR || isPartnerRole(role)){
      menuItems['dist_portal'] = {
        "label" : "Reseller Portal",
        "menu_icon" : "supervised_user_circle",
        "pageowner" : 5,
        "urlpattern" : state.menuItems['business_list'].urlpattern
      };
    }
    state.roleMenuitems = {...state.menuItems};
    Object.keys(state.roleMenuitems).map(menuItem => {
      if(localStorage.getItem('business_id') !== null){
        if((state.roleMenuitems[menuItem].urlpattern).indexOf(":buss_id") >= 0){
          state.roleMenuitems[menuItem].urlpattern = (state.roleMenuitems[menuItem].urlpattern).replace(":buss_id",localStorage.getItem('business_id'))
        }
        if(state.roleMenuitems[menuItem].submenu){
          Object.keys(state.roleMenuitems[menuItem].submenu).map(subMenuItem => {
            if((state.roleMenuitems[menuItem].submenu[subMenuItem].urlpattern).indexOf(":buss_id") >= 0){
              state.roleMenuitems[menuItem].submenu[subMenuItem].urlpattern = (state.roleMenuitems[menuItem].submenu[subMenuItem].urlpattern).replace(":buss_id",localStorage.getItem('business_id'));
            }
          })
        }
      }
    })
    Object.keys(state.roleMenuitems).map(menuItem => {
      if(state.roleMenuitems[menuItem].pageowner === Number(roles.BUSINESS_ADMIN) && state.roleMenuitems[menuItem].label !== ''){
        menuItems[menuItem] = state.roleMenuitems[menuItem];
      }
    })
    if(Object.keys(menuItems).length > 0){
      state.roleMenuitems = menuItems;
    }
    return state;
  }

  const sidebarReducer = (state = initialState, action) => {
    let localState = Object.assign({}, state);
    switch (action.type) {
        case SET_BUSINESS_MENUS : return setBusinessMenus(localState, action);
        case SET_BUSINESS_SELECT_MENU : return setBusinessSelectMenu(localState, action);
        case SET_SG_MENUS : return setSGMenus(localState, action);
        case SET_MENU_ITEMS_FOR_DISTRIBUTOR_SELECT : return setMenuItemsForDistributorSelect(localState, action);
        case SET_MENU_ITEMS_FOR_PARTNER_SELECT : return setMenuItemsForPartnerSelect(localState, action);
        case SET_MENU_ITEMS : return setMenuItems(localState, action);
        default:
            return state;
    }
  };
  
  export default sidebarReducer;