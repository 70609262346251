import React, { Component } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import CardHeader from '@material-ui/core/CardHeader';
import FormGroup from '@material-ui/core/FormGroup';
import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux';

import Input from "../../components/UI/Input/Input";

import {
    onSubmit,
    initializeSGAdminUserComponent,
    onFieldChange    
} from '../../actions/SGAdminUsers';

const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing.unit * 2,
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
  card: {
    minWidth: 275,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

class AddSGAdminUser extends Component {
    componentDidMount(){
        this.props.initializeSGAdminUserComponent();
    }
    
    render(){
        const { classes } = this.props;
        
        let { 
            sgAdminUserForm
        } = this.props.sgAdminUsers;

        const formElementArray = [];

        for(let key in sgAdminUserForm){
            formElementArray.push({
                id : key,
                config : sgAdminUserForm[key]
            })
        }

        let sgAdminUserInputForm = (
            <FormGroup row>
                <Grid container spacing={24}>
                    {
                        formElementArray.map(formElement => {
                            return(
                                <Grid item xs={12} sm = {4} key = {formElement.id}>
                                    <Input 
                                        key = {formElement.id}
                                        name = {formElement.config.elementConfig.label}
                                        elementType={formElement.config.elementType}
                                        elementConfig={formElement.config.elementConfig}
                                        // value={formElement.config.value} //TODO: Review - Clearing the value entered in this class not others though.
                                        menuItems = {formElement.config.menuItems}
                                        error = {formElement.config.errorStatus}
                                        helperText = {formElement.config.errorMessage}
                                        changed={(event) => this.props.onFieldChange(formElement.id, event)}
                                        maxLength = {formElement.config.maxLength}
                                        disabledStatus = {formElement.config.disabledStatus}
                                    />
                                </Grid>
                            )
                        })
                    }
                </Grid>
            </FormGroup>
        )
        
        return(
            <div className = "container">
                <Card 
                    // className={classes.card} 
                    className = "pl-3 pr-3 pt-3 mb-5"
                >
                <CardHeader
                    title="ADD SG ADMIN USER"
                />
                    <CardContent>
                    {
                        <div>
                            {sgAdminUserInputForm}
                            <Grid 
                                container 
                                spacing={24} 
                                className = "pt-2"
                                direction="row" 
                                justify="flex-end"
                                alignItems="center"
                            >
                                <Grid item>
                                {
                                    <div>
                                        <Button
                                            variant="contained" 
                                            color="primary" 
                                            className = "mr-3"
                                            disabled={false}
                                            onClick = {() => {this.props.onSubmit(sgAdminUserForm, this.props)}}
                                        >
                                            Add
                                        </Button>
                                    </div>
                                }
                                </Grid> 
                            </Grid>
                        </div>
                    }
                    </CardContent>
                </Card>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return{
        sgAdminUsers: state.sgAdminUsers
    };
};

const mapDispatchToProps = dispatch => {
    return{
        initializeSGAdminUserComponent : () => {
            return dispatch(initializeSGAdminUserComponent())
        },

        onSubmit: (sgAdminUserForm, props) => {
            return dispatch(onSubmit(sgAdminUserForm, props))
        },

        
        onFieldChange : (formElementId, event) => {
            return dispatch(onFieldChange(formElementId, event))
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps) (AddSGAdminUser);