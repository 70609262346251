import React, { Component } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import FormGroup from '@material-ui/core/FormGroup';
import CircularProgress from '@material-ui/core/CircularProgress';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import Input from "../../../../components/UI/Input/Input";

import {
    handleAddDistributorBillingFormField,
    addDistributorAdminOnSubmit,
    addDistributorAdminBillingBack,
    addDistributorAdminOnUpdate, postAddDistributorAdminOnSubmit, postAddDistributorAdminOnSubmitAfterCloseToastr
} from '../../../../actions/Distributor';
import axios from "../../../../axios";
import {toast} from "react-toastify";

class Billing extends Component{
    render(){
        let editDistributorSearchFoundStatus = (window.location.href).search('editdistributor');
        let editProfileSearchFoundStatus = (window.location.href).search('editprofile');

        let {
            billingForm,
            addDistributorReqParams,
            addDistributorAdminLoaderStatus
        } = this.props.distributorState;
        let{
            menuItems
        } = this.props.sidebarState;
        const formElementArray = [];
        for(let key in billingForm){
            formElementArray.push({
                id : key,
                config : billingForm[key]
            })
        }
        let form = (
            <FormGroup row>
                <Grid container spacing={24}>
                    {
                        formElementArray.map(formElement => (
                            <Grid item xs={12} sm = {6} key = {formElement.id}>
                                <Input
                                    key = {formElement.id}
                                    name = {formElement.config.elementConfig.label}
                                    elementType={formElement.config.elementType}
                                    elementConfig={formElement.config.elementConfig}
                                    value={formElement.config.value}
                                    menuItems = {formElement.config.menuItems}
                                    error = {formElement.config.errorStatus}
                                    helperText = {formElement.config.errorMessage}
                                    changed={(event) => this.props.handleAddDistributorBillingFormField(formElement.id, event)}
                                    touched={(event) => this.props.handleAddDistributorBillingFormField(formElement.id, event )}
                                    maxLength={formElement.config.maxLength}
                                />
                            </Grid>
                        ))
                    }
                </Grid>
                <Grid
                    container
                    spacing={24}
                    className = "pt-2"
                    direction="row"
                    justify="flex-end"
                    alignItems="center"
                >
                    {
                        editDistributorSearchFoundStatus != -1 || editProfileSearchFoundStatus != -1 ?
                        addDistributorAdminLoaderStatus ? <CircularProgress /> :
                        <Grid item className = "d-flex justify-content-end">
                            <Button
                                variant="contained"
                                color="default"
                                className = "mr-3"
                                disabled={false}
                                onClick = {() => {this.props.addDistributorAdminBillingBack()}}
                            >
                                Back
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                disabled={false}
                                onClick = {() => {this.props.addDistributorAdminOnUpdate(billingForm, addDistributorReqParams, this.props, editProfileSearchFoundStatus)}}
                            >
                                Update
                            </Button>
                        </Grid> :
                        addDistributorAdminLoaderStatus ? <CircularProgress /> :
                        <Grid item className = "d-flex justify-content-end">
                            <Button
                                variant="contained"
                                color="default"
                                className = "mr-3"
                                disabled={false}
                                onClick = {() => {this.props.addDistributorAdminBillingBack()}}
                            >
                                Back
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                disabled={false}
                                onClick = {() => {this.props.addDistributorAdminOnSubmit(billingForm, addDistributorReqParams, this.props)}}
                            >
                                Submit
                            </Button>
                        </Grid>
                    }
                </Grid>
            </FormGroup>
        )
        return(
            <Card  className = "pl-3 pr-3 pt-3 mb-3">
                <Grid container spacing={24} className = "pt-2">
                    <Grid item xs={12} sm = {10}>
                        <CardHeader title="Billing"/>
                    </Grid>
                </Grid>
                <CardContent>
                    {form}
                </CardContent>
            </Card>
        )
    }
}

const mapStateToProps = state => {
    return{
        distributorState : state.distributor,
        sidebarState : state.sidebarState,
        profileState : state.profile
    }
}

const mapDispatchToProps = dispatch => {
    return{
        handleAddDistributorBillingFormField : (formFieldId, event) => {
            return dispatch(handleAddDistributorBillingFormField(formFieldId, event))
        },
        addDistributorAdminOnSubmit : (addDistributorReqParams, props) => {
            const partnerId = localStorage.getItem('partner_id');
            let reqURL = `/partners/${partnerId}/distributors`;

            return axios.post(reqURL, addDistributorReqParams).then(response => {
                toast.success("Reseller Created Successfully", {
                    containerId: 'toastMsg',
                    onClose : () => {
                        props.history.push('/sgadminportal/distributors');
                        dispatch(postAddDistributorAdminOnSubmitAfterCloseToastr())
                    }
                });
            }).catch(error => {
                if(error.response.status == 403){
                    localStorage.clear();
                    toast.error("Session Expired ! Please login again", {containerId: 'toastMsg'});
                    window.location.href = "/";
                }else{
                    toast.error(error.response.data.message, {containerId: 'toastMsg'});
                }
            })
        },
        addDistributorAdminOnUpdate : (billingForm, addDistributorReqParams, props, url,editProfileSearchFoundStatus) => {
            return dispatch(addDistributorAdminOnUpdate(billingForm, addDistributorReqParams, props, url,editProfileSearchFoundStatus))
        },
        addDistributorAdminBillingBack : () => {
            return dispatch(addDistributorAdminBillingBack())
        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Billing));
