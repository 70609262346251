import {
    SET_FATIGUE_RULE_TEXT_FIELD,
    SET_FATIGUE_RULE_LIST,
    SUBMIT_FATIGUE_RULE_INFO,
    UPDATE_FATIGUE_RULE_INFO,
    SET_DEFAULT_VALUE_TO_FATIGUE_RULE_COMPONENT
  } from "../actions/ActionType";
  import axios from '../axios';
  import { toast } from 'react-toastify';
  
  export const initializeFatigueRuleComponent = () => {
    return (dispatch) => {
      return dispatch(setDefaultValueToFatigueRuleComponent())
    }
  }

  export const setDefaultValueToFatigueRuleComponent = () => {
    return{
      type : SET_DEFAULT_VALUE_TO_FATIGUE_RULE_COMPONENT
    }
  }
  
  /********************************************************************************* */
  export const handleFatigueRuleTextFields = (textField, event) => {
    return dispatch => {
      return dispatch(setfatigueRuleTextFields(textField, event));
    };
  };
  
  export const setfatigueRuleTextFields = (textField, event) => {
    return {
      type: SET_FATIGUE_RULE_TEXT_FIELD,
      textField: textField,
      data: event.target.value
    };
  };
  
  /********************************************************************************************** */ 
  /**************************************************************************************** */
  
  export const onSubmit = (fatigueRuleForm, props) => {
  return (dispatch) => {
    let showLoaderStatus = false;
    let fieldValidationStatus = true;
    Object.keys(fatigueRuleForm).map((textField) => {
        if(fatigueRuleForm[textField].value  == ''){
            fieldValidationStatus = false;
        }
    })
    dispatch(setSubmittedData(showLoaderStatus)); 
    if(fieldValidationStatus){
        showLoaderStatus = true;
        dispatch(setSubmittedData(showLoaderStatus)); 
        let addReq = {};
        addReq["description"] = fatigueRuleForm['fatigue_rule'].value;
        addReq["current"] = true;
        return axios.post('/sg/fatiguerulegroup', addReq)
        .then(response => {
                showLoaderStatus = false;
                toast.success("Fatigue Rule Group added successfully", {
                                containerId: 'toastMsg',
                                onClose :  () => props.history.push('/sgadminportal/fatiguerules')
                                }
                            );
                dispatch(setSubmittedData(showLoaderStatus)); 
        })
        .catch(error => {
            if(error.response.status == 403){
                showLoaderStatus = false;
                localStorage.clear();
                // toast.error("Session Expired ! Please login again", {containerId: 'toastMsg'});
                window.location.href = "/";
            }else{
                showLoaderStatus = false;
                toast.error(error.response.data.message, {containerId: 'toastMsg'});
                dispatch(setSubmittedData(showLoaderStatus)); 
            }
        })
    }
  
  }
  
  }
  
  export const setSubmittedData = (showLoaderStatus) => {
  return {
    type: SUBMIT_FATIGUE_RULE_INFO,
    showLoaderStatus : showLoaderStatus
  }
  }
  /************************************************************************/
  export const getFatigueRuleList = () => {
    let fatigueRuleGroup = {};
    let fatigueRuleList = [];
    return (dispatch) => {
        return axios.get('/sg/fatiguerulegroup')
        .then(response => {
                for(let i = 0; i < response.data.data.length; i++){
                  fatigueRuleGroup = {};
                  fatigueRuleGroup['fatiguerulegroupid'] = response.data.data[i].fatiguerulegroupid;
                  fatigueRuleGroup['description'] = response.data.data[i].description;
                  fatigueRuleGroup['current'] = response.data.data[i].current;
                  fatigueRuleList.push(fatigueRuleGroup);
                }
                dispatch(setList(fatigueRuleList));
        })
        .catch(error => {
            if(error.response.status == 403){
                localStorage.clear();
                window.location.href = "/";
            }
        })
    }
  }
  
  export const setList = (fatigueRuleList) => {
    return{
        type : SET_FATIGUE_RULE_LIST,
        fatigueRuleList : fatigueRuleList
    }
  }
  
  /************************************************************************************************************* */ 
  export const editFatigueRule = (fatigueRuleInfo, fatigueListrows) => {
    let reqURL = '/sg/fatiguerulegroup/' + fatigueRuleInfo.fatiguerulegroupid;
    let updateReq = {};
    updateReq['description'] = fatigueRuleInfo.description;
    updateReq['current'] = fatigueRuleInfo.current=="true"?true:false;
    return (dispatch) => {
        return axios.put(reqURL, updateReq)
        .then(response => {
                const updateIndex = fatigueListrows.findIndex(d => {
                    return d.fatiguerulegroupid === fatigueRuleInfo.fatiguerulegroupid;
                });
                fatigueListrows[updateIndex].description = fatigueRuleInfo.description;
                fatigueListrows[updateIndex].current = fatigueRuleInfo.current;
                toast.success("Fatigue Rule Group Updated Successfully", {containerId: 'toastMsg'});
                dispatch(updateInfo(fatigueListrows));
        })
        .catch(error => {
             if(error.response.status == 403){
                localStorage.clear();
                toast.error(
                            "Session Expired ! Please login again", 
                              {
                                containerId: 'toastMsg',
                                onclose : () => window.location.href = "/"
                              }
                            );
             }else{
                toast.error(error.response.data.message, {containerId: 'toastMsg'});
             }
  
             dispatch(updateInfo(fatigueListrows));
        })
    }
  }
  export const updateInfo = (fatigueListrows) => {
    return{
        type : UPDATE_FATIGUE_RULE_INFO,
        fatigueListrows : fatigueListrows
    }
  }