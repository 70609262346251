import React, { Component } from 'react';
import MaterialTable from 'material-table';
import Paper from '@material-ui/core/Paper';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';

import {
    getBusinessUser,
    updateBusinessUser,
    deleteBusinessUser
} from '../../../actions/Business';

class ListBusinessUser extends Component{
    componentDidMount(){
        this.props.getBusinessUser();
    }
    navigateToBusinessUser = (url) => {
        this.props.history.push(url);
    }
    render(){
        let {
            businessUserColumns,
            businessUserInformations,
            listBusinessUserLoaderStatus
        } = this.props.businessState;

        let{
            menuItems
        } = this.props.sidebarState;
        return(
            <div className = "container mt-3 mb-3">
                <Paper>
                    <div className = "d-flex">
                        <div className = "d-flex flex-wrap align-content-center mr-auto">
                            <h4 className = "mt-3 ml-2 text-uppercase">User List</h4>
                        </div>
                        <div className = "d-flex flex-wrap align-content-center">
                        <Button 
                            variant="contained" 
                            color="default"
                            className = "mr-3"
                            onClick = {() => this.props.history.push(menuItems['business_user_upload'].urlpattern)}
                        >
                            <i className="material-icons" style = {{paddingRight : "0.5rem"}}>
                                cloud_upload
                            </i>
                            Upload Business Users
                        </Button>
                            <Button 
                                variant="contained" 
                                color="primary"
                                onClick = {() => this.navigateToBusinessUser(menuItems['business_user_create'].urlpattern)}
                                className = "mr-2"
                            >
                                <i className="material-icons" style = {{paddingRight : "0.5rem"}}>
                                    add_circle_outline
                                </i>
                                Add User
                            </Button>
                        </div>
                    </div>
                    {
                        listBusinessUserLoaderStatus ? 
                        <div style = {{height : "30rem"}} className = "d-flex justify-content-center align-items-center">
                            <CircularProgress color = "primary" />
                        </div>
                        :
                        <MaterialTable
                            localization={{
                                // header: {
                                //     actions: 'Action'
                                // }
                                body: {
                                    editRow: {
                                        deleteText: 'Are you sure you want to delete this user?'
                                    },
                                    editTooltip : "Edit",
                                    deleteTooltip : "Delete",
                                }
                            }}
                            columns={businessUserColumns}
                            data={businessUserInformations}
                            title=""
                            editable={{
                                onRowUpdate: (newData, oldData) =>
                                    new Promise((resolve, reject) => {
                                        if((newData.business_email).match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)){
                                            resolve(this.props.updateBusinessUser(newData, oldData.business_email, businessUserInformations))
                                        }else{
                                            toast.error("Please enter an valid email", {containerId: 'toastMsg'});
                                            reject();
                                        }
                                }),
                                onRowDelete: oldData =>
                                    new Promise((resolve, reject) => {
                                        resolve(this.props.deleteBusinessUser(oldData, oldData.business_email, businessUserInformations))
                                }),
                            }}
                        />
                    }
                </Paper>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return{
        businessState : state.business,
        sidebarState : state.sidebarState
    }
}
const mapDispatchToProps = dispatch => {
    return{
        getBusinessUser : () => {
            return dispatch(getBusinessUser())
        },
        updateBusinessUser : (rowData,email, businessUserInformations) => {
            return dispatch(updateBusinessUser(rowData,email, businessUserInformations))
        },
        deleteBusinessUser : (rowData,email, businessUserInformations) => {
            return dispatch(deleteBusinessUser(rowData,email, businessUserInformations))
        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ListBusinessUser));