import {
  SET_VEHCILE_TYPE_TEXT_FIELD,
  SET_VEHICLETYPE_LIST,
  SUBMIT_VEHICLETYPE_INFO,
  UPDATE_VEHICLETYPE_INFO,
  SET_DEFAULT_VALUE_TO_VEHICLE_TYPES
} from "../actions/ActionType";
import TextField from "@material-ui/core/TextField";
import React from 'react';

const initialState = {
  vehicleTypeForm : {
    vechile_types : {
      elementType : 'input',
      elementConfig : {
        type : 'text',
        label : 'Enter Vehicle Types'
      },
      value : '',
      errorStatus : false,
      errorMessage : "Enter Vehicle Types",
      maxLength:256
    }
  },
  vehicletypesListcols: [
    { title: "Vehicle Type ID", field: "vehicletypeid",
    editComponent: props => (
      <TextField
        type="text"
        value={props.value}
        disabled = {true}
      />
    ),
    cellStyle: {
      width : "18%",
    }, 
  },
    { title: "Description", 
      field: "description",
      editComponent: props => {
        initialState.activeAxelConfigDescription = props.value;
        return(
          <TextField
            type="text"
            multiline
            value={props.value}
            onChange = {e => props.onChange(e.target.value)}
            inputProps={{ maxLength: 256 }}
            style = {{width: "100%"}} 
          />
        )
      },
      cellStyle: {
        wordWrap: "break-word",
        maxWidth : '200px',
      },
  
  },
    {
      title: "Status",
      field: "current",
      lookup: {
        true: "Active",
        false: "Inactive"
      },
      cellStyle: {
        width : "10%",
      },
    }
  ],
  vehicletypesListrows: [],

  //Loader Status
  vehicleTypeListLoaderStatus : false
};


const vehicletypes = (state = initialState, action) => {
  let localState = Object.assign({}, state);
  switch (action.type) {
    case SET_DEFAULT_VALUE_TO_VEHICLE_TYPES :
      Object.keys(localState.vehicleTypeForm).map((inputField) => {
        localState.vehicleTypeForm[inputField].value = '';
        localState.vehicleTypeForm[inputField].errorStatus = false;
      })
      return localState;
    case SET_VEHCILE_TYPE_TEXT_FIELD:
      if(action.data === ''){
        localState.vehicleTypeForm[action.textField].errorStatus = true;
      }else{
        localState.vehicleTypeForm[action.textField].errorStatus = false;
      }
      localState.vehicleTypeForm[action.textField].value = action.data;
      return localState;
    case SET_VEHICLETYPE_LIST :
        localState.vehicleTypeListLoaderStatus = action.vehicleTypeListLoaderStatus;
        localState.vehicletypesListrows = action.vehicleTypeList;
        return localState;
    case SUBMIT_VEHICLETYPE_INFO:
        localState.vehicleTypeForm = action.vehicleTypeForm;
        localState.showLoaderStatus = action.showLoaderStatus;
        localState.vehicletypesListrows = action.vehicletypesListrows;
        return localState;
    case UPDATE_VEHICLETYPE_INFO:
          localState.vehicletypesListrows = action.vehicletypesListrows;
          return localState;
    default:
      return localState;
  }
};

export default vehicletypes;
