import{
    SET_MENU_ITEMS,
    SET_MENU_ITEMS_FOR_DISTRIBUTOR_SELECT,
    SET_SG_MENUS,
    SET_BUSINESS_SELECT_MENU,
    SET_BUSINESS_MENUS,
    SET_MENU_ITEMS_FOR_PARTNER_SELECT
} from "./ActionType";
import axios from '../axios';
import{
    getActulaURL
} from "../utils/Helper";
import { roles } from "../constants/roles";
import { history } from '../index';


export const getBusinessMenus = (props) => {
    return (dispatch) => {
            dispatch(setBusinessMenus());
    }
}
export const setBusinessMenus = () => {
    return{
        type : SET_BUSINESS_MENUS
    }
}
export const businessSelect = (props,row, businessId, url) => {
    return (dispatch) => {
        localStorage.setItem('child_role', Number(roles.BUSINESS_ADMIN));
        localStorage.setItem('business_id', businessId);
        localStorage.setItem('lastSelectedBusinessId', row._id);
        let actualURL = getActulaURL(url);
        props.history.push(actualURL);
        dispatch(setBusinessMenus());
    }
}

export const setBusinessSelectMenu = () => {
    return{
        type : SET_BUSINESS_SELECT_MENU
    }
}
export const getSGMenu = () => {
    return (dispatch) => {
        localStorage.removeItem('child_role');
        dispatch(setSGMenus())
    }
}

export const setSGMenus = () => {
    return{
        type : SET_SG_MENUS
    }
}

export const getDistributorMenu = () => { //TODO seems unused execept for action it dispatches
    return (dispatch) => {
        if(localStorage.getItem('child_role') !== undefined){
            localStorage.setItem('child_role', Number(roles.DISTRIBUTOR_ADMIN));
            let reqURL = "/user/"+localStorage.getItem('child_role')+"/menuitems";
            return axios.get(reqURL)
            .then(response => {
                const menuItems = response.data.data;
                dispatch(setDistributorMenus(menuItems));
            })
            .catch(error => {
                dispatch(setDistributorMenus({}));
            })
        }
    }
}

export const getPartnerMenu = () => {
    return (dispatch) => {
        if(localStorage.getItem('child_role') !== undefined){
            localStorage.setItem('child_role', Number(roles.PARTNER_ADMIN));
            let reqURL = "/user/"+localStorage.getItem('child_role')+"/menuitems";
            return axios.get(reqURL)
            .then(response => {
                const menuItems = response.data.data;
                dispatch(setDistributorMenus(menuItems));
            })
            .catch(error => {
                dispatch(setDistributorMenus({}));
            })
        }
    }
}


export const distributorSelect = (distributorId, url) => {
    
    return (dispatch) => {
        localStorage.setItem('child_role', Number(roles.PARTNER_ADMIN));
        localStorage.setItem('distributor_id', distributorId);
        let actualURL = getActulaURL(url);
        history.push(actualURL);
        dispatch(setDistributorMenus());
    }
}

export const setDistributorMenus = (menuItems) => {
    return {
        type : SET_MENU_ITEMS_FOR_DISTRIBUTOR_SELECT,
        menuItems : menuItems
    }
}

export const partnerSelect = (partnerId) => {
    return (dispatch) => {
        localStorage.setItem('partner_id', partnerId);
        let url = `/sgadminportal/partnerportal/${partnerId}/distributors`;
        window.location.href = url;
    }
}

export const setPartnerMenus = () => {
    return{
        type : SET_MENU_ITEMS_FOR_PARTNER_SELECT
    }
}


export const getMenuItems = () => {
    return (dispatch) => {
        let menuItems = [];
        let reqURL = "/user/"+localStorage.getItem('role')+"/menuitems";
        return axios.get(reqURL)
        .then(response => {
            menuItems = response.data.data;
            dispatch(setMenuItems(menuItems))
        })
        .catch(error => {
            dispatch(setMenuItems(menuItems))
        })
    }
}
export const setMenuItems = (menuItems) => {
    return{
        type : SET_MENU_ITEMS,
        menuItems : menuItems
    }
}