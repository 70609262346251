import React, { Component } from 'react';
import MaterialTable from 'material-table';
import {connect} from "react-redux";

import SGPaperContainer from '../../../components/UI/PaperContainer/PaperContainer';
import SGContainer from '../../../components/UI/Container/SGContainer';
import SGLoader from "../../../components/UI/Loader/SGLoader";
import {
    getVehicles
} from "../../../actions/Checklist";
import SGMaterialTable from "../../../components/UI/Table/SGMaterialTable";
class Vehicle extends Component{
    componentDidMount(){
        this.props.getVehicles();
    }
    render(){
        let{
            vehicleListColumn,
            vehicleInformations,
            getVehicleInformationLoaderStatus
        } = this.props.checkListState;
        let{
            menuItems
        } = this.props.sidebarState;
        return(
            <SGContainer>
                <SGPaperContainer
                    title = "Vehicle List"
                >
                {
                    getVehicleInformationLoaderStatus ? <SGLoader />  :
                    <SGMaterialTable
                        columns={vehicleListColumn}
                        data={vehicleInformations}
                        title=""
                        actions={[
                            {
                                icon : 'near_me',
                                tooltip : 'Assign Checklist',
                                onClick : (event, rowData) => {
                                    localStorage.setItem('vehicle_id', rowData.vehicle_id);
                                    this.props.history.push(menuItems['business_checklist'].submenu['assigned_checklitem_list'].urlpattern)
                                }
                            }
                        ]}
                    />
                }

                </SGPaperContainer>
            </SGContainer>
        )
    }
}

const mapStateToProps = state => {
    return{
        checkListState : state.checkList,
        sidebarState : state.sidebarState
    }
}

const mapDispatchToProps = dispatch => {
    return{
        getVehicles : () => {
            return dispatch(getVehicles())
        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Vehicle);