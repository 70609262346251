import React, { Component } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import CardHeader from '@material-ui/core/CardHeader';
import FormGroup from '@material-ui/core/FormGroup';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';

import Input from "../../../components/UI/Input/Input";
import{
    distributorUserInputHandler,
    onSubmitDistributorUser,
    initializeAddDistributorUserForm
} from '../../../actions/Distributor';
class AddDistributorUser extends Component{
    componentDidMount(){
        this.props.initializeAddDistributorUserForm();
    }
    render(){
        let {
            distributorUserForm,
            distributorUserLoaderStatus
        } = this.props.distributorState;
        let{
            menuItems
        } = this.props.sidebarState;
        const formElementArray = [];
        for(let key in distributorUserForm){
            formElementArray.push({
                id : key,
                config : distributorUserForm[key]
            })
        }
        let form = (
            <FormGroup row>
                <Grid container spacing={24}>
                    {
                        formElementArray.map(formElement => (
                                <Grid item xs={12} sm = {6} key = {formElement.id}>
                                    <Input 
                                        key = {formElement.id}
                                        name = {formElement.config.elementConfig.label}
                                        elementType={formElement.config.elementType}
                                        elementConfig={formElement.config.elementConfig}
                                        value={formElement.config.value}
                                        menuItems = {formElement.config.menuItems}
                                        error = {formElement.config.errorStatus}
                                        helperText = {formElement.config.errorMessage}
                                        changed={(event) => this.props.distributorUserInputHandler(formElement.id, event )}
                                        touched = {(event) => this.props.distributorUserInputHandler(formElement.id, event )}
                                        maxLength={formElement.config.maxLength}
                                    />
                                </Grid>
                            
                        ))
                    }
                </Grid>
                <Grid container spacing={24} className = "pt-2">
                    <Grid item xs={10}>
                    </Grid>
                    <Grid item xs={2} className = "d-flex justify-content-end">
                        {
                            distributorUserLoaderStatus ? <CircularProgress color = "primary" /> :
                            <Button
                                variant="contained" 
                                color="primary" 
                                disabled={false}
                                onClick = {() => {this.props.onSubmitDistributorUser(distributorUserForm, this.props, menuItems['distributor_user_list'].urlpattern)}}
                            >
                                Submit
                            </Button>
                        }
                        

                    </Grid>
                </Grid>
            </FormGroup>
        )
        return(
            <div className = "container">
                <Card className = "pl-3 pr-3 pt-3">
                        <div>
                            <Grid container spacing={24} className = "pt-2">
                                <Grid item xs={12} sm = {10}>
                                    <CardHeader title="Add User"/>
                                </Grid>
                            </Grid>
                        </div>
                    <CardContent>
                        {form}
                    </CardContent>
                </Card>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        distributorState : state.distributor,
        sidebarState : state.sidebarState
    }
}

const mapDispatchToProps = dispatch => {
    return{
        distributorUserInputHandler : (inputFiledId, event) => {
            return dispatch(distributorUserInputHandler(inputFiledId, event))
        },
        onSubmitDistributorUser : (distributorUserForm, props, url) => {
            return dispatch(onSubmitDistributorUser(distributorUserForm, props, url))
        },
        initializeAddDistributorUserForm : () => {
            return dispatch(initializeAddDistributorUserForm())
        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AddDistributorUser));