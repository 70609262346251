import {
  SUBMIT_CONTRACT_INFO,
  POST_HANDLE_ITEM_CHECKBOX_CHANGE,
  SET_ITEM_CHECKBOX_LIST,
  SET_CONTRACT_ID
} from "../actions/ActionType";
import axios from '../axios';
import { toast } from 'react-toastify';

//Handle Checkbox

export const handleitemsCheckBoxChange = (formElementId) => {
  return (dispatch) => {
    return dispatch(posthandleitemsCheckBoxChange(formElementId))
  }
}
export const posthandleitemsCheckBoxChange = (formElementId) => {
  return {
    type: POST_HANDLE_ITEM_CHECKBOX_CHANGE,
    formElementId: formElementId
  }
}

//GET CONTRACT ITEMS
export const getContractItems = (itemsCheckbox) => {
  let ContractSetupLoaderStatus = true;
  let itemsCheckboxMenuItems = {};
  return (dispatch) => {
    dispatch(setitemsCheckbox(itemsCheckboxMenuItems, ContractSetupLoaderStatus));
    let businessID = localStorage.getItem('business_id');
    let reqURL = "/businesses/" + businessID + "/contracts";
    return axios.get(reqURL)
      .then(response => {
        ContractSetupLoaderStatus = false;
        for (let i = 0; i < response.data.data.itemcodedata.length; i++) {
          itemsCheckboxMenuItems[response.data.data.itemcodedata[i].name] = {
            elementType: 'checkbox',
            elementConfig: {
              name: response.data.data.itemcodedata[i].name,
              itemcode: response.data.data.itemcodedata[i].itemcode,
              checked: response.data.data.itemcodedata[i].status,
              color: 'primary',
              label: response.data.data.itemcodedata[i].label
            },
          }

        }
        dispatch(setitemsCheckbox(itemsCheckboxMenuItems, ContractSetupLoaderStatus))
      })
      .catch(error => {
        ContractSetupLoaderStatus = false;
        console.log('Error occurred in catch', error)
        if (error.response.status == 403) {
          localStorage.clear();
          window.location.href = "/";
        }
        dispatch(setitemsCheckbox(itemsCheckboxMenuItems, ContractSetupLoaderStatus))
      })
  }
}


export const setitemsCheckbox = (itemsCheckboxMenuItems, ContractSetupLoaderStatus) => {
  return {
    type: SET_ITEM_CHECKBOX_LIST,
    itemsCheckboxlist: itemsCheckboxMenuItems,
    ContractSetupLoaderStatus: ContractSetupLoaderStatus
  }
}


//GET CONTRACT ID
export const getContractId = (contractId) => {
  return (dispatch) => {
    let contractId = '';
    let businessID = localStorage.getItem('business_id');
    let reqURL = "/businesses/" + businessID + "/contracts";
    return axios.get(reqURL)
      .then(response => {
        contractId = response.data.data.contractId
        dispatch(setContractId(contractId))
      })
      .catch(error => {
        console.log('Error occurred in catch', error)
        if (error.response.status == 403) {
          localStorage.clear();
          window.location.href = "/";
        }
        dispatch(setContractId(contractId))

      })
  }
}

export const setContractId = (contractId) => {
  return {
    type: SET_CONTRACT_ID,
    contractId: contractId
  }
}

//Submit Data

export const onSubmit = (ContractSetupInfo, props) => {
  return (dispatch) => {
    let showLoaderStatus = false;
    let fieldValidationStatus = true;

    Object.keys(ContractSetupInfo.itemsCheckbox).map((Checkbox) => {
      if (ContractSetupInfo.itemsCheckbox[Checkbox].value == '') {
        fieldValidationStatus = false;
        ContractSetupInfo.itemsCheckbox[Checkbox].errorStatus = true;
      }
    })
    dispatch(setSubmittedData(
      showLoaderStatus,
      ContractSetupInfo.itemsCheckbox
    )
    );
    if (fieldValidationStatus) {
      showLoaderStatus = true;
      dispatch(setSubmittedData(
        showLoaderStatus,
        ContractSetupInfo.itemsCheckbox
      )
      );
      let checkboxArray = []
      let addCheckboxdata = {};
      let businessID = localStorage.getItem('business_id');
      let reqURL = "/businesses/" + businessID + "/contracts/" + ContractSetupInfo.contractId;
      for (let key in ContractSetupInfo.itemsCheckbox) {
        checkboxArray.push({
          id: key,
          name: ContractSetupInfo.itemsCheckbox[key].elementConfig.name,
          itemcode: ContractSetupInfo.itemsCheckbox[key].elementConfig.itemcode,
          status: ContractSetupInfo.itemsCheckbox[key].elementConfig.checked
        })
      }
      addCheckboxdata["itemscodedata"] = checkboxArray
      addCheckboxdata["contractId"] = ContractSetupInfo.contractId;
      // console.log("putdata", addCheckboxdata)
      return axios.put(reqURL, addCheckboxdata)
        .then(response => {
          showLoaderStatus = false;
          toast.success("Contract Created Successfully", {
            containerId: 'toastMsg',
          },
            setTimeout(() => {
              window.location.href = "/businessportal/" + localStorage.getItem('business_id') + "/contractsetup"
            }, 5000)

          );
          dispatch(setSubmittedData(
            showLoaderStatus,
            ContractSetupInfo.itemsCheckbox
          )
          );
        })
        .catch(error => {
          if (error.response.status == 403) {
            showLoaderStatus = false;
            localStorage.clear();
            // toast.error("Session Expired ! Please login again", {containerId: 'toastMsg'});
            window.location.href = "/";
          } else {
            showLoaderStatus = false;
            toast.error(error.response.data.message, { containerId: 'toastMsg' });
            dispatch(setSubmittedData(
              showLoaderStatus,
              ContractSetupInfo.itemsCheckbox
            )
            );
          }
        })
    }

  }

}

export const setSubmittedData = (showLoaderStatus, itemsCheckbox) => {
  return {
    type: SUBMIT_CONTRACT_INFO,
    showLoaderStatus: showLoaderStatus,
    itemsCheckbox: itemsCheckbox
  }
}

