import {
  SET_MASSVARIABLE_TYPE_TEXT_FIELD,
  SET_MASSVARIABLETYPE_LIST,
  SUBMIT_MASSVARIABLETYPE_INFO,
  UPDATE_MASSVARIABLETYPE_INFO,
  SET_DEFAULT_VALUE_TO_MASSVARIABLE_TYPES
} from "../actions/ActionType";
import TextField from "@material-ui/core/TextField";
import React from 'react';

const initialState = {
  massVariableTypeForm : {
    massVariable_types : {
      elementType : 'input',
      elementConfig : {
        type : 'text',
        label : 'Enter Mass Variable Types'
      },
      value : '',
      errorStatus : false,
      errorMessage : "Enter Mass Variable Types",
      maxLength:256
    }
  },
  massVariabletypesListcols: [
    { title: "MassVariable Type ID", field: "_id",
    editComponent: props => (
      <TextField
        type="text"
        value={props.value}
        disabled = {true}
      />
    ),
    cellStyle: {
      width : "18%",
    }, 
  },
    { title: "Mass Variable Type", 
      field: "mm_massvariable_types",
      editComponent: props => {
        initialState.activeAxelConfigDescription = props.value;
        return(
          <TextField
            type="text"
            multiline
            value={props.value}
            onChange = {e => props.onChange(e.target.value)}
            inputProps={{ maxLength: 256 }}
            style = {{width: "100%"}} 
          />
        )
      },
      cellStyle: {
        wordWrap: "break-word",
        maxWidth : '200px',
      },
  
  },
    {
      title: "Status",
      field: "status",
      lookup: {
        true: "Active",
        false: "Inactive"
      },
      cellStyle: {
        width : "10%",
      },
    }
  ],
  massVariabletypesListrows: [],

  //Loader Status
  massVariableTypeListLoaderStatus : false
};


const massVariabletypes = (state = initialState, action) => {
  let localState = Object.assign({}, state);
  switch (action.type) {
    case SET_DEFAULT_VALUE_TO_MASSVARIABLE_TYPES :
      Object.keys(localState.massVariableTypeForm).map((inputField) => {
        localState.massVariableTypeForm[inputField].value = '';
        localState.massVariableTypeForm[inputField].errorStatus = false;
      })
      return localState;
    case SET_MASSVARIABLE_TYPE_TEXT_FIELD:
      if(action.data === ''){
        localState.massVariableTypeForm[action.textField].errorStatus = true;
      }else{
        localState.massVariableTypeForm[action.textField].errorStatus = false;
      }
      localState.massVariableTypeForm[action.textField].value = action.data;
      return localState;
    case SET_MASSVARIABLETYPE_LIST :
        localState.massVariableTypeListLoaderStatus = action.massVariableTypeListLoaderStatus;
        localState.massVariabletypesListrows = action.massVariableTypeList;
        return localState;
    case SUBMIT_MASSVARIABLETYPE_INFO:
        localState.massVariableTypeForm = action.massVariableTypeForm;
        localState.showLoaderStatus = action.showLoaderStatus;
        localState.massVariabletypesListrows = action.massVariabletypesListrows;
        return localState;
    case UPDATE_MASSVARIABLETYPE_INFO:
          localState.massVariabletypesListrows = action.massVariabletypesListrows;
          return localState;
    default:
      return localState;
  }
};

export default massVariabletypes;
