import React, { Component } from 'react';
import Grid from "@material-ui/core/Grid";
import ProfileDetails from "./ProfileDetails";

class AdminProfile extends Component{
    render() {
        return (
            <div className = "container">
                <Grid container spacing={24}>
                    <Grid item xs={1} sm = {3}>
                    </Grid>
                    <Grid item xs={11} sm = {6}>
                        <ProfileDetails/>
                    </Grid>
                    <Grid item xs={1} sm = {3}>
                    </Grid>
                </Grid>
            </div>
        )
    }
}

export default AdminProfile
