import React, { Component } from 'react';
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import FormGroup from "@material-ui/core/FormGroup";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import CardHeader from "@material-ui/core/CardHeader";
import MaterialTable from 'material-table';
import CircularProgress from '@material-ui/core/CircularProgress';
import {connect} from "react-redux";

import Input from "../../components/UI/Input/Input";
import {
    inputHandlerUploadVehicle,
    onvehicleUploadSubmit,
    initializeVehicleUploadForm
} from "../../actions/vehicle";
import {
    convertArrayToCSVDownload,
} from "../../utils/Helper";
import SGMaterialTable from '../../components/UI/Table/SGMaterialTable';

class BulkUploadVehicle extends Component{
    componentDidMount(){
        this.props.initializeVehicleUploadForm();
    }
    downloadCSVFile = () =>{
        let data = [
            [
                'vehicle_type', 
                'vehicle_state', 
                'vehicle_plate', 
                'is_mass_cert_exp', 
                'is_pre_start_exp', 
                'is_asset_exp', 
                'is_trip_exp',
                'MM',
                'FM',
                'VD',
                'CL',
                'TP'
            ]
          ];
          convertArrayToCSVDownload(data, 'vehicle_bulk');

    }
    render(){
        let {
            bulkUploadVehicles,
            uploadVehicleForm,
            vehicleUploadStatusList,
            vehicleUploadStatusTableColumns,
            vehicleBulkUploadVehicleLoaderStatus
        } = this.props.vehicleState;
        const formElementArray = [];
        for(let key in uploadVehicleForm){
          formElementArray.push({
            id : key,
            config : uploadVehicleForm[key]
          })
        }
        let form = (
            <div>
                <FormGroup row className = "pl-3 pr-1">
                    <Grid container spacing={24}>
                        <Grid item xs={10} sm = {10}>
                            <form>
                                {
                                    formElementArray.map(formElement => (
                                        <Input 
                                        key = {formElement.id}
                                        elementType={formElement.config.elementType}
                                        elementConfig={formElement.config.elementConfig}
                                        value={formElement.config.value}
                                        error = {formElement.config.errorStatus}
                                        helperText = {formElement.config.errorMessage}
                                        changed={(event) => this.props.inputHandlerUploadVehicle(formElement.id, event )}
                                        //   touched = {(event) => this.props.inputHandlerVehicleType(formElement.id, event )}
                                        />
                                    ))
                                }
                            </form>
                        </Grid> 
                        <Grid item xs={2} sm = {2}>
                        {
                            vehicleBulkUploadVehicleLoaderStatus ? <CircularProgress color = "primary" /> :
                            <Button 
                                variant="contained" 
                                color = "primary"
                                className = "mt-4"
                                onClick = {() => this.props.onvehicleUploadSubmit(bulkUploadVehicles, uploadVehicleForm)}
                                >
                                Submit
                            </Button>
                        }
                        </Grid>     
                    </Grid>
                </FormGroup>
            </div>
        )
        return(
            <div>
            <div className = "container">

                <Grid container spacing={24}>
                    <Grid item xs={1} sm={2} />
                        <Grid item xs={11} sm={8}>
                        <Card className = "pl-3 pr-3 pt-3">
                            <Grid container spacing={24}>
                                <Grid item xs={6} sm = {6}>
                                    <CardHeader
                                        title="Upload Vehicles"
                                    />
                                </Grid>                                
                                <Grid item xs={6} sm = {6} style = {{marginTop : "-1rem", display : "flex", justifyContent : "flex-end"}}>

                                    <Button 
                                        variant="contained" 
                                        color = "default"
                                        className = "mt-4 mb-4"
                                        onClick = {this.downloadCSVFile}
                                    >
                                        <i className="material-icons" style = {{paddingRight : "0.6rem"}}>
                                            cloud_download
                                        </i>
                                        Download sample csv
                                    </Button>
                                </Grid>
                            </Grid>

                            <CardContent>
                                {form}
                            </CardContent>
                            <CardActions>
                                
                            </CardActions>
                        </Card>
                        {
                            vehicleUploadStatusList.length > 0 ?
                            <div className = "mt-3">
                                <SGMaterialTable
                                    columns={vehicleUploadStatusTableColumns}
                                    data={vehicleUploadStatusList}
                                    title=""
                                />
                            </div>: null
                        }
                    </Grid>
                    <Grid item xs={1} sm={2} />
                </Grid>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return{
        vehicleState : state.vehicle
    }
}

const mapDispatchToProps = dispatch => {
    return{
        inputHandlerUploadVehicle : (formElementId, event) => {
            return dispatch(inputHandlerUploadVehicle(formElementId, event))
        },
        onvehicleUploadSubmit : (bulkUploadVehicles, uploadVehicleForm) => {
            return dispatch(onvehicleUploadSubmit(bulkUploadVehicles, uploadVehicleForm))
        },
        initializeVehicleUploadForm : () => {
            return dispatch(initializeVehicleUploadForm())
        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(BulkUploadVehicle);