import {
  SET_ACCESSLEVEL_TEXT_FIELD,
  SUBMIT_ACCESSLEVEL_TEXT_FIELD,
  SET_ACCESSLEVEL_LIST,
  SUBMIT_ACCESSLEVEL_INFO,
  UPDATE_ACCESSLEVEL_INFO,
  SET_DEFAULT_VALUE_TO_ACCESS_LEVEL
} from "../actions/ActionType";
import TextField from "@material-ui/core/TextField";
import React from 'react';

// Vehicle List State
const initialState = {
  accessLevelForm : {
    access_level : {
      elementType : 'input',
      elementConfig : {
        type : 'text',
        label : 'Access Level Description'
      },
      value : '',
      errorStatus : false,
      errorMessage : "Enter Vehicle Types",
      maxLength:256
    }
  },
  accessLevelCols: [
    { title: "Access Level ID", 
      field: "accesslevelid",
    editComponent: props => (
      <TextField
        type="text"
        value={props.value}
        disabled = {true}
      />
    ),
    cellStyle: {
      width : "18%",
    }, 
   },
    { title: "Description", 
      field: "description",
    
    editComponent: props => {
      initialState.activeAxelConfigDescription = props.value;
      return(
        <TextField
          type="text"
          multiline
          value={props.value}
          onChange = {e => props.onChange(e.target.value)}
          inputProps={{ maxLength: 256 }}
          style = {{width: "100%"}} 
        />
      )
    },
    cellStyle: {
      wordWrap: "break-word",
      maxWidth : '200px',
    },
  },
    {
      title: "Status",
      field: "current",
      lookup: {
        true: "Active",
        false: "Inactive"
      },
      cellStyle: {
        width : "10%",
      },
    }
  ],
  accessLevelRows: [],
};

//SET_DEFAULT_VALUE_TO_ACCESS_LEVEL Reducer
const setDefaultValueToAccessLevel = (state, action) => {
  Object.keys(state.accessLevelForm).map((inputField) =>{
    state.accessLevelForm[inputField].value = '';
    state.accessLevelForm[inputField].errorStatus = false;
  })
  return state;
}

//SET_ACCESSLEVEL_TEXT_FIELD Reducer
const setAccessLevelTextField = (state, action) => {
  if(action.data === ''){
    state.accessLevelForm[action.textField].errorStatus = true;
  }else{
    state.accessLevelForm[action.textField].errorStatus = false;
  }
  state.accessLevelForm[action.textField].value = action.data;
  return state;
}
//SUBMIT_ACCESSLEVEL_TEXT_FIELD Reducer
const submitAccessLevelTextField = (state, action) => {
  return state;
}

//SET_ACCESSLEVEL_LIST Reducer
const setAccessLevelList = (state, action) => {
  state.accessLevelRows = action.vehicleTypeList;
  return state;
}
//SUBMIT_ACCESSLEVEL_INFO Reducer
const submitAccessLevelInfo = (state, action) => {
  Object.keys(state.accessLevelForm).map((textField) => {
      if(state.accessLevelForm[textField].value  == ''){
        state.accessLevelForm[textField].errorStatus = true;
      }else{
        state.accessLevelForm[textField].errorStatus = false;
      }
  })
  state.showLoaderStatus = action.showLoaderStatus;
  return state;
}

//UPDATE_ACCESSLEVEL_INFO Reducer
const updateAcccessLevelInfo = (state, action) => {
  state.accessLevelRows = action.accessLevelRows;
  return state;
}

const accessLevelReducer = (state = initialState, action) => {
  let localState = Object.assign({}, state);
  switch (action.type) {
    case SET_DEFAULT_VALUE_TO_ACCESS_LEVEL : return setDefaultValueToAccessLevel(localState, action);
    case SET_ACCESSLEVEL_TEXT_FIELD: return setAccessLevelTextField(localState, action);
    case SUBMIT_ACCESSLEVEL_TEXT_FIELD: return submitAccessLevelTextField(localState, action);
    case SET_ACCESSLEVEL_LIST : return setAccessLevelList(localState, action);
    case SUBMIT_ACCESSLEVEL_INFO: return submitAccessLevelInfo(localState, action);
    case UPDATE_ACCESSLEVEL_INFO: return updateAcccessLevelInfo(localState, action);
    default:
      return localState;
  }
};

export default accessLevelReducer;
