import {
  SET_EXTERNAL_DRIVER_INPUT_FIELD,
  SET_EXTERNAL_DRIVER_SUBMITED_DATA,
  SET_DEFAULT_VALUE_TO_SEARCH_EXTERNAL_DRIVER,
  SET_ADD_ED_RECORD_INPUT_HANDLER,
  SET_SUBMITED_ED_RECORD,
  SET_EXTERNAL_DRIVER_RECORDS,
  SET_ES_LIST,
  SET_ES_TYPES,
  SET_ED_RECORD_OPEN_DIALOG_STATUS,
  SET_EDIT_ED_RECORD_CLOSE_DIALOG,
  SET_UPDATE_ED_RECORD,
  SET_ED_RECORD_DELETE,
  OPEN_EXTERNAL_DRIVER_RECORD,
  SET_DEFAULT_VALUE_TO_ADD_ED_FORM
  } from "../actions/ActionType";
  import {
    formatDate,
    convertFormDDMMYYYToDateObject
  } from '../utils/Helper';
  const initialState = {
    addEDRecordForm : {
      es_type : {
        elementType : 'select',
        elementConfig : {
          type : 'text',
          label : 'External System Types'
        },
        value : '',
        errorStatus : false,
        errorMessage : "Enter External System Type",
        menuItems : {
          'select_es_type' : {
              name : "Select External System Type",
              value : ''
          }
        }
      },
    },
    edInformations : [],
    editEDRecordDialogStatus : false,
    activeEDRecordForEdit : '',
    showEDRecordListLoaderStatus : false,
    showEDRecordSubmitLoaderStatus : false,
    showEDRecordUpdateLoaderStatus : false,
    esTypeLookUp : {}
};

  const setDefaultValueToAddEDForm = (state, action) => {
    state.addEDRecordForm['es_type'].value = '';

    Object.keys(state.addEDRecordForm).map(inputField => {
      state.addEDRecordForm[inputField].errorStatus = false;
    })

    return state;
  }

  const openExternalDriverRecord = (state, action) => {
    return state;
  }

  const setUpdateEDRecord = (state, action) => {
    let updatedRecord = action.updatedData;
    if(updatedRecord !== undefined){
      const updatedEDRecordIndex = state.edInformations.findIndex(d => {
          return d.recordId === updatedRecord.recordId;
      });
      state.edInformations[updatedEDRecordIndex].es_type = updatedRecord.es_type;
      state.edInformations[updatedEDRecordIndex].recordId = updatedRecord.recordId;
    }

    if(!action.showEDRecordUpdateLoaderStatus){
      state.editEDRecordDialogStatus = false;
    }
    state.showEDRecordUpdateLoaderStatus = action.showEDRecordUpdateLoaderStatus;
    return state;
  }

  const setEditEDRecordCloseDialog = (state, action) => {
    state.editEDRecordDialogStatus = false;
    return state;
  }

  const setEDRecordOpenDialogStatus = (state, action) => {
    Object.keys(state.addEDRecordForm).map(inputField => {
      state.addEDRecordForm[inputField].errorStatus = false;
    })
    state.addEDRecordForm['es_type'].value = action.edRecordData['es_type'];
    state.activeEDRecordForEdit = action.edRecordData['recordId']
    state.editEDRecordDialogStatus = true;
    return state;
  }

  
  const setEDRecordDelete = (state, action) => {
    state.edInformations = action.edInformations;
    return state;
  }

  const setESTypes = (state, action) => {
    state.esTypeLookUp = action.lookUp;
    return state;
  }

  const setESList = (state, action) => {
    state.addEDRecordForm['es_type'].menuItems = action.esTypesMenuItems;
    return state;
  }

  const setExternalDriverRecords = (state, action) => {
    state.showEDRecordListLoaderStatus = action.showEDRecordListLoaderStatus;
    state.edInformations = action.edInformations;
    return state;
  }
  
  const setSubmitedEDRecord = (state, action) => {
    state.showEDRecordSubmitLoaderStatus = action.showEDRecordSubmitLoaderStatus;
    Object.keys(state.addEDRecordForm).map((inputField) => {
      if((state.addEDRecordForm[inputField].value === '' || state.addEDRecordForm[inputField].value === null) && !state.addEDRecordForm[inputField].disabledStatus){
        state.addEDRecordForm[inputField].errorStatus = true;
      }else{
        state.addEDRecordForm[inputField].errorStatus = false;
      }
    })
    return state;
  }
  const setAddEDRecordInputHandler = (state, action) => {
    state.addEDRecordForm[action.inputFieldId].value = action.data;
    if(state.addEDRecordForm[action.inputFieldId].value === ''){
      state.addEDRecordForm[action.inputFieldId].errorStatus = true;
    }else{
      state.addEDRecordForm[action.inputFieldId].errorStatus = false;
    } 
    return state;
  }
  const externalDriverReducer = (state = initialState, action) => {
    let localState = Object.assign({}, state);
    switch (action.type) {
      case SET_DEFAULT_VALUE_TO_ADD_ED_FORM : return setDefaultValueToAddEDForm(localState, action);
      case OPEN_EXTERNAL_DRIVER_RECORD : return openExternalDriverRecord(localState, action);
      case SET_UPDATE_ED_RECORD : return setUpdateEDRecord(localState, action);
      case SET_EDIT_ED_RECORD_CLOSE_DIALOG: return setEditEDRecordCloseDialog(localState, action);
      case SET_ED_RECORD_OPEN_DIALOG_STATUS: return setEDRecordOpenDialogStatus(localState, action);
      case SET_ED_RECORD_DELETE: return setEDRecordDelete(localState, action);
      case SET_ES_TYPES : return setESTypes(localState, action);
      case SET_ES_LIST: return setESList(localState, action);
      case SET_EXTERNAL_DRIVER_RECORDS : return setExternalDriverRecords(localState, action);
      case SET_SUBMITED_ED_RECORD : return setSubmitedEDRecord(localState, action);
      case SET_ADD_ED_RECORD_INPUT_HANDLER : return setAddEDRecordInputHandler(localState, action);
      default:
        return localState;
    }
  };
  
  export default externalDriverReducer;