export const convertToJSON = file => {

  return new Promise((resolve,reject) => {
    let fileReader = new FileReader();
    let jsonOutput;
    fileReader.onload = function(fileLoadedEvent) {
        jsonOutput = fileLoadedEvent.target.result;

        resolve(JSON.parse(jsonOutput));  
    };
    fileReader.onerror = function(error){
      reject(error);
    }
    fileReader.readAsText(file);
  })
}


export const convertCSVToJSON = (file) =>{
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        var result = [];
        let validCSVStatus = true;
        reader.onload = (event) => {
            const file = event.target.result;
            const allLines = file.split(/\r\n|\n/);
            let headers=allLines[0].split(",");
            for(var i=1;i<allLines.length-1;i++){
                var obj = {};
                var currentline=allLines[i].split(",");
                for(var j=0;j<headers.length;j++){
                    if(headers[j] === "LightRidged"){
                        if(currentline[j] === "TRUE"){
                            currentline[j] = true;
                        }else{
                            currentline[j] = false;
                        }
                    }
                    if(headers[j] === "MediumRidged"){
                        if(currentline[j] === "TRUE"){
                            currentline[j] = true;
                        }else{
                            currentline[j] = false;
                        }
                    }
                    if(headers[j] === "HeavyRidged"){
                        if(currentline[j] === "TRUE"){
                            currentline[j] = true;
                        }else{
                            currentline[j] = false;
                        }
                    }
                    if(headers[j] === "MultiCombination"){
                        if(currentline[j] === "TRUE"){
                            currentline[j] = true;
                        }else{
                            currentline[j] = false;
                        }
                    }
                    if(headers[j] === "Car"){
                        if(currentline[j] === "TRUE"){
                            currentline[j] = true;
                        }else{
                            currentline[j] = false;
                        }
                    }
                    if(headers[j] === "Motorcycle"){
                        if(currentline[j] === "TRUE"){
                            currentline[j] = true;
                        }else{
                            currentline[j] = false;
                        }
                    }
                    if(headers[j] === "Marine"){
                        if(currentline[j] === "TRUE"){
                            currentline[j] = true;
                        }else{
                            currentline[j] = false;
                        }
                    }
                    if(headers[j] === "afm"){
                        if(currentline[j] === "TRUE"){
                            currentline[j] = true;
                        }else{
                            currentline[j] = false;
                        }
                    }
                    if(headers[j] === "bfm"){
                        if(currentline[j] === "TRUE"){
                            currentline[j] = true;
                        }else{
                            currentline[j] = false;
                        }
                    }
                    if(headers[j] === "HeavyCombination"){
                        if(currentline[j] === "TRUE"){
                            currentline[j] = true;
                        }else{
                            currentline[j] = false;
                        }
                    }
                    if(headers[j] === "EWD"){
                      if(currentline[j] === "TRUE"){
                          currentline[j] = true;
                      }else{
                          currentline[j] = false;
                      }
                  }
                //   if(headers[j] === "BUSINESSFORMS"){
                //     if(currentline[j] === "TRUE"){
                //         currentline[j] = true;
                //     }else{
                //         currentline[j] = false;
                //     }
                // }
                    
                    obj[headers[j]] = currentline[j];
                }
                for(let k = 0; k  < currentline.length; k++){
                  if(validCSVStatus){
                    if(currentline[k] === ''){
                      validCSVStatus = false;
                    }
                  }
                }
                result.push(obj);
            }
            if(validCSVStatus && result.length > 0){
              resolve(result);
            }else if(result.length === 0){
              reject("No record found");
            }
            else{
              reject("CSV is not valid");
            }
        };
    
        reader.onerror = (event) => {
            console(event.target.error.name);
        };
    
        reader.readAsText(file);
    })
}

export const convertCSVToJSONUser = (file) =>{
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        var result = [];
        let validCSVStatus = true;
        reader.onload = (event) => {
            const file = event.target.result;
            const allLines = file.split(/\r\n|\n/);
            let headers=allLines[0].split(",");
            for(var i=1;i<allLines.length-1;i++){
                var obj = {};
                var currentline=allLines[i].split(",");
          
                for(var j=0;j<headers.length;j++){
                    if(headers[j] === "accesslevel"){
                      currentline[j] = Number(currentline[j])
                    }
                    obj[headers[j]] = currentline[j];
                }
                for(let k = 0; k  < currentline.length; k++){
                  if(validCSVStatus){
                    if(currentline[k] === ''){
                      validCSVStatus = false;
                    }
                  }
                }
                result.push(obj);
            }
            if(validCSVStatus && result.length > 0){
              resolve(result);
            }else if(result.length === 0){
              reject("No record found");
            }
            else{
              reject("CSV is not valid");
            }
        };
    
        reader.onerror = (event) => {
            console(event.target.error.name);
        };
    
        reader.readAsText(file);
    })
}

export const convertVehicleCSVToJSON = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        var result = [];
        let validCSVStatus = true;
        reader.onload = (event) => {
            const file = event.target.result;
            const allLines = file.split(/\r\n|\n/);
            let headers=allLines[0].split(",");
            for(var i=1;i<allLines.length;i++){
                var obj = {};
                var currentline=allLines[i].split(",");
          
                for(var j=0;j<headers.length;j++){
                    if(headers[j] === "vehicle_status"){
                        if(currentline[j] === "TRUE"){
                            currentline[j] = true;
                        }else{
                            currentline[j] = false;
                        }
                    }
                    if(headers[j] === "is_asset_exp"){
                        if(currentline[j] === "TRUE"){
                            currentline[j] = true;
                        }else{
                            currentline[j] = false;
                        }
                    }
                    if(headers[j] === "is_mass_cert_exp"){
                        if(currentline[j] === "TRUE"){
                            currentline[j] = true;
                        }else{
                            currentline[j] = false;
                        }
                    }
                    if(headers[j] === "is_pre_start_exp"){
                        if(currentline[j] === "TRUE"){
                            currentline[j] = true;
                        }else{
                            currentline[j] = false;
                        }
                    }
                    if(headers[j] === "is_trip_exp"){
                        if(currentline[j] === "TRUE"){
                            currentline[j] = true;
                        }else{
                            currentline[j] = false;
                        }
                    }
                    if(headers[j] === "MM"){
                        if(currentline[j] === "TRUE"){
                            currentline[j] = true;
                        }else{
                            currentline[j] = false;
                        }
                    }
                    if(headers[j] === "FM"){
                      if(currentline[j] === "TRUE"){
                          currentline[j] = true;
                      }else{
                          currentline[j] = false;
                      }
                    }
                    if(headers[j] === "VD"){
                      if(currentline[j] === "TRUE"){
                          currentline[j] = true;
                      }else{
                          currentline[j] = false;
                      }
                    }
                    if(headers[j] === "CL"){
                      if(currentline[j] === "TRUE"){
                          currentline[j] = true;
                      }else{
                          currentline[j] = false;
                      }
                    }
                    if(headers[j] === "TP"){
                      if(currentline[j] === "TRUE"){
                          currentline[j] = true;
                      }else{
                          currentline[j] = false;
                      }
                    }
                    obj[headers[j]] = currentline[j];
                }
                for(let k = 0; k  < currentline.length; k++){
                  if(validCSVStatus){
                    if(currentline[k] === ''){
                      validCSVStatus = false;
                    }
                  }
                }
                result.push(obj);
            }
            if(validCSVStatus && result.length > 0){
              resolve(result);
            }else if(result.length === 0){
              reject("No record found");
            }
            else{
              reject("CSV is not valid");
            }
        };
    
        reader.onerror = (event) => {
            console(event.target.error.name);
        };
    
        reader.readAsText(file);
    });
}

export const downloadAFMBFMSFMJSON = (fileName) => {
    let text = `
      {
        "rulesetName": "Standard Hours - 2 UP Driver - Heavy Vehicle - updated version 1.0a",
        "rules": [
          {
            "period": {
              "days": 0,
              "hours": 5,
              "minutes": 30,
              "inclusive": false
            },
            "maximumWork": {
              "days": 0,
              "hours": 5,
              "minutes": 15,
              "inclusive": false
            },
            "break": [
              {
                "restBreak": {
                  "minimumTime": {
                    "days": 0,
                    "hours": 0,
                    "minutes": 0,
                    "inclusive": false
                  },
                  "continuousBreak": {
                    "days": 0,
                    "hours": 0,
                    "minutes": 15,
                    "inclusive": false
                  },
                  "numberOfContinuousBreaks": 0,
                  "stationary": false,
                  "sleeperBerthWhileMoving": false,
                  "descriptionContainsAtLeast": false
                },
                "nightBreaks": 0,
                "consecutiveNightBreaks": 0,
                "breachList": {
                  "criticalBreach": [
                    {
                      "continuousStationaryBreakBreach": {
                        "from": {
                          "days": 0,
                          "hours": 0,
                          "minutes": 0,
                          "inclusive": false
                        },
                        "to": {
                          "days": 0,
                          "hours": 0,
                          "minutes": 0,
                          "inclusive": false
                        }
                      },
                      "perBlock": false,
                      "restBreakBreach": {
                        "restBreakInterval": {
                          "from": {
                            "days": 0,
                            "hours": 0,
                            "minutes": 0,
                            "inclusive": false
                          },
                          "to": {
                            "days": 0,
                            "hours": 0,
                            "minutes": 0,
                            "inclusive": false
                          }
                        },
                        "continuousBreak": {
                          "days": 0,
                          "hours": 0,
                          "minutes": 0,
                          "inclusive": false
                        }
                      }
                    }
                  ],
                  "minorBreach": [
                    {
                      "continuousStationaryBreakBreach": {
                        "from": {
                          "days": 0,
                          "hours": 0,
                          "minutes": 0,
                          "inclusive": false
                        },
                        "to": {
                          "days": 0,
                          "hours": 0,
                          "minutes": 0,
                          "inclusive": false
                        }
                      },
                      "perBlock": false,
                      "restBreakBreach": {
                        "restBreakInterval": {
                          "from": {
                            "days": 0,
                            "hours": 0,
                            "minutes": 0,
                            "inclusive": false
                          },
                          "to": {
                            "days": 0,
                            "hours": 0,
                            "minutes": 0,
                            "inclusive": false
                          }
                        },
                        "continuousBreak": {
                          "days": 0,
                          "hours": 0,
                          "minutes": 0,
                          "inclusive": false
                        }
                      }
                    }
                  ],
                  "severeBreach": [
                    {
                      "continuousStationaryBreakBreach": {
                        "from": {
                          "days": 0,
                          "hours": 0,
                          "minutes": 0,
                          "inclusive": false
                        },
                        "to": {
                          "days": 0,
                          "hours": 0,
                          "minutes": 0,
                          "inclusive": false
                        }
                      },
                      "perBlock": false,
                      "restBreakBreach": {
                        "restBreakInterval": {
                          "from": {
                            "days": 0,
                            "hours": 0,
                            "minutes": 0,
                            "inclusive": false
                          },
                          "to": {
                            "days": 0,
                            "hours": 0,
                            "minutes": 0,
                            "inclusive": false
                          }
                        },
                        "continuousBreak": {
                          "days": 0,
                          "hours": 0,
                          "minutes": 0,
                          "inclusive": false
                        }
                      }
                    }
                  ],
                  "substantialBreach": [
                    {
                      "continuousStationaryBreakBreach": {
                        "from": {
                          "days": 0,
                          "hours": 0,
                          "minutes": 0,
                          "inclusive": false
                        },
                        "to": {
                          "days": 0,
                          "hours": 0,
                          "minutes": 0,
                          "inclusive": false
                        }
                      },
                      "perBlock": false,
                      "restBreakBreach": {
                        "restBreakInterval": {
                          "from": {
                            "days": 0,
                            "hours": 0,
                            "minutes": 0,
                            "inclusive": false
                          },
                          "to": {
                            "days": 0,
                            "hours": 0,
                            "minutes": 0,
                            "inclusive": false
                          }
                        },
                        "continuousBreak": {
                          "days": 0,
                          "hours": 0,
                          "minutes": 0,
                          "inclusive": false
                        }
                      }
                    }
                  ]
                },
                "timeLimit": {
                  "days": 0,
                  "hours": 0,
                  "minutes": 0,
                  "inclusive": false
                }
              }
            ],
            "maximumWorkBreach": {
              "criticalBreach": {
                "from": {
                  "days": 0,
                  "hours": 0,
                  "minutes": 0,
                  "inclusive": false
                },
                "to": {
                  "days": 0,
                  "hours": 0,
                  "minutes": 0,
                  "inclusive": false
                }
              },
              "minorBreach": {
                "from": {
                  "days": 0,
                  "hours": 0,
                  "minutes": 0,
                  "inclusive": false
                },
                "to": {
                  "days": 0,
                  "hours": 0,
                  "minutes": 0,
                  "inclusive": false
                }
              },
              "noBreach": {
                "days": 0,
                "hours": 5,
                "minutes": 23,
                "inclusive": true
              },
              "severeBreach": {
                "from": {
                  "days": 0,
                  "hours": 0,
                  "minutes": 0,
                  "inclusive": false
                },
                "to": {
                  "days": 0,
                  "hours": 0,
                  "minutes": 0,
                  "inclusive": false
                }
              },
              "substantialBreach": {
                "from": {
                  "days": 0,
                  "hours": 0,
                  "minutes": 0,
                  "inclusive": false
                },
                "to": {
                  "days": 0,
                  "hours": 0,
                  "minutes": 0,
                  "inclusive": false
                }
              }
            },
            "isLongNightWork": false
          },
          {
            "period": {
              "days": 0,
              "hours": 8,
              "minutes": 0,
              "inclusive": false
            },
            "maximumWork": {
              "days": 0,
              "hours": 7,
              "minutes": 30,
              "inclusive": false
            },
            "break": [
              {
                "restBreak": {
                  "minimumTime": {
                    "days": 0,
                    "hours": 0,
                    "minutes": 30,
                    "inclusive": false
                  },
                  "continuousBreak": {
                    "days": 0,
                    "hours": 0,
                    "minutes": 15,
                    "inclusive": false
                  },
                  "numberOfContinuousBreaks": 0,
                  "stationary": false,
                  "sleeperBerthWhileMoving": false,
                  "descriptionContainsAtLeast": true
                },
                "nightBreaks": 0,
                "consecutiveNightBreaks": 0,
                "breachList": {
                  "criticalBreach": [
                    {
                      "continuousStationaryBreakBreach": {
                        "from": {
                          "days": 0,
                          "hours": 0,
                          "minutes": 0,
                          "inclusive": false
                        },
                        "to": {
                          "days": 0,
                          "hours": 0,
                          "minutes": 0,
                          "inclusive": false
                        }
                      },
                      "perBlock": false,
                      "restBreakBreach": {
                        "restBreakInterval": {
                          "from": {
                            "days": 0,
                            "hours": 0,
                            "minutes": 0,
                            "inclusive": false
                          },
                          "to": {
                            "days": 0,
                            "hours": 0,
                            "minutes": 0,
                            "inclusive": false
                          }
                        },
                        "continuousBreak": {
                          "days": 0,
                          "hours": 0,
                          "minutes": 0,
                          "inclusive": false
                        }
                      },
                      "continuousBreakBreach": {
                        "from": {
                          "hours": 0,
                          "minutes": 0
                        },
                        "to": {
                          "hours": 0,
                          "minutes": 0
                        }
                      }
                    }
                  ],
                  "minorBreach": [
                    {
                      "continuousStationaryBreakBreach": {
                        "from": {
                          "days": 0,
                          "hours": 0,
                          "minutes": 0,
                          "inclusive": false
                        },
                        "to": {
                          "days": 0,
                          "hours": 0,
                          "minutes": 0,
                          "inclusive": false
                        }
                      },
                      "perBlock": false,
                      "restBreakBreach": {
                        "restBreakInterval": {
                          "from": {
                            "days": 0,
                            "hours": 0,
                            "minutes": 0,
                            "inclusive": false
                          },
                          "to": {
                            "days": 0,
                            "hours": 0,
                            "minutes": 0,
                            "inclusive": false
                          }
                        },
                        "continuousBreak": {
                          "days": 0,
                          "hours": 0,
                          "minutes": 0,
                          "inclusive": false
                        }
                      },
                      "continuousBreakBreach": {
                        "from": {
                          "hours": 0,
                          "minutes": 0
                        },
                        "to": {
                          "hours": 0
                        }
                      }
                    }
                  ],
                  "severeBreach": [
                    {
                      "continuousStationaryBreakBreach": {
                        "from": {
                          "days": 0,
                          "hours": 0,
                          "minutes": 0,
                          "inclusive": false
                        },
                        "to": {
                          "days": 0,
                          "hours": 0,
                          "minutes": 0,
                          "inclusive": false
                        }
                      },
                      "perBlock": false,
                      "restBreakBreach": {
                        "restBreakInterval": {
                          "from": {
                            "days": 0,
                            "hours": 0,
                            "minutes": 0,
                            "inclusive": false
                          },
                          "to": {
                            "days": 0,
                            "hours": 0,
                            "minutes": 0,
                            "inclusive": false
                          }
                        },
                        "continuousBreak": {
                          "days": 0,
                          "hours": 0,
                          "minutes": 0,
                          "inclusive": false
                        }
                      },
                      "continuousBreakBreach": {
                        "from": {
                          "hours": 0,
                          "minutes": 0
                        },
                        "to": {
                          "hours": 0,
                          "minutes": 0
                        }
                      }
                    }
                  ],
                  "substantialBreach": [
                    {
                      "continuousStationaryBreakBreach": {
                        "from": {
                          "days": 0,
                          "hours": 0,
                          "minutes": 0,
                          "inclusive": false
                        },
                        "to": {
                          "days": 0,
                          "hours": 0,
                          "minutes": 0,
                          "inclusive": false
                        }
                      },
                      "perBlock": false,
                      "restBreakBreach": {
                        "restBreakInterval": {
                          "from": {
                            "days": 0,
                            "hours": 0,
                            "minutes": 0,
                            "inclusive": false
                          },
                          "to": {
                            "days": 0,
                            "hours": 0,
                            "minutes": 0,
                            "inclusive": false
                          }
                        },
                        "continuousBreak": {
                          "days": 0,
                          "hours": 0,
                          "minutes": 0,
                          "inclusive": false
                        }
                      },
                      "continuousBreakBreach": {
                        "from": {
                          "hours": 0,
                          "minutes": 0
                        },
                        "to": {
                          "hours": 0,
                          "minutes": 0
                        }
                      }
                    }
                  ]
                },
                "timeLimit": {
                  "days": 0,
                  "hours": 0,
                  "minutes": 0,
                  "inclusive": false
                }
              }
            ],
            "maximumWorkBreach": {
              "criticalBreach": {
                "from": {
                  "days": 0,
                  "hours": 0,
                  "minutes": 0,
                  "inclusive": false
                },
                "to": {
                  "days": 0,
                  "hours": 0,
                  "minutes": 0,
                  "inclusive": false
                }
              },
              "minorBreach": {
                "from": {
                  "days": 0,
                  "hours": 7,
                  "minutes": 45,
                  "inclusive": false
                },
                "to": {
                  "days": 0,
                  "hours": 0,
                  "minutes": 0,
                  "inclusive": false
                }
              },
              "noBreach": {
                "days": 0,
                "hours": 7,
                "minutes": 38,
                "inclusive": true
              },
              "severeBreach": {
                "from": {
                  "days": 0,
                  "hours": 0,
                  "minutes": 0,
                  "inclusive": false
                },
                "to": {
                  "days": 0,
                  "hours": 0,
                  "minutes": 0,
                  "inclusive": false
                }
              },
              "substantialBreach": {
                "from": {
                  "days": 0,
                  "hours": 0,
                  "minutes": 0,
                  "inclusive": false
                },
                "to": {
                  "days": 0,
                  "hours": 0,
                  "minutes": 0,
                  "inclusive": false
                }
              }
            }
          },
          {
            "period": {
              "days": 0,
              "hours": 11,
              "minutes": 0,
              "inclusive": false
            },
            "maximumWork": {
              "days": 0,
              "hours": 10,
              "minutes": 0,
              "inclusive": false
            },
            "break": [
              {
                "restBreak": {
                  "minimumTime": {
                    "days": 0,
                    "hours": 0,
                    "minutes": 60,
                    "inclusive": false
                  },
                  "continuousBreak": {
                    "days": 0,
                    "hours": 0,
                    "minutes": 15,
                    "inclusive": false
                  },
                  "numberOfContinuousBreaks": 0,
                  "stationary": false,
                  "sleeperBerthWhileMoving": false,
                  "descriptionContainsAtLeast": true
                },
                "nightBreaks": 0,
                "consecutiveNightBreaks": 0,
                "breachList": {
                  "criticalBreach": [
                    {
                      "continuousStationaryBreakBreach": {
                        "from": {
                          "days": 0,
                          "hours": 0,
                          "minutes": 0,
                          "inclusive": false
                        },
                        "to": {
                          "days": 0,
                          "hours": 0,
                          "minutes": 0,
                          "inclusive": false
                        }
                      },
                      "perBlock": false,
                      "restBreakBreach": {
                        "restBreakInterval": {
                          "from": {
                            "days": 0,
                            "hours": 0,
                            "minutes": 0,
                            "inclusive": false
                          },
                          "to": {
                            "days": 0,
                            "hours": 0,
                            "minutes": 0,
                            "inclusive": false
                          }
                        },
                        "continuousBreak": {
                          "days": 0,
                          "hours": 0,
                          "minutes": 0,
                          "inclusive": false
                        }
                      },
                      "continuousBreakBreach": {
                        "from": {
                          "minutes": 0
                        },
                        "to": {
                          "hours": 0,
                          "minutes": 0
                        }
                      }
                    }
                  ],
                  "minorBreach": [
                    {
                      "continuousStationaryBreakBreach": {
                        "from": {
                          "days": 0,
                          "hours": 0,
                          "minutes": 0,
                          "inclusive": false
                        },
                        "to": {
                          "days": 0,
                          "hours": 0,
                          "minutes": 0,
                          "inclusive": false
                        }
                      },
                      "perBlock": false,
                      "restBreakBreach": {
                        "restBreakInterval": {
                          "from": {
                            "days": 0,
                            "hours": 0,
                            "minutes": 0,
                            "inclusive": false
                          },
                          "to": {
                            "days": 0,
                            "hours": 0,
                            "minutes": 0,
                            "inclusive": false
                          }
                        },
                        "continuousBreak": {
                          "days": 0,
                          "hours": 0,
                          "minutes": 0,
                          "inclusive": false
                        }
                      },
                      "continuousBreakBreach": {
                        "from": {
                          "hours": 0,
                          "minutes": 0
                        },
                        "to": {
                          "hours": 0
                        }
                      }
                    }
                  ],
                  "severeBreach": [
                    {
                      "continuousStationaryBreakBreach": {
                        "from": {
                          "days": 0,
                          "hours": 0,
                          "minutes": 0,
                          "inclusive": false
                        },
                        "to": {
                          "days": 0,
                          "hours": 0,
                          "minutes": 0,
                          "inclusive": false
                        }
                      },
                      "perBlock": false,
                      "restBreakBreach": {
                        "restBreakInterval": {
                          "from": {
                            "days": 0,
                            "hours": 0,
                            "minutes": 0,
                            "inclusive": false
                          },
                          "to": {
                            "days": 0,
                            "hours": 0,
                            "minutes": 0,
                            "inclusive": false
                          }
                        },
                        "continuousBreak": {
                          "days": 0,
                          "hours": 0,
                          "minutes": 0,
                          "inclusive": false
                        }
                      },
                      "continuousBreakBreach": {
                        "from": {
                          "minutes": 0
                        },
                        "to": {
                          "hours": 0
                        }
                      }
                    }
                  ],
                  "substantialBreach": [
                    {
                      "continuousStationaryBreakBreach": {
                        "from": {
                          "days": 0,
                          "hours": 0,
                          "minutes": 0,
                          "inclusive": false
                        },
                        "to": {
                          "days": 0,
                          "hours": 0,
                          "minutes": 0,
                          "inclusive": false
                        }
                      },
                      "perBlock": false,
                      "restBreakBreach": {
                        "restBreakInterval": {
                          "from": {
                            "days": 0,
                            "hours": 0,
                            "minutes": 0,
                            "inclusive": false
                          },
                          "to": {
                            "days": 0,
                            "hours": 0,
                            "minutes": 0,
                            "inclusive": false
                          }
                        },
                        "continuousBreak": {
                          "days": 0,
                          "hours": 0,
                          "minutes": 0,
                          "inclusive": false
                        }
                      },
                      "continuousBreakBreach": {
                        "from": {
                          "hours": 0,
                          "minutes": 0
                        },
                        "to": {
                          "hours": 0
                        }
                      }
                    }
                  ]
                },
                "timeLimit": {
                  "days": 0,
                  "hours": 0,
                  "minutes": 0,
                  "inclusive": false
                }
              }
            ],
            "maximumWorkBreach": {
              "criticalBreach": {
                "from": {
                  "days": 0,
                  "hours": 0,
                  "minutes": 0,
                  "inclusive": false
                },
                "to": {
                  "days": 0,
                  "hours": 0,
                  "minutes": 0,
                  "inclusive": false
                }
              },
              "minorBreach": {
                "from": {
                  "days": 0,
                  "hours": 10,
                  "minutes": 15,
                  "inclusive": false
                },
                "to": {
                  "days": 0,
                  "hours": 10,
                  "minutes": 45,
                  "inclusive": true
                }
              },
              "noBreach": {
                "days": 0,
                "hours": 10,
                "minutes": 8,
                "inclusive": true
              },
              "severeBreach": {
                "from": {
                  "days": 0,
                  "hours": 0,
                  "minutes": 0,
                  "inclusive": false
                },
                "to": {
                  "days": 0,
                  "hours": 0,
                  "minutes": 0,
                  "inclusive": false
                }
              },
              "substantialBreach": {
                "from": {
                  "days": 0,
                  "hours": 10,
                  "minutes": 45,
                  "inclusive": false
                },
                "to": {
                  "days": 0,
                  "hours": 0,
                  "minutes": 0,
                  "inclusive": false
                }
              }
            }
          },
          {
            "period": {
              "days": 0,
              "hours": 24,
              "minutes": 0,
              "inclusive": false
            },
            "maximumWork": {
              "days": 0,
              "hours": 12,
              "minutes": 0,
              "inclusive": false
            },
            "break": [
              {
                "restBreak": {
                  "minimumTime": {
                    "days": 0,
                    "hours": 0,
                    "minutes": 0,
                    "inclusive": false
                  },
                  "continuousBreak": {
                    "days": 0,
                    "hours": 5,
                    "minutes": 0,
                    "inclusive": false
                  },
                  "numberOfContinuousBreaks": 0,
                  "stationary": true,
                  "sleeperBerthWhileMoving": true,
                  "descriptionContainsAtLeast": false,
                  "continuousBreaksMustBeStationary": true
                },
                "nightBreaks": 0,
                "consecutiveNightBreaks": 0,
                "breachList": {
                  "criticalBreach": [
                    {
                      "continuousStationaryBreakBreach": {
                        "from": {
                          "days": 0,
                          "hours": 3,
                          "minutes": 30,
                          "inclusive": false
                        },
                        "to": {
                          "days": 0,
                          "hours": 0,
                          "minutes": 0,
                          "inclusive": false
                        }
                      },
                      "perBlock": false,
                      "restBreakBreach": {
                        "restBreakInterval": {
                          "from": {
                            "days": 0,
                            "hours": 0,
                            "minutes": 0,
                            "inclusive": false
                          },
                          "to": {
                            "days": 0,
                            "hours": 0,
                            "minutes": 0,
                            "inclusive": false
                          }
                        },
                        "continuousBreak": {
                          "days": 0,
                          "hours": 0,
                          "minutes": 0,
                          "inclusive": false
                        }
                      },
                      "continuousBreakBreach": {
                        "from": {
                          "hours": 3,
                          "minutes": 30
                        },
                        "to": {
                          "hours": 0,
                          "minutes": 0
                        }
                      }
                    }
                  ],
                  "minorBreach": [
                    {
                      "continuousStationaryBreakBreach": {
                        "from": {
                          "days": 0,
                          "hours": 4,
                          "minutes": 45,
                          "inclusive": false
                        },
                        "to": {
                          "days": 0,
                          "hours": 4,
                          "minutes": 15,
                          "inclusive": true
                        }
                      },
                      "perBlock": false,
                      "restBreakBreach": {
                        "restBreakInterval": {
                          "from": {
                            "days": 0,
                            "hours": 0,
                            "minutes": 0,
                            "inclusive": false
                          },
                          "to": {
                            "days": 0,
                            "hours": 0,
                            "minutes": 0,
                            "inclusive": false
                          }
                        },
                        "continuousBreak": {
                          "days": 0,
                          "hours": 0,
                          "minutes": 0,
                          "inclusive": false
                        }
                      },
                      "continuousBreakBreach": {
                        "from": {
                          "hours": 4,
                          "minutes": 45
                        },
                        "to": {
                          "hours": 4,
                          "minutes": 15,
                          "inclusive": true
                        }
                      },
                      "continuousBreakIsStationary": false
                    }
                  ],
                  "severeBreach": [
                    {
                      "continuousStationaryBreakBreach": {
                        "from": {
                          "days": 0,
                          "hours": 3,
                          "minutes": 45,
                          "inclusive": false
                        },
                        "to": {
                          "days": 0,
                          "hours": 3,
                          "minutes": 30,
                          "inclusive": true
                        }
                      },
                      "perBlock": false,
                      "restBreakBreach": {
                        "restBreakInterval": {
                          "from": {
                            "days": 0,
                            "hours": 0,
                            "minutes": 0,
                            "inclusive": false
                          },
                          "to": {
                            "days": 0,
                            "hours": 0,
                            "minutes": 0,
                            "inclusive": false
                          }
                        },
                        "continuousBreak": {
                          "days": 0,
                          "hours": 0,
                          "minutes": 0,
                          "inclusive": false
                        }
                      },
                      "continuousBreakBreach": {
                        "from": {
                          "hours": 3,
                          "minutes": 45
                        },
                        "to": {
                          "hours": 3,
                          "minutes": 30,
                          "inclusive": true
                        }
                      }
                    }
                  ],
                  "substantialBreach": [
                    {
                      "continuousStationaryBreakBreach": {
                        "from": {
                          "days": 0,
                          "hours": 4,
                          "minutes": 15,
                          "inclusive": false
                        },
                        "to": {
                          "days": 0,
                          "hours": 3,
                          "minutes": 45,
                          "inclusive": true
                        }
                      },
                      "perBlock": false,
                      "restBreakBreach": {
                        "restBreakInterval": {
                          "from": {
                            "days": 0,
                            "hours": 0,
                            "minutes": 0,
                            "inclusive": false
                          },
                          "to": {
                            "days": 0,
                            "hours": 0,
                            "minutes": 0,
                            "inclusive": false
                          }
                        },
                        "continuousBreak": {
                          "days": 0,
                          "hours": 0,
                          "minutes": 0,
                          "inclusive": false
                        }
                      },
                      "continuousBreakBreach": {
                        "from": {
                          "hours": 4,
                          "minutes": 15
                        },
                        "to": {
                          "hours": 3,
                          "minutes": 45,
                          "inclusive": true
                        }
                      }
                    }
                  ]
                },
                "timeLimit": {
                  "days": 0,
                  "hours": 0,
                  "minutes": 0,
                  "inclusive": false
                }
              }
            ],
            "maximumWorkBreach": {
              "criticalBreach": {
                "from": {
                  "days": 0,
                  "hours": 13,
                  "minutes": 30,
                  "inclusive": false
                },
                "to": {
                  "days": 0,
                  "hours": 0,
                  "minutes": 0,
                  "inclusive": false
                }
              },
              "minorBreach": {
                "from": {
                  "days": 0,
                  "hours": 12,
                  "minutes": 15,
                  "inclusive": false
                },
                "to": {
                  "days": 0,
                  "hours": 12,
                  "minutes": 45,
                  "inclusive": true
                }
              },
              "noBreach": {
                "days": 0,
                "hours": 12,
                "minutes": 8,
                "inclusive": true
              },
              "severeBreach": {
                "from": {
                  "days": 0,
                  "hours": 13,
                  "minutes": 15,
                  "inclusive": false
                },
                "to": {
                  "days": 0,
                  "hours": 13,
                  "minutes": 30,
                  "inclusive": true
                }
              },
              "substantialBreach": {
                "from": {
                  "days": 0,
                  "hours": 12,
                  "minutes": 45,
                  "inclusive": false
                },
                "to": {
                  "days": 0,
                  "hours": 13,
                  "minutes": 15,
                  "inclusive": true
                }
              }
            }
          },
          {
            "period": {
              "days": 0,
              "hours": 52,
              "minutes": 0,
              "inclusive": false
            },
            "maximumWork": {
              "days": 0,
              "hours": 0,
              "minutes": 0,
              "inclusive": false
            },
            "break": [
              {
                "restBreak": {
                  "minimumTime": {
                    "days": 0,
                    "hours": 0,
                    "minutes": 0,
                    "inclusive": false
                  },
                  "continuousBreak": {
                    "days": 0,
                    "hours": 10,
                    "minutes": 0,
                    "inclusive": false
                  },
                  "numberOfContinuousBreaks": 0,
                  "stationary": false,
                  "sleeperBerthWhileMoving": false,
                  "descriptionContainsAtLeast": false,
                  "continuousBreaksMustBeStationary": true
                },
                "nightBreaks": 0,
                "consecutiveNightBreaks": 0,
                "breachList": {
                  "criticalBreach": [
                    {
                      "continuousStationaryBreakBreach": {
                        "from": {
                          "days": 0,
                          "hours": 8,
                          "minutes": 30,
                          "inclusive": false
                        },
                        "to": {
                          "days": 0,
                          "hours": 0,
                          "minutes": 0,
                          "inclusive": false
                        }
                      },
                      "perBlock": false,
                      "restBreakBreach": {
                        "restBreakInterval": {
                          "from": {
                            "days": 0,
                            "hours": 0,
                            "minutes": 0,
                            "inclusive": false
                          },
                          "to": {
                            "days": 0,
                            "hours": 0,
                            "minutes": 0,
                            "inclusive": false
                          }
                        },
                        "continuousBreak": {
                          "days": 0,
                          "hours": 0,
                          "minutes": 0,
                          "inclusive": false
                        }
                      },
                      "continuousBreakBreach": {
                        "from": {
                          "hours": 8,
                          "minutes": 30
                        },
                        "to": {
                          "hours": 0,
                          "minutes": 0,
                          "inclusive": true
                        }
                      },
                      "continuousBreakIsStationary": true
                    }
                  ],
                  "minorBreach": [
                    {
                      "continuousStationaryBreakBreach": {
                        "from": {
                          "days": 0,
                          "hours": 9,
                          "minutes": 45,
                          "inclusive": false
                        },
                        "to": {
                          "days": 0,
                          "hours": 9,
                          "minutes": 15,
                          "inclusive": true
                        }
                      },
                      "perBlock": false,
                      "restBreakBreach": {
                        "restBreakInterval": {
                          "from": {
                            "days": 0,
                            "hours": 0,
                            "minutes": 0,
                            "inclusive": false
                          },
                          "to": {
                            "days": 0,
                            "hours": 0,
                            "minutes": 0,
                            "inclusive": false
                          }
                        },
                        "continuousBreak": {
                          "days": 0,
                          "hours": 0,
                          "minutes": 0,
                          "inclusive": false
                        }
                      },
                      "continuousBreakBreach": {
                        "from": {
                          "hours": 9,
                          "minutes": 45
                        },
                        "to": {
                          "hours": 9,
                          "minutes": 15,
                          "inclusive": true
                        }
                      },
                      "continuousBreakIsStationary": true
                    }
                  ],
                  "severeBreach": [
                    {
                      "continuousStationaryBreakBreach": {
                        "from": {
                          "days": 0,
                          "hours": 8,
                          "minutes": 45,
                          "inclusive": false
                        },
                        "to": {
                          "days": 0,
                          "hours": 8,
                          "minutes": 30,
                          "inclusive": true
                        }
                      },
                      "perBlock": false,
                      "restBreakBreach": {
                        "restBreakInterval": {
                          "from": {
                            "days": 0,
                            "hours": 0,
                            "minutes": 0,
                            "inclusive": false
                          },
                          "to": {
                            "days": 0,
                            "hours": 0,
                            "minutes": 0,
                            "inclusive": false
                          }
                        },
                        "continuousBreak": {
                          "days": 0,
                          "hours": 0,
                          "minutes": 0,
                          "inclusive": false
                        }
                      },
                      "continuousBreakBreach": {
                        "from": {
                          "hours": 8,
                          "minutes": 45
                        },
                        "to": {
                          "hours": 8,
                          "minutes": 30,
                          "inclusive": true
                        }
                      },
                      "continuousBreakIsStationary": true
                    }
                  ],
                  "substantialBreach": [
                    {
                      "continuousStationaryBreakBreach": {
                        "from": {
                          "days": 0,
                          "hours": 9,
                          "minutes": 15,
                          "inclusive": false
                        },
                        "to": {
                          "days": 0,
                          "hours": 8,
                          "minutes": 45,
                          "inclusive": true
                        }
                      },
                      "perBlock": false,
                      "restBreakBreach": {
                        "restBreakInterval": {
                          "from": {
                            "days": 0,
                            "hours": 0,
                            "minutes": 0,
                            "inclusive": false
                          },
                          "to": {
                            "days": 0,
                            "hours": 0,
                            "minutes": 0,
                            "inclusive": false
                          }
                        },
                        "continuousBreak": {
                          "days": 0,
                          "hours": 0,
                          "minutes": 0,
                          "inclusive": false
                        }
                      },
                      "continuousBreakBreach": {
                        "from": {
                          "hours": 9,
                          "minutes": 15
                        },
                        "to": {
                          "hours": 8,
                          "minutes": 45,
                          "inclusive": true
                        }
                      },
                      "continuousBreakIsStationary": true
                    }
                  ]
                },
                "timeLimit": {
                  "days": 0,
                  "hours": 0,
                  "minutes": 0,
                  "inclusive": false
                }
              }
            ],
            "maximumWorkBreach": {
              "criticalBreach": {
                "from": {
                  "days": 0,
                  "hours": 0,
                  "minutes": 0,
                  "inclusive": false
                },
                "to": {
                  "days": 0,
                  "hours": 0,
                  "minutes": 0,
                  "inclusive": false
                }
              },
              "minorBreach": {
                "from": {
                  "days": 0,
                  "hours": 0,
                  "minutes": 0,
                  "inclusive": false
                },
                "to": {
                  "days": 0,
                  "hours": 0,
                  "minutes": 0,
                  "inclusive": false
                }
              },
              "noBreach": {
                "days": 0,
                "hours": 0,
                "minutes": 0,
                "inclusive": false
              },
              "severeBreach": {
                "from": {
                  "days": 0,
                  "hours": 0,
                  "minutes": 0,
                  "inclusive": false
                },
                "to": {
                  "days": 0,
                  "hours": 0,
                  "minutes": 0,
                  "inclusive": false
                }
              },
              "substantialBreach": {
                "from": {
                  "days": 0,
                  "hours": 0,
                  "minutes": 0,
                  "inclusive": false
                },
                "to": {
                  "days": 0,
                  "hours": 0,
                  "minutes": 0,
                  "inclusive": false
                }
              }
            }
          },
          {
            "period": {
              "days": 7,
              "hours": 0,
              "minutes": 0,
              "inclusive": false
            },
            "maximumWork": {
              "days": 0,
              "hours": 60,
              "minutes": 0,
              "inclusive": false
            },
            "break": [
              {
                "restBreak": {
                  "minimumTime": {
                    "days": 0,
                    "hours": 0,
                    "minutes": 0,
                    "inclusive": false
                  },
                  "continuousBreak": {
                    "days": 0,
                    "hours": 24,
                    "minutes": 0,
                    "inclusive": false
                  },
                  "numberOfContinuousBreaks": 0,
                  "stationary": true,
                  "sleeperBerthWhileMoving": false,
                  "descriptionContainsAtLeast": false,
                  "continuousBreaksMustBeStationary": true
                },
                "nightBreaks": 0,
                "consecutiveNightBreaks": 0,
                "breachList": {
                  "criticalBreach": [
                    {
                      "continuousStationaryBreakBreach": {
                        "from": {
                          "days": 0,
                          "hours": 22,
                          "minutes": 30,
                          "inclusive": false
                        },
                        "to": {
                          "days": 0,
                          "hours": 0,
                          "minutes": 0,
                          "inclusive": false
                        }
                      },
                      "perBlock": false,
                      "restBreakBreach": {
                        "restBreakInterval": {
                          "from": {
                            "days": 0,
                            "hours": 0,
                            "minutes": 0,
                            "inclusive": false
                          },
                          "to": {
                            "days": 0,
                            "hours": 0,
                            "minutes": 0,
                            "inclusive": false
                          }
                        },
                        "continuousBreak": {
                          "days": 0,
                          "hours": 0,
                          "minutes": 0,
                          "inclusive": false
                        }
                      },
                      "continuousBreakBreach": {
                        "from": {
                          "hours": 22,
                          "minutes": 30
                        },
                        "to": {
                          "hours": 0,
                          "minutes": 0
                        }
                      },
                      "continuousBreakIsStationary": true
                    }
                  ],
                  "minorBreach": [
                    {
                      "continuousStationaryBreakBreach": {
                        "from": {
                          "days": 0,
                          "hours": 24,
                          "minutes": 15,
                          "inclusive": false
                        },
                        "to": {
                          "days": 0,
                          "hours": 23,
                          "minutes": 45,
                          "inclusive": true
                        }
                      },
                      "perBlock": false,
                      "restBreakBreach": {
                        "restBreakInterval": {
                          "from": {
                            "days": 0,
                            "hours": 0,
                            "minutes": 0,
                            "inclusive": false
                          },
                          "to": {
                            "days": 0,
                            "hours": 0,
                            "minutes": 0,
                            "inclusive": false
                          }
                        },
                        "continuousBreak": {
                          "days": 0,
                          "hours": 0,
                          "minutes": 0,
                          "inclusive": false
                        }
                      },
                      "continuousBreakBreach": {
                        "from": {
                          "hours": 23,
                          "minutes": 45
                        },
                        "to": {
                          "hours": 23,
                          "minutes": 15,
                          "inclusive": true
                        }
                      },
                      "continuousBreakIsStationary": true
                    }
                  ],
                  "severeBreach": [
                    {
                      "continuousStationaryBreakBreach": {
                        "from": {
                          "days": 0,
                          "hours": 22,
                          "minutes": 45,
                          "inclusive": false
                        },
                        "to": {
                          "days": 0,
                          "hours": 22,
                          "minutes": 30,
                          "inclusive": true
                        }
                      },
                      "perBlock": false,
                      "restBreakBreach": {
                        "restBreakInterval": {
                          "from": {
                            "days": 0,
                            "hours": 0,
                            "minutes": 0,
                            "inclusive": false
                          },
                          "to": {
                            "days": 0,
                            "hours": 0,
                            "minutes": 0,
                            "inclusive": false
                          }
                        },
                        "continuousBreak": {
                          "days": 0,
                          "hours": 0,
                          "minutes": 0,
                          "inclusive": false
                        }
                      },
                      "continuousBreakBreach": {
                        "from": {
                          "hours": 22,
                          "minutes": 45
                        },
                        "to": {
                          "hours": 22,
                          "minutes": 30,
                          "inclusive": true
                        }
                      },
                      "continuousBreakIsStationary": true
                    }
                  ],
                  "substantialBreach": [
                    {
                      "continuousStationaryBreakBreach": {
                        "from": {
                          "days": 0,
                          "hours": 23,
                          "minutes": 45,
                          "inclusive": false
                        },
                        "to": {
                          "days": 0,
                          "hours": 22,
                          "minutes": 45,
                          "inclusive": true
                        }
                      },
                      "perBlock": false,
                      "restBreakBreach": {
                        "restBreakInterval": {
                          "from": {
                            "days": 0,
                            "hours": 0,
                            "minutes": 0,
                            "inclusive": false
                          },
                          "to": {
                            "days": 0,
                            "hours": 0,
                            "minutes": 0,
                            "inclusive": false
                          }
                        },
                        "continuousBreak": {
                          "days": 0,
                          "hours": 0,
                          "minutes": 0,
                          "inclusive": false
                        }
                      },
                      "continuousBreakBreach": {
                        "from": {
                          "hours": 23,
                          "minutes": 15
                        },
                        "to": {
                          "hours": 22,
                          "minutes": 45,
                          "inclusive": true
                        }
                      },
                      "continuousBreakIsStationary": true
                    }
                  ]
                },
                "timeLimit": {
                  "days": 0,
                  "hours": 0,
                  "minutes": 0,
                  "inclusive": false
                }
              },
              {
                "restBreak": {
                  "minimumTime": {
                    "days": 0,
                    "hours": 24,
                    "minutes": 0,
                    "inclusive": false
                  },
                  "continuousBreak": {
                    "days": 0,
                    "hours": 7,
                    "minutes": 0,
                    "inclusive": false
                  },
                  "numberOfContinuousBreaks": 0,
                  "stationary": true,
                  "sleeperBerthWhileMoving": false,
                  "descriptionContainsAtLeast": true,
                  "continuousBreaksMustBeStationary": false,
                  "minimumTimeMustBeStationary": true
                },
                "nightBreaks": 0,
                "consecutiveNightBreaks": 0,
                "breachList": {
                  "criticalBreach": [
                    {
                      "continuousStationaryBreakBreach": {
                        "from": {
                          "days": 0,
                          "hours": 22,
                          "minutes": 30,
                          "inclusive": false
                        },
                        "to": {
                          "days": 0,
                          "hours": 0,
                          "minutes": 0,
                          "inclusive": false
                        }
                      },
                      "perBlock": false,
                      "restBreakBreach": {
                        "restBreakInterval": {
                          "from": {
                            "days": 0,
                            "hours": 22,
                            "minutes": 30,
                            "inclusive": false
                          },
                          "to": {
                            "days": 0,
                            "hours": 0,
                            "minutes": 0,
                            "inclusive": false
                          }
                        },
                        "continuousBreak": {
                          "days": 0,
                          "hours": 0,
                          "minutes": 0,
                          "inclusive": false
                        }
                      },
                      "continuousBreakBreach": {
                        "from": {
                          "hours": 0,
                          "minutes": 0
                        },
                        "to": {
                          "hours": 0,
                          "minutes": 0
                        }
                      },
                      "continuousBreakIsStationary": false,
                      "restBreakIsStationary": true
                    },
                    {
                      "continuousStationaryBreakBreach": {
                        "from": {
                          "days": 0,
                          "hours": 5,
                          "minutes": 30,
                          "inclusive": false
                        },
                        "to": {
                          "days": 0,
                          "hours": 0,
                          "minutes": 0,
                          "inclusive": false
                        }
                      },
                      "perBlock": true,
                      "restBreakBreach": {
                        "restBreakInterval": {
                          "from": {
                            "days": 0,
                            "hours": 0,
                            "minutes": 0,
                            "inclusive": false
                          },
                          "to": {
                            "days": 0,
                            "hours": 0,
                            "minutes": 0,
                            "inclusive": false
                          }
                        },
                        "continuousBreak": {
                          "days": 0,
                          "hours": 0,
                          "minutes": 0,
                          "inclusive": false
                        }
                      },
                      "continuousBreakBreach": {
                        "from": {
                          "hours": 5,
                          "minutes": 30
                        },
                        "to": {
                          "hours": 0,
                          "minutes": 0
                        }
                      },
                      "continuousBreakIsStationary": true
                    }
                  ],
                  "minorBreach": [
                    {
                      "continuousStationaryBreakBreach": {
                        "from": {
                          "days": 0,
                          "hours": 24,
                          "minutes": 15,
                          "inclusive": false
                        },
                        "to": {
                          "days": 0,
                          "hours": 23,
                          "minutes": 45,
                          "inclusive": true
                        }
                      },
                      "perBlock": false,
                      "restBreakBreach": {
                        "restBreakInterval": {
                          "from": {
                            "days": 0,
                            "hours": 23,
                            "minutes": 45,
                            "inclusive": false
                          },
                          "to": {
                            "days": 0,
                            "hours": 23,
                            "minutes": 15,
                            "inclusive": true
                          }
                        },
                        "continuousBreak": {
                          "days": 0,
                          "hours": 0,
                          "minutes": 0,
                          "inclusive": false
                        }
                      },
                      "continuousBreakBreach": {
                        "from": {
                          "hours": 0,
                          "minutes": 0
                        },
                        "to": {
                          "hours": 0,
                          "minutes": 0,
                          "inclusive": false
                        }
                      },
                      "continuousBreakIsStationary": false,
                      "restBreakIsStationary": true
                    },
                    {
                      "continuousStationaryBreakBreach": {
                        "from": {
                          "days": 0,
                          "hours": 6,
                          "minutes": 45,
                          "inclusive": false
                        },
                        "to": {
                          "days": 0,
                          "hours": 6,
                          "minutes": 15,
                          "inclusive": true
                        }
                      },
                      "perBlock": true,
                      "restBreakBreach": {
                        "restBreakInterval": {
                          "from": {
                            "days": 0,
                            "hours": 0,
                            "minutes": 0,
                            "inclusive": false
                          },
                          "to": {
                            "days": 0,
                            "hours": 0,
                            "minutes": 0,
                            "inclusive": false
                          }
                        },
                        "continuousBreak": {
                          "days": 0,
                          "hours": 0,
                          "minutes": 0,
                          "inclusive": false
                        }
                      },
                      "continuousBreakBreach": {
                        "from": {
                          "hours": 6,
                          "minutes": 45
                        },
                        "to": {
                          "hours": 6,
                          "minutes": 15,
                          "inclusive": true
                        }
                      },
                      "continuousBreakIsStationary": true
                    }
                  ],
                  "severeBreach": [
                    {
                      "continuousStationaryBreakBreach": {
                        "from": {
                          "days": 0,
                          "hours": 22,
                          "minutes": 45,
                          "inclusive": false
                        },
                        "to": {
                          "days": 0,
                          "hours": 22,
                          "minutes": 30,
                          "inclusive": true
                        }
                      },
                      "perBlock": false,
                      "restBreakBreach": {
                        "restBreakInterval": {
                          "from": {
                            "days": 0,
                            "hours": 22,
                            "minutes": 45,
                            "inclusive": false
                          },
                          "to": {
                            "days": 0,
                            "hours": 22,
                            "minutes": 30,
                            "inclusive": true
                          }
                        },
                        "continuousBreak": {
                          "days": 0,
                          "hours": 0,
                          "minutes": 0,
                          "inclusive": false
                        }
                      },
                      "continuousBreakBreach": {
                        "from": {
                          "hours": 0,
                          "minutes": 0
                        },
                        "to": {
                          "hours": 0,
                          "minutes": 0,
                          "inclusive": false
                        }
                      },
                      "continuousBreakIsStationary": false,
                      "restBreakIsStationary": true
                    },
                    {
                      "continuousStationaryBreakBreach": {
                        "from": {
                          "days": 0,
                          "hours": 5,
                          "minutes": 45,
                          "inclusive": false
                        },
                        "to": {
                          "days": 0,
                          "hours": 5,
                          "minutes": 30,
                          "inclusive": true
                        }
                      },
                      "perBlock": true,
                      "restBreakBreach": {
                        "restBreakInterval": {
                          "from": {
                            "days": 0,
                            "hours": 0,
                            "minutes": 0,
                            "inclusive": false
                          },
                          "to": {
                            "days": 0,
                            "hours": 0,
                            "minutes": 0,
                            "inclusive": false
                          }
                        },
                        "continuousBreak": {
                          "days": 0,
                          "hours": 0,
                          "minutes": 0,
                          "inclusive": false
                        }
                      },
                      "continuousBreakBreach": {
                        "from": {
                          "hours": 5,
                          "minutes": 45
                        },
                        "to": {
                          "hours": 5,
                          "minutes": 30,
                          "inclusive": true
                        }
                      },
                      "continuousBreakIsStationary": true
                    }
                  ],
                  "substantialBreach": [
                    {
                      "continuousStationaryBreakBreach": {
                        "from": {
                          "days": 0,
                          "hours": 23,
                          "minutes": 45,
                          "inclusive": false
                        },
                        "to": {
                          "days": 0,
                          "hours": 22,
                          "minutes": 45,
                          "inclusive": true
                        }
                      },
                      "perBlock": false,
                      "restBreakBreach": {
                        "restBreakInterval": {
                          "from": {
                            "days": 0,
                            "hours": 23,
                            "minutes": 15,
                            "inclusive": false
                          },
                          "to": {
                            "days": 0,
                            "hours": 22,
                            "minutes": 45,
                            "inclusive": true
                          }
                        },
                        "continuousBreak": {
                          "days": 0,
                          "hours": 0,
                          "minutes": 0,
                          "inclusive": false
                        }
                      },
                      "continuousBreakBreach": {
                        "from": {
                          "hours": 0,
                          "minutes": 0
                        },
                        "to": {
                          "hours": 0,
                          "minutes": 0,
                          "inclusive": false
                        }
                      },
                      "continuousBreakIsStationary": false,
                      "restBreakIsStationary": true
                    },
                    {
                      "continuousStationaryBreakBreach": {
                        "from": {
                          "days": 0,
                          "hours": 6,
                          "minutes": 15,
                          "inclusive": false
                        },
                        "to": {
                          "days": 0,
                          "hours": 5,
                          "minutes": 45,
                          "inclusive": true
                        }
                      },
                      "perBlock": true,
                      "restBreakBreach": {
                        "restBreakInterval": {
                          "from": {
                            "days": 0,
                            "hours": 0,
                            "minutes": 0,
                            "inclusive": false
                          },
                          "to": {
                            "days": 0,
                            "hours": 0,
                            "minutes": 0,
                            "inclusive": false
                          }
                        },
                        "continuousBreak": {
                          "days": 0,
                          "hours": 0,
                          "minutes": 0,
                          "inclusive": false
                        }
                      },
                      "continuousBreakBreach": {
                        "from": {
                          "hours": 6,
                          "minutes": 15
                        },
                        "to": {
                          "hours": 5,
                          "minutes": 45,
                          "inclusive": true
                        }
                      },
                      "continuousBreakIsStationary": true
                    }
                  ]
                },
                "timeLimit": {
                  "days": 0,
                  "hours": 0,
                  "minutes": 0,
                  "inclusive": false
                }
              }
            ],
            "maximumWorkBreach": {
              "criticalBreach": {
                "from": {
                  "days": 0,
                  "hours": 63,
                  "minutes": 0,
                  "inclusive": false
                },
                "to": {
                  "days": 0,
                  "hours": 0,
                  "minutes": 0,
                  "inclusive": false
                }
              },
              "minorBreach": {
                "from": {
                  "days": 0,
                  "hours": 60,
                  "minutes": 15,
                  "inclusive": false
                },
                "to": {
                  "days": 0,
                  "hours": 61,
                  "minutes": 30,
                  "inclusive": true
                }
              },
              "noBreach": {
                "days": 0,
                "hours": 0,
                "minutes": 0,
                "inclusive": false
              },
              "severeBreach": {
                "from": {
                  "days": 0,
                  "hours": 62,
                  "minutes": 30,
                  "inclusive": false
                },
                "to": {
                  "days": 0,
                  "hours": 63,
                  "minutes": 0,
                  "inclusive": true
                }
              },
              "substantialBreach": {
                "from": {
                  "days": 0,
                  "hours": 61,
                  "minutes": 30,
                  "inclusive": false
                },
                "to": {
                  "days": 0,
                  "hours": 62,
                  "minutes": 30,
                  "inclusive": true
                }
              }
            }
          },
          {
            "period": {
              "days": 14,
              "hours": 0,
              "minutes": 0,
              "inclusive": false
            },
            "maximumWork": {
              "days": 0,
              "hours": 120,
              "minutes": 0,
              "inclusive": false
            },
            "break": [
              {
                "restBreak": {
                  "minimumTime": {
                    "days": 0,
                    "hours": 0,
                    "minutes": 0,
                    "inclusive": false
                  },
                  "continuousBreak": {
                    "days": 0,
                    "hours": 0,
                    "minutes": 0,
                    "inclusive": false
                  },
                  "numberOfContinuousBreaks": 0,
                  "stationary": false,
                  "sleeperBerthWhileMoving": false,
                  "descriptionContainsAtLeast": false
                },
                "nightBreaks": 2,
                "consecutiveNightBreaks": 0,
                "breachList": {
                  "criticalBreach": [
                    {
                      "continuousStationaryBreakBreach": {
                        "from": {
                          "days": 0,
                          "hours": 5,
                          "minutes": 30,
                          "inclusive": false
                        },
                        "to": {
                          "days": 0,
                          "hours": 0,
                          "minutes": 0,
                          "inclusive": false
                        }
                      },
                      "perBlock": true,
                      "restBreakBreach": {
                        "restBreakInterval": {
                          "from": {
                            "days": 0,
                            "hours": 0,
                            "minutes": 0,
                            "inclusive": false
                          },
                          "to": {
                            "days": 0,
                            "hours": 0,
                            "minutes": 0,
                            "inclusive": false
                          }
                        },
                        "continuousBreak": {
                          "days": 0,
                          "hours": 0,
                          "minutes": 0,
                          "inclusive": false
                        }
                      },
                      "continuousBreakBreach": {
                        "from": {
                          "hours": 5,
                          "minutes": 30
                        },
                        "to": {
                          "minutes": 0
                        }
                      },
                      "continuousBreakIsStationary": true
                    }
                  ],
                  "minorBreach": [
                    {
                      "continuousStationaryBreakBreach": {
                        "from": {
                          "days": 0,
                          "hours": 6,
                          "minutes": 45,
                          "inclusive": false
                        },
                        "to": {
                          "days": 0,
                          "hours": 6,
                          "minutes": 15,
                          "inclusive": true
                        }
                      },
                      "perBlock": true,
                      "restBreakBreach": {
                        "restBreakInterval": {
                          "from": {
                            "days": 0,
                            "hours": 0,
                            "minutes": 0,
                            "inclusive": false
                          },
                          "to": {
                            "days": 0,
                            "hours": 0,
                            "minutes": 0,
                            "inclusive": false
                          }
                        },
                        "continuousBreak": {
                          "days": 0,
                          "hours": 0,
                          "minutes": 0,
                          "inclusive": false
                        }
                      },
                      "continuousBreakBreach": {
                        "from": {
                          "hours": 6,
                          "minutes": 45
                        },
                        "to": {
                          "hours": 6,
                          "minutes": 15,
                          "inclusive": true
                        }
                      },
                      "continuousBreakIsStationary": true
                    }
                  ],
                  "severeBreach": [
                    {
                      "continuousStationaryBreakBreach": {
                        "from": {
                          "days": 0,
                          "hours": 5,
                          "minutes": 45,
                          "inclusive": false
                        },
                        "to": {
                          "days": 0,
                          "hours": 5,
                          "minutes": 30,
                          "inclusive": true
                        }
                      },
                      "perBlock": true,
                      "restBreakBreach": {
                        "restBreakInterval": {
                          "from": {
                            "days": 0,
                            "hours": 0,
                            "minutes": 0,
                            "inclusive": false
                          },
                          "to": {
                            "days": 0,
                            "hours": 0,
                            "minutes": 0,
                            "inclusive": false
                          }
                        },
                        "continuousBreak": {
                          "days": 0,
                          "hours": 0,
                          "minutes": 0,
                          "inclusive": false
                        }
                      },
                      "continuousBreakBreach": {
                        "from": {
                          "hours": 5,
                          "minutes": 45
                        },
                        "to": {
                          "hours": 5,
                          "minutes": 30,
                          "inclusive": true
                        }
                      },
                      "continuousBreakIsStationary": true
                    }
                  ],
                  "substantialBreach": [
                    {
                      "continuousStationaryBreakBreach": {
                        "from": {
                          "days": 0,
                          "hours": 6,
                          "minutes": 15,
                          "inclusive": false
                        },
                        "to": {
                          "days": 0,
                          "hours": 5,
                          "minutes": 45,
                          "inclusive": true
                        }
                      },
                      "perBlock": true,
                      "restBreakBreach": {
                        "restBreakInterval": {
                          "from": {
                            "days": 0,
                            "hours": 0,
                            "minutes": 0,
                            "inclusive": false
                          },
                          "to": {
                            "days": 0,
                            "hours": 0,
                            "minutes": 0,
                            "inclusive": false
                          }
                        },
                        "continuousBreak": {
                          "days": 0,
                          "hours": 0,
                          "minutes": 0,
                          "inclusive": false
                        }
                      },
                      "continuousBreakBreach": {
                        "from": {
                          "hours": 6,
                          "minutes": 15
                        },
                        "to": {
                          "hours": 5,
                          "minutes": 45,
                          "inclusive": true
                        }
                      },
                      "continuousBreakIsStationary": true
                    }
                  ]
                },
                "timeLimit": {
                  "days": 0,
                  "hours": 0,
                  "minutes": 0,
                  "inclusive": false
                }
              },
              {
                "restBreak": {
                  "minimumTime": {
                    "days": 0,
                    "hours": 0,
                    "minutes": 0,
                    "inclusive": false
                  },
                  "continuousBreak": {
                    "days": 0,
                    "hours": 0,
                    "minutes": 0,
                    "inclusive": false
                  },
                  "numberOfContinuousBreaks": 0,
                  "stationary": false,
                  "sleeperBerthWhileMoving": false,
                  "descriptionContainsAtLeast": false
                },
                "nightBreaks": 0,
                "consecutiveNightBreaks": 2,
                "breachList": {
                  "criticalBreach": [
                    {
                      "continuousStationaryBreakBreach": {
                        "from": {
                          "days": 0,
                          "hours": 5,
                          "minutes": 30,
                          "inclusive": false
                        },
                        "to": {
                          "days": 0,
                          "hours": 0,
                          "minutes": 0,
                          "inclusive": false
                        }
                      },
                      "perBlock": true,
                      "restBreakBreach": {
                        "restBreakInterval": {
                          "from": {
                            "days": 0,
                            "hours": 0,
                            "minutes": 0,
                            "inclusive": false
                          },
                          "to": {
                            "days": 0,
                            "hours": 0,
                            "minutes": 0,
                            "inclusive": false
                          }
                        },
                        "continuousBreak": {
                          "days": 0,
                          "hours": 0,
                          "minutes": 0,
                          "inclusive": false
                        }
                      },
                      "continuousBreakBreach": {
                        "from": {
                          "hours": 5,
                          "minutes": 30
                        },
                        "to": {
                          "minutes": 0
                        }
                      },
                      "continuousBreakIsStationary": true
                    }
                  ],
                  "minorBreach": [
                    {
                      "continuousStationaryBreakBreach": {
                        "from": {
                          "days": 0,
                          "hours": 6,
                          "minutes": 45,
                          "inclusive": false
                        },
                        "to": {
                          "days": 0,
                          "hours": 6,
                          "minutes": 15,
                          "inclusive": true
                        }
                      },
                      "perBlock": true,
                      "restBreakBreach": {
                        "restBreakInterval": {
                          "from": {
                            "days": 0,
                            "hours": 0,
                            "minutes": 0,
                            "inclusive": false
                          },
                          "to": {
                            "days": 0,
                            "hours": 0,
                            "minutes": 0,
                            "inclusive": false
                          }
                        },
                        "continuousBreak": {
                          "days": 0,
                          "hours": 0,
                          "minutes": 0,
                          "inclusive": false
                        }
                      },
                      "continuousBreakBreach": {
                        "from": {
                          "hours": 6,
                          "minutes": 45
                        },
                        "to": {
                          "hours": 6,
                          "minutes": 15,
                          "inclusive": true
                        }
                      },
                      "continuousBreakIsStationary": true
                    }
                  ],
                  "severeBreach": [
                    {
                      "continuousStationaryBreakBreach": {
                        "from": {
                          "days": 0,
                          "hours": 5,
                          "minutes": 45,
                          "inclusive": false
                        },
                        "to": {
                          "days": 0,
                          "hours": 5,
                          "minutes": 30,
                          "inclusive": true
                        }
                      },
                      "perBlock": true,
                      "restBreakBreach": {
                        "restBreakInterval": {
                          "from": {
                            "days": 0,
                            "hours": 0,
                            "minutes": 0,
                            "inclusive": false
                          },
                          "to": {
                            "days": 0,
                            "hours": 0,
                            "minutes": 0,
                            "inclusive": false
                          }
                        },
                        "continuousBreak": {
                          "days": 0,
                          "hours": 0,
                          "minutes": 0,
                          "inclusive": false
                        }
                      },
                      "continuousBreakBreach": {
                        "from": {
                          "hours": 5,
                          "minutes": 45
                        },
                        "to": {
                          "hours": 5,
                          "minutes": 30,
                          "inclusive": true
                        }
                      },
                      "continuousBreakIsStationary": true
                    }
                  ],
                  "substantialBreach": [
                    {
                      "continuousStationaryBreakBreach": {
                        "from": {
                          "days": 0,
                          "hours": 6,
                          "minutes": 15,
                          "inclusive": false
                        },
                        "to": {
                          "days": 0,
                          "hours": 5,
                          "minutes": 45,
                          "inclusive": true
                        }
                      },
                      "perBlock": true,
                      "restBreakBreach": {
                        "restBreakInterval": {
                          "from": {
                            "days": 0,
                            "hours": 0,
                            "minutes": 0,
                            "inclusive": false
                          },
                          "to": {
                            "days": 0,
                            "hours": 0,
                            "minutes": 0,
                            "inclusive": false
                          }
                        },
                        "continuousBreak": {
                          "days": 0,
                          "hours": 0,
                          "minutes": 0,
                          "inclusive": false
                        }
                      },
                      "continuousBreakBreach": {
                        "from": {
                          "hours": 6,
                          "minutes": 15
                        },
                        "to": {
                          "hours": 5,
                          "minutes": 45,
                          "inclusive": true
                        }
                      },
                      "continuousBreakIsStationary": true
                    }
                  ]
                },
                "timeLimit": {
                  "days": 0,
                  "hours": 0,
                  "minutes": 0,
                  "inclusive": false
                }
              }
            ],
            "maximumWorkBreach": {
              "criticalBreach": {
                "from": {
                  "days": 0,
                  "hours": 123,
                  "minutes": 0,
                  "inclusive": false
                },
                "to": {
                  "days": 0,
                  "hours": 0,
                  "minutes": 0,
                  "inclusive": false
                }
              },
              "minorBreach": {
                "from": {
                  "days": 0,
                  "hours": 120,
                  "minutes": 15,
                  "inclusive": false
                },
                "to": {
                  "days": 0,
                  "hours": 121,
                  "minutes": 30,
                  "inclusive": true
                }
              },
              "noBreach": {
                "days": 0,
                "hours": 0,
                "minutes": 0,
                "inclusive": false
              },
              "severeBreach": {
                "from": {
                  "days": 0,
                  "hours": 122,
                  "minutes": 30,
                  "inclusive": false
                },
                "to": {
                  "days": 0,
                  "hours": 123,
                  "minutes": 0,
                  "inclusive": true
                }
              },
              "substantialBreach": {
                "from": {
                  "days": 0,
                  "hours": 121,
                  "minutes": 30,
                  "inclusive": false
                },
                "to": {
                  "days": 0,
                  "hours": 122,
                  "minutes": 30,
                  "inclusive": true
                }
              }
            }
          }
        ],
        "rulesetEffectiveDate": "2018-05-28",
        "rulesetExpiryDate": null,
        "rulesetVersionNumber": "V1.0"
      }
    `
        var element = document.createElement('a');
        element.setAttribute('href', 'data:application/json;charset=utf-8,' + encodeURIComponent(text));
        element.setAttribute('download', fileName+'.json');

        element.style.display = 'none';
        document.body.appendChild(element);

        element.click();

        document.body.removeChild(element);
}