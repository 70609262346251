import React, { Component } from "react";
import MaterialTable from "material-table";
import Dialog from '@material-ui/core/Dialog';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { toast } from 'react-toastify';
import Typography from '@material-ui/core/Typography';
import {
  editMassEvent,
  getMassEventList,
} from '../../actions/MassEvent';

class ListMassEvent extends Component {
  componentDidMount(){
    this.props.getMassEventList();
}
  render() {
    let {
      massEventInformations,
      massEventListcols } = this.props.massEvents
    return (
       <div style = {{marginTop : '2rem', marginBottom : "2rem"}} className = "container">
      <Paper>
      <div className = "pt-1 pl-3">
        <span className = "d-flex">
          <div className = "mr-auto" style = {{paddingTop : "0"}}>
              <h4 style = {{marginTop : "1rem", textTransform : "uppercase"}}>Aus Mass Event List</h4>
          </div>
          <div className = "d-flex flex-wrap align-content-center">
              <Button 
                  variant="contained" 
                  color="primary"
                  onClick = {() => this.props.history.push("/sgadminportal/ausmassevents/newmassevent")}
                  className = "mr-2"
              >
                  <i className="material-icons" style = {{paddingRight : "0.5rem"}}>
                      add_circle_outline
                  </i>
                  Add Aus Mass Event
              </Button>
          </div>
        </span>
      </div>
      {massEventInformations.length == 0 ? 
        <div style = {{height : "20rem"}} className = "d-flex justify-content-center align-items-center">
            <CircularProgress color = "primary" />
        </div>:
          <MaterialTable
            columns={massEventListcols}
            data={massEventInformations}
            title=""
            editable={{
              onRowUpdate: (newData, oldData) =>
                new Promise((resolve, reject) => {
                  if(newData.description !== ''){
                    resolve(this.props.editMassEvent(newData, massEventInformations));
                  }else{
                    toast.error("Description field should not be blank", {containerId: 'toastMsg'});
                    reject();
                  }
                }),
            }}
            options={{ actionsColumnIndex: -1}}
          />
      }
      </Paper>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return{
    massEvents : state.massEvents
  }
}

const mapDispatchToProps = dispatch => {
  return{
    getMassEventList : () => {
          return dispatch(getMassEventList())
      },
      editMassEvent : (newData, massEventInformations) => {
        return dispatch(editMassEvent(newData, massEventInformations))
      }

  }
}
export default connect(mapStateToProps, mapDispatchToProps)(ListMassEvent);