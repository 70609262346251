import React from 'react';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';

const sgPaperContainer = (props) => {
    let buttonList = props.buttons;
    if(buttonList === undefined){
        buttonList = [];
    }
    return(
        <Paper className = "mb-3">
            <div className = "d-flex">
                <div className = "d-flex flex-wrap align-content-center mr-auto">
                    <h4 className = "mt-3 ml-2 text-uppercase">{props.title}</h4>
                </div>
                <div className = "d-flex flex-wrap align-content-center">
                    {
                        (buttonList).map(button => {
                            return(
                                <Button 
                                    variant="contained" 
                                    color={button.color}
                                    onClick = {button.action}
                                    className = "mr-2"
                                    key = {button}
                                >
                                    <i className="material-icons" style = {{paddingRight : "0.5rem"}}>
                                        {button.icon}
                                    </i>
                                    {button.name}
                                </Button>
                            )
                        })
                    }

                </div>
            </div>
            {props.children}
        </Paper>
    )
}

export default sgPaperContainer;