import { display } from '@material-ui/system';
import {
    SEARCH_DRIVER_TEXTFIELD_CHANGE,
    MANGE_LINK_DRIVER_DIALOG,
    MANAGE_LINK_DRIVER_DIALOG_CLOSE,
    MANAGE_EDIT_DRIVER_DIALOG_CLOSE,
    SEARCH_DRIVER,
    MANAGE_EDIT_DRIVER_OPEN_DIALOG,
    SUBMIT_DRIVER_INFO,
    DRIVER_SET_SEARCH_FOUND_STATUS_TO_NULL,
    SET_ALL_DRIVER_LIST,
    MAKE_DRIVER_TOAST_STATUS_NULL,
    SET_ALL_TEXTFIELD_ENABLE,
    LINK_DRIVER_TO_BUSINESS,
    SET_DEFAULT_VALUE_TO_ADD_DRIVER_COMPONENT,
    SET_INPUTHANDLER_UPLOAD_DRIVER,
    POST_DRIVER_UPLOADED_DATA,
    SET_DEFAULT_VALUE_TO_SEARCH_DRIVER_COMPONENT,
    SET_DEFAULT_VALUE_TO_DRIVER_BULK_UPLOAD_FORM,
    SET_ADD_DRIVER_INPUT_FIELD,
    SET_INPUT_DOB,
    SET_FATIGUE_TYPE_CHECKBOXES,
    SET_DRIVER_TYPE_CHECKBOXES,
    POST_HANDLE_ADMIN_CHECKBOXES,
    POST_HANDLE_CONTRACTOR_CHECKBOXES,
    SET_UPDATED_DRIVER_DATA,
    POST_UNLINK_DRIVER,
    POST_CLEAR_ALL_SEARCH_DRIVER_FIELD,
    POST_HANDLE_LICENSING_MODULE_CHECKBOXES,
    TOGGLE_VIEW_DEPORT_DIALOG,
    TOGGLE_ADD_DEPORT_DIALOG,
    SET_DEPOT_MAP_FIELD,
    SEARCH_DRIVER_DOB_CHANGE
} from '../actions/ActionType';

const initialState = {
    uploadDriverForms : {
        upload_driver : {
            elementType : 'upload_csv',
            elementConfig : {
                type : 'text',
                label : 'Upload CSV'
            },
            value : '',
            errorStatus : false,
            errorMessage : "Upload CSV"
        }
    },

    searchDriverForm : {
        'driver_id' : {
            elementType : 'input',
            elementConfig : {
                type : 'text',
                label : 'Driver Id'
            },
            value : '',
            maxLength:20
        },
        'dob' : {
            elementType : 'date',
            elementConfig : {
                type : 'number',
                label : 'Date Of Birth'
            },
            value : new Date(),
            errorStatus : false,
            errorMessage : "Enter Date Of Birth",
            disabledStatus : false
        },
        'license_number' : {
            elementType : 'input',
            elementConfig : {
                type : 'text',
                label : 'License Number',

            },
            value : '',
            maxLength:10
        },
    },
    searchFoundStatus : null,
    driverTypesCheckBoxes : {
        "select_all" : {
            elementType : 'checkbox',
            elementConfig : {
                checked : false,
                color : 'primary',
                label : "Select All"
            },
        },
        "light_ridged" : {
            elementType : 'checkbox',
            elementConfig : {
                checked : false,
                color : 'primary',
                label : "Light Ridged"
            },
        },
        "medium_ridged" : {
            elementType : 'checkbox',
            elementConfig : {
                checked : false,
                color : 'primary',
                label : "Medium Ridged"
            },
        },
        "heavy_ridged" : {
            elementType : 'checkbox',
            elementConfig : {
                checked : false,
                color : 'primary',
                label : "Heavy Ridged"
            },
        },
        "heavy_combination" : {
            elementType : 'checkbox',
            elementConfig : {
                checked : false,
                color : 'primary',
                label : "Heavy Combination"
            },
        },
        "multi_combination" : {
            elementType : 'checkbox',
            elementConfig : {
                checked : false,
                color : 'primary',
                label : "Multi Combination"
            },
        },
        "car" : {
            elementType : 'checkbox',
            elementConfig : {
                checked : false,
                color : 'primary',
                label : "Car"
            },
        },
        "motorcycle" : {
            elementType : 'checkbox',
            elementConfig : {
                checked : false,
                color : 'primary',
                label : "Motorcycle"
            },
        },
        "marine" : {
            elementType : 'checkbox',
            elementConfig : {
                checked : false,
                color : 'primary',
                label : "Marine"
            },
        }
    },
    fatigueTypesCheckboxes : {
        /*
        "select_all" : {
            elementType : 'checkbox',
            elementConfig : {
                checked : false,
                color : 'primary',
                label : "Select All"
            },
        },
        "basic_fatigue" : {
            elementType : 'checkbox',
            elementConfig : {
                checked : false,
                color : 'primary',
                label : "BFM"
            },
        },
        "advanced_fatigue" : {
            elementType : 'checkbox',
            elementConfig : {
                checked : false,
                color : 'primary',
                label : "AFM"
            },
        }
         */
        "bfms" : {
            elementType : 'input',
            elementConfig : {
                type : 'text',
                label : "BFM Accreditation Number"
            },
            value : '',
            maxLength: 30
        },
        "afms" : {
            elementType : 'input',
            elementConfig : {
                type : 'text',
                label : "AFM Accreditation Number"
            },
            value : '',
            maxLength: 30
        }
    },
    licensingModuleCheckBoxes : {
        // "select_all" : {
        //     elementType : 'checkbox',
        //     elementConfig : {
        //         checked : false,
        //         color : 'primary',
        //         label : "Select All"
        //     },
        // },
        "ewd" : {
            elementType : 'checkbox',
            elementConfig : {
                checked : false,
                color : 'primary',
                label : "EWD"
            },
        },
        // "business_forms" : {
        //     elementType : 'checkbox',
        //     elementConfig : {
        //         checked : false,
        //         color : 'primary',
        //         label : "BUSINESSFORMS"
        //     },
        // }
    },
    adminCheckBoxes : {
        "driver_is_nhvr_testing" : {
            elementType : 'checkbox',
            elementConfig : {
                checked : false,
                color : 'primary',
                label : "NHVR Testing"
            },
        }
    },
    contractorCheckBoxes : {
        "driver_is_contractor" : {
            elementType : 'checkbox',
            elementConfig : {
                checked : false,
                color : 'primary',
                label : "Is Contractor"
            },
        }
    },
    openAdminStatus : false,
    addFatigueSelectAllStatus : false,
    addDriverFormFields : {
        'driver_first_name' : {
            elementType : 'input',
            elementConfig : {
                type : 'text',
                label : 'Driver First Name'
            },
            value : '',
            errorStatus : false,
            errorMessage : "Enter Driver First Name",
            maxLength : 60
        },
        'driver_last_name' : {
            elementType : 'input',
            elementConfig : {
                type : 'text',
                label : 'Driver Last Name'
            },
            value : '',
            errorStatus : false,
            errorMessage : "Enter Driver Last Name",
            maxLength : 60
        },
        'driver_other_name' : {
            elementType : 'input',
            elementConfig : {
                type : 'text',
                label : 'Driver Other Name'
            },
            value : '',
            errorStatus : false,
            errorMessage : "Enter Driver Other Name",
            maxLength : 60
        },
        'license_number' : {
            elementType : 'input',
            elementConfig : {
                type : 'text',
                label : 'License Number'
            },
            value : '',
            errorStatus : false,
            errorMessage : "Enter License Number",
            maxLength:10
        },
        'dob' : {
            elementType : 'date',
            elementConfig : {
                type : 'number',
                label : 'Date Of Birth'
            },
            value : null,
            errorStatus : false,
            errorMessage : "Enter Date Of Birth",
            disabledStatus : false
        },
        'email' : {
            elementType : 'input',
            elementConfig : {
                type : 'email',
                label : 'Email ID'
            },
            value : '',
            errorStatus : false,
            errorMessage : "Enter Email ID",
            maxLength : 60
        },
        "country" : {
            elementType : 'select',
            elementConfig : {
                type : 'number',
                label : 'Country'
            },
            value : 'AUS',
            errorStatus : false,
            errorMessage : "Select Country",
            menuItems : {
                'select_country' : {
                    name : "Select Country",
                    value : ''
                },
                'aus' : {
                    name : "Australia",
                    value : "AUS"
                }
            }
        },
        "state" : {
            elementType : 'select',
            elementConfig : {
                type : 'text',
                label : 'State'
            },
            value : '',
            errorStatus : false,
            errorMessage : "Enter State",
            menuItems : {
                'select_state' : {
                    name : "Select State",
                    value : ''
                },
                'nsw' : {
                    name : "NSW",
                    value : "NSW"
                },
                'qld':{
                    name : "QLD",
                    value : "QLD"
                },
                'sa' : {
                    name : "SA",
                    value : "SA"
                },
                'tas':{
                    name : "TAS",
                    value : "TAS"
                },
                'vic' : {
                    name : "VIC",
                    value : "VIC"
                },
                'act' : {
                    name : 'ACT',
                    value : 'ACT'
                },
                'nt' : {
                    name : 'NT',
                    value : 'NT'
                },
                'wa':{
                    name : "WA",
                    value : "WA"
                },
                'ashmore island':{
                    name : "ASHMORE ISLAND",
                    value : "ASHMORE ISLAND"
                },
                'cartier island':{
                    name : "CARTIER ISLAND",
                    value : "CARTIER ISLAND"
                },
                'christmas island':{
                    name:'CHRISTMAS ISLAND',
                    value:'CHRISTMAS ISLAND'
                },
                'cocos island':{
                    name:'COCOS ISLAND',
                    value:'COCOS ISLAND'
                },
                'coral sea island':{
                    name:'CORAL SEA ISLAND',
                    value:'CORAL SEA ISLAND'
                },
                'heard island':{
                    name:'HEARD ISLAND',
                    value:'HEARD ISLAND'
                },
                'mcdonald island':{
                    name:'MCDONALD ISLAND',
                    value:'MCDONALD ISLAND'
                },
                'oth':{
                    name : "OTH",
                    value : "OTH"
                }
            },
            disabledStatus : false
        },
        'baseLocation' : {
            elementType : 'input',
            elementConfig : {
                type : 'text',
                label : 'Base Location'
            },
            value : '',
            errorStatus : false,
            errorMessage : "Enter Driver Base Location",
            maxLength : 500
        },
        'recordKeeper' : {
            elementType : 'input',
            elementConfig : {
                type : 'text',
                label : 'Record Keeper'
            },
            value : '',
            errorStatus : false,
            errorMessage : "Enter Record Keeper",
            maxLength : 500
        },
        'homeBaseTimeZone' : {
            elementType : 'select',
            elementConfig : {
                type : 'text',
                label : 'Home Base Time Zone'
            },
            value : '',
            errorStatus : false,
            errorMessage : "Select Home Base Time Zone",
            maxLength : 500,
            menuItems : {
                'VIC': {
                    name: "VIC",
                    value: "VIC"
                },
                'NSW': {
                    name: "NSW",
                    value: "NSW"
                },
                'TAS': {
                    name: "TAS",
                    value: "TAS"
                },
                'ACT': {
                    name: "ACT",
                    value: "ACT"
                },
                'QLD': {
                    name: "QLD",
                    value: "QLD"
                },
                'SA': {
                    name: "SA",
                    value: "SA"
                },
                'NT': {
                    name: "NT",
                    value: "NT"
                },
                'BROKEN_HILL': {
                    name: "BROKEN HILL",
                    value: "BROKEN_HILL"
                },
                'EUCLA': {
                    name: "EUCLA",
                    value: "EUCLA"
                },
                'WA': {
                    name: "WA",
                    value: "WA"
                }
            }
        },
        'integrationId' : {
            elementType : 'input',
            elementConfig : {
                type : 'text',
                label : 'Integration ID'
            },
            value : '',
            errorStatus : false,
            errorMessage : "Enter ID",
            maxLength : 60
        },
    },
    addDriverSelectAllStatus : false,
    openLinkDriverDialogstatus : false,
    openEditDriverDialogStatus : false,
    driverInformations:[],
    showLoaderStatus : false,
    driverInfoCols : [
        {
            title: 'Status',
            field: 'statusIcon'
        },
        {
            title: 'Driver ID',
            field: 'id'
        },
        {
            title: 'Driver Name',
            field: 'name'
        },
        {
            title: 'License Number',
            field: 'license_number'
        },
        {
            title: 'Country',
            field: 'license_country',
            lookup: {
                'AUS': 'Australia'
               },
        },
        {
            title: 'State',
            field: 'license_state',
            lookup : {
                "NSW" : "NSW",
                "QLD" : "QLD",
                "SA" : "SA",
                "TAS" : "TAS",
                "VIC" : "VIC",
                "WA" : "WA",
                "OTH":"OTH",
                "ACT" : "ACT",
                "NT" : "NT",
                'ASHMORE ISLAND':"ASHMORE ISLAND",
                'CARTIER ISLAND': "CARTIER ISLAND",
                'CHRISTMAS ISLAND':'CHRISTMAS ISLAND',
                'COCOS ISLAND ':'COCOS ISLAND ',
                'CORAL SEA ISLAND':'CORAL SEA ISLAND',
                'HEARD ISLAND':'HEARD ISLAND',
                'MCDONALD ISLAND':'MCDONALD ISLAND'
            }
        }
    ],
    selectCountryValue : "",
    selectCountryErrorStatus : false,
    selectCountryErrorMessage : "Please Select Country",
    selectStateValue : "",
    selectStateErrorStatus : false,
    selectStateErrorMessage : "Please Select State",
    searchErrorToastMessage : '',
    searchDriverInfo : {},
    linkDriverStatus : null,
    showListDriverLoaderStatus : false,
    bulkUploadedDrivers : [],
    driverUploadStatusTableColumns : [
        {
            title : 'Driver Firstname',
            field : 'driver_firstname'
        },
        {
            title : 'Driver Lastname',
            field : 'driver_lastname'
        },
        {
            title : 'Driver License',
            field : 'driver_license'
        },
        {
            title : 'Status',
            field : 'status'

        }
    ],
    driverUploadStatusList : [],
    driverBulkUploadSubmitLoaderStatus : false,
    searchDriverLoaderStatus : false,
    driverIdForEdit : '',
    driverUpdateLoaderStatus : false,
    activeDriverRecordForLink : '',
    deleteDriverLoaderStatus : false,
    driverLinkLoaderStatus : false,
    getDriverLicenseStatusLoaderStatus : false,
    depots:[],
    toggleViewDepotsDialogStatus:false,
    toggleAddDepotsDialogStatus:false,
    mapDepot:{
        selectedItem:"",
        selectedDriver:"",
        error:""
    }
}

const reducerBusiness = (state = initialState, action) => {
    let localState = Object.assign({}, state);
    switch(action.type) {
        case SET_DEFAULT_VALUE_TO_ADD_DRIVER_COMPONENT :
            localState.driverInformations = undefined;
            if(!localState.openEditDriverDialogStatus && !localState.openLinkDriverDialogstatus){
                Object.keys(localState.addDriverFormFields).map(inputField => {
                    if(inputField !== 'dob'){
                        localState.addDriverFormFields[inputField].disabledStatus = false;
                        localState.addDriverFormFields[inputField].value = '';
                        localState.addDriverFormFields[inputField].errorStatus = false;
                    }else{
                        localState.addDriverFormFields[inputField].errorStatus = false;
                        localState.addDriverFormFields[inputField].disabledStatus = false;
                        localState.addDriverFormFields[inputField].value = null;
                    }

                })

                Object.keys(localState.driverTypesCheckBoxes).map(checkBox => {
                    localState.driverTypesCheckBoxes[checkBox].elementConfig.disabled = false;
                    localState.driverTypesCheckBoxes[checkBox].elementConfig.checked = false;
                })
                Object.keys(localState.fatigueTypesCheckboxes).map(inputField => {
                    localState.fatigueTypesCheckboxes[inputField].errorStatus = false;
                    localState.fatigueTypesCheckboxes[inputField].disabledStatus = false;
                    localState.fatigueTypesCheckboxes[inputField].value = null;
                })

                Object.keys(localState.addDriverFormFields).map(inputField => {
                    if(inputField !== 'dob'){
                        localState.addDriverFormFields[inputField].disabledStatus = false;
                        localState.addDriverFormFields[inputField].value = '';
                        localState.addDriverFormFields[inputField].errorStatus = false;
                    }else{
                        localState.addDriverFormFields[inputField].errorStatus = false;
                        localState.addDriverFormFields[inputField].disabledStatus = false;
                        localState.addDriverFormFields[inputField].value = null;
                    }

                })

                Object.keys(localState.licensingModuleCheckBoxes).map(checkBox => {
                    localState.licensingModuleCheckBoxes[checkBox].elementConfig.disabled = false;
                    localState.licensingModuleCheckBoxes[checkBox].elementConfig.checked = false;
                })
                Object.keys(localState.adminCheckBoxes).map(checkBox => {
                    localState.adminCheckBoxes[checkBox].elementConfig.disabled = false;
                    localState.adminCheckBoxes[checkBox].elementConfig.checked = false;
                })
                Object.keys(localState.contractorCheckBoxes).map(checkBox => {
                    localState.contractorCheckBoxes[checkBox].elementConfig.disabled = false;
                    localState.contractorCheckBoxes[checkBox].elementConfig.checked = false;
                })
            }
            localState.driverInformations = action.driverInformations;
            return localState;
        case POST_CLEAR_ALL_SEARCH_DRIVER_FIELD:
            Object.keys(localState.searchDriverForm).map(inputField => {
                if(inputField==="dob"){
                    localState.searchDriverForm[inputField].value = null;
                }else{
                    localState.searchDriverForm[inputField].value = '';
                }
            })
            return localState;
        case POST_UNLINK_DRIVER :
            localState.deleteDriverLoaderStatus = action.deleteDriverLoaderStatus;
            if(!action.deleteDriverLoaderStatus){
                localState.openEditDriverDialogStatus = false;
            }
            return localState;
        case SET_UPDATED_DRIVER_DATA :
            localState.driverUpdateLoaderStatus = action.driverUpdateLoaderStatus;
            localState.openEditDriverDialogStatus = action.openEditDriverDialogStatus;
            localState.driverInformations = action.driverInformations;
            return localState;
        case SET_DRIVER_TYPE_CHECKBOXES :
            let selectAllDriverTypeStatus = true;
            localState.driverTypesCheckBoxes[action.formElementId].elementConfig.checked = !localState.driverTypesCheckBoxes[action.formElementId].elementConfig.checked;
            let driverTypeWithoutSelectAll = {...localState.driverTypesCheckBoxes};
            delete driverTypeWithoutSelectAll.select_all;
            Object.keys(driverTypeWithoutSelectAll).map(checkbox => {
                if(localState.driverTypesCheckBoxes[checkbox].elementConfig.checked === false){
                    selectAllDriverTypeStatus = false;
                }
            })
            if(action.formElementId !== 'select_all'){
                if(selectAllDriverTypeStatus){
                    localState.driverTypesCheckBoxes['select_all'].elementConfig.checked = true;
                }else{
                    localState.driverTypesCheckBoxes['select_all'].elementConfig.checked = false;
                }
            }
            if(action.formElementId === 'select_all'){
                if(localState.driverTypesCheckBoxes['select_all'].elementConfig.checked){
                    Object.keys(localState.driverTypesCheckBoxes).map(checkbox => {
                        localState.driverTypesCheckBoxes[checkbox].elementConfig.checked = true;
                    })
                }else{
                    Object.keys(localState.driverTypesCheckBoxes).map(checkbox => {
                        localState.driverTypesCheckBoxes[checkbox].elementConfig.checked = false;
                    })
                }
            }
            return localState;
            case POST_HANDLE_LICENSING_MODULE_CHECKBOXES :
            localState.licensingModuleCheckBoxes[action.formElementId].elementConfig.checked = !localState.licensingModuleCheckBoxes[action.formElementId].elementConfig.checked;
            return localState;
        case POST_HANDLE_ADMIN_CHECKBOXES :
            localState.adminCheckBoxes[action.formElementId].elementConfig.checked = !localState.adminCheckBoxes[action.formElementId].elementConfig.checked;
            return localState;
        case POST_HANDLE_CONTRACTOR_CHECKBOXES :
            localState.contractorCheckBoxes[action.formElementId].elementConfig.checked = !localState.contractorCheckBoxes[action.formElementId].elementConfig.checked;
            return localState;
        case SET_FATIGUE_TYPE_CHECKBOXES :
            localState.fatigueTypesCheckboxes[action.formElementId].value = action.data;
            return localState;
        case SET_INPUT_DOB :
            localState.addDriverFormFields[action.formElementId].value = action.date;
            if(localState.addDriverFormFields[action.formElementId].value === null){
                localState.addDriverFormFields[action.formElementId].errorStatus = true;
            }else{
                localState.addDriverFormFields[action.formElementId].errorStatus = false;
            }
            return localState;
        case SET_ADD_DRIVER_INPUT_FIELD :
            localState.addDriverFormFields[action.formElementId].value = action.data;
            if(localState.addDriverFormFields[action.formElementId].value === ''){
                localState.addDriverFormFields[action.formElementId].errorStatus = true;
            }else{
                localState.addDriverFormFields[action.formElementId].errorStatus = false;
            }
            localState.addDriverFormFields['driver_other_name'].errorStatus = false;
            localState.addDriverFormFields['integrationId'].errorStatus = false;
            return localState;
        case SET_DEFAULT_VALUE_TO_DRIVER_BULK_UPLOAD_FORM :
            localState.driverUploadStatusList = [];
            Object.keys(localState.uploadDriverForms).map(inputField => {
                localState.uploadDriverForms[inputField].errorStatus = false;
                localState.uploadDriverForms[inputField].value = ''
            })
            return localState;
        case SET_DEFAULT_VALUE_TO_SEARCH_DRIVER_COMPONENT :
            Object.keys(localState.searchDriverForm).map((inputField) => {
                localState.searchDriverForm[inputField].value = null;
            })
            localState.openLinkDriverDialogstatus = false;
            localState.driverLinkLoaderStatus = false;
            return localState;
        case POST_DRIVER_UPLOADED_DATA :
            Object.keys(localState.uploadDriverForms).map(inputField => {
                if(localState.uploadDriverForms[inputField].value === ''){
                    localState.uploadDriverForms[inputField].errorStatus = true;
                }
            })
            localState.driverBulkUploadSubmitLoaderStatus = action.driverBulkUploadSubmitLoaderStatus;
            localState.driverUploadStatusList = action.driverUploadStatusList;
            return localState;
        case SET_INPUTHANDLER_UPLOAD_DRIVER :
            localState.uploadDriverForms[action.formElementId].value = action.data;
            if(action.bulkUploadedDrivers === undefined){
                localState.bulkUploadedDrivers = [];
            }else{
                localState.bulkUploadedDrivers = action.bulkUploadedDrivers;
            }
            return localState;
        case LINK_DRIVER_TO_BUSINESS :
            localState.driverLinkLoaderStatus = action.driverLinkLoaderStatus;
            if(!action.driverLinkLoaderStatus){
                localState.openLinkDriverDialogstatus = false;
            }
            return localState;
        case SET_ALL_TEXTFIELD_ENABLE :
            Object.keys(localState.searchDriverForm).map((textField) => {
                localState.searchDriverForm[textField].disabledStatus = false;
            })
            return localState;
        case SET_ALL_DRIVER_LIST :
            localState.showListDriverLoaderStatus = action.showListDriverLoaderStatus;
            localState.driverInformations = action.driverList;
            return localState;
        case DRIVER_SET_SEARCH_FOUND_STATUS_TO_NULL :

            Object.keys(localState.searchDriverForm).map((textField) => {
                localState.searchDriverForm[textField].disabledStatus = false;
            })

            Object.keys(localState.addDriverFormFields).map(inputField => {
                if(inputField !== 'dob'){
                    localState.addDriverFormFields[inputField].disabledStatus = false;
                    localState.addDriverFormFields[inputField].value = '';
                    localState.addDriverFormFields[inputField].errorStatus = false;
                }else{
                    localState.addDriverFormFields[inputField].errorStatus = false;
                    localState.addDriverFormFields[inputField].disabledStatus = false;
                    localState.addDriverFormFields[inputField].value = null;
                }

            })

            Object.keys(localState.driverTypesCheckBoxes).map(checkBox => {
                localState.driverTypesCheckBoxes[checkBox].elementConfig.disabled = false;
                localState.driverTypesCheckBoxes[checkBox].elementConfig.checked = false;
            })
            Object.keys(localState.fatigueTypesCheckboxes).map(inputField => {
                localState.fatigueTypesCheckboxes[inputField].errorStatus = false;
                localState.fatigueTypesCheckboxes[inputField].disabledStatus = false;
                localState.fatigueTypesCheckboxes[inputField].value = null;
            })

            Object.keys(localState.addDriverFormFields).map(inputField => {
                if(inputField !== 'dob'){
                    localState.addDriverFormFields[inputField].disabledStatus = false;
                    localState.addDriverFormFields[inputField].value = '';
                    localState.addDriverFormFields[inputField].errorStatus = false;
                }else{
                    localState.addDriverFormFields[inputField].errorStatus = false;
                    localState.addDriverFormFields[inputField].disabledStatus = false;
                    localState.addDriverFormFields[inputField].value = null;
                }

            })

            Object.keys(localState.licensingModuleCheckBoxes).map(checkBox => {
                localState.licensingModuleCheckBoxes[checkBox].elementConfig.disabled = false;
                localState.licensingModuleCheckBoxes[checkBox].elementConfig.checked = false;
            })
            Object.keys(localState.adminCheckBoxes).map(checkBox => {
                localState.adminCheckBoxes[checkBox].elementConfig.disabled = false;
                localState.adminCheckBoxes[checkBox].elementConfig.checked = false;
            })
            Object.keys(localState.contractorCheckBoxes).map(checkBox => {
                localState.contractorCheckBoxes[checkBox].elementConfig.disabled = false;
                localState.contractorCheckBoxes[checkBox].elementConfig.checked = false;
            })

            localState.searchFoundStatus = null;
            return localState;
        case MAKE_DRIVER_TOAST_STATUS_NULL :
            return localState;
        case SUBMIT_DRIVER_INFO:
            Object.keys(localState.addDriverFormFields).map(formField => {
                if(localState.addDriverFormFields[formField].value === '' || localState.addDriverFormFields[formField].value === null){
                    localState.addDriverFormFields[formField].errorStatus = true;
                }else{
                    localState.addDriverFormFields[formField].errorStatus = false;
                }
            })
            localState.addDriverFormFields['driver_other_name'].errorStatus = false;
            localState.addDriverFormFields['integrationId'].errorStatus = false;
            localState.showLoaderStatus = action.showLoaderStatus;
            return localState;
        case MANAGE_EDIT_DRIVER_DIALOG_CLOSE :
            localState.openEditDriverDialogStatus = false;
            return localState;
        case MANAGE_EDIT_DRIVER_OPEN_DIALOG :
            localState.getDriverLicenseStatusLoaderStatus = action.getDriverLicenseStatusLoaderStatus;
            localState.driverIdForEdit = action.driverInfo["id"];
            localState.driverForEditLinkStatus = action.driverInfo["linkage"];

            //Driver Type checkboxes
            localState.driverTypesCheckBoxes["light_ridged"].elementConfig.checked = action.driverInfo['driver_is_lightrigid'];
            localState.driverTypesCheckBoxes["medium_ridged"].elementConfig.checked = action.driverInfo['driver_is_meduimrigid'];
            localState.driverTypesCheckBoxes["heavy_ridged"].elementConfig.checked = action.driverInfo['driver_is_heavyrigid'];
            localState.driverTypesCheckBoxes["heavy_combination"].elementConfig.checked = action.driverInfo['driver_is_heavycombination'];
            localState.driverTypesCheckBoxes["multi_combination"].elementConfig.checked = action.driverInfo['driver_is_multicombination'];

            localState.driverTypesCheckBoxes["car"].elementConfig.checked = action.driverInfo['driver_is_car'];
            localState.driverTypesCheckBoxes["motorcycle"].elementConfig.checked = action.driverInfo['driver_is_motorcycle'];
            localState.driverTypesCheckBoxes["marine"].elementConfig.checked = action.driverInfo['driver_is_marine'];

            if(
                localState.driverTypesCheckBoxes["light_ridged"].elementConfig.checked &&
                localState.driverTypesCheckBoxes["medium_ridged"].elementConfig.checked &&
                localState.driverTypesCheckBoxes["heavy_ridged"].elementConfig.checked &&
                localState.driverTypesCheckBoxes["heavy_combination"].elementConfig.checked &&
                localState.driverTypesCheckBoxes["multi_combination"].elementConfig.checked &&
                localState.driverTypesCheckBoxes["car"].elementConfig.checked &&
                localState.driverTypesCheckBoxes["motorcycle"].elementConfig.checked &&
                localState.driverTypesCheckBoxes["marine"].elementConfig.checked
            ){
                localState.driverTypesCheckBoxes["select_all"].elementConfig.checked = true;
            }

            localState.fatigueTypesCheckboxes["bfms"].value = action.driverInfo['bfms'];
            localState.fatigueTypesCheckboxes["afms"].value = action.driverInfo['afms'];

            //Add Driver Textfields
            localState.addDriverFormFields['driver_first_name'].value = action.driverInfo['driver_firstname'];
            localState.addDriverFormFields['driver_last_name'].value = action.driverInfo['driver_lastname'];
            localState.addDriverFormFields['driver_other_name'].value = action.driverInfo['driver_other_name'];
            localState.addDriverFormFields['license_number'].value = action.driverInfo["license_number"];
            localState.addDriverFormFields['dob'].value = action.driverInfo['driver_dob'];
            localState.addDriverFormFields['email'].value = action.driverInfo['driver_email'];
            localState.addDriverFormFields['country'].value = action.driverInfo['license_country'];
            localState.addDriverFormFields['state'].value = action.driverInfo['license_state'];
            localState.addDriverFormFields['baseLocation'].value = action.driverInfo['baseLocation'];
            localState.addDriverFormFields['recordKeeper'].value = action.driverInfo['recordKeeper'];
            localState.addDriverFormFields['homeBaseTimeZone'].value = action.driverInfo['homeBaseTimeZone'];
            localState.addDriverFormFields['integrationId'].value = action.driverInfo['integrationId'];

            Object.keys(localState.addDriverFormFields).map(inputField => {
                localState.addDriverFormFields[inputField].errorStatus = false;
            })

            //Enabled Field
            localState.addDriverFormFields['driver_first_name'].disabledStatus = false;
            localState.addDriverFormFields['driver_last_name'].disabledStatus = false;
            localState.addDriverFormFields['driver_other_name'].disabledStatus = false;
            localState.addDriverFormFields['driver_first_name'].disabledStatus = false;
            localState.addDriverFormFields['driver_first_name'].disabledStatus = false;

            //Disabled Field
            localState.addDriverFormFields['email'].disabledStatus = true;
            localState.addDriverFormFields['state'].disabledStatus = true;
            localState.addDriverFormFields['country'].disabledStatus = true;
            localState.addDriverFormFields['dob'].disabledStatus = true;
            localState.addDriverFormFields['license_number'].disabledStatus = true;
            Object.keys(localState.driverTypesCheckBoxes).map(checkBox => {
                localState.driverTypesCheckBoxes[checkBox].elementConfig.disabled = false;
            })

            //Licensing Module Checkboxes
            localState.licensingModuleCheckBoxes['ewd'].elementConfig.checked = action.licensingStatus['EWD'];

            // admin functions
            localState.adminCheckBoxes["driver_is_nhvr_testing"].elementConfig.checked = action.driverInfo['driver_is_nhvr_testing'];
            localState.contractorCheckBoxes["driver_is_contractor"].elementConfig.checked = action.driverInfo['driver_is_contractor'];
            if(localStorage.getItem('role') == '1'){
                localState.openAdminStatus = true;
            } else {
                localState.openAdminStatus = false;
            }
            // localState.licensingModuleCheckBoxes['business_forms'].elementConfig.checked = action.licensingStatus['BUSINESSFORMS'];

            // if(localState.licensingModuleCheckBoxes['ewd'].elementConfig.checked && localState.licensingModuleCheckBoxes['business_forms'].elementConfig.checked){
            //     localState.licensingModuleCheckBoxes['select_all'].elementConfig.checked = true;
            // }
            localState.openEditDriverDialogStatus = true;
            return localState;
        case MANAGE_LINK_DRIVER_DIALOG_CLOSE :
            localState.openLinkDriverDialogstatus = false;
            return localState;
        case MANGE_LINK_DRIVER_DIALOG :
            localState.activeDriverRecordForLink = action.driverInfo['driver_id'];

            //Mark the fatigue management checkboxes as false
            Object.keys(localState.fatigueTypesCheckboxes).map(checkbox => {
                localState.fatigueTypesCheckboxes[checkbox].elementConfig.checked = false;
            })

            //Driver Type checked status
            localState.driverTypesCheckBoxes["light_ridged"].elementConfig.checked = action.driverInfo['driver_is_lightrigid'];
            localState.driverTypesCheckBoxes["medium_ridged"].elementConfig.checked = action.driverInfo['driver_is_meduimrigid'];
            localState.driverTypesCheckBoxes["heavy_combination"].elementConfig.checked = action.driverInfo['driver_is_multicombination'];
            localState.driverTypesCheckBoxes["car"].elementConfig.checked = action.driverInfo['driver_is_car'];
            localState.driverTypesCheckBoxes["motorcycle"].elementConfig.checked = action.driverInfo['driver_is_motorcycle'];
            localState.driverTypesCheckBoxes["marine"].elementConfig.checked = action.driverInfo['driver_is_marine'];
            localState.driverTypesCheckBoxes["multi_combination"].elementConfig.checked = action.driverInfo['driver_is_multicombination'];
            localState.driverTypesCheckBoxes["heavy_ridged"].elementConfig.checked = action.driverInfo['driver_is_heavyrigid'];


            if(
                localState.driverTypesCheckBoxes["light_ridged"].elementConfig.checked &&
                localState.driverTypesCheckBoxes["medium_ridged"].elementConfig.checked &&
                localState.driverTypesCheckBoxes["heavy_ridged"].elementConfig.checked &&
                localState.driverTypesCheckBoxes["heavy_combination"].elementConfig.checked &&
                localState.driverTypesCheckBoxes["multi_combination"].elementConfig.checked &&
                localState.driverTypesCheckBoxes["car"].elementConfig.checked &&
                localState.driverTypesCheckBoxes["motorcycle"].elementConfig.checked &&
                localState.driverTypesCheckBoxes["marine"].elementConfig.checked
            ){
                localState.driverTypesCheckBoxes["select_all"].elementConfig.checked = true;
            }

            //Licensing Module checked status
            localState.licensingModuleCheckBoxes['ewd'].elementConfig.checked = false;

            //Driver Text Fields
            localState.addDriverFormFields['driver_first_name'].value = action.driverInfo['driver_firstname'];
            localState.addDriverFormFields['driver_last_name'].value = action.driverInfo['driver_lastname'];
            localState.addDriverFormFields['driver_other_name'].value = action.driverInfo['driver_othername'];
            localState.addDriverFormFields['license_number'].value = action.driverInfo['driver_license'];
            localState.addDriverFormFields['dob'].value = Date(action.driverInfo['driver_dob']);
            localState.addDriverFormFields['email'].value = action.driverInfo['driver_email'];
            localState.addDriverFormFields['country'].value = action.driverInfo['driver_country'];
            localState.addDriverFormFields['state'].value = action.driverInfo['driver_state'];
            localState.addDriverFormFields['integrationId'].value = action.driverInfo['integrationId'];

            Object.keys(localState.addDriverFormFields).map(inputField => {
                localState.addDriverFormFields[inputField].errorStatus = false;
            })

            localState.contractorCheckBoxes["driver_is_contractor"].elementConfig.checked = action.driverInfo['driver_is_contractor'];

            //Disabled Fields
            localState.addDriverFormFields['license_number'].elementConfig.disabled = true;
            localState.addDriverFormFields['driver_first_name'].disabledStatus = true;
            localState.addDriverFormFields['driver_last_name'].disabledStatus = true;
            localState.addDriverFormFields['driver_other_name'].disabledStatus = true;
            localState.addDriverFormFields['integrationId'].disabledStatus = true;
            localState.addDriverFormFields['state'].disabledStatus = true;
            localState.addDriverFormFields['country'].disabledStatus = true;
            localState.addDriverFormFields['dob'].disabledStatus = true;
            localState.addDriverFormFields['email'].disabledStatus = true;
            localState.addDriverFormFields['license_number'].disabledStatus = true;
            localState.driverTypesCheckBoxes["light_ridged"].elementConfig.disabled = true;
            localState.driverTypesCheckBoxes["medium_ridged"].elementConfig.disabled = true;
            localState.driverTypesCheckBoxes["heavy_combination"].elementConfig.disabled = true;
            localState.driverTypesCheckBoxes["car"].elementConfig.disabled = true;
            localState.driverTypesCheckBoxes["motorcycle"].elementConfig.disabled = true;
            localState.driverTypesCheckBoxes["marine"].elementConfig.disabled = true;
            localState.driverTypesCheckBoxes["select_all"].elementConfig.disabled = true;
            localState.driverTypesCheckBoxes["multi_combination"].elementConfig.disabled = true;
            localState.driverTypesCheckBoxes["heavy_ridged"].elementConfig.disabled = true;

            localState.openLinkDriverDialogstatus = true;
            return localState;
        case SEARCH_DRIVER :
            Object.keys(localState.searchDriverForm).map((textFieldId) => {
                localState.searchDriverForm[textFieldId].disabledStatus = false;
            })
            localState.searchErrorToastMessage = action.searchErrorToastMessage;
            localState.searchDriverInfo = action.driverInfo;
            localState.searchFoundStatus = action.data;
            localState.searchDriverForm['driver_id'].value = '';
            localState.searchDriverLoaderStatus = action.searchDriverLoaderStatus;
            return localState;
        case SEARCH_DRIVER_DOB_CHANGE:
            localState.searchDriverForm[action.formElementId].value = action.date;
            return  localState;
        case SEARCH_DRIVER_TEXTFIELD_CHANGE :
            localState.searchErrorToastMessage = '';
            if(action.textFieldData != ''){
                Object.keys(localState.searchDriverForm).map((textFieldId) => {
                    if(textFieldId != action.textFieldId){
                        localState.searchDriverForm[textFieldId].disabledStatus = true;
                    }
                })
            }else{
                Object.keys(localState.searchDriverForm).map((textFieldId) => {
                    localState.searchDriverForm[textFieldId].disabledStatus = false;
                })
            }

            localState.searchDriverForm[action.textFieldId].value = action.textFieldData;
            return localState;
        case TOGGLE_VIEW_DEPORT_DIALOG:
            localState.toggleViewDepotsDialogStatus = action.status;
            localState.depots = action.depots;
            return localState;
        case SET_DEPOT_MAP_FIELD:
            localState.mapDepot.selectedItem = action.data;
            localState.mapDepot.error = "";
            return localState;
        case TOGGLE_ADD_DEPORT_DIALOG:
            localState.toggleAddDepotsDialogStatus = action.status;
            localState.depots = action.depots;
            localState.mapDepot.selectedDriver = action.status?action.driver:"";
            return localState;
        default :
            return state;
    }
}

export default reducerBusiness;
