import React, { Component } from 'react';
import MaterialTable from 'material-table';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import FormGroup from '@material-ui/core/FormGroup';
import Input from "../../components/UI/Input/Input";
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import{ withRouter } from 'react-router-dom';
import Dialog from '@material-ui/core/Dialog';
import AddDriver from './AddDriver';
import CircularProgress from '@material-ui/core/CircularProgress';

import {
    handleTextFieldChange,
    openLinkDriverDialog,
    handleLinkDriverDialogClose,
    onSearch,
    initializeSearchDriverComponent,
    clearAllSearchDriverField, handleInputDOB, handleSearchDOB
} from '../../actions/Driver';
import {
    menuItems
  } from '../../actions/Sidebar';
import SGMaterialTable from '../../components/UI/Table/SGMaterialTable';

class SearchDriver extends Component{
    componentDidMount(){
        this.props.initializeSearchDriverComponent()
    }
    render(){
        let {
            searchDriverForm,
            openLinkDriverDialogstatus,
            searchFoundStatus,
            searchDriverInfo,
            linkDriverStatus,
            searchDriverLoaderStatus
        } = this.props.driverStates;
        let{
            menuItems
        } = this.props.sidebarState;
        const formElementArray = [];
        for(let key in searchDriverForm){
            if (key != 'driver_id') {
                formElementArray.push({
                    id : key,
                    config : searchDriverForm[key]
                })
            }
        }
        let form = (
            <div>
                <FormGroup row className = "pl-3 pr-1">
                    <Grid container spacing={24}>
                        {
                            formElementArray.map((formElement) => {
                                if(formElement.config.elementType === 'date'){
                                    if(formElement.id === 'dob'){
                                        return(
                                            <Grid item xs={12} sm = {4} key = {formElement.id}>
                                                <Input
                                                    key = {formElement.id}
                                                    name = {formElement.config.elementConfig.label}
                                                    elementType={formElement.config.elementType}
                                                    elementConfig={formElement.config.elementConfig}
                                                    value={formElement.config.value}
                                                    menuItems = {formElement.config.menuItems}
                                                    error = {formElement.config.errorStatus}
                                                    helperText = {formElement.config.errorMessage}
                                                    changed={(date) => this.props.handleInputDOB(formElement.id, date)}
                                                    //disabledStatus = {formElement.config.disabledStatus}
                                                    maxLength={formElement.config.maxLength}
                                                />
                                            </Grid>
                                        )
                                    }
                                }else{
                                    return(
                                        <Grid item md={6} key = {formElement}>
                                            <Input
                                                key = {formElement.id}
                                                name = {formElement.config.elementConfig.label}
                                                elementType={formElement.config.elementType}
                                                elementConfig={formElement.config.elementConfig}
                                                value={formElement.config.value}
                                                menuItems = {formElement.config.menuItems}
                                                error = {formElement.config.errorStatus}
                                                helperText = {formElement.config.errorMessage}
                                                changed={(event) => this.props.handleTextFieldChange(formElement.id,event)}
                                                maxLength = {formElement.config.maxLength}
                                            />
                                        </Grid>
                                    )
                                }
                            })
                        }
                    </Grid>
                </FormGroup>
                <FormGroup row className = "pl-3 pr-1">
                    <Grid
                        container
                        spacing={24}
                        direction="row"
                        justify="flex-end"
                        alignItems="center"
                    >
                        <Grid item>
                        {
                            searchDriverLoaderStatus ? <CircularProgress color = "primary" /> :
                            <div>
                                <Button
                                    disabled = {searchDriverForm['license_number'].value || searchDriverForm['dob'].value?false:true}
                                    variant="contained"
                                    color = "primary"
                                    className = "mr-3"
                                    onClick = {() => this.props.clearAllSearchDriverField()}
                                    >
                                    <i className="material-icons" style = {{marginRight : "0.3rem", marginLeft : "-0.5rem", fontSize : "1.2rem"}}>
                                        clear_all
                                    </i>
                                    Clear All
                                </Button>

                                <Button
                                    disabled = {searchDriverForm['license_number'].value || searchDriverForm['dob'].value ? false:true}
                                    variant="contained"
                                    color = "primary"
                                    onClick = {() => this.props.onSearch(searchDriverForm, this.props, menuItems['driver_create'].urlpattern)}>
                                    <i className="material-icons" style = {{marginRight : "0.3rem", marginLeft : "-0.5rem", fontSize : "1.2rem"}}>
                                        search
                                    </i>
                                    Search
                                </Button>
                            </div>
                        }


                        </Grid>
                    </Grid>
                </FormGroup>
            </div>

        )
        return(
            <div className = "container">
                <Card className = "pl-3 pr-3 pt-3 mb-3">
                    <CardHeader
                        title="Search Drivers"
                    />
                    <CardContent>
                        {form}
                    </CardContent>
                </Card>

                <Dialog
                    open={openLinkDriverDialogstatus && (linkDriverStatus!= true)}
                    onClose={() => {this.props.handleLinkDriverDialogClose()}}
                    maxWidth = 'md'
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <AddDriver/>
                </Dialog>
                {
                    (searchFoundStatus && searchDriverInfo.drivers.length > 0) ?
                    <SGMaterialTable
                        columns={searchDriverInfo.columns}
                        data={searchDriverInfo.drivers}
                        title=""
                        actions={[
                            {
                              icon: 'link',
                              tooltip: 'Link',
                              onClick: (event, rowData) => this.props.openLinkDriverDialog(rowData)
                            },
                        ]}
                        options={{
                            actionsColumnIndex: -1,
                            search: true
                        }}
                    />
                    : null
                }
            </div>
        )
    }
}

const mapStateToProps = state => {
    return{
        driverStates : state.driver,
        sidebarState : state.sidebarState
    }
}

const mapDispatchToProps = dispatch => {
    return{
        clearAllSearchDriverField : () => {
            return dispatch(clearAllSearchDriverField())
        },
        onSearch : (searchDriverForm, props, url) => {
            return dispatch(onSearch(searchDriverForm, props, url))
        },
        handleTextFieldChange : (textField, event) => {
            if(textField === 'license_number'){
                event.target.value = event.target.value.replace(/[^\w\s]/gi, '');
            }
            return dispatch(handleTextFieldChange(textField, event))
        },
        openLinkDriverDialog : (rowData) => {
            return dispatch(openLinkDriverDialog(rowData))
        },
        handleLinkDriverDialogClose : () => {
            return dispatch(handleLinkDriverDialogClose())
        },
        initializeSearchDriverComponent : () => {
            return dispatch(initializeSearchDriverComponent())
        },
        handleInputDOB : (formElementId, date) => {
            return dispatch(handleSearchDOB(formElementId, date))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SearchDriver));
