import React, {Component} from 'react';
import SGMateriaTable from '../../components/UI/Table/SGMaterialTable';
import { connect } from 'react-redux';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';
import Dialog from '@material-ui/core/Dialog';
import Round from "@material-ui/icons/FiberManualRecordRounded";
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded'
import CircularProgress from '@material-ui/core/CircularProgress';
import CancelRounded from '@material-ui/icons/CancelRounded';
import { withRouter } from 'react-router-dom';
import DateTimePicker from 'react-datetime-picker';
import moment from 'moment-timezone';

import AddDriver from "./AddDriver";
import BusinessFirstLoginStepper from "../Business/BusinessOnBoard/BusinessFirstLoginStepper";
import ChangePassword from "../../components/ChangePassword/ChangePassword";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import Input from "../../components/UI/Input/Input";



import {
    handleAddHistoricEwdEventDialog,
    handleEditDriverOpenDialog,
    handleEditDriverDialogClose,
    makeSearchFoundStatusNull,
    getDriverList, manageEditDriverOpenDialog,
    toggleViewDepotsDialog,
    toggleAddDepotsDialog,depotInputHandler
} from '../../actions/Driver';

import {getDepots,linkDepotToDriver,deLinkDepot } from '../../actions/Depot'
import {
    getBusinessMenus
  } from '../../actions/Sidebar';
import FormGroup from "@material-ui/core/FormGroup/FormGroup";
import axios from "../../axios";
import {toast} from "react-toastify";
import { FormControl } from '@material-ui/core';
import {roles} from "../../constants/roles";


class DriverList extends Component{    

      RegionTimeZone = {
        "VIC": "Australia/Victoria",
        "QLD": "Australia/Queensland",
        "NSW": "Australia/NSW",
        "TAS": "Australia/Tasmania",
        "ACT": "Australia/ACT",
        "SA" :  "Australia/South",
        "NT" :  "Australia/North",
        "BROKEN_HILL" : "Australia/Broken_Hill",
        "EUCLA" : "Australia/Eucla",
        "WA" : "Australia/West"        
    }

    state = { isOpenHistoricalEwdDialog: false, isOpenSearchHistoricDialog: false, date: new Date(), currentHistoricEvents: [] , isOpenEwdSubscriptionDialog: false,
        twoUpDriverName: "",
        twoUpHomeBaseTimeZone: "",
        twoUpFatiguePlan: "",
        twoUpWorkBookNumberAndPageNumber: "",
        twoUpLicenseState: "",
        twoUpLicenseNumber: ""
    };

    onChange = (date) => {
        let oldState = {...this.state}
        oldState.date = date;   
        this.setState({...oldState})
        
    }

    navigateToDriverMedicalTeatmentRecord = (rowData, url) => {
        let actulaURL = '';
        localStorage.setItem('driver_id', rowData.id)
        if(url.indexOf(":driverid") >= 0){
            actulaURL = url.replace(":driverid",localStorage.getItem('driver_id'));
        }
        if(actulaURL !== ''){
            url = actulaURL;
        }
        this.props.history.push(url);
    }
    navigateToDriverExternalSystemRecord = (rowData, url) => {
        let actulaURL = '';
        localStorage.setItem('_driver_id', rowData._id)
        localStorage.setItem('driver_id', rowData.id)
        if(url.indexOf(":driverid") >= 0){
            actulaURL = url.replace(":driverid",localStorage.getItem('driver_id'));
        }
        if(actulaURL !== ''){
            url = actulaURL;
        }
        this.props.history.push(url);
    }
    changeDriverStatusColour = (data) => {       
        var status = data['linkStatus'];

        if(status != undefined){
            if(status === 'PENDING'){
                data['statusIcon'] = <Round style={{ color: 'yellow' }}/>
             }else if((status.indexOf('DELINKED') != -1) || status.indexOf('DRIVER DECLINED') != -1 ){
                data['statusIcon'] = <CancelRounded style={{ color: 'red' }} />
             }else if(status.indexOf('DELINKED') === -1 && status.indexOf('LINKED') != -1 ){
                 if(data.driver_is_contractor){
                    data['statusIcon'] = <CheckCircleRoundedIcon style={{ color: 'purple' }} />
                 }else{
                    data['statusIcon'] = <CheckCircleRoundedIcon style={{ color: 'green' }} />
                 }
                
             } 
        }        
       
        return data;
    }
    componentDidMount(){
        this.props.getBusinessMenus(this.props);
        this.props.getDriverList();
        this.props.getDepotList();
        let oldState = {...this.state};
        oldState.activity="Work";
        oldState.wrOption = "Standard Heavy"
        this.setState({...oldState})
    } 
  
   
    navigateToSearchDriver = (url) => {
        this.props.makeSearchFoundStatusNull();
        this.props.history.push(url);
    }

    openViewHistoricalEwdDialog = (driverInfo) => {
        this.setState({ isOpenSearchHistoricDialog: true });
        this.setState({ driverInfo: driverInfo });
        // Get
        let reqUrl = '/drivers/' + driverInfo.id + '/ewdevents';
        let headers = {'Authorization': localStorage.getItem('token')};
        axios.get(reqUrl, {headers}).then(response => {
            let driversLocal = response ? (response.data ? response.data.data : []) : [];
            let historicOrigin = [];
            driversLocal.forEach(e => {
                if (e.origin == 'Historic') {
                    if (e.eventStatus == 'D')
                        e.eventStatus = 'Deleted';
                    else if (e.eventStatus == 'N')
                        e.eventStatus = 'New';

                    e.time = e.device_datetime_offset;//moment(e.device_datetime_offset).format("HH:mm");

                    historicOrigin.push(e);
                }
            })
            this.setState({currentHistoricEvents: historicOrigin});
        }).catch(error => {
            let message = typeof error === 'object' ? error.toString() : error.toString(); 
            toast.error(message, {
                containerId: 'toastMsg',
            });
        })
    }

    markHistoricEventAsDeleted = (ewdInfo) => {
        let ewdArray = [{
            "operation_type": "U",
            "vehicle_id": ewdInfo.vehicle_id,
            "event_type": ewdInfo.event_type,
            "device_datetime_offset": ewdInfo.device_datetime_offset,
            "created_time": ewdInfo.created_time,
            "location": ewdInfo.location,
            "odometer": ewdInfo.odometer + "",
            "comments": ewdInfo.comments,
            "entry_type": ewdInfo.entry_type,
            "fatigue_type": ewdInfo.fatigue_type,
            "is_breach": false,
            "is_tampered": false,
            "event_id": ewdInfo.event_id,
            "city": ewdInfo.city,
            "state": ewdInfo.state,
            "severity": ewdInfo.severity,
            "eventStatus": "D",
            "annotation": {},
            "isLocked": true,
            "vehicleType": ewdInfo.vehicleType,
            "latitude": ewdInfo.latitude + "",
            "longitude": ewdInfo.longitude + "",
            "driver_id": this.state.driverInfo.id,
            "homeBaseTimeZone": ewdInfo.homeBaseTimeZone ? ewdInfo.homeBaseTimeZone : '',
            "buildVersion": ewdInfo.buildVersion,
            "platformUser": localStorage.getItem('Platform-User')}];
        let reqURL = "/drivers/ewdevents";
        axios.post(reqURL, ewdArray, {'Authorization': localStorage.getItem('token'), 'Content-Type': 'application/json'}).then(response => {
            toast.success("Historic EWD Event is marked Deleted.", {
                containerId: 'toastMsg',
            });
            this.setState({ isOpenSearchHistoricDialog: false });
        }).catch(error => {
            let message = typeof error === 'object' ? error.response.data.message.toString() : error.toString(); 
            toast.error(message, {
                containerId: 'toastMsg',
            });
        });
    }

    closeViewHistoricalEwdDialog = () => {
        this.setState({ isOpenSearchHistoricDialog: false });
        this.setState({ currentHistoricEvents: [] });
    }

    openHistoricalEwdDialog = (driverInfo) => {
        this.setState({ isOpenHistoricalEwdDialog: true });
        this.setState({ driverInfo: driverInfo });
    }

    closeHistoricalEwdDialog = () => {
        this.setState({ isOpenHistoricalEwdDialog: false });
    }

    openEwdSubscriptionDialog = (driverInfo) => {
        this.setState({ isOpenEwdSubscriptionDialog: true });
        this.setState({ driverInfo: driverInfo });
    }

    closeEwdSubscriptionDialog = () => {
        this.setState({ isOpenEwdSubscriptionDialog: false });
    }

    setFieldState(stateKey, event){
        let oldState = {...this.state};
        oldState[stateKey] = event.target.value;
        if (stateKey==="odometer"){;
            let v = event.target.value;
            oldState[stateKey] = isNaN(v)?0:v;
        }
        this.setState({
            ...oldState
        })
        console.log(event.target.value)
    }

    getFatigueType(type){
        switch (type){
            case "Standard Bus":
                return "Standard"
            default:
                return type;
        }
    }

    getVehicleType(type){
        switch (type){
            case "Standard Bus":
                return "BUS"
            case "Standard 2-Up":
            case "BFM 2-up":
            case "AFM 2-up":
                return "TWO_UP"
            default:
                return "HEAVY_VEHICLE"
        }
    }

    checkId2UpDriverEvent(type){
        return !!type.toLowerCase().includes("2-up");
    }


    saveHistoricEwdDialog = () => {
        let region = this.RegionTimeZone[this.state.driverInfo.homeBaseTimeZone]       
        if (this.state.vehicleId && this.state.vehicleId != '' && this.state.activity && this.state.activity != '' &&
            this.state.location && this.state.location != '' && this.state.odometer && this.state.odometer != '' &&
            this.state.comments && this.state.comments != '' && this.state.wrOption && this.state.wrOption != '' ) {
                //Set device date time as per drivers homebase timezone
            let ewdArray = [{"operation_type": "I",
                "vehicle_id": this.state.vehicleId,
                "event_type": this.state.activity,
                "device_datetime_offset": region == undefined ? moment(this.state.date.getTime()).format('YYYY-MM-DDTHH:mm:ssZ') : moment(this.state.date.getTime()).tz(region,true).format('YYYY-MM-DDTHH:mm:ssZ'),               
                "created_time":region == undefined ? moment(new Date().getTime()).format('YYYY-MM-DDTHH:mm:ssZ') : moment(new Date().getTime()).tz(region,true).format('YYYY-MM-DDTHH:mm:ssZ'),
                "location": this.state.location,
                "odometer": this.state.odometer,
                "comments": this.state.comments,
                "business_id": localStorage.getItem("lastSelectedBusinessId"),
                "entry_type": "Record Keeper",
                "origin": 'Historic',
                "fatigue_type": this.getFatigueType(this.state.wrOption),
                "is_breach": false,
                "is_tampered": false,
                "event_id": this.state.driverInfo.id + '-' + new Date().getTime(),
                "city": this.state.location,
                "state": this.state.driverInfo.baseLocation ? this.state.driverInfo.baseLocation : '',
                "eventStatus": "N",
                "vehicleType": this.getVehicleType(this.state.wrOption),
                "latitude": "0",
                "longitude": "0",
                "driver_id": this.state.driverInfo.id,
                "homeBaseTimeZone": this.state.driverInfo.homeBaseTimeZone ? this.state.driverInfo.homeBaseTimeZone : '',
                "buildVersion": "",
                "isLocked": true,
                "twoUpDriver":this.checkId2UpDriverEvent(this.state.wrOption),
                "twoUpDriverObject":{
                    driverName: this.state.twoUpDriverName,
                    homeBaseTimeZone: this.state.twoUpHomeBaseTimeZone,
                    fatiguePlan: this.state.twoUpFatiguePlan,
                    workBookNumberAndPageNumber: this.state.twoUpWorkBookNumberAndPageNumber,
                    licenseState: this.state.twoUpLicenseState,
                    licenseNumber: this.state.twoUpLicenseNumber
                },
                "platformUser": localStorage.getItem('Platform-User')}];
            let reqURL = "/drivers/ewdevents";
            debugger;
            axios.post(reqURL, ewdArray, {'Authorization': localStorage.getItem('token'), 'Content-Type': 'application/json'}).then(response => {
                toast.success("Historic EWD Event is saved.", {
                    containerId: 'toastMsg',
                });
                // Clear all field values
                let clearState = {};
                clearState.vehicleId = '';
                clearState.activity = 'Work';
                clearState.wrOption = 'Standard Heavy';
                clearState.location = '';
                clearState.odometer = '';
                clearState.comments = '';
                clearState.date = new Date();
                clearState.twoUpLicenseState = "";
                clearState.twoUpFatiguePlan = "";
                clearState.twoUpLicenseNumber = "";
                clearState.twoUpDriverName = "";
                clearState.twoUpWorkBookNumberAndPageNumber = "";
                clearState.twoUpHomeBaseTimeZone = "";
                this.setState({...clearState});
            }).catch(error => {
                console.log(error.response)
                let message =  error.response ? error.response.data.message : error.toString();
                toast.error(message, {
                    containerId: 'toastMsg',
                });
            })
        } else {
            toast.error("Please fill up all the fields in the form.", {
                containerId: 'toastMsg',
            });
        }

    }

    render(){

        let depotsColumns = [
            {

                title: 'Name',
                field: 'depot.name'
            },
            {
                title: 'Manager',
                field: 'manager[0].user_first_name'
            },
            {
                title: 'Region',
                field: 'depot.region'
            },{
                title: 'State',
                field: 'depot.state'
            },
            {
                title: 'Suburb',
                field: 'depot.suburb'
            },
            {
                title: 'Address',
                field: 'depot.ddress'
            },
            {
                title: 'Geo Code',
                field: 'depot.geocode'
            }
        ];

        let historicColumns = [
            {

                title: 'Event Status',
                field: 'eventStatus'
            },
            {
                title: 'Time',
                field: 'time'
            },
            {
                title: 'Activity',
                field: 'event_type'
            },
            {
                title: 'Location',
                field: 'location'
            },
            {
                title: 'Odometer',
                field: 'odometer'
            },
            {
                title: 'Registration',
                field: 'vehicle_id'
            },
            {
                title: 'W/R Option',
                field: 'fatigue_type'
            },
            {
                title: 'Comments',
                field: 'comments'
            },
            {
                title: 'Origin',
                field: 'origin'
            },
            {
                title: 'Entry Timestamp',
                field: 'created_time_offset'
            },
            {
                title: 'Entry Type',
                field: 'entry_type'
            }
        ];
        let {
            openEditDriverDialogStatus,
            driverInfoCols,
            driverInformations,
            showListDriverLoaderStatus,
            toggleViewDepotsDialogStatus,
            toggleAddDepotsDialogStatus,
            mapDepot,
            depots: driverDepots
        } = this.props.driverState;
        let {
            depots
        } = this.props.depotState;

        let _depots = [];
        for (let index in depots) {
            let depot = depots[index];
            _depots[index] = {
                name: depot.name,
                value: depot._id
            }
        }
        let{
            menuItems
        } = this.props.sidebarState;
        let{
            userFirstLoginStatus
        } = this.props.changePasswordState;
        let mainUrl;
        if(localStorage.getItem('role') === '1'){
            mainUrl = ((window.location.pathname).split('/'))[6];
        }
        if(localStorage.getItem('role') === '2' || localStorage.getItem('role') === '3' || localStorage.getItem('role') === '4'){
            mainUrl = ((window.location.pathname).split('/'))[5];
        }
        if(localStorage.getItem('role') === '5' || localStorage.getItem('role') === '6' || localStorage.getItem('role') === '7'){
            mainUrl = ((window.location.pathname).split('/'))[3];
        }
        return(

            <div className = "container">
                <Dialog
                    open={localStorage.getItem('role') !== '5' && userFirstLoginStatus === 'true'}
                    maxWidth = 'sm'
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">
                    <ChangePassword/>
                </Dialog>
                <div>
                    <BusinessFirstLoginStepper />
                </div>

                <div className = "mt-4 mb-5">
                    <Paper>
                        <Grid container spacing = {24} style = {{paddingLeft : "1rem", paddingTop : "0", paddingRight : "1rem"}}>
                            <Grid item xs = {6} style = {{paddingTop : "0"}}>
                                <h4 style = {{marginTop : "1rem", textTransform : "uppercase"}}>Driver List</h4>
                            </Grid>

                            <Grid item xs = {6} style = {{display : "flex", justifyContent : "flex-end", paddingBottom : "2rem"}}>
                                {
                                    mainUrl !== 'mtdriverlist' && mainUrl !== 'esdriverlist' ?
                                    <div>
                                        {parseInt(roles.DEPOT_MANAGER) !== parseInt(localStorage.getItem('role')) &&

                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick = {() => this.navigateToSearchDriver(menuItems['driver_search'].urlpattern)}
                                        >
                                            <i className="material-icons" style = {{paddingRight : "0.5rem"}}>
                                                add_circle_outline
                                            </i>
                                            Add Driver
                                        </Button>}
                                    </div> : null
                                }

                            </Grid>
                        </Grid>
                        {
                            showListDriverLoaderStatus || openEditDriverDialogStatus ?
                            <div style = {{height : "30rem"}} className = "d-flex justify-content-center align-items-center">
                                <CircularProgress color = "primary" />                                
                            </div>
                                :
                            <SGMateriaTable
                                title=""
                                columns={driverInfoCols}
                                data={driverInformations != undefined ? driverInformations.map((d) => this.changeDriverStatusColour(d)) : driverInformations}                             
                                actions={mainUrl === 'mtdriverlist' ? [
                                    {
                                        icon: 'local_hospital',
                                        tooltip: 'Add Medical Treatment',
                                        onClick: (event, rowData) => this.navigateToDriverMedicalTeatmentRecord(rowData, menuItems['medical_treatment_list'].urlpattern)
                                    }
                                    ] : mainUrl === 'esdriverlist' ? [
                                        {
                                            icon: 'computer',
                                            tooltip: 'Add External System',
                                            onClick: (event, rowData) => this.navigateToDriverExternalSystemRecord(rowData, menuItems['external_driver_list'].urlpattern)
                                        }
                                        ] :
                                        [ 
                                            {
                                                icon: 'edit',
                                                tooltip: 'Edit Driver',
                                                onClick: (event, rowData) => this.props.handleEditDriverOpenDialog(rowData)
                                            },
                                            {
                                                icon: 'insert_chart',
                                                tooltip: 'View Historic EWD Events',
                                                onClick: (event, rowData) => this.openViewHistoricalEwdDialog(rowData)
                                            },
                                            {
                                                icon: 'addchart',
                                                tooltip: 'Add Historic EWD Event',
                                                onClick: (event, rowData) => this.openHistoricalEwdDialog(rowData)
                                            },
                                            {
                                                hidden: (parseInt(roles.DEPOT_MANAGER) === parseInt(localStorage.getItem('role'))),
                                                icon: 'store',
                                                tooltip: 'View Depots',
                                                onClick: (event, rowData) => this.props.toggleViewDepotsDialog(rowData,true)
                                            },
                                            {
                                                hidden: (parseInt(roles.DEPOT_MANAGER) === parseInt(localStorage.getItem('role'))),
                                                icon: 'add_business',
                                                tooltip: 'Add Depots',
                                                onClick: (event, rowData) => this.props.toggleAddDepotsDialog(rowData,true)
                                            }
                                        ]

                                }

                                
                            />
                        }

                    </Paper>
                </div>

                <Dialog
                    open={openEditDriverDialogStatus}
                    onClose={() => {this.props.handleEditDriverDialogClose()}}
                    maxWidth = 'md'
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">
                    <AddDriver/>
                </Dialog>

                <Dialog
                    open={this.state.isOpenSearchHistoricDialog}
                    onClose={() => {this.closeViewHistoricalEwdDialog()}}
                    maxWidth = 'md'
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">
                        
                            <div style={{"margin-left": "30px", "margin-right": "30px", "margin-bottom": "30px"}}>
                                <h4>Historic EWD Events of&nbsp;{this.state.driverInfo ? this.state.driverInfo.driver_firstname : ''}&nbsp;{this.state.driverInfo ? this.state.driverInfo.driver_lastname : ''}</h4>

                                {/* <FormGroup row> */}
                                    <SGMateriaTable
                                        title=""
                                        columns={historicColumns}
                                        data={this.state.currentHistoricEvents}
                                        actions={[{ icon: 'delete', tooltip: 'Mark as Deleted', onClick: (event, rowData) => this.markHistoricEventAsDeleted(rowData)}]}/>
                                {/* </FormGroup> */}
                                <div class='closeButton'>
                                    <button className="MuiButtonBase-root MuiButton-root MuiButton-contained"
                                        onClick={() => this.closeViewHistoricalEwdDialog()}>Close
                                    </button>
                                </div>
                                
                            </div>
                </Dialog>

                <Dialog
                    open={this.state.isOpenHistoricalEwdDialog}
                    onClose={() => {this.closeHistoricalEwdDialog()}}
                    maxWidth = '80%'
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >

           
                    <FormGroup row xs={12}>
                    <div style={{"margin-left": "30px", "width":"900px", "margin-right": "30px", "margin-bottom": "30px"}}>
                        <h4>Add Historic EWD Event</h4>

                        <Grid container spacing={2} direction="column"  _rowSpacing={4}>
                            <Grid container direction="row" spacing={4}>
                                <Grid item xs={4}>
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">Activity</InputLabel>
                                        <Select value={this.state.activity} labelId='demo-simple-select-label' autoWidth  id="text-activity" label="Activity" onChange={(event)=>this.setFieldState("activity",event)}>
                                            <MenuItem value="Work">Work</MenuItem>
                                            <MenuItem value="rest inside">Stationary Rest</MenuItem>
                                            <MenuItem value="rest outside">None-Stationary Rest</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField fullWidth value={this.state.location} id="text-location" label="Location" variant="standard" onChange={(event)=>this.setFieldState("location",event)}/>
                                </Grid>
                                <Grid item xs={4}>
                                    <InputLabel  id="demo-simple-select-label">Date</InputLabel>
                                    <div className="customDatePickerWidth">
                                        <DateTimePicker style={{width:"100%"}} fullWidth onChange={this.onChange} format='dd/MM/yyyy HH:mm' value={this.state.date}/>
                                    </div>
                                </Grid>
                            </Grid>
                            <br/>
                            <Grid container direction="row" spacing={4}>
                                <Grid item xs={4}>
                                    <TextField fullWidth  value={this.state.vehicleId}  id="text-vehicleId" label="Vehicle ID" variant="standard" onChange={(event)=>this.setFieldState("vehicleId",event)}/>
                                </Grid>

                                 <Grid item xs={4}>
                                    <TextField fullWidth value={this.state.odometer}  id="text-odometer" label="Odometer" variant="standard" onChange={(event)=>this.setFieldState("odometer",event)}/>
                                </Grid>
                                <Grid item xs={4}>
                                    <FormControl fullWidth>
                                        <InputLabel id="wr-option-label">W/R Option</InputLabel>
                                        <Select value={this.state.wrOption} labelId='wr-option-label' autoWidth  id="select-wrOption" label="W/R Option" onChange={(event)=>this.setFieldState("wrOption",event)}>
                                            <MenuItem value="Standard Heavy">Standard Heavy</MenuItem>
                                            <MenuItem value="Standard Bus">Standard Bus</MenuItem>
                                            <MenuItem value="Standard 2-Up">Standard 2-Up</MenuItem>
                                            <MenuItem value="BFM">BFM</MenuItem>
                                            <MenuItem value="BFM 2-up">BFM 2-up</MenuItem>
                                            <MenuItem value="AFM">AFM</MenuItem>
                                            <MenuItem value="AFM 2-up">AFM 2-up</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <br/>
                            {
                                (this.state.wrOption === "Standard 2-Up" || this.state.wrOption === "BFM 2-up" || this.state.wrOption === "AFM 2-up") &&
                                <span>
                                    <h5>2-up driver details</h5>
                                        <Grid container direction="row" spacing={4}>
                                            <Grid item xs={4}>
                                                <TextField fullWidth value={this.state.twoUpDriverName}  id="text-twoUpDriverName" label="Driver Name" variant="standard" onChange={(event)=>this.setFieldState("twoUpDriverName",event)}/>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <TextField fullWidth value={this.state.twoUpLicenseNumber}  id="text-twoUpLicenseNumber" label="License Number" variant="standard" onChange={(event)=>this.setFieldState("twoUpLicenseNumber",event)}/>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <TextField fullWidth value={this.state.twoUpLicenseState}  id="text-twoUpLicenseState" label="License State" variant="standard" onChange={(event)=>this.setFieldState("twoUpLicenseState",event)}/>
                                            </Grid>
                                        </Grid>
                                        <Grid container direction="row" spacing={4}>
                                            <Grid item xs={4}>
                                                <TextField fullWidth value={this.state.twoUpWorkBookNumberAndPageNumber}  id="text-twoUpWorkBookNumberAndPageNumber" label="WorkBook Number and Page Number" variant="standard" onChange={(event)=>this.setFieldState("twoUpWorkBookNumberAndPageNumber",event)}/>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <TextField fullWidth value={this.state.twoUpFatiguePlan}  id="text-twoUpFatiguePlan" label="Fatigue Plan" variant="standard" onChange={(event)=>this.setFieldState("twoUpFatiguePlan",event)}/>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <TextField fullWidth value={this.state.twoUpHomeBaseTimeZone}  id="text-twoUpHomeBaseTimeZone" label="HomeBase Time Zone" variant="standard" onChange={(event)=>this.setFieldState("twoUpHomeBaseTimeZone",event)}/>
                                            </Grid>
                                        </Grid>
                                </span>
                            }
                            <br/>
                            <Grid container direction="row" spacing={4}>
                                <Grid item xs>
                                    <TextField fullWidth value={this.state.comments}  id="text-comments" label="Comments" variant="standard" onChange={(event)=>this.setFieldState("comments",event)}/>
                                </Grid>
                            </Grid>
                        </Grid>
                      
                            
                           
                            {/* <div class='padding'>
                                <span  style={{"color": "#6c757d"}}>Activity</span><br/>
                                <select ref={el => this.state.activity = el}>
                                    <option value="Work">Work</option>
                                    <option value="Rest">Rest</option>
                                </select>
                            </div>

                            <div class='padding'>
                                <span class='padding' style={{"color": "#6c757d"}}>Location</span><br/>
                                <input id="location" ref={el => this.state.location = el}/><br/>
                            </div>

                            <div class='padding'>
                                <span class='padding' style={{"color": "#6c757d"}}>Time of Activity</span><br/>
                                <DateTimePicker onChange={this.onChange} format='dd/MM/yyyy HH:mm' value={this.state.date}/><br/>     
                            </div>

                            <div class='padding'>  
                                <span class='padding' style={{"color": "#6c757d"}}>Vehicle Id</span><br/>
                                <input id="vehicleId" ref={el => this.state.vehicleId = el}/><br/>
                            </div>

                            <div class='padding'>
                                <span class='padding' style={{"color": "#6c757d"}}>Odometer</span><br/>
                                <input id="odometer" ref={el => this.state.odometer = el}/><br/>
                            </div>
                            <div class='padding'>
                                <span class='padding' style={{"color": "#6c757d"}}>W/R Option</span><br/>
                                <select ref={el => this.state.wrOption = el}>
                                    <option value="Standard Heavy">Standard Heavy</option>
                                    <option value="Standard Bus">Standard Bus</option>
                                    <option value="Standard 2-Up">Standard 2-Up</option>
                                    <option value="BFM">BFM</option>
                                    <option value="BFM 2-up">BFM 2-up</option>
                                    <option value="AFM">AFM</option>
                                    <option value="AFM 2-up">AFM 2-up</option>
                                </select><br/>
                            </div>
                            <div class='padding'>
                                <span class='padding'style={{"color": "#6c757d"}}>Comments</span><br/>
                                <input id="comments" ref={el => this.state.comments = el} maxLength={400}/>
                                <br/>
                            </div>
                            
                            */}
                            <br/>
                            <div class="actions-history">
                                <button class="MuiButtonBase-root MuiButton-root MuiButton-contained mr-3" onClick={() => this.closeHistoricalEwdDialog()} >Close</button>

                                <button class="MuiButtonBase-root MuiButton-root MuiButton-contained mr-3 MuiButton-containedPrimary"
                                        onClick={() => this.saveHistoricEwdDialog()}>Save
                                </button>
                            </div>
                            
                           
                        </div> 
                    </FormGroup>
                </Dialog>

                <Dialog
                    open={toggleAddDepotsDialogStatus}
                    onClose={() => {
                        this.props.toggleAddDepotsDialog([], false)
                    }}
                    maxWidth='80%'
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <FormGroup row xs={12}>
                        <div style={{
                            "margin-left": "30px",
                            "width": "300px",
                            "margin-right": "30px",
                            "margin-bottom": "30px"
                        }}>
                            <h4>Link Depot</h4>

                            <Grid container spacing={2} direction="column" _rowSpacing={4}>
                                <Input
                                    key="depot_id"
                                    name="Depot"
                                    elementType="select"
                                    elementConfig={ {
                                        type: 'text',
                                        label: 'Depot'
                                    }}
                                    value={mapDepot.selectedItem}
                                    menuItems={_depots?_depots:[]}
                                    error={mapDepot.error}
                                    helperText={"Please select depot"}
                                    changed={(event) => this.props.depotInputHandler("depot_id", event)}
                                    touched={(event) => this.props.depotInputHandler("depot_id", event)}
                                />
                            </Grid>
                            <br/>
                            <div class="actions-history">
                                <button class="MuiButtonBase-root MuiButton-root MuiButton-contained mr-3"
                                        onClick={() => this.props.toggleAddDepotsDialog([], false)}>Close
                                </button>
                                <button
                                    class="MuiButtonBase-root MuiButton-root MuiButton-contained mr-3 MuiButton-containedPrimary"
                                    onClick={() => this.props.linkDepotToDriver(mapDepot)}>Submit
                                </button>
                            </div>
                        </div>
                    </FormGroup>
                </Dialog>

                <Dialog
                    open={toggleViewDepotsDialogStatus}
                    onClose={() => {this.props.toggleViewDepotsDialog([],false)}}
                    maxWidth = 'md'
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">

                    <div style={{"margin-left": "30px", "margin-right": "30px", "margin-bottom": "30px"}}>
                        <h4> Linked Depots </h4>

                        {/* <FormGroup row> */}
                        <SGMateriaTable
                            title=""
                            columns={depotsColumns}
                            data={driverDepots}
                            actions={[{
                                icon: 'delete',
                                tooltip: 'Mark as Deleted',
                                onClick: (event, rowData) => this.props.deLinkDepot(rowData)
                            }]}/>
                        {/* </FormGroup> */}
                        <div class='closeButton'>
                            <button className="MuiButtonBase-root MuiButton-root MuiButton-contained"
                                    onClick={() => this.props.toggleViewDepotsDialog([],false)}>Close
                            </button>
                        </div>

                    </div>
                </Dialog>
            </div>
        )
    }

}
const mapStateToProps = state => {
    return{
        driverState : state.driver,
        depotState:state.depots,
        sidebarState : state.sidebarState,
        changePasswordState : state.changePassword
    }
}
const matDispatchToProps = (dispatch) => {
    return{
        handleAddHistoricEwdEvent: (rowData) => {
            return dispatch(handleAddHistoricEwdEventDialog(rowData))
        },
        handleEditDriverOpenDialog : (rowData) => {
            return dispatch(handleEditDriverOpenDialog(rowData))
        },
        handleEditDriverDialogClose : () => {
            return dispatch(handleEditDriverDialogClose())
        },
        makeSearchFoundStatusNull : () => {
            return dispatch(makeSearchFoundStatusNull())
        },
        getDriverList : (businessId) => {
            return dispatch(getDriverList(businessId))
        },
        getDepotList : (businessId) => {
            return dispatch(getDepots(businessId))
        },
        getBusinessMenus : (props) => {
            return dispatch(getBusinessMenus(props))
        },
        toggleViewDepotsDialog : (props,status) => {
            return dispatch(toggleViewDepotsDialog(props,status))
        },
        toggleAddDepotsDialog : (props,status) => {
            return dispatch(toggleAddDepotsDialog(props,status))
        },
        depotInputHandler:(inputFieldId,event)=>{
            return dispatch(depotInputHandler(inputFieldId, event))
        },
        linkDepotToDriver:(mappedDepot)=>{
            return dispatch(linkDepotToDriver(mappedDepot))
        },
        deLinkDepot:(depot)=>{
            return dispatch(deLinkDepot(depot))
        }
    }
}
export default connect(mapStateToProps, matDispatchToProps)(withRouter(DriverList));
