import {
    SET_MASSEVENT_TEXT_FIELD,
    SET_MASSEVENT_LIST,
    SUBMIT_MASSEVENT_INFO,
    UPDATE_MASSEVENT_INFO,
    SET_DEFAULT_VALUE_TO_INITIALIZE_MASS_EVENT_COMPONENT
  } from "../actions/ActionType";
  import TextField from "@material-ui/core/TextField";
  import React from 'react';
  const initialState = {
    massEventForm : {
      mass_events : {
        elementType : 'input',
        elementConfig : {
          type : 'text',
          label : 'Enter Mass Event'
        },
        value : '',
        errorStatus : false,
        errorMessage : "Enter Mass Event",
        maxLength: 256
      }
    },
  
    massEventListcols: [
      { title: "Mass Event ID", field: "masseventsid" ,
        editComponent: props => (
        <TextField
          type="text"
          value={props.value}
          disabled = {true}
        />
      ),
      cellStyle: {
        width : "18%",
      }, 
    },
      { title: "Description", 
        field: "description",
        
      editComponent: props => {
        initialState.activeAxelConfigDescription = props.value;
        return(
          <TextField
            type="text"
            multiline
            value={props.value}
            onChange = {e => props.onChange(e.target.value)}
            inputProps={{ maxLength: 256 }}
            style = {{width: "100%"}}
          />
        )
      },
      cellStyle: {
        wordWrap: "break-word",
        maxWidth : '200px',
      }, 
      },
      {
        title : "Status", field : "current", 
        lookup : {
          "true" : "Active",
          "false" : "Inactive"
        },
        cellStyle: {
          width : "10%",
        },
      }
    ],
    massEventInformations: [],
  };

  const setDefaultValueToInitializeMassEventComponent = (state, action) => {
    Object.keys(state.massEventForm).map((inpuitField) => {
      state.massEventForm[inpuitField].value = '';
      state.massEventForm[inpuitField].errorStatus = false;
    })
    return state;
  }

  const setMassEventTextField = (state, action) => {
    if(action.data === ''){
      state.massEventForm[action.textField].errorStatus = true;
    }else{
      state.massEventForm[action.textField].errorStatus = false;
    }
    state.massEventForm[action.textField].value = action.data;
    return state;
  }

  const setMassEventList = (state, action) => {
    state.massEventInformations = action.massEventList;
    return state;
  }

  const submitMassEventInfo = (state, action) => {
    Object.keys(state.massEventForm).map((textField) => {
        if(state.massEventForm[textField].value  == ''){
          state.massEventForm[textField].errorStatus = true;
        }else{
          state.massEventForm[textField].errorStatus = false;
        }
    })
    state.showLoaderStatus = action.showLoaderStatus;
    return state;
  }

  const updateMassEventInfo = (state, action) => {
    state.massEventInformations = action.massEventInformations;
    return state;
  }
  
  const reducerMassEvent = (state = initialState, action) => {
      let localState = Object.assign({}, state);
      switch (action.type) {
          case SET_DEFAULT_VALUE_TO_INITIALIZE_MASS_EVENT_COMPONENT : return setDefaultValueToInitializeMassEventComponent(localState, action);
          case SET_MASSEVENT_TEXT_FIELD : return setMassEventTextField(localState, action);
          case SET_MASSEVENT_LIST : return setMassEventList(localState, action);
          case SUBMIT_MASSEVENT_INFO : return submitMassEventInfo(localState, action);
          case UPDATE_MASSEVENT_INFO : return updateMassEventInfo(localState, action);
          default:
              return localState;
      }
  }
  
  export default reducerMassEvent;