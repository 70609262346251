import React, {Component} from 'react';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import TextField from '@material-ui/core/TextField';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { connect } from "react-redux";

import {
    handleSearchBusinessTextFieldChange,
    onSearch,
    // makeSearchBusinessFoundStatusNull,
    intializeSearchBusinessComponent
} from '../../../actions/Business';

class SearchBusiness extends Component{
    componentDidMount(){
        this.props.intializeSearchBusinessComponent()
    }
    render(){
        let { 
                searchBusinessForm, 
                showLoaderStatus 
            } = this.props.businessState;
        let{
            menuItems
        } = this.props.sidebarState;
        let distributorID = ((window.location.pathname).split('/'))[2];
        return(
            <div>
                <Grid container spacing = {24}>
                    <Grid item xs = {2}>

                    </Grid>
                    <Grid item xs = {8}>
                        <div className = "container">
                            <Card className = "pr-2">
                                <CardHeader 
                                    title = "Search Business"
                                />
                                <CardContent>
                                    <Grid container spacing = {24}>
                                        {
                                            Object.keys(searchBusinessForm).map((textField) => {
                                                return(
                                                    <Grid item xs = {10} style = {{paddingLeft : "2rem"}} key = {textField}>
                                                        <TextField 
                                                            label = {searchBusinessForm[textField].name}
                                                            type = {searchBusinessForm[textField].type}
                                                            value = {searchBusinessForm[textField].value}
                                                            fullWidth = {true}
                                                            error={searchBusinessForm[textField].errorStatus}
                                                            helperText={searchBusinessForm[textField].errorStatus ? searchBusinessForm[textField].errorMessage : null}
                                                            onChange = {(event) => this.props.handleSearchBusinessTextFieldChange(textField, event)}
                                                            onBlur = {(event) => this.props.handleSearchBusinessTextFieldChange(textField, event)}
                                                            inputProps = {{ maxLength : searchBusinessForm[textField].maxLength}}
                                                        />
                                                    </Grid>
                                                )
                                            })
                                        }

                                        <Grid item xs = {2}>
                                            {
                                                showLoaderStatus ? <CircularProgress color = "primary" /> :
                                                <Button 
                                                    variant="contained" 
                                                    color="primary" 
                                                    style = {{marginTop : "0.5rem", paddingRight : "0.5rem"}}
                                                    onClick = {() => this.props.onSearch(searchBusinessForm, this.props, menuItems['business_create'].urlpattern)}
                                                >
                                                    <i className="material-icons" style = {{marginRight : "0.1rem"}}>
                                                        search
                                                    </i>
                                                    Search
                                                </Button>
                                            }

                                        </Grid>

                                    </Grid>
                                </CardContent>

                            </Card>
                        </div>
                    </Grid>
                    <Grid item xs = {2}>

                    </Grid>
                </Grid>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return{
        businessState : state.business,
        sidebarState : state.sidebarState
    }
}

const mapDispatchToProps = (dispatch) => {
    return{
        handleSearchBusinessTextFieldChange : (textFieldId, event) => {
            if(textFieldId === 'abn'){
                event.target.value = event.target.value.replace(/[^0-9]/g, '');
                }
            return dispatch(handleSearchBusinessTextFieldChange(textFieldId, event))
        },
        onSearch : (searchBusinessForm, props, url) => { 
            return dispatch(onSearch(searchBusinessForm, props, url)) 
        },
        // makeSearchBusinessFoundStatusNull : () => {
        //     return dispatch(makeSearchBusinessFoundStatusNull())
        // },
        intializeSearchBusinessComponent : () => {
            return dispatch(intializeSearchBusinessComponent())
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchBusiness);