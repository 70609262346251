import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import FormGroup from "@material-ui/core/FormGroup";
import Button from "@material-ui/core/Button";
import { withRouter } from "react-router-dom";

import { connect } from "react-redux";

import { 
  inputHandler, 
  onSubmit,
  initializeAccessLevel
} from "../../actions/AccessLevel";
import Input from "../../components/UI/Input/Input";
import CircularProgress from '@material-ui/core/CircularProgress';

class AddAccessLevel extends Component {
  componentDidMount(){
    this.props.initializeAccessLevel()
  }
  render() {
    let { accessLevelForm, showLoaderStatus } = this.props.accessLevel;
    const formElementArray = [];
    for(let key in accessLevelForm){
      formElementArray.push({
        id : key,
        config : accessLevelForm[key]
      })
    }
    let form = (
      <form>
        {
          formElementArray.map(formElement => (
            <Input 
              key = {formElement.id}
              elementType={formElement.config.elementType}
              elementConfig={formElement.config.elementConfig}
              value={formElement.config.value}
              error = {formElement.config.errorStatus}
              helperText = {formElement.config.errorMessage}
              changed={(event) => this.props.inputHandler(formElement.id, event )}
              touched = {(event) => this.props.inputHandler(formElement.id, event )}
              maxLength={formElement.config.maxLength}
            />
          ))
        }
      </form>
    )
    return (
      <Grid container spacing={24}>
      <Grid item xs={1} sm={2} />
      <Grid item xs={11} sm={8}>
      <Card className="pl-3 pr-3 pt-3">
          <CardHeader title="Add Access Level" />
          <CardContent>
            <FormGroup row className="pl-3 pr-1">
              <Grid container spacing={24}>
              <Grid item xs={10} sm={10}>
                {form}
              </Grid>
              <Grid item xs={2} sm={2}>
              {
                  showLoaderStatus ? <CircularProgress color = "primary" /> :
                <Button
                  variant="contained"
                  color="primary"
                  className="mt-4"
                  onClick = {() => {this.props.onSubmit(accessLevelForm, this.props)}}>
                  ADD
                </Button>
              }
              </Grid>
              </Grid>
            </FormGroup>
          </CardContent>
          <CardActions />
        </Card>
      </Grid>
      <Grid item xs={1} sm={2} />
    </Grid>

    );
  }
}

const mapStateToProps = state => {
  return {
    accessLevel: state.accessLevel
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onSubmit : (accessLevelForm, props) => { 
        return dispatch(onSubmit(accessLevelForm, props))
    },
    inputHandler: (textField, event) => {
      return dispatch(inputHandler(textField, event));
    },
    initializeAccessLevel : () => {
      return dispatch(initializeAccessLevel())
    }
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AddAccessLevel));
