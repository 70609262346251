import React, { Component } from 'react';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withRouter } from 'react-router-dom';
import Dialog from '@material-ui/core/Dialog';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { withStyles } from '@material-ui/core/styles';
import { ExportToCsv } from 'export-to-csv';
import FormGroup from "@material-ui/core/FormGroup/FormGroup";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import {
    getActulaURL
  } from "../../../utils/Helper";


import {
    getBusinessList,
    handleEditBusinessOpenDialog,
    handleEditDriverDialogClose,
    handleEditBusinessDialogOpen,
    handleEditBusinessDialogClose,
    navigateToAddBusiness,
    navigateToEditBusinessAdmin, postAddBusinessAdminOnUpdate, postAddBusinessAdminOnSubmitAfterCloseToastr
} from "../../../actions/Business";

import {
    getDistributorMenu,
    businessSelect
} from '../../../actions/Sidebar';
import AddBusiness from "./AddBusiness";

import ChangePassword from "../../../components/ChangePassword/ChangePassword";
import SGMaterialTable from '../../../components/UI/Table/SGMaterialTable';

import axios from "../../../axios";
import {toast} from "react-toastify";
import { roles } from '../../../constants/roles';
const moment = require('moment');

class ListBusiness extends Component{


    state = {
        isEnableDisableEWDOpen: false,
        currentBusinessToEnableDisable: null,
        isBusinessEnabled: false,
        isChangeDistributorForBusinessOpen: false,
        currentBusinessToMove: null,
        selectedNewDistributorId: null,
        distributorsArray: [],
        enableEwd: true,
        csvData:[],
        csvFileName:'GeneratedCsv',
        isOpenEwdSubscriptionDialog: false,
        contractStartDate: new Date(),
        contractEndDate: new Date(),
        contractPeriod : [],
        inputRoleout:[], 
        maxRoleOut: 6 
    }

    // Method appears unused
    navigateToDrivers(distributorId,business_id){
        if(distributorId === undefined){
            distributorId = localStorage.getItem('distributor_id');
        }
        this.props.history.push('/distributors/'+distributorId+'/businesses/'+business_id+'/drivers');
    }

    navigateToBusiness = (url) => {
        this.props.history.push(url);
    }

    selectBusinessForEnableDisable (rowData) {
        this.setState({currentBusinessToEnableDisable: rowData});
        this.setState({enableEwd: rowData.isBusinessEnabled});
    }
  
    selectBusinessForMove (rowData) {
        const partnerId = localStorage.getItem('partner_id');
        axios.get(`/partners/${partnerId}/distributors`)
            .then(response => {
                // sort ascending
                let distLocal = response.data.data;
                distLocal.sort(function(a,b) {return (a.distributor_name.toLowerCase() > b.distributor_name.toLowerCase()) ? 1 : ((b.distributor_name.toLowerCase() > a.distributor_name.toLowerCase()) ? -1 : 0);} );
                this.setState({distributorsArray: distLocal})
            });
        this.setState({currentBusinessToMove: rowData})
    }

    moveBusinessToDistributor () {
        const found = this.state.distributorsArray.find(distributor => distributor._id == this.state.selectedNewDistributorId);
        let businessToMovelocal = this.state.currentBusinessToMove;
        businessToMovelocal.distributor_id = found._id;

        axios.put("/businesses/" + businessToMovelocal.business_id, businessToMovelocal)
            .then(response => {
                this.props.getBusinessList();
                this.setState({isChangeDistributorForBusinessOpen: false, distributorsArray: [], selectedNewDistributorId: null,});
                toast.success(businessToMovelocal.business_name + " moved to " + found.distributor_name, {containerId: 'toastMsg'});
            }).catch(error => {
                toast.error("Moving throws an error " + error, {containerId: 'toastMsg'});
            });
    }

     openEwdSubscriptionDialog = (rowData) => {
        this.setState({ isOpenEwdSubscriptionDialog: true });        
    }

    closeEwdSubscriptionDialog = () => {
        this.setState({ isOpenEwdSubscriptionDialog: false });
    }

    countContractPeriod = (startDate,endDate) => {
        var contract = [];  
        let totalPeriodInMonths = 0;
        this.setState(prev => ({ contractPeriod: [] }))      
         
        var diff =(endDate.getTime() - startDate.getTime()) / 1000;
        diff /= (60 * 60 * 24 * 7 * 4);
        totalPeriodInMonths = Math.abs(Math.round(diff));
        totalPeriodInMonths = totalPeriodInMonths < this.state.maxRoleOut ? totalPeriodInMonths : this.state.maxRoleOut; 
        for (let index = 1; index < totalPeriodInMonths; index++) {
            let period = {};
            period.index = 0
           contract.push(period);
           this.setState(prev => ({ contractPeriod: [...prev.contractPeriod, period] }))                                  
        }     

    }
    handleContractEndDate = (endDate) => {
        this.setState({contractEndDate: endDate});       
        this.countContractPeriod(this.state.contractStartDate,endDate); 
    }
    handleContractStartDate = (startDate) => {
        this.setState({contractStartDate: startDate})          
        this.countContractPeriod(startDate,this.state.contractEndDate);  
    }
    calculateBillBasedOnBusinessCount= async (rowData) => {
        let driverList = [];
        let driver= {};    
        let totalCount = 0;  
        // API call to get the driver data
        let reqURL = "/dist/"+localStorage.getItem('distributor_id')+"/businesses/" + rowData.business_id + "/drivers";
        let drivers = await axios.get(reqURL)
        .then(resp => {
            return resp.data.data.map((result) => {
                return result;
            });
        })
        .catch(err => {
            toast.error(err.response.data.message, { containerId: 'toastMsg', });
            return [];
        });
            
        for (let user of drivers) {
           driver= {};
            if(user.driver_id != null && user.linkedByBusinessDate && user.linkedByBusinessDate != null
                 && user.linkingApprovedByDriverDate && user.linkingApprovedByDriverDate != null ){
                
                let URL = "/drivers/id/"+user.driver_id.driver_id;
                let count = 0;
                // API call to get the business details based on the howmany business that driver is linked.
                await axios.get(URL)
                .then(resp => {  
                    const businessItems = resp.data.data.businessitems;
                    if(businessItems.length != 0){
                        driver["BusinessName"] = rowData.business_name;                      
                        driver["Driver_ID"] = user.driver_id.driver_id;                      
                        driver["License_Number"] = user.driver_id.driver_license;
                        driver["DriverName"] = user.driver_id.driver_firstname + " " + user.driver_id.driver_lastname;  
                        driver["HomeBaseTimeZone"] = user.homeBaseTimeZone;
                        driver["License_state"] = user.driver_id.driver_state;
                        
                        const driverLink = businessItems.find(x => x.business_id.business_id === rowData.business_id)
                        driver["DriverLinkedDate"] =  driverLink ? new moment(driverLink.linkedByBusinessDate).format('DD/MM/YYYY') :'N/A';  

                        count = (1 / businessItems.length).toFixed(2)
                        totalCount += parseFloat(count);
                        driver["Bill"] = count;
                        driverList.push(driver)  
                    }
                                                   
                })
                .catch(err => {
                    toast.error(err.response.data.message, { containerId: 'toastMsg', });
                    return err;                    
                });
            }
           
        }

        //create empty driver to display total
        if(drivers.length != 0){
            driver= {};
            driver["Driver_ID"] = "";                      
            driver["License_Number"] = "";
            driver["DriverName"] = "";  
            driver["HomeBaseTimeZone"] = "";
            driver["License_state"] = ""; 
            driver["DriverLinkedDate"] = ""; 
            driver["BusinessName"] = "";
            driver["Bill"] = totalCount;
            driverList.push(driver);     
            this.setState({csvData:driverList});
           this.downloadBillingReport(rowData.business_name);  
           toast.success("Billing report downloaded sucessfully", {
            containerId: 'toastMsg'
        }); 
        }
        else{
            toast.error("No driver Exist !! Please link at least one driver prior to download report", {
                containerId: 'toastMsg'
            }); 
        }
         

          
    }    

    downloadBillingReport = (businessName) => {
        
         const options = { 
            fieldSeparator: ',',
            quoteStrings: '"',
            decimalSeparator: '.',
            showLabels: true,                                          
            useTextFile: false,
            useBom: true,
            useKeysAsHeaders: true,
            filename:businessName +'_Bill'            
          };                  
        const csvExporter = new ExportToCsv(options); 
        csvExporter.generateCsv(this.state.csvData);
      }
    componentDidMount(){
        this.props.getDistributorMenu();
        this.props.getBusinessList();   
    }
    render(){

        let {
                columns,
                businessInformations,
                addBusinessAdminLoaderStatus,
                showLoaderStatus,
                editDistributorDialogStatus,
            } = this.props.businessState;

        let{
            menuItems
        } = this.props.sidebarState;
        let{
            userFirstLoginStatus
        } = this.props.changePasswordState;
        
        const role = localStorage.getItem('role');
        let actionsArray = [
            {
                icon: 'call_made',
                tooltip: 'Business Select',
                onClick: (event, rowData) => this.props.businessSelect(this.props, rowData,rowData.business_id, menuItems['driver_list'].urlpattern),
            },
            {
                icon: 'edit',
                tooltip: 'Edit',
                onClick: (event, rowData) => {
                    this.props.navigateToEditBusinessAdmin();
                    localStorage.setItem('business_id', rowData.business_id);
                    this.props.history.push(getActulaURL(menuItems['business_edit'].urlpattern))
                }
            }
        ];
        if (role === roles.SG_ADMIN || role === roles.PARTNER_ADMIN || role === roles.PARTNER_EDITOR) {
            actionsArray.push({
                icon: 'rv_hookup',
                tooltip: 'Move to another distributor',
                onClick: (event, rowData) => {
                    this.setState({isChangeDistributorForBusinessOpen: true})
                    this.selectBusinessForMove(rowData)
                },
            })
        }
        actionsArray.push({
            icon: 'power',
            iconProps: { color: "red" },
            tooltip: 'Enable/Disable EWD for this business',
            onClick: (event, rowData) => {
                this.setState({isEnableDisableEWDOpen: true})
                this.selectBusinessForEnableDisable(rowData)
            },
        });
        actionsArray.push({
            icon: 'monetization_on',
            iconProps: { color: "red" },
            tooltip: 'Billing Report',
            onClick: (event, rowData) => {             
                this.calculateBillBasedOnBusinessCount(rowData);               
            },
        });
        actionsArray.push({
            icon: 'subscriptions',
            iconProps: { color: "red" },
            tooltip: 'EWD Subscription',
            onClick: (event, rowData) => {             
                this.openEwdSubscriptionDialog(rowData);               
            },
        });

        let distributorMenu = (
            this.state.distributorsArray.map(distributor => {
                return <MenuItem value={distributor._id}>{distributor.distributor_name}</MenuItem>
            })
        )

        const IOSSwitch = withStyles((theme) => ({
            root: {
                width: 42,
                height: 26,
                padding: 0,
                margin: theme.spacing(1),
            },
            switchBase: {
                padding: 1,
                '&$checked': {
                    transform: 'translateX(16px)',
                    color: theme.palette.common.white,
                    '& + $track': {
                        backgroundColor: '#52d869',
                        opacity: 1,
                        border: 'none',
                    },
                },
                '&$focusVisible $thumb': {
                    color: '#52d869',
                    border: '6px solid #fff',
                },
            },
            thumb: {
                width: 24,
                height: 24,
            },
            track: {
                borderRadius: 26 / 2,
                border: `1px solid ${theme.palette.grey[400]}`,
                backgroundColor: theme.palette.grey[50],
                opacity: 1,
                transition: theme.transitions.create(['background-color', 'border']),
            },
            checked: {},
            focusVisible: {},
        }))(({ classes, ...props }) => {
            return (
                <Switch
                    focusVisibleClassName={classes.focusVisible}
                    disableRipple
                    classes={{
                        root: classes.root,
                        switchBase: classes.switchBase,
                        thumb: classes.thumb,
                        track: classes.track,
                        checked: classes.checked,
                    }}
                    {...props}
                />
            );
        });

        const handleCloseEnableDisableEWD = () => {
            this.setState({isEnableDisableEWDOpen: false});
            this.props.getBusinessList();
        }

        const handleEnableDisableEwdChange = (event) => {
            // Set enable/disable in the UI
            this.setState({ enableEwd: event.target.checked });
            // Set enable/disable in the server
            let reqURL = '/businesses/' + this.state.currentBusinessToEnableDisable.business_id;
            axios.put(reqURL, {isBusinessEnabled: event.target.checked}).then(response => {
                toast.success("Business Updated Successfully", { containerId: 'toastMsg' });
            }).catch(error => {
                if (error.response.status == 403) {
                    localStorage.clear();
                    toast.error("Session Expired ! Please login again", { containerId: 'toastMsg' });
                    window.location.href = "/";
                } else {
                    toast.error(error.response.data.message, { containerId: 'toastMsg' });
                }
            })
        };

        return(
            <div className = "container">
                <Paper>
                    <div className = "d-flex">
                        <div className = "d-flex flex-wrap align-content-center mr-auto">
                            <h4 className = "mt-3 ml-2 text-uppercase">Business Detail List</h4>
                        </div>
                        <div className = "d-flex flex-wrap align-content-center">
                            <Button
                                variant="contained"
                                color="primary"
                                onClick = {() => this.navigateToBusiness(menuItems['business_search'].urlpattern)}
                                className = "mr-2"
                            >
                                <i className="material-icons" style = {{paddingRight : "0.5rem"}}>
                                    add_circle_outline
                                </i>
                                Add Business
                            </Button>
                        </div>
                    </div>
                    {
                         addBusinessAdminLoaderStatus ?
                        <div style = {{height : "30rem"}} className = "d-flex justify-content-center align-items-center">
                            <CircularProgress color = "primary" />
                        </div>
                         :
                        <SGMaterialTable
                            columns={columns}
                            data={businessInformations}
                            title=""
                            actions={actionsArray}>
                        </SGMaterialTable>
                    }
                </Paper>

                <Dialog
                    open={this.state.isEnableDisableEWDOpen}
                    maxWidth = 'sm'
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">
                    <DialogTitle id="form-dialog-title">Confirm Enable/Disable Business {(this.state.currentBusinessToEnableDisable && this.state.currentBusinessToEnableDisable != null) ? this.state.currentBusinessToEnableDisable.business_name : ''}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            <FormControlLabel control={<IOSSwitch checked={this.state.enableEwd} onChange={handleEnableDisableEwdChange} name="enableEwd" />}/> {this.state.enableEwd ? 'Business Enabled' : 'Business Disabled'}
                        </DialogContentText>
                        <Button
                            variant="contained"
                            onClick = {handleCloseEnableDisableEWD}
                            className = "mr-2">Close</Button>
                    </DialogContent>
                </Dialog>

               
                <Dialog
                    open={this.state.isChangeDistributorForBusinessOpen}
                    maxWidth = 'sm'
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">
                    <DialogTitle id="form-dialog-title">Move {(this.state.currentBusinessToMove && this.state.currentBusinessToMove != null) ? this.state.currentBusinessToMove.business_name : ''} to a new Reseller?</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Select the New Reseller:<br/>
                            <Select onChange={(event) => {this.setState({selectedNewDistributorId: event.target.value})}}>
                                {distributorMenu}
                            </Select>
                        </DialogContentText>
                        <Button
                            variant="contained"
                            onClick = {() => this.moveBusinessToDistributor()}
                            className = "mr-2"
                            color="primary"
                        >
                            Move Business
                        </Button>&nbsp;
                        <Button
                            variant="contained"
                            onClick = {() => {this.setState({isChangeDistributorForBusinessOpen: false})}}
                            className = "mr-2"
                        >
                            Close
                        </Button>
                    </DialogContent>
                </Dialog>
                <Dialog
                    open={localStorage.getItem('role') !== '5' && userFirstLoginStatus === 'true'}
                    maxWidth = 'sm'
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <ChangePassword/>
                </Dialog>
                <Dialog
                    open={editDistributorDialogStatus}
                    onClose={() => {this.props.closeEwdSubscriptionDialog()}}
                    maxWidth = 'md'
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                <AddBusiness/>
                </Dialog>
              
                <Dialog
                    open={this.state.isOpenEwdSubscriptionDialog}
                    onClose={() => {this.closeEwdSubscriptionDialog()}}                 
                    maxWidth = 'lg'
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <FormGroup row>
                        <div style={{"margin-left": "150px", "margin-right": "150px", "margin-bottom": "50px"}}>
                            <h4>EWD Subscription</h4>
                            <div style={{"display": "inline-block","margin":"20px"}}> 
                             <span style={{"color": "#6c757d"}}>EWD LicenseType :</span><br/>
                            </div>
                            <div style={{"display": "inline-block"}}>
                            <select>
                                <option value="LicensePerDriver">License Per Driver</option>
                                <option value="LicensePerVehicle">License Per Vehicle</option>
                            </select>
                            </div><br/>
                            <div style={{"display": "inline-block","margin":"20px"}}> 
                             <span style={{"color": "#6c757d"}}>Contract Start :</span><br/>
                            </div>
                            <div style={{"display": "inline-block"}}>
                            <DatePicker
                                selected={this.state.contractStartDate}
                                onChange={date => this.handleContractStartDate(date)}    
                                dateFormat="dd/MM/yyyy"                                                           
                            />                          
                            </div><br/>                           
                            <div style={{"display": "inline-block","margin":"20px"}}> 
                             <span style={{"color": "#6c757d"}}>Contract End :</span><br/>
                            </div>
                            <div style={{"display": "inline-block"}}>
                            <DatePicker
                                selected={this.state.contractEndDate}
                                onChange={date => this.handleContractEndDate(date)}    
                                dateFormat="dd/MM/yyyy"                                                           
                            />
                            </div><br/>  
                            <span style={{"color": "#6c757d"}}>Role Out :</span><br/> 
                            <input value='0' style={{"display": "inline-block","margin":"5px"}} /> <br />
                            {this.state.contractPeriod.map((e,i)=> {
                                return (   
                                    <div>
                                        <div style={{"display": "inline-block"}}>
                                            <span style={{"color": "#6c757d"}}>{i} :</span><br/> 
                                        </div>  
                                         <div style={{"display": "inline-block"}}> 
                                            <input value={i} style={{"display": "inline-block","margin":"5px"}} /> <br />
                                         </div>                                                
                                    </div>                                                         
                               )
                            })}                                                   
                           
                            <button class="MuiButtonBase-root MuiButton-root MuiButton-contained mr-3 MuiButton-containedPrimary"
                                    onClick={() => this.saveHistoricEwdDialog()}>Save
                            </button>
                            <button class="MuiButtonBase-root MuiButton-root MuiButton-contained mr-3" onClick={() => this.closeEwdSubscriptionDialog()} >Close</button>
                        </div>
                    </FormGroup>
                </Dialog>
                
            </div>
        )
    }
}

const mapStateToProps = state => {
    return{
        businessState : state.business,
        sidebarState : state.sidebarState,
        changePasswordState : state.changePassword
    }
}
const mapDispatchToProps = (dispatch) => {
    return{
        getBusinessList : (distributorId) => {
            return dispatch(getBusinessList(distributorId))
        },
        handleEditBusinessDialogOpen : (rowData) => {
            return dispatch(handleEditBusinessDialogOpen(rowData))
        },
        handleEditBusinessOpenDialog : (rowData) => {
            return dispatch(handleEditBusinessOpenDialog(rowData))
        },
        getDistributorMenu : () => {
            return dispatch(getDistributorMenu())
        },
        businessSelect : (props,row, businessId, url) => {
            return dispatch(businessSelect(props,row, businessId, url))
        },      
        navigateToEditBusinessAdmin : () => {
            return dispatch(navigateToEditBusinessAdmin())
        },
        handleEditBusinessDialogClose : () => {
            return dispatch(handleEditBusinessDialogClose())
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ListBusiness));
