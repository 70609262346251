import React, { Component } from 'react';
import FormGroup from '@material-ui/core/FormGroup';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withRouter } from 'react-router-dom';

import Input from "../../components/UI/Input/Input";
import {
  addEDRecordFormInputHandler,
  onSubmitEDRecord,
  getESList,
  handleEditEDRecordCloseDialog,
  // onUpdateEDRecord,
  initializeAddEDForm
} from "../../actions/ExternalDriver";
class AddExternalDriver extends Component {
  componentDidMount() {
    this.props.getESList();
    if (!this.props.externalDriverState.editEDRecordDialogStatus) {
      this.props.initializeAddEDForm()
    }
  }
  render() {
    let {
      addEDRecordForm,
      editEDRecordDialogStatus,
      activeEDRecordForEdit,
      showEDRecordSubmitLoaderStatus,
      showEDRecordUpdateLoaderStatus
    } = this.props.externalDriverState;
    let {
      menuItems
    } = this.props.sidebarState;
    let driverId = ((window.location.pathname).split('/'))[7];
    const formElementArray = [];
    for (let key in addEDRecordForm) {
      formElementArray.push({
        id: key,
        config: addEDRecordForm[key]
      })
    }
    let form = (
      <FormGroup row>
        <Grid container spacing={24}>
          {
            formElementArray.map(formElement => {
              if (formElement.config.elementType === 'date') {
              } else {
                return (
                  <Grid item xs={12} sm={6} key={formElement.id}>
                    <Input
                      key={formElement.id}
                      name={formElement.config.elementConfig.label}
                      elementType={formElement.config.elementType}
                      elementConfig={formElement.config.elementConfig}
                      value={formElement.config.value}
                      menuItems={formElement.config.menuItems}
                      error={formElement.config.errorStatus}
                      helperText={formElement.config.errorMessage}
                      changed={(event) => this.props.addEDRecordFormInputHandler(formElement.id, event)}
                    />
                  </Grid>
                )
              }

            })
          }
        </Grid>
        <Grid container spacing={24} className="pt-2">
          <Grid item xs={8}>
          </Grid>
          <Grid item xs={4} className="d-flex justify-content-end">

            {
              // editEDRecordDialogStatus ?
              //   <span>
              //     {
              //       showEDRecordUpdateLoaderStatus ?
              //         <div className="d-flex justify-content-center align-items-center">
              //           <CircularProgress color="primary" />
              //         </div> :
              //         <span>
              //           <Button
              //             variant="contained"
              //             color="primary"
              //             className="mr-3"
              //             disabled={false}
              //             style={{ marginRight: "1rem" }}
              //             onClick={() => { this.props.onUpdateEDRecord(addEDRecordForm, activeEDRecordForEdit, driverId, this.props) }}
              //           >
              //             Update
              //                           </Button>
              //           <Button
              //             variant="contained"
              //             color="default"
              //             disabled={false}
              //             onClick={() => { this.props.handleEditEDRecordCloseDialog() }}
              //           >
              //             Cancel
              //                           </Button>
              //         </span>
              //     }
              //   </span> :
                showEDRecordSubmitLoaderStatus ?
                  <div className="d-flex justify-content-center align-items-center">
                    <CircularProgress color="primary" />
                  </div> :
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={false}
                    onClick={() => { this.props.onSubmitEDRecord(addEDRecordForm, driverId, this.props, menuItems['external_driver_list'].urlpattern) }}>
                    Submit
                            </Button>
            }
          </Grid>
        </Grid>
      </FormGroup>
    )
    return (
      <div className="container">
        <Card className="pl-3 pr-3 pt-3">
          <div>
            <Grid container spacing={24} className="pt-2">
              <Grid item xs={12} sm={10}>
                {
                  editEDRecordDialogStatus ?
                    <CardHeader title="Edit External System Record" /> :
                    <CardHeader title="Add External System Record" />
                }
              </Grid>
            </Grid>
          </div>
          <CardContent>
            {form}
          </CardContent>
        </Card>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    externalDriverState: state.externalDriver,
    sidebarState: state.sidebarState
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getESList: () => {
      return dispatch(getESList())
    },
    addEDRecordFormInputHandler: (inputFieldId, event) => {
      return dispatch(addEDRecordFormInputHandler(inputFieldId, event))
    },
    onSubmitEDRecord: (addEDRecordForm, driverId, props, url) => {
      return dispatch(onSubmitEDRecord(addEDRecordForm, driverId, props, url))
    },
    handleEditEDRecordCloseDialog: () => {
      return dispatch(handleEditEDRecordCloseDialog())
    },
    // onUpdateEDRecord: (addEDRecordForm, activeEDRecordForEdit, driverId, props) => {
    //   return dispatch(onUpdateEDRecord(addEDRecordForm, activeEDRecordForEdit, driverId, props))
    // },
    initializeAddEDForm: () => {
      return dispatch(initializeAddEDForm())
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AddExternalDriver));