import React, { Component } from 'react';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import FormGroup from '@material-ui/core/FormGroup';
import {connect} from "react-redux";
import {withRouter} from 'react-router';
import Divider from '@material-ui/core/Divider';

import MuiExpansionPanel from '@material-ui/core/ExpansionPanel';
import MuiExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import MuiExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';

import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { withStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import Checkbox from '@material-ui/core/Checkbox';

import Input from "../../../components/UI/Input/Input";
import {
    getTriggers,
    handleTriggerDropdown,
    getVehicleById,
    handleAssignmentListExpansionCollapse,
    initializeListAssignedCheckList,
    activeDeleteCheckitemOption,
    deactiveDeleteCheckitemOption,
    handleCheckItemCheckboxForDelete,
    deleteCheckItems
} from "../../../actions/Checklist";

import {
    getActulaURL
} from "../../../utils/Helper";
const ExpansionPanel = withStyles({
    root: {
      border: '1px solid rgba(0,0,0,.125)',
      boxShadow: 'none',
      '&:not(:last-child)': {
        borderBottom: 0,
      },
      '&:before': {
        display: 'none',
      },
    },
    expanded: {
      margin: 'auto',
    },
  })(MuiExpansionPanel);
const ExpansionPanelSummary = withStyles({
    root: {
      backgroundColor: 'rgba(0,0,0,.03)',
      borderBottom: '1px solid rgba(0,0,0,.125)',
      marginBottom: -1,
      minHeight: 56,
      '&$expanded': {
        minHeight: 56,
      },
    },
    content: {
      '&$expanded': {
        margin: '12px 0',
      },
    },
    expanded: {},
  })(props => <MuiExpansionPanelSummary {...props} />);
ExpansionPanelSummary.muiName = 'ExpansionPanelSummary';
const ExpansionPanelDetails = withStyles(theme => ({
    root: {
      padding: theme.spacing.unit * 2,
    },
  }))(MuiExpansionPanelDetails);
class ListAssignedChecklist extends Component{
    componentDidMount(){
        this.props.getTriggers();
        this.props.getVehicleById();
        this.props.initializeListAssignedCheckList()
    }
    state = {
        expanded : ''
    }
    handleExpansionCollapse = (event, expansionDetail) => {
        let expanded = expansionDetail;
        if(expanded === this.state.expanded){
            expanded = false;
        }
        this.setState({
            expanded : expanded
        })
    }
    render(){
        let{
            menuItems
        } = this.props.sidebarState;
        let {
            vehicleInfoForAssignChecklist,
            checklistExpansionPanelExpandedStatus,
            triggerDropdown,
            checklistArray,
            getCheckListloaderStatus,
            activeDeleteCheckItemOptionStatus,
            checkItemArrayForDelete,
            deleteCheckitemLoaderStatus
        } = this.props.checkListState;
        const formElementArray = [];
        for(let key in triggerDropdown){
            formElementArray.push({
                id : key,
                config : triggerDropdown[key]
            })
        }
        let expansionPanelWidget = (
            checklistArray.map(expansionDetail => {
                return(
                    <ExpansionPanel
                        square
                        expanded={checklistExpansionPanelExpandedStatus === expansionDetail}
                        onChange={(event) => this.props.handleAssignmentListExpansionCollapse(event, expansionDetail)}
                    >
                    <ExpansionPanelSummary style = {{padding : "0", paddingLeft : "1rem"}}>
                        <Grid
                            container
                            spacing = {24}
                            direction = "row"
                            justify="space-between"
                            align = "center"
                        >
                            <Grid item>
                                <Grid
                                    container
                                    spacing = {24}
                                    direction = "row"
                                    align = "center"
                                    style = {{padding : "12px"}}
                                >
                                    <Grid item sm = {3}>
                                        <i className="material-icons mr-2">
                                            chrome_reader_mode
                                        </i>
                                    </Grid>
                                    <Grid item>
                                        <Typography style = {{paddingTop : "0.15rem"}}>
                                            {expansionDetail.checklist_name}
                                        </Typography>
                                    </Grid>

                                </Grid>
                            </Grid>
                            <Grid item>
                                <IconButton
                                    onClick = {() => 
                                        {
                                            this.props.history.push(getActulaURL(menuItems['business_checklist'].submenu['edit_checklist'].urlpattern));
                                            localStorage.setItem('checklist_id', expansionDetail.id);
                                            localStorage.setItem('checklist_name', expansionDetail.checklist_name);
                                        }
                                    }
                                >
                                    <i className = "material-icons">
                                        edit
                                    </i>
                                </IconButton>
                                <IconButton
                                    onClick = {() => 
                                        {
                                            this.props.activeDeleteCheckitemOption(checkItemArrayForDelete);
                                        }
                                    }
                                >
                                    <i className = "material-icons">
                                        delete
                                    </i>
                                </IconButton>
                            </Grid>
                        </Grid>


                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails style = {{flexDirection: "column", padding : "0"}}>
                    {
                            (expansionDetail.checkItems && !deleteCheckitemLoaderStatus) ?
                                expansionDetail.checkItems.length === 0 ?
                                <div 
                                    style = {{paddingLeft : "1rem", height : "30vh"}} 
                                    className = "d-flex justify-content-center align-items-center"
                                >
                                    <Typography style = {{paddingTop : "0.15rem"}}>
                                        No Checkitem Found
                                    </Typography>
                                </div> :
                                <div>
                                    {
                                        expansionDetail.checkItems.map(checklistItem => {
                                            return(
                                                <div> 
                                                    <ListItem>
                                                        <ListItemIcon>
                                                            {
                                                                activeDeleteCheckItemOptionStatus ? 
                                                                <Checkbox
                                                                    checked={checklistItem.checkedStatus}
                                                                    onChange= {() => this.props.handleCheckItemCheckboxForDelete(expansionDetail.id, checklistItem.id)}
                                                                    value={checklistItem.id}
                                                                /> : 
                                                                <i className="material-icons mr-2 ml-2">
                                                                    list
                                                                </i>
                                                            }
                                                        </ListItemIcon>
                                                        <ListItemText inset primary={checklistItem.check_item_name} style = {{padding : "0"}}/>
                                                    </ListItem>
                                                    <Divider />
                                                </div>
                                            )
                                        })
                                    }
                                    {
                                        activeDeleteCheckItemOptionStatus ? 
                                        <Grid
                                            container
                                            directoion = "row"
                                            justify = "flex-end"
                                            alignItems = "center"
                                            className = "pt-3 pb-3 pr-2"
                                        >
                                            <Grid item className = "mr-3">
                                                <Button 
                                                    variant="contained" 
                                                    color="default" 
                                                    onClick = {() => this.props.deactiveDeleteCheckitemOption()}
                                                >
                                                    Cancel
                                                </Button>
                                            </Grid>

                                            <Grid item>
                                                <Button 
                                                        variant="contained" 
                                                        color="primary"
                                                        onClick = {() => this.props.deleteCheckItems(checkItemArrayForDelete, expansionDetail.id, expansionDetail.name)}
                                                    >
                                                        Update
                                                </Button>
                                            </Grid>

                                        </Grid> : null
                                    }

                                </div> :
                                <div 
                                    style = {{paddingLeft : "1rem", height : "30vh"}} 
                                    className = "d-flex justify-content-center align-items-center"
                                >
                                        <CircularProgress />
                                </div>
                        }
                    </ExpansionPanelDetails>
                </ExpansionPanel>
                )
            })
        )
        let vehicleInfoWidget = (
            <Card className = "pt-3">

                {
                    Object.keys(vehicleInfoForAssignChecklist).length === 0 ?
                    <div className = "d-flex justify-content-center align-items-center">
                        <CircularProgress />
                    </div>:
                    <Grid 
                        container
                        direction = "row"
                        justify = "space-around"
                        alignItems = "center"
                    >
                        <Grid item>
                            <p>Vehicle ID : {vehicleInfoForAssignChecklist.vehicle_id}</p>
                        </Grid>
                        <Grid item>
                            <p>Vehicle Plate : {vehicleInfoForAssignChecklist.vehicle_plate}</p>
                        </Grid>
                        <Grid item>
                            <p>State : {vehicleInfoForAssignChecklist.vehicle_state}</p>
                        </Grid>
                    </Grid>
                }

                
            </Card>
        );

        let triggerDropdownForm = (
            <FormGroup row>
                <Grid 
                    container 
                    spacing = {24}
                    justify = "center"
                    alignItems = "center"
                    className = "pt-2 pb-3"
                >
                    {
                        formElementArray.map(formElement => {
                                return(
                                    <Grid item xs = {12} sm = {4} key = {formElement.id}>
                                        <Input 
                                            key = {formElement.id}
                                            name = {formElement.config.elementConfig.label}
                                            elementType={formElement.config.elementType}
                                            elementConfig={formElement.config.elementConfig}
                                            value={formElement.config.value}
                                            menuItems = {formElement.config.menuItems}
                                            error = {formElement.config.errorStatus}
                                            helperText = {formElement.config.errorMessage}
                                            changed = {(event) => this.props.handleTriggerDropdown(formElement.id, event)}
                                            disabledStatus={formElement.config.disabledStatus}
                                            maxLength={formElement.config.maxLength}
                                        />
                                    </Grid>
                                )
                        }
                        )
                    }
                </Grid>
            </FormGroup>
        )

        let assignChecklistWidget = (
            <Paper className = "pt-0 mt-3 mb-3">
                <Card>
                    <Grid 
                        container 
                        spacing = {24} 
                        justify = "space-between"
                        alignItems = "center"
                        className = "pt-2 pb-3"
                    >
                        <Grid item>
                        <Typography variant="title" gutterBottom className = "ml-4 pt-2">
                            Assign Checklist
                        </Typography>
                        </Grid>
                        <Grid item>
                            <Button 
                                variant="contained" 
                                color="primary"
                                className = "mr-3"
                                onClick = {() => this.props.history.push(menuItems['business_checklist'].submenu['create_checklist'].urlpattern)}
                                disabled = {triggerDropdown['trigger_dropdown'].value ? false : true}
                            >
                                <i className="material-icons" style = {{paddingRight : "0.5rem"}}>
                                    add_circle_outline
                                </i>
                                Add Checklist
                            </Button>
                        </Grid>
                    </Grid>
                    <Divider />
                    {triggerDropdownForm}
                    <Grid 
                        container 
                        spacing = {24} 
                        justify = "center"
                        alignItems = "center"
                        className = "pt-2 pb-3"
                    >
                        <Grid item sm = {8}>
                            {
                                getCheckListloaderStatus ?
                                <div 
                                    style = {{height : "30vh"}} 
                                    className = "d-flex justify-content-center align-items-center"
                                >
                                        <CircularProgress />
                                </div> :
                                checklistArray.length === 0 ? 
                                <div 
                                    style = {{height : "30vh"}} 
                                    className = "d-flex justify-content-center align-items-center"
                                >
                                    <Typography>
                                        No Checkitem Found
                                    </Typography>
                                </div> : 
                                <div style = {{height : "50%"}}>
                                    {expansionPanelWidget}
                                </div>
                            }
                        </Grid>
                    </Grid>
                </Card>
            </Paper>
        )
        return(
            <div className = "container">
                {vehicleInfoWidget}
                {assignChecklistWidget}
            </div>
        )
    }
}

const mapStateToProps = state => {
    return{
        sidebarState : state.sidebarState,
        checkListState : state.checkList
    }
}

const mapDispatchToProps = dispatch => {
    return{
        getTriggers : () =>  { 
            return dispatch(getTriggers())
        },
        handleTriggerDropdown : (formElementId, event) => {
            return dispatch(handleTriggerDropdown(formElementId, event))
        },
        getVehicleById : () => {
            return dispatch(getVehicleById())
        },
        handleAssignmentListExpansionCollapse : (event, expansionDetail) => {
            return dispatch(handleAssignmentListExpansionCollapse(event, expansionDetail))
        },
        initializeListAssignedCheckList : () => {
            return dispatch(initializeListAssignedCheckList())
        },
        activeDeleteCheckitemOption : () => {
            return dispatch(activeDeleteCheckitemOption())
        },
        deactiveDeleteCheckitemOption : () => {
            return dispatch(deactiveDeleteCheckitemOption())
        },
        handleCheckItemCheckboxForDelete : (checklistId, checklistItemId) => {
            return dispatch(handleCheckItemCheckboxForDelete(checklistId, checklistItemId))
        },
        deleteCheckItems : (checkItemArrayForDelete, checkItemId, checkItemName) => {
            return dispatch(deleteCheckItems(checkItemArrayForDelete, checkItemId, checkItemName))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ListAssignedChecklist));
