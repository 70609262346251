import {
  SET_LOAD_TYPE_TEXT_FIELD,
  SET_LOADTYPE_LIST,
  SUBMIT_LOADTYPE_INFO,
  UPDATE_LOADTYPE_INFO,
  SET_DEFAULT_VALUE_TO_LOAD_TYPES,
  MEASUREMENT_TYPE_LIST,
  MASSVARIABLETYPE_LIST,
  LOADTYPE_OPEN_DIALOG,
  LOADTYPE_CLOSE_DIALOG,
  SET_DEFAULT_VALUE_TO_ADD_LOADTYPE_FORM
} from "../actions/ActionType";
import axios from '../axios';
import { toast } from 'react-toastify';

export const initializeloadTypes = () => {
  return (dispatch) => {
    return dispatch(setDefaultValueToloadTypes())
  }
}
export const setDefaultValueToloadTypes = () => {
  return {
    type: SET_DEFAULT_VALUE_TO_LOAD_TYPES
  }
}
/********************************************************************************* */
export const inputHandlerloadType = (textField, event) => {
  return dispatch => {
    // console.log("event", event.target)
    return dispatch(setloadtypesTextFields(textField, event));
  };
};

export const setloadtypesTextFields = (textField, event) => {
  return {
    type: SET_LOAD_TYPE_TEXT_FIELD,
    textField: textField,
    data: event.target.value
  };
};

//=================================================================
export const onUpdateLoadType = (loadTypeForm, activeLoadTypeForEdit, props, menuItems) => {
  return (dispatch) => {
    let updatedData = {};
    let showLoadTypeUpdateLoaderStatus = false;
    let updateLoadTypeFormValidationStatus = false;
    Object.keys(loadTypeForm).map(inputField => {
      if (loadTypeForm[inputField].value === '') {
        updateLoadTypeFormValidationStatus = true;
      }
    })
    if (!updateLoadTypeFormValidationStatus) {
      showLoadTypeUpdateLoaderStatus = true;
      // let reqURL = "/businesses/" + businessID + "/LoadTypes" + loadTypeInfo._id;
      let reqPath = "/businesses/" + localStorage.getItem('business_id') + "/loadtypes/" + activeLoadTypeForEdit;
      let reqParams = {};
      reqParams["loadType"] = loadTypeForm['load_types'].value;
      reqParams["mass_limit"] = loadTypeForm['mass_limit'].value;
      reqParams["mm_measurement_type"] = loadTypeForm['measurement_type'].value;
      reqParams["specificGravity"] = loadTypeForm['specific_gravity'].value;
      // reqParams["mm_massvariable_type"] = loadTypeForm['mass_variable_type'].value;
      // reqParams["status"] = loadTypeForm['status'].value;
      // reqParams["_id"] = loadTypeForm['_id'].value;
      dispatch(setUpdateLoadType(showLoadTypeUpdateLoaderStatus));
      return axios.put(reqPath, reqParams)
        .then(response => {
          showLoadTypeUpdateLoaderStatus = false;
          updatedData = {};
          updatedData["loadType"] = loadTypeForm['load_types'].value;
          updatedData["mass_limit"] = loadTypeForm['mass_limit'].value;
          updatedData["mm_measurement_type"] = loadTypeForm['measurement_type'].value;
          updatedData["specificGravity"] = loadTypeForm['specific_gravity'].value;
          // updatedData["mm_massvariable_type"] = loadTypeForm['mass_variable_type'].value;
          // updatedData["status"] = loadTypeForm['status'].value;
          updatedData["_id"] = activeLoadTypeForEdit;
          toast.success("Load Type Updated Successfully", {
            containerId: 'toastMsg',
            // onClose: () => props.history.push("/businessportal/" + localStorage.getItem('business_id') + "/loadtypeslist")
          });
          dispatch(setUpdateLoadType(showLoadTypeUpdateLoaderStatus, updatedData))
        })
        .catch(error => {
          showLoadTypeUpdateLoaderStatus = false;
          toast.error(error.message, {
            containerId: 'toastMsg'
          });
          dispatch(setUpdateLoadType(showLoadTypeUpdateLoaderStatus, updatedData))
        })
    }

  }
}

export const setUpdateLoadType = (showLoadTypeUpdateLoaderStatus, updatedData) => {
  return{
      type : UPDATE_LOADTYPE_INFO,
      showLoadTypeUpdateLoaderStatus : showLoadTypeUpdateLoaderStatus,
      updatedData : updatedData
  }
}

/********************************************************************************************** */
/**************************************************************************************** */

export const onSubmit = (loadTypeInfo, props) => {
  return (dispatch) => {
    let showLoaderStatus = false;
    let fieldValidationStatus = true;

    Object.keys(loadTypeInfo.loadTypeForm).map((textField) => {
      // console.log("textfield ids",loadTypeInfo.loadTypeForm[textField])
      if (loadTypeInfo.loadTypeForm[textField].value == '' && loadTypeInfo.loadTypeForm[textField].elementConfig.label != 'Specific Gravity' ) {
        fieldValidationStatus = false;
        loadTypeInfo.loadTypeForm[textField].errorStatus = true;
      }
    })
    dispatch(setSubmittedData(
      loadTypeInfo.loadtypesListrows,
      showLoaderStatus,
      loadTypeInfo.loadTypeForm
    )
    );
    if (fieldValidationStatus) {
      showLoaderStatus = true;
      dispatch(setSubmittedData(
        loadTypeInfo.loadtypesListrows,
        showLoaderStatus,
        loadTypeInfo.loadTypeForm
      )
      );
      // console.log("data found" + loadTypeInfo.loadTypeForm['load_types'].value)
      let addloadTypeReq = {};
      let createdInfo = {};
      let businessID = localStorage.getItem('business_id');
      let reqURL = "/businesses/" + businessID + "/loadtypes";
      addloadTypeReq["loadType"] = loadTypeInfo.loadTypeForm['load_types'].value;
      addloadTypeReq["mass_limit"] = loadTypeInfo.loadTypeForm['mass_limit'].value;
      addloadTypeReq["mm_measurement_type"] = loadTypeInfo.loadTypeForm['measurement_type'].value;
      addloadTypeReq["specificGravity"] = loadTypeInfo.loadTypeForm['specific_gravity'].value;
      // addloadTypeReq["mm_massvariable_type"] = loadTypeInfo.loadTypeForm['mass_variable_type'].value;
      addloadTypeReq["status"] = true;
      return axios.post(reqURL, addloadTypeReq)
        .then(response => {
          showLoaderStatus = false;
          createdInfo = {};
          createdInfo['_id'] = response.data.data._id;
          createdInfo['mass_limit'] = response.data.data.mass_limit;
          createdInfo['loadType'] = response.data.data.loadType;
          createdInfo['mm_measurement_type'] = response.data.data.mm_measurement_type;
          createdInfo['specificGravity'] = response.data.data.specificGravity;
          // createdInfo['mm_massvariable_type'] = response.data.data.mm_massvariable_type;
          createdInfo['status'] = response.data.data.status;
          loadTypeInfo.loadtypesListrows.unshift(createdInfo);
          Object.keys(loadTypeInfo.loadTypeForm).map((textField) => {
            loadTypeInfo.loadTypeForm[textField].value = '';
          })
          toast.success("Load Type Created Successfully", {
            containerId: 'toastMsg',
            onClose: () => props.history.push("/businessportal/" + localStorage.getItem('business_id') + "/loadtypeslist")
          }
          );
          dispatch(setSubmittedData(
            loadTypeInfo.loadtypesListrows,
            showLoaderStatus,
            loadTypeInfo.loadTypeForm
          )
          );
        })
        .catch(error => {
          // if (error.response.status == 403) {
          //   showLoaderStatus = false;
          //   localStorage.clear();
          //   // toast.error("Session Expired ! Please login again", {containerId: 'toastMsg'});
          //   window.location.href = "/";
          // } else {
            showLoaderStatus = false;
            toast.error(error.response.data.message, { containerId: 'toastMsg' });
            dispatch(setSubmittedData(
              loadTypeInfo.loadtypesListrows,
              showLoaderStatus,
              loadTypeInfo.loadTypeForm
            )
            );
          // }
        })
    }

  }

}

export const setSubmittedData = (loadtypesListrows, showLoaderStatus, loadTypeForm) => {
  return {
    type: SUBMIT_LOADTYPE_INFO,
    loadtypesListrows: loadtypesListrows,
    showLoaderStatus: showLoaderStatus,
    loadTypeForm: loadTypeForm
  }
}

export const handleClickOpenDialog = (rowData) => {
  return (dispatch) => {
    return dispatch(setLoadTypeOpenDialogStatus(rowData))
  }
}
export const setLoadTypeOpenDialogStatus = (rowData) => {
  return {
    type: LOADTYPE_OPEN_DIALOG,
    rowData: rowData
  }
}

export const handleCloseDialog = () => {
  return (dispatch) => {
    return dispatch(setLoadTypeCloseDialog())

  }
}
export const setLoadTypeCloseDialog = () => {
  return {
    type: LOADTYPE_CLOSE_DIALOG
  }
}
/************************************************************************/
export const getloadTypeList = () => {
  let loadtype = {};
  let loadTypeList = [];
  let loadTypeListLoaderStatus = true;
  // return (dispatch) => {
  //   loadTypeListLoaderStatus = false;

  //   loadtype = {};
  //   loadtype['_id'] = 22;
  //   loadtype['loadType'] = "fghfghh";
  //   loadtype['mm_measurement_type'] = "fgfgh";
  //   loadtype['specificGravity'] = "gdfgdfg";
  //   loadtype['mm_massvariable_type'] = "dfff";
  //   loadtype['status'] = true;
  //   loadTypeList.push(loadtype);
  //   dispatch(setloadTypeList(loadTypeList, loadTypeListLoaderStatus));
  // }
  return (dispatch) => {
    dispatch(setloadTypeList(loadTypeList, loadTypeListLoaderStatus));
    return axios.get("/businesses/" + localStorage.getItem('business_id') + "/loadtypes")
      .then(response => {
        loadTypeListLoaderStatus = false;
        for (let i = 0; i < response.data.data.length; i++) {
          loadtype = {};
          loadtype['_id'] = response.data.data[i]._id;
          loadtype['mass_limit'] = response.data.data[i].mass_limit;

          loadtype['loadType'] = response.data.data[i].loadType;
          loadtype['mm_measurement_type'] = response.data.data[i].mm_measurement_type;
          loadtype['specificGravity'] = response.data.data[i].specificGravity;
          // loadtype['mm_massvariable_type'] = response.data.data[i].mm_massvariable_type;
          loadtype['status'] = response.data.data[i].status;
          loadTypeList.push(loadtype);
        }
        dispatch(setloadTypeList(loadTypeList, loadTypeListLoaderStatus));
      })
      .catch(error => {
        loadTypeListLoaderStatus = false;
        if (error.response.status == 403) {
          localStorage.clear();
          window.location.href = "/";
        }
        dispatch(setloadTypeList(loadTypeList, loadTypeListLoaderStatus));
      })
  }
}

export const getmeasurementTypes = (loadTypeForm) => {
  return (dispatch) => {
    let measurementTypeMenuItemsKey = {};
    let measurementTypeMenuItems = {};
    let businessID = localStorage.getItem('business_id');
    let reqURL = "/businesses/" + businessID + "/measurementtypes";
    return axios.get(reqURL)
      .then(response => {
        for (let i = 0; i < response.data.data.length; i++) {
          measurementTypeMenuItems[response.data.data[i].name] = { name: response.data.data[i].name, value: response.data.data[i].value }

        }
        dispatch(setmeasurementTypes(measurementTypeMenuItems))
      })
      .catch(error => {
        console.log('Error occurred in catch', error)
        if (error.response.status == 403) {
          localStorage.clear();
          window.location.href = "/";
        }
        dispatch(setmeasurementTypes(measurementTypeMenuItems))
      })
  }
}

export const getmeasurementTypesOptions = (loadTypeForm) => {
  return (dispatch) => {
    let measurementTypeMenuItemsKey = {};
    let measurementTypeMenuItems = {};
    let businessID = localStorage.getItem('business_id');
    let reqURL = "/businesses/" + businessID + "/measurementtypes";
    return axios.get(reqURL)
      .then(response => {
        for (let i = 0; i < response.data.data.length; i++) {
          measurementTypeMenuItems[response.data.data[i].name] = { name: response.data.data[i].name, value: response.data.data[i].name }

        }
        dispatch(setmeasurementTypes(measurementTypeMenuItems))
      })
      .catch(error => {
        console.log('Error occurred in catch', error)
        if (error.response.status == 403) {
          localStorage.clear();
          window.location.href = "/";
        }
        dispatch(setmeasurementTypes(measurementTypeMenuItems))
      })
  }
}

export const getmassvariableTypes = (loadTypeForm) => {
  // console.log(loadTypeForm)
  return (dispatch) => {
    let massvariableTypeMenuItemsKey = {};
    let massvariableTypeMenuItems = {};
    let businessID = localStorage.getItem('business_id');
    let reqURL = "/businesses/" + businessID + "/massvariabletypes";
    return axios.get(reqURL)
      .then(response => {
        for (let i = 0; i < response.data.data.length; i++) {
          massvariableTypeMenuItems[response.data.data[i].name] = { name: response.data.data[i].name, value: response.data.data[i].value }

        }
        dispatch(setmassvariableTypes(massvariableTypeMenuItems))
      })
      .catch(error => {
        console.log('Error occurred in catch', error)
        if (error.response.status == 403) {
          localStorage.clear();
          window.location.href = "/";
        }
        dispatch(setmassvariableTypes(massvariableTypeMenuItems))
      })
  }
}

export const setloadTypeList = (loadTypeList, loadTypeListLoaderStatus) => {
  return {
    type: SET_LOADTYPE_LIST,
    loadTypeList: loadTypeList,
    loadTypeListLoaderStatus: loadTypeListLoaderStatus
  }
}

/************************************************************************************************************* */
export const setmeasurementTypes = (measurementTypeMenuItems) => {
  // console.log(setmeasurementTypes)
  return {
    type: MEASUREMENT_TYPE_LIST,
    measurementtypelist: measurementTypeMenuItems
  }
}

export const setmassvariableTypes = (massvariableTypeMenuItems) => {
  return {
    type: MASSVARIABLETYPE_LIST,
    massvariabletypelist: massvariableTypeMenuItems
  }
}

// export const initializeAddLoadTypeForm = () => {
//   return (dispatch) => {
//       return dispatch(setDefaultValueToAddLoadTypeForm())
//   }
// }
// export const setDefaultValueToAddLoadTypeForm = () => {
//   return{
//       type : SET_DEFAULT_VALUE_TO_ADD_LOADTYPE_FORM
//   }
// }
