import {
    SET_SGADMINUSR_INPUT_FIELD,
    SET_SGADMINUSR_DEFAULT_VALUE,
    SET_SGADMINUSR_SUBMITTED_DATA,
    SET_SGADMINUSR_LIST_DEFAULT,
    UPDATE_SGADMINUSR_INFO,
    DELETE_SGADMINUSR_INFO
} from '../actions/ActionType';

import TextField from "@material-ui/core/TextField";
import React from 'react';
import { apiUrl } from "../config";

const initialState = {
    sgAdminUserForm : {
        'sgadmin_user_name' : {
            elementType : 'input',
            elementConfig : {
                type : 'text',
                label : 'Please provide an Email'
            },
            value : '',
            errorStatus : false,
            errorMessage : "Email",
            maxLength : 60
        },
        'sgadmin_user_password' : {
            elementType : 'input',
            elementConfig : {
                type : 'password',
                label : 'Please enter a Password'
            },
            value : '',
            errorStatus : false,
            errorMessage : "Enter a password",
            maxLength : 250
        }
    },
    showLoaderStatus : false,
    sgadminColumns: [
        {
          title: 'Email',
          field: 'name'
        }
      ],
    sgAdminUserRowData: []
}

const setSGAdminUserTextField = (state, action) => {
    if (action.data === '') {
      state.sgAdminUserForm[action.textField].errorStatus = true;
    }
    state.sgAdminUserForm[action.textField].value = action.data;
    return state;
  }

const submitSGAdminUser = (state, action) => {
    state.sgAdminUserForm = action.sgAdminUserForm;
    state.showLoaderStatus = action.showLoaderStatus;
    return state;
}

const setSGAdminUserDefault = (state, action) => {
    Object.keys(state.sgAdminUserForm).map((inputField) => {
        state.sgAdminUserForm[inputField].value = '';
        state.sgAdminUserForm[inputField].errorStatus = false;
    })
    return state;
}

const getSGAdminUserList = (state, action) => {
    state.sgAdminUserRowData = action.sgAdminUserRowData;
    return state;
}

const updateSGAdminUser = (state, action) => {
    state.sgAdminUserRowData = action.sgAdminUserRowData;
    return state;
}
  
const deleteSGAdminUser = (state, action) => {
    state.sgAdminUserRowData = action.sgAdminUserRowData;
    return state;
}

const sgAdminUsers = (state = initialState, action) => {
    let localState = Object.assign({}, state);
    switch (action.type) {
        case SET_SGADMINUSR_INPUT_FIELD: return setSGAdminUserTextField(localState, action);
        case SET_SGADMINUSR_DEFAULT_VALUE: return setSGAdminUserDefault(localState, action);
        case SET_SGADMINUSR_SUBMITTED_DATA: return submitSGAdminUser(localState, action);
        case SET_SGADMINUSR_LIST_DEFAULT: return getSGAdminUserList(localState, action);
        case UPDATE_SGADMINUSR_INFO: return updateSGAdminUser(localState, action);
        case DELETE_SGADMINUSR_INFO: return deleteSGAdminUser(localState, action);
    
        default:
        return localState;
    }
};  

export default sgAdminUsers;