// import {

//   } from "../actions/ActionType";
  const initialState = {
    complianceInformations : [],
    complianceColumns : [
      {
        title : "Fatigue Type",
        field : "fatigue_type"
      },
      {
        title : "Driver Type",
        field : "driver_type"
      },
      {
        title : "Vehicle Type",
        field : "vehicle_type"
      },
      {
        title : "Duration",
        field : "duration"
      },      
      {
        title : "Branch Type",
        field : "branch_type"
      },
      {
        title : "Compliance Doc",
        field : "compliance_doc"
      }
    ]
  };
  
  const NHVRCompliance = (state = initialState, action) => {
    let localState = Object.assign({}, state);
    switch (action.type) {

      default:
        return localState;
    }
  };
  
  export default NHVRCompliance;