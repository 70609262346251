import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import FormGroup from "@material-ui/core/FormGroup";
import Button from "@material-ui/core/Button";

import { connect } from "react-redux";

import {
  inputHandlerloadType,
  onSubmit,
  initializeloadTypes,
  getmeasurementTypesOptions,
  getmassvariableTypes,
  initializeAddLoadTypeForm,
  handleCloseDialog,
  onUpdateLoadType,
} from "../../actions/LoadTypes";
import Input from "../../components/UI/Input/Input";
import CircularProgress from '@material-ui/core/CircularProgress';

class AddLoadTypes extends Component {
  componentDidMount() {
    // this.props.initializeloadTypes();
    this.props.getmeasurementTypesOptions();
    this.props.getmassvariableTypes();
    if (!this.props.loadtypesState.editLoadTypeDialogOpen) {
      this.props.initializeloadTypes()
    }
  }
  render() {
    let {
      menuItems
    } = this.props.sidebarState;
    let {
      loadTypeForm,
      activeLoadTypeForEdit,
      showLoaderStatus,
      editLoadTypeDialogOpen,
      showLoadTypeUpdateLoaderStatus
    } = this.props.loadtypesState;
    // console.log(this.props.loadtypesState.showLoadTypeUpdateLoaderStatus);
    // console.log(editLoadTypeDialogOpen)
    const formElementArray = [];
    for (let key in loadTypeForm) {
      formElementArray.push({
        id: key,
        config: loadTypeForm[key]
      })
    }
    // console.log("hiii" + this.props.getmeasurementTypesOptions)
    let form = (
      <FormGroup row>
        <Grid container spacing={24}>
          {
            formElementArray.map(formElement => {
              return (
                <Grid item xs={12} sm={6} key={formElement.id}>
                  <Input
                    key={formElement.id}
                    name={formElement.config.elementConfig.label}
                    elementType={formElement.config.elementType}
                    elementConfig={formElement.config.elementConfig}
                    value={formElement.config.value}
                    menuItems={formElement.config.menuItems}
                    error={formElement.config.errorStatus}
                    helperText={formElement.config.errorMessage}
                    changed={(event) => this.props.inputHandlerloadType(formElement.id, event)}
                    // touched = {(event) => this.props.inputHandlerloadType(formElement.id, event )}
                    maxLength={formElement.config.maxLength}
                    disabledStatus = {formElement.config.disabledStatus}

                  />
                </Grid>
              )
            })
          }


        </Grid>
        <Grid container spacing={24} className="pt-2">
          <Grid item xs={8}>
          </Grid>
          <Grid item xs={4} className="d-flex justify-content-end">
            {
              editLoadTypeDialogOpen ?
                <span>
                  {
                    showLoadTypeUpdateLoaderStatus ?
                      <div className="d-flex justify-content-center align-items-center">
                        <CircularProgress color="primary" />
                      </div> :
                      <span>
                        <Button
                          variant="contained"
                          color="primary"
                          className="mr-3"
                          disabled={false}
                          style={{ marginRight: "1rem" }}
                          onClick={() => {this.props.onUpdateLoadType(loadTypeForm, activeLoadTypeForEdit, this.props, menuItems['load_types_list'].urlpattern)}}
                        >
                          Update
                        </Button>
                        <Button
                          variant="contained"
                          color="default"
                          disabled={false}
                          onClick={() => { this.props.handleCloseDialog() }}
                        >
                          Cancel
                        </Button>
                      </span>
                  }
                </span> :
                showLoaderStatus ?
                  <div className="d-flex justify-content-center align-items-center">
                    <CircularProgress color="primary" />
                  </div> :
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={false}
                    onClick={() => { this.props.onSubmit(this.props.loadtypesState, this.props, menuItems['load_types_list'].urlpattern) }}>
                    Submit
                            </Button>
            }
          </Grid>
        </Grid>
      </FormGroup>
    )
    return (
      <div className="container">
        <Card className="pl-3 pr-3 pt-3">
          <div>
            <Grid container spacing={24} className="pt-2">
              <Grid item xs={12} sm={10}>
                {
                  editLoadTypeDialogOpen ?
                    <CardHeader title="Edit Load Type" /> :
                    <CardHeader title="Add Load Type" />
                }
              </Grid>
            </Grid>
          </div>
          <CardContent>
            {form}
          </CardContent>
        </Card>
      </div>
    )
  }
}
//       <form>
//         {
//           formElementArray.map(formElement => (

//             <Input 
//               key = {formElement.id}
//               name = {formElement.config.elementConfig.label}
//               elementType={formElement.config.elementType}
//               elementConfig={formElement.config.elementConfig}
//               value={formElement.config.value}
//               menuItems = {formElement.config.menuItems}
//               error = {formElement.config.errorStatus}
//               helperText = {formElement.config.errorMessage}
//               changed={(event) => this.props.inputHandlerloadType(formElement.id, event )}
//               // touched = {(event) => this.props.inputHandlerloadType(formElement.id, event )}
//               maxLength={formElement.config.maxLength}
//               disabledStatus={formElement.config.disabledStatus}

//             />
//           ))
//         }
//       </form>
//     )
//     return (
//       <Grid container spacing={24}>
//         <Grid item xs={1} sm={2} />
//         <Grid item xs={11} sm={8}>
//         <Card className="pl-3 pr-3 pt-2 container">
//           <CardHeader title="Add Load Types" />
//             <CardContent>
//               <FormGroup row className="pl-3 pr-1">
//               <Grid container spacing={24}>
//                 <Grid item xs={10} sm={10}>
//                   {form}
//                 </Grid>
//                 </Grid>
//                 <Grid container spacing={24}>
//                 <Grid item xs={2} sm={2}>
//                 {
//                     showLoaderStatus ? <CircularProgress color = "primary" /> :
//                   <Button
//                     variant="contained"
//                     color="primary"
//                     className="mt-4"
//                     onClick = {() => {this.props.onSubmit(this.props.loadTypes, this.props)}}>
//                     Submit
//                   </Button>
//                 }
//                 </Grid>
//                 </Grid>
//               </FormGroup>
//             </CardContent>
//             <CardActions />
//           </Card>
//         </Grid>
//         <Grid item xs={1} sm={2} />
//       </Grid>

//     );
//   }
// }

const mapStateToProps = state => {
  return {
    loadtypesState: state.loadTypes,
    sidebarState : state.sidebarState
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onSubmit: (loadTypeInfo, props) => {
      return dispatch(onSubmit(loadTypeInfo, props))
    },
    onUpdateLoadType: (loadTypeForm, activeLoadTypeForEdit, props, menuItems) => {
      return dispatch(onUpdateLoadType(loadTypeForm, activeLoadTypeForEdit, props, menuItems))
    },
    inputHandlerloadType: (textField, event) => {
      return dispatch(inputHandlerloadType(textField, event));
    },
    initializeloadTypes: () => {
      return dispatch(initializeloadTypes())
    },
    getmeasurementTypesOptions: () => {
      return dispatch(getmeasurementTypesOptions())
    },
    getmassvariableTypes: () => {
      return dispatch(getmassvariableTypes())
    },
    // initializeAddLoadTypeForm: () => {
    //   return dispatch(initializeAddLoadTypeForm())
    // },
    handleCloseDialog: () => {
      return dispatch(handleCloseDialog())
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AddLoadTypes);
