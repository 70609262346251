import React from 'react';
import MaterialTable from 'material-table';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

const sgMateriaTable = (props) => {
    return(
        <MaterialTable
            {...props}
            components={{
                Action: props => (
                    <Tooltip title={props.action.tooltip}>
                        <IconButton hidden={props.action.hidden}
                            onClick={(event) => props.action.onClick(event, props.data)}
                        >
                            <i className="material-icons">
                                {props.action.icon}
                            </i>
                        </IconButton>
                    </Tooltip>
                ),
            }}
        />
    )
}
export default sgMateriaTable;
