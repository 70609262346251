import {
  SET_MASSCERTIFICATE_TEXT_FIELD,
  SET_MASSCERTIFICATE_LIST,
  SUBMIT_MASSCERTIFICATE_INFO,
  UPDATE_MASSCERTIFICATE_INFO,
  SET_DEFAULT_VALUE_TO_MASSCERTIFICATES,
  SET_UPLOAD_INPUT_CHANGE_MC
} from "./ActionType";
import axios from '../axios';
import { toast } from 'react-toastify';
import {
  convertToBase64, getActulaURL
} from "../utils/Helper";

export const initializemassCertificateComponent = () => {
  return (dispatch) => {
    return dispatch(setDefaultValueTomassCertificateComponent())
  }
}
export const setDefaultValueTomassCertificateComponent = () => {
  return {
    type: SET_DEFAULT_VALUE_TO_MASSCERTIFICATES
  }
}

export const handlemassCertificateTextFields = (textField, event) => {
  return dispatch => {
    return dispatch(setmassCertificateTextFields(textField, event));
  };
};

export const setmassCertificateTextFields = (textField, event) => {
  return {
    type: SET_MASSCERTIFICATE_TEXT_FIELD,
    textField: textField,
    data: event.target.value
  };
};


export const getmassCertificateList = () => {
  let massCertificate = {};
  let listmassCertificateLoaderStatus = true;
  let massCertificateList = [];
  let businessId = localStorage.getItem('business_id');
  let reqURL = "/businesses/" + businessId + "/masscertificate";
  return (dispatch) => {
    dispatch(setmassCertificateList(massCertificateList, listmassCertificateLoaderStatus));
    return axios.get(reqURL)
      .then(response => {
        listmassCertificateLoaderStatus = false;
        if (response.data.data !== null) {
          let newdata = response.data.data[0]
          console.log(newdata)
          for (let i = 0; i < newdata.mass_certifcate_data.length; i++) {
            // console.log(newdata.mass_certifcate_data[i])
            massCertificate = {};
            massCertificate['_id'] = newdata.mass_certifcate_data[i]._id;
            massCertificate['certificatename'] = newdata.mass_certifcate_data[i].masscertificate_name;
            // massCertificate['certificatedata'] = newdata.mass_certifcate_data[i].
            massCertificate['status'] = newdata.mass_certifcate_data[i].status;
            massCertificate['bucket'] = newdata.mass_certifcate_data[i].mass_certificate_documentObject.bucket_name;
            massCertificate['key'] = newdata.mass_certifcate_data[i].mass_certificate_documentObject.bucket_key;
            massCertificateList.push(massCertificate);
          }
        }
        dispatch(setmassCertificateList(massCertificateList, listmassCertificateLoaderStatus));
      })
      .catch(error => {
        listmassCertificateLoaderStatus = false;
        if (error.response.status == 403) {
          localStorage.clear();
          window.location.href = "/";
        }
        dispatch(setmassCertificateList(massCertificateList, listmassCertificateLoaderStatus));
      })
  }
}

export const setmassCertificateList = (massCertificateList, listmassCertificateLoaderStatus) => {
  return {
    type: SET_MASSCERTIFICATE_LIST,
    massCertificateList: massCertificateList,
    listmassCertificateLoaderStatus: listmassCertificateLoaderStatus
  }
}

/**************************************************************************************** */

export const onSubmit = (massCertificateInfo, base64_string, props) => {
  return (dispatch) => {
    let showLoaderStatus = false;
    let fieldValidationStatus = true;
    let businessId = localStorage.getItem('business_id');
    let fileExtension;
    let mimeType = '';
    let reqURL = "/businesses/" + businessId + "/masscertificate";
    let certificatedata = {}
    let addmassCertificateReq = {};
    let createdInfo = {};

    Object.keys(massCertificateInfo.massCertificateForm).map((textField) => {
      if (massCertificateInfo.massCertificateForm[textField].value == '' && textField !== 'certificate_image') {
        fieldValidationStatus = false;
        massCertificateInfo.massCertificateForm[textField].errorStatus = true;
      }
    })
    dispatch(setSubmittedData(
      massCertificateInfo.massCertificateListrows,
      showLoaderStatus,
      massCertificateInfo.massCertificateForm
    )
    );
    if (fieldValidationStatus) {
      showLoaderStatus = true;

      let fileName = (massCertificateInfo.massCertificateForm['certificate_image'].value)
      fileName = fileName.split('.');
      // console.log(fileName)
      fileExtension = fileName.pop();
      // console.log("chek" +fileExtension)
      dispatch(setSubmittedData(
        massCertificateInfo.massCertificateListrows,
        showLoaderStatus,
        massCertificateInfo.massCertificateForm
      )
      );

      if (fileExtension === 'docx') {
        mimeType = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
      }
      if (fileExtension === 'doc') {
        mimeType = 'application/msword';
      }
      if (fileExtension === 'pdf') {
        mimeType = 'application/pdf';
      }
      if (fileExtension === 'jpeg') {
        mimeType = 'application/jpeg';
      }
      if (fileExtension === 'jpg') {
        mimeType = 'application/jpeg';
      }
      addmassCertificateReq["certificatedata"] = { 'file_type': mimeType, 'base64_string': base64_string }
      addmassCertificateReq["certificatename"] = massCertificateInfo.massCertificateForm['mass_certificate_name'].value;
      addmassCertificateReq["status"] = true;
      console.log("inserted data" + mimeType)
      return axios.post(reqURL, addmassCertificateReq)

        .then(response => {
          showLoaderStatus = false;
          toast.success("MassCertificate Created Successfully", {
            containerId: 'toastMsg',
            onClose: () => props.history.push('/businessportal/' + businessId + '/certificatelist')
          }
            //   createdInfo = {};
            //   createdInfo['massCertificateid'] = response.data.data.massCertificateid;
            //   createdInfo['description'] = response.data.data.description;
            //   createdInfo['current'] = response.data.data.current;
            //   massCertificateInfo.massCertificateListrows.unshift(createdInfo);
            //   Object.keys(massCertificateInfo.massCertificateForm).map((textField) => {
            //     massCertificateInfo.massCertificateForm[textField].value = '';
            //   })
            //   
          );
          dispatch(setSubmittedData(
            massCertificateInfo.massCertificateListrows,
            showLoaderStatus,
            massCertificateInfo.massCertificateForm
          )

          );

        })
        .catch(error => {
          if (error.response.status == 403) {
            showLoaderStatus = false;
            localStorage.clear();
            // toast.error("Session Expired ! Please login again", {containerId: 'toastMsg'});
            window.location.href = "/";
          } else {
            showLoaderStatus = false;
            toast.error(error.response.data.message, { containerId: 'toastMsg' });
            dispatch(setSubmittedData(
              massCertificateInfo.massCertificateListrows,
              showLoaderStatus,
              massCertificateInfo.massCertificateForm
            )
            );
          }
        })
    }

  }

}

export const handleUploadInputChange = (textField, event) => {
  let textFieldValue = event.target.value.replace(/^.*\\/, "");
  return (dispatch) => {
    convertToBase64(event.target.files[0]).then(data => {
      let base64 = data;
      if ((((new Blob([base64]).size) / 1024) / 1024) > 1.5) {
        textFieldValue = '';
        toast.error("File size should be less than 1 MB", { containerId: 'toastMsg' });
      }
      dispatch(setUploadInputChanges(base64, textField, textFieldValue))
    });

  }
}
export const setUploadInputChanges = (base64, textField, textFieldValue) => {
  return {
    type: SET_UPLOAD_INPUT_CHANGE_MC,
    base64: base64,
    textField: textField,
    data: textFieldValue
  }
}

export const setSubmittedData = (massCertificateListrows, showLoaderStatus, massCertificateForm) => {
  return {
    type: SUBMIT_MASSCERTIFICATE_INFO,
    massCertificateListrows: massCertificateListrows,
    showLoaderStatus: showLoaderStatus,
    massCertificateForm: massCertificateForm
  }
}

/************************************************************************************************** */
export const editmassCertificate = (massCertificateInfo, massCertificateListrows) => {
  let businessId = localStorage.getItem('business_id');
  let reqURL = "/businesses/" + businessId + "/masscertificate/"+ massCertificateInfo._id;
  let updateReq = {};
  console.log("check" , massCertificateInfo);
  updateReq['certificatename'] = massCertificateInfo.certificatename;
  updateReq['status'] = massCertificateInfo.status == "true" ? true : false;
  updateReq['certificatedata'] = { 'file_type': "", 'base64_string': "" };
  return (dispatch) => {
    return axios.put(reqURL, updateReq)
      .then(response => {
        const updateIndex = massCertificateListrows.findIndex(d => {
          return d._id === massCertificateInfo._id;
        });
        massCertificateListrows[updateIndex].certificatename = massCertificateInfo.certificatename;
        massCertificateListrows[updateIndex].status = massCertificateInfo.status;
        toast.success("certificate Updated Successfully", { containerId: 'toastMsg' });
        dispatch(updateInfo(massCertificateListrows));
      })
      .catch(error => {
        if (error.response.status == 403) {
          localStorage.clear();
          toast.error(
            "Session Expired ! Please login again",
            {
              containerId: 'toastMsg',
              onclose: () => window.location.href = "/"
            }
          );
        } else {
          toast.error(error.response.data.message, { containerId: 'toastMsg' });
        }

        dispatch(updateInfo(massCertificateListrows));
      })
  }
}
export const updateInfo = (massCertificateListrows) => {
  return {
    type: UPDATE_MASSCERTIFICATE_INFO,
    massCertificateListrows: massCertificateListrows
  }
}