import React from 'react';
import TextField from "@material-ui/core/TextField";
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from "@date-io/date-fns";
import { withStyles } from "@material-ui/core/styles";

import {
    MuiPickersUtilsProvider, DatePicker
} from 'material-ui-pickers';

const styles = theme => ({
    number: {
      "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
        "-webkit-appearance": "none",
        margin: 0
      }
    }
  });
const fileClick = () => {
    document.getElementById('outlined-button-file').click()
}
const input = ( props) => {
    let { classes } = props;
    let inputElement = null;
    switch(props.elementType){
        case ('date'):
            inputElement = <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid container justify="space-around">
            <DatePicker
                margin="normal"
                label = { props.elementConfig.label }
                fullWidth = { true }
                value = { props.value }
                onChange={ props.changed }
                format="dd/MM/yyyy"
                error = { props.error }
                helperText = { props.error ? props.helperText : null }
                shouldDisableDate = { props.shouldDisableDate }
                disabled = { props.disabledStatus }
              />
            </Grid>
          </MuiPickersUtilsProvider>
            return inputElement;
        case ('input'):
            inputElement = <TextField
                {...props.elementConfig}
                value = {props.value}
                error = {props.error}
                helperText = {props.error ? props.helperText : null}
                fullWidth = {true}
                margin="normal"
                onChange = {props.changed}
                onBlur = {props.touched}
                inputProps = {{ maxLength : props.maxLength}}
                className={classes.number}
                disabled = {props.disabledStatus}
            />
            return inputElement;
        case ('select'):
            inputElement =

            <FormControl
                fullWidth={true}
                error = {props.error}
                disabled = {props.disabledStatus}
            >
                <InputLabel style = {{paddingTop : "1rem"}}>{props.name}</InputLabel>
                <Select
                    style = {{marginTop : "2rem"}}
                    value={props.value}
                    fullWidth={true}
                    onChange={props.changed}
                >
                    {
                        Object.keys(props.menuItems).map((menuItems) => {
                            return(
                                <MenuItem
                                    value = {props.menuItems[menuItems].value}
                                    key = {props.menuItems[menuItems].value}
                                >
                                    {props.menuItems[menuItems].name}
                                </MenuItem>
                            )
                        })
                    }

                </Select>
                {
                    props.error ? <FormHelperText>{props.helperText}</FormHelperText> : null
                }

            </FormControl>
            return inputElement;
        case ('upload_csv') :
            inputElement =
                <div>
                    <TextField
                        {...props.elementConfig}
                        value = {props.value}
                        error = {props.error}
                        helperText = {props.error ? props.helperText : null}
                        fullWidth = {true}
                        margin="normal"
                        onBlur = {props.touched}
                        onClick = {() => fileClick()}
                        disabled = {props.disabledStatus}
                    />
                    <input
                        accept=".csv"
                        style = {{visibility : "hidden"}}
                        id="outlined-button-file"
                        multiple
                        type="file"
                        onChange = {props.changed}
                        disabled = {props.disabledStatus}
                    />
                </div>

            return inputElement;
        case ('upload_json') :
        inputElement =
            <div>
                <TextField
                    {...props.elementConfig}
                    value = {props.value}
                    error = {props.error}
                    helperText = {props.error ? props.helperText : null}
                    fullWidth = {true}
                    margin="normal"
                    onBlur = {props.touched}
                    onClick = {() => fileClick()}
                    disabled = {props.disabledStatus}
                />
                <input
                    accept="application/json"
                    style = {{visibility : "hidden"}}
                    id="outlined-button-file"
                    multiple
                    type="file"
                    onChange = {props.changed}
                    disabled = {props.disabledStatus}
                />
            </div>

            return inputElement;
        case ('upload_file') :
            inputElement =
                <div>
                    <TextField
                        {...props.elementConfig}
                        value = {props.value}
                        error = {props.error}
                        helperText = {props.error ? props.helperText : null}
                        fullWidth = {true}
                        margin="normal"
                        onBlur = {props.touched}
                        onClick = {() => fileClick()}
                        disabled = {props.disabledStatus}
                    />
                    <input
                        accept="application/pdf,.doc,.docx,.jpg,.jpeg"
                        style = {{visibility : "hidden"}}
                        id="outlined-button-file"
                        multiple
                        type="file"
                        onChange = {props.changed}
                        disabled = {props.disabledStatus}
                    />
                </div>
            return inputElement;
        case ('upload_image') :
        inputElement =
            <div>
                <TextField
                    {...props.elementConfig}
                    value = {props.value}
                    error = {props.error}
                    helperText = {props.error ? props.helperText : null}
                    fullWidth = {true}
                    margin="normal"
                    onBlur = {props.touched}
                    onClick = {() => fileClick()}
                    disabled = {props.disabledStatus}
                />
                <input
                    accept="application/jpg,.jpeg,.png"
                    style = {{visibility : "hidden"}}
                    id="outlined-button-file"
                    multiple
                    type="file"
                    onChange = {props.changed}
                    disabled = {props.disabledStatus}
                />
            </div>
        return inputElement;
        case ('checkbox') :
            inputElement =
                <FormControlLabel
                    control={
                        <Checkbox
                            {...props.elementConfig}
                            onChange={props.changed}
                        />
                    }
                    label={props.name}
                />
            return inputElement;
        default :
            return inputElement

    }
}

export default withStyles(styles)(input);
