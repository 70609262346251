import React, {Component} from 'react';
import SGMateriaTable from '../../components/UI/Table/SGMaterialTable';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import axios from "../../axios";
import {toast} from "react-toastify";
import {handleEditPotentialNonComplianceOpenDialog } from '../../actions/PotentialNonCompliance'
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import Dialog from '@material-ui/core/Dialog';
import EditPotentialNonCompliance from './EditPotentialNonCompliance';


class PotentialNonComplianceList extends Component {

state = { nonComplianceBreach: [] };

getNonComplianceBreachList =  async() => {      
    // Get
   /////
   var breachJsonData = [];  
   let buisnessId = localStorage.getItem('business_id');

   let reqUrl = '/businesses/' + buisnessId + '/potentialcompliancebreachlist';
   let headers = {'Authorization': localStorage.getItem('token')};
   await axios.get(reqUrl, {headers}).then(response => {
      // breachJsonData = response ? (response.data ? response.data.data : []) : [];
       let data = response.data.data;
       data.forEach(element => {
            element['driver_name'] = element['driver_firstname'] + ' ' + element['driver_lastname'];                    
            breachJsonData.push(element);
       });

       this.setState({nonComplianceBreach: breachJsonData});

       
   }).catch(error => {
       toast.error(error.response.data.message, {
           containerId: 'toastMsg',
       });
   })   
    
}

componentDidMount(){
   this.getNonComplianceBreachList();
}

 render(){

    let { 
        editPotentialNonCompliancelist,        
        openEditpotentialNonComplianceDialogStatus,              
    } = this.props.potentialNonComplianceState;

    let complianceColumns = [
        {
            title: 'Status',
            field: 'status',
            lookup: {
                'PENDING': 'pending',
                'INPROGRESS': 'inprogress',
                'COMPLETED': 'completed',
               },
            emptyValue: () => <div>Pending</div>

        },
       
        {
            title: 'Driver Name',
            field: 'driver_name'
        },
        {
            title: 'License Number',
            field: 'driver_license'
        },
        {
            title: 'UID',
            field: 'driver_id'
        },
        {
            title: 'Vehicle',
            field: 'vehicle_id'
        },
        {
            title: 'Breach Condition',
            field: 'breach_type'
        },
        {
            title: 'DateTime',
            field: 'breach_createdtimeoffset'
        },
        {
            title: 'Location',
            field: 'location'
        }
    ]; 
   

    {
    
        return(
            <div className="container">
              <div className = "mt-4 mb-5">
                        <Paper>
                            <Grid container spacing = {24} style = {{paddingLeft : "1rem", paddingTop : "0", paddingRight : "1rem"}}>
                                <Grid item xs = {6} style = {{paddingTop : "0"}}>
                                    <h4 style = {{marginTop : "1rem", textTransform : "uppercase"}}>Breach List</h4>
                                </Grid>
                            </Grid>
                         </Paper>
                 </div>
                 <SGMateriaTable
                                        title=""
                                        columns={complianceColumns}
                                        data={this.state.nonComplianceBreach}
                                        // actions={[
                                        //     {
                                        //         icon: 'edit',
                                        //         tooltip: 'Edit Record',
                                        //         onClick: (event, rowData) => this.props.handleEditPotentialNonComplianceOpenDialog(rowData)
                                        //     }
                                        // ]}
                />
                 <Dialog
                    open={openEditpotentialNonComplianceDialogStatus}                   
                    maxWidth = 'md'
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">
                    <EditPotentialNonCompliance/>
                    
                </Dialog>
            </div>
        );
          
         
     }

 }  

}

const mapStateToProps = state => {
    return{
        potentialNonComplianceState : state.potentialNonCompliance
    }
}

const matDispatchToProps = (dispatch) => {
    return {
        handleEditPotentialNonComplianceOpenDialog : (rowData) => {
            return dispatch(handleEditPotentialNonComplianceOpenDialog(rowData))
        }
    }
}

export default connect(mapStateToProps,matDispatchToProps)(withRouter(PotentialNonComplianceList));

