import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import CardHeader from '@material-ui/core/CardHeader';
import{ withRouter } from 'react-router-dom';
import FormGroup from '@material-ui/core/FormGroup';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';

import Input from "../../components/UI/Input/Input";


import {
    handleLinkDriverDialogClose,
    handleEditDriverDialogClose,
    onSubmitAddDriver,
    linkToBusiness,
    handleAddDriverInputFieldChange,
    handleInputDOB,
    handleFatigueTypeCheckboxes,
    handleDriverTypeCheckboxes,
    onUpdateDriver,
    unlinkDriver,
    handleLicensingModuleCheckboxes,
    handleAdminCheckboxes,
    handleContractCheckboxes,
    initializeAddDriverComponent
} from '../../actions/Driver';

const queryString = require('query-string');

const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing.unit * 2,
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
  card: {
    minWidth: 275,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});


class AddDriver extends Component {
    componentDidMount(){
        console.log(window.location.search);
//=> '?foo=bar'

        const parsed = queryString.parse(window.location.search);
        if (this.props.driver.driverIdForEdit == false){
            this.props.initializeAddDriverComponent();}
            let event ={
                target:{
                    "value":""
                }
            }
            
            if(parsed['license_number']){
                event.target.value = parsed['license_number']
                this.props.handleAddDriverInputFieldChange("license_number", event)
            }

            if(parsed['dob']){
                event.target.value = parsed['dob']
                this.props.handleAddDriverInputFieldChange("dob", event)
            }
    }
    render(){
        const { classes } = this.props;
        let {
            addDriverFormFields,
            fatigueTypesCheckboxes,
            driverTypesCheckBoxes,
            activeDriverRecordForLink,
            openLinkDriverDialogstatus,
            openEditDriverDialogStatus,
            openAdminStatus,
            driverIdForEdit,
            driverForEditLinkStatus,
            driverUpdateLoaderStatus,
            driverInformations,
            deleteDriverLoaderStatus,
            driverLinkLoaderStatus,
            licensingModuleCheckBoxes,
            adminCheckBoxes,
            contractorCheckBoxes,
            showLoaderStatus,
            getDriverLicenseStatusLoaderStatus
        } = this.props.driver;
        let{
            menuItems
        } = this.props.sidebarState;
        const addDriverFormFieldElementArray = [];
        const fatigueTypesCheckboxesElementArray = [];
        const driverTypesCheckBoxesElementArray = [];
        const licensingModuleCheckBoxesElementArray = [];
        const adminCheckBoxesElementArray = [];
        const contractorCheckBoxesElementArray = [];


        for(let key in adminCheckBoxes){
            adminCheckBoxesElementArray.push({
                id : key,
                config : adminCheckBoxes[key]
            })
        }
        for(let key in contractorCheckBoxes){
            contractorCheckBoxesElementArray.push({
                id : key,
                config : contractorCheckBoxes[key]
            })
        }
        for(let key in licensingModuleCheckBoxes){
            licensingModuleCheckBoxesElementArray.push({
                id : key,
                config : licensingModuleCheckBoxes[key]
            })
        }
        for(let key in fatigueTypesCheckboxes){
            fatigueTypesCheckboxesElementArray.push({
                id : key,
                config : fatigueTypesCheckboxes[key]
            })
        }
        for(let key in driverTypesCheckBoxes){
            driverTypesCheckBoxesElementArray.push({
                id : key,
                config : driverTypesCheckBoxes[key]
            })
        }
        for(let key in addDriverFormFields){
            addDriverFormFieldElementArray.push({
                id : key,
                config : addDriverFormFields[key]
            })
        }


        //Admin Checkboxes
        let adminCheckBox = null;
        if (openAdminStatus)
        {
            adminCheckBox = (
                <FormGroup>
                    <Grid container spacing={24}>
                        {
                            adminCheckBoxesElementArray.map(formElement => {
                                    return(
                                        <Grid item xs={12} sm = {4} key = {formElement.id}>
                                            <Input
                                                key = {formElement.id}
                                                name = {formElement.config.elementConfig.label}
                                                elementType={formElement.config.elementType}
                                                elementConfig={formElement.config.elementConfig}
                                                value={formElement.config.value}
                                                menuItems = {formElement.config.menuItems}
                                                error = {formElement.config.errorStatus}
                                                helperText = {formElement.config.errorMessage}
                                                changed={() => this.props.handleAdminCheckboxes(formElement.id)}
                                            />
                                        </Grid>
                                    )
                                // }
                            }
                        )
                        }
                    </Grid>
                </FormGroup>
            );
        }

        let contractorCheckBox = null;
        if (openEditDriverDialogStatus || openLinkDriverDialogstatus){
            contractorCheckBox = (
                <FormGroup>
                    <Grid container spacing={24}>
                        {
                            contractorCheckBoxesElementArray.map(formElement => {
                                    return (
                                        <Grid item xs={12} sm = {4} key = {formElement.id}>
                                            <Input
                                                key = {formElement.id}
                                                name = {formElement.config.elementConfig.label}
                                                elementType={formElement.config.elementType}
                                                elementConfig={formElement.config.elementConfig}
                                                value={formElement.config.value}
                                                menuItems = {formElement.config.menuItems}
                                                error = {formElement.config.errorStatus}
                                                helperText = {formElement.config.errorMessage}  
                                                changed={() => this.props.handleContractCheckboxes(formElement.id)}                                          
                                            />
                                        </Grid>
                                    )
                            }
                        )
                        }
                        </Grid>
                </FormGroup>
          );
        }     
        //Licensing Module Checkboxes
        let licensingModuleCheckBox = (
            <FormGroup>
                <Grid container spacing={24}>
                    {
                        licensingModuleCheckBoxesElementArray.map(formElement => {
                                return (
                                    <Grid item xs={12} sm = {4} key = {formElement.id}>
                                        <Input
                                            key = {formElement.id}
                                            name = {formElement.config.elementConfig.label}
                                            elementType={formElement.config.elementType}
                                            elementConfig={formElement.config.elementConfig}
                                            value={formElement.config.value}
                                            menuItems = {formElement.config.menuItems}
                                            error = {formElement.config.errorStatus}
                                            helperText = {formElement.config.errorMessage}
                                            changed={() => this.props.handleLicensingModuleCheckboxes(formElement.id)}
                                        />
                                    </Grid>
                                )
                        }
                    )
                    }
                </Grid>
            </FormGroup>
        );

        //Fatigue Type Checkboxes
        let fatigueTypeCheckbox = (
            openLinkDriverDialogstatus ? '' :
                <FormGroup row>
                    <h6 class="MuiTypography-root MuiTypography-h6 MuiTypography-gutterBottom" >Fatigue Profile</h6>
                    <Grid container spacing={24}>
                        {
                            fatigueTypesCheckboxesElementArray.map(formElement => {
                                    return(
                                        <Grid item xs={12} sm = {4} key = {formElement.id}>
                                            <Input
                                                key = {formElement.id}
                                                name = {formElement.config.elementConfig.label}
                                                elementType={formElement.config.elementType}
                                                elementConfig={formElement.config.elementConfig}
                                                value={formElement.config.value}
                                                menuItems = {formElement.config.menuItems}
                                                error = {formElement.config.errorStatus}
                                                helperText = {formElement.config.errorMessage}
                                                changed={(event) => this.props.handleFatigueTypeCheckboxes(formElement.id, event)}
                                                maxLength = {formElement.config.maxLength}
                                                disabledStatus = {formElement.config.disabledStatus}
                                            />
                                        </Grid>
                                    )
                                }
                            )
                        }
                    </Grid>
                </FormGroup>
        )

        let driverTypeCheckbox = (
            <FormGroup row>
                <Grid container spacing={24}>
                    {
                        driverTypesCheckBoxesElementArray.map(formElement => {
                                if(formElement.id === 'select_all'){
                                    return(
                                        <Grid item xs={12} sm = {12} key = {formElement.id}>
                                            <Input
                                                key = {formElement.id}
                                                name = {formElement.config.elementConfig.label}
                                                elementType={formElement.config.elementType}
                                                elementConfig={formElement.config.elementConfig}
                                                value={formElement.config.value}
                                                menuItems = {formElement.config.menuItems}
                                                error = {formElement.config.errorStatus}
                                                helperText = {formElement.config.errorMessage}
                                                changed={() => this.props.handleDriverTypeCheckboxes(formElement.id)}
                                            />
                                        </Grid>
                                    )
                                }
                            else{
                                return(
                                    <Grid item xs={12} sm = {4} key = {formElement.id}>
                                        <Input
                                            key = {formElement.id}
                                            name = {formElement.config.elementConfig.label}
                                            elementType={formElement.config.elementType}
                                            elementConfig={formElement.config.elementConfig}
                                            value={formElement.config.value}
                                            menuItems = {formElement.config.menuItems}
                                            error = {formElement.config.errorStatus}
                                            helperText = {formElement.config.errorMessage}
                                            changed={() => this.props.handleDriverTypeCheckboxes(formElement.id)}
                                        />
                                    </Grid>
                                )
                            }
                        }
                    )
                    }
                </Grid>
            </FormGroup>
        )
        let addDriverInputForm = (
            <FormGroup row>
                <Grid container spacing={24}>
                    {
                        addDriverFormFieldElementArray.map(formElement => {
                            if (openLinkDriverDialogstatus && (formElement.id == 'baseLocation'
                                || formElement.id == 'recordKeeper'
                                || formElement.id == 'homeBaseTimeZone')) {
                                // NOOP
                            } else {
                                if(formElement.config.elementType === 'date'){
                                    if(formElement.id === 'dob'){
                                        return(
                                            <Grid item xs={12} sm = {4} key = {formElement.id}>
                                                <Input
                                                    key = {formElement.id}
                                                    name = {formElement.config.elementConfig.label}
                                                    elementType={formElement.config.elementType}
                                                    elementConfig={formElement.config.elementConfig}
                                                    value={formElement.config.value}
                                                    menuItems = {formElement.config.menuItems}
                                                    error = {formElement.config.errorStatus}
                                                    helperText = {formElement.config.errorMessage}
                                                    changed={(date) => this.props.handleInputDOB(formElement.id, date)}
                                                    disabledStatus = {formElement.config.disabledStatus}
                                                    maxLength={formElement.config.maxLength}
                                                />
                                            </Grid>
                                        )
                                    }
                                }else{
                                    return(
                                        <Grid item xs={12} sm = {4} key = {formElement.id}>
                                            <Input
                                                key = {formElement.id}
                                                name = {formElement.config.elementConfig.label}
                                                elementType={formElement.config.elementType}
                                                elementConfig={formElement.config.elementConfig}
                                                value={formElement.config.value}
                                                menuItems = {formElement.config.menuItems}
                                                error = {formElement.config.errorStatus}
                                                helperText = {formElement.config.errorMessage}
                                                changed={(event) => this.props.handleAddDriverInputFieldChange(formElement.id, event)}
                                                maxLength = {formElement.config.maxLength}
                                                disabledStatus = {formElement.config.disabledStatus}
                                                //touched = {(event) => this.props.handleAddDriverInputFieldChange(formElement.id, event)}

                                            />
                                        </Grid>
                                    )
                                }
                            }
                        }
                    )
                    }
                </Grid>
            </FormGroup>
        )
        let elevationStatus = 2;
        if(openLinkDriverDialogstatus || openEditDriverDialogStatus){
            elevationStatus = 0;
        }
        return(
            <div className = "container">
                <Card className={classes.card} elevation = {elevationStatus} className = "pl-3 pr-3 pt-3 mb-5" >
                    {
                        openLinkDriverDialogstatus ?
                        <CardHeader
                            title="Link Driver"
                        /> : openEditDriverDialogStatus ?

                        <CardHeader
                            title="Edit Driver"
                        /> :
                        <CardHeader
                            title="Add Driver"
                        />
                    }
                    <CardContent>
                        {
                            getDriverLicenseStatusLoaderStatus  ?
                            <div style = {{height : "30rem", width : "900px"}} className = "d-flex justify-content-center align-items-center">
                                <CircularProgress color = "primary" />
                            </div> :
                            <div>
                                {addDriverInputForm}
                                <br/>
                                {fatigueTypeCheckbox}
                                <Typography variant="h6" style={{paddingTop:20}} gutterBottom>
                                    DRIVER TYPES
                                </Typography>
                                {driverTypeCheckbox}

                                {/*<Typography variant="h6" style={{paddingTop:20}} gutterBottom>*/}
                                {/*LICENSE*/}
                                {/*</Typography>*/}
                                {/*{licensingModuleCheckBox}*/}

                                { openAdminStatus ?
                                <Typography variant="h6" style={{paddingTop:20}} gutterBottom>
                                ADMIN ONLY
                                </Typography>
                                : <div></div> }                               
                                {adminCheckBox}                               
                                { openLinkDriverDialogstatus || openEditDriverDialogStatus ?
                                <Typography variant="h6" style={{paddingTop:20}} gutterBottom>
                                 Contractor
                                </Typography>
                                : <div></div> }                               
                                {contractorCheckBox}
                                <Grid
                                    container
                                    spacing={24}
                                    className = "pt-2"
                                    direction="row"
                                    justify="flex-end"
                                    alignItems="center"
                                >
                                {
                                    openEditDriverDialogStatus ?
                                    <Grid item>
                                        {
                                            driverUpdateLoaderStatus || deleteDriverLoaderStatus ? <CircularProgress color = "primary" /> :
                                            <div>
                                                {(driverForEditLinkStatus == 'DELINKED' || driverForEditLinkStatus == 'DRIVER DECLINED') ? <span/> : <Button variant="contained" color="primary" className = "mr-3" onClick = {() => {this.props.onUpdateDriver(addDriverFormFields, driverTypesCheckBoxes, fatigueTypesCheckboxes,licensingModuleCheckBoxes, adminCheckBoxes,contractorCheckBoxes, driverIdForEdit, driverInformations)}}>Update</Button>}
                                                {(driverForEditLinkStatus == 'DELINKED' || driverForEditLinkStatus == 'DRIVER DECLINED') ? <span/> : <Button variant="contained" color="primary" className = "mr-3" onClick = {() => {this.props.unlinkDriver(driverIdForEdit,this.props, menuItems['driver_list'].urlpattern, driverInformations)}}>DeLink</Button>}
                                                <Button
                                                    variant="contained"
                                                    color="default"
                                                    disabled={false}
                                                    className = "mr-3"
                                                    onClick = {() => {this.props.handleEditDriverDialogClose()}}
                                                >
                                                    Cancel
                                                </Button>
                                            </div>
                                          
                                        }
                                    </Grid>
                                    :openLinkDriverDialogstatus ?
                                    <div>
                                        {
                                            driverLinkLoaderStatus ? <CircularProgress color = "primary" /> :
                                            <div>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    disabled={false}
                                                    className = "mr-3"
                                                    onClick = {() => this.props.linkToBusiness(fatigueTypesCheckboxes,licensingModuleCheckBoxes,contractorCheckBoxes, this.props, activeDriverRecordForLink, menuItems['driver_list'].urlpattern)}
                                                >
                                                    Link To Business
                                                </Button>
                                                <Button
                                                    variant="contained"
                                                    color="default"
                                                    disabled={false}
                                                    onClick = {() => {this.props.handleLinkDriverDialogClose()}}
                                                >
                                                    Cancel
                                                </Button>
                                            </div>
                                        }

                                    </div> :
                                    <Grid item>
                                        {
                                            showLoaderStatus ? <CircularProgress color = "primary" /> :
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                disabled={false}
                                                onClick = {() => {this.props.onSubmitAddDriver(addDriverFormFields, driverTypesCheckBoxes, fatigueTypesCheckboxes,licensingModuleCheckBoxes, adminCheckBoxes,contractorCheckBoxes, this.props, menuItems['driver_list'].urlpattern)}}
                                            >
                                                Submit
                                            </Button>
                                        }
                                    </Grid>
                                }

                                </Grid>
                            </div>

                        }

                    </CardContent>
                </Card>

            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return{
        driver : state.driver,
        sidebarState : state.sidebarState
    }
}

const mapDispatchToProps = (dispatch) => {
    return{
        initializeAddDriverComponent : () => {
            return dispatch(initializeAddDriverComponent())
        },
        handleAdminCheckboxes : (formElementId) => {
            return dispatch(handleAdminCheckboxes(formElementId))
        },
        handleContractCheckboxes : (formElementId) => {
            return dispatch(handleContractCheckboxes(formElementId))
        },
        handleLicensingModuleCheckboxes : (formElementId) => {
            return dispatch(handleLicensingModuleCheckboxes(formElementId))
        },
        unlinkDriver : (driverIdForEdit,props, url, driverInformations) => {
            return dispatch(unlinkDriver(driverIdForEdit,props, url, driverInformations))
        },
        handleEditDriverDialogClose : () => {
            return dispatch(handleEditDriverDialogClose())
        },
        onUpdateDriver : (addDriverFormFields, driverTypesCheckBoxes, fatigueTypesCheckboxes,licensingModuleCheckBoxes, adminCheckBoxes, contractorCheckBoxes,driverIdForEdit, driverInformations) => {
            return dispatch(onUpdateDriver(addDriverFormFields, driverTypesCheckBoxes, fatigueTypesCheckboxes,licensingModuleCheckBoxes, adminCheckBoxes,contractorCheckBoxes, driverIdForEdit, driverInformations))
        },
        handleDriverTypeCheckboxes : (formElementId) => {
            return dispatch(handleDriverTypeCheckboxes(formElementId))
        },
        handleFatigueTypeCheckboxes : (formElementId, event) => {
            return dispatch(handleFatigueTypeCheckboxes(formElementId, event))
        },
        handleInputDOB : (formElementId, date) => {
            return dispatch(handleInputDOB(formElementId, date))
        },
        handleAddDriverInputFieldChange : (formElementId, event) => {
            if(formElementId === 'license_number'){
                event.target.value = event.target.value.replace(/[^\w\s]/gi, '');
            }
            return dispatch(handleAddDriverInputFieldChange(formElementId, event))
        },
        onSubmitAddDriver:(addDriverFormFields, driverTypesCheckBoxes, fatigueTypesCheckboxes,licensingModuleCheckBoxes, adminCheckBoxes,contractorCheckBoxes, props, url)=>{
            return dispatch(onSubmitAddDriver(addDriverFormFields, driverTypesCheckBoxes, fatigueTypesCheckboxes,licensingModuleCheckBoxes, adminCheckBoxes,contractorCheckBoxes, props, url))
        },
        handleLinkDriverDialogClose : () => {
            return dispatch(handleLinkDriverDialogClose())
        },
        linkToBusiness : (fatigueTypesCheckboxes,licensingModuleCheckBoxes,contractorCheckBoxes, props, activeDriverRecordForLink, url) => {
            return dispatch(linkToBusiness(fatigueTypesCheckboxes,licensingModuleCheckBoxes,contractorCheckBoxes, props, activeDriverRecordForLink, url))
        },
    }
}
export default connect(mapStateToProps, mapDispatchToProps) (withStyles(styles)(withRouter(AddDriver)));
