import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid'
import './Signin.css';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';

import {
  // notAUserSubmit,
  submitNotAUserDetails,
  handleNotAuserInputFields
} from '../../actions/Login';
class NotAUser extends Component {
  render() {
    let {
      notAUsertextFields,
      submitNotAUserDetailsLoaderStatus
    }  = this.props.loginState;
    return (
      <form className="d-flex align-items-center justify-content-center">
        <div className="card-body">
        {
          Object.keys(notAUsertextFields).map(inputField => {
            if(inputField !== 'message'){
              return(
                <div className="input-group no-border input-lg">
                  <input 
                    type={notAUsertextFields[inputField].type} 
                    className="form-control" 
                    placeholder={notAUsertextFields[inputField].name}
                    onChange = {(event) => this.props.handleNotAuserInputFields(event, inputField)}
                    maxlength = {notAUsertextFields[inputField].maxlength}
                  />
                </div>
              )
            }else{
              return(
                <div className="input-group no-border input-lg">
                  <textarea 
                    rows="4" cols="50"
                    type={notAUsertextFields[inputField].type} 
                    className="form-control" 
                    placeholder={notAUsertextFields[inputField].name}
                    onChange = {(event) => this.props.handleNotAuserInputFields(event, inputField)}
                    maxlength = {notAUsertextFields[inputField].maxlength} 
                  >
                  </textarea>
                </div>
              )
            }


          })
        }
          <div className="text-center">
          {
            submitNotAUserDetailsLoaderStatus ? <CircularProgress style = {{color : "#69bce6"}} /> :
            <div>
              <button
                type="button"
                className="btn btn-info btn-round btn-lg btn-block btn-login"
                onClick = {() => this.props.submitNotAUserDetails(notAUsertextFields, this.props)}
                disabled = {(notAUsertextFields['name'].value === '' || notAUsertextFields['email'].value === '' || notAUsertextFields['phone'].value === '' || (notAUsertextFields['email'].value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i) === null)) ? true : false}
                style={{cursor:(notAUsertextFields['name'].value === '' || notAUsertextFields['email'].value === '' || notAUsertextFields['phone'].value === '' || (notAUsertextFields['email'].value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i) === null)) ? 'not-allowed' : ''}}
              >
                Submit
              </button>
              <div className="pull-left">
                <h6>
                  <a href="javascript:void(0)" onClick={() => { this.props.handleType('password') }} className="link" style={{ cursor: "pointer" }} >
                    Forgot Password
                      </a>
                </h6>
              </div>
              <div className="pull-right">
                <h6>
                  <a onClick={() => { this.props.handleType('signin') }} className="link" style={{ cursor: "pointer" }}>
                    Already User ?
                      </a>
                </h6>
              </div>
            </div>
          }

          </div>
        </div>
      </form>
    );
  }
}

const mapStateToProps = state => {
  return{
    loginState : state.login
  }
}

const mapDispatchToProps = dispatch =>{
  return{
    handleNotAuserInputFields : (event, inputFieldId) => {
      return dispatch(handleNotAuserInputFields(event, inputFieldId))
    },
    // notAUserSubmit : (notAUsertextFields) => {
    //   return dispatch(notAUserSubmit(notAUsertextFields))
    // },
    submitNotAUserDetails : (notAUsertextFields, props) => {
      return dispatch(submitNotAUserDetails(notAUsertextFields, props))
    }
  }
}

export default connect(mapStateToProps,mapDispatchToProps) (withRouter(NotAUser));
