import{
    SET_EXTERNAL_DRIVER_INPUT_FIELD,
    SET_DEFAULT_VALUE_TO_SEARCH_EXTERNAL_DRIVER,
    SET_ADD_ED_RECORD_INPUT_HANDLER,
    SET_SUBMITED_ED_RECORD,
    SET_EXTERNAL_DRIVER_RECORDS,
    SET_ES_LIST,
    SET_ES_TYPES,
    SET_ED_RECORD_OPEN_DIALOG_STATUS,
    SET_EDIT_ED_RECORD_CLOSE_DIALOG,
    SET_UPDATE_ED_RECORD,
    SET_ED_RECORD_DELETE,
    OPEN_EXTERNAL_DRIVER_RECORD,
    SET_DEFAULT_VALUE_TO_ADD_ED_FORM
} from "./ActionType";
import axios from "../axios";
import {apiUrl} from "../config";
import { toast } from 'react-toastify';
import {
    convertToBase64, getFileExtension, formatDate, getActulaURL, downloadFile
}from "../utils/Helper";

export const initializeAddEDForm = () => {
    return (dispatch) => {
        return dispatch(setDefaultValueToAddEDForm())
    }
}
export const setDefaultValueToAddEDForm = () => {
    return{
        type : SET_DEFAULT_VALUE_TO_ADD_ED_FORM
    }
}
export const openExternalDriverRecord = () => {
    return{
        type : OPEN_EXTERNAL_DRIVER_RECORD
    }
}
export const onSubmitEDRecord = (addEDRecordForm, driverId, props, url) => {
    return (dispatch) => {
        let addEDRecordErrorStatus = false;
        let showEDRecordSubmitLoaderStatus = false;
        Object.keys(addEDRecordForm).map(inputFieldId => {
            if(addEDRecordForm[inputFieldId].value === '' || addEDRecordForm[inputFieldId].value === null){
                addEDRecordErrorStatus = true;
            }
        })
        dispatch(setSubmitedEDRecord(showEDRecordSubmitLoaderStatus));
        if(!addEDRecordErrorStatus){
            showEDRecordSubmitLoaderStatus = true;
            let reqPath = "/externalsystem/"+addEDRecordForm['es_type'].value+"/drivers/"+localStorage.getItem('_driver_id');
            let reqParams = {};
    
            reqParams['external_system'] = addEDRecordForm['es_type'].value;
            dispatch(setSubmitedEDRecord(showEDRecordSubmitLoaderStatus))
            return axios.post(reqPath, reqParams)
            .then(response => {
                showEDRecordSubmitLoaderStatus = false;
                toast.success("External Driver Record Created Successfully", {
                    containerId: 'toastMsg',
                    onClose : () => props.history.push(getActulaURL(url))
                });
                dispatch(setSubmitedEDRecord())
            })
            .catch(error => {
                showEDRecordSubmitLoaderStatus = false;
                toast.error(error.response.data.message, {
                    containerId: 'toastMsg'
                });
                dispatch(setSubmitedEDRecord(showEDRecordSubmitLoaderStatus))
            })
        }
    }
}
export const setSubmitedEDRecord = (showEDRecordSubmitLoaderStatus) => {
    return{
        type : SET_SUBMITED_ED_RECORD,
        showEDRecordSubmitLoaderStatus : showEDRecordSubmitLoaderStatus
    }
}
export const setUpdateEDRecord = (showEDRecordUpdateLoaderStatus, updatedData) => {
    return{
        type : SET_UPDATE_ED_RECORD,
        showEDRecordUpdateLoaderStatus : showEDRecordUpdateLoaderStatus,
        updatedData : updatedData
    }
}
export const handleDeleteEDRecord = (edRecordData, edInformations) => {
    return (dispatch) => {
        let reqPath = "/externalsystem/"+edRecordData.external_id+"/drivers/"+edRecordData.driver_id;
        dispatch(setEDRecordDelete(edInformations))
        return axios.delete(reqPath)
        .then(response => {
            const deletedEDRecordIndex = edInformations.findIndex(d => {
                return d.recordId === edRecordData.recordId;
            });
            if (deletedEDRecordIndex >= 0) {
                edInformations.splice(deletedEDRecordIndex, 1);
            }

            toast.success("External system has been removed", {
                containerId: 'toastMsg'
            });
            dispatch(setEDRecordDelete(edInformations))
        })
        .catch( error => {
            toast.error(error.message, {containerId: 'toastMsg'});
            dispatch(setEDRecordDelete(edInformations))
        })
    }
}
export const setEDRecordDelete = (edInformations) => {
    return{
        type : SET_ED_RECORD_DELETE,
        edInformations : edInformations
    }
}
export const handleEditEDRecordCloseDialog = () => {
    return (dispatch) => {
        return dispatch(setEditEDRecordCloseDialog())
    }
}
export const setEditEDRecordCloseDialog = () => {
    return{
        type : SET_EDIT_ED_RECORD_CLOSE_DIALOG
    }
}
export const handleEditEDRecordOpenDialog = (edRecordData) => {
    return (dispatch) => {
        return dispatch(setEDRecordOpenDialogStatus(edRecordData))
    }
}
export const setEDRecordOpenDialogStatus = (edRecordData) => {
    return{
        type : SET_ED_RECORD_OPEN_DIALOG_STATUS,
        edRecordData : edRecordData
    }
}
export const getESTypes = () => {
    return dispatch => {
        let reqPath = "/externalsystems";
        let lookUp = {};
        return axios.get(reqPath)
        .then(response => {
            for(let i = 0; i < response.data.data.length; i++){
                lookUp[response.data.data[i]._id] = response.data.data[i].name;
            }
            dispatch(setESTypes(lookUp));
        })
        .catch(error => {
            dispatch(setESTypes(lookUp));
        })
    }
}
export const setESTypes = (lookUp) => {
    return{
        type : SET_ES_TYPES,
        lookUp : lookUp
    }
}
export const getESList = () => {
    return(dispatch) => {
        let reqPath = "/externalsystems";

        let esTypesMenuItems = {};
        let esType = {};
        esType['name'] = "Select External System";
        esType['value'] = "";
        esTypesMenuItems['select_es_type'] = esType;
        return axios.get(reqPath)
        .then(response => {
            for(let i = 0; i < response.data.data.length; i++){
                esType = {};
                esType['value'] = response.data.data[i]._id;
                esType['name'] = response.data.data[i].name;
                esTypesMenuItems[response.data.data[i]._id] = esType;
            } 
            dispatch(setESList(esTypesMenuItems))
        })
        .catch(error => {
            dispatch(setESList(esTypesMenuItems))
        })
    }
}
export const setESList = (esTypesMenuItems) => {
    return{
        type : SET_ES_LIST,
        esTypesMenuItems : esTypesMenuItems,
    }
}
export const getExternalDriver = () => {
    return (dispatch) => {
        let showEDRecordListLoaderStatus = true;
        let edInformations = [];
        let singleEDRecord = {};
        let reqPath = "/externalsystems/drivers/"+localStorage.getItem('_driver_id');
        dispatch(setExternalDrivers(edInformations, showEDRecordListLoaderStatus))
        return axios.get(reqPath)
        .then(response => {
            showEDRecordListLoaderStatus = false;
            for(let i = 0; i < response.data.data.length; i++){
                singleEDRecord = {};
                singleEDRecord['es_type'] = response.data.data[i].external_id.name;
                singleEDRecord['driver_id'] = response.data.data[i].driver_id;
                singleEDRecord['external_id'] = response.data.data[i].external_id._id;
                singleEDRecord['recordId'] = response.data.data[i].external_id._id;
                edInformations.push(singleEDRecord);
            }
            dispatch(setExternalDrivers(edInformations, showEDRecordListLoaderStatus))
        })
        .catch( error => {
            showEDRecordListLoaderStatus = false;
            dispatch(setExternalDrivers(edInformations, showEDRecordListLoaderStatus))
        })
    }
}
export const setExternalDrivers = (edInformations, showEDRecordListLoaderStatus) => {
    return{
        type : SET_EXTERNAL_DRIVER_RECORDS,
        edInformations : edInformations,
        showEDRecordListLoaderStatus : showEDRecordListLoaderStatus
    }
}
export const addEDRecordFormInputHandler = (inputFieldId, event) => {
    let textFieldValue = event.target.value;
    return (dispatch) => {
        dispatch(setAddEDRecordInputHandler(inputFieldId, textFieldValue, ""))
    }
}
export const setAddEDRecordInputHandler = (inputFieldId, textFieldValue) => {
        return{
            type : SET_ADD_ED_RECORD_INPUT_HANDLER,
            inputFieldId : inputFieldId,
            data : textFieldValue
        }
}

export const initializeSearchExternalDriver = () =>{
    return (dispatch) => {
        return dispatch(setDefaultValueToSearchExternalDriver())
    }
}
export const setDefaultValueToSearchExternalDriver = () => {
    return{
        type : SET_DEFAULT_VALUE_TO_SEARCH_EXTERNAL_DRIVER
    }
}

