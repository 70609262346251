import axios from '../axios';
import {
    MANAGE_LOGIN_STATUS,
    LOGIN_TEXTFIELD_CHANGE,
    ENABLE_DISABLE_SIGNIN,
    SET_CHANGE_PASSWORD_INPUT_FIELD,
    POST_NOT_A_USER,
    POST_SUBMIT_NOT_A_USER_DETAILS,
    POST_HANDLE_NOT_A_USER_INPUT_FIELDS
} from '../actions/ActionType';
import { toast } from 'react-toastify';
import { roles } from '../constants/roles';


export const handleNotAuserInputFields = (event, inputFieldId) => {
    return (dispatch) => {
        if(inputFieldId === 'phone'){
            event.target.value = (event.target.value).replace(/[^0-9]/g, '');
        }
        return dispatch(postHandleNotAUserInputFields(event, inputFieldId))
    }
}
export const postHandleNotAUserInputFields = (event, inputFieldId) => {
    return{
        type : POST_HANDLE_NOT_A_USER_INPUT_FIELDS,
        inputFieldId : inputFieldId,
        data : event.target.value
    }
}
export const submitNotAUserDetails = (notAUsertextFields, props) => {
    return (dispatch) => {
        let reqUrl = "/vistors";
        let reqParams = {};
        let submitNotAUserDetailsLoaderStatus = true;
        reqParams['visitorname'] = notAUsertextFields['name'].value;
        reqParams['visitoremail'] = notAUsertextFields['email'].value;
        reqParams['visitormessage'] = notAUsertextFields['message'].value;
        reqParams['visitorphone'] = notAUsertextFields['phone'].value;
        dispatch(postSubmitNotAUserDetails(submitNotAUserDetailsLoaderStatus))
        return axios.post(reqUrl, reqParams)
        .then(response => {
            submitNotAUserDetailsLoaderStatus = false;
            toast.success("Thanks for your interest in business, Our tech support team will contact you within 7 days", {
                containerId: 'toastMsg',
                onClose : () => window.location.href = "/"
                }
            );
            dispatch(postSubmitNotAUserDetails(submitNotAUserDetailsLoaderStatus))
        })
        .catch(error => {
            submitNotAUserDetailsLoaderStatus = false;
            toast.error(error.response.data.message, {
                containerId: 'toastMsg',
                }
            );
            dispatch(postSubmitNotAUserDetails(submitNotAUserDetailsLoaderStatus))
        })
    }
}
export const postSubmitNotAUserDetails = (submitNotAUserDetailsLoaderStatus) => {
    return{
        type : POST_SUBMIT_NOT_A_USER_DETAILS,
        submitNotAUserDetailsLoaderStatus : submitNotAUserDetailsLoaderStatus
    }
}
export const notAUserSubmit = () => {
    return (dispatch) => {
        toast.success("Thanks for your interest in business, Our tech support team will contact you within 7 days", {
            containerId: 'toastMsg',
            }
        );
        return dispatch(postNotAUser())
    }
}
export const postNotAUser = () => {
    return{
        type : POST_NOT_A_USER
    }
}
export const inputHandlerChangePassword = (inputField, event) => {
    return (dispatch) => {
        return dispatch(setChangePasswordInputField(inputField, event))
    }
}

export const setChangePasswordInputField = (inputField, event) => {
    return{
        type : SET_CHANGE_PASSWORD_INPUT_FIELD,
        inputField : inputField,
        data : event.target.value
    }
}

/**************************************** LOGIN *********************************************************** */
export const login = (loginControls, props) => {
    let loginStatus = true;
    let showLoaderStatus = true;
    return (dispatch) => {
        let email = loginControls.email.value;
        let password = loginControls.password.value;
        let credentials = email + ':' + password;
        var encodedData = window.btoa(credentials);
        dispatch(setLoginStatus(loginStatus, showLoaderStatus));
        let headers = {
            "Authorization": "Basic" + " " + encodedData
        }
        return axios.post('/authenticate', {}, { headers: headers })
            .then(response => {
                let redirectUrl = '';
                const isFirstLogin = response.data.data.userfirstlogin;
                loginStatus = true;
                localStorage.setItem('token', response.headers['x-token']);
                localStorage.setItem('role', response.data.data.role);
                localStorage.setItem('Platform-User', response.data.data.user_id);
                localStorage.setItem('userfirstlogin',response.data.data.userfirstlogin);

                if (!isFirstLogin){
                    switch(response.data.data.role){
                        case roles.SG_ADMIN :
                            redirectUrl = '/sgadminportal/partners';
                            localStorage.setItem('lastSelectedBusinessId', response.data.data.business_object_id);
                            break;
                        case roles.DISTRIBUTOR_ADMIN :
                        case roles.DISTRIBUTOR_EDITOR :
                            localStorage.setItem('partner_id', response.data.data.partner_id);
                            localStorage.setItem('distributor_id', response.data.data.distributor_id);
                            redirectUrl = "/distributorportal/"+localStorage.getItem('distributor_id')+"/businesses";
                            break;       
                        case roles.DISTRIBUTOR_REPORTER :
                            localStorage.setItem('distributor_id', response.data.data.distributor_id);
                            redirectUrl = "/distributorportal/"+localStorage.getItem('distributor_id')+"/profile";
                            break;              
                        case roles.BUSINESS_ADMIN :
                        case roles.BUSINESS_EDITOR :
                        case roles.DEPOT_MANAGER :
                            localStorage.setItem('business_id', response.data.data.business_id);
                            localStorage.setItem('lastSelectedBusinessId', response.data.data.business_object_id);
                            localStorage.setItem('distributor_id', response.data.data.distributor_id.distributor_id);
                            redirectUrl = '/businessportal/'+localStorage.getItem('business_id')+'/drivers';
                            break;
                        case roles.BUSINESS_REPORTER :
                            localStorage.setItem('business_id', response.data.data.business_id);
                            localStorage.setItem('distributor_id', response.data.data.distributor_id.distributor_id);
                            redirectUrl = '/businessportal/'+localStorage.getItem('business_id')+'/profile';
                            break;
                        case roles.PARTNER_ADMIN:
                        case roles.PARTNER_EDITOR:
                        case roles.PARTNER_REPORTER: //TODO need to define what menu items these diff roles have
                            localStorage.setItem('partner_id', response.data.data.partner_id);
                            redirectUrl = '/partnerportal/'+ localStorage.getItem('partner_id') + '/distributors';
                            break;
                        default:
                            redirectUrl="/";
                    }
                    window.location.href = redirectUrl;
                    dispatch(setLoginStatus(loginStatus, showLoaderStatus))
                } else {
                    window.location.href = '/change-password';
                }
            })
            .catch(error => {
                loginStatus = false;
                showLoaderStatus = false;
                /*this error.response.data sometime null, therefore going to use message */
                var message = !error.response? "Error Connecting to the Server, please contact support":  error.response.data.message;
                toast.error(message, {containerId: 'toastMsg'});
                dispatch(setLoginStatus(loginStatus, showLoaderStatus))
            })         
    }  
}
export const handleForgotPassword = (loginControls, props) => {
    let loginStatus = true;
    let showLoaderStatus = true;
    return (dispatch) => {
        let email = loginControls.email.value;
        dispatch(setLoginStatus(loginStatus, showLoaderStatus));
        return axios.get('/users/'+email+'/forgotpwd')
            .then(response => {
                showLoaderStatus = false;                
                dispatch(setLoginStatus(loginStatus, showLoaderStatus))
                toast.success('Password Reset Link is sent to your email Id', {containerId: 'toastMsg'});
                setTimeout(() => {
                    window.location.href='/login'; 
                }, 5000);
                

            })
            .catch(error => {
                loginStatus = false;
                showLoaderStatus = false;
                toast.error(error.response.data.message, {containerId: 'toastMsg'});
                dispatch(setLoginStatus(loginStatus, showLoaderStatus))
            })         
    }  
}
export const handleResetPassword = (loginControls, props) => {
    let loginStatus = true;
    let showLoaderStatus = true;
    return (dispatch) => {
        let password = loginControls.password.value;
        dispatch(setLoginStatus(loginStatus, showLoaderStatus));
        return axios.post('/users/'+((window.location.pathname).split('/'))[2]+'/resetpwd/'+((window.location.pathname).split('/'))[3],{newpassword:password})
            .then(response => {
                showLoaderStatus = false;                
                dispatch(setLoginStatus(loginStatus, showLoaderStatus))
                toast.success('Password Successfully Reset', {containerId: 'toastMsg'})
                window.location.href='/login';
            })
            .catch(error => {
                loginStatus = false;
                showLoaderStatus = false;
                toast.error(error.response.data.message, {containerId: 'toastMsg'});
                dispatch(setLoginStatus(loginStatus, showLoaderStatus))
            })         
    }  
}
export const setLoginStatus = (loginStatus, showLoaderStatus) => {
    return {
        type: MANAGE_LOGIN_STATUS,
        data: loginStatus,
        showLoaderStatus : showLoaderStatus
    }
}
/****************************************** ENDS ****************************** */

/********************************** DISABLE ENABLE SIGIN BUTTON ON FIELD VALUES ***************************** */
export const handleTextFieldChange = (textField, event, loginTextField) => {

    return (dispatch) => {
        dispatch(setTextFieldData(textField, event));
    }
}
/****************************************** ENDS *************************************** */

export const setTextFieldData = (textField, event) => {
    return {
        type: LOGIN_TEXTFIELD_CHANGE,
        textFieldId: textField,
        textFieldData: event.target.value
    }
}

export const enableDisableSignin = (value) => {
    return {
        type: ENABLE_DISABLE_SIGNIN,
        data: value
    }
}
