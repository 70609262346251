import {
    SUBMIT_BFM_INFO,
    SET_BFM_FILE_SELECTED,
    SET_DEFAULT_STATE_BFM
  } from "../actions/ActionType";
  const initialState = {
    uploadBFMForm : {
      driver_type : {
        elementType : 'select',
        elementConfig : {
            type : 'number',
            label : 'Select Driver Type'
        },
        value : '',
        errorStatus : false,
        errorMessage : "Select Driver Type",
        menuItems : {
            'select_type' : {
                name : "Select Driver Type",
                value : ''
            },
            'driver_type' : {
                name : "Solo",
                value : "solo"
            },
            'vehicle_category' : {
              name : "Two UP",
              value : "two_up"
          }
        }
    },
    vehicle_category : {
      elementType : 'select',
      elementConfig : {
          type : 'number',
          label : 'Select Vehicle Category'
      },
      value : '',
      errorStatus : false,
      errorMessage : "Select Vehicle Category",
      menuItems : {
          'select_type' : {
              name : "Select Vehicle Category",
              value : ''
          },
          'driver_type' : {
              name : "Heavy Vehicle",
              value : "heavy_vehicle"
          },
          'vehicle_category' : {
            name : "Bus",
            value : "bus"
        }
      }
  },
      upload_bfm : {
          elementType : 'upload_json',
          elementConfig : {
              type : 'text',
              label : 'Upload BFM Json'
          },
          value : '',
          errorStatus : false,
          errorMessage : "Upload BFM Json"
      },
  },
  bfmJSON : {},
  bfmLoaderStatus : false
  };

  const setDefaultStateBFM = (state, action) => {
    Object.keys(state.uploadBFMForm).map((formField) => {
        state.uploadBFMForm[formField].value = '';
        state.uploadBFMForm[formField].errorStatus = false;
    })
    return state;
  }
  const submitBFMInfo = (state, action) => {
    Object.keys(state.uploadBFMForm).map((formField) => {
        if(state.uploadBFMForm[formField].value === ''){
            state.uploadBFMForm[formField].errorStatus = true;
        }else{
            state.uploadBFMForm[formField].errorStatus = false;
        }
    })
    state.bfmLoaderStatus = action.bfmLoaderStatus;
    return state;
  }

  const setBFMFileSelected = (state, action) => {
    if(action.data === ''){
        state.uploadBFMForm[action.formElementId].errorStatus = true;
    }else{
        state.uploadBFMForm[action.formElementId].errorStatus = false;
    }
    if(action.formElementId === 'upload_bfm'){
        state.bfmJSON = action.bfmJSON;
    }
    state.uploadBFMForm[action.formElementId].value = action.data;
    return state;
  }
  const bfmReducer = (state = initialState, action) => {
    let localState = Object.assign({}, state);
    switch (action.type) {
        case SET_DEFAULT_STATE_BFM : return setDefaultStateBFM(localState, action);
        case SUBMIT_BFM_INFO : return submitBFMInfo(localState, action);
        case SET_BFM_FILE_SELECTED : return setBFMFileSelected(localState, action);
        default:
            return localState;
    }
  };
  export default bfmReducer;