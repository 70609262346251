import { GET_PARTNERS_FAIL, GET_PARTNERS_SUCCESS, GET_PARTNERS, SAVE_PARTNER_FAIL, SAVE_PARTNER_SUCCESS, SAVE_PARTNER } from "../actions/ActionType";

const initialState = {
  partners: [],
  apiLoading: false
};



export const partnerReducer = (state = initialState, action) => {
  switch (action.type) {

    case GET_PARTNERS_SUCCESS:
      return {
        ...state,
        partners: action.partners,
        apiLoading: false
      };

    case GET_PARTNERS_FAIL:
      return {
        ...state,
        partners: [],
        apiLoading: false
      };


    case GET_PARTNERS:
      return {
        ...state,
        apiLoading: true
      };


    case SAVE_PARTNER:
      return {
        ...state,
        apiLoading: true
      };
      

    case SAVE_PARTNER_FAIL:
    case SAVE_PARTNER_SUCCESS:
      return {
        ...state,
        apiLoading: false
      };


    default:
      return state;

  }
}

