import {
    SEARCH_DRIVER_TEXTFIELD_CHANGE,
    MANGE_LINK_DRIVER_DIALOG,
    MANAGE_LINK_DRIVER_DIALOG_CLOSE,
    MANAGE_EDIT_DRIVER_DIALOG_CLOSE,
    SEARCH_DRIVER,
    MANAGE_EDIT_DRIVER_OPEN_DIALOG,
    SUBMIT_DRIVER_INFO,
    DRIVER_SET_SEARCH_FOUND_STATUS_TO_NULL,
    SET_ALL_DRIVER_LIST,
    SET_ALL_TEXTFIELD_ENABLE,
    LINK_DRIVER_TO_BUSINESS,
    SET_DEFAULT_VALUE_TO_ADD_DRIVER_COMPONENT,
    SET_INPUTHANDLER_UPLOAD_DRIVER,
    POST_DRIVER_UPLOADED_DATA,
    SET_DEFAULT_VALUE_TO_SEARCH_DRIVER_COMPONENT,
    SET_DEFAULT_VALUE_TO_DRIVER_BULK_UPLOAD_FORM,
    SET_ADD_DRIVER_INPUT_FIELD,
    SET_INPUT_DOB,
    SET_FATIGUE_TYPE_CHECKBOXES,
    SET_DRIVER_TYPE_CHECKBOXES,
    POST_HANDLE_ADMIN_CHECKBOXES,
    SET_UPDATED_DRIVER_DATA,
    POST_UNLINK_DRIVER,
    POST_CLEAR_ALL_SEARCH_DRIVER_FIELD,
    POST_HANDLE_LICENSING_MODULE_CHECKBOXES,
    POST_HANDLE_CONTRACTOR_CHECKBOXES,
    TOGGLE_VIEW_DEPORT_DIALOG,
    TOGGLE_ADD_DEPORT_DIALOG,
    SET_DEPOT_INPUT_FIELD,
    SET_DEPOT_MAP_FIELD, SEARCH_DRIVER_DOB_CHANGE
} from './ActionType';
import { toast } from 'react-toastify';
import {
    getActulaURL
} from "../utils/Helper";

import axios from "../axios";
import { convertCSVToJSON } from "./Common";
import { formatDate } from "../utils/Helper";

const moment = require('moment');

export const handleLicensingModuleCheckboxes = (formElementId) => {
    return (dispatch) => {
        return dispatch(postHandleLicensingModuleCheckboxes(formElementId))
    }
}
export const postHandleLicensingModuleCheckboxes = (formElementId) => {
    return{
        type : POST_HANDLE_LICENSING_MODULE_CHECKBOXES,
        formElementId : formElementId
    }
}
export const clearAllSearchDriverField = () => {
    return (dispatch) => {
        return dispatch(postClearAllSearchDriverField())
    }
}
export const postClearAllSearchDriverField = () => {
    return{
        type : POST_CLEAR_ALL_SEARCH_DRIVER_FIELD
    }
}
export const unlinkDriver = (driverIdForEdit,props, url, driverInformations) => {
    return (dispatch) => {
        let reqPath = "/businesses/"+localStorage.getItem('business_id')+"/drivers/"+driverIdForEdit;
        let deleteDriverLoaderStatus = true;
        dispatch(postUnlinkDriver(deleteDriverLoaderStatus))
        return axios.delete(reqPath)
        .then(response => {
            deleteDriverLoaderStatus = false;
            const deletedDriverIndex = driverInformations.findIndex(d => {
                return d.id === driverIdForEdit;
            });
            driverInformations.splice(deletedDriverIndex, 1);
            toast.success("Driver Delinked Successfully", {
                containerId: 'toastMsg'
            });
            dispatch(postUnlinkDriver(deleteDriverLoaderStatus))
        })
        .catch(error => {
            deleteDriverLoaderStatus = false;
            toast.error(error.response.data.message, {
                containerId: 'toastMsg',
            });
            dispatch(postUnlinkDriver(deleteDriverLoaderStatus))
        })
    }
}
export const postUnlinkDriver = (deleteDriverLoaderStatus) => {
    return{
        type : POST_UNLINK_DRIVER,
        deleteDriverLoaderStatus : deleteDriverLoaderStatus
    }
}
export const onUpdateDriver = (addDriverFormFields, driverTypesCheckBoxes, fatigueTypesCheckboxes,licensingModuleCheckBoxes, adminCheckBoxes, contractorCheckBoxes ,driverIdForEdit, driverInformations) => {
    return (dispatch) => {
        let driverUpdateLoaderStatus = false;
        let openEditDriverDialogStatus = true;
        let driverFieldsValidationStatus = true;
       
        Object.keys(addDriverFormFields).map((textField) => {
            const optionalFields = ['integrationId', 'driver_other_name'];
            if(!optionalFields.includes(textField)){
                if(addDriverFormFields[textField].value  == ''){
                    driverFieldsValidationStatus = false;
                }
            }
        })            

        dispatch(setUpdatedDriverData(driverUpdateLoaderStatus, openEditDriverDialogStatus, driverInformations))
        if(driverFieldsValidationStatus){
            let licensingModule = {};
            licensingModule['EWD'] = licensingModuleCheckBoxes['ewd'].elementConfig.checked;
            // licensingModule['BUSINESSFORMS'] = licensingModuleCheckBoxes['business_forms'].elementConfig.checked;
            driverUpdateLoaderStatus = true;
            let updateDriverParams = {};
            updateDriverParams["DriverFirstName"] = addDriverFormFields['driver_first_name'].value;
            updateDriverParams["DriverLastName"] = addDriverFormFields['driver_last_name'].value;
            updateDriverParams["DriverOtherName"] = addDriverFormFields['driver_other_name'].value;
            updateDriverParams["DriverDob"] = formatDate(addDriverFormFields['dob'].value);
            updateDriverParams["DriverEmail"] = addDriverFormFields['email'].value;
            updateDriverParams["LicenseNumber"] =  addDriverFormFields['license_number'].value;
            updateDriverParams["LicenseState"] = addDriverFormFields['state'].value;
            updateDriverParams["LicenseCountry"] = addDriverFormFields['country'].value;
            updateDriverParams["integrationId"] = addDriverFormFields['integrationId'].value;

            updateDriverParams["LightRidged"] = driverTypesCheckBoxes['light_ridged'].elementConfig.checked;
            updateDriverParams["MediumRidged"] = driverTypesCheckBoxes['medium_ridged'].elementConfig.checked;
            updateDriverParams["HeavyRidged"] = driverTypesCheckBoxes['heavy_ridged'].elementConfig.checked;
            updateDriverParams["HeavyCombination"] = driverTypesCheckBoxes['heavy_combination'].elementConfig.checked;
            updateDriverParams["MultiCombination"] = driverTypesCheckBoxes['multi_combination'].elementConfig.checked;

            updateDriverParams["Car"] = driverTypesCheckBoxes['car'].elementConfig.checked;
            updateDriverParams["Motorcycle"] = driverTypesCheckBoxes['motorcycle'].elementConfig.checked;
            updateDriverParams["Marine"] = driverTypesCheckBoxes['marine'].elementConfig.checked;
            updateDriverParams["baseLocation"] = addDriverFormFields['baseLocation'].value;
            updateDriverParams["recordKeeper"] = addDriverFormFields['recordKeeper'].value;
            updateDriverParams["homeBaseTimeZone"] = addDriverFormFields['homeBaseTimeZone'].value;
            updateDriverParams["bfms"] = fatigueTypesCheckboxes['bfms'].value;
            updateDriverParams["afms"] = fatigueTypesCheckboxes['afms'].value;

            if(localStorage.getItem('role') == '1'){
                updateDriverParams["IsNHVRTesting"] = adminCheckBoxes['driver_is_nhvr_testing'].elementConfig.checked;
            }

            updateDriverParams["licenseModules"] = licensingModule;
            updateDriverParams["driver_is_contractor"] = contractorCheckBoxes['driver_is_contractor'].elementConfig.checked;



            dispatch(setUpdatedDriverData(driverUpdateLoaderStatus, openEditDriverDialogStatus, driverInformations));
            let reqPath = '/businesses/' + localStorage.getItem('business_id') + "/drivers/"+ driverIdForEdit;
            return axios.put(reqPath, updateDriverParams)
            .then(response => {
                driverUpdateLoaderStatus = false;
                openEditDriverDialogStatus = false;              
                toast.success("Driver Updated Successfully", {
                    containerId: 'toastMsg',
                });

                const updatedDriverIndex = driverInformations.findIndex(d => {
                    return d.id === response.data.data.driverObject.driver_id;
                });
                driverInformations[updatedDriverIndex].driver_dob = response.data.data.driverObject.driver_dob;
                driverInformations[updatedDriverIndex].driver_email = response.data.data.driverObject.driver_email;
                driverInformations[updatedDriverIndex].driver_country = response.data.data.driverObject.license_country;
                driverInformations[updatedDriverIndex].driver_firstname = response.data.data.driverObject.driver_firstname;
                driverInformations[updatedDriverIndex].driver_id = response.data.data.driverObject.id;
                driverInformations[updatedDriverIndex].driver_is_car = response.data.data.driverObject.driver_is_car;
                driverInformations[updatedDriverIndex].driver_is_heavycombination = response.data.data.driverObject.driver_is_meduimrigid;
                driverInformations[updatedDriverIndex].driver_is_heavyrigid = response.data.data.driverObject.driver_is_heavyrigid;
                driverInformations[updatedDriverIndex].driver_is_lightrigid = response.data.data.driverObject.driver_is_lightrigid;
                driverInformations[updatedDriverIndex].driver_is_marine = response.data.data.driverObject.driver_is_marine;
                driverInformations[updatedDriverIndex].driver_is_meduimrigid = response.data.data.driverObject.driver_is_meduimrigid;
                driverInformations[updatedDriverIndex].driver_is_motorcycle = response.data.data.driverObject.driver_is_motorcycle;
                driverInformations[updatedDriverIndex].driver_is_multicombination = response.data.data.driverObject.driver_is_multicombination;
                driverInformations[updatedDriverIndex].driver_lastname = response.data.data.driverObject.driver_lastname;
                driverInformations[updatedDriverIndex].driver_other_name = response.data.data.driverObject.driver_othername;
                driverInformations[updatedDriverIndex].driver_license = response.data.data.driverObject.license_number;
                driverInformations[updatedDriverIndex].driver_state = response.data.data.driverObject.license_state;
                driverInformations[updatedDriverIndex].integrationId = response.data.data.driverObject.integrationId;
                driverInformations[updatedDriverIndex].driver_is_nhvr_testing = response.data.data.driverObject.driver_is_nhvr_testing;  
                driverInformations[updatedDriverIndex].driver_is_contractor = response.data.data.fatigueObject.driver_is_contractor;      
                driverInformations[updatedDriverIndex].name = response.data.data.driverObject.driver_firstname + " " + response.data.data.driverObject.driver_lastname;
                driverInformations[updatedDriverIndex].recordKeeper = response.data.data.fatigueObject.recordKeeper;
                driverInformations[updatedDriverIndex].baseLocation = response.data.data.fatigueObject.baseLocation;
                driverInformations[updatedDriverIndex].bfms = response.data.data.fatigueObject.bfms;
                driverInformations[updatedDriverIndex].afms = response.data.data.fatigueObject.afms;
                driverInformations[updatedDriverIndex].homeBaseTimeZone = response.data.data.fatigueObject.homeBaseTimeZone;

                dispatch(setUpdatedDriverData(driverUpdateLoaderStatus, openEditDriverDialogStatus, driverInformations))
              

            })
            .catch(error => {
                driverUpdateLoaderStatus = false;
                toast.error(error.toString(), {containerId: 'toastMsg'});
                dispatch(setUpdatedDriverData(driverUpdateLoaderStatus, openEditDriverDialogStatus, driverInformations))
            })
        }
    }
}

export const setUpdatedDriverData = (driverUpdateLoaderStatus, openEditDriverDialogStatus, driverInformations) => {
    return{
        type : SET_UPDATED_DRIVER_DATA,
        driverUpdateLoaderStatus : driverUpdateLoaderStatus,
        openEditDriverDialogStatus : openEditDriverDialogStatus,
        driverInformations : driverInformations
    }
}

export const handleAdminCheckboxes = (formElementId) => {
    return (dispatch) => {
        return dispatch(postHandleAdminCheckboxes(formElementId))
    }
}
export const handleContractCheckboxes = (formElementId) => {
    return (dispatch) => {
        return dispatch(postHandlContractorCheckboxes(formElementId))
    }
}
export const postHandleAdminCheckboxes = (formElementId) => {
    return{
        type : POST_HANDLE_ADMIN_CHECKBOXES,
        formElementId : formElementId
    }
}
export const postHandlContractorCheckboxes = (formElementId) => {
    return{
        type : POST_HANDLE_CONTRACTOR_CHECKBOXES,
        formElementId : formElementId
    }
}
export const handleDriverTypeCheckboxes = (formElementId) => {
    return (dispatch) => {
        return dispatch(setDriverTypeCheckboxes(formElementId))
    }
}
export const setDriverTypeCheckboxes = (formElementId) => {
    return{
        type : SET_DRIVER_TYPE_CHECKBOXES,
        formElementId : formElementId
    }
}
export const handleFatigueTypeCheckboxes = (formElementId, event) => {
    return (dispatch) => {
        return dispatch(setFatigueTypeCheckBoxes(formElementId, event))
    }
}
export const setFatigueTypeCheckBoxes = (formElementId, event) => {
    return{
        type : SET_FATIGUE_TYPE_CHECKBOXES,
        data : event.target.value,
        formElementId : formElementId
    }
}
export const handleInputDOB = (formElementId, date) => {
    return (dispatch) => {
        return dispatch(setInputDOB(formElementId, date))
    }
}

export const handleSearchDOB = (formElementId, date) => {
    return (dispatch) => {
        return dispatch({
            type : SEARCH_DRIVER_DOB_CHANGE,
            formElementId : formElementId,
            date : date
        })
    }
}

export const setInputDOB = (formElementId, date) => {
    return{
        type : SET_INPUT_DOB,
        formElementId : formElementId,
        date : date
    }
}
export const handleAddDriverInputFieldChange = (formElementId, event) => {
    return (dispatch) => {
        return dispatch(setAddDriverInputField(formElementId, event))
    }
}
export const setAddDriverInputField = (formElementId, event) => {
    return{
        type : SET_ADD_DRIVER_INPUT_FIELD,
        data : event.target.value,
        formElementId : formElementId
    }
}
export const initializeDriverBulkUploadForm = () => {
    return (dispatch) => {
        return dispatch(setDefaultValueToDriverBulkUploadForm())
    }
}
export const setDefaultValueToDriverBulkUploadForm = () => {
    return{
        type : SET_DEFAULT_VALUE_TO_DRIVER_BULK_UPLOAD_FORM
    }
}
export const initializeSearchDriverComponent = () => {
    return (dispatch) => {
        return dispatch(setDefaultValueToSearchDriverComponent())
    }
}
export const setDefaultValueToSearchDriverComponent = () => {
    return{
        type : SET_DEFAULT_VALUE_TO_SEARCH_DRIVER_COMPONENT
    }
}
export const ondriverUploadSubmit = (bulkUploadedDrivers, uploadDriverForms) => {
    return (dispatch) => {
        let driverBulkUploadSubmitLoaderStatus = false;
        let driverUploadStatusList = [];
        let driverBulkUploadFormValidationStatus = true;
        let reqPath = "/businesses/"+localStorage.getItem('business_id')+"/drivers/bulk";
        Object.keys(uploadDriverForms).map(inputField => {
            if(uploadDriverForms[inputField].value === ''){
                driverBulkUploadFormValidationStatus = false;
            }
        })
        dispatch(postdriverUploadedData(driverUploadStatusList, driverBulkUploadSubmitLoaderStatus));
        if(driverBulkUploadFormValidationStatus){
            driverBulkUploadSubmitLoaderStatus = true;
            dispatch(postdriverUploadedData(driverUploadStatusList, driverBulkUploadSubmitLoaderStatus));
            return axios.post(reqPath, bulkUploadedDrivers)
            .then(response => {
                driverBulkUploadSubmitLoaderStatus = false;
                toast.success("Drivers Uploaded Successfully", {
                    containerId: 'toastMsg'
                });
                driverUploadStatusList = response.data.data
                dispatch(postdriverUploadedData(driverUploadStatusList, driverBulkUploadSubmitLoaderStatus));
            })
            .catch(error => {
                driverBulkUploadSubmitLoaderStatus = false;
                if(error.response.status == 403){
                    localStorage.clear();
                    window.location.href = "/";
                }else{
                    toast.error(error.response.data.message, {
                        containerId: 'toastMsg',
                    });
                }
                dispatch(postdriverUploadedData(driverUploadStatusList, driverBulkUploadSubmitLoaderStatus));
            })
        }
    }
}
export const postdriverUploadedData = (driverUploadStatusList, driverBulkUploadSubmitLoaderStatus) => {
    return {
        type : POST_DRIVER_UPLOADED_DATA,
        driverUploadStatusList : driverUploadStatusList,
        driverBulkUploadSubmitLoaderStatus : driverBulkUploadSubmitLoaderStatus
    }
}
export const inputHandlerUploadDriver = (formElementId, event) => {
    let bulkUploadedDrivers;
    let formData = event.target.value.replace(/^.*\\/, "");
    return (dispatch) => {
        convertCSVToJSON(event.target.files[0]).then(data =>{
            bulkUploadedDrivers = data;
            let licensingModule = {};
            for(let i = 0; i < bulkUploadedDrivers.length; i++){
                licensingModule = {};
                licensingModule['EWD'] = bulkUploadedDrivers[i].EWD;
                // licensingModule['BUSINESSFORMS'] = bulkUploadedDrivers[i].BUSINESSFORMS;
                delete bulkUploadedDrivers[i].EWD;
                // delete bulkUploadedDrivers[i].BUSINESSFORMS;
                bulkUploadedDrivers[i].licenseModules = licensingModule;
            }
            dispatch(setInputHandlerUploadDriver(formElementId, formData, bulkUploadedDrivers));
        })
        .catch(error => {
            toast.error(error, {
                containerId: 'toastMsg'
            });
            formData = '';
            dispatch(setInputHandlerUploadDriver(formElementId, formData, bulkUploadedDrivers));
        })
    }
}
export const setInputHandlerUploadDriver = (formElementId, formData, bulkUploadedDrivers) => {
    return{
        type : SET_INPUTHANDLER_UPLOAD_DRIVER,
        formElementId : formElementId,
        data : formData,
        bulkUploadedDrivers : bulkUploadedDrivers
    }
}
export const initializeAddDriverComponent = () => {
    return (dispatch) => {
        return dispatch(setDefaultValueToAddDriverComponent())
    }
}

export const setDefaultValueToAddDriverComponent = () => {
    return{
        type : SET_DEFAULT_VALUE_TO_ADD_DRIVER_COMPONENT
    }
}

export const linkToBusiness = (fatigueTypesCheckboxes,licensingModuleCheckBoxes,contractorCheckBoxes, props, activeDriverRecordForLink, url) => {
    return (dispatch) => {
        let reqPath = 'dist/'+localStorage.getItem('distributor_id')+'/businesses/' + localStorage.getItem('business_id') + "/drivers/" + activeDriverRecordForLink;
        let linkDriverReq = {};
        let driverLinkLoaderStatus = true;
        let licensingModule = {};
        licensingModule['EWD'] = licensingModuleCheckBoxes['ewd'].elementConfig.checked;
        // licensingModule['BUSINESSFORMS'] = licensingModuleCheckBoxes['business_forms'].elementConfig.checked;
        linkDriverReq['afms'] = fatigueTypesCheckboxes['afms'].elementConfig.value;
        linkDriverReq['bfms'] = fatigueTypesCheckboxes['bfms'].elementConfig.value;
        linkDriverReq['driver_is_contractor'] = contractorCheckBoxes['driver_is_contractor'].elementConfig.checked;
        linkDriverReq['licenseModules'] = licensingModule;
        dispatch(linkDriverToBusiness(driverLinkLoaderStatus));
        return axios.post(reqPath, linkDriverReq)
            .then((response) => {
                driverLinkLoaderStatus = false;
                toast.success("Driver is successfully linked to the business", {
                    containerId: 'toastMsg',
                    onClose : () => props.history.push(getActulaURL(url))
                });
                dispatch(linkDriverToBusiness(driverLinkLoaderStatus));
            })
            .catch(error => {
                driverLinkLoaderStatus = false;
                if(error.response.status == 403){
                    localStorage.clear();
                    window.location.href = "/";
                }else{
                    toast.error(error.response.data.message, {
                        containerId: 'toastMsg'
                    });
                }
                dispatch(linkDriverToBusiness(driverLinkLoaderStatus));
            })
    }
}

export const linkDriverToBusiness = (driverLinkLoaderStatus) =>{
    return{
        type : LINK_DRIVER_TO_BUSINESS,
        driverLinkLoaderStatus : driverLinkLoaderStatus
    }
}
export const makeAllTextFieldEnable = () => {
    return (dispatch) => {
        return dispatch(setAllTextFieldEnable())
    }
}

export const setAllTextFieldEnable = () => {
    return{
        type : SET_ALL_TEXTFIELD_ENABLE
    }
}
export const getDriverList = (businessId) => {
    let showListDriverLoaderStatus = true;
    return (dispatch) => {
        let reqPath = "/dist/"+localStorage.getItem('distributor_id')+"/businesses/" + localStorage.getItem('business_id') + "/drivers";
        let driver = {};
        let driverList = [];
        dispatch(setAllDriverList(driverList, showListDriverLoaderStatus));
        return axios.get(reqPath)
        .then(response => {
                showListDriverLoaderStatus = false;
                for(let i = 0; i < response.data.data.length; i++){
                    driver = {};
                    if(response.data.data[i].driver_id != null) {
                        driver["id"] = response.data.data[i].driver_id.driver_id;
                        driver["_id"] = response.data.data[i].driver_id._id;
                        if (response.data.data[i].linkedByBusinessDate && response.data.data[i].linkedByBusinessDate != null && response.data.data[i].linkingApprovedByDriverDate && response.data.data[i].linkingApprovedByDriverDate != null)
                            driver["linkStatus"] =  'LINKED (' + formatDate(response.data.data[i].linkedByBusinessDate) + ')';
                        else if (response.data.data[i].linkedByBusinessDate && response.data.data[i].linkedByBusinessDate != null &&
                                (!response.data.data[i].linkingApprovedByDriverDate || response.data.data[i].linkingApprovedByDriverDate == null) &&
                                (!response.data.data[i].linkingDeclinedByDriverDate || response.data.data[i].linkingDeclinedByDriverDate == null))
                            driver["linkStatus"] =  'PENDING';
                        else if (response.data.data[i].delinkedByBusinessDate && response.data.data[i].delinkedByBusinessDate != null) {
                            driver["linkage"] = 'DELINKED';
                            driver["linkStatus"] =  'DELINKED (' + formatDate(response.data.data[i].delinkedByBusinessDate) + ')';
                        } else if (response.data.data[i].linkingDeclinedByDriverDate && response.data.data[i].linkingDeclinedByDriverDate != null) {
                            driver["linkage"] = 'DRIVER DECLINED';
                            driver["linkStatus"] =  'DRIVER DECLINED (' + formatDate(response.data.data[i].linkingDeclinedByDriverDate) + ')';
                        }
                        driver["name"] = response.data.data[i].driver_id.driver_firstname + " " + response.data.data[i].driver_id.driver_lastname;
                        driver["license_number"] = response.data.data[i].driver_id.driver_license;
                        driver["license_country"] = response.data.data[i].driver_id.driver_country;
                        driver["license_state"] = response.data.data[i].driver_id.driver_state;
                        driver["driver_dob"] = response.data.data[i].driver_id.driver_dob;
                        driver["driver_email"] = response.data.data[i].driver_id.driver_email;
                        driver["driver_firstname"] = response.data.data[i].driver_id.driver_firstname;
                        driver["driver_lastname"] = response.data.data[i].driver_id.driver_lastname;
                        driver["driver_is_car"] = response.data.data[i].driver_id.driver_is_car;
                        driver["driver_is_heavyrigid"] = response.data.data[i].driver_id.driver_is_heavyrigid;
                        driver["driver_is_lightrigid"] = response.data.data[i].driver_id.driver_is_lightrigid;
                        driver["driver_is_marine"] = response.data.data[i].driver_id.driver_is_marine;
                        driver["driver_is_meduimrigid"] = response.data.data[i].driver_id.driver_is_meduimrigid;
                        driver["driver_is_motorcycle"] = response.data.data[i].driver_id.driver_is_motorcycle;
                        driver["driver_is_multicombination"] = response.data.data[i].driver_id.driver_is_multicombination;
                        driver["driver_is_heavycombination"] = response.data.data[i].driver_id.driver_is_heavycombination;
                        driver["driver_other_name"] = response.data.data[i].driver_id.driver_othername;
                        driver["driver_is_nhvr_testing"] = response.data.data[i].driver_id.driver_is_nhvr_testing;
                        driver["bfms"] = response.data.data[i].bfms;
                        driver["afms"] = response.data.data[i].afms;
                        driver["baseLocation"] = response.data.data[i].baseLocation;
                        driver["recordKeeper"] = response.data.data[i].recordKeeper;
                        driver["homeBaseTimeZone"] = response.data.data[i].homeBaseTimeZone;
                        driver["driver_is_contractor"] = response.data.data[i].driver_is_contractor;
                        driver["hasEWDLicence"] = response.data.data[i].hasEWDLicence;
                        driver["integrationId"] = response.data.data[i].driver_id.integrationId;
                        driverList.push(driver);
                    }

                }
                dispatch(setAllDriverList(driverList, showListDriverLoaderStatus));
            })
            .catch(error => {
                showListDriverLoaderStatus = false;
                if(error.response.status == 403){
                    localStorage.clear();
                    window.location.href = "/";
                }
                dispatch(setAllDriverList(driverList, showListDriverLoaderStatus));
            })
    }
}
export const setAllDriverList = (driverList, showListDriverLoaderStatus) => {
    return{
        type : SET_ALL_DRIVER_LIST,
        driverList : driverList,
        showListDriverLoaderStatus : showListDriverLoaderStatus
    }
}
export const onSearch = (searchDriverForm, props, url) => {
    return (dispatch) => {
        let searchFoundStatus = null;
        let driverInfo = {};
        let searchDriverLoaderStatus = false;
        let ln = searchDriverForm['license_number'].value;
        let ls= searchDriverForm['dob'].value;

        function getDateOnly(date) {
            let month = parseInt(date.getMonth());
            month+=1;
            if(month<10){
                month = "0"+month;
            }
            return (date.getFullYear() + "-" + month + "-" + (date.getDate()<10?"0"+date.getDate():date.getDate()));
        }

        if(ln && ls){
            let reqPath = "/businesses/" + localStorage.getItem('business_id') + "/searchdrivers"
            let params = {
                license:searchDriverForm.license_number.value?searchDriverForm.license_number.value:'NA',
                dob:searchDriverForm.dob.value?getDateOnly(searchDriverForm.dob.value):'NA'
            }
            searchDriverLoaderStatus = true;
            dispatch(searchDriver(searchFoundStatus, driverInfo, searchDriverLoaderStatus));
            return axios.get(reqPath,{ params:params})
            .then(response => {
                searchDriverLoaderStatus = false;
                let result = {
                    columns: [],
                    drivers: []
                }
                    if(response.data.data.length == 0){
                        searchFoundStatus = false;
                        url = url + "?license_number=" + ln + "&dob="+ls;
                        toast.success("Input data looks good for creating new Driver", {
                            containerId: 'toastMsg',
                            onClose : () => props.history.push(getActulaURL(url))
                        });
                    }else{
                        searchFoundStatus = true;
                        response.data.data.map(driver=>{
                            if(driver!="LINKED"){
                                let driverInfo = {};
                                let dob = driver.driver_dob.split("T");
                                driverInfo['driver_firstname'] = driver.driver_firstname;
                                driverInfo['driver_lastname'] = driver.driver_lastname;
                                driverInfo['driver_id'] = driver.driver_id;
                                driverInfo['driver_license'] = driver.driver_license;
                                driverInfo['driver_country'] = driver.driver_country;
                                driverInfo['driver_state'] = driver.driver_state;
                                driverInfo['driver_dob'] = dob[0];
                                driverInfo['driver_email'] = driver.driver_email;
                                driverInfo['driver_is_car'] = driver.driver_is_car;
                                driverInfo['driver_is_heavyrigid'] = driver.driver_is_heavyrigid;
                                driverInfo['driver_is_lightrigid'] = driver.driver_is_lightrigid;
                                driverInfo['driver_is_marine'] = driver.driver_is_marine;
                                driverInfo['driver_is_meduimrigid'] = driver.driver_is_meduimrigid;
                                driverInfo['driver_is_motorcycle'] = driver.driver_is_motorcycle;
                                driverInfo['driver_is_multicombination'] = driver.driver_is_multicombination;
                                driverInfo['driver_is_heavycombination'] = driver.driver_is_heavycombination;
                                result.drivers.push(driverInfo)
                            }
                        })
                        if((result.drivers).length==0){
                            toast.error("Driver is already associated with Business Id: "+ localStorage.getItem('business_id'), {
                                containerId: 'toastMsg'
                            });
                        }

                        result.columns= [
                            {
                                title: 'Driver ID',
                                field: 'driver_id',
                                type:'numeric'
                            },
                            {
                                title: 'First Name',
                                field: 'driver_firstname'
                            },
                            {
                                title: 'Last Name',
                                field: 'driver_lastname'
                            },
                            {
                                title: 'License Number',
                                field: 'driver_license'
                            },
                            {
                                title: 'Date of Birth',
                                field: 'dob'
                            },
                            {
                                title: 'Country',
                                field: 'driver_country'
                            }
                        ]
                    }
                    dispatch(searchDriver(searchFoundStatus, result, searchDriverLoaderStatus));
            })
            .catch(error => {
                searchDriverLoaderStatus = false;
                if(error.response.status == 403){
                    localStorage.clear();
                    window.location.href = "/";
                }else{
                    // searchFoundStatus = true;
                    toast.error(error.response.data.message, {
                        containerId: 'toastMsg'
                    });
                }
                dispatch(searchDriver(searchFoundStatus, driverInfo, searchDriverLoaderStatus));
            })
        } else {
            toast.error("Both the field can't be blank", {
                containerId: 'toastMsg'
            });
        }
        dispatch(searchDriver(searchFoundStatus, driverInfo, searchDriverLoaderStatus));
    }

}

export const searchDriver = (searchFoundStatus, driverInfo, searchDriverLoaderStatus) => {
    return{
        type : SEARCH_DRIVER,
        data : searchFoundStatus,
        driverInfo : driverInfo,
        searchDriverLoaderStatus : searchDriverLoaderStatus
    }
}

export const makeSearchFoundStatusNull = () => {
    return (dispatch) => {
        return dispatch(setSearchFoundStatus());
    }
}

export const setSearchFoundStatus = () => {
    return{
        type : DRIVER_SET_SEARCH_FOUND_STATUS_TO_NULL
    }
}

export const openLinkDriverDialog = (driverInfo) => {
    return (dispatch) => {
        return dispatch(manageLinkDriverDialog(driverInfo));
    }
}

export const manageLinkDriverDialog = (driverInfo) => {
    return{
        type : MANGE_LINK_DRIVER_DIALOG,
        driverInfo : driverInfo
    }
}

export const handleTextFieldChange = (textField, event) => {
    return (dispatch) => {
        return dispatch(setTextFieldData(textField, event));
    }
}

export const handleAddHistoricEwdEventDialog = (driverInfo) => {
    alert('otin');
}

export const handleEditDriverOpenDialog = (driverInfo) => {
    return (dispatch) => {
        let getDriverLicenseStatusLoaderStatus = true;      
        let reqUrl = "/businesses/"+localStorage.getItem('business_id')+"/licenses";
        let headers = {};
        let licensingStatus = {};
        licensingStatus['EWD'] = false;
        // licensingStatus['BUSINESSFORMS'] = false;
        headers['businessId'] = localStorage.getItem('business_id');
        headers['driverId'] = driverInfo["id"];
        dispatch(manageEditDriverOpenDialog(driverInfo, licensingStatus, getDriverLicenseStatusLoaderStatus));
        return axios.get(reqUrl, {headers})
        .then(response => {
            getDriverLicenseStatusLoaderStatus = false;
            for(let i = 0; i < response.data.data.length; i++){
                if(response.data.data[i] === 'EWD'){
                    licensingStatus['EWD'] = true;
                }
                // if(response.data.data[i] === 'BUSINESSFORMS'){
                //     licensingStatus['BUSINESSFORMS'] = true;
                // }
            }
            dispatch(manageEditDriverOpenDialog(driverInfo, licensingStatus, getDriverLicenseStatusLoaderStatus));
        })
        .catch(error => {
            getDriverLicenseStatusLoaderStatus = false;
            dispatch(manageEditDriverOpenDialog(driverInfo, licensingStatus, getDriverLicenseStatusLoaderStatus));
        })
    }
}

export const manageEditDriverOpenDialog = (driverInfo, licensingStatus, getDriverLicenseStatusLoaderStatus) => {
    return{
        type : MANAGE_EDIT_DRIVER_OPEN_DIALOG,
        driverInfo : driverInfo,
        licensingStatus : licensingStatus,
        getDriverLicenseStatusLoaderStatus : getDriverLicenseStatusLoaderStatus
    }
}

export const handleEditDriverDialogClose = () => {
    return (dispatch) => {
        return dispatch(manageEditDriverDialogClose());
    }
}

export const toggleViewDepotsDialog = (data,status) => {
    return (dispatch) => {
        dispatch({
            type : TOGGLE_VIEW_DEPORT_DIALOG,
            depots:[],
            status:status
        });
        if(!status){
            return true;
        }
        let reqPath = "businesses/"+localStorage.getItem("lastSelectedBusinessId") +"/drivers/"+data._id+"/depots";
        return axios.get(reqPath)
            .then(response => {
                dispatch({
                    type : TOGGLE_VIEW_DEPORT_DIALOG,
                    depots:response.data.data,
                    status:status
                });
            })
            .catch(error => {
                toast.error("Unable to load depots. Please contact administrator");
                dispatch({
                    type : TOGGLE_VIEW_DEPORT_DIALOG,
                    data:[],
                    status:false
                });
            })
    }
}

export const depotInputHandler = (elementId,event)=>{
    return (dispatch) => {
        return dispatch({
            type: SET_DEPOT_MAP_FIELD,
            inputFiledId: elementId,
            data: event.target.value
        });
    }
}

export const toggleAddDepotsDialog = (data,status) => {
    return (dispatch) => {
        dispatch({
            type : TOGGLE_ADD_DEPORT_DIALOG,
            data:[],
            driver:data,
            status:status
        });
    }
}

export const manageEditDriverDialogClose = () => {
    return{
        type : MANAGE_EDIT_DRIVER_DIALOG_CLOSE
    }
}

export const handleLinkDriverDialogClose = () => {
    return (dispatch) => {
        return dispatch(manageLinkDriverDialogClose());
    }
}

export const manageLinkDriverDialogClose = () => {
    return{
        type : MANAGE_LINK_DRIVER_DIALOG_CLOSE
    }
}


export const setTextFieldData = (textField, event) => {
    return{
        type : SEARCH_DRIVER_TEXTFIELD_CHANGE,
        textFieldId : textField,
        textFieldData : event.target.value
    }
}

/*********************************************************************************************************/
export const onSubmitAddDriver=(addDriverFormFields, driverTypesCheckBoxes, fatigueTypesCheckboxes,licensingModuleCheckBoxes, adminCheckBoxes,contractorCheckBoxes, props, url) => {
    return (dispatch) => {
        let showLoaderStatus = false;
        let driverFieldsValidationStatus = true;
        const optionalFields = ['integrationId', 'driver_other_name'];
        Object.keys(addDriverFormFields).map((textField) => {
            if(!optionalFields.includes(textField)){
                if(addDriverFormFields[textField].value  == ''){
                    driverFieldsValidationStatus = false;
                }
            }
        })
        // change the claim is valid
        if (addDriverFormFields['email'].value != '') {
            if (!(addDriverFormFields['email'].value).match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
                driverFieldsValidationStatus = false;
                addDriverFormFields['email'].errorStatus = true;
                toast.error("The email is not valid", {containerId: 'toastMsg'});
            } else {
                addDriverFormFields['email'].errorStatus = false;
            }
        }
        // Check the DOB for more than 18
        let dobvalid = false;
        if (addDriverFormFields['dob'].value) {
            let dob_date = moment(addDriverFormFields['dob'].value);
            let check_date = moment(new Date()).add(-15, 'year');
            if (dob_date <= check_date) {
                dobvalid = true;
            }
        }
        if (!dobvalid) {
            driverFieldsValidationStatus = false;
            addDriverFormFields['dob'].errorStatus = true;
            toast.error("You must be over 18", {containerId: 'toastMsg'});
        }
        dispatch(setSubmittedData(showLoaderStatus));
        if(driverFieldsValidationStatus){
            showLoaderStatus = true;
            let addDriverReq = {};
            let licensingModule = {};
            licensingModule['EWD'] = licensingModuleCheckBoxes['ewd'].elementConfig.checked;
            // licensingModule['BUSINESSFORMS'] = licensingModuleCheckBoxes['business_forms'].elementConfig.checked;

            if(localStorage.getItem('role') == '1'){
                licensingModule["IsNHVRTesting"] = adminCheckBoxes['driver_is_nhvr_testing'].elementConfig.checked;
            }

            addDriverReq["DriverFirstName"] = addDriverFormFields['driver_first_name'].value;
            addDriverReq["DriverLastName"] = addDriverFormFields['driver_last_name'].value;
            addDriverReq["DriverOtherName"] = addDriverFormFields['driver_other_name'].value;
            addDriverReq["DriverDob"] = formatDate(addDriverFormFields['dob'].value);
            addDriverReq["DriverEmail"] = addDriverFormFields['email'].value;
            addDriverReq["LicenseNumber"] =  addDriverFormFields['license_number'].value;
            addDriverReq["LicenseState"] = addDriverFormFields['state'].value;
            addDriverReq["LicenseCountry"] = addDriverFormFields['country'].value;
            addDriverReq["baseLocation"] = addDriverFormFields['baseLocation'].value;
            addDriverReq["recordKeeper"] = addDriverFormFields['recordKeeper'].value;
            addDriverReq["homeBaseTimeZone"] = addDriverFormFields['homeBaseTimeZone'].value;
            addDriverReq["integrationId"] = addDriverFormFields['integrationId'].value;

            addDriverReq["LightRidged"] = driverTypesCheckBoxes['light_ridged'].elementConfig.checked;
            addDriverReq["MediumRidged"] = driverTypesCheckBoxes['medium_ridged'].elementConfig.checked;
            addDriverReq["HeavyRidged"] = driverTypesCheckBoxes['heavy_ridged'].elementConfig.checked;
            addDriverReq["MultiCombination"] = driverTypesCheckBoxes['multi_combination'].elementConfig.checked;

            addDriverReq["HeavyCombination"] = driverTypesCheckBoxes['heavy_combination'].elementConfig.checked;
            addDriverReq["Car"] = driverTypesCheckBoxes['car'].elementConfig.checked;
            addDriverReq["Motorcycle"] = driverTypesCheckBoxes['motorcycle'].elementConfig.checked;
            addDriverReq["Marine"] = driverTypesCheckBoxes['marine'].elementConfig.checked;
            addDriverReq["bfms"] = fatigueTypesCheckboxes['bfms'].value;
            addDriverReq["afms"] = fatigueTypesCheckboxes['afms'].value;
            addDriverReq["licenseModules"] = licensingModule;
            addDriverReq["driver_is_contractor"] = contractorCheckBoxes['driver_is_contractor'].elementConfig.checked;

            dispatch(setSubmittedData(showLoaderStatus));
            let reqPath = '/dist/'+localStorage.getItem('distributor_id')+'/businesses/' + localStorage.getItem('business_id') + "/drivers";
            return axios.post(reqPath, addDriverReq)
            .then(response => {
                    showLoaderStatus = false;
                        toast.success("Driver Created Successfully", {
                            containerId: 'toastMsg',
                            onClose : () => props.history.push(getActulaURL(url))
                        });
                    dispatch(setSubmittedData(showLoaderStatus));
            })
            .catch(error => {
                showLoaderStatus = false;
                toast.error("Unable to create driver. Driver may already exist.", {containerId: 'toastMsg'});
                dispatch(setSubmittedData(showLoaderStatus));
            })
        }
    }

}

export const setSubmittedData=(showLoaderStatus)=>{
    return {
        type: SUBMIT_DRIVER_INFO,
        showLoaderStatus : showLoaderStatus
    }
}
