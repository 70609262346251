import React, {Component} from 'react';
import {connect} from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import {withRouter} from 'react-router-dom';
import {
    login,
    handleTextFieldChange, setLoginStatus
} from '../../actions/Login';
import './Signin.css';
import {roles} from "../../constants/roles";

class SignIn extends Component {

    componentDidMount() {
        if (localStorage.getItem("token")) {
            let redirectUrl = "";
            switch (localStorage.getItem("role")) {
                case roles.SG_ADMIN :
                    redirectUrl = '/sgadminportal/partners';
                    break;
                case roles.DISTRIBUTOR_ADMIN :
                case roles.DISTRIBUTOR_EDITOR :
                    redirectUrl = "/distributorportal/" + localStorage.getItem('distributor_id') + "/businesses";
                    break;
                case roles.DISTRIBUTOR_REPORTER :
                    redirectUrl = "/distributorportal/" + localStorage.getItem('distributor_id') + "/profile";
                    break;
                case roles.BUSINESS_ADMIN :
                case roles.BUSINESS_EDITOR :
                    redirectUrl = '/businessportal/' + localStorage.getItem('business_id') + '/drivers';
                    break;
                case roles.BUSINESS_REPORTER :
                    redirectUrl = '/businessportal/' + localStorage.getItem('business_id') + '/profile';
                    break;
                case roles.PARTNER_ADMIN:
                case roles.PARTNER_EDITOR:
                case roles.PARTNER_REPORTER:
                    redirectUrl = '/partnerportal/' + localStorage.getItem('partner_id') + '/distributors';
                    break;
                default:
                    redirectUrl ="/"
                    break;
            }
            window.location.href = redirectUrl;
        }
    }

    render() {
        let {showLoaderStatus, loginTextFields} = this.props.loginState;
        let disableSignin = loginTextFields.email.value && loginTextFields.password.value && loginTextFields.email.value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i) ? false : true
        return (
            <div className="d-flex align-items-center justify-content-center">
                <div className="card-body">
                    <form>
                        {
                            Object.keys(loginTextFields).map((textFields) => {
                                return (
                                    <div className="input-group no-border input-lg"
                                         key={loginTextFields[textFields].name}>
                                        <div className="input-group-prepend">
                                    <span className="input-group-text">
                                        <i className={loginTextFields[textFields].icon}></i>
                                    </span>
                                        </div>
                                        <input
                                            type={loginTextFields[textFields].type}
                                            className="form-control"
                                            placeholder={loginTextFields[textFields].name}
                                            value={loginTextFields[textFields].value}
                                            onChange={(event) => {
                                                this.props.handleTextFieldChange(textFields, event, loginTextFields)
                                            }}
                                        />
                                    </div>

                                )
                            })
                        }

                        {
                            showLoaderStatus ? <CircularProgress style={{color: "#69bce6"}}/> :
                                <div className="text-center">
                                    <button
                                        type="submit"
                                        onClick={() => this.props.login(loginTextFields, this.props)}
                                        className="btn btn-info btn-round btn-lg btn-block btn-login"
                                        disabled={disableSignin}
                                        style={{cursor: disableSignin ? 'not-allowed' : ''}}
                                    >
                                        Sign In
                                    </button>
                                    <div className="pull-left">
                                        <h6>
                                            {/*<a href = "javascript:void(0)" onClick={()=>{this.props.handleType('notauser')}} className="link" style = {{cursor : "pointer"}}>
                                                Not A User?
                                            </a>*/}
                                            <a href="https://smartedriver.com/contact/" className="link"
                                               style={{cursor: "pointer"}}>
                                                Not A User?
                                            </a>
                                        </h6>
                                    </div>
                                    <div className="pull-right">
                                        <h6>
                                            <a href="javascript:void(0)" onClick={() => {
                                                this.props.handleType('password')
                                            }} className="link" style={{cursor: "pointer"}}>
                                                Forgot Password
                                            </a>
                                        </h6>
                                    </div>
                                </div>
                        }

                    </form>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        loginState: state.login
    }
}

const mapDispatchToProps = dispatch => {
    return {
        login: (loginControls, props) => {
            return dispatch(login(loginControls, props))
        },
        handleTextFieldChange: (textField, event, loginTextField) => {
            return dispatch(handleTextFieldChange(textField, event, loginTextField))
        },
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SignIn));
