import React, { Component } from 'react';
import Dialog from '@material-ui/core/Dialog';
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import MobileStepper from "@material-ui/core/MobileStepper";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';

import TermNCondition from "./TermNCondition";
import ChangePassword from "./ChangePassword";
import BusinessDetails from "./BusinessDetails";
import {
    handleBusinessFirstLoginStepperNext,
    handleBusinessFirstLoginStepperBack,
    onClickChangePassword,
    termNCondition,
    finishFirstLogin,
    termNConditionReject
} from "../../../actions/Business";
  const styles = theme => ({
    root: {
      flexGrow: 1
    },
    header: {
      display: 'flex',
      alignItems: 'center',
      height: 50,
      paddingLeft: theme.spacing.unit * 4,
      backgroundColor: theme.palette.background.default,
    },
    img: {
      height: 255,
      overflow: 'hidden',
      display: 'block',
      width: '100%',
    },
  });

class BusinessFirstLoginStepper extends Component{
    render(){
        let{
            businessFirstLoginSteps,
            businessFirstLoginActiveStep,
            businessFirstLoginStepperLoaderStatus,
            firstLoginBusinessChangePasswordForm,
            businessDetailsForm
        } = this.props.businessState;
        const { classes, theme } = this.props;
        const maxSteps = businessFirstLoginSteps.length;
        return(
            <div>
                <Dialog
                    aria-labelledby="customized-dialog-title"
                    open={localStorage.getItem('userfirstlogin') === 'true' && localStorage.getItem('role') === '5' ? true : false}
                    maxWidth = 'md'
                >
                    <div className={classes.root} style = {{minWidth : "100%"}}>
                        <Paper square elevation={0} className={classes.header}>
                        <Typography variant="h5" className = "pt-5 pb-5">
                            {businessFirstLoginSteps[businessFirstLoginActiveStep].label}
                        </Typography>
                        </Paper>
                        {
                            businessFirstLoginSteps[businessFirstLoginActiveStep].id === 'term_n_condition' ? 
                                <TermNCondition /> 
                            : businessFirstLoginSteps[businessFirstLoginActiveStep].id === 'change_password' ?
                                <ChangePassword/> : businessFirstLoginSteps[businessFirstLoginActiveStep].id === 'business_details' ?
                                <BusinessDetails/> : null
                        }
                        <MobileStepper
                        steps={maxSteps}
                        position="static"
                        activeStep={businessFirstLoginActiveStep}
                        className={classes.mobileStepper}
                        nextButton={
                            businessFirstLoginSteps[businessFirstLoginActiveStep].id === 'term_n_condition' ? 
                                <div>
                                    {
                                        businessFirstLoginStepperLoaderStatus ? <CircularProgress color = "primary" /> :
                                        <Button
                                            className = "pt-2 pb-2 pl-4 pr-4 mr-3"
                                            size="small"
                                            variant="contained" 
                                            color="primary" 
                                            onClick={() => this.props.termNCondition()}
                                            disabled={businessFirstLoginActiveStep === maxSteps - 1}
                                        >
                                            Accept
                                            {theme.direction === "rtl" ? (
                                                <KeyboardArrowLeft />
                                            ) : (
                                                <KeyboardArrowRight />
                                            )}
                                        </Button> 
                                    }
                                    
                                </div>


                            : businessFirstLoginSteps[businessFirstLoginActiveStep].id === 'change_password' ? 
                            <div>
                                {
                                    businessFirstLoginStepperLoaderStatus ? <CircularProgress color = "primary" /> :
                                    <Button
                                        className = "pt-2 pb-2 pl-4 pr-4 mr-3"
                                        size="small"
                                        variant="contained" 
                                        color="primary" 
                                        onClick={() => this.props.onClickChangePassword(firstLoginBusinessChangePasswordForm)}
                                        disabled={businessFirstLoginActiveStep === maxSteps - 1}
                                    >
                                        Change Password
                                        {theme.direction === "rtl" ? (
                                            <KeyboardArrowLeft />
                                        ) : (
                                            <KeyboardArrowRight />
                                        )}
                                    </Button>
                                }

                            </div>
                            : businessFirstLoginSteps[businessFirstLoginActiveStep].id === 'business_details' ? 
                            <Button
                                className = "pt-2 pb-2 pl-4 pr-4 mr-3"
                                size="small"
                                variant="contained" 
                                color="primary" 
                                onClick={() => this.props.finishFirstLogin(businessDetailsForm)}
                            >
                                Finish
                            </Button> : null
                        }
                        backButton={
                            businessFirstLoginActiveStep === 0 ?
                            <Button
                                className = "pt-2 pb-2 pl-4 pr-4 mr-3"
                                size="small"
                                color="default" 
                                onClick={() => this.props.termNConditionReject()}
                            >
                                {theme.direction === "rtl" ? (
                                    <KeyboardArrowRight />
                                ) : (
                                    <KeyboardArrowLeft />
                                )}
                                Reject
                            </Button> :
                            <Button
                                size="small"
                                onClick={this.props.handleBusinessFirstLoginStepperBack}
                                >
                                {theme.direction === "rtl" ? (
                                    <KeyboardArrowRight />
                                ) : (
                                    <KeyboardArrowLeft />
                                )}
                                Back
                            </Button>
                        }
                        />
                    </div>
                </Dialog>
            </div>
        )
    }
}
BusinessFirstLoginStepper.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired
  };

const mapStateToProps = state => {
    return{
        businessState : state.business
    }
}

const mapDispatchToProps = dispatch => {
    return{
        termNConditionReject : () => {
            return dispatch(termNConditionReject())
        },
        handleBusinessFirstLoginStepperNext : () => {
            return dispatch(handleBusinessFirstLoginStepperNext())
        },
        handleBusinessFirstLoginStepperBack : () => {
            return dispatch(handleBusinessFirstLoginStepperBack())
        },
        onClickChangePassword : (firstLoginBusinessChangePasswordForm) => {
            return dispatch(onClickChangePassword(firstLoginBusinessChangePasswordForm))
        },
        termNCondition : () => {
            return dispatch(termNCondition())
        },
        finishFirstLogin : (businessDetailsForm) => {
            return dispatch(finishFirstLogin(businessDetailsForm))
        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, { withTheme: true })(BusinessFirstLoginStepper));