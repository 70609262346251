import React, { Component } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import CardHeader from '@material-ui/core/CardHeader';
import FormGroup from '@material-ui/core/FormGroup';
import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux';
import{ withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';

import Input from "../../components/UI/Input/Input";


import {
    handleViewMaillogDialogClose,
} from '../../actions/Maillogs';

const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing.unit * 2,
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
  card: {
    minWidth: 275,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

class ViewMaillog extends Component {
    componentDidMount(){
    }
    
    render(){
        const { classes } = this.props;
        
        let { 
            viewMaillogFormFields
        } = this.props.maillogs;

        const formElementArray = [];

        for(let key in viewMaillogFormFields){
            formElementArray.push({
                id : key,
                config : viewMaillogFormFields[key]
            })
        }

        let maillogViewForm = (
            <FormGroup row>
                <Grid container spacing={24}>
                    {
                        formElementArray.map(formElement => {
                            if(formElement.id === 'mail_body'){
                                return(
                                    <Grid item xs={12} sm = {6} key = {formElement.id}>
                                        <textarea 
                                            key = {formElement.id}
                                            name = {formElement.config.elementConfig.label}
                                            elementType={formElement.config.elementType}
                                            elementConfig={formElement.config.elementConfig}
                                            value={formElement.config.value}
                                            menuItems = {formElement.config.menuItems}
                                            error = {formElement.config.errorStatus}
                                            helperText = {formElement.config.errorMessage}
                                            maxLength = {formElement.config.maxLength}
                                            disabledStatus = {formElement.config.disabledStatus}
                                            rows = "20"
                                            cols = "120"
                                        />
                                    </Grid>
                                )
                            } else {
                                return(
                                    <Grid item xs={12} sm = {6} key = {formElement.id}>
                                        <Input 
                                            key = {formElement.id}
                                            name = {formElement.config.elementConfig.label}
                                            elementType={formElement.config.elementType}
                                            elementConfig={formElement.config.elementConfig}
                                            value={formElement.config.value}
                                            menuItems = {formElement.config.menuItems}
                                            error = {formElement.config.errorStatus}
                                            helperText = {formElement.config.errorMessage}
                                            maxLength = {formElement.config.maxLength}
                                            disabledStatus = {formElement.config.disabledStatus}
                                        />
                                    </Grid>
                                )
                            }
                        })
                    }
                </Grid>
                <Grid 
                    container 
                    spacing={24} 
                    className = "pt-2"
                    direction="row" 
                    justify="flex-end"
                    alignItems="center"
                >
                    <Grid item>
                        {
                                <Button
                                    variant="contained" 
                                    color="default" 
                                    disabled={false}
                                    onClick = {() => {this.props.handleViewMaillogDialogClose()}}
                                >
                                    Cancel
                                </Button>
                        }
                    </Grid>

                </Grid>
            </FormGroup>
        )
        
        return(
            <div className = "container">
                <Card 
                    // className={classes.card} 
                    className = "pl-3 pr-3 pt-3 mb-5"
                >
                <CardHeader
                    title="VIEW MAIL LOG"
                />
                    <CardContent>
                    {
                        <div>
                            {maillogViewForm}
                            <Grid 
                                container 
                                spacing={24} 
                                className = "pt-2"
                                direction="row" 
                                justify="flex-end"
                                alignItems="center"
                            >
                            </Grid>
                        </div>
                    }
                    </CardContent>
                </Card>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return{
        maillogs: state.maillogs
    };
};


const mapDispatchToProps = dispatch => {
    return{
        handleViewMaillogDialogClose : () => {
            return dispatch(handleViewMaillogDialogClose())
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps) (withStyles(styles)(withRouter(ViewMaillog)));