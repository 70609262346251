import {xAPIKey} from "../config";
export const convertArrayToCSVDownload = (data, fileName) => {
		let csvContent = '';
		let dataString = '';
		data.forEach(function(infoArray, index) {
			dataString = infoArray.join(',');
			csvContent += index < data.length ? dataString : dataString;
		});
		let download = function(content, fileName, mimeType) {
			let a = document.createElement('a');
			mimeType = mimeType || 'application/octet-stream';
		
			if (navigator.msSaveBlob) { // IE10
				navigator.msSaveBlob(new Blob([content], {
					type: mimeType
				}), fileName);
			} else if (URL && 'download' in a) { 
				a.href = URL.createObjectURL(new Blob([content], {
					type: mimeType
				}));
				a.setAttribute('download', fileName);
				document.body.appendChild(a);
				a.click();
				document.body.removeChild(a);
			} else {
				window.location.href = 'data:application/octet-stream,' + encodeURIComponent(content);
			}
		}
		
		download(csvContent, fileName+'.csv', 'text/csv;encoding:utf-8');
}

export const convertToBase64 = (file) => {
    return new Promise((resolve,reject) => {
        var fileReader = new FileReader();
        var base64;
        let returnBase64Data;
        fileReader.onload = function(fileLoadedEvent) {
            base64 = fileLoadedEvent.target.result;
            base64 = base64.split(",");
            returnBase64Data = base64[1];
            resolve(returnBase64Data);
        };
        fileReader.readAsDataURL(file);
    })
};

export const getActulaURL = (formalURL) => {
    let actulaURL = '';
    if(localStorage.getItem('partner_id')){
        if(formalURL.indexOf(":partner_id") >= 0){
            actulaURL = formalURL.replace(":partner_id",localStorage.getItem('partner_id'));
        }
    }
    if(localStorage.getItem('distributor_id')){
        if(formalURL.indexOf(":dist_id") >= 0){
            actulaURL = formalURL.replace(":dist_id",localStorage.getItem('distributor_id'));
        }
    }
    if(localStorage.getItem('business_id')){
        if(formalURL.indexOf(":buss_id") >= 0){
            actulaURL = formalURL.replace(":buss_id",localStorage.getItem('business_id'));
        }
    }

    if(localStorage.getItem('checklist_id')){
        if(formalURL.indexOf(":checklistid") >= 0){
            actulaURL = formalURL.replace(":checklistid",localStorage.getItem('checklist_id'));
        }
    }

    if(localStorage.getItem('template_id')){
        if(formalURL.indexOf(":tempid") >= 0){
            actulaURL = formalURL.replace(":tempid",localStorage.getItem('template_id'));
        }
    }

    if(localStorage.getItem('vehicle_id')){
        if(formalURL.indexOf(":vehicleid") >= 0){
            actulaURL = formalURL.replace(":vehicleid",localStorage.getItem('vehicle_id'));
        }
    }
    if(localStorage.getItem('driver_id')){
        if(formalURL.indexOf(":driverid") >= 0){
            actulaURL = formalURL.replace(":driverid",localStorage.getItem('driver_id'));
        }
    }

    if(actulaURL === ''){
        return formalURL
    }else{
        return actulaURL;
    }
}



export function formatDate(date, format = "YYYY-MM-DD") {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    if(format === "YYYY-MM-DD"){
        return [year, month, day].join('-');
    }
    if(format === "DD-MM-YYYY"){
        return [day, month,year].join('-');
    }
}

export function removeFakePath(path){
    return path.replace(/^.*\\/, "");
}

export function getFileExtension(fullPath){
    return ((fullPath.split('.'))[(fullPath.split('.')).length - 1]);
    
}

export function isObjectEmpty(obj){
    if((Object.keys(obj)).length > 0){
        return false;
    }else{
        return true;
    }
}

export function getMimeType(filename){
    let extension = getFileExtension(filename);
    let mimeType = '';
    if(extension.toLowerCase() === 'jpeg'){
        mimeType = 'application/jpeg';
    } 
    if(extension.toLowerCase() === 'jpg'){
        mimeType = 'application/jpeg';
    }
    if(extension.toLowerCase() === 'png'){
        mimeType = 'image/png';
    }
    return mimeType;
}

export function findFromArray(array, element){
    for(let i = 0; i < array.length; i++){
        if(element === array[i]){
            return true;
        }
    }
    return false;
}


export function convertFormDDMMYYYToDateObject(date){
    date = date.split("-");
    let dateObj = new Date([date[2],date[1],date[0]].join('-'));
    return dateObj;
}

export function downloadFile(reqURL){
    var request = new XMLHttpRequest();
    
    request.open("GET", reqURL, true); 
    request.setRequestHeader("Authorization", localStorage.getItem('token'));
    request.setRequestHeader("Platform-User", localStorage.getItem('Platform-User'));
    // request.send("businessId", localStorage.getItem('business_id'));
    // request.send("bucket", "masscertificationbucket");
    // request.send("key", );
    request.setRequestHeader("x-api-key", xAPIKey);
    request.responseType = "blob";
    request.onload = function (e) {
            let mimeType = request.getResponseHeader('content-type').split('/');
            let fileName = 'medical_certificate';
            mimeType = mimeType[1];
            if(mimeType === 'vnd.openxmlformats-officedocument.wordprocessingml.document'){
                fileName = 'medical_certificate.docx';
            }
            if(mimeType === 'msword'){
                fileName = 'medical_certificate.doc';
            }
            if(mimeType === 'pdf'){
                fileName = 'medical_certificate.pdf';
            }
            if(mimeType === 'jpeg'){
                fileName = 'medical_certificate.jpeg';
            }
            if(mimeType === 'jpeg'){
                fileName = 'medical_certificate.jpeg';
            }
            if(mimeType === 'png'){
                fileName = 'medical_certificate.png';
            }

            var file = window.URL.createObjectURL(this.response);
            var a = document.createElement("a");
            a.href = file;
            a.download = this.response.name || fileName;
            document.body.appendChild(a);
            a.click();
            window.onfocus = function () {                     
            document.body.removeChild(a)
            }
    };
    request.send();
}


export function GetUrl(reqURL){
    var request = new XMLHttpRequest();
    request.open("GET", reqURL, true); 
    request.setRequestHeader("Authorization", localStorage.getItem('token'));
    request.setRequestHeader("Platform-User", localStorage.getItem('Platform-User'));
    request.setRequestHeader("x-api-key", xAPIKey);
    request.responseType = "blob";
    request.onload = function (e) {
            let mimeType = request.getResponseHeader('content-type').split('/');
            let fileName = 'medical_certificate';
            mimeType = mimeType[1];
            if(mimeType === 'vnd.openxmlformats-officedocument.wordprocessingml.document'){
                fileName = 'medical_certificate.docx';
            }
            if(mimeType === 'msword'){
                fileName = 'medical_certificate.doc';
            }
            if(mimeType === 'pdf'){
                fileName = 'medical_certificate.pdf';
            }
            if(mimeType === 'jpeg'){
                fileName = 'medical_certificate.jpeg';
            }
            if(mimeType === 'jpeg'){
                fileName = 'medical_certificate.jpeg';
            }
            if(mimeType === 'png'){
                fileName = 'medical_certificate.png';
            }

            var file = window.URL.createObjectURL(this.response);
            console.log("3333 " + file);
            var a = document.createElement("a");
            a.href = file;
            a.target = "_blank";
            a.open = this.response.name || fileName;
            document.body.appendChild(a);
            a.click();
            // window.onfocus = function () {                     
            // document.body.removeChild(a)
            // }
    };
    request.send();
}


//Download Mass Certificate
export function downloadCert(reqURL){
    var request = new XMLHttpRequest();
    request.open("GET", reqURL, true); 
    request.setRequestHeader("Authorization", localStorage.getItem('token'));
    request.setRequestHeader("Platform-User", localStorage.getItem('Platform-User'));
    request.setRequestHeader("x-api-key", xAPIKey);
    request.responseType = "blob";
    request.onload = function (e) {
            let mimeType = request.getResponseHeader('Content-Type').split('/');
            let fileName = 'Certificate';
            mimeType = mimeType[1];
            if(mimeType === 'vnd.openxmlformats-officedocument.wordprocessingml.document'){
                fileName = 'Certificate.docx';
            }
            if(mimeType === 'msword'){
                fileName = 'Certificate.doc';
            }
            if(mimeType === 'pdf'){
                fileName = 'Certificate.pdf';
            }
            if(mimeType === 'jpeg'){
                fileName = 'Certificate.jpeg';
            }
            if(mimeType === 'jpeg'){
                fileName = 'Certificate.jpeg';
            }
            if(mimeType === 'png'){
                fileName = 'Certificate.png';
            }

            var file = window.URL.createObjectURL(this.response);
            console.log("3333 " + file);
            var a = document.createElement("a");
            a.href = file;
            a.target = "_blank";
            console.log(fileName)
            a.download = this.response.name || fileName;
            document.body.appendChild(a);
            a.click();
            // window.onfocus = function () {                     
            // document.body.removeChild(a)
            // }
    };
    request.send();
}