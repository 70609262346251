import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Hidden from "@material-ui/core/Hidden";
import Drawer from "@material-ui/core/Drawer";
import Button from '@material-ui/core/Button';
import Popover from '@material-ui/core/Popover';
import { NavLink } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import axios from '../../axios';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import {reportUrl} from '../../config';

import {
    BrowserRouter as Router,
    Route
} from 'react-router-dom';

import {
    getActulaURL
} from "../../utils/Helper";

import {
    getMenuItems
} from "../../actions/Sidebar";

import "./Header.css"
import { roles } from "../../constants/roles";


const drawerWidth = 270;
const styles = theme => ({
    root: {
        display: "flex"
    },
    toolbar: theme.mixins.toolbar,
    btnPadding: {
        paddingTop: "0.7rem",
        paddingBottom: "0.7rem"
    },

    profileName: {
        display: "flex",
        height: "8vh",
        marginTop: "0.4rem",
        justifyContent: "center",
        alignItems: "center",
    },
    drawerPaper: {
        width: drawerWidth
    },
    drawer: {
        [theme.breakpoints.up("sm")]: {
            width: drawerWidth,
            flexShrink: 0
        }
    }
});

const defaultTheme = {
    bannercolor: "#3b3c3c",
    buttoncolor: "#656464",
    buttonhovercolor: "#3b3c3c"
}
class Sidebar extends Component {
    state = {
        menuOpen: true,
        toolkitOpen: true,
        fatigue_type: '',
        solo_or_twoup: '',
        vehicle_type:  '',
        time_duration: '',
        breach_type: ''
      };

      handleMenuClick = () => {
        this.setState(state => ({ menuOpen: !state.menuOpen }));
      };
    constructor(props) {
        super(props);
        if (props.themeColor) {
            this.state = {
                mobileOpen: false,
                anchorEl: null,
                themeDialog: false,
                ...props.themeColor
            }
        } else {
            this.state = {
                mobileOpen: false,
                anchorEl: null,
                themeDialog: false,
                ...defaultTheme
            }
        }
    }

    componentWillReceiveProps = (nextProps) => {
        if (nextProps.themeColor) {
            this.setState({ ...nextProps.themeColor })
        }
    }

    handleDrawerToggle = () => {
        this.setState(state => ({ mobileOpen: !state.mobileOpen }));
    };
    handleClick = event => {
        this.setState({ anchorEl: event.currentTarget });
    };

    updateState = (state, value) => {
        this.setState({
            [state]: value
        })
    }

    handleClose = () => {
        this.setState({ anchorEl: null });
    }

    resetTheme = () => {
        this.setState({ ...defaultTheme });
    }

    handleLogout = () => {
        this.setState({ anchorEl: null });
        let reqUrl = "/userlogout";
        let reqParams = {}
        return axios.post(reqUrl, reqParams)
        .then(response => {
            localStorage.clear();
            window.location.href = "/";
        })
        .catch(error => {
            localStorage.clear();
            window.location.href = "/";
        })
    }

    componentDidMount = () => {
        this.props.getMenuItems()
    }

    updateTheme = () => {
        let payloads = {
            bannercolor: this.state.bannercolor,
            buttoncolor: this.state.buttoncolor,
            buttonhovercolor: this.state.buttonhovercolor
        }
        axios.put('/distributor/' + localStorage.distributor_id + '/themes', payloads)
            .then(response => {
                localStorage.setItem('themeColor', JSON.stringify(payloads))
                window.location.reload()
            }).catch(error => {
                console.log(error);
            })
    }

    handleDialogChange = (name,event)=>{
        console.log('event',name,event.target)
        this.setState({[name]:event.target.value})
    }

    handleDialogClose =()=>{
        this.setState({toolkitOpen:false})
    }
    getToday(){
        let t = new Date();
        return t.getFullYear() + '-' + (parseInt(t.getMonth()) +1) + '-' + t.getDate();
    }
    getUserType(){
        const role = localStorage.getItem('role');

        switch (role) {
            
            case roles.SG_ADMIN:
                return 'SG Admin'
            
            case roles.DISTRIBUTOR_EDITOR:
            case roles.DISTRIBUTOR_ADMIN:
            case roles.DISTRIBUTOR_REPORTER:
                return 'Reseller';
            
            case roles.BUSINESS_ADMIN:
            case roles.BUSINESS_EDITOR:
            case roles.BUSINESS_REPORTER:
                return 'Business'
            case roles.DEPOT_MANAGER:
                return 'DEPOT MANAGER'

            case roles.PARTNER_ADMIN:
            case roles.PARTNER_EDITOR:
            case roles.PARTNER_REPORTER:
                return 'Partner'

            default:
                return ''
        }
    }

    render() {
        let {
            roleMenuitems,
            menuItems
        } = this.props.sidebarState;

        Object.keys(menuItems).map(menuItem => {
            if(menuItems[menuItem].submenu){
                Object.keys(menuItems[menuItem].submenu).map(submenuItem => {
                    menuItems[menuItem].submenu[submenuItem].urlpattern = getActulaURL(menuItems[menuItem].submenu[submenuItem].urlpattern);
                })
            }
            menuItems[menuItem].urlpattern = getActulaURL(menuItems[menuItem].urlpattern);
        })

        const userType = this.getUserType();

        const { classes, theme } = this.props;
        const { anchorEl } = this.state;
        const themeDialogWidget = (
            <Dialog
                scroll='body'
                open={this.state.themeDialog}
                onClose={e => this.updateState('themeDialog', false)}
            >
                <DialogTitle>Theme Setting</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        name="bannercolor"
                        margin="dense"
                        id="bannercolor"
                        label="Banner Color"
                        type="text"
                        error = {!this.state.bannercolor ? true : false}
                        helperText = {!this.state.bannercolor ? "Enter Banner Color" : null}
                        onChange={e => { this.updateState(e.target.name, e.target.value) }}
                        value={this.state.bannercolor}
                        fullWidth
                        required
                    />
                    <TextField
                        autoFocus
                        name="buttoncolor"
                        margin="dense"
                        id="buttoncolor"
                        label="Button Color"
                        type="text"
                        error = {!this.state.buttoncolor ? true : false}
                        helperText = {!this.state.buttoncolor ? "Enter Button Color" : null}
                        onChange={e => { this.updateState(e.target.name, e.target.value) }}
                        value={this.state.buttoncolor}
                        fullWidth
                        required
                    />
                    <TextField
                        autoFocus
                        name="buttonhovercolor"
                        margin="dense"
                        id="buttonhovercolor"
                        label="Button Hover Color"
                        type="text"
                        error = {!this.state.buttonhovercolor ? true : false}
                        helperText = {!this.state.buttonhovercolor ? "Enter Button Hover Color" : null}
                        onChange={e => { this.updateState(e.target.name, e.target.value) }}
                        value={this.state.buttonhovercolor}
                        fullWidth
                        required
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={(e) => this.updateTheme()}
                        color="primary"
                        disabled = {this.state.buttonhovercolor === '' || this.state.buttoncolor === '' || this.state.bannercolor === ''}
                    >
                        Submit
                    </Button>
                    <Button onClick={(e) => this.resetTheme()} color="primary">
                        Reset
                    </Button>
                    <Button onClick={(e) => this.updateState('themeDialog', false)} color="primary">
                        Cancel
                    </Button>

                </DialogActions>
            </Dialog>
        );
        const drawer = (
            <div>
                <div className={classes.toolbar} >
                    <div className={classes.profileName}>
                        <Button
                            variant="outlined"
                            color="default"
                            aria-label="Add"
                            aria-owns={anchorEl ? 'simple-menu' : undefined}
                            aria-haspopup="true"
                            onClick={this.handleClick}
                            className={classes.btnPadding}
                            className="custom-btn"

                        >
                            <i style={{ paddingRight: 5, color: "#3c4858" }} className="material-icons">
                                account_circle
                            </i>
                            {
                                userType
                            }

                            <i className="material-icons" style={{ paddingLeft: 10, color: "#3c4858" }}>
                                arrow_drop_down
                            </i>
                        </Button>

                        <Popover
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            id="simple-menu"
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={this.handleClose}
                        >
                            {
                                localStorage.getItem('role') !== roles.SG_ADMIN ?
                                    <NavLink to={menuItems['my_profile'] !== undefined ? menuItems['my_profile'].urlpattern : 'profile'} style={{textDecoration: 'none'}}>
                                        <MenuItem onClick={this.handleClose}>My Profile</MenuItem>
                                    </NavLink> : null
                            }
                            <MenuItem onClick={() => this.handleLogout()}>
                                Logout
                            </MenuItem>
                        </Popover>
                    </div>
                </div>
                <Divider />
                <List>
                    {
                        roleMenuitems !== undefined ?
                            Object.keys(roleMenuitems).map(menuItem => {
                                    if(!roleMenuitems[menuItem].submenu){
                                        if (roleMenuitems[menuItem].label != '') {
                                            return (
                                                <div key={menuItem}>
                                                    {
                                                        menuItem !== 'sg_reports' && menuItem !== 'distributor_reports' && menuItem !== 'business_reports' && menuItem !== 'distributor_theme' && menuItem !== 'partner_reports'?
                                                            <NavLink
                                                                key={menuItem}
                                                                className="navBarTextColor"
                                                                to={roleMenuitems[menuItem].routeDisable ? { javascript: void (0) } : roleMenuitems[menuItem].urlpattern}
                                                                onClick={e => {
                                                                    if (menuItem == 'sg_reports' || menuItem == 'distributor_reports' || menuItem == 'business_reports') {
                                                                        console.log(">>>" + reportUrl);
                                                                        let url = reportUrl + '?date='+ this.getToday() +'&token=' + localStorage.token + '&businessId='+ localStorage.lastSelectedBusinessId;
                                                                        window.open(url, "_blank");
                                                                    } else if (menuItem == 'distributor_theme') {
                                                                        this.setState({ themeDialog: true })
                                                                    }
                                                                }}
                                                                activeStyle = {{backgroundColor: "#cccccc"}}
                                                            >
                                                                <ListItem
                                                                    style = {{backgroundColor : "inherit"}}
                                                                    button key={menuItem}
                                                                >
                                                                    <ListItemIcon>
                                                                        <i className="material-icons">{roleMenuitems[menuItem].menu_icon} </i>
                                                                    </ListItemIcon>
                                                                    <ListItemText primary={roleMenuitems[menuItem].label} secondaryTypographyProps={{ style: { fontWeight: 'bold', fontSize: 14 } }} />
                                                                </ListItem>
                                                            </NavLink> :
                                                            <NavLink
                                                                key={menuItem}
                                                                className="navBarTextColor"
                                                                to={roleMenuitems[menuItem].routeDisable ? { javascript: void (0) } : roleMenuitems[menuItem].urlpattern}
                                                                onClick={e => {
                                                                    if (menuItem == 'sg_reports' || menuItem == 'distributor_reports' || menuItem == 'business_reports' || menuItem == 'partner_reports') {
                                                                        console.log(">>>" + reportUrl);
                                                                        let url = reportUrl + '?date=' +this.getToday() +'&token=' + localStorage.token + '&businessId=' + localStorage.lastSelectedBusinessId;
                                                                        window.open(url, "_blank");
                                                                    } else if (menuItem == 'distributor_theme') {
                                                                        this.setState({ themeDialog: true })
                                                                    }
                                                                }}
                                                            >
                                                                <ListItem
                                                                    button key={menuItem}
                                                                >
                                                                    <ListItemIcon>
                                                                        <i className="material-icons">{roleMenuitems[menuItem].menu_icon} </i>
                                                                    </ListItemIcon>
                                                                    <ListItemText primary={roleMenuitems[menuItem].label} secondaryTypographyProps={{ style: { fontWeight: 'bold', fontSize: 14 } }} />
                                                                </ListItem>
                                                            </NavLink>
                                                    }
                                                </div>
                                            )
                                        }
                                    }else{
                                        return (
                                            <div key={menuItem}>
                                                <ListItem
                                                    button
                                                    style = {{backgroundColor : "inherit"}}
                                                    onClick={this.handleMenuClick}
                                                    key={menuItem}
                                                    style = {this.state.menuOpen ? {backgroundColor : "#cccccc"} : null}
                                                >
                                                    <ListItemIcon>
                                                        <i className="material-icons">{roleMenuitems[menuItem].menu_icon} </i>
                                                    </ListItemIcon>
                                                    <ListItemText primary={roleMenuitems[menuItem].label} secondaryTypographyProps={{ style: { fontWeight: 'bold', fontSize: 14 } }} />
                                                    {this.state.menuOpen ? <ExpandLess /> : <ExpandMore />}
                                                </ListItem>
                                                <Collapse in={this.state.menuOpen} timeout="auto" unmountOnExit style = {{backgroundColor : "#cccccc"}}>
                                                    {
                                                        Object.keys(roleMenuitems[menuItem].submenu).map(subMenuItem => {
                                                            if(roleMenuitems[menuItem].submenu[subMenuItem].label !== ''){
                                                                return(
                                                                    <NavLink
                                                                        key={menuItem}
                                                                        style={{ textDecoration: 'none' }}
                                                                        to={roleMenuitems[menuItem].submenu[subMenuItem].urlpattern}
                                                                        activeStyle = {{backgroundColor: "#cccccc"}}
                                                                        key = {subMenuItem}
                                                                    >
                                                                        <ListItem
                                                                            style = {{backgroundColor : "inherit", paddingLeft : "1.5rem"}}
                                                                            button key={menuItem}
                                                                        >
                                                                            <ListItemIcon>
                                                                                <i className="material-icons">{roleMenuitems[menuItem].submenu[subMenuItem].menu_icon} </i>
                                                                            </ListItemIcon>
                                                                            <ListItemText primary={roleMenuitems[menuItem].submenu[subMenuItem].label} secondaryTypographyProps={{ style: { fontWeight: 'bold', fontSize: 14 } }} />
                                                                        </ListItem>
                                                                    </NavLink>
                                                                )
                                                            }
                                                        })
                                                    }
                                                </Collapse>
                                            </div>
                                        )
                                    }
                            }) : null
                    }
                </List>


            </div>
        );

        return (
            <div>
                <nav className={classes.drawer}>
                    <Hidden smUp implementation="css">
                        <Drawer
                            container={this.props.container}
                            variant="temporary"
                            anchor={theme.direction === "rtl" ? "right" : "left"}
                            open={this.props.mobileOpenStatus}
                            onClose={this.props.handleDrawerToggle}
                            classes={{
                                paper: classes.drawerPaper
                            }}
                        >
                            {drawer}
                        </Drawer>
                    </Hidden>
                    <Hidden xsDown implementation="css">
                        <Drawer
                            classes={{
                                paper: classes.drawerPaper
                            }}
                            variant="permanent"
                            open
                        >
                            {drawer}
                        </Drawer>
                    </Hidden>
                </nav>
                {themeDialogWidget}
                <Dialog  disableBackdropClick disableEscapeKeyDown open={this.state.toolkitOpen} onClose={this.handleDialogClose}>
                    <DialogTitle>NHVR Toolkit</DialogTitle>
                    <DialogContent style={{ width:320 }}>
                        <form>
                        <FormControl>
                        <InputLabel htmlFor="fatigue_type">Fatigue Type</InputLabel>
                        <Select
                            style={{ width:270 }}
                            value={this.state.fatigue_type}
                            onChange={(e)=>this.handleDialogChange('fatigue_type',e)}
                            input={<Input id="fatigue_type" />}
                            autoWidth={true}
                        >
                            <MenuItem style={{ width:270 }} value="">
                            <em>None</em>
                            </MenuItem>
                            <MenuItem value={'smf'}>smf</MenuItem>
                            <MenuItem value={'bfm'}>bfm</MenuItem>
                            <MenuItem value={'afm'}>afm</MenuItem>
                        </Select>
                        </FormControl><br/><br/>
                        <FormControl>
                        <InputLabel htmlFor="solo_or_twoup">Solo Or Twoup</InputLabel>
                        <Select
                            style={{ width:270 }}
                            value={this.state.solo_or_twoup}
                            onChange={(e)=>this.handleDialogChange('solo_or_twoup',e)}
                            input={<Input id="solo_or_twoup" />}
                        >
                            <MenuItem style={{ width:270 }} value="">
                            <em>None</em>
                            </MenuItem>
                            <MenuItem value={'solo'}>solo</MenuItem>
                            <MenuItem value={'twoup'}>twoup</MenuItem>
                        </Select>
                        </FormControl><br/><br/>
                        <FormControl>
                        <InputLabel htmlFor="vehicle_type">Vehicle Type</InputLabel>
                        <Select
                            style={{ width:270 }}
                            value={this.state.vehicle_type}
                            onChange={(e)=>this.handleDialogChange('vehicle_type',e)}
                            input={<Input id="vehicle_type" />}
                        >
                            <MenuItem style={{ width:270 }} value="">
                            <em>None</em>
                            </MenuItem>
                            <MenuItem value={'heavy vehicle'}>heavy vehicle</MenuItem>
                            <MenuItem value={'bus'}>bus</MenuItem>
                        </Select>
                        </FormControl><br/><br/>
                        <FormControl>
                        <InputLabel htmlFor="time_duration">Time Duration</InputLabel>
                        <Select
                            style={{ width:270 }}
                            value={this.state.time_duration}
                            onChange={(e)=>this.handleDialogChange('time_duration',e)}
                            input={<Input id="time_duration" />}
                        >
                            <MenuItem style={{ width:270 }} value="">
                            <em>None</em>
                            </MenuItem>
                            <MenuItem value={'14'}>14</MenuItem>
                            <MenuItem value={'7'}>7</MenuItem>
                        </Select>
                        </FormControl><br/><br/>
                        <FormControl>
                        <InputLabel htmlFor="breach_type">Breach Type</InputLabel>
                        <Select
                            style={{ width:270 }}
                            value={this.state.breach_type}
                            onChange={(e)=>this.handleDialogChange('breach_type',e)}
                            input={<Input id="breach_type" />}
                        >
                            <MenuItem style={{ width:270 }} value="">
                            <em>None</em>
                            </MenuItem>
                            <MenuItem value={'Critical Rest (night rest not consecutive)'}>Critical Rest (night rest not consecutive)</MenuItem>
                        </Select>
                        </FormControl>
                    </form>
                    </DialogContent>
                    <DialogActions>
                    <Button onClick={this.handleDialogClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={this.handleDialogClose} color="primary">
                        Ok
                    </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}
Sidebar.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired
};

const mapStateToProps = state => {
    return {
        sidebarState: state.sidebarState
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getMenuItems: () => {
            return dispatch(getMenuItems())
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, { withTheme: true })(withRouter(Sidebar)));
