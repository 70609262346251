import axios from 'axios';
import {apiUrl, xAPIKey} from "./config";
const axiosInstance = axios.create({
    baseURL : apiUrl
})
axiosInstance.defaults.headers.common['Authorization'] = localStorage.getItem('token');
axiosInstance.defaults.headers.common['Platform-User'] = localStorage.getItem('Platform-User');
axiosInstance.defaults.headers.common['Content-Type'] = 'application/json';
axiosInstance.defaults.headers.common['x-api-key'] = xAPIKey;

export default axiosInstance;