import React, { Component } from 'react';
import MaterialTable from 'material-table';
import Paper from '@material-ui/core/Paper';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import { withRouter } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import { toast } from 'react-toastify';
import { roles } from '../../../constants/roles';
import { isValidEmail } from '../../../utils/EmailValidator';

import {
    getPartnerUsers,
    updatePartnerUser,
    deletePartnerUser
} from '../../../actions/PartnerUser';

class ListPartnerUsers extends Component{
    
    partnerUserColumns = [
        {
            title: "First Name",
            field: "firstName"
        },
        {
            title: "Last Name",
            field: "lastName"
        },
        {
            title: "Email",
            field: "email"
        },
        {
            title: "Role",
            field: "accesslevel",
            lookup: {
                [roles.PARTNER_EDITOR] : "Partner Editor",
                [roles.PARTNER_REPORTER]: "Partner Reporter"
            }
        }
    ];
    
    
    componentDidMount(){
        this.props.getPartnerUsers();
    }

    navigateToPartnerUser = (url) => {
        this.props.history.push(url);
    }

    render(){
        let {
            partnerUsers,
            apiLoading
        } = this.props.partnerUserState;

        let{
            menuItems
        } = this.props.sidebarState;
        return(
            <div className = "container mt-3 mb-3">
                <Paper>
                    <div className = "d-flex">
                        <div className = "d-flex flex-wrap align-content-center mr-auto">
                            <h4 className = "mt-3 ml-2 text-uppercase">User List</h4>
                        </div>
                        <div className = "d-flex flex-wrap align-content-center">
                            <Button 
                                variant="contained" 
                                color="primary"
                                onClick = {() => this.navigateToPartnerUser(menuItems['partner_user_create'].urlpattern)}
                                className = "mr-2"
                            >
                                <i className="material-icons" style = {{paddingRight : "0.5rem"}}>
                                    add_circle_outline
                                </i>
                                Add User
                            </Button>
                        </div>
                    </div>
                    {
                        apiLoading ? 
                        <div style = {{height : "30rem"}} className = "d-flex justify-content-center align-items-center">
                            <CircularProgress color = "primary" />
                        </div>
                         :
                        <MaterialTable
                            localization={{
                                body: {
                                    editRow: {
                                        deleteText: 'Are you sure you want to delete this user?'
                                    },
                                    editTooltip : "Edit",
                                    deleteTooltip : "Delete",
                                }
                            }}
                            columns={this.partnerUserColumns}
                            data={partnerUsers}
                            title=""
                            editable={{
                            onRowUpdate: (newData, oldData) =>
                                new Promise((resolve, reject) => {
                                    let isValid = true;
                                    for (let field of this.partnerUserColumns.map(x => x.field)) {
                                        if ((field === 'email' && !isValidEmail(newData.email )) || !newData[field]){
                                            toast.error("All fields are required, email must be valid", {containerId: 'toastMsg'});
                                            isValid = false;
                                        }
                                    }

                                    if (isValid){
                                        resolve(this.props.updatePartnerUser(newData));
                                    } else {
                                        reject();
                                    }
                                }),
                            }}
                        />
                    }
                </Paper>

            </div>
        )
    }
}

const mapStateToProps = state => {
    return{
        partnerUserState : state.partnerUser,
        sidebarState : state.sidebarState
    }
}
const mapDispatchToProps = dispatch => {
    return{
        getPartnerUsers : () => {
            return dispatch(getPartnerUsers());
        },
        updatePartnerUser : (rowData, email, partnerUsers) => {
            rowData.accesslevel = Number(rowData.accesslevel);
            return dispatch(updatePartnerUser(rowData, email, partnerUsers));
        },
        deletePartnerUser : (rowData, email, partnerUsers) => {
            return dispatch(deletePartnerUser(rowData, email, partnerUsers));
        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ListPartnerUsers));
