import React, { Component } from "react";
import Appbar from "../components/Header/Appbar";
import Sidebar from "../components/Header/Sidebar";
class Header extends Component {
  constructor(props) {
		super(props);
		this.state={
			mobileOpen: false
		}
	}
  handleDrawerToggle = () => {
    this.setState(state => ({ mobileOpen: !state.mobileOpen }));
  };
  render() {
    return (
      <div>
        <Appbar handleDrawerToggle={() => this.handleDrawerToggle()} />
        <Sidebar
          themeColor={this.props.themeColor}
          mobileOpenStatus={this.state.mobileOpen}
          handleDrawerToggle={() => this.handleDrawerToggle()}
        />
      </div>
    );
  }
}

export default Header;