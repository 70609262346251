import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { connect } from 'react-redux';
import {
	Route,
  Switch,
  withRouter
} from 'react-router-dom';

import SearchDriver from "./Driver/SearchDriver";
import AddDriver from "./Driver/AddDriver";
import AddExternalSystem from "./ExternalSystems/AddExternalSystem";
import ListExternalSystems from "./ExternalSystems/ListExternalSystems";
import ListMaillogs from "./Maillogs/ListMaillogs";
import AddSGAdminUser from "./SGAdminUsers/AddSGAdminUser";
import ListSGAdminUsers from "./SGAdminUsers/ListSGAdminUsers";
import DriverList from "./Driver/DriverList";
import UploadDriver from "./Driver/DriverBulkUpload";
import ListVehicle from "./Vehicle/ListVehicle";
import AddVehicle from "./Vehicle/AddVehicle";
import UploadVehicle from "./Vehicle/BulkUploadVehicle";
import ListEntryTypes from "./EntryTypes/ListEntryTypes";
import AddEntryTypes from "./EntryTypes/AddEntryTypes";
import ListAccessLevel from './AccessLevel/ListAccessLevel';
import AddAccessLevel from './AccessLevel/AddAccessLevel';
import ListMassEvent from "./AusMassEvent/ListMassEvent";
import AddMassEvent from "./AusMassEvent/AddMassEvent";
import MassSetup from "./MassSetup/MassSetup.js";
import Combination from "./MassSetup/Combination.js";
import AFM from "./AFM/AddAFM";
import BFM from "./BFM/UploadBFM";
import SFM from "./SFM/UploadSFM";
import ContractSetup from "./Contract/ContractSetup"
import ListAxelConfig from './AxelConfig/ListAxelConfig';
import AddAxelConfig from './AxelConfig/AddAxelConfig';

import ListMassCertificate from './MassCertificate/ListCertificates';
import AddMassCertificate from './MassCertificate/AddMassCertificate'
import ListDistributor from "./Distributor/DistributorAdmin/ListDistributor";
import AddDistributorAdmin from "./Distributor/DistributorAdmin/AddDistributor/index";
import ListDistributorUser from "../containers/Distributor/DistributorUser/ListDistributorUser";
import AddDistributorUser from "../containers/Distributor/DistributorUser/AddDistributorUser";
import UploadDistributorUser from "./Distributor/DistributorUser/DistributorBulkUpload";

import ListPartner from './Partner/PartnerAdmin/ListPartner';
import AddPartnerAdmin from './Partner/PartnerAdmin/AddPartnerAdmin';

import ListPartnerUsers from './Partner/PartnerUser/ListPartnerUser';
import AddPartnerUsers from './Partner/PartnerUser/AddPartnerUser';

import SearchBusiness from "./Business/BusinessAdmin/SearchBusiness";
import AddBusinessAdmin from "./Business/BusinessAdmin/AddBusiness";
import ListBusiness from "./Business/BusinessAdmin/ListBusiness";
import AddBusinessUser from "./Business/BusinessUser/AddBusinessUser";
import ListBusinessUser from "./Business/BusinessUser/ListBusinessUser";
import UploadBusinessUser from "./Business/BusinessUser/BusinessBulkUpload";

import ListMedicalTreatment from "../containers/MedicalTreatment/ListMedicalTreatments";
import AddMedicalTreatment from "../containers/MedicalTreatment/AddMedicalTreatment";

import ListExternalDriver from "../containers/ExternalDriver/ListExternalDrivers";
import AddExternalDriver from "../containers/ExternalDriver/AddExternalDriver";

import ListFatigueRule from './FatigueRule/ListFatigueRule';
import AddFatigueRule from './FatigueRule/AddFatigueRule';

import ListMeasurementTypes from "./MeasurementTypes/ListMeasurementTypes";
import AddMeasurementTypes from "./MeasurementTypes/AddMeasurementTypes";

import ListMassVariableTypes from "./MassVariableTypes/ListMassVariableTypes";
import AddMassVariableTypes from "./MassVariableTypes/AddMassVariableTypes";

import ListLoadTypes from "./LoadTypes/ListLoadTypes";
import AddLoadTypes from "./LoadTypes/AddLoadTypes";

import ListVehicleTypes from "./VehicleTypes/ListVehicleTypes";
import AddVehicleTypes from "./VehicleTypes/AddVehicleTypes";
import Profile from "./Profile";
import AdminProfile from "./Profile/AdminProfile";
import EditProfile from "./Profile/EditProfileDetails";
import ChangePassword from "./Profile/ChangePassword";
import ListTemplate from "./Checklist/Template";
import ListChecklistItem from "./Checklist/CheckItem/ListChecklistItem";
import AddChecklistItem from "./Checklist/CheckItem/AddChecklistItem";
import AddTemplate from "./Checklist/Template/AddTemplate";
import Vehicle from "./Checklist/AssignChecklist/Vehicles";
import CreateChecklist from "./Checklist/AssignChecklist/CreateChecklist";
import ListAssignedChecklist from "./Checklist/AssignChecklist/ListAssignedChecklist";
import NotificationSetting from "./Checklist/Notification Setting";
import PotentialNonComplianceList from "./PotentialNonCompliance/PotentialNonComplianceList";

import NHVRToolkit from "./NHVRToolkit/NHVRToolkit"
import { roles } from "../constants/roles";
import DepotList from "./Depot/DepotList";
import AddDepot from "./Depot/AddDepot";

const styles = theme => ({
  root: {
    display: "flex"
  },
  content: {
    flexGrow: 1,
    [theme.breakpoints.up("sm")]: {
      paddingLeft: 270
    },
    paddingTop: 80
  }
});
class Appbody extends Component {
  
  getProfileEditRoute(menuItems) {

    if (!menuItems['edit_profile']){
      return null;
    }
    
    const role = localStorage.getItem('role');
    switch (role) {
      
      case roles.PARTNER_ADMIN:
        return <Route exact path = {menuItems['edit_profile'].urlpattern} component = {AddPartnerAdmin}/>
        
      case roles.DISTRIBUTOR_ADMIN:
        return <Route exact path = {menuItems['edit_profile'].urlpattern} component = {AddDistributorAdmin}/>

      case roles.BUSINESS_ADMIN:
      case roles.DEPOT_MANAGER:
        return <Route exact path = {menuItems['edit_profile'].urlpattern} component = {AddBusinessAdmin}/>

      default:
        return menuItems['edit_profile'] ? <Route exact path = {menuItems['edit_profile'].urlpattern} component = {EditProfile}/> : null
    }
  }
  
  
  
  render() {
    const { classes } = this.props;
    let{
      menuItems
    } = this.props.sidebarState;
    
    
    const profileEdit = this.getProfileEditRoute(menuItems);
    
    return (
      <main className={classes.content}>
        <div className={classes.toolbar} />
        {
          (Object.keys(menuItems)).length > 0 ?
					<Switch>
          {
            menuItems['partner_list'] != undefined ?
            <Route exact path = {menuItems['partner_list'].urlpattern} component = {ListPartner}/>
            :null
          }
          {
            menuItems['partner_create'] != undefined ?
            <Route exact path = {menuItems['partner_create'].urlpattern} component = {AddPartnerAdmin}/>
            :null
          }
          {
            menuItems['partner_edit'] != undefined ?
            <Route exact path = {menuItems['partner_edit'].urlpattern} component = {AddPartnerAdmin}/>
            :null
          }
          {
            menuItems['partner_user_list'] != undefined ?
            <Route exact path = {menuItems['partner_user_list'].urlpattern} component = {ListPartnerUsers}/>
            :null
          }
          {
            menuItems['partner_user_create'] != undefined ?
            <Route exact path = {menuItems['partner_user_create'].urlpattern} component = {AddPartnerUsers}/>
            :null
          }
          {
            menuItems['business_checklist'] ?
              menuItems['business_checklist'].submenu != undefined ?
                <Route exact path = {menuItems['business_checklist'].submenu['template_list'].urlpattern} component = {ListTemplate}/>
                :
                null
            :null
          }
          {
              menuItems['business_checklist'] ?
                menuItems['business_checklist'].submenu != undefined ?
                <Route exact path = {menuItems['business_checklist'].submenu['template_create'].urlpattern} component = {AddTemplate}/>
                :null
              :null
          }
          {
            menuItems['business_checklist'] ?
              menuItems['business_checklist'].submenu != undefined ?
              <Route exact path = {menuItems['business_checklist'].submenu['assign_checklist'].urlpattern} component = {Vehicle}/>
              :null
            :null
          }
          {
            menuItems['business_checklist'] ?
              menuItems['business_checklist'].submenu != undefined ?
              <Route exact path = {menuItems['business_checklist'].submenu['assigned_checklitem_list'].urlpattern} component = {ListAssignedChecklist}/>
              :null
            :null
          }
          {
            menuItems['business_checklist'] ?
              menuItems['business_checklist'].submenu != undefined ?
              <Route exact path = {menuItems['business_checklist'].submenu['create_checklist'].urlpattern} component = {CreateChecklist}/>
              :null
            :null
          }
          {
            menuItems['business_checklist'] ?
              menuItems['business_checklist'].submenu != undefined ?
              <Route exact path = {menuItems['business_checklist'].submenu['list_checklist_items'].urlpattern} component = {ListChecklistItem}/>
              :null
            :null
          }
          {
            menuItems['business_checklist'] ?
              menuItems['business_checklist'].submenu != undefined ?
              <Route exact path = {menuItems['business_checklist'].submenu['create_checklist_items'].urlpattern} component = {AddChecklistItem}/>
              :null
            :null
          }
          {
            menuItems['business_checklist'] ?
              menuItems['business_checklist'].submenu != undefined ?
              <Route exact path = {menuItems['business_checklist'].submenu['edit_checklist'].urlpattern} component = {CreateChecklist}/>
              :null
            :null
          }
          {
            menuItems['business_checklist'] ?
              menuItems['business_checklist'].submenu != undefined ?
              <Route exact path = {menuItems['business_checklist'].submenu['notification_setting'].urlpattern} component = {NotificationSetting}/>
              :null
            :null
          }
          {
            menuItems['distributor_edit'] != undefined ?
            <Route exact path = {menuItems['distributor_edit'].urlpattern} component = {AddDistributorAdmin}/>
            :null
          }
          {
            menuItems['change_password'] != undefined ?
            <Route exact path = {menuItems['change_password'].urlpattern} component = {ChangePassword}/>
            :null
          }
          {
            menuItems['my_profile'] != undefined ?
            <Route exact path = {menuItems['my_profile'].urlpattern} component = {Profile}/>
            :null
          }
          {
            menuItems['sgadmin_profile'] != undefined ? <Route exact path = {menuItems['sgadmin_profile'].urlpattern} component = {AdminProfile}/> : null
          }
          {
            profileEdit
          }
          {
            menuItems['distributor_list'] != undefined ?
            <Route exact path = {menuItems['distributor_list'].urlpattern} component = {ListDistributor}/>
            :null
          }
          {
            menuItems['distributor_create'] != undefined ?
            <Route exact path = {menuItems['distributor_create'].urlpattern} component = {AddDistributorAdmin}/>
            :null
          }
          {
            menuItems['measurement_types_list'] != undefined ?
            <Route exact path = {menuItems['measurement_types_list'].urlpattern} component = {ListMeasurementTypes}/>
            :null
          }
          {
            menuItems['measurement_types_create'] != undefined ?
            <Route exact path = {menuItems['measurement_types_create'].urlpattern} component = {AddMeasurementTypes}/>
            :null
          }
          {
            menuItems['mass_variable_types_list'] != undefined ?
            <Route exact path = {menuItems['mass_variable_types_list'].urlpattern} component = {ListMassVariableTypes}/>
            :null
          }
          {
            menuItems['mass_variable_types_create'] != undefined ?
            <Route exact path = {menuItems['mass_variable_types_create'].urlpattern} component = {AddMassVariableTypes}/>
            :null
          }
          {
            menuItems['vehicle_types_list'] != undefined ?
            <Route exact path = {menuItems['vehicle_types_list'].urlpattern} component = {ListVehicleTypes}/>
            :null
          }
          {
            menuItems['vehicle_types_create'] != undefined ?
            <Route exact path = {menuItems['vehicle_types_create'].urlpattern} component = {AddVehicleTypes}/>
            :null
          }
          {
            menuItems['load_types_list'] != undefined ?
            <Route exact path = {menuItems['load_types_list'].urlpattern} component = {ListLoadTypes}/>
            :null
          }
          {
            menuItems['load_types_create'] != undefined ?
            <Route exact path = {menuItems['load_types_create'].urlpattern} component = {AddLoadTypes}/>
            :null
          }
          {
            menuItems['contract_setup'] != undefined ?
            <Route exact path = {menuItems['contract_setup'].urlpattern} component = {ContractSetup}/>
            :null
          }
          {
            menuItems['mass_certificate_list'] != undefined ?
            <Route exact path = {menuItems['mass_certificate_list'].urlpattern} component = {ListMassCertificate}/>
            :null
          }
          {
            menuItems['mass_certificate_create'] != undefined ?
            <Route exact path = {menuItems['mass_certificate_create'].urlpattern} component = {AddMassCertificate}/>
            :null
          }
          {
            menuItems['axel_config_list'] != undefined ?
            <Route exact path = {menuItems['axel_config_list'].urlpattern} component = {ListAxelConfig}/>
            :null
          }
          {
            menuItems['axel_config_create'] != undefined ?
            <Route exact path = {menuItems['axel_config_create'].urlpattern} component = {AddAxelConfig}/>
            :null
          }
          {
            menuItems['aus_massevents_list'] != undefined ?
            <Route exact path = {menuItems['aus_massevents_list'].urlpattern} component = {ListMassEvent}/>
            :null
          }
          {
            menuItems['aus_massevents_create'] != undefined ?
            <Route exact path = {menuItems['aus_massevents_create'].urlpattern} component = {AddMassEvent}/>
            :null
          }
          {
            menuItems['fatigue_rules_list'] != undefined ?
            <Route exact path = {menuItems['fatigue_rules_list'].urlpattern} component = {ListFatigueRule}/>
            :null
          }
          {
            menuItems['fatigue_rules_create'] != undefined ?
            <Route exact path = {menuItems['fatigue_rules_create'].urlpattern} component = {AddFatigueRule}/>
            :null
          }
          {
            menuItems['afm_upload'] != undefined ?
            <Route exact path = {menuItems['afm_upload'].urlpattern} component = {AFM}/>
            :null
          }
          {
            menuItems['bfm_upload'] != undefined ?
            <Route exact path = {menuItems['bfm_upload'].urlpattern} component = {BFM}/>
            :null
          }
          {
            menuItems['sfm_upload'] != undefined ?
            <Route exact path = {menuItems['sfm_upload'].urlpattern} component = {SFM}/>
            :null
          }
          {
            menuItems['access_level_list'] != undefined ?
            <Route exact path = {menuItems['access_level_list'].urlpattern} component = {ListAccessLevel}/>
            :null
          }
          {
            menuItems['access_level_create'] != undefined ?
            <Route exact path = {menuItems['access_level_create'].urlpattern} component = {AddAccessLevel}/>
            :null
          }
          {
            menuItems['entry_type_list'] != undefined ?
            <Route exact path = {menuItems['entry_type_list'].urlpattern} component = {ListEntryTypes}/>
            :null
          }
          {
            menuItems['entry_types_create'] != undefined ?
            <Route exact path = {menuItems['entry_types_create'].urlpattern} component = {AddEntryTypes}/>
            :null
          }
          {
            menuItems['business_list'] != undefined ?
            <Route exact path = {menuItems['business_list'].urlpattern} component = {ListBusiness}/>
            :null
          }
          {
            menuItems['driver_list'] != undefined ?
            <Route exact path = {menuItems['driver_list'].urlpattern} component = {DriverList}/>
            :null
          }
          {
            menuItems['depots'] != undefined ?
            <Route exact path = {menuItems['depots'].urlpattern} component = {DepotList}/>
            :null
          }
          {
            menuItems['depot_create'] != undefined ?
                <Route exact path = {menuItems['depot_create'].urlpattern} component = {AddDepot}/> :null
          }
          {
            menuItems['driver_search'] != undefined ?
            <Route exact path = {menuItems['driver_search'].urlpattern} component = {SearchDriver}/>
            :null
          }
          {
            menuItems['driver_create'] != undefined ?
            <Route exact path = {menuItems['driver_create'].urlpattern} component = {AddDriver}/>
            :null
          }
          {
            menuItems['external_sys_create'] != undefined ?
            <Route exact path = {menuItems['external_sys_create'].urlpattern} component = {AddExternalSystem}/>
            :null
          }
          {
            menuItems['external_sys_list'] != undefined ?
            <Route exact path = {menuItems['external_sys_list'].urlpattern} component = {ListExternalSystems}/>
            :null
          }
          {
            menuItems['sgadminuser_create'] != undefined ?
            <Route exact path = {menuItems['sgadminuser_create'].urlpattern} component = {AddSGAdminUser}/>
            :null
          }
          {
            menuItems['sgadminuser_list'] != undefined ?
            <Route exact path = {menuItems['sgadminuser_list'].urlpattern} component = {ListSGAdminUsers}/>
            :null
          }
          {
            menuItems['maillog_list'] != undefined ?
            <Route exact path = {menuItems['maillog_list'].urlpattern} component = {ListMaillogs}/>
            :null
          }
          {
            menuItems['driver_upload'] != undefined ?
            <Route exact path = {menuItems['driver_upload'].urlpattern} component = {UploadDriver}/>
            :null
          }
          {
            menuItems['vehicle_list'] != undefined ?
            <Route exact path = {menuItems['vehicle_list'].urlpattern} component = {ListVehicle}/>
            :null
          }
          {
            menuItems['vehicle_create'] != undefined ?
            <Route exact path = {menuItems['vehicle_create'].urlpattern} component = {AddVehicle}/>
            :null
          }

          {
            menuItems['vehicle_upload'] != undefined ?
            <Route exact path = {menuItems['vehicle_upload'].urlpattern} component = {UploadVehicle}/>
            :null
          }
          {
            menuItems['medical_treatment_list'] != undefined ?
            <Route exact path = {menuItems['medical_treatment_list'].urlpattern} component = {ListMedicalTreatment}/>
            :null
          }
          {
            menuItems['medical_treatment_add'] != undefined ?
            <Route exact path = {menuItems['medical_treatment_add'].urlpattern} component = {AddMedicalTreatment}/>
            :null
          }
          {
            menuItems['medical_treatment'] != undefined ?
            <Route exact path = {menuItems['medical_treatment'].urlpattern} component = {DriverList}/>
            :null
          }
          {
            menuItems['external_driver'] != undefined ?
            <Route exact path = {menuItems['external_driver'].urlpattern} component = {DriverList}/>
            :null
          }
          {
            menuItems['external_driver_list'] != undefined ?
            <Route exact path = {menuItems['external_driver_list'].urlpattern} component = {ListExternalDriver}/>
            :null
          }
          {
            menuItems['external_driver_add'] != undefined ?
            <Route exact path = {menuItems['external_driver_add'].urlpattern} component = {AddExternalDriver}/>
            :null
          }
          {
            menuItems['business_search'] != undefined ?
            <Route exact path = {menuItems['business_search'].urlpattern} component = {SearchBusiness}/>
            :null
          }
          {
            menuItems['business_create'] != undefined ?
            <Route exact path = {menuItems['business_create'].urlpattern} component = {AddBusinessAdmin}/>
            :null
          }
          {

              menuItems['business_edit'] != undefined ?
              <Route exact path = {menuItems['business_edit'].urlpattern} component = {AddBusinessAdmin}/>
              :null

          }

          {
            menuItems['business_user_list'] != undefined ?
            <Route exact path = {menuItems['business_user_list'].urlpattern} component = {ListBusinessUser}/>
            :null
          }
          {
            menuItems['business_user_upload'] != undefined ?
            <Route exact path = {menuItems['business_user_upload'].urlpattern} component = {UploadBusinessUser}/>
            :null
          }
          {
            menuItems['business_user_create'] != undefined ?
            <Route exact path = {menuItems['business_user_create'].urlpattern} component = {AddBusinessUser}/>
            :null
          }
          {
            menuItems['distributor_user_create'] != undefined ?
            <Route exact path = {menuItems['distributor_user_create'].urlpattern} component = {AddDistributorUser}/>
            :null
          }
          {
            menuItems['distributor_user_list'] != undefined ?
            <Route exact path = {menuItems['distributor_user_list'].urlpattern} component = {ListDistributorUser}/>
            :null
          }
          {
            menuItems['distributor_user_upload'] != undefined ?
            <Route exact path = {menuItems['distributor_user_upload'].urlpattern} component = {UploadDistributorUser}/>
            :null
          }
          {
            menuItems['nhvr_compliance_list'] != undefined ?
            <Route exact path = {menuItems['nhvr_compliance_list'].urlpattern} component = {NHVRToolkit}/>
            :null
          }
          {
            menuItems['business_mass_setup'] != undefined ?
            <Route exact path = {menuItems['business_mass_setup'].urlpattern} component = {MassSetup}/>
            :null
          }
          {
            menuItems['business_combination_add'] != undefined ?
            <Route exact path = {menuItems['business_combination_add'].urlpattern} component = {Combination}/>
            :null
          }
          {
            menuItems['business_combination_edit'] != undefined ?
            <Route exact path = {menuItems['business_combination_edit'].urlpattern} component = {Combination}/>
            :null
          }
          {
            menuItems['potentialNonCompliance_list'] != undefined ?
            <Route exact path = {menuItems['potentialNonCompliance_list'].urlpattern} component = {PotentialNonComplianceList}/>
            :null
          }
					</Switch>
          : null
        }
      </main>
    );
  }
}
Appbody.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return{
    sidebarState : state.sidebarState
  }
}

export default withRouter(connect(mapStateToProps, null)(withStyles(styles, { withTheme: true })(Appbody)));
