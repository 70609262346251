import React, { Component } from "react";
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import axios from '../../axios';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { toast } from 'react-toastify';
import CircularProgress from '@material-ui/core/CircularProgress';

import { convertToJSON } from "../../actions/Common";

const fileClick = () => {
    document.getElementById('outlined-button-file').click()
}

class NHVRToolkit extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading:false,
            mobileOpen: false,
            anchorEl: null,
            themeDialog: false,
            toolkitOpen: true,
            fatigue_type: '',
            solo_or_twoup: '',
            vehicle_type: '',
            time_duration: '',
            breach_type: '',
            upload_ewd_file: ''
        }
    }

    handleDialogChange = (name, event) => {
        console.log('event', name, event.target)
        if (event.target.files != null) {
            let upload_ewd_file = (event.target.value).replace(/^.*\\/, "");
            convertToJSON(event.target.files[0]).then(data => {
                console.log('data', data, upload_ewd_file)
                this.setState({ upload_ewd_file: upload_ewd_file, breach_doc: data })
            })
                .catch(error => {
                })
        } else {
            this.setState({ [name]: event.target.value })
        }
    }

    submitEWDTestFile = () => {
        this.setState({loading:true})
        let nhvrJSON = {
            fatigue_type: this.state.fatigue_type,
            solo_or_twoup: this.state.solo_or_twoup,
            vehicle_type: this.state.vehicle_type,
            time_duration: this.state.time_duration,
            breach_type: this.state.breach_type,
            breach_doc: this.state.breach_doc
        }
        axios.post('/breach', nhvrJSON)
          .then(response => {
            console.log('response',response)
            this.setState({loading:false})
            toast.success("NHVR Document Uploaded Successfully", {
                containerId: 'toastMsg',
            })
          })
          .catch(error => {
            console.log('error',error)
            this.setState({loading:false})
            toast.error("Failed to Upload NHVR Document", {
                containerId: 'toastMsg',
            })
          })
    }

    handleDialogClose = () => {
        this.setState({ toolkitOpen: false })
    }

    render() {
        console.log(this.state)
        return (
            <div>
                <Grid container spacing={24}>
                    <Grid item xs={1} sm={2} />
                    <Grid item xs={11} sm={8}>
                        <Card className="pl-3 pr-3 pt-3">
                            <CardHeader
                                title="Add Fatigue Rule Group"
                            />
                            <CardContent>
                                <FormControl fullWidth={true}>
                                    <InputLabel htmlFor="fatigue_type">Fatigue Type</InputLabel>
                                    <Select
                                        value={this.state.fatigue_type}
                                        onChange={(e) => this.handleDialogChange('fatigue_type', e)}
                                        input={<Input id="fatigue_type" />}
                                        fullWidth={true}
                                    >
                                        <MenuItem value="">
                                            <em>None</em>
                                        </MenuItem>
                                        <MenuItem value={'STANDARD'}>STANDARD</MenuItem>
                                        <MenuItem value={'BFM'}>BFM</MenuItem>
                                        <MenuItem value={'AFM'}>AFM</MenuItem>
                                    </Select>
                                </FormControl><br /><br />
                                <FormControl fullWidth={true}>
                                    <InputLabel htmlFor="solo_or_twoup">Solo Or Twoup</InputLabel>
                                    <Select
                                        fullWidth={true}
                                        value={this.state.solo_or_twoup}
                                        onChange={(e) => this.handleDialogChange('solo_or_twoup', e)}
                                        input={<Input id="solo_or_twoup" />}
                                    >
                                        <MenuItem value="">
                                            <em>None</em>
                                        </MenuItem>
                                        <MenuItem value={'Solo'}>Solo</MenuItem>
                                        <MenuItem value={'Twoup'}>Twoup</MenuItem>
                                    </Select>
                                </FormControl><br /><br />
                                <FormControl fullWidth={true}>
                                    <InputLabel htmlFor="vehicle_type">Vehicle Type</InputLabel>
                                    <Select
                                        fullWidth={true}
                                        value={this.state.vehicle_type}
                                        onChange={(e) => this.handleDialogChange('vehicle_type', e)}
                                        input={<Input id="vehicle_type" />}
                                    >
                                        <MenuItem value="">
                                            <em>None</em>
                                        </MenuItem>
                                        <MenuItem value={'Heavy Vehicle'}>Heavy Vehicle</MenuItem>
                                        <MenuItem value={'Bus'}>Bus</MenuItem>
                                    </Select>
                                </FormControl><br /><br />
                                <FormControl fullWidth={true}>
                                    <InputLabel htmlFor="time_duration">Time Duration</InputLabel>
                                    <Select
                                        fullWidth={true}
                                        value={this.state.time_duration}
                                        onChange={(e) => this.handleDialogChange('time_duration', e)}
                                        input={<Input id="time_duration" />}
                                    >
                                        <MenuItem value="">
                                            <em>None</em>
                                        </MenuItem>
                                        <MenuItem value={'8 Hours'}>8 Hours</MenuItem>
                                        <MenuItem value={'9 Hours'}>9 Hours</MenuItem>
                                        <MenuItem value={'11 Hours'}>11 Hours</MenuItem>
                                        <MenuItem value={'12 Hours'}>12 Hours</MenuItem>
                                        <MenuItem value={'24 Hours'}>24 Hours</MenuItem>
                                        <MenuItem value={'52 Hours'}>52 Hours</MenuItem>
                                        <MenuItem value={'82 Hours'}>82 Hours</MenuItem>
                                        <MenuItem value={'530 Hours'}>530 Hours</MenuItem>
                                        <MenuItem value={'615 Hours'}>615 Hours</MenuItem>
                                        <MenuItem value={'7 Days'}>7 Days</MenuItem>
                                        <MenuItem value={'14 Days'}>14 Days</MenuItem>
                                        <MenuItem value={'28 Days'}>28 Days</MenuItem>
                                    </Select>
                                </FormControl><br /><br />
                                <FormControl fullWidth={true}>
                                    <InputLabel htmlFor="breach_type">Breach Type</InputLabel>
                                    <Select
                                        fullWidth={true}
                                        value={this.state.breach_type}
                                        onChange={(e) => this.handleDialogChange('breach_type', e)}
                                        input={<Input id="breach_type" />}
                                    >
                                        <MenuItem value="">
                                            <em>None</em>
                                        </MenuItem>
                                        <MenuItem value={'NoBreach'}>NoBreach</MenuItem>
                                        <MenuItem value={'Minor Work'}>Minor Work</MenuItem>
                                        <MenuItem value={'Substantial Work'}>Substantial Work</MenuItem>
                                        <MenuItem value={'Severe Work'}>Severe Work</MenuItem>
                                        <MenuItem value={'Critical Work'}>Critical Work</MenuItem>
                                        <MenuItem value={'Minor Rest'}>Minor Rest</MenuItem>
                                        <MenuItem value={'Substantial Rest'}>Substantial Rest</MenuItem>
                                        <MenuItem value={'Severe Rest'}>Severe Rest</MenuItem>
                                        <MenuItem value={'Critical Rest'}>Critical Rest</MenuItem>
                                        <MenuItem value={'Compliant'}>Compliant</MenuItem>
                                        <MenuItem value={'Compliant Other'}>Compliant Other</MenuItem>
                                    </Select>
                                </FormControl>
                                <FormControl fullWidth={true} style={{ marginTop: 15 }}>
                                    <TextField
                                        value={this.state.upload_ewd_file}
                                        fullWidth={true}
                                        margin="normal"
                                        onClick={() => fileClick()}
                                        placeholder="Upload EWD Test JSON"
                                    />
                                    <input
                                        accept="application/json"
                                        style={{ visibility: "hidden" }}
                                        id="outlined-button-file"
                                        multiple
                                        type="file"
                                        onChange={(e) => this.handleDialogChange('upload_ewd_file', e)}
                                    />
                                </FormControl>
                                <CardActions style={{ float: 'right' }}>
                                    {this.state.loading ? <CircularProgress color = "primary" /> 
                                    :
                                        <Button variant="contained"
                                            color="primary"
                                            className="mb-2"
                                            onClick={this.submitEWDTestFile}>
                                            Submit
                                        </Button>
                                    }
                                </CardActions>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default NHVRToolkit;
