import React, { Component } from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import { connect } from 'react-redux';

import AddPartner from './AddPartner';


const styles = theme => ({
  active_tabStyle: {
    color: 'white',
    fontWeight: 'bold',
    backgroundColor: '#3b3c3c'
  }
});
function TabContainer(props) {
  return (
    <Typography component="div" style={{ padding: 8 * 3 }}>
      {props.children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};
class AddPartnerAdmin extends Component {


  state = {
    isEditPartner: false,
    isEditProfile: false,
    activeTabValue: 0
  }

  componentDidMount() {
    let isEditPartner = (window.location.href).search('editpartner') > 0;
    let isEditProfile = (window.location.href).search('editprofile') > 0;

    this.setState({
      isEditPartner,
      isEditProfile
    })
  }


  render() {

    const { classes } = this.props;

    return (
      <div className="container">
        <AppBar position="static">
          <Tabs
            value={0}
            variant="fullWidth"
          >
            <Tab
              label={this.state.isEditPartner ? "Edit Partner" : this.state.isEditProfile ? "Edit Profile" : "Add Partner"}
              className={classes.active_tabStyle}
            />
          </Tabs>
        </AppBar>
        {
          <AddPartner />
        }
      </div>
    );
  }
}

AddPartner.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
  return {}
}

const mapDispatchToprops = dispatch => {
  return {}
}

export default connect(mapStateToProps, mapDispatchToprops)(withStyles(styles)(AddPartnerAdmin));
