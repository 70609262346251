import React, { Component } from 'react';
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import FormGroup from "@material-ui/core/FormGroup";
import Button from "@material-ui/core/Button";
import CircularProgress from '@material-ui/core/CircularProgress';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import Input from "../../../components/UI/Input/Input";

import {
    handleAddTemplateTextFields,
    onSubmitTemplateCreate,
    initializeAddTemplateForm
} from "../../../actions/Checklist";

class AddTemplate extends Component{
    componentDidMount(){
        this.props.initializeAddTemplateForm();
    }
    render(){
        let{
            addTemplateForm,
            addTemplateLoaderStatus
        } = this.props.checkListState;
        let{
            menuItems
        } = this.props.sidebarState;
        const formElementArray = [];
        for(let key in addTemplateForm){
          formElementArray.push({
            id : key,
            config : addTemplateForm[key]
          })
        }
        let form = (
            formElementArray.map(formElement => (
                    <Grid item xs={12} sm={12}>
                        <Input 
                            key = {formElement.id}
                            elementType={formElement.config.elementType}
                            elementConfig={formElement.config.elementConfig}
                            value={formElement.config.value}
                            error = {formElement.config.errorStatus}
                            helperText = {formElement.config.errorMessage}
                            changed={(event) => this.props.handleAddTemplateTextFields(formElement.id, event)}
                            touched = {(event) => this.props.handleAddTemplateTextFields(formElement.id, event)}
                            maxLength={formElement.config.maxLength}
                        />
                    </Grid>
                    )
                )
            )
        return(
            <Grid container spacing={24}>
            <Grid item xs={1} sm={2} />
                <Grid item xs={10} sm={8}>
                <Card className="pl-3 pr-3 pt-3">
                    <CardHeader title="Add Template" />
                    <CardContent>
                        <FormGroup row>
                            <Grid container spacing={24}>
                                {form}
                            </Grid>
                        </FormGroup>
                        <Grid 
                            container 
                            spacing={24}
                            justify="flex-end"
                            alignItems="center"
                        >
                            <Grid item>
                                {
                                    addTemplateLoaderStatus ? 
                                        <CircularProgress/> :
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            className="mt-4"
                                            onClick = {() => this.props.onSubmitTemplateCreate(addTemplateForm, menuItems['business_checklist'].submenu['template_list'].urlpattern, this.props)}
                                        >
                                            Submit
                                        </Button>
                                }

                            </Grid>

                        </Grid>
                    </CardContent>
                    <CardActions />
                    </Card>
                </Grid>
                <Grid item xs={1} sm={2} />
            </Grid>
        )
    }
}

const mapStateToProps = state => {
    return{
        checkListState : state.checkList,
        sidebarState : state.sidebarState
    }
}

const mapDispatchToProps = dispatch => {
    return{
        handleAddTemplateTextFields : (formFieldId, event) => {
            return dispatch(handleAddTemplateTextFields(formFieldId, event))
        },
        onSubmitTemplateCreate : (addTemplateForm, url, props) => {
            return dispatch(onSubmitTemplateCreate(addTemplateForm, url, props))
        },
        initializeAddTemplateForm : () => {
            return dispatch(initializeAddTemplateForm())
        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(AddTemplate);