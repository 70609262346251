import TextField from "@material-ui/core/TextField";
import React from 'react';

import {
    DISTRIBUTOR_TEXTFIELD_CHANGE,
    SUBMIT_DISTRIBUTOR_INFO,
    UPDATE_DISTRIBUTOR_INFO,
    SET_DEFAULT_VALUE_TO_ALL_FORM_FIELD,
    SET_DISTRIBUTOR_LIST,
    SET_DISTRIBUTOR_USER_INPUT_FIELD,
    SET_SUBMITTED_DISTRIBUTOR_USER,
    SET_DEFAULT_VALUE_TO_ADD_DISTRIBUTOR_USER,
    SET_DISTRIBUTOR_USER_LIST,
    SET_UPDATED_DISTRIBUTOR_USER,
    SET_INPUTHANDLER_UPLOAD_DISTRIBUTOR,
    POST_DISTRIBUTOR_UPLOADED_DATA,
    SET_DEFAULT_VALUE_TO_DISTRIBUTOR_UPLOAD_FORM,
    POST_HANDLER_EDIT_DISTRIBUTOR_DIALOG_OPEN,
    POST_HANDLER_EDIT_DISTRIBUTOR_DIALOG_CLOSE,
    POST_ADD_DISTRIBUTOR_TAB_HANDLE_CHANGE,
    POST_ADD_ADD_DISTRIBUTOR_CONTACT_INFO,
    POST_UPDATE_DISTRIBUTOR_CONTACT_INFO,
    POST_DELETE_ADD_DISTRIBUTOR_CONTACT_INFO,
    POST_ADD_DISTRIBUTOR_CONTACT_NEXT,
    POST_HANDLE_ADD_DISTRIBUTOR_BILLING_FORM_FIELD,
    POST_ADD_DISTRIBUTOR_ADMIN_ON_SUBMIT,
    POST_ADD_DISTRIBUTOR_CONTACT_BACK,
    POST_ADD_DISTRIBUTOR_ADMIN_BILLING_BACK,
    POST_ADD_DISTRIBUTOR_ADMIN_ON_SUBMIT_AFTER_CLOSE_TOASTR,
    POST_GET_DISTRIBUTOR_INFO_BY_ID,
    POST_ADD_DISTRIBUTOR_ADMIN_ON_UPDATE,
    POST_NAVIGATOR_TO_ADD_DISTRIBUTOR,
    POST_NAVIGATE_TO_EDIT_DISTRIBUTOR_ADMIN
} from '../actions/ActionType';

import {
    isObjectEmpty
} from '../utils/Helper';

const initialState = {
    uploadDistributorForms: {
        upload_distributor: {
            elementType: 'upload_csv',
            elementConfig: {
                type: 'text',
                label: 'Upload Reseller User CSV'
            },
            value: '',
            errorStatus: false,
            errorMessage: "Upload Reseller User CSV"
        }
    },

    //Distributor User Form Elements
    distributorUserForm: {
        distributor_user_fname: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                label: 'First Name'
            },
            value: '',
            errorStatus: false,
            errorMessage: "Enter Reseller First Name",
            maxLength: 60
        },
        distributor_user_lname: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                label: 'Last Name'
            },
            value: '',
            errorStatus: false,
            errorMessage: "Enter Reseller Last Name",
            maxLength: 40
        },
        distributor_user_email: {
            elementType: 'input',
            elementConfig: {
                type: 'email',
                label: 'Reseller Email'
            },
            value: '',
            errorStatus: false,
            errorMessage: "Enter a valid email address",
            maxLength: 60
        },
        distributor_user_role: {
            elementType: 'select',
            elementConfig: {
                type: 'text',
                label: 'Reseller Role'
            },
            value: '',
            errorStatus: false,
            errorMessage: "Select Reseller Role",
            menuItems: {
                'select_distributor_role': {
                    name: "Select Reseller Role",
                    value: ''
                },
                'distributor_editor': {
                    name: "Reseller Editor",
                    value: 3
                },
                'distributor_reporter': {
                    name: "Reseller Reporter",
                    value: 4
                },
            }
        }
    },

    billingForm: {
        address_line: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                label: 'Address Line'
            },
            value: '',
            errorStatus: false,
            errorMessage: "Enter Address Line",
            maxLength: 60
        },
        city: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                label: 'City'
            },
            value: '',
            errorStatus: false,
            errorMessage: "Enter City",
            maxLength: 60
        },
        state : {
            elementType : 'select',
            elementConfig : {
                type : 'text',
                label : 'State'
            },
            value : '',
            errorStatus : false,
            errorMessage : "Enter State",
            menuItems : {
                'select_state' : {
                    name : "Select State",
                    value : ''
                },
                'nsw' : {
                    name : "NSW",
                    value : "NSW"
                },
                'qld':{
                    name : "QLD",
                    value : "QLD"
                },
                'sa' : {
                    name : "SA",
                    value : "SA"
                },
                'tas':{
                    name : "TAS",
                    value : "TAS"
                },
                'vic' : {
                    name : "VIC",
                    value : "VIC"
                },
                'act' : {
                    name : 'ACT',
                    value : 'ACT'
                },
                'nt' : {
                    name : 'NT',
                    value : 'NT'
                },
                'wa':{
                    name : "WA",
                    value : "WA"
                },
                'ashmore island':{
                    name : "ASHMORE ISLAND",
                    value : "ASHMORE ISLAND"
                },
                'cartier island':{
                    name : "CARTIER ISLAND",
                    value : "CARTIER ISLAND"
                },
                'christmas island':{
                    name:'CHRISTMAS ISLAND',
                    value:'CHRISTMAS ISLAND'
                },
                'cocos island':{
                    name:'COCOS ISLAND',
                    value:'COCOS ISLAND'
                },
                'coral sea island':{
                    name:'CORAL SEA ISLAND',
                    value:'CORAL SEA ISLAND'
                },
                'heard island':{
                    name:'HEARD ISLAND',
                    value:'HEARD ISLAND'
                },
                'mcdonald island':{
                    name:'MCDONALD ISLAND',
                    value:'MCDONALD ISLAND'
                },
                'oth':{
                    name : "OTH",
                    value : "OTH"
                }
            },
            disabledStatus : false
        },
        postalcode: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                label: 'Postal Code '
            },
            value: '',
            errorStatus: false,
            errorMessage: "Enter Postal Code",
            maxLength: 4
        },
        country: {
            elementType: 'select',
            elementConfig: {
                type: 'text',
                label: 'Country'
            },
            value: 'AUS',
            errorStatus: false,
            errorMessage: "Select Country",
            menuItems: {
                'select_country': {
                    name: "Select Country",
                    value: ''
                },
                'aus': {
                    name: "Australia",
                    value: "AUS"
                }
            }
        },
        //company_name: {
        //    elementType: 'input',
        //    elementConfig: {
        //        type: 'text',
        //        label: 'Company Name'
        //    },
        //    value: '',
        //    errorStatus: false,
        //    errorMessage: "Enter Company Name",
        //    maxLength: 60
        //},
        phone: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                label: 'Phone'
            },
            value: '',
            errorStatus: false,
            errorMessage: "Enter Phone",
            maxLength: 10
        },
        email: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                label: 'Email Address'
            },
            value: '',
            errorStatus: false,
            errorMessage: "Enter Email Address",
            maxLength: 60
        },
        currency: {
            elementType: 'select',
            elementConfig: {
                type: 'text',
                label: 'Currency'
            },
            value: 'AUD',
            errorStatus: false,
            errorMessage: "Select Currency",
            menuItems: {
                'select_country': {
                    name: "Select Currency",
                    value: ''
                },
                'aud': {
                    name: "AUD",
                    value: "AUD"
                }
            }
        }
    },

    //Distributor Admin Form Elements
    addDistributorAdminForm: {
        //company_name: {
        //    elementType: 'input',
        //    elementConfig: {
        //        type: 'text',
        //        label: 'Company Name'
        //    },
        //    value: '',
        //    errorStatus: false,
        //    errorMessage: "Enter Company Name",
        //    maxLength: 60
        //},
        distributor_name: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                label: 'Reseller Name'
            },
            value: '',
            errorStatus: false,
            errorMessage: "Enter Reseller Name",
            maxLength: 60
        },/*
        attention: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                label: 'Attention'
            },
            value: '',
            errorStatus: false,
            errorMessage: "Enter Attention",
            maxLength: 60,
            disabledStatus: false
        },*/
        phone: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                label: 'Phone'
            },
            value: '',
            errorStatus: false,
            errorMessage: "Enter Phone",
            maxLength: 11,
            disabledStatus: false
        },
        abn: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                label: 'ABN'
            },
            value: '',
            errorStatus: false,
            errorMessage: "Enter ABN",
            maxLength: 11,
            disabledStatus: false
        },
        distributor_email: {
            elementType: 'input',
            elementConfig: {
                type: 'email',
                label: 'Email'
            },
            value: '',
            errorStatus: false,
            errorMessage: "Enter a valid email address",
            maxLength: 60
        },
        distributor_logo: {
            elementType: 'upload_image',
            elementConfig: {
                type: 'text',
                label: 'Logo'
            },
            value: '',
            errorStatus: false,
            errorMessage: "Enter Logo",
        },
        support_email: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                label: 'Support Email'
            },
            value: '',
            errorStatus: false,
            errorMessage: "Enter Support Email",
            maxLength: 60
        },
        support_contact_number: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                label: 'Support Contact Number'
            },
            value: '',
            errorStatus: false,
            errorMessage: "Enter Support Contact Number",
            maxLength: 10
        },
        distributor_address: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                label: 'Address'
            },
            value: '',
            errorStatus: false,
            errorMessage: "Enter Address",
            maxLength: 100
        },
        country: {
            elementType: 'select',
            elementConfig: {
                type: 'text',
                label: 'Country'
            },
            value: 'AUS',
            errorStatus: false,
            errorMessage: "Select Country",
            menuItems: {
                'select_country': {
                    name: "Select Country",
                    value: ''
                },
                'aus': {
                    name: "Australia",
                    value: "AUS"
                }
            }
        },
        state : {
            elementType : 'select',
            elementConfig : {
                type : 'text',
                label : 'State'
            },
            value : '',
            errorStatus : false,
            errorMessage : "Enter State",
            menuItems : {
                'select_state' : {
                    name : "Select State",
                    value : ''
                },
                'nsw' : {
                    name : "NSW",
                    value : "NSW"
                },
                'qld':{
                    name : "QLD",
                    value : "QLD"
                },
                'sa' : {
                    name : "SA",
                    value : "SA"
                },
                'tas':{
                    name : "TAS",
                    value : "TAS"
                },
                'vic' : {
                    name : "VIC",
                    value : "VIC"
                },
                'act' : {
                    name : 'ACT',
                    value : 'ACT'
                },
                'nt' : {
                    name : 'NT',
                    value : 'NT'
                },
                'wa':{
                    name : "WA",
                    value : "WA"
                },
                'ashmore island':{
                    name : "ASHMORE ISLAND",
                    value : "ASHMORE ISLAND"
                },
                'cartier island':{
                    name : "CARTIER ISLAND",
                    value : "CARTIER ISLAND"
                },
                'christmas island':{
                    name:'CHRISTMAS ISLAND',
                    value:'CHRISTMAS ISLAND'
                },
                'cocos island':{
                    name:'COCOS ISLAND',
                    value:'COCOS ISLAND'
                },
                'coral sea island':{
                    name:'CORAL SEA ISLAND',
                    value:'CORAL SEA ISLAND'
                },
                'heard island':{
                    name:'HEARD ISLAND',
                    value:'HEARD ISLAND'
                },
                'mcdonald island':{
                    name:'MCDONALD ISLAND',
                    value:'MCDONALD ISLAND'
                },
                'oth':{
                    name : "OTH",
                    value : "OTH"
                }
            },
            disabledStatus : false
        },
        postalcode: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                label: 'Postal Code '
            },
            value: '',
            errorStatus: false,
            errorMessage: "Enter Postal Code",
            maxLength: 4
        },/*
        payment_method: {
            elementType: 'select',
            elementConfig: {
                type: 'text',
                label: 'Payment Method'
            },
            value: 'EFT',
            errorStatus: false,
            errorMessage: "Select Payment Method",
            menuItems: {
                'select_payment_method': {
                    name: "Select Payment Method",
                    value: ''
                },
                'eft': {
                    name: "EFT",
                    value: "EFT"
                }
            }
        }*/
    },
    //Loader Status
    showLoaderStatus: false,
    myob_account_number: '',
    //Distributor Admin Table Information
    cols: [
        {
            title: 'Reseller ID',
            field: 'distributor_id',
            editComponent: props => (
                <TextField
                    type="text"
                    value={props.value}
                    InputProps={{
                        readOnly: true,
                    }}
                />
            )
        },
        {
            title: 'Reseller Name',
            field: 'distributor_name'
        },
        {
            title: 'Country Name',
            field: 'country_name',
            lookup: { "AUS": 'Australia' },
        },
        {
            title: 'ABN',
            field: "abn",
            editComponent: props => (
                <TextField
                    type="number"
                    value={props.value}
                    InputProps={{
                        readOnly: true,
                    }}
                />
            )
        }
    ],
    distributorInformations: [],

    //Distributor User Table Information
    distributorUserColumns: [
        {
            title: "First Name",
            field: "distributor_first_name"
        },
        {
            title: "Last Name",
            field: "distributor_last_name"
        },
        {
            title: "Email",
            field: "distributor_email"
        },
        {
            title: "Role",
            field: "distributor_role",
            lookup: {
                3: "Reseller Editor",
                4: "Reseller Reporter"
            }
        }
    ],
    distributorUserInformations: [],
    distributorUserLoaderStatus: false,
    listDistributorUserLoaderStatus: false,
    bulkUploadedDistributors: [],
    distributorUploadStatusTableColumns: [
        {
            title: 'Firstname',
            field: 'userfirstname'
        },
        {
            title: 'Lastname',
            field: 'userlastname'
        },
        {
            title: 'Status',
            field: 'status'
        }
    ],
    distributorUploadStatusList: [],
    distributorBulkUploadSubmitLoaderStatus: false,
    editDistributorDialogOpenStatus: false,
    addDistributorLogoBase64: '',
    distributorIdForEdit: '',
    updateDistributorLoaderStatus: false,
    addDistributorTabValue: 0,
    addDistributorContactinfoColumns: [
        { title: 'First Name', field: 'first_name' },
        { title: 'Last Name', field: 'last_name' },
        { title: 'Email', field: 'contact_email' },
        { title: 'Phone', field: 'contact_phone', type: 'numeric' }
    ],
    addDistributorContactinformations: [],
    addDistributorReqParams: {},
    addDistributorTabDisableStatus: true,
    addDistributorAdminLoaderStatus: false,
    tabSwitchStatus: false,
    distributorAdminEditStatus: false,
    getDistributorAdminByIdLoaderStatus: false
};

//Handle SET_SUBMITTED_DISTRIBUTOR_USER Reducer
const setSubmittedDistributorUser = (state, action) => {
    state.distributorUserLoaderStatus = action.distributorUserLoaderStatus;
    Object.keys(state.distributorUserForm).map((inputField) => {
        if (state.distributorUserForm[inputField].value === '') {
            state.distributorUserForm[inputField].errorStatus = true;
        } else {
            state.distributorUserForm[inputField].errorStatus = false;
        }
    })
    if (!(state.distributorUserForm['distributor_user_email'].value).match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
        state.distributorUserForm['distributor_user_email'].errorStatus = true;
    } else {
        state.distributorUserForm['distributor_user_email'].errorStatus = false;
    }
    return state;
}

//Handle SET_DISTRIBUTOR_USER_INPUT_FIELD Reducer
const setDistributorUserInputField = (state, action) => {
    state.distributorUserForm[action.inputFiledId].value = action.data;
    if (state.distributorUserForm[action.inputFiledId].value === '') {
        state.distributorUserForm[action.inputFiledId].errorStatus = true;
    } else {
        state.distributorUserForm[action.inputFiledId].errorStatus = false;
    }

    return state;
}

//Handle SET_DEFAULT_VALUE_TO_ALL_FORM_FIELD Reducer
const setDefaultValueToAllFormField = (state, action) => {

    Object.keys(state.addDistributorAdminForm).map((textField) => {
        state.addDistributorAdminForm[textField].value = '';
        state.addDistributorAdminForm[textField].errorStatus = false;
    })
    state.distributorAdminEditStatus = false;
    return state;
}

//Handle SET_DISTRIBUTOR_LIST Reducer
const setDistributorList = (state, action) => {
    state.distributorInformations = action.distributerList;
    return state;
}

//Handle DISTRIBUTOR_TEXTFIELD_CHANGE Reducer
const distributorTextFieldChange = (state, action) => {

    state.addDistributorAdminForm[action.textFieldId].value = action.textFieldData;
    if (action.addDistributorLogoBase64 !== '') {
        state.addDistributorLogoBase64 = action.addDistributorLogoBase64;
    }
    if (
        action.textFieldData === '' &&
        (
            action.textFieldId !== 'support_email' &&
            action.textFieldId !== 'support_contact_number' &&
            action.textFieldId !== 'distributor_address' &&
            action.textFieldId !== 'company_name' &&
            action.textFieldId !== 'attention' &&
            action.textFieldId !== 'phone' &&
            action.textFieldId !== 'payment_method' &&
            action.textFieldId !== 'distributor_logo'
        )
    ) {
        state.addDistributorAdminForm[action.textFieldId].errorStatus = true;
    }
    else {
        state.addDistributorAdminForm[action.textFieldId].errorStatus = false;
    }


    return state;
}

//Handle SUBMIT_DISTRIBUTOR_INFO Reducer
const submitDistributorInfo = (state, action) => {
    state.addDistributorTabValue = action.addDistributorTabValue;
    state.addDistributorReqParams = action.addDistributorReqParams;
    state.addDistributorTabDisableStatus = action.addDistributorTabDisableStatus;
    state.tabSwitchStatus = action.tabSwitchStatus;
    return state;
}

//Handle UPDATE_DISTRIBUTOR_INFO Reducer
const updateDistributorInfo = (state, action) => {
    state.updateDistributorLoaderStatus = action.updateDistributorLoaderStatus;
    if (state.updateDistributorLoaderStatus === false) {
        state.editDistributorDialogOpenStatus = false;
    }
    state.distributorInformations = action.distributorInformations;
    return state;
}

//Handle SET_DEFAULT_VALUE_TO_ADD_DISTRIBUTOR_USER Reducer
const setDefaultValueToAddDistributorUser = (state, action) => {
    Object.keys(state.distributorUserForm).map((inputField) => {
        state.distributorUserForm[inputField].value = '';
        state.distributorUserForm[inputField].errorStatus = false;
    })
    return state;
}

//Handle SET_DISTRIBUTOR_USER_LIST Reducer
const setDistributorUserList = (state, action) => {
    state.distributorUserInformations = action.distributorUsers;
    state.listDistributorUserLoaderStatus = action.listDistributorUserLoaderStatus;
    return state;
}


const setUpdatedDistributorUser = (state, action) => {
    state.distributorUserInformations = action.distributorUserInformations;
    return state;
}

const setDefaultValueToDistributorUploadForm = (state, action) => {
    Object.keys(state.uploadDistributorForms).map(inputField => {
        state.uploadDistributorForms[inputField].errorStatus = false;
        state.uploadDistributorForms[inputField].value = '';
    });
    state.distributorUploadStatusList = [];
    return state;
}

const postDistributorUploadedData = (state, action) => {
    Object.keys(state.uploadDistributorForms).map(inputField => {
        if (state.uploadDistributorForms[inputField].value === '') {
            state.uploadDistributorForms[inputField].errorStatus = true;
        }
    })
    state.distributorBulkUploadSubmitLoaderStatus = action.distributorBulkUploadSubmitLoaderStatus;
    state.distributorUploadStatusList = action.distributorUploadStatusList;
    return state;
}

const setInputHandlerUploadDistributor = (state, action) => {
    state.uploadDistributorForms[action.formElementId].value = action.data;
    if (action.bulkUploadedDistributors === undefined) {
        state.bulkUploadedDistributors = [];
    } else {
        state.bulkUploadedDistributors = action.bulkUploadedDistributors;
    }
    return state;
}

//POST_ADD_DISTRIBUTOR_TAB_HANDLE_CHANGE
const postAddDistributorTabhandleChange = (state, action) => {
    state.addDistributorTabValue = action.addDistributorTabValue;
    return state;
}

// /POST_ADD_ADD_DISTRIBUTOR_CONTACT_INFO
const postAddAddDistributorContactInfo = (state, action) => {
    state.addDistributorContactinformations = action.addDistributorContactinformations;
    return state;
}

//POST_UPDATE_DISTRIBUTOR_CONTACT_INFO
const postUpdateDistributorContactInfo = (state, action) => {
    state.addDistributorContactinformations = action.addDistributorContactinformations;
    return state;
}

//POST_DELETE_ADD_DISTRIBUTOR_CONTACT_INFO
const postDeleteAddDistributorContactInfo = (state, action) => {
    return state;
}

// POST_ADD_DISTRIBUTOR_CONTACT_NEXT
const postAddDistributorContactNext = (state, action) => {
    state.addDistributorReqParams['distributor_contacts'] = action.addDistributorContactinformations;
    state.addDistributorTabValue = 2;
    return state;
}

//POST_HANDLE_ADD_DISTRIBUTOR_BILLING_FORM_FIELD
const postHandleAddDistributorBillingFormField = (state, action) => {
    state.billingForm[action.formFieldId].value = action.data;
    return state;
}

// /POST_ADD_DISTRIBUTOR_ADMIN_ON_SUBMIT
const postAddDistributorAdminOnSubmit = (state, action) => {
    state.addDistributorAdminLoaderStatus = action.addDistributorAdminLoaderStatus;
    return state;
}


//POST_ADD_DISTRIBUTOR_CONTACT_BACK
const postAddDistributorContactBack = (state, action) => {
    state.addDistributorTabValue = 0;
    return state;
}


// POST_ADD_DISTRIBUTOR_ADMIN_BILLING_BACK
const postAddDistributorAdminBillingBack = (state, action) => {
    state.addDistributorTabValue = 1;
    return state;
}

const postAddDistributorAdminOnSubmitAfterCloseToastr = (state, action) => {
    state.addDistributorTabValue = 0;
    state.tabSwitchStatus = false;
    return state;
}

const postGetDistributorInfoById = (state, action) => {
    state.getDistributorAdminByIdLoaderStatus = action.getDistributorAdminByIdLoaderStatus;
    state.distributorAdminEditStatus = true;
    let contactInfo = {};
    setDefaultValueToAllFormField(state, action);
    state.addDistributorContactinformations = [];
    Object.keys(state.addDistributorAdminForm).map(inputField => {
        state.addDistributorAdminForm[inputField].errorStatus = false;
    })
    Object.keys(state.billingForm).map(inputField => {
        state.billingForm[inputField].errorStatus = false;
    })
    if (!isObjectEmpty(action.distributorInfo) && action.distributorInfo) {
        state.myob_account_number = action.distributorInfo.myob_account_number;
        if (action.distributorInfo.main_attributes) {
            // SMED-1354 state.addDistributorAdminForm['company_name'].value = action.distributorInfo.main_attributes['company_name'] ? action.distributorInfo.main_attributes['company_name'] : '';
            //state.addDistributorAdminForm['attention'].value = action.distributorInfo.main_attributes['attention'] ?
            //    action.distributorInfo.main_attributes['attention'] : '';
            state.addDistributorAdminForm['phone'].value = action.distributorInfo.main_attributes['phone'] ?
                action.distributorInfo.main_attributes['phone'] : '';
            //state.addDistributorAdminForm['payment_method'].value = action.distributorInfo.main_attributes['payment_method'] ? action.distributorInfo.main_attributes['payment_method'] : '';
            state.addDistributorAdminForm['distributor_email'].value = action.distributorInfo.main_attributes['email'] ?
                action.distributorInfo.main_attributes['email'] : '';
        }

        state.addDistributorAdminForm['distributor_name'].value = action.distributorInfo['distributor_name'] ? action.distributorInfo['distributor_name'] : '';


        state.addDistributorAdminForm['abn'].value = action.distributorInfo['abn'] ? action.distributorInfo['abn'] : '';

        state.addDistributorAdminForm['support_email'].value = action.distributorInfo['distributor_support_email'] ?
            action.distributorInfo['distributor_support_email'] : '';
        state.addDistributorAdminForm['support_contact_number'].value = action.distributorInfo['distributor_support_contactnumber'] ?
            action.distributorInfo['distributor_support_contactnumber'] : '';
        state.addDistributorAdminForm['distributor_address'].value = action.distributorInfo['distributor_address'] ?
            action.distributorInfo['distributor_address'] :
            '';
        state.addDistributorAdminForm['distributor_logo'].value = 'Logo';
        state.addDistributorAdminForm['country'].value = action.distributorInfo['country'] ? action.distributorInfo['country'] : '';
        state.addDistributorAdminForm['state'].value = action.distributorInfo['state'] ? action.distributorInfo['state'] : '';
        state.addDistributorAdminForm['postalcode'].value = action.distributorInfo['postalcode'] ? action.distributorInfo['postalcode'] : '';

        if(action.distributorInfo.distributor_contacts) {
            for (let i = 0; i < (action.distributorInfo.distributor_contacts).length; i++) {
                contactInfo = {};
                contactInfo['first_name'] = action.distributorInfo.distributor_contacts[i].first_name;
                contactInfo['last_name'] = action.distributorInfo.distributor_contacts[i].last_name;
                contactInfo['contact_email'] = action.distributorInfo.distributor_contacts[i].contact_email;
                contactInfo['contact_phone'] = action.distributorInfo.distributor_contacts[i].contact_phone;
                state.addDistributorContactinformations.push(contactInfo);
            }
        }

        if(action.distributorInfo.distributor_billing_data) {
            state.billingForm['address_line'].value = action.distributorInfo.distributor_billing_data['billing_address'];
            state.billingForm['city'].value = action.distributorInfo.distributor_billing_data['billing_city'];
            state.billingForm['state'].value = action.distributorInfo.distributor_billing_data['billing_state'];
            state.billingForm['postalcode'].value = action.distributorInfo.distributor_billing_data['billing_postalcode'];
            state.billingForm['country'].value = action.distributorInfo.distributor_billing_data['billing_country'];
            // SMED-1354 state.billingForm['company_name'].value = action.distributorInfo.distributor_billing_data['billing_company_name'];
            state.billingForm['phone'].value = action.distributorInfo.distributor_billing_data['billing_phone'];
            state.billingForm['email'].value = action.distributorInfo.distributor_billing_data['billing_email'];
            state.billingForm['currency'].value = action.distributorInfo.distributor_billing_data['billing_currency'];
        }
    }
    return state;
}

//POST_ADD_DISTRIBUTOR_ADMIN_ON_UPDATE
const postAddDistributorAdminOnUpdate = (state, action) => {
    state.addDistributorAdminLoaderStatus = action.addDistributorAdminLoaderStatus;
    return state;
}

//POST_NAVIGATOR_TO_ADD_DISTRIBUTOR
const postNavigatorToAddDistributor = (state, action) => {
    state.distributorAdminEditStatus = false;
    state.tabSwitchStatus = false;
    state.addDistributorTabDisableStatus = true;
    return state;
}

const postNavigateToEditDistributorAdmin = (state, action) => {
    state.distributorAdminEditStatus = true;
    return state;
}

const reducerDistributor = (state = initialState, action) => {
    let localState = Object.assign({}, state);
    switch (action.type) {
        case POST_HANDLER_EDIT_DISTRIBUTOR_DIALOG_OPEN:
            localState.addDistributorAdminForm['distributor_logo'].value = "Logo";
            localState.distributorIdForEdit = action.data['distributor_id'];
            localState.addDistributorAdminForm['distributor_name'].value = action.data['distributor_name'];
            localState.addDistributorAdminForm['country'].value = action.data['country_name'];
            localState.addDistributorAdminForm['myob_account_number'].value = action.data['myob_account_number'];
            localState.addDistributorAdminForm['abn'].value = action.data['abn'];
            localState.addDistributorAdminForm['support_contact_number'].value = action.data['distributor_support_contactnumber'];
            localState.addDistributorAdminForm['distributor_address'].value = action.data['distributor_address'];
            localState.addDistributorAdminForm['support_email'].value = action.data['distributor_support_email'];
            Object.keys(localState.addDistributorAdminForm).map(inputField => {
                localState.addDistributorAdminForm[inputField].errorStatus = false;
            })
            localState.editDistributorDialogOpenStatus = true;
            localState.addDistributorAdminForm['abn'].disabledStatus = true;
            return localState;
        case POST_HANDLER_EDIT_DISTRIBUTOR_DIALOG_CLOSE:
            localState.addDistributorAdminForm['abn'].disabledStatus = false;
            localState.editDistributorDialogOpenStatus = false;
            return localState;
        case SET_DEFAULT_VALUE_TO_DISTRIBUTOR_UPLOAD_FORM: return setDefaultValueToDistributorUploadForm(localState, action);
        case POST_DISTRIBUTOR_UPLOADED_DATA: return postDistributorUploadedData(localState, action);
        case SET_INPUTHANDLER_UPLOAD_DISTRIBUTOR: return setInputHandlerUploadDistributor(localState, action);
        case SET_UPDATED_DISTRIBUTOR_USER: return setUpdatedDistributorUser(localState, action);
        case SET_DISTRIBUTOR_USER_LIST: return setDistributorUserList(localState, action);
        case SET_DEFAULT_VALUE_TO_ADD_DISTRIBUTOR_USER: return setDefaultValueToAddDistributorUser(localState, action);
        case SET_SUBMITTED_DISTRIBUTOR_USER: return setSubmittedDistributorUser(localState, action);
        case SET_DISTRIBUTOR_USER_INPUT_FIELD: return setDistributorUserInputField(localState, action);
        case SET_DEFAULT_VALUE_TO_ALL_FORM_FIELD: return setDefaultValueToAllFormField(localState, action);
        case SET_DISTRIBUTOR_LIST: return setDistributorList(localState, action);
        case DISTRIBUTOR_TEXTFIELD_CHANGE: return distributorTextFieldChange(localState, action);
        case SUBMIT_DISTRIBUTOR_INFO: return submitDistributorInfo(localState, action);
        case UPDATE_DISTRIBUTOR_INFO: return updateDistributorInfo(localState, action);
        case POST_ADD_DISTRIBUTOR_TAB_HANDLE_CHANGE: return postAddDistributorTabhandleChange(localState, action);
        case POST_ADD_ADD_DISTRIBUTOR_CONTACT_INFO: return postAddAddDistributorContactInfo(localState, action);
        case POST_UPDATE_DISTRIBUTOR_CONTACT_INFO: return postUpdateDistributorContactInfo(localState, action);
        case POST_DELETE_ADD_DISTRIBUTOR_CONTACT_INFO: return postDeleteAddDistributorContactInfo(localState, action);
        case POST_ADD_DISTRIBUTOR_CONTACT_NEXT: return postAddDistributorContactNext(localState, action);
        case POST_HANDLE_ADD_DISTRIBUTOR_BILLING_FORM_FIELD: return postHandleAddDistributorBillingFormField(localState, action);
        case POST_ADD_DISTRIBUTOR_ADMIN_ON_SUBMIT: return postAddDistributorAdminOnSubmit(localState, action);
        case POST_ADD_DISTRIBUTOR_CONTACT_BACK: return postAddDistributorContactBack(localState, action);
        case POST_ADD_DISTRIBUTOR_ADMIN_BILLING_BACK: return postAddDistributorAdminBillingBack(localState, action);
        case POST_ADD_DISTRIBUTOR_ADMIN_ON_SUBMIT_AFTER_CLOSE_TOASTR: return postAddDistributorAdminOnSubmitAfterCloseToastr(localState, action);
        case POST_GET_DISTRIBUTOR_INFO_BY_ID: return postGetDistributorInfoById(localState, action);
        case POST_ADD_DISTRIBUTOR_ADMIN_ON_UPDATE: return postAddDistributorAdminOnUpdate(localState, action);
        case POST_NAVIGATOR_TO_ADD_DISTRIBUTOR: return postNavigatorToAddDistributor(localState, action);
        case POST_NAVIGATE_TO_EDIT_DISTRIBUTOR_ADMIN: return postNavigateToEditDistributorAdmin(localState, action);
        default:
            return state;
    }
}
export default reducerDistributor
