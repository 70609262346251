import{
    SET_MEDICAL_TREATMENT_INPUT_FIELD,
    SET_DEFAULT_VALUE_TO_SEARCH_MEDICAL_TREATMENT,
    SET_ADD_MT_RECORD_INPUT_HANDLER,
    SET_SUBMITED_MT_RECORD,
    SET_ADD_MT_RECORD_FORM_DATE_HANDLER,
    SET_MEDICAL_TREATMENT_RECORDS,
    SET_MT_LIST,
    SET_MT_TYPES,
    SET_MT_RECORD_OPEN_DIALOG_STATUS,
    SET_EDIT_MT_RECORD_CLOSE_DIALOG,
    SET_UPDATE_MT_RECORD,
    OPEN_MEDICAL_TREATMENT_RECORD,
    SET_DEFAULT_VALUE_TO_ADD_MT_FORM
} from "./ActionType";
import axios from "../axios";
import {apiUrl} from "../config";
import { toast } from 'react-toastify';
import {
    convertToBase64, getFileExtension, formatDate, getActulaURL, downloadFile
}from "../utils/Helper";

export const initializeAddMTForm = () => {
    return (dispatch) => {
        return dispatch(setDefaultValueToAddMTForm())
    }
}
export const setDefaultValueToAddMTForm = () => {
    return{
        type : SET_DEFAULT_VALUE_TO_ADD_MT_FORM
    }
}
export const downloadMedicalTreatmentRecord = (rowData) => {
    return (dispatch) => {
        let reqURL = apiUrl + "/businesses/"+localStorage.getItem('business_id')+"/drivers/"+localStorage.getItem('driver_id')+"/records/"+rowData.recordId;
        // console.log("HI" + reqURL);
        downloadFile(reqURL);
        dispatch(openMedicalTreatmentRecord());
    }
}
export const openMedicalTreatmentRecord = () => {
    return{
        type : OPEN_MEDICAL_TREATMENT_RECORD
    }
}
export const onSubmitMTRecord = (addMTRecordForm, driverId, medicalTreatMentBase64, props, url) => {
    return (dispatch) => {
        let addMTRecordErrorStatus = false;
        let showMTRecordSubmitLoaderStatus = false;
        Object.keys(addMTRecordForm).map(inputFieldId => {
            if(addMTRecordForm[inputFieldId].value === '' || addMTRecordForm[inputFieldId].value === null){
                addMTRecordErrorStatus = true;
            }
        })
        dispatch(setSubmitedMTRecord(showMTRecordSubmitLoaderStatus));
        if(!addMTRecordErrorStatus){
            showMTRecordSubmitLoaderStatus = true;
            let reqPath = "/businesses/"+localStorage.getItem('business_id')+"/drivers/"+localStorage.getItem('driver_id')+"/records";
            let reqParams = {};
            let treatmentDate = formatDate(addMTRecordForm['treatment_date'].value);
            let nextTreatmentDate = formatDate(addMTRecordForm['next_treatment_date'].value);
            let dueTreatmentDate = formatDate(addMTRecordForm['notification_date'].value);
            let fileExtension  = getFileExtension(addMTRecordForm['medical_certificate'].value);
            let mimeType = '';
            if(fileExtension === 'docx'){
                mimeType = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
            }
            if(fileExtension === 'doc'){
                mimeType = 'application/msword';
            }
            if(fileExtension === 'pdf'){
                mimeType = 'application/pdf';
            } 
            if(fileExtension === 'jpeg'){
                mimeType = 'image/jpeg';
            } 
            if(fileExtension === 'jpg'){
                mimeType = 'image/jpeg';
            }
            if(fileExtension === 'png'){
                mimeType = 'image/png';
            }
    
            reqParams['treatment_date_last'] = treatmentDate;
            reqParams['treatment_date_next'] = nextTreatmentDate;
            reqParams['treatment_due_next'] = dueTreatmentDate;
            reqParams['document_contents'] = medicalTreatMentBase64;
            reqParams['hidden'] = addMTRecordForm['mt_require'].value;
            if(addMTRecordForm['medical_certificate'].value != ''){
                reqParams['extension'] = mimeType;
            }
            reqParams['treatment_type'] = addMTRecordForm['mt_type'].value;
            dispatch(setSubmitedMTRecord(showMTRecordSubmitLoaderStatus))
            return axios.post(reqPath, reqParams)
            .then(response => {
                showMTRecordSubmitLoaderStatus = false;
                toast.success("MT Record Created Successfully", {
                    containerId: 'toastMsg',
                    onClose : () => props.history.push(getActulaURL(url))
                });
                dispatch(setSubmitedMTRecord())
            })
            .catch(error => {
                showMTRecordSubmitLoaderStatus = false;
                toast.error(error.response.data.message, {
                    containerId: 'toastMsg'
                });
                dispatch(setSubmitedMTRecord(showMTRecordSubmitLoaderStatus))
            })
        }
    }
}

export const setSubmitedMTRecord = (showMTRecordSubmitLoaderStatus) => {
    return{
        type : SET_SUBMITED_MT_RECORD,
        showMTRecordSubmitLoaderStatus : showMTRecordSubmitLoaderStatus
    }
}
export const onUpdateMTRecord = (addMTRecordForm, activeMTRecordForEdit, driverId, medicalTreatMentBase64, props) => {
    return (dispatch) => {
        let updatedData = {};
        let showMTRecordUpdateLoaderStatus = false;
        let updateMTRecordFormValidationStatus = false;
        Object.keys(addMTRecordForm).map(inputField => {
            if(addMTRecordForm[inputField].value === ''){
                updateMTRecordFormValidationStatus = true;
            }
        })
        if(!updateMTRecordFormValidationStatus){
            showMTRecordUpdateLoaderStatus = true;
            let reqPath = "/businesses/"+localStorage.getItem('business_id')+"/drivers/"+localStorage.getItem('driver_id')+"/records/"+activeMTRecordForEdit;
            let reqParams = {};
            let treatmentDate = formatDate(addMTRecordForm['treatment_date'].value);
            let nextTreatmentDate = formatDate(addMTRecordForm['notification_date'].value);
            let dueTreatmentDate = formatDate(addMTRecordForm['next_treatment_date'].value);
            let fileExtension = getFileExtension(addMTRecordForm['medical_certificate'].value);
           
            let mimeType = '';
            if(fileExtension === 'docx'){
                mimeType = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
            }
            if(fileExtension === 'doc'){
                mimeType = 'application/msword';
            }
            if(fileExtension === 'pdf'){
                mimeType = 'application/pdf';
            } 
            if(fileExtension === 'jpeg'){
                mimeType = 'image/jpeg';
            } 
            if(fileExtension === 'jpg'){
                mimeType = 'image/jpeg';
            }
            if(fileExtension === 'png'){
                mimeType = 'image/png';
            }
            reqParams['treatment_date_last'] = treatmentDate;
            reqParams['treatment_date_next'] = nextTreatmentDate;
            reqParams['treatment_due_next'] = dueTreatmentDate;
            reqParams['document_contents'] = medicalTreatMentBase64;
            reqParams['hidden'] = addMTRecordForm['mt_require'].value;
            if(addMTRecordForm['medical_certificate'].value != ''){
                reqParams['extension'] = mimeType;
            }
            reqParams['treatment_type'] = addMTRecordForm['mt_type'].value;
            dispatch(setUpdateMTRecord(showMTRecordUpdateLoaderStatus));
            return axios.put(reqPath, reqParams)
            .then(response => {
                showMTRecordUpdateLoaderStatus = false;
                updatedData = {};
                updatedData['treatment_date'] = formatDate(treatmentDate, "DD-MM-YYYY");
                updatedData['next_treatment_date'] = formatDate(dueTreatmentDate, "DD-MM-YYYY");
                updatedData['notification_date'] = formatDate(nextTreatmentDate, "DD-MM-YYYY");
                updatedData['document_contents'] = addMTRecordForm['mt_type'].value + ' Certificate';
                updatedData['mt_require'] = addMTRecordForm['mt_require'].value;
                updatedData['mt_type'] = addMTRecordForm['mt_type'].value;
                updatedData['recordId'] = activeMTRecordForEdit;
                toast.success("MT Record Updated Successfully", {
                    containerId: 'toastMsg',
                });
                dispatch(setUpdateMTRecord(showMTRecordUpdateLoaderStatus, updatedData))
            })
            .catch(error => {
                showMTRecordUpdateLoaderStatus = false;
                toast.error(error.response.data.message, {
                    containerId: 'toastMsg'
                });
                dispatch(setUpdateMTRecord(showMTRecordUpdateLoaderStatus, updatedData))
            })
        }

    }
}
export const setUpdateMTRecord = (showMTRecordUpdateLoaderStatus, updatedData) => {
    return{
        type : SET_UPDATE_MT_RECORD,
        showMTRecordUpdateLoaderStatus : showMTRecordUpdateLoaderStatus,
        updatedData : updatedData
    }
}
export const handleEditMTRecordCloseDialog = () => {
    return (dispatch) => {
        return dispatch(setEditMTRecordCloseDialog())
    }
}
export const setEditMTRecordCloseDialog = () => {
    return{
        type : SET_EDIT_MT_RECORD_CLOSE_DIALOG
    }
}
export const handleEditMTRecordOpenDialog = (mtRecordData) => {
    return (dispatch) => {
        return dispatch(setMTRecordOpenDialogStatus(mtRecordData))
    }
}
export const setMTRecordOpenDialogStatus = (mtRecordData) => {
    return{
        type : SET_MT_RECORD_OPEN_DIALOG_STATUS,
        mtRecordData : mtRecordData
    }
}
export const getMTTypes = () => {
    return dispatch => {
        let reqPath = "/businesses/"+localStorage.getItem('business_id')+"/mtListTypes";
        let lookUp = {};
        return axios.get(reqPath)
        .then(response => {
            for(let i = 0; i < response.data.data.length; i++){
                lookUp[response.data.data[i].mt_id] = response.data.data[i].mt_type;
            }
            dispatch(setMTTypes(lookUp));
        })
        .catch(error => {
            dispatch(setMTTypes(lookUp));
        })
    }
}
export const setMTTypes = (lookUp) => {
    return{
        type : SET_MT_TYPES,
        lookUp : lookUp
    }
}
export const getMTList = () => {
    return(dispatch) => {
        let reqPath = "/businesses/"+localStorage.getItem('business_id')+"/mtListTypes";

        let mtTypesMenuItems = {};
        let mtType = {};
        mtType['name'] = "Select MT Type";
        mtType['value'] = "";
        mtTypesMenuItems['select_mt_type'] = mtType;
        return axios.get(reqPath)
        .then(response => {
            for(let i = 0; i < response.data.data.length; i++){
                mtType = {};
                mtType['value'] = response.data.data[i].mt_id;
                mtType['name'] = response.data.data[i].mt_type;
                mtTypesMenuItems[response.data.data[i].mt_id] = mtType;
            } 
            dispatch(setMTList(mtTypesMenuItems))
        })
        .catch(error => {
            dispatch(setMTList(mtTypesMenuItems))
        })
    }
}
export const setMTList = (mtTypesMenuItems) => {
    return{
        type : SET_MT_LIST,
        mtTypesMenuItems : mtTypesMenuItems,
    }
}
export const getMedicalTreatment = () => {
    return (dispatch) => {
        let showMTRecordListLoaderStatus = true;
        let mtInformations = [];
        let singleMTRecord = {};
        let reqPath = "/businesses/"+localStorage.getItem('business_id')+"/drivers/"+localStorage.getItem('driver_id')+"/records";
        dispatch(setMedicalTreatments(mtInformations, showMTRecordListLoaderStatus))
        return axios.get(reqPath)
        .then(response => {
            showMTRecordListLoaderStatus = false;
            for(let i = 0; i < response.data.data.document_details.length; i++){
                singleMTRecord = {};
                singleMTRecord['mt_type'] = response.data.data.document_details[i].treatment_type;
                singleMTRecord['treatment_date'] = formatDate(response.data.data.document_details[i].treatment_date, "DD-MM-YYYY");
                singleMTRecord['next_treatment_date'] = formatDate(response.data.data.document_details[i].treatment_next_due_date, "DD-MM-YYYY");
                singleMTRecord['notification_date'] = formatDate(response.data.data.document_details[i].treatment_notification_date, "DD-MM-YYYY");
                singleMTRecord['medical_certificate'] = singleMTRecord['mt_type'] + " Certificate";
                singleMTRecord['mt_requires'] = response.data.data.document_details[i].hidden;
                singleMTRecord['recordId'] = response.data.data.document_details[i]._id;
                mtInformations.push(singleMTRecord);
            }
            dispatch(setMedicalTreatments(mtInformations, showMTRecordListLoaderStatus))
        })
        .catch( error => {
            showMTRecordListLoaderStatus = false;
            dispatch(setMedicalTreatments(mtInformations, showMTRecordListLoaderStatus))
        })
    }
}
export const setMedicalTreatments = (mtInformations, showMTRecordListLoaderStatus) => {
    return{
        type : SET_MEDICAL_TREATMENT_RECORDS,
        mtInformations : mtInformations,
        showMTRecordListLoaderStatus : showMTRecordListLoaderStatus
    }
}
export const addMTRecordFormDateHandler = (dateElementId, date) => {
    return (dispatch) => {
        return dispatch(setAddMTRecordFormDateHandler(dateElementId, date))
    }
}
export const setAddMTRecordFormDateHandler = (dateElementId, date) => {
    return{
        type : SET_ADD_MT_RECORD_FORM_DATE_HANDLER,
        dateElementId : dateElementId,
        date : date
    }
}
export const addMTRecordFormInputHandler = (inputFieldId, event, medicalTreatMentBase64) => {
    let textFieldValue = event.target.value;
    return (dispatch) => {
        if(inputFieldId === 'medical_certificate' && event.target.files !== null){
            textFieldValue = (event.target.value).replace(/^.*\\/, "");
            convertToBase64(event.target.files[0]).then(data =>{
                let medicalTreatMentBase64 = data;
                if((((new Blob([medicalTreatMentBase64]).size)/1024)/1024) > 1.5){
                    toast.error("File size should be less than 1 MB", {containerId: 'toastMsg'});
                }
                dispatch(setAddMTRecordInputHandler(inputFieldId, textFieldValue, medicalTreatMentBase64))
            });
        }
        dispatch(setAddMTRecordInputHandler(inputFieldId, textFieldValue, ""))
    }
}
export const setAddMTRecordInputHandler = (inputFieldId, textFieldValue, medicalTreatMentBase64) => {
        return{
            type : SET_ADD_MT_RECORD_INPUT_HANDLER,
            inputFieldId : inputFieldId,
            data : textFieldValue,
            medicalTreatMentBase64 : medicalTreatMentBase64
        }
}

export const initializeSearchMedicalTreatment = () =>{
    return (dispatch) => {
        return dispatch(setDefaultValueToSearchMedicalTreatment())
    }
}
export const setDefaultValueToSearchMedicalTreatment = () => {
    return{
        type : SET_DEFAULT_VALUE_TO_SEARCH_MEDICAL_TREATMENT
    }
}

