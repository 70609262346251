import { ExportToCsv } from 'export-to-csv';
import axios from "../axios";
import { toast } from "react-toastify";

export function BillGeneratorService(setBusyStatus) {

  if (!setBusyStatus) {
    // Add no op fn
    setBusyStatus = (value) => '';
  }


  this.calculateBillForSgAdmin = async (partner = undefined) => {
    setBusyStatus(true);
    const url = '/billingreport/adminbillingreport';
    let reqConfig = partner ? { params: { partnerId: partner.partner_id } } : {};

    try {
      const reportData = await axios.get(url, reqConfig).then(resp => resp.data.data);
      const reportName = partner ? partner.partner_name : 'AdminReport';
      
      if (reportData.length > 0){
        this.downloadBillingReport(reportName, reportData);
      } else {
        toast.error(`No data available to generate report (no linked resellers)`, {
          containerId: 'toastMsg'
        });
      }

    } catch (err) {
      toast.error(`Error downloading billing report ${err.message}`, {
        containerId: 'toastMsg'
      });
    } finally {
      setBusyStatus(false);
    }
  }


  this.calculateBillForPartner = async (partner) => {
    return await this.calculateBillForSgAdmin(partner);
  }


  this.calculateBillForDistributor = async (distributor) => {
    setBusyStatus(true);
    const url = '/billingreport/distbillingreport?';
    let reqConfig = { params: { distributorId: distributor.distributor_id } };

    try {
      const reportData = await axios.get(url, reqConfig).then(resp => resp.data.data);
      if (reportData.length === 0) {
        toast.error(`No data available to generate report (no linked businesses)`, {
          containerId: 'toastMsg'
        });
      } else {
        this.downloadBillingReport(distributor.distributor_name, reportData);
      }

    } catch (err) {
      toast.error(`Error downloading billing report ${err.message}`, {
        containerId: 'toastMsg'
      });
    } finally {
      setBusyStatus(false);
    }
  }



  this.downloadBillingReport = (businessName, csvData) => {

    const options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
      filename: businessName + '_Bill'
    };
    const csvExporter = new ExportToCsv(options);
    csvExporter.generateCsv(csvData);
  }

}