import {
    SEARCH_MAILLOG_TEXTFIELD_CHANGE,
    SEARCH_MAILLOG_DATEFIELD_CHANGE,
    SET_MAILLOG_LIST_DEFAULT,
    SET_DEFAULT_VALUE_TO_ALL_FORM_FIELD,
    MANAGE_VIEW_MAILLOG_OPEN_DIALOG,
    MANAGE_VIEW_MAILLOG_DIALOG_CLOSE,
    SET_DEFAULT_VALUE_TO_SEARCH_MAILLOG_COMPONENT,
    POST_CLEAR_ALL_SEARCH_MAILLOG_FIELD
} from '../actions/ActionType';
import {
  isObjectEmpty
} from "../utils/Helper";

import TextField from "@material-ui/core/TextField";
import React from 'react';
import { apiUrl } from "../config";
const moment = require('moment');

const initialState = {
    showLoaderStatus : false,

    openViewMaillogDialogStatus: false,

    maillogColumns: [
        {
          title: 'Sent at',
          field: 'sentat'
        },
        {
          title: 'State',
          field: 'state'
        },
        {
          title: 'To',
          field: 'mailto'
        },
        {
          title: 'Subject',
          field: 'mailsubject'
        }
      ],

    maillogRowData: [],

    viewMaillogFormFields: {
      mail_sentAt: {
        elementType: 'input',
        elementConfig: {
            type: 'text',
            label: 'Sent At'
        },
        value: '',
        disabledStatus: true
      },
      mail_state: {
        elementType: 'input',
        elementConfig: {
            type: 'text',
            label: 'State'
        },
        value: '',
        disabledStatus: true
      },
      mail_from: {
        elementType: 'input',
        elementConfig: {
            type: 'text',
            label: 'From'
        },
        value: '',
        disabledStatus: true
      },
      mail_to: {
        elementType: 'input',
        elementConfig: {
            type: 'text',
            label: 'To'
        },
        value: '',
        disabledStatus: true
      },
      mail_subject: {
        elementType: 'input',
        elementConfig: {
            type: 'text',
            label: 'Subject'
        },
        value: '',
        disabledStatus: true
      },
      mail_cc: {
        elementType: 'input',
        elementConfig: {
            type: 'text',
            label: 'CC'
        },
        value: '',
        disabledStatus: true
      },
      mail_body: {
        elementType: 'input',
        elementConfig: {
            type: 'textarea',
            label: 'Body'
        },
        value: '',
        disabledStatus: true
      }
    },

    searchFoundStatus : null,

    searchMaillogForm : {
      'from_date' : {
        elementType : 'date',
        elementConfig : {
            type : 'number',
            label : 'From Date'
        },
        value : '',
        errorStatus : false,
        errorMessage : "Enter From Date"
      },
      'to_date' : {
        elementType : 'date',
        elementConfig : {
            type : 'number',
            label : 'To Date'
        },
        value : '',
        errorStatus : false,
        errorMessage : "Enter To Date"
      },
      'mail_state' : {
          elementType : 'select',
          elementConfig : {
              type : 'text',
              label : 'Mail State'
          },
          value : '',
          errorStatus : false,
          errorMessage : "Enter Mail State",
          menuItems : {
              'all' : {
                name : "All",
                value : 'all'
              },
              'Succeeded' : {
                  name : "Suceeded only",
                  value : "succeeded"
              },
              'Failed':{
                  name : "Failed only",
                  value : "failed"
              }
          },
      },
  },

}

const getMaillogList = (state, action) => {
    state.maillogRowData = action.maillogRowData;
    state.searchMaillogLoaderStatus = action.searchMaillogLoaderStatus;
    //status.searchFoundStatus = action.maillogRowData;
    return state;
}

const manageViewMaillogOpenDialog = (state, action) => {
  //state.openViewMaillogDialogStatus = action.openViewMaillogDialogStatus;
  setDefaultValueToAllFormField(state, action);
  if (!isObjectEmpty(action.maillogInfo) && action.maillogInfo) {
      state.viewMaillogFormFields["mail_sentAt"].value = moment(action.maillogInfo.sentAt).format('DD/MM/YYYY h:mm a');
      if (action.maillogInfo.sendFailed) {
        state.viewMaillogFormFields["mail_state"].value = 'Failed';
        if (action.maillogInfo.failureReason) {
          state.viewMaillogFormFields["mail_state"].value = action.maillogInfo.failureReason;
        }
      } else {
        state.viewMaillogFormFields["mail_state"].value = 'Sent';
      }
      if (action.maillogInfo.Maildata) {
          state.viewMaillogFormFields["mail_from"].value = action.maillogInfo.Maildata.from;
          state.viewMaillogFormFields["mail_to"].value = action.maillogInfo.Maildata.to;
          state.viewMaillogFormFields["mail_cc"].value = action.maillogInfo.Maildata.cc;
          state.viewMaillogFormFields["mail_subject"].value = action.maillogInfo.Maildata.subject;
          state.viewMaillogFormFields["mail_body"].value = action.maillogInfo.Maildata.html;
      }
      state.openViewMaillogDialogStatus = true;
    }
  return state;
}

const setDefaultValueToAllFormField = (state, action) => {

  //Object.keys(state.viewMaillogFormFields).map((textField) => {
  //    state.viewMaillogFormFields[textField].value = '';
  //})

  return state;
}

const maillogs = (state = initialState, action) => {
    let localState = Object.assign({}, state);
    switch (action.type) {
        case SET_MAILLOG_LIST_DEFAULT: return getMaillogList(localState, action);
        case SET_DEFAULT_VALUE_TO_ALL_FORM_FIELD: return setDefaultValueToAllFormField(localState, action);
        case MANAGE_VIEW_MAILLOG_OPEN_DIALOG: return manageViewMaillogOpenDialog(localState, action);
        case MANAGE_VIEW_MAILLOG_DIALOG_CLOSE :
          localState.openViewMaillogDialogStatus = false;
          return localState;
        case SET_DEFAULT_VALUE_TO_SEARCH_MAILLOG_COMPONENT :
          Object.keys(localState.searchMaillogForm).map((inputField) => {
              localState.searchMaillogForm[inputField].value = '';
          })
          localState.openViewMaillogDialogStatus = false;
          return localState;
        case POST_CLEAR_ALL_SEARCH_MAILLOG_FIELD:
          Object.keys(localState.searchMaillogForm).map(inputField => {
              localState.searchMaillogForm[inputField].value = '';
          })
          return localState;
        case SEARCH_MAILLOG_TEXTFIELD_CHANGE :
          localState.searchErrorToastMessage = '';
          if(action.textFieldData != ''){
              Object.keys(localState.searchMaillogForm).map((textFieldId) => {
                  if(textFieldId != action.textFieldId){
                      localState.searchMaillogForm[textFieldId].disabledStatus = true;
                  }
              })
          }else{
              Object.keys(localState.searchMaillogForm).map((textFieldId) => {
                  localState.searchMaillogForm[textFieldId].disabledStatus = false;
              })
          }

          localState.searchMaillogForm[action.textFieldId].value = action.textFieldData;
          return localState;

        case SEARCH_MAILLOG_DATEFIELD_CHANGE :
          localState.searchErrorToastMessage = '';

          localState.searchMaillogForm[action.dateElementId].value = action.date;
          if(localState.searchMaillogForm[action.dateElementId].value === null && state.localState[action.dateElementId].disabledStatus === false){
            localState.searchMaillogForm[action.dateElementId].errorStatus = true;
          }else{
            localState.searchMaillogForm[action.dateElementId].errorStatus = false;
          }

          return localState;

        default:
          return localState;
    }
};

export default maillogs;
