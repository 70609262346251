import axios from '../axios';
import { GET_PARTNER_USERS_SUCCESS, GET_PARTNER_USERS_FAIL, CREATE_PARTNER_USERS_SUCCESS, CREATE_PARTNER_USERS_FAIL } from './ActionType';
import { history } from "../index";
import { toast } from 'react-toastify';

export const getPartnerUsers = () => {

    return (dispatch) => {

        const partnerId = localStorage.getItem('partner_id');

        return axios.get(`partners/${partnerId}/users`)
            .then(resp => {
                dispatch(getPartnerUserSuccess(resp.data.data));
            })
            .catch(error => {

                toast.error(error.response.data.message, {
                    containerId: 'toastMsg',
                });
                dispatch(getPartnerUserFail());
            });
    }
}


export const getPartnerUserSuccess = (partnerUsers) => {
    return {
        type: GET_PARTNER_USERS_SUCCESS,
        partnerUsers
    };
}


export const getPartnerUserFail = () => {
    return {
        type: GET_PARTNER_USERS_FAIL
    };
}

export const createPartnerUser = (partnerUser) => {
    const partnerId = localStorage.getItem('partner_id');
    return (dispatch) => {
        return axios.post(`/partners/${partnerId}/users`, partnerUser)
            .then(resp => {
                dispatch(createPartnerUserSuccess());
                toast.success("User created succesfully", {
                    containerId: 'toastMsg',
                });
                history.goBack();

            })
            .catch(error => {
                toast.error(error.response.data.message, {
                    containerId: 'toastMsg',
                });
                dispatch(createPartnerUserFail());
            })
    };
}

export const createPartnerUserSuccess = (partnerUsers) => {
    return {
        type: CREATE_PARTNER_USERS_SUCCESS,
        partnerUsers
    };
}


export const createPartnerUserFail = () => {
    return {
        type: CREATE_PARTNER_USERS_FAIL
    };
}

export const updatePartnerUser = (partnerUser) => {
    const partnerId = localStorage.getItem('partner_id');
    return (dispatch) => {
        return axios.put(`/partners/${partnerId}/users`, partnerUser)
            .then(resp => {
                dispatch(createPartnerUserSuccess());
                dispatch(getPartnerUsers());
                toast.success("User updated succesfully", {
                    containerId: 'toastMsg',
                });
            })
            .catch(error => {
                toast.error(error.response.data.message, {
                    containerId: 'toastMsg',
                });
                dispatch(createPartnerUserFail());
            })
    };
};


export const deletePartnerUser = () => {
    return {} //TOOD
}
