import React, { Component } from 'react'
import { connect } from "react-redux";

import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import FormGroup from '@material-ui/core/FormGroup';
import Button from '@material-ui/core/Button';

import Input from "../../components/UI/Input/Input";
import CircularProgress from '@material-ui/core/CircularProgress';
import{
    getProfileDetailsForEdit,
    editProfileInputHandler,
    onUpdateProfileDetails
} from "../../actions/Profile";
class EditProfileDetails extends Component{
    componentDidMount(){
        this.props.getProfileDetailsForEdit();
       
    }
    render(){
        let {
            profileForm,
            updateProfileLoaderStatus,
            profileDistributorLogoBase64,
            profileBusinessLogoBase64
        } = this.props.profileState;
        let{
            menuItems
        } = this.props.sidebarState;
        const formElementArray = [];
        for(let key in profileForm){
            formElementArray.push({
                id : key,
                config : profileForm[key]
            })
        }
        let form = (
            <FormGroup row>
                <Grid container spacing={24}>
                    {
                        formElementArray.map(formElement => {
                            return(
                                formElement.config.role === localStorage.getItem('role') ?
                                <Grid item xs={12} sm = {6} key = {formElement.id}>
                                    <Input 
                                        key = {formElement.id}
                                        name = {formElement.config.elementConfig.label}
                                        elementType={formElement.config.elementType}
                                        elementConfig={formElement.config.elementConfig}
                                        value={formElement.config.value}
                                        menuItems = {formElement.config.menuItems}
                                        error = {formElement.config.errorStatus}
                                        helperText = {formElement.config.errorMessage}
                                        disabledStatus = {formElement.config.disabledStatus}
                                        changed={(event) => this.props.editProfileInputHandler(formElement.id, event )}
                                        maxLength={formElement.config.maxLength}
                                    />
                                </Grid> : null
                            )
                        })
                    }
                </Grid>
            </FormGroup>
        )
        return(
            <div className = "container">
                <Grid container spacing={24}>
                    <Grid item xs={1} sm = {1}>
                    </Grid>
                    <Grid item xs={11} sm = {10}>
                    <Card className = "pl-3 pr-3 pt-3 container">
                        <CardHeader
                            title="Edit Profile Details"
                        />
                        <CardContent>
                            <FormGroup row style = {{paddingLeft : "3rem", paddingRight : "1rem"}}>
                                <Grid container spacing={24}>
                                    {form}
                                </Grid>
                                <Grid 
                                    container 
                                    spacing={24} 
                                    className = "pt-2"
                                    direction="row" 
                                    justify="flex-end"
                                    alignItems="center"
                                >
                                    <Grid item className = "mt-3">
                                        {
                                            updateProfileLoaderStatus ? <CircularProgress color = "primary" /> :
                                            <div>
                                                 <Button
                                                    variant="contained" 
                                                    color="primary"
                                                    className = "mr-3"
                                                    onClick = {() => this.props.onUpdateProfileDetails(profileForm, this.props, menuItems['my_profile'].urlpattern, profileDistributorLogoBase64, profileBusinessLogoBase64)}
                                                >
                                                    Update
                                                </Button>
                                                <Button
                                                    variant="contained" 
                                                    color="default" 
                                                    onClick = {() => this.props.history.push(menuItems['my_profile'].urlpattern)}
                                                >
                                                    Cancel
                                                </Button>
                                            </div>

                                        }

                                    </Grid>
                                </Grid>
                            </FormGroup>
                        </CardContent>
                        <CardActions>
                            
                        </CardActions>
                    </Card>
                    </Grid>
                    <Grid item xs={1} sm = {1}>
                    </Grid>
                </Grid>
            </div>

        );
    }
}

const mapStateToProps = state => {
    return{
        profileState : state.profile,
        sidebarState : state.sidebarState
    }
}

const mapDispatchToProps = dispatch => {
    return{
        getProfileDetailsForEdit : () => {
            return dispatch(getProfileDetailsForEdit())
        },
        editProfileInputHandler : (inputFieldId, event) => {
            return dispatch(editProfileInputHandler(inputFieldId, event))
        },
        onUpdateProfileDetails : (profileForm, props, url, profileDistributorLogoBase64, profileBusinessLogoBase64) => {
            return dispatch(onUpdateProfileDetails(profileForm, props, url, profileDistributorLogoBase64, profileBusinessLogoBase64))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps) (EditProfileDetails);