import React, { Component } from 'react';
import MaterialTable from 'material-table';
import SGPaper from '../../../../components/UI/PaperContainer/PaperContainer';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { withRouter } from "react-router-dom";
import { toast } from 'react-toastify';
import {
    addAddBusinessContactInfo,
    updateBusinessContactInfo,
    deleteAddBusinessContactInfo,
    addBusinessContactNext,
    addBusinessContactBack,
    postAddBusinessAdminOnSubmit,
    postAddBusinessAdminOnSubmitAfterCloseToastr,
    postAddBusinessAdminOnUpdate
} from '../../../../actions/Business';
import axios from "../../../../axios";
class Contact extends Component{
      render() {
        let {
          addBusinessContactinformations,
          addBusinessContactinfoColumns,
          addBusinessReqParams
        } = this.props.business;
        let editBusinessSearchFoundStatus = (window.location.href).search('editbusiness');
        let editProfileSearchFoundStatus = (window.location.href).search('editprofile');

        return (
          <SGPaper
              title = "Contact List"
          >
            <MaterialTable
              title=""
              columns={addBusinessContactinfoColumns}
              data={addBusinessContactinformations}
              editable={{
                onRowAdd: newData =>
                  new Promise((resolve, reject) => {
                      let contactValidationStatus = true;
                      const data = addBusinessContactinformations;
                      if(!newData.first_name || !newData.last_name || !newData.phone || !newData.email){
                        contactValidationStatus = false;
                        toast.error("Please fill all the fields!", {containerId: 'toastMsg'});
                        reject();
                      }
                      if(newData.email){
                        if(!(newData.email).match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)){
                          contactValidationStatus = false;
                          toast.error("Please enter a valid email!", {containerId: 'toastMsg'});
                          reject();
                        }
                      }
                      if(contactValidationStatus){
                        data.push(newData);
                        resolve(this.props.addAddBusinessContactInfo(data))
                      }

                      // if(newData.last_name){
                      //   resolve(this.props.addAddBusinessContactInfo(data))
                      // }
                      // if(newData.phone){
                      //   resolve(this.props.addAddBusinessContactInfo(data))
                      // }
                      // if(newData.email){
                      //   resolve(this.props.addAddBusinessContactInfo(data))
                      // }
                  }),
                onRowUpdate: (newData, oldData) =>
                  new Promise((resolve, reject) => {
                    setTimeout(() => {
                      {
                        const data = addBusinessContactinformations;
                        const index = data.indexOf(oldData);
                        data[index] = newData;
                        resolve(this.props.updateBusinessContactInfo(data))
                      }
                      resolve()
                    }, 1000)
                  }),
                onRowDelete: oldData =>
                  new Promise((resolve, reject) => {
                    setTimeout(() => {
                      {
                        let data = addBusinessContactinformations;
                        const index = data.indexOf(oldData);
                        data.splice(index, 1);
                        resolve(this.props.deleteAddBusinessContactInfo(data))
                      }
                      resolve()
                    }, 1000)
                  }),
              }}
            />
              <Grid
                  container
                  spacing={24}
                  className = "pt-2"
                  direction="row"
                  justify="flex-end"
                  alignItems="center"
              >
              <Grid item className = "d-flex justify-content-end">
                <Button
                    variant="contained"
                    color="default"
                    disabled={false}
                    className = "mr-3"
                    onClick = {() => {this.props.addBusinessContactBack()}}
                >
                    Back
                </Button>
                  {
                      editBusinessSearchFoundStatus != -1 || editProfileSearchFoundStatus != -1 ?
                      <Button
                          variant="contained"
                          color="primary"
                          disabled={false}
                          onClick = {() => {this.props.editBusinessSave(addBusinessReqParams, addBusinessContactinformations)}}>Update</Button>
                      :
                      <Button
                          variant="contained"
                          color="primary"
                          disabled={false}
                          onClick = {() => {this.props.addBusinessContactNext(addBusinessReqParams, addBusinessContactinformations)}}>Save</Button>
                  }
              </Grid>

            </Grid>
          </SGPaper>
        )
      }
}

const mapStateToProps = state => {
  return{
    business : state.business
  }
}

const mapDispatchToProps = dispatch => {
  return{
    addAddBusinessContactInfo : (addBusinessContactinformations) => {
      return dispatch(addAddBusinessContactInfo(addBusinessContactinformations))
    },
    updateBusinessContactInfo : (addBusinessContactinformations) => {
      return dispatch(updateBusinessContactInfo(addBusinessContactinformations))
    },
    deleteAddBusinessContactInfo : (addBusinessContactinformations) => {
      return dispatch(deleteAddBusinessContactInfo(addBusinessContactinformations))
    },
    editBusinessSave (addBusinessReqParams, addBusinessContactinformations) {
        let reqURL = '/dist/' + localStorage.getItem('distributor_id') + '/businesses/' + localStorage.getItem('business_id');
        addBusinessReqParams["business_contacts"] = addBusinessContactinformations;
        axios.put(reqURL, addBusinessReqParams).then(response => {
            toast.success("Business Updated Successfully", {
                containerId: 'toastMsg',
                onClose: () => {
                    dispatch(postAddBusinessAdminOnSubmitAfterCloseToastr())
                }
            });
        }).catch(error => {
            if (error.response.status == 403) {
                localStorage.clear();
                toast.error("Session Expired ! Please login again", { containerId: 'toastMsg' });
                window.location.href = "/";
            } else {
                toast.error(error.response.data.message, { containerId: 'toastMsg' });
            }
        })
    },
    addBusinessContactNext : (addBusinessReqParams, addBusinessContactinformations) => {
        let addBusinessAdminLoaderStatus = true;
        addBusinessContactinformations['accesslevel'] = 5;

        let reqPath = '/dist/' + localStorage.getItem('distributor_id') + "/businesses";
        dispatch(postAddBusinessAdminOnSubmit(addBusinessAdminLoaderStatus));
        addBusinessReqParams["business_contacts"] = addBusinessContactinformations;
        return axios.post(reqPath, addBusinessReqParams)
            .then(response => {
                addBusinessAdminLoaderStatus = false;
                //   addBusinessAdminLoaderStatus = false;
                toast.success("Business Created Successfully", {
                    containerId: 'toastMsg',
                    onClose: () => {
                        dispatch(postAddBusinessAdminOnSubmitAfterCloseToastr())
                    }
                });
                dispatch(postAddBusinessAdminOnSubmit(addBusinessAdminLoaderStatus));
            }).catch(error => {
                addBusinessAdminLoaderStatus = false;
                addBusinessAdminLoaderStatus = false;
                if (error.response.status == 403) {
                    localStorage.clear();
                    toast.error("Session Expired ! Please login again", { containerId: 'toastMsg' });
                    window.location.href = "/";
                } else {
                    toast.error(error.response.data.message, { containerId: 'toastMsg' });
                    dispatch(postAddBusinessAdminOnSubmit(addBusinessAdminLoaderStatus));
                }

            })
    },
    addBusinessContactBack : () => {
      return dispatch(addBusinessContactBack())
    }
  }
}
export default connect(mapStateToProps,mapDispatchToProps)(withRouter(Contact));
