import React, { Component } from 'react';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import FormGroup from '@material-ui/core/FormGroup';
import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

import Input from "../../../components/UI/Input/Input";
import SGLoader from "../../../components/UI/Loader/SGLoader";
import {
    handleChecklistitemDialogCloseStatus,
    handleAddChecklistitemTextFieldChange,
    initializeAddChecklistItemForm,
    onSubmitAddChecklistItem,
    onUpdateCheckItem
} from "../../../actions/Checklist";
class AddChecklistItem extends Component{
    componentDidMount(){
        this.props.initializeAddChecklistItemForm()
    }
    render(){
        let{
            addCheckListItemForm,
            editChecklistItemDialogStatus,
            checkItemImageBase64,
            checkitemSubmitLoaderStatus,
            editDialogOpenLoaderStatus,
            activeCheckListItemForEditId,
            checkitemUpdateLoaderStatus,
            checkListItemInformation
        } = this.props.checkListState;
        let{
            menuItems
        } = this.props.sidebarState;
        const formElementArray = [];
        for(let key in addCheckListItemForm){
            formElementArray.push({
                id : key,
                config : addCheckListItemForm[key]
            })
        }

        let form = (
            <FormGroup row>
                <Grid container spacing = {24}>
                    {
                        formElementArray.map(formElement => {
                            if(editChecklistItemDialogStatus){
                                return(
                                    <Grid item xs = {12} sm = {6} key = {formElement.id}>
                                        <Input 
                                            key = {formElement.id}
                                            name = {formElement.config.elementConfig.label}
                                            elementType={formElement.config.elementType}
                                            elementConfig={formElement.config.elementConfig}
                                            value={formElement.config.value}
                                            menuItems = {formElement.config.menuItems}
                                            error = {formElement.config.errorStatus}
                                            helperText = {formElement.config.errorMessage}
                                            changed = {(event) => this.props.handleAddChecklistitemTextFieldChange(formElement.id, event)}
                                            touched = {(event) => this.props.handleAddChecklistitemTextFieldChange(formElement.id, event)}
                                            disabledStatus={formElement.config.disabledStatus}
                                            maxLength={formElement.config.maxLength}
                                        />
                                    </Grid>
                                )
                            }else{
                                if(formElement.id !== 'checklist_item_status'){
                                    return(
                                        <Grid item xs = {12} sm = {6} key = {formElement.id}>
                                            <Input 
                                                key = {formElement.id}
                                                name = {formElement.config.elementConfig.label}
                                                elementType={formElement.config.elementType}
                                                elementConfig={formElement.config.elementConfig}
                                                value={formElement.config.value}
                                                menuItems = {formElement.config.menuItems}
                                                error = {formElement.config.errorStatus}
                                                helperText = {formElement.config.errorMessage}
                                                changed = {(event) => this.props.handleAddChecklistitemTextFieldChange(formElement.id, event)}
                                                touched = {(event) => this.props.handleAddChecklistitemTextFieldChange(formElement.id, event)}
                                                disabledStatus={formElement.config.disabledStatus}
                                                maxLength={formElement.config.maxLength}
                                            />
                                        </Grid>
                                    )
                                }

                            }
                        }
                        )
                    }
                        <Grid
                            container
                            spacing={24} 
                            direction="row"
                            justify="flex-end"
                            alignItems="center"
                        >
                        {
                            editChecklistItemDialogStatus ?
                                <Grid item className = "mt-2 mb-2"> 
                                    {
                                        checkitemUpdateLoaderStatus  ?
                                        <CircularProgress /> :
                                        <div>
                                            <Button
                                                variant="contained" 
                                                color="primary" 
                                                disabled={false}
                                                className = "mr-2"
                                                onClick = {() => {this.props.onUpdateCheckItem(addCheckListItemForm, checkItemImageBase64, activeCheckListItemForEditId, checkListItemInformation)}}
                                            >
                                                Update
                                            </Button>
                                            <Button
                                                variant="contained" 
                                                color="default" 
                                                disabled={false}
                                                onClick = {() => {this.props.handleChecklistitemDialogCloseStatus()}}
                                            >
                                                Cancel
                                            </Button>
                                        </div>
                                    }
                                </Grid>
                                :
                                <Grid item> 
                                    {
                                       checkitemSubmitLoaderStatus ? <CircularProgress color = "primary" /> :
                                       <Button
                                            variant="contained" 
                                            color="primary" 
                                            disabled={false}
                                            onClick = {() => this.props.onSubmitAddChecklistItem(addCheckListItemForm, checkItemImageBase64, menuItems['business_checklist'].submenu['list_checklist_items'].urlpattern, this.props)}
                                        >
                                            Submit
                                        </Button>
                                    }

                                </Grid>
                        }
                        </Grid>

                </Grid>
            </FormGroup>
        )
        return(
            <div className = "container">
                <Card className = "pl-3 pr-3 pt-3">
                    <div>
                        <Grid container spacing={24} className = "pt-2">
                            <Grid item xs={12}>
                                <CardHeader title={editChecklistItemDialogStatus ? "Edit Checkitem" : "Add Checkitem"}/>
                            </Grid>
                        </Grid>
                    </div>
                    <CardContent>
                        {
                            editDialogOpenLoaderStatus ? 
                            <div style = {{height : "30rem", width : "900px"}} className = "d-flex justify-content-center align-items-center">
                                <SGLoader/>
                            </div>
                             : form
                        }
                        
                    </CardContent>
                </Card>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return{
        checkListState : state.checkList,
        sidebarState : state.sidebarState
    }
}

const mapDispatchToProps = dispatch => {
    return{
        handleChecklistitemDialogCloseStatus : () => {
            return dispatch(handleChecklistitemDialogCloseStatus())
        },
        handleAddChecklistitemTextFieldChange : (fieldId, event) => {
            return dispatch(handleAddChecklistitemTextFieldChange(fieldId, event))
        },
        initializeAddChecklistItemForm : () => {
            return dispatch(initializeAddChecklistItemForm())
        },
        onSubmitAddChecklistItem : (addCheckListItemForm, checkItemImageBase64, url, props) => {
            return dispatch(onSubmitAddChecklistItem(addCheckListItemForm, checkItemImageBase64, url, props))
        },
        onUpdateCheckItem : (addCheckListItemForm, checkItemImageBase64, activeCheckListItemForEditId, checkListItemInformation) => {
            return dispatch(onUpdateCheckItem(addCheckListItemForm, checkItemImageBase64, activeCheckListItemForEditId, checkListItemInformation))
        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(AddChecklistItem);