import { display } from '@material-ui/system';
import { MANAGE_EDIT_POTENTIAL_NON_COMPLIANCE_OPEN_DIALOG } from '../actions/ActionType';
import { MANAGE_EDIT_POTENTIAL_NON_COMPLIANCE_CLOSE_DIALOG } from '../actions/ActionType'

const initialState = {
    editPotentialNonCompliancelist : {
        'driver_first_name' : {
            elementType : 'input',
            elementConfig : {
                type : 'text',
                label : 'Driver First Name'
            },
            value : '',
            errorStatus : false,
            errorMessage : "Enter Driver First Name",
            maxLength : 60
        },
        'driver_last_name' : {
            elementType : 'input',
            elementConfig : {
                type : 'text',
                label : 'Driver Last Name'
            },
            value : '',
            errorStatus : false,
            errorMessage : "Enter Driver Last Name",
            maxLength : 60
        },
        'driver_license' : {
            elementType : 'input',
            elementConfig : {
                type : 'text',
                label : 'License Number'
            },
            value : '',
            errorStatus : false,
            errorMessage : "Enter License Number",
            maxLength:10
        },
        'driver_id' : {
            elementType : 'input',
            elementConfig : {
                type : 'text',
                label : 'Driver Id'
            },
            value : '',
            errorStatus : false,
            errorMessage : "Enter driver Id",
            maxLength:10
        },
        'vehicle_id' : {
            elementType : 'input',
            elementConfig : {
                type : 'text',
                label : 'Vehicle Type'
            },
            value : '',
            errorStatus : false,
            errorMessage : "Please enter vehicle",
            maxLength:10
        },
        'breach_type' : {
            elementType : 'input',
            elementConfig : {
                type : 'text',
                label : 'Breach Type'
            },
            value : '',
            errorStatus : false,
            errorMessage : "enter Breach type",
            maxLength:10
        },
        'breach_createdtimeoffset' : {
            elementType : 'input',
            elementConfig : {
                type : 'text',
                label : 'DateTime'
            },
            value : '',
            errorStatus : false,
            errorMessage : "enter date and time",
            maxLength:10
        },
        'investigation_comment' : {
            elementType : 'input',
            elementConfig : {
                type : 'text',
                label : 'Comment'
            },
            value : '',
            errorStatus : false,
            errorMessage : "enter comment",
            maxLength:1000
        },
        'status' : {
            elementType : 'select',
            elementConfig : {
                type : 'text',
                label : 'Status Of Investigation'
            },
            value : '',
            errorStatus : false,
            errorMessage : "Select Home BPlease select status",
            maxLength : 500,
            menuItems : {
                'PENDING': {
                    name: "pending",
                    value: "PENDING"
                },
                'INPROGRESS': {
                    name: "inProgress",
                    value: "INPROGRESS"
                },
                'COMPLETED': {
                    name: "completed",
                    value: "COMPLETED"
                }
            }
        }
    },
    openEditpotentialNonComplianceDialogStatus : false,
}

const reducerPotentialNonCompliance = (state = initialState, action) => {
    let localState = Object.assign({}, state);
    switch(action.type) {    
     case MANAGE_EDIT_POTENTIAL_NON_COMPLIANCE_OPEN_DIALOG :
            //Add Potential Non Compliance List
            localState.editPotentialNonCompliancelist['driver_first_name'].value = action.eventInfo['driver_firstname'];
            localState.editPotentialNonCompliancelist['driver_last_name'].value = action.eventInfo['driver_lastname'];           
            localState.editPotentialNonCompliancelist['driver_license'].value = action.eventInfo["driver_license"];
            localState.editPotentialNonCompliancelist['driver_id'].value = action.eventInfo['driver_id'];
            localState.editPotentialNonCompliancelist['vehicle_id'].value = action.eventInfo['vehicle_id'];
            localState.editPotentialNonCompliancelist['breach_type'].value = action.eventInfo['breach_type'];
            localState.editPotentialNonCompliancelist['breach_createdtimeoffset'].value = action.eventInfo['breach_createdtimeoffset'];
            localState.editPotentialNonCompliancelist['investigation_comment'].value = action.eventInfo['investigation_comment'];
            localState.editPotentialNonCompliancelist['status'].value = action.eventInfo['status'];

            Object.keys(localState.editPotentialNonCompliancelist).map(inputField => {
                localState.editPotentialNonCompliancelist[inputField].errorStatus = false;
            })

             //Disabled Field
            localState.editPotentialNonCompliancelist['driver_first_name'].disabledStatus = true;
            localState.editPotentialNonCompliancelist['driver_last_name'].disabledStatus = true;
            localState.editPotentialNonCompliancelist['driver_license'].disabledStatus = true;
            localState.editPotentialNonCompliancelist['driver_id'].disabledStatus = true;
            localState.editPotentialNonCompliancelist['vehicle_id'].disabledStatus = true;
            localState.editPotentialNonCompliancelist['breach_type'].disabledStatus = true;
            localState.editPotentialNonCompliancelist['breach_createdtimeoffset'].disabledStatus = true;

            localState.openEditpotentialNonComplianceDialogStatus = true;
            return localState;
        case MANAGE_EDIT_POTENTIAL_NON_COMPLIANCE_CLOSE_DIALOG :
            localState.openEditpotentialNonComplianceDialogStatus = false;
            return localState;  
        default :
            return state;    
    }

}

export default reducerPotentialNonCompliance;