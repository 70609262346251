import {
  SET_LOAD_TYPE_TEXT_FIELD,
  SET_LOADTYPE_LIST,
  SUBMIT_LOADTYPE_INFO,
  UPDATE_LOADTYPE_INFO,
  SET_DEFAULT_VALUE_TO_LOAD_TYPES,
  MEASUREMENT_TYPE_LIST,
  MASSVARIABLETYPE_LIST,
  LOADTYPE_OPEN_DIALOG,
  LOADTYPE_CLOSE_DIALOG,
} from "../actions/ActionType";
import TextField from "@material-ui/core/TextField";
import React from 'react';

const initialState = {
  loadTypeForm: {
    mass_limit: {
      elementType: 'select',
      elementConfig: {
        type: 'text',
        label: 'Mass Limit'
      },
      value: '',
      errorStatus: false,
      errorMessage: "Enter Mass Limit",
      menuItems: {
        'HML': {
          name: "HML",
          value: 'HML'
        },
        'CML': {
          name: "CML",
          value: 'CML'
        },
        'GML': {
          name: "GML",
          value: 'GML'
        },
      },
    },
    load_types: {
      elementType: 'input',
      elementConfig: {
        type: 'text',
        label: 'Enter Load Type'
      },
      value: '',
      errorStatus: false,
      errorMessage: "Enter Load Type",
      maxLength: 256
    },
    measurement_type: {
      elementType: 'select',
      elementConfig: {
        type: 'text',
        label: 'Measurement Type'
      },
      value: '',
      errorStatus: false,
      errorMessage: "Enter Measurement Type",
      menuItems: {
        'select_measurement_type': {
          name: "Select Measurement Type",
          value: ''
        },
      },
    },
    specific_gravity: {
      elementType: 'input',
      elementConfig: {
        type: 'text',
        label: 'Specific Gravity',
      },
      value: '',
      errorStatus: false,
      errorMessage: "Enter Specific Gravity",
      maxLength: 256,
      disabledStatus: true
    },
    // mass_variable_type: {
    //   elementType: 'select',
    //   elementConfig: {
    //     type: 'text',
    //     label: 'Mass Variable Type'
    //   },
    //   value: '',
    //   errorStatus: false,
    //   errorMessage: "Enter Mass Variable Type",
    //   menuItems: {
    //     'select_mass_variable_type': {
    //       name: "Select Mass Variable",
    //       value: ''
    //     },
    //   },
    // },
  //   status : {
  //     elementType : 'select',
  //     elementConfig : {
  //         type : 'text',
  //         label : 'Load Status'
  //     },
  //     value : '',
  //     errorStatus : false,
  //     disabledStatus : false,
  //     errorMessage : "Enter Load Status",
  //     menuItems : {
  //         'select_status' : {
  //             name : "Select Status",
  //             value : ''
  //         },
  //         'active' : {
  //             name : "Active",
  //             value : "active"
  //         },
  //         'inactive' : {
  //             name : "Inactive",
  //             value : "inactive"
  //         },
  //     },
  // }

  },
  activeLoadTypeForEdit: '',
  activeLoadType: '',
  editLoadTypeDialogOpen: false,
  showLoadTypeUpdateLoaderStatus : false,
  loadtypesListcols: [
    {
      title: "ID", field: "_id",
    },
    {
      title: "Mass Limit", field: "mass_limit",
    },
    {
      title: "Load Type",
      field: "loadType",
    },
    // {
    //   title: "Measurement Type",
    //   field: "mm_measurement_type",

    // },
    // {
    //   title: "Mass Variable Type",
    //   field: "mm_massvariable_type",

    // },
    // {
    //   title: "Specific Gravity",
    //   field: "specificGravity"
    // },
    {
      title: "Status",
      field: "status",
      lookup: {
        true: "Active",
        false: "Inactive"
      },
    }
  ],
  loadtypesListrows: [],

  //Loader Status
  loadTypeListLoaderStatus: false
};

const setUpdateLoadType = (state, action) => {
  // console.log(action.showLoadTypeUpdateLoaderStatus)
  let updatedRecord = action.updatedData;
  if(updatedRecord !== undefined){
    const updatedRecordIndex = state.loadtypesListrows.findIndex(d => {
        return d._id === updatedRecord._id;
    });
   
    state.loadtypesListrows[updatedRecordIndex].mass_limit = updatedRecord.mass_limit;
    state.loadtypesListrows[updatedRecordIndex].loadType = updatedRecord.loadType;
    state.loadtypesListrows[updatedRecordIndex].measurement_type = updatedRecord.mm_measurement_type;
    state.loadtypesListrows[updatedRecordIndex].specific_gravity = updatedRecord.specific_gravity;
    // state.loadtypesListrows[updatedRecordIndex].mass_variable_type = updatedRecord.mass_variable_type;
    state.loadtypesListrows[updatedRecordIndex]._id = updatedRecord._id;
  }

  if(!action.showLoadTypeUpdateLoaderStatus){
    state.editLoadTypeDialogOpen = false;
  }
  state.showLoadTypeUpdateLoaderStatus = action.showLoadTypeUpdateLoaderStatus;
  return state;
}


// LOADTYPE_OPEN_DIALOG
const setLoadTypeOpenDialogStatus = (state, action) => {
  // console.log('reducer ',action.rowData)
  Object.keys(state.loadTypeForm).map(inputField => {
    state.loadTypeForm[inputField].errorStatus = false;
  })
  state.loadTypeForm['mass_limit'].value = action.rowData['mass_limit'];
  state.loadTypeForm['load_types'].value = action.rowData['loadType'];
  state.loadTypeForm['measurement_type'].name = action.rowData['mm_measurement_type'];
  state.loadTypeForm['specific_gravity'].value = action.rowData['specificGravity'];
  // state.loadTypeForm['mass_variable_type'].name = action.rowData['mm_massvariable_type'];
  state.activeLoadTypeForEdit = action.rowData['_id']
  state.editLoadTypeDialogOpen = true;
  return state;
}

const setLoadTypeCloseDialog = (state, action) => {
  state.editLoadTypeDialogOpen = false;
  return state;
}

const loadtypes = (state = initialState, action) => {
  let localState = Object.assign({}, state);
  switch (action.type) {
    case SET_DEFAULT_VALUE_TO_LOAD_TYPES:
      Object.keys(localState.loadTypeForm).map((inputField) => {
        localState.loadTypeForm[inputField].value = '';
        localState.loadTypeForm[inputField].errorStatus = false;
      })
      return localState;
    case SET_LOAD_TYPE_TEXT_FIELD:
      // if (action.data === '') {
      //   localState.loadTypeForm[action.textField].errorStatus = true;
      // } else {
      //   localState.loadTypeForm[action.textField].errorStatus = false;
      // }
      // console.log("actiondata",action)
      if (action.textField === "measurement_type" && action.data=== 'Flow Measurement'){
        state.loadTypeForm['specific_gravity'].disabledStatus = false;
      }
      else if(action.textField === "specific_gravity") {
        state.loadTypeForm['specific_gravity'].disabledStatus = false;
      }
      else{
        state.loadTypeForm['specific_gravity'].disabledStatus = true;
        localState.loadTypeForm['specific_gravity'].value = '';
      }
      localState.loadTypeForm[action.textField].value = action.data;
      return localState;
    case SET_LOADTYPE_LIST:
      localState.loadTypeListLoaderStatus = action.loadTypeListLoaderStatus;
      localState.loadtypesListrows = action.loadTypeList;
      return localState;
    case SUBMIT_LOADTYPE_INFO:
      localState.loadTypeForm = action.loadTypeForm;
      localState.showLoaderStatus = action.showLoaderStatus;
      localState.loadtypesListrows = action.loadtypesListrows;
      return localState;
    case UPDATE_LOADTYPE_INFO:
    return setUpdateLoadType(localState, action);

    case MEASUREMENT_TYPE_LIST:
      localState.loadTypeForm.measurement_type.menuItems = action.measurementtypelist;
      return localState;
    case MASSVARIABLETYPE_LIST:
      localState.loadTypeForm.mass_variable_type.menuItems = action.massvariabletypelist;
      return localState;
    case LOADTYPE_OPEN_DIALOG:
      return setLoadTypeOpenDialogStatus(localState, action);
    case LOADTYPE_CLOSE_DIALOG:
      return setLoadTypeCloseDialog(localState, action);
    default:
      return localState;
  }
};

export default loadtypes;
