import {
	SET_ITEM_CHECKBOX_LIST,
	SUBMIT_CONTRACT_INFO,
	POST_HANDLE_ITEM_CHECKBOX_CHANGE,
	SET_CONTRACT_ID
} from "../actions/ActionType";
import TextField from "@material-ui/core/TextField";
import React from 'react';

const initialState = {
	contractId: '',
	itemsCheckbox: {},
	ContractSetupLoaderStatus: false
};


const posthandleitemsCheckBoxChange = (state, action) => {
	state.itemsCheckbox[action.formElementId].elementConfig.checked = !state.itemsCheckbox[action.formElementId].elementConfig.checked;
	return state;
}

const ContractSetup = (state = initialState, action) => {
	let localState = Object.assign({}, state);
	switch (action.type) {
		case SET_ITEM_CHECKBOX_LIST:
			localState.ContractSetupLoaderStatus = action.ContractSetupLoaderStatus;
			localState.itemsCheckbox = action.itemsCheckboxlist;
			return localState;
		case SET_CONTRACT_ID:
			localState.contractId = action.contractId;
			return localState;
		case SUBMIT_CONTRACT_INFO:
			localState.itemsCheckbox = action.itemsCheckbox;
			localState.showLoaderStatus = action.showLoaderStatus;
			return localState;
		case POST_HANDLE_ITEM_CHECKBOX_CHANGE:
			return posthandleitemsCheckBoxChange(localState, action);
		default:
			return localState;
	}
};

export default ContractSetup;
