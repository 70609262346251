import React, { Component } from "react";
import MaterialTable from "material-table";
import { connect } from "react-redux";
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';

import {
    getSGAdminUsersList,
    editSGAdminUser,
    deleteSGAdminUser
} from '../../actions/SGAdminUsers';
import Dialog from "@material-ui/core/Dialog/Dialog";
import FormGroup from "@material-ui/core/FormGroup/FormGroup";
import {toast} from "react-toastify";
import axios from "../../axios";

class ListSGAdminUsers extends Component {

  state = { isChangePasswordOpen: false, newPassword: null, confirmNewPassword: null, oldPassword: null };

  componentDidMount(){
      this.props.getSGAdminUsersList();
  }

  closeChangePasswordDialog() {
      // Clear all field values
      this.state.newPassword.value = '';
      this.state.confirmNewPassword.value = '';
      this.state.oldPassword.value = '';
      // Close the dialog
      this.setState({isChangePasswordOpen: false});
  }

  submitChangePassword() {
      let newPassword = this.state.newPassword.value;
      let confirmNewPassword = this.state.confirmNewPassword.value;
      let oldPassword = this.state.oldPassword.value;
      // Check password null
      if (!(oldPassword)) {
          toast.error("Please provide the old password.", {
              containerId: 'toastMsg',
          });
          return;
      }
      if (!(newPassword && confirmNewPassword)) {
          toast.error("Please provide the new password and confirmation.", {
              containerId: 'toastMsg',
          });
          return;
      }
      // Check password confirmation
      if (confirmNewPassword !== newPassword) {
          toast.error("Password confirmation doesn't match.", {
              containerId: 'toastMsg',
          });
          return;
      }

      let reqURL = "/sg/admin/user/" + localStorage.getItem("Platform-User");
      let adminUserObject = {user_id: localStorage.getItem("Platform-User"), oldpassword: oldPassword, newpassword: newPassword};
      axios.put(reqURL, adminUserObject).then(response => {
          // Clear all field values
          this.state.newPassword.value = '';
          this.state.confirmNewPassword.value = '';
          this.state.oldPassword.value = '';
          // Close the dialog
          this.setState({isChangePasswordOpen: false});

          toast.success("Changes saved successfully.", {
              containerId: 'toastMsg',
          });
      }).catch(error => {
          toast.error(error.response.data.message, {
              containerId: 'toastMsg',
          });
      })
  }

  render() {
    let {
      sgAdminUserRowData,
      showLoaderStatus,
      sgadminColumns
    } = this.props.sgAdminUsers;

    return (
       <div
        style = {{marginTop : '2rem', marginBottom : "2rem"}}
        className = "container"
      >
        <Paper>
            <div className = "pt-1 pl-3">
              <span className = "d-flex">
                <div className = "mr-auto" style = {{paddingTop : "0"}}>
                    <h4 style = {{marginTop : "1rem", textTransform : "uppercase"}}>SG Admin Users</h4>
                </div>
                <div className = "d-flex flex-wrap align-content-center">
                    <Button
                        variant="contained"
                        color="primary"
                        onClick = {() => this.props.history.push("/sgadminportal/sgadmin")}
                        className = "mr-2"
                    >
                        <i className="material-icons" style = {{paddingRight : "0.5rem"}}>
                            add_circle_outline
                        </i>
                        Add SG Admin User
                    </Button>
                </div>
              </span>
            </div>
            {
              showLoaderStatus ?
                <div style = {{height : "20rem"}} className = "d-flex justify-content-center align-items-center">
                    <CircularProgress color = "primary" />
                </div>:
                <MaterialTable
                  columns={sgadminColumns}
                  data={sgAdminUserRowData}
                  title=""
                  actions={[
                    {
                      icon: 'delete',
                      tooltip: 'Delete SG User Admin',
                      onClick: (event, rowData) => this.props.deleteSGAdminUser(rowData, sgAdminUserRowData)
                    },
                    rowData => ({
                      icon: 'edit',
                      tooltip: 'Edit SG User Admin',
                      onClick: (event, rowData) => this.setState({isChangePasswordOpen: true}),
                      disabled: localStorage.getItem('Platform-User') !== rowData.name
                    })
                  ]}
                  options={{ actionsColumnIndex: -1}}
                />
              }
          </Paper>

           <Dialog
               open={this.state.isChangePasswordOpen}
               onClose={() => {this.closeChangePasswordDialog()}}
               maxWidth = 'md'
               aria-labelledby="alert-dialog-title"
               aria-describedby="alert-dialog-description">
               <div style={{"margin-left": "30px", "margin-right": "30px", "margin-bottom": "30px"}}>
                   <FormGroup row>
                       <div style={{"margin-left": "30px", "margin-right": "30px", "margin-bottom": "30px"}}>
                           <h4>Change Password</h4>
                           <table>
                               <tr>
                                   <td>Current Password</td>
                                   <td><input type="password" id="oldPassword" ref={el => this.state.oldPassword = el}/></td>
                               </tr>
                               <tr>
                                   <td>New Password</td>
                                   <td><input type="password" id="newPassword" ref={el => this.state.newPassword = el}/></td>
                               </tr>
                               <tr>
                                   <td>Confirm Password</td>
                                   <td><input type="password" id="confirmNewPassword" ref={el => this.state.confirmNewPassword = el}/></td>
                               </tr>
                           </table>
                       </div>
                   </FormGroup>
                   <button className="MuiButtonBase-root MuiButton-root MuiButton-contained mr-3 MuiButton-containedPrimary" onClick={() => this.submitChangePassword()}>Submit</button>
                   <button className="MuiButtonBase-root MuiButton-root MuiButton-contained mr-3" onClick={() => this.closeChangePasswordDialog()}>Close
                   </button>
               </div>
           </Dialog>

      </div>
    );
  }
}

const mapStateToProps = state => {
    return{
      sgAdminUsers : state.sgAdminUsers
    }
};

const mapDispatchToProps = dispatch => {
  return {
    getSGAdminUsersList : () => {
        return dispatch(getSGAdminUsersList())
    },
    editSGAdminUser : (rowData, sgAdminUserRowData) => {
      return dispatch(editSGAdminUser(rowData, sgAdminUserRowData))
    },
    deleteSGAdminUser : (rowData, sgAdminUserRowData) => {
      return dispatch(deleteSGAdminUser(rowData, sgAdminUserRowData))
    }
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(ListSGAdminUsers);
