import { GET_PARTNER_USERS_FAIL, GET_PARTNER_USERS_SUCCESS, GET_PARTNER_USERS, CREATE_PARTNER_USERS_FAIL, CREATE_PARTNER_USERS, CREATE_PARTNER_USERS_SUCCESS } from "../actions/ActionType";

const initialState = {
  partnerUsers: [],
  apiLoading: false
};


export const partnerUserReducer = (state = initialState, action) => {
  switch (action.type) {

    case GET_PARTNER_USERS_SUCCESS:
      return {
        ...state,
        partnerUsers: action.partnerUsers,
        apiLoading: false
      };

    case GET_PARTNER_USERS_FAIL:
      return {
        ...state,
        partnerUsers: [],
        apiLoading: false
      };


    case GET_PARTNER_USERS:
      return {
        ...state,
        apiLoading: true
      };
    
    
    case CREATE_PARTNER_USERS_FAIL:
    case CREATE_PARTNER_USERS_SUCCESS:
      return {
        ...state,
        apiLoading: false
      };

    case CREATE_PARTNER_USERS:
      return {
        ...state,
        apiLoading: true
      };


    default:
      return state;

  }
};

