import React, { Component } from 'react';
import MaterialTable from 'material-table';
import {connect} from 'react-redux';
import {withRouter} from "react-router";
import Dialog from '@material-ui/core/Dialog';

import {getActulaURL} from "../../../utils/Helper";

import AddChecklistItem from "./AddChecklistItem";
import SGLoader from "../../../components/UI/Loader/SGLoader";
import SGContainer from "../../../components/UI/Container/SGContainer";
import SGPaper from "../../../components/UI/PaperContainer/PaperContainer";

import {
    handleOpenEditChecklistItemDialog,
    handleChecklistitemDialogCloseStatus,
    getCheckItems,
    getExampleImage
} from "../../../actions/Checklist";

import SGMaterialTable from "../../../components/UI/Table/SGMaterialTable";

class ListChecklistItem extends Component{3
    state = {
        checkListitemColumn : [
            {
                title : "Name", 
                field : "check_item_name"
            },
            {
                title : "Description", 
                field : "check_item_description"
            },
            {
                title : "Image", 
                field : "check_item_image",
                render : rowData => rowData.check_item_image !== 'No Image' ? <a href = "javascript:void(0);" style = {{color : "blue"}} onClick = {() => this.props.getExampleImage(rowData.id)}>{rowData.check_item_image}</a> : "No Image"
            },
            {
                title : "Status", 
                field : "status",
                lookup: { 
                    'active': 'Active', 
                    'inactive': 'Inactive' 
                }
            }
        ],
    }
    componentDidMount(){
        this.props.getCheckItems()
    }
    render(){
        let {
            checkListItemInformation,
            editChecklistItemDialogStatus,
            listCheckItemLoaderStatus,
            editDialogOpenLoaderStatus
        } = this.props.checkListState;
        let{
            menuItems
        } = this.props.sidebarState;
        return(
            <SGContainer>
                <SGPaper
                    title = "Checkitem List"
                    buttons = {[
                        {
                            color : "primary",
                            icon : "add_circle_outline",
                            name : "Add Checkitem",
                            action : () => this.props.history.push(getActulaURL(menuItems['business_checklist'].submenu['create_checklist_items'].urlpattern))
                        }
                    ]}
                >
                    {
                        listCheckItemLoaderStatus ? <SGLoader />  :
                        <SGMaterialTable
                            columns={this.state.checkListitemColumn}
                            data={checkListItemInformation}
                            title=""
                            actions={[
                                {
                                    icon: 'edit',
                                    tooltip: 'Edit',
                                    onClick: (event, rowData) => this.props.handleOpenEditChecklistItemDialog(rowData)
                                }
                            ]}
                        />
                    }
                </SGPaper>
                <Dialog
                    open={editChecklistItemDialogStatus}
                    onClose={() => {this.props.handleChecklistitemDialogCloseStatus()}}
                    maxWidth = 'md'
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <AddChecklistItem />
                </Dialog>
            </SGContainer>
        )
    }
}
const mapStateToProps = state => {
    return{
        checkListState : state.checkList,
        sidebarState : state.sidebarState,
    }
}

const mapDispatchToProps = dispatch => {
    return{
        handleOpenEditChecklistItemDialog : (checklistItem) => {
            return dispatch(handleOpenEditChecklistItemDialog(checklistItem))
        },
        handleChecklistitemDialogCloseStatus : () =>{
            return dispatch(handleChecklistitemDialogCloseStatus())
        },
        getCheckItems : () => {
            return dispatch(getCheckItems())
        },
        getExampleImage : (checkItemId) => {
            return dispatch(getExampleImage(checkItemId))
        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ListChecklistItem));