import React, { Component } from 'react'
import '../styles/bootstrap.min.css'
import '../styles/now-ui-kit.min5438.css?v=1.2.0'
import SmartDriverLogo from '../images/smart_driver.png'
import { connect } from 'react-redux';
import LoginImage from '../images/login1.jpg'
import {
    confirm,
    handleTextFieldChange
} from '../actions/confirmation';

import CircularProgress from '@material-ui/core/CircularProgress';
class Confirmation extends Component {
  constructor(props){
    super(props)
    this.state={
    }
  }
  render() {
    let {showLoaderStatus, confirmTextField, disableConfirm} = this.props.confirm;
    let confirmtoken  = ((window.location.pathname).split('/'))[2];
    return (
      <div className="Login">
        <div className="confirm-page sidebar-collapse">
        <div className="page-header clear-filter" filter-color="blue">
        <div className="page-header-image" style={{backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),url(${LoginImage})`, transform: 'scaleX(-1)'}}> </div>
        <div className="content">
          <div className="container">
            <div className="col-md-4 ml-auto mr-auto">
              <div className="card card-confirm card-plain">
                <div className="card-header text-center">
                  <img alt="logo" style={{width : "60%" }} src={SmartDriverLogo} />
                </div>

                <form className="d-flex align-items-center justify-content-center">
                <div className="card-body">
                    <div>
                        {
                            Object.keys(confirmTextField).map((textFields) => {
                                return(
                                <div className="input-group no-border input-lg" key = {confirmTextField[textFields].name}>
                                    <div className="input-group-prepend">
                                        <span className="input-group-text">
                                            <i className={confirmTextField[textFields].icon}></i>
                                        </span>
                                    </div>
                                    <input 
                                        type={confirmTextField[textFields].type}
                                        className="form-control" 
                                        placeholder={confirmTextField[textFields].name}
                                        value = {confirmTextField[textFields].value}
                                        onChange = {(event) => {this.props.handleTextFieldChange(textFields, event,confirmTextField)}}
                                    />
                                </div>
                                
                                )
                            })
                        }

                        {
                            showLoaderStatus ? <CircularProgress style = {{color : "#69bce6"}} /> : 
                                <div className="text-center">
                                    <button
                                        type = "button"
                                        onClick = {() => this.props.confirmEmail(confirmTextField, confirmtoken)} 
                                        className="btn btn-info btn-round btn-lg btn-block btn-confirm"
                                        disabled={disableConfirm}
                                        style={{cursor:disableConfirm?'not-allowed':''}}
                                    >
                                        Confirm
                                    </button>
                                </div>
                        }
                    </div>
                </div>

            </form>
            </div>
          </div>
        </div>
      </div>
      <footer className="footer" >
          <div className="container">
              <div className="copyright" id="copyright">
                  &copy; 2019, Powered by <a href="https://stepglobal.com/" style={{color:'#549bff'}} target="_blank">StepGlobal</a>. 
              </div>
          </div>
      </footer>
      </div>
    </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
    return {
        confirm : state.confirm
    }
}

const mapDispatchToProps = dispatch => {
    return{
        confirmEmail : (confirmControls,confirmtoken) => {
            return dispatch(confirm(confirmControls, confirmtoken))
        },
        handleTextFieldChange : (textField, event,confirmTextField) => {
            return dispatch(handleTextFieldChange(textField, event,confirmTextField))
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Confirmation);
