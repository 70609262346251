import {
    DEPOT_CREATE,
    EDIT_DEPOTS,
    LOAD_DEPOTS,
    SET_DEPOT_INPUT_FIELD,
    SET_EMPTY_DEPOT_FORM_FIELDS
} from './ActionType';
import axios from "../axios";
import {toast} from "react-toastify";
import {getActulaURL} from "../utils/Helper";
import {setSubmittedData} from "./Driver";

/***
 * Initialize Add Depot Form, this will return empty list of fields to form
 * @param distributorUserForm
 * @returns {function(*): *}
 */
export const initializeAddDepotForm = () => {
    return (dispatch) => {
        return dispatch({
            type: SET_EMPTY_DEPOT_FORM_FIELDS
        })
    }
}

export const depotInputHandler = (inputFiledId, event) => {
    return (dispatch) => {
        return dispatch({
            type: SET_DEPOT_INPUT_FIELD,
            inputFiledId: inputFiledId,
            data: event.target.value
        });
    }
}

export const onSubmitDeport = (deportFormFields, props, callBackUrl, showDepotEditDialog) => {
    return (dispatch) => {
        let deportFormDataIsValid = true;
        let depotCreateSpinnerStatus = false;
        Object.keys(deportFormFields).map(inputField => {
            if (deportFormFields[inputField].value === '') {
                deportFormFields[inputField].errorStatus = true;
                deportFormDataIsValid = false;
            }
        })

        dispatch(fireDepotCreateEvent(depotCreateSpinnerStatus))
        if (deportFormDataIsValid) {

            let reqParams = {};
            reqParams['name'] = deportFormFields['name'].value;
            reqParams['geocode'] = deportFormFields['geocode'].value;
            reqParams['suburb'] = deportFormFields['suburb'].value;
            reqParams['manager_id'] = deportFormFields['manager_id'].value;
            reqParams['state'] = deportFormFields['state'].value;
            reqParams['state'] = deportFormFields['state'].value;
            reqParams['region'] = deportFormFields['region'].value;
            reqParams['address'] = deportFormFields['address'].value;
            dispatch(fireDepotCreateEvent(depotCreateSpinnerStatus))
            depotCreateSpinnerStatus = true;
            let reqURL = "/businesses/" + localStorage.getItem('lastSelectedBusinessId') + "/depots";
            if (showDepotEditDialog) {
                reqURL = "/businesses/" + localStorage.getItem('lastSelectedBusinessId') + "/depots/" + props.depotState.editedDepotId;
                return axios.put(reqURL, reqParams)
                    .then(() => {
                        depotCreateSpinnerStatus = false;
                        toast.success("Deport Updated Successfully",
                            {
                                containerId: 'toastMsg',
                                onClose: () => {
                                    dispatch(toggleDepotEditDialog({}, false));
                                    dispatch(getDepots())
                                }
                            });

                    })
                    .catch(error => {
                        depotCreateSpinnerStatus = false;
                        toast.error(error.response.data.message, {containerId: 'toastMsg'});
                        dispatch(fireLoadDepotsEvent(depotCreateSpinnerStatus))
                    })
            }
            return axios.post(reqURL, reqParams)
                .then(() => {
                    depotCreateSpinnerStatus = false;
                    toast.success("Deport Created Successfully",
                        {
                            containerId: 'toastMsg',
                            onClose: () => props.history.push(callBackUrl)
                        });
                    dispatch(fireLoadDepotsEvent(depotCreateSpinnerStatus))
                })
                .catch(error => {
                    depotCreateSpinnerStatus = false;
                    toast.error(error.response.data.message, {containerId: 'toastMsg'});
                    dispatch(fireLoadDepotsEvent(depotCreateSpinnerStatus))
                })
        }
    }
}

export const deLinkDepot = (depot) =>{
    console.log(depot);
    return (dispatch) => {
        let reqURL = "/businesses/" + localStorage.getItem('lastSelectedBusinessId') + "/depots/de-link?depot_map_id="+depot._id;
        return axios.delete(reqURL, {}
    )
            .then(response => {

                toast.success("Depot successfully de-linked", {
                    containerId: 'toastMsg',
                    onClose : () => window.location.reload()
                });

            })
            .catch(error => {
                toast.error("Unable to de-link depot. [" + error.message+ " ]" , {containerId: 'toastMsg'});
            })
    }
}


export const linkDepotToDriver = (data) => {
    console.log(data);
    return (dispatch) => {
        let linkData = {
            "driver_id":data.selectedDriver._id
        };
        let reqURL = "/businesses/" + localStorage.getItem('lastSelectedBusinessId') + "/depots/" + data.selectedItem+ "/link";
        return axios.post(reqURL, linkData)
            .then(response => {

                toast.success("Depot successfully linked", {
                    containerId: 'toastMsg',
                    onClose : () => window.location.reload()
                });

            })
            .catch(error => {
                toast.error("Unable to link depot. Depot may already linked.", {containerId: 'toastMsg'});
            })
    }
}

export const getDepots = () => {
    let showDataLoadSpinnerStatus = true;
    let depots = [];
    return (dispatch) => {
        dispatch(fireLoadDepotsEvent(depots, showDataLoadSpinnerStatus));
        let path = "/businesses/" + localStorage.getItem('lastSelectedBusinessId') + "/depots";
        return axios.get(path)
            .then(response => {
                showDataLoadSpinnerStatus = false;
                dispatch(fireLoadDepotsEvent(response.data.data, showDataLoadSpinnerStatus));
            })
            .catch(error => {
                showDataLoadSpinnerStatus = false;
                if (error.response.status === 403) {
                    localStorage.clear();
                    window.location.href = "/";
                }
                dispatch(fireLoadDepotsEvent(depots, showDataLoadSpinnerStatus));
            })
    }
}

export const toggleDepotEditDialog = (depotObj, showEditDialog) => {
    return (dispatch) => {
        dispatch(fireEditDepotEvent(depotObj, showEditDialog));
    }
}

export const deleteDepot = (depotObj) => {
    return (dispatch) => {
        let path = "/businesses/" + localStorage.getItem('lastSelectedBusinessId') + "/depots/"+depotObj._id;
        return axios.delete(path)
            .then(() => {
                toast.success("Deport Deleted Successfully",
                    {
                        containerId: 'toastMsg',
                        onClose: () =>  dispatch(getDepots())
                    });
            })
            .catch(error => {
                if (error.response.status === 403) {
                    localStorage.clear();
                    window.location.href = "/";
                }
            })
    }
}

export const fireEditDepotEvent = (depotObj, showDepotEditDialog) => {
    return {
        type: EDIT_DEPOTS,
        depot: depotObj,
        showDepotEditDialog: showDepotEditDialog
    }
}


export const fireLoadDepotsEvent = (depots, showDataLoadSpinnerStatus) => {
    return {
        type: LOAD_DEPOTS,
        depots: depots,
        showDataLoadSpinnerStatus: showDataLoadSpinnerStatus
    }
}

export const fireDepotCreateEvent = (depots, depotCreateSpinnerStatus) => {
    return {
        type: DEPOT_CREATE,
        showDataLoadSpinnerStatus: depotCreateSpinnerStatus
    }
}

