import {
    SUBMIT_BUSINESS_INFO,
    UPDATE_BUSINESS_INFO,
    BUSINESS_TEXTFIELD_CHANGE,
    BUSINESS_OPEN_DIALOG,
    BUSINESS_CLOSE_DIALOG,
    BUSINESS_MODAL_CLOSE,
    SEARCH_BUSINESS_TEXTFIELD_CHANGE,
    ON_SEARCH_BUSINESS,
    GET_BUSINESS_DETAILS,
    BUSINESS_MODAL_OPEN,
    SET_INITIAL_VALUE_TO_ADD_DISTRIBUTOR_FORM,
    SET_EDIT_BUSINESS_OPEN_DIALOG_STATUS,
    SET_EDIT_DRIVER_DIALOG_STATUS_TO_CLOSE,
    SET_BUSINESS_FIRST_LOGIN_STEPPER_VALUE,
    SET_BUSINESS_FIRST_LOGIN_STEPPER_BACK,
    SET_BUSINESS_FIRST_LOGIN_CHANGE_PASSWORD_INPUT_FIELD,
    SET_BUSINESS_DETAILS_INPUT_FIELD,
    SET_DEFAULT_VALUE_TO_SEARCH_BUSINESS_COMPONENT,
    SET_BUSINESS_USER_INPUT_FIELD,
    SET_SUBMITTED_BUSINESS_USER,
    SET_DEFAULT_VALUE_TO_ADD_BUSINESS_USER,
    SET_DEFAULT_VALUE_FIRST_LOGIN_CHANGE_PASSWORD_FORM,
    SET_FIRST_LOGIN_CHANGE_PASSWORD,
    SET_TERM_N_CONDITION,
    SET_BUSINESS_DETAILS,
    FINISH_BUSINESS_FIRST_LOGIN_STEPPER,
    SET_BUSINESS_USER,
    SET_UPDATED_BUSINESS_USER,
    SET_INPUTHANDLER_UPLOAD_BUSINESS,
    POST_BUSINESS_UPLOADED_DATA,
    SET_DEFAULT_VALUE_TO_BUSINESS_BULK_UPLOAD_FORM,
    POST_TERM_N_CONDITION_REJECT,
    POST_ADD_BUSINESS_ADMIN_ON_SUBMIT,
    POST_HANDLE_ADD_BUSINESS_BILLING_FORM_FIELD,
    POST_ADD_BUSINESS_CONTACT_NEXT,
    POST_DELETE_ADD_BUSINESS_CONTACT_INFO,
    POST_UPDATE_BUSINESS_CONTACT_INFO,
    POST_ADD_ADD_BUSINESS_CONTACT_INFO,
    POST_ADD_BUSINESS_TAB_HANDLE_CHANGE,
    POST_ADD_BUSINESS_ADMIN_BILLING_BACK,
    POST_ADD_BUSINESS_CONTACT_BACK,
    POST_NAVIGATOR_TO_ADD_BUSINESS,
    POST_ADD_BUSINESS_ADMIN_ON_SUBMIT_AFTER_CLOSE_TOASTR,
    POST_HANDLER_EDIT_BUSINESS_DIALOG_OPEN,
    POST_HANDLER_EDIT_BUSINESS_DIALOG_CLOSE,
    POST_NAVIGATE_TO_EDIT_BUSINESS_ADMIN,
    POST_GET_BUSINESS_INFO_BY_ID,
    POST_ADD_BUSINESS_ADMIN_ON_UPDATE
} from '../actions/ActionType';
import { toast } from 'react-toastify';
import axios from "../axios";
import { convertCSVToJSONUser } from "./Common";

import {
    removeFakePath, convertToBase64, getFileExtension, isObjectEmpty
} from "../utils/Helper";



export const initializeBusinessBulkUploadForm = () => {
    return (dispatch) => {
        return dispatch(setDefaultValueToBusinessBulkUploadForm())
    }
}
export const setDefaultValueToBusinessBulkUploadForm = () => {
    return {
        type: SET_DEFAULT_VALUE_TO_BUSINESS_BULK_UPLOAD_FORM
    }
}
export const onbusinessUploadSubmit = (bulkUploadedBusinesses, uploadBusinessForms) => {
    return (dispatch) => {
        let businessBulkUploadSubmitLoaderStatus = false;
        let businessUploadStatusList = [];
        let businessUserBulkUploadValidationStatus = true;
        let reqPath = "/businesses/" + localStorage.getItem('business_id') + "/users/bulk";
        Object.keys(uploadBusinessForms).map(inputField => {
            if (uploadBusinessForms[inputField].value === '') {
                businessUserBulkUploadValidationStatus = false;
            }
        })
        dispatch(postbusinessUploadedData(businessUploadStatusList, businessBulkUploadSubmitLoaderStatus));
        if (bulkUploadedBusinesses.length > 0 && businessUserBulkUploadValidationStatus) {
            businessBulkUploadSubmitLoaderStatus = true;
            dispatch(postbusinessUploadedData(businessUploadStatusList, businessBulkUploadSubmitLoaderStatus));
            return axios.post(reqPath, bulkUploadedBusinesses)
                .then(response => {
                    businessBulkUploadSubmitLoaderStatus = false;
                    toast.success("Business Users Uploaded Successfully", {
                        containerId: 'toastMsg'
                    });
                    businessUploadStatusList = response.data.data
                    dispatch(postbusinessUploadedData(businessUploadStatusList, businessBulkUploadSubmitLoaderStatus));
                })
                .catch(error => {
                    businessBulkUploadSubmitLoaderStatus = false;
                    if (error.response.status == 403) {
                        localStorage.clear();
                        window.location.href = "/";
                    } else {
                        toast.error(error.response.data.message, {
                            containerId: 'toastMsg',
                        });
                    }
                    dispatch(postbusinessUploadedData(businessUploadStatusList, businessBulkUploadSubmitLoaderStatus));
                })
        }
    }
}
export const postbusinessUploadedData = (businessUploadStatusList, businessBulkUploadSubmitLoaderStatus) => {
    return {
        type: POST_BUSINESS_UPLOADED_DATA,
        businessUploadStatusList: businessUploadStatusList,
        businessBulkUploadSubmitLoaderStatus: businessBulkUploadSubmitLoaderStatus
    }
}
export const inputHandlerUploadBusiness = (formElementId, event) => {
    let bulkUploadedBusinesses;
    let formData = (event.target.value).replace(/^.*\\/, "");
    return (dispatch) => {
        convertCSVToJSONUser(event.target.files[0]).then(data => {
            bulkUploadedBusinesses = data;
            dispatch(setInputHandlerUploadBusiness(formElementId, formData, bulkUploadedBusinesses));
        })
            .catch(error => {
                toast.error(error, {
                    containerId: 'toastMsg'
                });
                formData = '';
                dispatch(setInputHandlerUploadBusiness(formElementId, formData, bulkUploadedBusinesses));
            })
    }
}
export const setInputHandlerUploadBusiness = (formElementId, formData, bulkUploadedBusinesses) => {
    return {
        type: SET_INPUTHANDLER_UPLOAD_BUSINESS,
        formElementId: formElementId,
        data: formData,
        bulkUploadedBusinesses: bulkUploadedBusinesses
    }
}
export const updateBusinessUser = (rowData, email, businessUserInformations) => {
    return (dispatch) => {
        let reqURL = "/businesses/" + localStorage.getItem('business_id') + "/users/" + email;
        let reqParams = {};
        reqParams['userfirstname'] = rowData.business_first_name;
        reqParams['userlastname'] = rowData.business_last_name;
        reqParams['accesslevel'] = Number(rowData.business_role);
        reqParams['useremailaddress'] = rowData.business_email;
        return axios.put(reqURL, reqParams)
            .then(response => {
                const updatedBusinessUserIndex = businessUserInformations.findIndex(d => {
                    return d.business_email == email;
                });
                businessUserInformations[updatedBusinessUserIndex].business_first_name = response.data.data.user.userfirstname;
                businessUserInformations[updatedBusinessUserIndex].business_last_name = response.data.data.user.userlastname;
                businessUserInformations[updatedBusinessUserIndex].business_role = response.data.data.accesslevel;
                businessUserInformations[updatedBusinessUserIndex].business_email = response.data.data.useremailaddress;
                toast.success("User Updated Successfully", {
                    containerId: 'toastMsg'
                });
                dispatch(setUpdatedBusinessUser(businessUserInformations))
            })
            .catch(error => {
                toast.success(error.response.data.message, {
                    containerId: 'toastMsg'
                });
                dispatch(setUpdatedBusinessUser(businessUserInformations))
            })
    }
}

export const deleteBusinessUser = (rowData, email, businessUserInformations) => {
    return (dispatch) => {
        let reqURL = "/businesses/" + localStorage.getItem('business_id') + "/users/" + email;
        return axios.delete(reqURL)
            .then(response => {
                const deletedBusinessUserIndex = businessUserInformations.findIndex(d => {
                    return d.business_email == rowData.business_email;
                });
                businessUserInformations.splice(deletedBusinessUserIndex, 1)
                toast.success("User Updated Successfully", {
                    containerId: 'toastMsg'
                });
                dispatch(setUpdatedBusinessUser(businessUserInformations))
            })
            .catch(error => {
                toast.success(error.response.data.message, {
                    containerId: 'toastMsg'
                });
                dispatch(setUpdatedBusinessUser(businessUserInformations))
            })
    }
}

export const setUpdatedBusinessUser = (businessUserInformations) => {
    return {
        type: SET_UPDATED_BUSINESS_USER,
        businessUserInformations: businessUserInformations
    }
}
export const getBusinessUser = () => {
    return (dispatch) => {
        let listBusinessUserLoaderStatus = true;
        let businessUsers = [];
        let businessUser = {};
        let reqURL = "/businesses/" + localStorage.getItem('business_id') + "/users";
        dispatch(setBusinessUser(businessUsers, listBusinessUserLoaderStatus))
        return axios.get(reqURL)
            .then(response => {
                listBusinessUserLoaderStatus = false;
                for (let i = 0; i < response.data.data.length; i++) {
                    businessUser = {};
                    businessUser['business_first_name'] = response.data.data[i].user_first_name;
                    businessUser['business_last_name'] = response.data.data[i].user_last_name;
                    businessUser['business_email'] = response.data.data[i].user_id.user_id;
                    businessUser['business_role'] = response.data.data[i].user_id.access_level.accesslevelid;
                    businessUsers.push(businessUser);
                }
                dispatch(setBusinessUser(businessUsers, listBusinessUserLoaderStatus))
            })
            .catch(error => {
                listBusinessUserLoaderStatus = false;
                dispatch(setBusinessUser(businessUsers, listBusinessUserLoaderStatus))
            })
    }
}


export const getDepotManagers = () => {
    return (dispatch) => {
        let listBusinessUserLoaderStatus = true;
        let businessUsers = [];
        let businessUser = {};
        let reqURL = "/businesses/" + localStorage.getItem('business_id') + "/depot_managers";
        dispatch(setBusinessUser(businessUsers, listBusinessUserLoaderStatus))
        return axios.get(reqURL)
            .then(response => {
                listBusinessUserLoaderStatus = false;
                for (let i = 0; i < response.data.data.length; i++) {
                    businessUser = {};
                    businessUser['_id'] = response.data.data[i].user_id._id;
                    businessUser['business_first_name'] = response.data.data[i].user_first_name;
                    businessUser['business_last_name'] = response.data.data[i].user_last_name;
                    businessUser['business_email'] = response.data.data[i].user_id.user_id;
                    businessUser['business_role'] = response.data.data[i].user_id.access_level.accesslevelid;
                    businessUsers.push(businessUser);
                }
                dispatch(setBusinessUser(businessUsers, listBusinessUserLoaderStatus))
            })
            .catch(error => {
                listBusinessUserLoaderStatus = false;
                dispatch(setBusinessUser(businessUsers, listBusinessUserLoaderStatus))
            })
    }
}
export const setBusinessUser = (businessUsers, listBusinessUserLoaderStatus) => {
    return {
        type: SET_BUSINESS_USER,
        businessUsers: businessUsers,
        listBusinessUserLoaderStatus: listBusinessUserLoaderStatus
    }
}
export const finishFirstLogin = (businessDetailsForm) => {
    return (dispatch) => {
        let reqURL = "/businesses/" + localStorage.getItem('business_id');
        let reqParams = {};
        let businessLogoDetails = {};
        let fileExtension = '';
        let mimeType = '';
        if (businessDetailsForm['business_logo'].value !== '') {
            fileExtension = getFileExtension(businessDetailsForm['business_logo'].value);
            if (fileExtension === 'jpeg') {
                mimeType = 'application/jpeg';
            }
            if (fileExtension === 'jpg') {
                mimeType = 'application/jpeg';
            }
            if (fileExtension === 'png') {
                mimeType = 'image/png';
            }
            businessLogoDetails['base64_string'] = businessDetailsForm['business_logo'].base64String;
            businessLogoDetails['file_type'] = mimeType;
        }

        reqParams['businessname'] = businessDetailsForm['businessname'].value;
        // reqParams['state'] = businessDetailsForm['state'].value;
        // reqParams['postalcode'] = businessDetailsForm['postalcode'].value;
        reqParams['country'] = businessDetailsForm['country'].value;
        reqParams['businesscontactname'] = businessDetailsForm['contact_name'].value;
        reqParams['businesscontactaddress'] = businessDetailsForm['contact_address'].value;
        //reqParams['businessbillingoption'] = businessDetailsForm['billing_option'].value;
        //reqParams['businesssgbilltype'] = businessDetailsForm['select_invoice'].value;
        reqParams['businessitsupportno'] = businessDetailsForm['business_it_support_no'].value;
        if (!isObjectEmpty(businessLogoDetails)) {
            reqParams['businesslogo'] = businessLogoDetails;
        }
        reqParams['businesscontactno'] = businessDetailsForm['business_contact_no'].value;
        dispatch(finishBusinessFirstLoginStepper());
        return axios.put(reqURL, reqParams)
            .then(response => {
                dispatch(finishBusinessFirstLoginStepper())
            })
            .catch(error => {
                dispatch(finishBusinessFirstLoginStepper())
            })
    }
}
export const finishBusinessFirstLoginStepper = () => {
    return {
        type: FINISH_BUSINESS_FIRST_LOGIN_STEPPER
    }
}
export const getBusinessDetail = () => {
    return (dispatch) => {
        let businessDetailsLoaderStatus = true;
        let reqURL = "/businesses/" + localStorage.getItem('business_id');
        let businessInfo = {};
        let menuItems = {};
        dispatch(setBusinessDetails(businessInfo, businessDetailsLoaderStatus));
        return axios.get(reqURL)
            .then(response => {
                businessDetailsLoaderStatus = false;
                businessInfo = response.data.data;
                /*
                if (businessInfo.business_billing_option === 'sg_bill') {
                    menuItems = {
                        'select_invoice': {
                            name: 'Select Invoice',
                            value: ''
                        },
                        'sg_invoice': {
                            name: 'SG Invoice',
                            value: 'sg_invoice'
                        },
                        'credit_card': {
                            name: 'Credit Card',
                            value: 'credit_card'
                        }
                    }
                } else {
                    menuItems = {
                        'select_invoice': {
                            name: 'Select Invoice',
                            value: ''
                        },
                        'dist_invoice': {
                            name: 'Dist Invoice',
                            value: 'dist_invoice'
                        },
                        'direct_debit': {
                            name: 'Direct Debit',
                            value: 'direct_debit'
                        }
                    }
                }*/
                dispatch(setBusinessDetails(businessInfo, businessDetailsLoaderStatus, menuItems));
            })
            .catch(error => {
                businessDetailsLoaderStatus = false;
                dispatch(setBusinessDetails(businessInfo, businessDetailsLoaderStatus, menuItems));
            })
    }
}
export const setBusinessDetails = (businessInfo, businessDetailsLoaderStatus, menuItems) => {
    return {
        type: SET_BUSINESS_DETAILS,
        businessInfo: businessInfo,
        businessDetailsLoaderStatus: businessDetailsLoaderStatus,
        menuItems: menuItems
    }
}
export const termNConditionReject = () => {
    return (dispatch) => {
        let reqUrl = "/businesses/" + localStorage.getItem('business_id') + "/acknowledged";
        let reqParams = {};
        reqParams['isTncAccepted'] = false;
        return axios.put(reqUrl, reqParams)
            .then(response => {
                window.location.href = "/";
                dispatch(postTermNConditionReject())
            })
            .catch(error => {
                dispatch(postTermNConditionReject())
            })
    }
}
export const postTermNConditionReject = () => {
    return {
        type: POST_TERM_N_CONDITION_REJECT
    }
}
export const termNCondition = () => {
    return (dispatch) => {
        let reqURL = "/businesses/" + localStorage.getItem('business_id') + "/acknowledged";
        let reqParams = {};
        reqParams['isTncAccepted'] = true;
        let termNConditionAcceptStatus = false;
        let businessFirstLoginStepperLoaderStatus = true;
        dispatch(setTermNCondition(termNConditionAcceptStatus, businessFirstLoginStepperLoaderStatus))
        return axios.put(reqURL, reqParams)
            .then(response => {
                businessFirstLoginStepperLoaderStatus = false;
                termNConditionAcceptStatus = true;
                dispatch(setTermNCondition(termNConditionAcceptStatus, businessFirstLoginStepperLoaderStatus))
            })
            .catch(error => {
                businessFirstLoginStepperLoaderStatus = false;
                termNConditionAcceptStatus = false;
                dispatch(setTermNCondition(termNConditionAcceptStatus, businessFirstLoginStepperLoaderStatus))
            })
    }
}
export const setTermNCondition = (termNConditionAcceptStatus, businessFirstLoginStepperLoaderStatus) => {
    return {
        type: SET_TERM_N_CONDITION,
        termNConditionAcceptStatus: termNConditionAcceptStatus,
        businessFirstLoginStepperLoaderStatus: businessFirstLoginStepperLoaderStatus
    }
}

export const onClickChangePassword = (firstLoginBusinessChangePasswordForm) => {
    return (dispatch) => {
        let changePasswordFormValidationStatus = true;
        let changePasswordSuccessStatus = false;
        let businessFirstLoginStepperLoaderStatus = false;
        Object.keys(firstLoginBusinessChangePasswordForm).map(inputField => {
            if (firstLoginBusinessChangePasswordForm[inputField].value === '') {
                changePasswordFormValidationStatus = false;
            }
        })
        if (firstLoginBusinessChangePasswordForm['new_password'].value !== firstLoginBusinessChangePasswordForm['confirm_password'].value) {
            toast.error("New Password and Confirm Password does not match", {
                containerId: 'toastMsg'
            });
            changePasswordFormValidationStatus = false;
        }
        if (changePasswordFormValidationStatus) {
            businessFirstLoginStepperLoaderStatus = true;
            let reqURL = "/users/" + localStorage.getItem('Platform-User') + "/changepwd";
            let reqParams = {};
            reqParams['username'] = localStorage.getItem('Platform-User');
            reqParams['oldpassword'] = firstLoginBusinessChangePasswordForm['current_password'].value;
            reqParams['newpassword'] = firstLoginBusinessChangePasswordForm['new_password'].value;
            dispatch(setFirstLoginChangePassword(changePasswordSuccessStatus, businessFirstLoginStepperLoaderStatus));
            return axios.post(reqURL, reqParams)
                .then(response => {
                    businessFirstLoginStepperLoaderStatus = false;
                    changePasswordSuccessStatus = true;
                    dispatch(setFirstLoginChangePassword(changePasswordSuccessStatus, businessFirstLoginStepperLoaderStatus));
                })
                .catch(error => {
                    businessFirstLoginStepperLoaderStatus = false;
                    changePasswordSuccessStatus = false;
                    toast.error(error.response.data.message, {
                        containerId: 'toastMsg'
                    });
                    dispatch(setFirstLoginChangePassword(changePasswordSuccessStatus, businessFirstLoginStepperLoaderStatus));
                })
        }
    }
}
export const setFirstLoginChangePassword = (changePasswordSuccessStatus, businessFirstLoginStepperLoaderStatus) => {
    return {
        type: SET_FIRST_LOGIN_CHANGE_PASSWORD,
        changePasswordSuccessStatus: changePasswordSuccessStatus,
        businessFirstLoginStepperLoaderStatus: businessFirstLoginStepperLoaderStatus
    }
}
export const initializeFirstLoginChangePassword = () => {
    return (dispatch) => {
        return dispatch(setDefaultValueFirstLoginChangePasswordForm())
    }
}
export const setDefaultValueFirstLoginChangePasswordForm = () => {
    return {
        type: SET_DEFAULT_VALUE_FIRST_LOGIN_CHANGE_PASSWORD_FORM
    }
}
//Initialize Add Business User Form
export const initializeAddBusinessUserForm = (distributorUserForm) => {
    return (dispatch) => {
        return dispatch(setDefaultValueToAddBusinessUser())
    }
}
export const setDefaultValueToAddBusinessUser = () => {
    return {
        type: SET_DEFAULT_VALUE_TO_ADD_BUSINESS_USER
    }
}
//onSubmit Action of Add Business User
export const onSubmitBusinessUser = (businessUserForm, props, url) => {
    return (dispatch) => {
        let businessUserFormValidation = true;
        let businessUserLoaderStatus = false;
        Object.keys(businessUserForm).map(inputField => {
            if (businessUserForm[inputField].value === '') {
                businessUserForm[inputField].errorStatus = true;
                businessUserFormValidation = false;
            }
        })
        if (businessUserForm['business_user_email'].value != '') {
            if (!(businessUserForm['business_user_email'].value).match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
                businessUserFormValidation = false;
                businessUserForm['business_user_email'].errorStatus = true;
            } else {
                businessUserForm['business_user_email'].errorStatus = false;
            }
        }
        dispatch(setSubmittedBusinessUser(businessUserLoaderStatus))
        if (businessUserFormValidation) {
            businessUserLoaderStatus = true;
            let reqURL = "/businesses/" + localStorage.getItem('business_id') + "/users";
            let reqParams = {};
            reqParams['userfirstname'] = businessUserForm['business_user_fname'].value;
            reqParams['userlastname'] = businessUserForm['business_user_lname'].value;
            reqParams['accesslevel'] = Number(businessUserForm['business_user_role'].value);
            reqParams['useremailaddress'] = businessUserForm['business_user_email'].value;
            dispatch(setSubmittedBusinessUser(businessUserLoaderStatus))
            return axios.post(reqURL, reqParams)
                .then(response => {
                    businessUserLoaderStatus = false;
                    toast.success("Business User Created Successfully",
                        {
                            containerId: 'toastMsg',
                            onClose: () => props.history.push(url)
                        });
                    dispatch(setSubmittedBusinessUser(businessUserLoaderStatus))
                })
                .catch(error => {
                    businessUserLoaderStatus = false;
                    toast.error(error.response.data.message, { containerId: 'toastMsg' });
                    dispatch(setSubmittedBusinessUser(businessUserLoaderStatus))
                })
        }
    }
}
export const setSubmittedBusinessUser = (businessUserLoaderStatus) => {
    return {
        type: SET_SUBMITTED_BUSINESS_USER,
        businessUserLoaderStatus: businessUserLoaderStatus
    }
}

export const businessUserInputHandler = (inputFiledId, event) => {
    return (dispatch) => {
        return dispatch(setBusinessUserInputField(inputFiledId, event));
    }
}

export const setBusinessUserInputField = (inputFiledId, event) => {
    return {
        type: SET_BUSINESS_USER_INPUT_FIELD,
        inputFiledId: inputFiledId,
        data: event.target.value
    }
}

//Intialize Search Business Component Action
export const intializeSearchBusinessComponent = () => {
    return (dispatch) => {
        return dispatch(setDefaultValueToSearchBusinessComponent())
    }
}
export const setDefaultValueToSearchBusinessComponent = () => {
    return {
        type: SET_DEFAULT_VALUE_TO_SEARCH_BUSINESS_COMPONENT
    }
}

//Business Details Input Field Change Action
export const businessDetailsInputFieldChange = (formElementId, event) => {
    return dispatch => {
        let logoBase64String = '';
        let textFieldValue = event.target.value;
        if (formElementId === 'abn' || formElementId === 'business_contact_no' || formElementId === 'business_it_support_no') {
            textFieldValue = (event.target.value).replace(/[^0-9]/g, '');
        }
        if (formElementId === 'business_logo' && event.target.files !== null) {
            textFieldValue = (event.target.value).replace(/^.*\\/, "");
            convertToBase64(event.target.files[0]).then(data => {
                let logoBase64String = data;
                if ((((new Blob([logoBase64String]).size) / 1024) / 1024) > 1.5) {
                    toast.error("File size too large", { containerId: 'toastMsg' });
                }
                dispatch(setBusinessDetailsInputField(formElementId, textFieldValue, logoBase64String))
            });
        }
        return dispatch(setBusinessDetailsInputField(formElementId, textFieldValue, logoBase64String))
    }
}

export const setBusinessDetailsInputField = (formElementId, textFieldValue, logoBase64String) => {
    return {
        type: SET_BUSINESS_DETAILS_INPUT_FIELD,
        formElementId: formElementId,
        data: textFieldValue,
        logoBase64String: logoBase64String
    }
}

//Business First Login Change Password Input Fied Change Action
export const businessFirstLoginChangePasswordInputFieldChange = (inputFieldID, event) => {
    return (dispatch) => {
        return dispatch(setBusinessFirstLoginChangePasswordInputField(inputFieldID, event))
    }
}
export const setBusinessFirstLoginChangePasswordInputField = (inputFieldID, event) => {
    return {
        type: SET_BUSINESS_FIRST_LOGIN_CHANGE_PASSWORD_INPUT_FIELD,
        inputFieldID: inputFieldID,
        data: event.target.value
    }
}

//Business First Login Stepper Back
export const handleBusinessFirstLoginStepperBack = () => {
    return (dispatch) => {
        return dispatch(setBusinessFirstLoginStepperBack())
    }
}
export const setBusinessFirstLoginStepperBack = () => {
    return {
        type: SET_BUSINESS_FIRST_LOGIN_STEPPER_BACK
    }
}

//Business First Login Stepper Next Action
export const handleBusinessFirstLoginStepperNext = () => {
    return (dispatch) => {
        return dispatch(setBusinessFirstLoginStepperValue())
    }
}
export const setBusinessFirstLoginStepperValue = () => {
    return {
        type: SET_BUSINESS_FIRST_LOGIN_STEPPER_VALUE
    }
}

//On Update Business Admin Informations
export const onUpdateBusiness = (businessIdForEdit, addBusinessForm, addBusinessLogoBase64, businessInformations) => {
    return (dispatch) => {
        let addBusinessAdminLoaderStatus = true;
        let businessFieldsValidationStatus = true;
        let reqURL = '/dist/' + localStorage.getItem('distributor_id') + '/businesses/' + businessIdForEdit;
        let updateBusinessReq = {};
        let businessLogoInfo = {};
        let updateBusinessLoaderStatus;

        Object.keys(addBusinessForm).map((textField) => {
            if (addBusinessForm[textField].value == '' &&
                (textField !== 'contact_email' && textField !== 'support_email'
                    && textField !== 'support_contact_number' && textField !== 'business_address'
                    && textField !== 'business_logo' && textField !== 'isDeleted')) {
                businessFieldsValidationStatus = false;
                addBusinessForm[textField].errorStatus = true;
            }
        })
        if (addBusinessForm['contact_email'].value != '') {
            if (!(addBusinessForm['contact_email'].value).match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
                addBusinessForm['contact_email'].errorStatus = true;
                businessFieldsValidationStatus = false;
            }
        }
        // if(addDistributorForm['myob_account_number'].value != ''){
        //     if(Number(addDistributorForm['myob_account_number'].value) === 0){
        //         addDistributorForm['myob_account_number'].errorStatus = true;
        //         distributorFieldsValidationStatus = false;
        //     }
        // }
        dispatch(updateBusinessInfo(updateBusinessLoaderStatus, businessInformations));
        if (businessFieldsValidationStatus) {
            updateBusinessLoaderStatus = true;
            addBusinessAdminLoaderStatus = true
            if (addBusinessLogoBase64 != '') {
                businessLogoInfo['base64_string'] = addBusinessLogoBase64;
                let fileType = getFileExtension(addBusinessForm['business_logo'].value);
                let mimeType = '';
                if (fileType.toLowerCase() === 'jpeg') {
                    mimeType = 'application/jpeg';
                }
                if (fileType.toLowerCase() === 'jpg') {
                    mimeType = 'application/jpeg';
                }
                if (fileType.toLowerCase() === 'png') {
                    mimeType = 'image/png';
                }
                businessLogoInfo['file_type'] = mimeType;
            }
            // updateBusinessReq["country"] = addBusinessForm['country'].value;
            // // updateBusinessReq["Businessemailaddress"] = addBusinessForm['Business_email'].value;
            // updateBusinessReq["businessname"] = addBusinessForm['business_name'].value;
            // updateBusinessReq["businesssupportnumber"] = addBusinessForm['support_contact_number'].value;
            // updateBusinessReq["businesssupportemail"] = addBusinessForm['support_email'].value;
            // updateBusinessReq["businessaddress"] = addBusinessForm['business_address'].value;
            // if(addBusinessLogoBase64 != ''){
            //     updateBusinessReq["businesslogo"] = businessLogoInfo;
            // }
            // updateBusinessReq["accesslevel"] = 5;
            // updateBusinessReq['businessname'] = addBusinessForm['business_name'].value;
            updateBusinessReq['state'] = addBusinessForm['state'].value;
            updateBusinessReq['postalcode'] = addBusinessForm['postalcode'].value;
            updateBusinessReq['country'] = addBusinessForm['country'].value;
            updateBusinessReq['businesscontactname'] = addBusinessForm['contact_name'].value;
            updateBusinessReq['businesscontactaddress'] = addBusinessForm['contact_address'].value;
            // updateBusinessReq['businessbillingoption'] = addBusinessForm['billing_option'].value;
            if (addBusinessForm['isDeleted'].value === 'inactive') {
                updateBusinessReq['isDeleted'] = true;
            } else {
                updateBusinessReq['isDeleted'] = false;
            }
            // updateDistributorReq["abn"] = addDistributorForm['abn'].value;
            // updateBusinessReq["myobaccountnumber"] = addBusinessForm['myob_account_number'].value;

            dispatch(updateBusinessInfo(updateBusinessLoaderStatus, businessInformations));
            return axios.put(reqURL, updateBusinessReq)
                .then(response => {

                    addBusinessAdminLoaderStatus = false;
                    updateBusinessLoaderStatus = false;
                    const updatedBusinessIndex = businessInformations.findIndex(d => {
                        return d.business_id === businessIdForEdit;
                    });
                    //businessInformations[updatedBusinessIndex].billing_option = addBusinessForm['billing_option'].value;
                    businessInformations[updatedBusinessIndex].business_name = addBusinessForm['business_name'].value;
                    businessInformations[updatedBusinessIndex].contact_name = addBusinessForm['contact_name'].value;
                    businessInformations[updatedBusinessIndex].state = addBusinessForm['state'].value;
                    businessInformations[updatedBusinessIndex].postalcode = addBusinessForm['postalcode'].value;
                    businessInformations[updatedBusinessIndex].country = addBusinessForm['country'].value;
                    businessInformations[updatedBusinessIndex].contact_address = addBusinessForm['contact_address'].value;
                    if (addBusinessForm['isDeleted'].value === 'active') {
                        businessInformations[updatedBusinessIndex].status = 'active';
                    } else {
                        businessInformations[updatedBusinessIndex].status = 'inactive';
                    }
                    toast.success("Business Updated Successfully", {
                        containerId: 'toastMsg'
                    });
                    dispatch(updateBusinessInfo(addBusinessAdminLoaderStatus, businessInformations));
                })
                .catch(error => {
                    updateBusinessLoaderStatus = false;
                    if (error.response.status == 403) {
                        localStorage.clear();
                        toast.error("Session Expired ! Please login again", { containerId: 'toastMsg' });
                        window.location.href = "/";
                    } else {
                        toast.error(error.response.data.message, { containerId: 'toastMsg' });
                    }
                    dispatch(updateBusinessInfo(updateBusinessLoaderStatus, businessInformations));
                })
        }
    }
}

export const updateBusinessInfo = (addBusinessAdminLoaderStatus, businessInformations) => {
    return {
        type: UPDATE_BUSINESS_INFO,
        updateDistributorLoaderStatus: addBusinessAdminLoaderStatus,
        addBusinessAdminLoaderStatus: addBusinessAdminLoaderStatus,
        businessInformations: businessInformations
    }
}


//Handle Edit Diriver Dialog Close Action
export const handleEditDriverDialogClose = () => {
    return (dispatch) => {
        return dispatch(setEditDriverDialogStatusToClose())
    }
}
export const setEditDriverDialogStatusToClose = () => {
    return {
        type: SET_EDIT_DRIVER_DIALOG_STATUS_TO_CLOSE
    }
}

//Handle Edit Business Open Dialog
export const handleEditBusinessOpenDialog = (recordToEdit) => {
    return (dispatch) => {
        return dispatch(setEditBusinessOpenDialogStatus(recordToEdit))
    }
}
export const setEditBusinessOpenDialogStatus = (recordToEdit) => {
    return {
        type: SET_EDIT_BUSINESS_OPEN_DIALOG_STATUS,
        recordToEdit: recordToEdit
    }
}

//Intitialize Add Distributor Form Action
export const initializeAddDistributorForm = () => {
    return (dispatch) => {
        return dispatch(setInitialValueToAddDistributorForm())
    }
}
export const setInitialValueToAddDistributorForm = () => {
    return {
        type: SET_INITIAL_VALUE_TO_ADD_DISTRIBUTOR_FORM
    }
}

//Get Business List Action
export const getBusinessList = () => {
    let business = {};
    let addBusinessAdminLoaderStatus = true;
    let businessList = [];
    return (dispatch) => {
        dispatch(getBusinessDetails(businessList, addBusinessAdminLoaderStatus));
        let path = "/dist/" + localStorage.getItem('distributor_id') + "/businesses";
        return axios.get(path)
            .then(response => {

                addBusinessAdminLoaderStatus = false;
                for (let i = 0; i < response.data.data.length; i++) {
                    // business = {};
                    // business['business_name'] = response.data.data[i].business_name;
                    // business['contact_name'] = response.data.data[i].business_contact_name;
                    // business['billing_option'] = response.data.data[i].business_billing_option;
                    // business['abn'] = response.data.data[i].abn;
                    // business['country'] = (response.data.data[i].country).toUpperCase();
                    // business['business_id'] = response.data.data[i].business_id;
                    // business['contact_address'] = response.data.data[i].business_contact_address;
                    // business['distId'] = response.data.data[i].distributor_id;
                    // if (!response.data.data[i].business_ack) {
                    //     business['status'] = "not_verified";
                    // } else {
                    //     if (!response.data.data[i].isDeleted) {
                    //         business['status'] = "active";
                    //     } else {
                    //         business['status'] = "inactive";
                    //     }
                    // }
                    // business['business_ack'] = response.data.data[i].business_ack;
                    businessList.push(response.data.data[i]);
                }
                dispatch(getBusinessDetails(businessList, addBusinessAdminLoaderStatus));
            })
            .catch(error => {

                addBusinessAdminLoaderStatus = false;
                if (error.response.status == 403) {
                    localStorage.clear();
                    window.location.href = "/";
                }
                dispatch(getBusinessDetails(businessList, addBusinessAdminLoaderStatus));
            })
    }
}

export const getBusinessDetails = (businessList, addBusinessAdminLoaderStatus) => {
    return {
        type: GET_BUSINESS_DETAILS,
        businessList: businessList,
        addBusinessAdminLoaderStatus: addBusinessAdminLoaderStatus
    }
}

//Search Business Action
export const onSearch = (searchBusinessForm, props, url) => {
    return (dispatch) => {
        let addBusinessAdminLoaderStatus = false;
        let searchBusinessFoundStatus = null;
        let textFiledValidationStatus = true;
        dispatch(onSearchBusiness(searchBusinessForm, searchBusinessFoundStatus, addBusinessAdminLoaderStatus))
        Object.keys(searchBusinessForm).map((textField) => {
            if (searchBusinessForm[textField].value == '') {
                textFiledValidationStatus = false;
                searchBusinessForm[textField].errorStatus = true;
            }
        })
        if (textFiledValidationStatus) {
            addBusinessAdminLoaderStatus = true;
            dispatch(onSearchBusiness(searchBusinessForm, searchBusinessFoundStatus, addBusinessAdminLoaderStatus))
            let abn = Number(searchBusinessForm['abn'].value);
            let reqPath = "/dist/" + localStorage.getItem('distributor_id') + "/businesses/abn/" + abn;
            return axios.get(reqPath)
                .then(response => {
                    addBusinessAdminLoaderStatus = false;
                    if (!response.data.data.businessexists) {
                        searchBusinessFoundStatus = false;
                        url=url+"?abn="+abn;
                        toast.success("ABN Number looks good for creating new business", {
                            containerId: 'toastMsg',
                            onClose: () => props.history.push(url)
                        });
                    } else {
                        searchBusinessFoundStatus = true;
                        toast.error("ABN number already exist with business", {
                            containerId: 'toastMsg'
                        });
                    }
                    dispatch(onSearchBusiness(searchBusinessForm, searchBusinessFoundStatus, addBusinessAdminLoaderStatus));
                })
                .catch(error => {
                    addBusinessAdminLoaderStatus = false;
                    if (error.response.status == 403) {
                        localStorage.clear();
                        window.location.href = "/";
                    }
                    dispatch(onSearchBusiness(searchBusinessForm, searchBusinessFoundStatus, addBusinessAdminLoaderStatus));
                })
        }
    }
}


export const getBusinessInfoById = () => {
    return (dispatch) => {
        // dist/AUS-054122/businesses/AUS-760896
        let reqURL = '/dist/' + localStorage.getItem('distributor_id') + '/businesses/' + localStorage.getItem('business_id');
        let getBusinessAdminByIdLoaderStatus = true;
        let businessInfo = {};
        dispatch(postGetBusinessInfoById(businessInfo, getBusinessAdminByIdLoaderStatus));
        return axios.get(reqURL)
            .then(response => {

                getBusinessAdminByIdLoaderStatus = false;
                businessInfo = response.data.data[0];
                dispatch(postGetBusinessInfoById(businessInfo, getBusinessAdminByIdLoaderStatus));
            })
            .catch(error => {
                getBusinessAdminByIdLoaderStatus = false;
                dispatch(postGetBusinessInfoById(businessInfo, getBusinessAdminByIdLoaderStatus));
            })
    }
}

export const postGetBusinessInfoById = (businessInfo, getBusinessAdminByIdLoaderStatus) => {
    return {
        type: POST_GET_BUSINESS_INFO_BY_ID,
        businessInfo: businessInfo,
        getBusinessAdminByIdLoaderStatus: getBusinessAdminByIdLoaderStatus
    }
}

export const onSearchBusiness = (searchBusinessForm, searchBusinessFoundStatus, addBusinessAdminLoaderStatus) => {
    return {
        type: ON_SEARCH_BUSINESS,
        searchBusinessFoundStatus: searchBusinessFoundStatus,
        searchBusinessForm: searchBusinessForm,
        addBusinessAdminLoaderStatus: addBusinessAdminLoaderStatus
    }
}

// Handle Search Business Text Field Change Action
export const handleSearchBusinessTextFieldChange = (textFieldId, event) => {
    return (dispatch) => {
        return dispatch(setSearchBusinessTextFieldData(textFieldId, event));
    }
}

export const setSearchBusinessTextFieldData = (textFieldId, event) => {
    return {
        type: SEARCH_BUSINESS_TEXTFIELD_CHANGE,
        textFieldId: textFieldId,
        textFieldData: event.target.value
    }
}

//onSubmit Action of AddBusiness Admin Form
export const addBusinessNext = (addBusinessForm, props, addBusinessLogoBase64) => {

    return (dispatch) => {
        let businessFieldsValidationStatus = true;
        let addBusinessTabValue = 0;
        let addBusinessReq = {};
        let addBusinessTabDisableStatus = true;
        let tabSwitchStatus = false;
        Object.keys(addBusinessForm).map((textField) => {
            if (
                addBusinessForm[textField].value == '' &&
                (
                    textField !== 'support_email' &&
                    textField !== 'support_contact_number' &&
                    textField !== 'business_address' &&
                    textField !== 'business_logo' &&
                    textField !== 'company_name' &&
                    textField !== 'attention' &&
                    textField !== 'phone' &&
                    textField !== 'payment_method' &&
                    textField !== 'integrationId' &&
                    textField !== 'isDeleted'
                )) {
                businessFieldsValidationStatus = false;
                addBusinessForm[textField].errorStatus = true;
            }
        })
        if (addBusinessForm['contact_email'].value != '') {
            if (!(addBusinessForm['contact_email'].value).match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
                addBusinessForm['contact_email'].errorStatus = true;
                businessFieldsValidationStatus = false;
            }
        }
        if (addBusinessForm['support_email'].value != '') {
            if (!(addBusinessForm['support_email'].value).match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
                addBusinessForm['support_email'].errorStatus = true;
                businessFieldsValidationStatus = false;
            }
        }

        dispatch(setSubmittedData(addBusinessTabValue, addBusinessReq, addBusinessTabDisableStatus, tabSwitchStatus));
        if (businessFieldsValidationStatus) {

            addBusinessReq = {};
            let businessLogoInfo = {};
            let fileType = getFileExtension(addBusinessForm['business_logo'].value);
            let mimeType = '';
            if (fileType.toLowerCase() === 'jpeg') {
                mimeType = 'application/jpeg';
            }
            if (fileType.toLowerCase() === 'jpg') {
                mimeType = 'application/jpeg';
            }
            if (fileType.toLowerCase() === 'png') {
                mimeType = 'image/png';
            }
            businessLogoInfo['file_type'] = mimeType;
            businessLogoInfo['base64_string'] = addBusinessLogoBase64;



            addBusinessReq["state"] = addBusinessForm['state'].value;
            addBusinessReq["postalcode"] = addBusinessForm['postalcode'].value;
            addBusinessReq["country"] = addBusinessForm['country'].value;
            addBusinessReq["emailaddress"] = addBusinessForm['contact_email'].value;
            addBusinessReq["businessname"] = addBusinessForm['businessname'].value;
            addBusinessReq["businesscontactname"] = addBusinessForm['contact_name'].value;

            addBusinessReq["businesssupportnumber"] = addBusinessForm['support_contact_number'].value;
            addBusinessReq["businesssupportemail"] = addBusinessForm['support_email'].value;
            // addBusinessReq["businessaddress"] = addBusinessForm['business_address'].value;
            addBusinessReq["businesslogo"] = businessLogoInfo;
            addBusinessReq["accesslevel"] = 5;
            addBusinessReq["abn"] = addBusinessForm['abn'].value;
            addBusinessReq["state"] = addBusinessForm['state'].value;
            addBusinessReq["postalcode"] = addBusinessForm['postalcode'].value;
            addBusinessReq["integrationId"] = addBusinessForm['integrationId'].value;
            let mainAttribute = {};


            //addBusinessReq['businessbillingoption'] = addBusinessForm['billing_option'].value;
            addBusinessReq['businesscontactaddress'] = addBusinessForm['contact_address'].value;
            //mainAttribute['company_name'] = addBusinessForm['company_name'].value;
            mainAttribute['email'] = addBusinessForm['contact_email'].value;
            mainAttribute['phone'] = addBusinessForm['phone'].value;
            //mainAttribute['attention'] = addBusinessForm['attention'].value;
            //mainAttribute['payment_method'] = addBusinessForm['payment_method'].value;
            addBusinessReq["main_attributes"] = mainAttribute;
            addBusinessTabValue = 1;
            addBusinessTabDisableStatus = false;
            tabSwitchStatus = true;
            dispatch(setSubmittedData(addBusinessTabValue, addBusinessReq, addBusinessTabDisableStatus, tabSwitchStatus));
        }
    }

}

export const postAddBusinessAdminOnSubmit = (addBusinessAdminLoaderStatus) => {
    return {
        type: POST_ADD_BUSINESS_ADMIN_ON_SUBMIT,
        addBusinessAdminLoaderStatus: addBusinessAdminLoaderStatus
    }
}

export const handleAddBusinessBillingFormField = (formFieldId, event) => {
    return (dispatch) => {
        return dispatch(postHandleAddBusinessBillingFormField(formFieldId, event))
    }
}



export const postHandleAddBusinessBillingFormField = (formFieldId, event) => {
    return {
        type: POST_HANDLE_ADD_BUSINESS_BILLING_FORM_FIELD,
        formFieldId: formFieldId,
        data: event.target.value,

    }
}

export const addBusinessContactNext = (addBusinessContactinformations) => {

    return (dispatch) => {
        return dispatch(postAddBusinessContactNext(addBusinessContactinformations))
    }
}
export const postAddBusinessContactNext = (addBusinessContactinformations) => {

    return {
        type: POST_ADD_BUSINESS_CONTACT_NEXT,
        addBusinessContactinformations: addBusinessContactinformations
    }
}

export const deleteAddBusinessContactInfo = (addBusinessContactinformations) => {
    return (dispatch) => {
        return dispatch(postDeleteAddBusinessContactInfo(addBusinessContactinformations))
    }
}
export const postDeleteAddBusinessContactInfo = (addBusinessContactinformations) => {
    return {
        type: POST_DELETE_ADD_BUSINESS_CONTACT_INFO,
        addBusinessContactinformations: addBusinessContactinformations
    }
}
export const updateBusinessContactInfo = (addBusinessContactinformations) => {
    return (dispatch) => {
        return dispatch(postUpdateBusinessContactInfo(addBusinessContactinformations))
    }
}


export const postUpdateBusinessContactInfo = (addBusinessContactinformations) => {
    return {
        type: POST_UPDATE_BUSINESS_CONTACT_INFO,
        addBusinessContactinformations: addBusinessContactinformations
    }
}
export const addAddBusinessContactInfo = (addBusinessContactinformations) => {
    return (dispatch) => {

        return dispatch(postAddAddBusinessContactInfo(addBusinessContactinformations))
    }
}
export const postAddAddBusinessContactInfo = (addBusinessContactinformations) => {
    return {
        type: POST_ADD_ADD_BUSINESS_CONTACT_INFO,
        addBusinessContactinformations: addBusinessContactinformations
    }
}
export const addBusinessTabHandleChange = (event, value) => {
    return (dispatch) => {
        let addBusinessTabValue = value;

        return dispatch(postAddBusinessTabhandleChange(addBusinessTabValue))
    }
}
export const postAddBusinessTabhandleChange = (addBusinessTabValue) => {
    return {
        type: POST_ADD_BUSINESS_TAB_HANDLE_CHANGE,
        addBusinessTabValue: addBusinessTabValue
    }
}

export const addBusinessAdminBillingBack = () => {
    return (dispatch) => {
        dispatch(postAddBusinessAdminBillingBack())
    }
}

export const postAddBusinessAdminBillingBack = () => {
    return {
        type: POST_ADD_BUSINESS_ADMIN_BILLING_BACK
    }
}

export const addBusinessContactBack = () => {
    return (dispatch) => {
        return dispatch(postAddBusinessContactBack())
    }
}
export const postAddBusinessContactBack = () => {
    return {
        type: POST_ADD_BUSINESS_CONTACT_BACK
    }
}


export const navigateToAddBusiness = () => {
    return (dispatch) => {
        return dispatch(postNavigateToAddBusiness())
    }
}
export const postNavigateToAddBusiness = () => {
    return {
        type: POST_NAVIGATOR_TO_ADD_BUSINESS
    }
}
export const navigateToEditBusinessAdmin = () => {
    return (dispatch) => {
        return dispatch(postNavigateToEditBusinessAdmin())
    }
}
export const postNavigateToEditBusinessAdmin = () => {
    return {
        type: POST_NAVIGATE_TO_EDIT_BUSINESS_ADMIN
    }
}
export const handleEditBusinessDialogOpen = (rowData) => {
    return (dispatch) => {
        return dispatch(postHandleEditBusinessDialogOpen(rowData))
    }
}
export const postHandleEditBusinessDialogOpen = (rowData) => {
    return {
        type: POST_HANDLER_EDIT_BUSINESS_DIALOG_OPEN,
        data: rowData
    }
}

export const addBusinessAdminOnSubmit = (billingForm, addBusinessReqParams, props) => {

    return (dispatch) => {
        let billingFormReqParams = {};
        let addBusinessAdminLoaderStatus = true;
        // addBusinessAdminLoaderStatus = true;
        // billingFormReqParams['billing_address'] = billingForm['address_line'].value;
        // billingFormReqParams['billing_city'] = billingForm['city'].value;
        // billingFormReqParams['billing_country'] = billingForm['country'].value;
        // billingFormReqParams['billing_company_name'] = billingForm['company_name'].value;
        // billingFormReqParams['billing_currency'] = billingForm['currency'].value;
        // billingFormReqParams['billing_email'] = billingForm['email'].value;
        // billingFormReqParams['billing_phone'] = billingForm['phone'].value;
        // billingFormReqParams['billing_state'] = billingForm['state'].value;
        // billingFormReqParams['billing_postalcode'] = billingForm['postalcode'].value;
        addBusinessReqParams['accesslevel'] = 5;
        // addBusinessReqParams['business_billing_data'] = billingFormReqParams;


        let reqPath = '/dist/' + localStorage.getItem('distributor_id') + "/businesses";
        dispatch(postAddBusinessAdminOnSubmit(addBusinessAdminLoaderStatus));

        return axios.post(reqPath, addBusinessReqParams)
            .then(response => {

                addBusinessAdminLoaderStatus = false;
                //   addBusinessAdminLoaderStatus = false;
                toast.success("Business Created Successfully", {
                    containerId: 'toastMsg',
                    onClose: () => {
                        props.history.push('/sgadminportal/distributorportal/' + localStorage.getItem('distributor_id') + '/businesses');
                        dispatch(postAddBusinessAdminOnSubmitAfterCloseToastr())
                    }
                });
                dispatch(postAddBusinessAdminOnSubmit(addBusinessAdminLoaderStatus));
            })
            .catch(error => {
                addBusinessAdminLoaderStatus = false;
                addBusinessAdminLoaderStatus = false;
                if (error.response.status == 403) {
                    localStorage.clear();
                    toast.error("Session Expired ! Please login again", { containerId: 'toastMsg' });
                    window.location.href = "/";
                } else {
                    toast.error(error.response.data.message, { containerId: 'toastMsg' });
                    dispatch(postAddBusinessAdminOnSubmit(addBusinessAdminLoaderStatus));
                }

            })
    }
}


export const postAddBusinessAdminOnSubmitAfterCloseToastr = () => {
    return {
        type: POST_ADD_BUSINESS_ADMIN_ON_SUBMIT_AFTER_CLOSE_TOASTR
    }
}


export const handleEditBusinessDialogClose = () => {
    return (dispatch) => {
        return dispatch(postHandleEditBusinessDialogClose())
    }
}

export const postHandleEditBusinessDialogClose = () => {
    return {
        type: POST_HANDLER_EDIT_BUSINESS_DIALOG_CLOSE
    }
}


//Handle Text Field Change Action
export const handleTextFieldChange = (inputFieldId, event) => {

    return (dispatch) => {
        let addBusinessLogoBase64 = '';
        let textFieldValue = event.target.value;
        if (
          inputFieldId === 'abn' ||
        // inputFieldId === 'myob_account_number' ||
        inputFieldId === 'support_contact_number' ||
        inputFieldId === 'phone'||inputFieldId==='postalcode'

        ) {
            textFieldValue = event.target.value.replace(/[^0-9]/g, '');
        }
        if (inputFieldId === 'business_logo') {
            textFieldValue = removeFakePath(event.target.value);
            if (event.target.files !== null) {
                convertToBase64(event.target.files[0]).then(data => {
                    let addBusinessLogoBase64 = data;
                    if ((((new Blob([addBusinessLogoBase64]).size) / 1024) / 1024) > 1.5) {
                        toast.error("File size too large", { containerId: 'toastMsg' });
                    }
                    dispatch(setTextFieldData(inputFieldId, textFieldValue, addBusinessLogoBase64))
                });


            }
        }

        return dispatch(setTextFieldData(inputFieldId, textFieldValue, addBusinessLogoBase64));
    }
}

export const addBusinessAdminOnUpdate = (billingForm, addBusinessReqParams, props, url = '/sgadminportal/distributorportal/' + localStorage.getItem('distributor_id') + '/businesses', editProfileSearchFoundStatus) => {

    return (dispatch) => {
        let billingFormReqParams = {};
        let addBusinessAdminLoaderStatus = true;
        billingFormReqParams['billing_address'] = billingForm['address_line'].value;
        billingFormReqParams['billing_city'] = billingForm['city'].value;
        billingFormReqParams['billing_country'] = billingForm['country'].value;
        //billingFormReqParams['billing_company_name'] = billingForm['company_name'].value;
        billingFormReqParams['billing_currency'] = billingForm['currency'].value;
        billingFormReqParams['billing_email'] = billingForm['email'].value;
        billingFormReqParams['billing_phone'] = billingForm['phone'].value;
        billingFormReqParams['billing_state'] = billingForm['state'].value;
        billingFormReqParams['billing_postalcode'] = billingForm['postalcode'].value;
        addBusinessReqParams['business_billing_data'] = billingFormReqParams;
        let reqURL
        if (editProfileSearchFoundStatus != -1) {
          //edit profile
            reqURL = "/businesses/" + localStorage.getItem('business_id');

        }
        else

            reqURL = '/dist/' + localStorage.getItem('distributor_id') + '/businesses/' + localStorage.getItem('business_id');


        dispatch(postAddBusinessAdminOnUpdate(addBusinessAdminLoaderStatus));
        return axios.put(reqURL, addBusinessReqParams)
            .then(response => {
                addBusinessAdminLoaderStatus = false;
                toast.success("Business Updated Successfully", {
                    containerId: 'toastMsg',
                    onClose: () => {

                        props.history.push(editProfileSearchFoundStatus!=-1?'/businessportal/'+localStorage.getItem('business_id') +'/profile':'/sgadminportal/distributorportal/' + localStorage.getItem('distributor_id') + '/businesses');
                        // if(url === '/sgadminportal/distributors'){
                        //     dispatch(postAddBusinessAdminOnSubmitAfterCloseToastr())
                        // }
                        dispatch(postAddBusinessAdminOnSubmitAfterCloseToastr())
                    }
                });
                dispatch(postAddBusinessAdminOnUpdate(addBusinessAdminLoaderStatus));
            })
            .catch(error => {
                addBusinessAdminLoaderStatus = false;
                if (error.response.status == 403) {
                    localStorage.clear();
                    toast.error("Session Expired ! Please login again", { containerId: 'toastMsg' });
                    window.location.href = "/";
                } else {
                    toast.error(error.response.data.message, { containerId: 'toastMsg' });
                    dispatch(postAddBusinessAdminOnUpdate(addBusinessAdminLoaderStatus));
                }

            })
    }
}

export const postAddBusinessAdminOnUpdate = (addBusinessAdminLoaderStatus) => {
    return {
        type: POST_ADD_BUSINESS_ADMIN_ON_UPDATE,
        addBusinessAdminLoaderStatus: addBusinessAdminLoaderStatus
    }
}



export const setSubmittedData = (addBusinessTabValue, addBusinessReqParams, addBusinessTabDisableStatus, tabSwitchStatus) => {
    return {
        type: SUBMIT_BUSINESS_INFO,
        addBusinessTabValue: addBusinessTabValue,
        addBusinessReqParams: addBusinessReqParams,
        addBusinessTabDisableStatus: addBusinessTabDisableStatus,
        tabSwitchStatus: tabSwitchStatus
    }

}

export const onBlurTextfield = (textField, event, addBusinessLogoBase64) => {
    return (dispatch) => {
        return dispatch(setTextFieldData(textField, event, addBusinessLogoBase64));
    }
}

export const setTextFieldData = (textField, textFieldValue, addBusinessLogoBase64) => {
    return {
        type: BUSINESS_TEXTFIELD_CHANGE,
        textFieldId: textField,
        textFieldData: textFieldValue,
        addBusinessLogoBase64: addBusinessLogoBase64
    }
}

//Handle Click Open Dialog Action
export const handleClickOpenDialog = (rowData) => {
    return (dispatch) => {
        return dispatch(manageOpenDialog(rowData));
    }
}

export const manageOpenDialog = (rowData) => {
    return {
        type: BUSINESS_OPEN_DIALOG,
        data: rowData
    }
}

//Handle Close Dialog Action
export const handleCloseDialog = () => {
    return (dispatch) => {
        return dispatch(manageCloseDialog());
    }
}

export const manageCloseDialog = () => {
    return {
        type: BUSINESS_CLOSE_DIALOG
    }
}

//Handle Modal Open Action
export const handleModalOpen = (rowData) => {
    return (dispatch) => {
        return dispatch(manageModalOpen(rowData));
    }
}

export const manageModalOpen = (rowData) => {
    return {
        type: BUSINESS_MODAL_OPEN,
        data: rowData
    }
}

//Handle Modal Close Action
export const handleModalClose = (rowData) => {
    return (dispatch) => {
        return dispatch(manageModalClose(rowData));
    }
}

//Manage Modal Close Action
export const manageModalClose = (rowData) => {
    return {
        type: BUSINESS_MODAL_CLOSE,
        data: rowData
    }
}


