import React, { Component } from 'react';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withRouter } from 'react-router-dom';
import {
    handleForgotPassword,
    handleTextFieldChange
} from '../../actions/Login';
import './Signin.css';
class ForgotPassword extends Component {
  render() {
      let {showLoaderStatus} = this.props.loginState;
      let loginTextFields = {...this.props.loginState.loginTextFields}
      delete loginTextFields['password']
      let disableForgot = loginTextFields.email.value&&loginTextFields.email.value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)?false:true
    return (
        <div className="d-flex align-items-center justify-content-center">
            <div className="card-body">
                <form>
                    {
                        Object.keys(loginTextFields).map((textFields) => {
                            return(
                            <div className="input-group no-border input-lg" key = {loginTextFields[textFields].name}>
                                <div className="input-group-prepend">
                                    <span className="input-group-text">
                                        <i className={loginTextFields[textFields].icon}></i>
                                    </span>
                                </div>
                                <input 
                                    type={loginTextFields[textFields].type}
                                    className="form-control" 
                                    placeholder={loginTextFields[textFields].name}
                                    value = {loginTextFields[textFields].value}
                                    onChange = {(event) => {this.props.handleTextFieldChange(textFields, event,loginTextFields)}}
                                />
                            </div>
                            
                            )
                        })
                    }

                        {
                            showLoaderStatus ? <CircularProgress style = {{color : "#69bce6"}} /> : 
                                <div className="text-center">
                                    <button
                                        // type = "submit"
                                        onClick = {() => this.props.handleForgotPassword(loginTextFields, this.props)} 
                                        className="btn btn-info btn-round btn-lg btn-block btn-login"
                                        disabled={disableForgot}
                                        style={{cursor:disableForgot?'not-allowed':''}}
                                    >
                                        Forgot Password
                                    </button>
                                    <div className="pull-left">
                                        <h6>
                                            <a href = "javascript:void(0)" onClick={()=>{this.props.handleType('notauser')}} className="link" style = {{cursor : "pointer"}}>
                                                Not A User?
                                            </a>
                                        </h6>
                                    </div>
                                    <div className="pull-right">
                                        <h6>
                                        <a onClick={()=>{this.props.handleType('signin')}} className="link" style = {{cursor : 'pointer'}}>
                                            Already a User
                                        </a>
                                        </h6>
                                    </div>
                                </div>
                        }

            </form>
        </div>
    </div>
    );
  }
}

const mapStateToProps = state => {
    return {
        loginState : state.login
    }
}

const mapDispatchToProps = dispatch => {
    return{
        handleForgotPassword : (loginControls, props) => {
            return dispatch(handleForgotPassword(loginControls, props))
        },
        handleTextFieldChange : (textField, event,loginTextField) => {
            return dispatch(handleTextFieldChange(textField, event,loginTextField))
        },
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ForgotPassword));
