import React, { Component } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import CardHeader from '@material-ui/core/CardHeader';
import FormGroup from '@material-ui/core/FormGroup';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import { isValidEmail } from '../../../utils/EmailValidator';

import Input from "../../../components/UI/Input/Input";
import {
    createPartnerUser,
} from '../../../actions/PartnerUser';
import { roles } from '../../../constants/roles';


class AddPartnerUser extends Component{
    
    state = {
        formIsValid: false,
        partnerUserForm: {
            firstName: {
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    label: 'First Name'
                },
                value: '',
                errorStatus: false,
                errorMessage: "Enter user first name",
                maxLength: 60,
                validate: (value) => value && value.length > 1
            },
            lastName: {
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    label: 'Last Name'
                },
                value: '',
                errorStatus: false,
                errorMessage: "Enter user last name",
                maxLength: 40,
                validate: (value) => value && value.length > 1
            },
            email: {
                elementType: 'input',
                elementConfig: {
                    type: 'email',
                    label: 'User Email'
                },
                value: '',
                errorStatus: false,
                errorMessage: "Enter a valid email address",
                maxLength: 60,
                validate: isValidEmail
            },
            accesslevel: {
                elementType: 'select',
                elementConfig: {
                    type: 'text',
                    label: 'Partner Role'
                },
                value: '',
                errorStatus: false,
                errorMessage: "Select Partner Role",
                menuItems: {
                    'partner_editor': {
                        name: "Partner Editor",
                        value: roles.PARTNER_EDITOR
                    },
                    'partnerr_reporter': {
                        name: "Partner Reporter",
                        value: roles.PARTNER_REPORTER
                    },
                },
                validate: (value) => !!value
            }
        }
    }

    partnerUserInputHandler(formElementId, event){
        const partnerForm = this.state.partnerUserForm;
        
        const formElement = partnerForm[formElementId];
        partnerForm[formElementId].value = event.target.value;

        let isValid = true;
        Object.keys(partnerForm).forEach(key => {
            if (partnerForm[key].validate && !partnerForm[key].validate(partnerForm[key].value)) {
                partnerForm[key].errorStatus = true
                isValid = false;
            } else {
                partnerForm[key].errorStatus = false
            }
        });
        
        this.setState({
            addPartnerForm: partnerForm,
            formIsValid: isValid
        });
    }
    

    render(){
        let {
            apiLoading
        } = this.props.partnerUserState;
        let{
            menuItems
        } = this.props.sidebarState;
        
        
        
        const formElementArray = Object.keys(this.state.partnerUserForm).map(key => ({ id: key, config: this.state.partnerUserForm[key] }));

        let form = (
            <FormGroup row>
                <Grid container spacing={24}>
                    {
                        formElementArray.map(formElement => (
                                <Grid item xs={12} sm = {6} key = {formElement.id}>
                                    <Input 
                                        key = {formElement.id}
                                        name = {formElement.config.elementConfig.label}
                                        elementType={formElement.config.elementType}
                                        elementConfig={formElement.config.elementConfig}
                                        value={formElement.config.value}
                                        menuItems = {formElement.config.menuItems}
                                        error = {formElement.config.errorStatus}
                                        helperText = {formElement.config.errorMessage}
                                        changed={(event) => this.partnerUserInputHandler(formElement.id, event )}
                                        touched = {(event) => this.partnerUserInputHandler(formElement.id, event )}
                                        maxLength={formElement.config.maxLength}
                                    />
                                </Grid>
                            
                        ))
                    }
                </Grid>
                <Grid container spacing={24} className = "pt-2">
                    <Grid item xs={10}>
                    </Grid>
                    <Grid item xs={2} className = "d-flex justify-content-end">
                        {
                            apiLoading ? <CircularProgress color = "primary" /> :
                            <Button
                                variant="contained" 
                                color="primary" 
                                disabled={!this.state.formIsValid}
                                onClick={() => {this.props.onSubmitPartnerUser(this.state.partnerUserForm)}}
                            >
                                Submit
                            </Button>
                        }
                    </Grid>
                </Grid>
            </FormGroup>
        )
        return(
            <div className = "container">
                <Card className = "pl-3 pr-3 pt-3">
                        <div>
                            <Grid container spacing={24} className = "pt-2">
                                <Grid item xs={12} sm = {10}>
                                    <CardHeader title="Add User"/>
                                </Grid>
                            </Grid>
                        </div>
                    <CardContent>
                        {form}
                    </CardContent>
                </Card>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        partnerUserState : state.partnerUser,
        sidebarState : state.sidebarState
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onSubmitPartnerUser : (partnerUserForm) => {
            
            const partnerUser = {
                firstName: partnerUserForm.firstName.value,
                lastName: partnerUserForm.lastName.value,
                email: partnerUserForm.email.value,
                accesslevel: Number(partnerUserForm.accesslevel.value)
            };

            return dispatch(createPartnerUser(partnerUser))
        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AddPartnerUser));
