import {MANAGE_EDIT_POTENTIAL_NON_COMPLIANCE_OPEN_DIALOG , MANAGE_EDIT_POTENTIAL_NON_COMPLIANCE_CLOSE_DIALOG} from './ActionType';


export const handleEditPotentialNonComplianceOpenDialog = (eventInfo) => {
    return (dispatch) => {
        dispatch(manageEditPotentialNonComplianceOpenDialog(eventInfo));       
    }
}

export const manageEditPotentialNonComplianceOpenDialog = (eventInfo) => {
    return{
        type : MANAGE_EDIT_POTENTIAL_NON_COMPLIANCE_OPEN_DIALOG,
        eventInfo : eventInfo        
    }
}

export const handleEditPotentialNonComplianceDialogClose = (eventInfo) => {
    return (dispatch) => {
        dispatch(manageEditPotentialNonComplianceCloseDialog(eventInfo));       
    }
}

export const manageEditPotentialNonComplianceCloseDialog = (eventInfo) => {
    return{
        type : MANAGE_EDIT_POTENTIAL_NON_COMPLIANCE_CLOSE_DIALOG,
        eventInfo : eventInfo        
    }
}
