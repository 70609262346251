import {
    SET_EXTERNAL_SYS_INPUT_FIELD,
    SET_EXTERNAL_SYS_DEFAULT_VALUE,
    SET_EXTERNAL_SYS_SUBMITTED_DATA,
    SET_EXTERNAL_SYS_LIST_DEFAULT,
    UPDATE_EXTERNAL_SYS_INFO,
    DELETE_EXTERNAL_SYS_INFO
} from '../actions/ActionType';

import TextField from "@material-ui/core/TextField";
import React from 'react';
import { apiUrl } from "../config";

const initialState = {
    externalSystemForm : {
        'external_system_name' : {
            elementType : 'input',
            elementConfig : {
                type : 'text',
                label : 'External System Name'
            },
            value : '',
            errorStatus : false,
            errorMessage : "Enter External System Name",
            maxLength : 60
        },
        'external_system_url' : {
            elementType : 'input',
            elementConfig : {
                type : 'text',
                label : 'External System URL'
            },
            value : '',
            errorStatus : false,
            errorMessage : "Enter External System URL",
            maxLength : 250
        },
        'external_system_key' : {
            elementType : 'input',
            elementConfig : {
                type : 'text',
                label : 'External System Key'
            },
            value : '',
            errorStatus : false,
            errorMessage : "Enter External System Key",
            maxLength : 250
        }
    },
    showLoaderStatus : false,
    extsysColumns: [
        {
          title: 'External System',
          field: 'name'
        },
        {
          title: 'EndPoint',
          field: 'endpoint'
        },
        {
          title: 'Key',
          field: 'key'
        },
      ],
    externalSystemRowData: []
}

const setExternalSystemTextField = (state, action) => {
    if (action.data === '') {
      state.externalSystemForm[action.textField].errorStatus = true;
    }
    state.externalSystemForm[action.textField].value = action.data;
    return state;
  }

const submitExternalSystem = (state, action) => {
    state.externalSystemForm = action.externalSystemForm;
    state.showLoaderStatus = action.showLoaderStatus;
    return state;
}

const setExternalSystemDefault = (state, action) => {
    Object.keys(state.externalSystemForm).map((inputField) => {
        state.externalSystemForm[inputField].value = '';
        state.externalSystemForm[inputField].errorStatus = false;
    })
    return state;
}

const getExternalSystemList = (state, action) => {
    state.externalSystemRowData = action.externalSystemRowData;
    return state;
}

const updateExternalSystem = (state, action) => {
    state.externalSystemRowData = action.externalSystemRowData;
    return state;
}
  
const deleteExternalSystem = (state, action) => {
    state.externalSystemRowData = action.externalSystemRowData;
    return state;
}

const externalSystems = (state = initialState, action) => {
    let localState = Object.assign({}, state);
    switch (action.type) {
        case SET_EXTERNAL_SYS_INPUT_FIELD: return setExternalSystemTextField(localState, action);
        case SET_EXTERNAL_SYS_DEFAULT_VALUE: return setExternalSystemDefault(localState, action);
        case SET_EXTERNAL_SYS_SUBMITTED_DATA: return submitExternalSystem(localState, action);
        case SET_EXTERNAL_SYS_LIST_DEFAULT: return getExternalSystemList(localState, action);
        case UPDATE_EXTERNAL_SYS_INFO: return updateExternalSystem(localState, action);
        case DELETE_EXTERNAL_SYS_INFO: return deleteExternalSystem(localState, action);
    
        default:
        return localState;
    }
};  

export default externalSystems;