import React, { Component } from 'react';
import '../styles/bootstrap.min.css';
import '../styles/now-ui-kit.min5438.css?v=1.2.0';
import LoginImage from '../images/login1.jpg';
import SignIn from '../components/Login/SignIn';
import NotAUser from '../components/Login/NotAUser';
import ForgotPasword from '../components/Login/ForgotPasword';
import FirstTimeUser from '../components/Login/FirstTimeUser';
import SmartDriverLogo from '../images/smart_driver.png';
import ResetPassword from '../components/Login/ResetPassword';

class Login extends Component {
  constructor(props){
    super(props)
    this.state={
      eventType:'signin',
    }
  }

  handleType=(eventType)=>{
    this.setState({eventType:eventType})
  }
  render() {
    // if(((window.location.pathname).split('/'))[1] === 'change-password'){
    //   this.setState({eventType:'firstTimeUser'})
    // }
    return (
      <div className="Login">
        <div className="login-page sidebar-collapse">
        <div className="page-header clear-filter" filter-color="blue">
        <div className="page-header-image" style={{backgroundImage: `linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)),url(${LoginImage})`, transform: 'scaleX(-1)'}}> </div>
        <div className="content">
          <div className="container" style = {{marginTop : "-5%"}}>
            <div className="col-md-4 ml-auto mr-auto">
              <div className="card card-login card-plain">
                <div className="card-header text-center">
                  <img alt="logo" style={{width : "60%" }} src={SmartDriverLogo} />
                </div>
                {/* <FirstTimeUser /> */}
                {
                  ((window.location.pathname).split('/'))[1] === 'reset-password' ?
                  <ResetPassword /> :
                  ((window.location.pathname).split('/'))[1] === 'change-password' ?
                  <FirstTimeUser /> :
                  this.state.eventType=='signin'?
                  <SignIn handleType = {this.handleType} /> :
                  this.state.eventType=='password' ?
                  <ForgotPasword handleType = {this.handleType} /> :
                  this.state.eventType=='notauser' ?
                  <NotAUser handleType = {this.handleType} /> :
                  this.state.eventType=='firsttimeuser' ?
                  <FirstTimeUser handleType = {this.handleType} /> :
                  this.state.eventType=='resetpassword' ?
                  <FirstTimeUser handleType = {this.handleType} /> : null
                }
            </div>
          </div>
        </div>
      </div>
      <footer className="footer">
          <div className="container-fluid">

              <div >
                <div className = "d-flex justify-content-center mt-5">
                  <a href = "/termncondtion" className = "mr-2" style={{color:'#549bff'}} target = "_blank" >
                    <span>Term & Condition</span>
                  </a>
                  <span>  &#124;  </span>
                  <a href = "/privacypolicy" className = "ml-2" style={{color:'#549bff'}} target = "_blank">
                    <span>Privacy Policy</span>
                  </a>
                  <span>  &#124;  </span>
                  <a href="//support.smartedriver.com" target="_blank" className = "ml-2" style={{color:'#549bff'}}><span>Support</span></a>
                </div>
                <div className = "d-flex justify-content-end copyright" id="copyright">
                    &copy; {new Date().getFullYear()}, Powered by &nbsp;<a href="https://stepglobal.com/" style={{color:'#549bff'}} target="_blank">StepGlobal</a>.
                </div>
              </div>

          </div>
      </footer>
      </div>
    </div>
      </div>
    );
  }
}

export default Login;
