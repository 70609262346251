import {
  SET_MEASUREMENT_TYPE_TEXT_FIELD,
  SET_MEASUREMENTTYPE_LIST,
  SUBMIT_MEASUREMENTTYPE_INFO,
  UPDATE_MEASUREMENTTYPE_INFO,
  SET_DEFAULT_VALUE_TO_MEASUREMENT_TYPES
} from "../actions/ActionType";
import TextField from "@material-ui/core/TextField";
import React from 'react';

const initialState = {
  measurementTypeForm : {
    measurement_types : {
      elementType : 'input',
      elementConfig : {
        type : 'text',
        label : 'Enter Measurement Types'
      },
      value : '',
      errorStatus : false,
      errorMessage : "Enter Measurement Types",
      maxLength:256
    }
  },
  measurementtypesListcols: [
    { title: "Measurement Type ID", field: "_id",
    editComponent: props => (
      <TextField
        type="text"
        value={props.value}
        disabled = {true}
      />
    ),
    cellStyle: {
      width : "18%",
    }, 
  },
    { title: "Measurement Type", 
      field: "mm_measurement_types",
      editComponent: props => {
        initialState.activeAxelConfigDescription = props.value;
        return(
          <TextField
            type="text"
            multiline
            value={props.value}
            onChange = {e => props.onChange(e.target.value)}
            inputProps={{ maxLength: 256 }}
            style = {{width: "100%"}} 
          />
        )
      },
      cellStyle: {
        wordWrap: "break-word",
        maxWidth : '200px',
      },
  
  },
    {
      title: "Status",
      field: "status",
      lookup: {
        true: "Active",
        false: "Inactive"
      },
      cellStyle: {
        width : "10%",
      },
    }
  ],
  measurementtypesListrows: [],

  //Loader Status
  measurementTypeListLoaderStatus : false
};


const measurementtypes = (state = initialState, action) => {
  let localState = Object.assign({}, state);
  switch (action.type) {
    case SET_DEFAULT_VALUE_TO_MEASUREMENT_TYPES :
      Object.keys(localState.measurementTypeForm).map((inputField) => {
        localState.measurementTypeForm[inputField].value = '';
        localState.measurementTypeForm[inputField].errorStatus = false;
      })
      return localState;
    case SET_MEASUREMENT_TYPE_TEXT_FIELD:
      if(action.data === ''){
        localState.measurementTypeForm[action.textField].errorStatus = true;
      }else{
        localState.measurementTypeForm[action.textField].errorStatus = false;
      }
      localState.measurementTypeForm[action.textField].value = action.data;
      return localState;
    case SET_MEASUREMENTTYPE_LIST :
        localState.measurementTypeListLoaderStatus = action.measurementTypeListLoaderStatus;
        localState.measurementtypesListrows = action.measurementTypeList;
        return localState;
    case SUBMIT_MEASUREMENTTYPE_INFO:
        localState.measurementTypeForm = action.measurementTypeForm;
        localState.showLoaderStatus = action.showLoaderStatus;
        localState.measurementtypesListrows = action.measurementtypesListrows;
        return localState;
    case UPDATE_MEASUREMENTTYPE_INFO:
          localState.measurementtypesListrows = action.measurementtypesListrows;
          return localState;
    default:
      return localState;
  }
};

export default measurementtypes;
