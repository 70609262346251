import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import { withRouter } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import { BillGeneratorService } from '../../../utils/BillGeneratorService';

import {
  getPartners,
} from '../../../actions/Partner';
import {
  getSGMenu,
  partnerSelect
} from '../../../actions/Sidebar';
import SGMaterialTable from '../../../components/UI/Table/SGMaterialTable';

import AddPartner from './AddPartner';

const styles = theme => ({
    root: {
        width : '100%',
        flexGrow: 1,
    },
    table: {
        minWidth: 500,
    },
    paper: {
        padding: theme.spacing.unit * 2,
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
  card: {
    minWidth: 275,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  button: {
    margin: theme.spacing.unit,
  },
  input: {
    display: 'none',
  },
  tableWrapper: {
    overflowX: 'auto',
  },
});
class ListPartner extends Component{
    componentDidMount(){
        this.props.getSGMenu();
        this.props.getPartners();
        
        localStorage.removeItem('partner_id');
    }

    billingService = new BillGeneratorService((status) => this.setState({apiLoading: status}));
 
    state = {     
      csvData:[],
      addEditPartnerDialogOpen: false
    };

    columnDefs = [
        {
            title: 'Partner ID',
            field: 'partner_id',
            editComponent: props => (
                <TextField
                    type="text"
                    value={props.value}
                    InputProps={{
                        readOnly: true,
                    }}
                />
            )
        },
        {
            title: 'Partner Name',
            field: 'partner_name'
        },
        {
            title: 'Email',
            field: 'partner_email',
        },
        {
            title: 'Phone',
            field: 'partner_phone',
        }
    ];

    openAddEditDialog(){
      this.setState({
        addEditPartnerDialogOpen: true
      })
    }
    closeAddEditDialog(){
      this.setState({
        addEditPartnerDialogOpen: false
      })
    }

    editPartner(rowData, url) {
      const urlSegments = url.split('/');
      urlSegments[urlSegments.length -1] = rowData.partner_id;
      const updatedUrl = urlSegments.join('/');
      this.props.history.push(updatedUrl);
    }


    render(){
        let {
           partners,
           apiLoading
          } = this.props.partners;
       
        apiLoading = this.state.apiLoading || apiLoading;  
        let{
          menuItems
        } = this.props.sidebarState;

        let actionDisArrays = [
            {
                icon: 'call_made',
                tooltip: 'Partner Select',
                onClick: (event, rowData) => this.props.partnerSelect(rowData, menuItems['business_list'].urlpattern, this.props)
            },
            {
                icon: 'edit',
                tooltip: 'Edit',
                onClick: (event, rowData) => { this.editPartner(rowData, menuItems['partner_edit'].urlpattern); }
            },
            {
              icon: 'monetization_on',
              iconProps: { color: "red" },
              tooltip: 'Billing Report',
              onClick: (event, rowData) => {             
                  this.billingService.calculateBillForPartner(rowData);               
              },
          }
        ];

        return(
            <div className = "container" style = {{marginTop : '2rem', marginBottom : "2rem"}}>
              <Paper>
              <div className = "pt-1 pl-3">
                <span className = "d-flex">
                  <div className = "mr-auto" style = {{paddingTop : "0"}}>
                      <h4 style = {{marginTop : "1rem", textTransform : "uppercase"}}>
                      Partner List
                      </h4>
                  </div>
                  <div className = "d-flex flex-wrap align-content-center">
                  <Button
                          variant="contained"
                          color="primary"
                          onClick = {() => {
                            this.billingService.calculateBillForSgAdmin();
                          }}
                          className = "mr-2"
                      >
                          <i className="material-icons" style = {{paddingRight : "0.5rem"}}>
                              monetization_on
                          </i>
                          Generate Bill
                      </Button>
                      
                      <Button
                          variant="contained"
                          color="primary"
                          onClick = {() => {
                            this.props.history.push("/sgadminportal/partners/newpartner");
                          }}
                          className = "mr-2"
                      >
                          <i className="material-icons" style = {{paddingRight : "0.5rem"}}>
                              add_circle_outline
                          </i>
                          Add Partner
                      </Button>
                  </div>
                </span>
              </div>
                {
                  apiLoading ?
                  <div style = {{height : "20rem"}} className = "d-flex justify-content-center align-items-center">
                      <CircularProgress color = "primary" />
                  </div> :
                  <SGMaterialTable
                    columns={this.columnDefs}
                    data={partners}
                    title=""
                    actions={actionDisArrays}
                  />
                }
              </Paper>
              <Dialog
                  open={this.state.addEditPartnerDialogOpen}
                  onClose={() => {  /* TODO */ }}
                  maxWidth = 'md'
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
              >
                  <AddPartner/>
              </Dialog>
            </div>
            
        )
    }
}

const mapStateToProps = (state) => {
  return {
      partners: state.partners,
      sidebarState : state.sidebarState
  }
}

const mapDispatchToProps = (dispatch) => {
  return{
    getPartners : () =>{
        return dispatch(getPartners());
    },
    partnerSelect : (rowData) => {
      dispatch(partnerSelect(rowData.partner_id));
    },
    getSGMenu : () => {
      return dispatch(getSGMenu())
    }
  }
}
export default connect(mapStateToProps, mapDispatchToProps) (withStyles(styles)(withRouter(ListPartner)));
