import {
  SET_AXEL_CONFIG_TEXT_FIELD,
  SET_AXELCONFIG_LIST,
  SUBMIT_AXEL_CONFIG,
  UPDATE_AXELCONFIG_INFO,
  SET_DEFAULT_VALUE_TO_AXEL_CONFIG_COMPONENT,
  SET_UPLOAD_INPUT_CHANGE_AXLE
} from "../actions/ActionType";
import TextField from "@material-ui/core/TextField";
import React from 'react';
import { apiUrl } from "../config";
import {
  downloadCert
} from "../utils/Helper";
import {
  getaxelConfigList,
} from '../actions/AxelConfig';
const initialState = {
  axelConfigForm: {
    axelGroupType: {
      elementType: 'input',
      elementConfig: {
        type: 'text',
        label: 'Axle Config Group'
      },
      value: '',
      errorStatus: false,
      errorMessage: "Enter Axle Config Group",
      maxLength: 256
    },
    axel_image: {
      elementType: 'upload_image',
      elementConfig: {
        type: 'text',
        label: 'Axle Image'
      },
      value: '',
      errorStatus: false,
      errorMessage: "Axle Image not added",
      maxLength: 256
    }
  },
  activeaxelConfigName: '',
  activeaxelConfigImage: '',
  base64_string: '',
  axelConfigListcols: [
    {
      title: "Axle Id",
      field: "_id",
      editComponent: props => (
        <TextField
          type="text"
          value={props.value}
          disabled={true}
        />
      ),
      cellStyle: {
        width: "15%",
      },
    },
    {
      title: "Axle Config Group",
      field: "axelGroupType",
      editComponent: props => {
        initialState.activeaxelConfigName = props.value;
        return (
          <TextField
            type="text"
            multiline
            value={props.value}
            onChange={e => props.onChange(e.target.value)}
            inputProps={{ maxLength: 256 }}
            style={{ width: "100%" }}
          />
        )
      },
      cellStyle: {
        wordWrap: "break-word",
        maxWidth: '200px',
      },
    },
    {
      title: "Axle Image",
      field: "axelConfigObjectData",
      render: rowData =>
        <a
          href="javascript:void(0);"
          style={{ color: "blue" }}
          //TODO: BusinessID not required for this call - see email from Ian on 11/11/2019 - Page.
          onClick={() => downloadCert(apiUrl + "/businesses/" + localStorage.getItem('business_id') + "/getobject?bucket=" + rowData.bucket + '&key=' + rowData.key)}
        >
          Download
        </a>,
      editComponent: props => (
        <TextField
            type="text"
            value="Download"
            // onChange={e => props.onChange(e.target.value)}
            inputProps={{ maxLength: 256 }}
            style={{ width: "100%" }}
            disabled={true}
          />
      ),
      cellStyle: {
        wordWrap: "break-word",
        maxWidth: '200px',
      },
    },
    {
      title: "Status",
      field: "status",
      lookup: {
        "true": "Active",
        "false": "Inactive"
      },
      cellStyle: {
        width: "10%",
      },
    }
  ],
  axelConfigListrows: [],

  //Loader Status
  listaxelConfigLoaderStatus: false,
  editMCDialogOpen: false,
  showMCUpdateLoaderStatus: false,
};

const setUploadInputChanges = (state, action) => {
  if (action.data === '' && action.textField === 'axel_image') {
    state.axelConfigForm[action.textField].errorStatus = true;
  } else {
    state.axelConfigForm[action.textField].errorStatus = false;
  }
  state.axelConfigForm[action.textField].value = action.data;
  state.base64_string = action.base64;
  return state;
}
const handleDescription = (initialState, value) => {
  let localState = Object.assign({}, initialState);
  localState.activeaxelConfigName = value;
  return localState;
}

const setDefaultValueToaxelConfigComponent = (state, action) => {
  Object.keys(state.axelConfigForm).map((inputField) => {
    state.axelConfigForm[inputField].value = '';
    state.axelConfigForm[inputField].errorStatus = false;
  })
  return state;
}

const setaxelConfigTextField = (state, action) => {
  if (action.data === '') {
    state.axelConfigForm[action.textField].errorStatus = true;
  }
  state.axelConfigForm[action.textField].value = action.data;
  return state;
}

const setaxelConfigList = (state, action) => {
  state.listaxelConfigLoaderStatus = action.listaxelConfigLoaderStatus;
  state.axelConfigListrows = action.axelConfigList;
  return state;
}

const submitaxelConfig = (state, action) => {
  state.axelConfigForm = action.axelConfigForm;
  state.showLoaderStatus = action.showLoaderStatus;
  state.axelConfigListrows = action.axelConfigListrows;
  return state;
}

const updateInfo = (state, action) => {
  state.axelConfigListrows = action.axelConfigListrows;
  return state;
}
const axelConfig = (state = initialState, action) => {
  let localState = Object.assign({}, state);
  switch (action.type) {
    case SET_DEFAULT_VALUE_TO_AXEL_CONFIG_COMPONENT: return setDefaultValueToaxelConfigComponent(localState, action);
    case SET_AXEL_CONFIG_TEXT_FIELD: return setaxelConfigTextField(localState, action);
    case SET_AXELCONFIG_LIST: return setaxelConfigList(localState, action);
    case SUBMIT_AXEL_CONFIG: return submitaxelConfig(localState, action);
    case UPDATE_AXELCONFIG_INFO: return updateInfo(localState, action);
    case SET_UPLOAD_INPUT_CHANGE_AXLE: return setUploadInputChanges(localState, action);
    default:
      return localState;
  }
};

export default axelConfig;
