import React, { Component } from 'react';
import FormGroup from '@material-ui/core/FormGroup';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withRouter } from 'react-router-dom';

import Input from "../../components/UI/Input/Input";
import {
  addMTRecordFormInputHandler,
  addMTRecordFormDateHandler,
  onSubmitMTRecord,
  getMTList,
  handleEditMTRecordCloseDialog,
  onUpdateMTRecord,
  initializeAddMTForm
} from "../../actions/MedicalTreatment";
class AddMedicalTreatment extends Component {
  componentDidMount() {
    this.props.getMTList();
    if (!this.props.medicalTreatmentState.editMTRecordDialogStatus) {
      this.props.initializeAddMTForm()
    }
  }
  disablePrevTreatmentDate() {
    if (this.props.medicalTreatmentState.addMTRecordForm['treatment_date'].value !== null) {
      const startSeconds = Date.parse(this.props.medicalTreatmentState.addMTRecordForm['treatment_date'].value);
      return (date) => {
        return Date.parse(date) < startSeconds;
      }
    }
  }
  disablePrevNextTreatmentDate() {
    if (this.props.medicalTreatmentState.addMTRecordForm['next_treatment_date'].value !== null && this.props.medicalTreatmentState.addMTRecordForm['treatment_date'].value !== null) {
      const startSeconds = Date.parse(this.props.medicalTreatmentState.addMTRecordForm['next_treatment_date'].value);
      const endSeconds = Date.parse(this.props.medicalTreatmentState.addMTRecordForm['treatment_date'].value);
      return (date) => {
        return ((Date.parse(date) > startSeconds) || (Date.parse(date) < endSeconds));
      }
    }
  }
  render() {
    let {
      addMTRecordForm,
      medicalTreatMentBase64,
      editMTRecordDialogStatus,
      activeMTRecordForEdit,
      showMTRecordSubmitLoaderStatus,
      showMTRecordUpdateLoaderStatus
    } = this.props.medicalTreatmentState;
    let {
      menuItems
    } = this.props.sidebarState;
    let driverId = ((window.location.pathname).split('/'))[2];
    const formElementArray = [];
    for (let key in addMTRecordForm) {
      formElementArray.push({
        id: key,
        config: addMTRecordForm[key]
      })
    }
    let form = (
      <FormGroup row>
        <Grid container spacing={24}>
          {
            formElementArray.map(formElement => {
              if (formElement.config.elementType === 'date') {
                if (formElement.id === 'treatment_date') {
                  return (
                    <Grid item xs={12} sm={6} key={formElement.id}>
                      <Input
                        key={formElement.id}
                        name={formElement.config.elementConfig.label}
                        elementType={formElement.config.elementType}
                        elementConfig={formElement.config.elementConfig}
                        value={formElement.config.value}
                        menuItems={formElement.config.menuItems}
                        error={formElement.config.errorStatus}
                        helperText={formElement.config.errorMessage}
                        changed={(date) => this.props.addMTRecordFormDateHandler(formElement.id, date, medicalTreatMentBase64)}
                      />
                    </Grid>
                  )
                }
                if (formElement.id === 'next_treatment_date') {
                  return (
                    <Grid item xs={12} sm={6} key={formElement.id}>
                      <Input
                        key={formElement.id}
                        name={formElement.config.elementConfig.label}
                        elementType={formElement.config.elementType}
                        elementConfig={formElement.config.elementConfig}
                        value={formElement.config.value}
                        menuItems={formElement.config.menuItems}
                        error={formElement.config.errorStatus}
                        helperText={formElement.config.errorMessage}
                        changed={(date) => this.props.addMTRecordFormDateHandler(formElement.id, date, medicalTreatMentBase64)}
                        shouldDisableDate={this.disablePrevTreatmentDate()}
                        disabledStatus={formElement.config.disabledStatus}
                      />
                    </Grid>
                  )
                }
                if (formElement.id === 'notification_date') {
                  return (
                    <Grid item xs={12} sm={6} key={formElement.id}>
                      <Input
                        key={formElement.id}
                        name={formElement.config.elementConfig.label}
                        elementType={formElement.config.elementType}
                        elementConfig={formElement.config.elementConfig}
                        value={formElement.config.value}
                        menuItems={formElement.config.menuItems}
                        error={formElement.config.errorStatus}
                        helperText={formElement.config.errorMessage}
                        changed={(date) => this.props.addMTRecordFormDateHandler(formElement.id, date, medicalTreatMentBase64)}
                        shouldDisableDate={this.disablePrevNextTreatmentDate()}
                        disabledStatus={formElement.config.disabledStatus}
                      />
                    </Grid>
                  )
                }
              } else {
                return (
                  <Grid item xs={12} sm={6} key={formElement.id}>
                    <Input
                      key={formElement.id}
                      name={formElement.config.elementConfig.label}
                      elementType={formElement.config.elementType}
                      elementConfig={formElement.config.elementConfig}
                      value={formElement.config.value}
                      menuItems={formElement.config.menuItems}
                      error={formElement.config.errorStatus}
                      helperText={formElement.config.errorMessage}
                      changed={(event) => this.props.addMTRecordFormInputHandler(formElement.id, event)}
                    />
                  </Grid>
                )
              }

            })
          }
        </Grid>
        <Grid container spacing={24} className="pt-2">
          <Grid item xs={8}>
          </Grid>
          <Grid item xs={4} className="d-flex justify-content-end">

            {
              editMTRecordDialogStatus ?
                <span>
                  {
                    showMTRecordUpdateLoaderStatus ?
                      <div className="d-flex justify-content-center align-items-center">
                        <CircularProgress color="primary" />
                      </div> :
                      <span>
                        <Button
                          variant="contained"
                          color="primary"
                          className="mr-3"
                          disabled={false}
                          style={{ marginRight: "1rem" }}
                          onClick={() => { this.props.onUpdateMTRecord(addMTRecordForm, activeMTRecordForEdit, driverId, medicalTreatMentBase64, this.props) }}
                        >
                          Update
                                        </Button>
                        <Button
                          variant="contained"
                          color="default"
                          disabled={false}
                          onClick={() => { this.props.handleEditMTRecordCloseDialog() }}
                        >
                          Cancel
                                        </Button>
                      </span>
                  }
                </span> :
                showMTRecordSubmitLoaderStatus ?
                  <div className="d-flex justify-content-center align-items-center">
                    <CircularProgress color="primary" />
                  </div> :
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={false}
                    onClick={() => { this.props.onSubmitMTRecord(addMTRecordForm, driverId, medicalTreatMentBase64, this.props, menuItems['medical_treatment_list'].urlpattern) }}>
                    Submit
                            </Button>
            }
          </Grid>
        </Grid>
      </FormGroup>
    )
    return (
      <div className="container">
        <Card className="pl-3 pr-3 pt-3">
          <div>
            <Grid container spacing={24} className="pt-2">
              <Grid item xs={12} sm={10}>
                {
                  editMTRecordDialogStatus ?
                    <CardHeader title="Edit MT Record" /> :
                    <CardHeader title="Add MT Record" />
                }
              </Grid>
            </Grid>
          </div>
          <CardContent>
            {form}
          </CardContent>
        </Card>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    medicalTreatmentState: state.medicalTreatment,
    sidebarState: state.sidebarState
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getMTList: () => {
      return dispatch(getMTList())
    },
    addMTRecordFormInputHandler: (inputFieldId, event, medicalTreatMentBase64) => {
      return dispatch(addMTRecordFormInputHandler(inputFieldId, event, medicalTreatMentBase64))
    },
    onSubmitMTRecord: (addMTRecordForm, driverId, medicalTreatMentBase64, props, url) => {
      return dispatch(onSubmitMTRecord(addMTRecordForm, driverId, medicalTreatMentBase64, props, url))
    },
    addMTRecordFormDateHandler: (inputFieldId, date) => {
      return dispatch(addMTRecordFormDateHandler(inputFieldId, date))
    },
    handleEditMTRecordCloseDialog: () => {
      return dispatch(handleEditMTRecordCloseDialog())
    },
    onUpdateMTRecord: (addMTRecordForm, activeMTRecordForEdit, driverId, medicalTreatMentBase64, props) => {
      return dispatch(onUpdateMTRecord(addMTRecordForm, activeMTRecordForEdit, driverId, medicalTreatMentBase64, props))
    },
    initializeAddMTForm: () => {
      return dispatch(initializeAddMTForm())
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AddMedicalTreatment));