import React, { Component } from 'react';
import MaterialTable from 'material-table';
import { connect } from 'react-redux';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { withRouter } from 'react-router-dom';
import Dialog from '@material-ui/core/Dialog';
import CircularProgress from '@material-ui/core/CircularProgress';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';

import {
  getExternalDriver,
  getESTypes,
  handleDeleteEDRecord,
  handleEditEDRecordOpenDialog,
  handleEditEDRecordCloseDialog
} from "../../actions/ExternalDriver";
import AddEDRecord from './AddExternalDriver';

import SGMaterialTable from '../../components/UI/Table/SGMaterialTable';
import {apiUrl} from "../../config";

import {
  GetUrl
}from "../../utils/Helper";


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class ListExternalDriver extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openDialog: false,
      edColumns: [
        {
          title: 'External System',
          field: 'es_type'
        },
      ],
    }
  }
  componentDidMount() {
    this.props.getExternalDriver();
    //this.props.getESTypes();
  }
  navigateToAddEDRecord(url) {
    let actulaURL = '';
    if (url.indexOf(":driverid") >= 0) {
      actulaURL = url.replace(":driverid", localStorage.getItem('driver_id'));
    }
    if (actulaURL !== '') {
      url = actulaURL;
    }
    this.props.history.push(url);
  }
  handleClose =()=>{
    this.setState({openDialog:false})
  }
  render() {
    let {
      edInformations,
      editEDRecordDialogStatus,
      esTypeLookUp,
      showEDRecordListLoaderStatus,
    } = this.props.externalDriverState;
    let {
      menuItems
    } = this.props.sidebarState;
    //this.state.edColumns[1].lookup = esTypeLookUp;
    return (
      <div className="container mt-3">
        <Paper>
          <Grid container spacing={24} style={{ paddingLeft: "1rem", paddingTop: "0", paddingRight: "1rem" }}>
            <Grid item xs={6} style={{ paddingTop: "0" }}>
              <h4 style={{ marginTop: "1rem", textTransform: "uppercase" }}>Driver External System Detail List</h4>
            </Grid>
            <Grid item xs={6} style={{ display: "flex", justifyContent: "flex-end", paddingBottom: "2rem" }}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => this.navigateToAddEDRecord(menuItems['external_driver_add'].urlpattern)}
              >
                <i className="material-icons" style={{ paddingRight: "0.5rem" }}>
                  add_circle_outline
                              </i>
                Add External System Record
              </Button>

            </Grid>
          </Grid>
          {
            showEDRecordListLoaderStatus ?
              <div style={{ height: "30rem" }} className="d-flex justify-content-center align-items-center">
                <CircularProgress color="primary" />
              </div> :
              <SGMaterialTable
                title=""
                columns={this.state.edColumns}
                data={edInformations}
                actions={[
                  {
                    icon: 'delete',
                    tooltip: 'Unlink',
                    onClick: (event, rowData) => this.props.handleDeleteEDRecord(rowData, edInformations)
                  }
                ]}
              />
          }

          <Dialog
            open={editEDRecordDialogStatus}
            onClose={() => { this.props.handleEditEDRecordCloseDialog() }}
            maxWidth='md'
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <AddEDRecord />
          </Dialog>
          <Dialog
            open={this.state.openDialog}
            TransitionComponent={Transition}
            onClose={window.setTimeout(() => {
              this.setState({openDialog: false});
           }, 5000)}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
          >
            <DialogTitle id="alert-dialog-slide-title">Loading Please Wait..</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-slide-description">
                {this.state.docUrl &&
                <embed src={this.state.docUrl} width="200px" height="150px" />

                }
          </DialogContentText>
            </DialogContent>
            {/* <DialogActions>
              <Button onClick={this.handleClose} color="primary">
                Close
          </Button>
            </DialogActions> */}
          </Dialog>
        </Paper>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    externalDriverState: state.externalDriver,
    sidebarState: state.sidebarState
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getExternalDriver: (driverId) => {
      return dispatch(getExternalDriver(driverId))
    },
    getESTypes: () => {
      return dispatch(getESTypes())
    },
    handleDeleteEDRecord: (rowData, edInformations) => {
      return dispatch(handleDeleteEDRecord(rowData, edInformations))
    },
    handleEditEDRecordOpenDialog: (rowData) => {
      return dispatch(handleEditEDRecordOpenDialog(rowData))
    },
    handleEditEDRecordCloseDialog: () => {
      return dispatch(handleEditEDRecordCloseDialog())
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ListExternalDriver));