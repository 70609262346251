import React, { Component } from 'react';
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import FormGroup from "@material-ui/core/FormGroup";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import CardHeader from "@material-ui/core/CardHeader";
import MaterialTable from 'material-table';
import CircularProgress from '@material-ui/core/CircularProgress';
import {connect} from "react-redux";

import Input from "../../../components/UI/Input/Input";
import {
    inputHandlerUploadDistributor,
    ondistributorUploadSubmit,
    initializeDistributorUploadForm
} from "../../../actions/Distributor";

import {
    convertArrayToCSVDownload,
} from "../../../utils/Helper";

import SGMaterialTable from '../../../components/UI/Table/SGMaterialTable'

class DistributorBulkUpload extends Component{
    componentDidMount(){
        this.props.initializeDistributorUploadForm();
    }
    downloadCSVFile = () =>{
        let data = [
            [
                'userfirstname', 
                'userlastname', 
                'accesslevel', 
                'useremailaddress'
            ]
          ];
          convertArrayToCSVDownload(data, 'distributor_bulk');

    }
    render(){
        let {
            uploadDistributorForms,
            bulkUploadedDistributors,
            distributorUploadStatusList,
            distributorUploadStatusTableColumns,
            distributorBulkUploadSubmitLoaderStatus
        } = this.props.distributorState;
        const formElementArray = [];
        for(let key in uploadDistributorForms){
          formElementArray.push({
            id : key,
            config : uploadDistributorForms[key]
          })
        }
        let form = (
            <FormGroup row className = "pl-3 pr-1">
                <Grid container spacing={24}>
                    <Grid item xs={10} sm = {10}>
                        <form>
                            {
                                formElementArray.map(formElement => (
                                    <Input 
                                    key = {formElement.id}
                                    elementType={formElement.config.elementType}
                                    elementConfig={formElement.config.elementConfig}
                                    value={formElement.config.value}
                                    error = {formElement.config.errorStatus}
                                    helperText = {formElement.config.errorMessage}
                                    changed={(event) => this.props.inputHandlerUploadDistributor(formElement.id, event)}
                                    //   touched = {(event) => this.props.inputHandlerVehicleType(formElement.id, event )}
                                    />
                                ))
                            }
                        </form>
                    </Grid> 
                    <Grid item xs={2} sm = {2}>
                    {
                        distributorBulkUploadSubmitLoaderStatus ? <CircularProgress color = "primary" /> :
                        <Button 
                            variant="contained" 
                            color = "primary"
                            className = "mt-4"
                            onClick = {() => this.props.ondistributorUploadSubmit(bulkUploadedDistributors, uploadDistributorForms)}
                            >
                            Submit
                        </Button>
                    }
                    </Grid>     
                </Grid>
            </FormGroup>
        )
        return(
            <div className = "container">

                <Grid container spacing={24}>
                    <Grid item xs={1} sm={2} />
                        <Grid item xs={12} sm={8}>
                        <Card className = "pl-3 pr-3 pt-3">
                            <Grid container spacing={24}>
                                <Grid item xs={6} sm = {6}>
                                    <CardHeader
                                        title="Upload Resellers"
                                    />
                                </Grid>                                
                                <Grid item xs={6} sm = {6} style = {{marginTop : "-1rem", display : "flex", justifyContent : "flex-end"}}>

                                    <Button 
                                        variant="contained" 
                                        color = "default"
                                        className = "mt-4 mb-4"
                                        onClick = {this.downloadCSVFile}
                                    >
                                        <i className="material-icons" style = {{paddingRight : "0.6rem"}}>
                                            cloud_download
                                        </i>
                                        Download sample csv
                                    </Button>
                                </Grid>
                            </Grid>

                            <CardContent>
                                {form}
                            </CardContent>
                            <CardActions>
                                
                            </CardActions>
                        </Card>
                        {
                            distributorUploadStatusList.length > 0 ?
                            <div className = "mt-3">
                                <SGMaterialTable
                                    columns={distributorUploadStatusTableColumns}
                                    data={distributorUploadStatusList}
                                    title=""
                                />
                            </div>: null
                        }
                    </Grid>
                    <Grid item xs={1} sm={2} />
                </Grid>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return{
        distributorState : state.distributor
    }
}

const mapDispatchToProps = dispatch => {
    return{
        inputHandlerUploadDistributor : (formElementId, event) => {
            return dispatch(inputHandlerUploadDistributor(formElementId, event))
        },
        ondistributorUploadSubmit : (bulkUploadedDistributors, uploadDistributorForms) => {
            return dispatch(ondistributorUploadSubmit(bulkUploadedDistributors, uploadDistributorForms))
        },
        initializeDistributorUploadForm : () => {
            return dispatch(initializeDistributorUploadForm())
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DistributorBulkUpload);