import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import CardHeader from '@material-ui/core/CardHeader';
import{ withRouter } from 'react-router-dom';
import FormGroup from '@material-ui/core/FormGroup';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';

import Input from "../../components/UI/Input/Input";
import { styles } from 'material-ui-pickers/wrappers/InlineWrapper';
import {handleEditPotentialNonComplianceDialogClose} from '../../actions/PotentialNonCompliance';

class EditPotentialNonCompliance extends Component {

    render(){

        const { classes } = this.props;
        let { 
            editPotentialNonCompliancelist,        
            openEditpotentialNonComplianceDialogStatus,              
        } = this.props.potentialNonComplianceState
        
        const potentialNonComplianceArray = [];

        for(let key in editPotentialNonCompliancelist){
            potentialNonComplianceArray.push({
                id : key,
                config : editPotentialNonCompliancelist[key]
            })
        }

        let editPotentialNonComplianceForm = (
            <FormGroup row>
                <Grid container spacing={50}>
                    {
                        potentialNonComplianceArray.map(formElement => {
                         
                            return(
                                <Grid item xs={12} sm = {4} key = {formElement.id}>
                                    <Input
                                        key = {formElement.id}
                                        name = {formElement.config.elementConfig.label}
                                        elementType={formElement.config.elementType}
                                        elementConfig={formElement.config.elementConfig}
                                        value={formElement.config.value}
                                        menuItems = {formElement.config.menuItems}
                                        error = {formElement.config.errorStatus}
                                        helperText = {formElement.config.errorMessage}
                                        //changed={(event) => this.props.handleAddDriverInputFieldChange(formElement.id, event)}
                                        maxLength = {formElement.config.maxLength}
                                        disabledStatus = {formElement.config.disabledStatus}
                                        //touched = {(event) => this.props.handleAddDriverInputFieldChange(formElement.id, event)}

                                    />
                                </Grid>
                            )    
                         
                   
                        }
                    )
                    }
                </Grid>
                <Grid container spacing={30}>
                {
                     <div>
                         <Button variant="contained" color="primary" className = "mr-3" onClick = {() => {this.props.handleEditPotentialNonComplianceDialogClose()}} >Submit</Button>
                         <Button variant="contained" color="default" className = "mr-3" onClick = {() => {this.props.handleEditPotentialNonComplianceDialogClose()}} >Cancel </Button>   
                     </div>
                }                             
                </Grid>

            </FormGroup>
        )

        return(
           <div className = "container">
                <Card className={classes.card}  className = "pl-3 pr-3 pt-3 mb-5" >
                    <CardHeader
                                title="Edit Non Compliance Event"
                    />
                    <CardContent>
                         <div>
                          {editPotentialNonComplianceForm}
                          <br/>
                         </div>
                    </CardContent>
                </Card>
           </div> 
        )

    }
  
}

const mapStateToProps = (state) => {
    return{
        potentialNonComplianceState : state.potentialNonCompliance,       
    }
}

const mapDispatchToProps = (dispatch) => {
    return{
        handleEditPotentialNonComplianceDialogClose : () => {
            return dispatch(handleEditPotentialNonComplianceDialogClose())
        },
    }
}

export default connect(mapStateToProps,mapDispatchToProps) (withStyles(styles)(withRouter(EditPotentialNonCompliance)));
