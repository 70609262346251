import {
  SET_MEASUREMENT_TYPE_TEXT_FIELD,
  SET_MEASUREMENTTYPE_LIST,
  SUBMIT_MEASUREMENTTYPE_INFO,
  UPDATE_MEASUREMENTTYPE_INFO,
  SET_DEFAULT_VALUE_TO_MEASUREMENT_TYPES
} from "../actions/ActionType";
import axios from '../axios';
import { toast } from 'react-toastify';

export const initializemeasurementTypes = () => {
  return (dispatch) => {
    return dispatch(setDefaultValueTomeasurementTypes())
  }
}
export const setDefaultValueTomeasurementTypes = () => {
  return{
    type : SET_DEFAULT_VALUE_TO_MEASUREMENT_TYPES
  }
}
/********************************************************************************* */
export const inputHandlermeasurementType = (textField, event) => {
  return dispatch => {
    return dispatch(setmeasurementtypesTextFields(textField, event));
  };
};

export const setmeasurementtypesTextFields = (textField, event) => {
  return {
    type: SET_MEASUREMENT_TYPE_TEXT_FIELD,
    textField: textField,
    data: event.target.value
  };
};

/********************************************************************************************** */ 
/**************************************************************************************** */

export const onSubmit = (measurementTypeInfo, props) => {
return (dispatch) => {
  let showLoaderStatus = false;
  let fieldValidationStatus = true;

  Object.keys(measurementTypeInfo.measurementTypeForm).map((textField) => {
      if(measurementTypeInfo.measurementTypeForm[textField].value  == ''){
          fieldValidationStatus = false;
          measurementTypeInfo.measurementTypeForm[textField].errorStatus = true;
      }
  })
  dispatch(setSubmittedData(
    measurementTypeInfo.measurementtypesListrows,
    showLoaderStatus,
    measurementTypeInfo.measurementTypeForm
    )
  );  
  if(fieldValidationStatus){
    showLoaderStatus = true;
      dispatch(setSubmittedData(
          measurementTypeInfo.measurementtypesListrows,
          showLoaderStatus,
          measurementTypeInfo.measurementTypeForm
          )
      );
      let addMeasurementTypeReq = {};
      let createdInfo = {};
      addMeasurementTypeReq["mm_measurement_types"] = measurementTypeInfo.measurementTypeForm['measurement_types'].value;
      addMeasurementTypeReq["status"] = true;
      return axios.post('/sg/measurementtypes', addMeasurementTypeReq)
      .then(response => {
              showLoaderStatus = false;
              createdInfo = {};
              createdInfo['_id'] = response.data.data._id;
              createdInfo['mm_measurement_types'] = response.data.data.mm_measurement_types;
              createdInfo['status'] = response.data.data.status;
              measurementTypeInfo.measurementtypesListrows.unshift(createdInfo);
              Object.keys(measurementTypeInfo.measurementTypeForm).map((textField) => {
                  measurementTypeInfo.measurementTypeForm[textField].value = '';
              })
              toast.success("MeasurementType Created Successfully", {
                              containerId: 'toastMsg',
                              onClose :  () => props.history.push('/sgadminportal/measurementtypes')
                            }
                          );
              dispatch(setSubmittedData(
                          measurementTypeInfo.measurementtypesListrows,
                          showLoaderStatus,
                          measurementTypeInfo.measurementTypeForm
                          )
                      );
      })
      .catch(error => {
          if(error.response.status == 403){
              showLoaderStatus = false;
              localStorage.clear();
              // toast.error("Session Expired ! Please login again", {containerId: 'toastMsg'});
              window.location.href = "/";
          }else{
              showLoaderStatus = false;
              toast.error(error.response.data.message, {containerId: 'toastMsg'});
              dispatch(setSubmittedData(
                          measurementTypeInfo.measurementtypesListrows,
                          showLoaderStatus,
                          measurementTypeInfo.measurementTypeForm
                          )
                      ); 
          }
      })
  }

}

}

export const setSubmittedData = ( measurementtypesListrows, showLoaderStatus, measurementTypeForm) => {
return {
  type: SUBMIT_MEASUREMENTTYPE_INFO,
  measurementtypesListrows : measurementtypesListrows,
  showLoaderStatus : showLoaderStatus,
  measurementTypeForm : measurementTypeForm
}
}
/************************************************************************/
export const getMeasurementTypeList = () => {
  let measurementtype = {};
  let measurementTypeList = [];
  let measurementTypeListLoaderStatus = true;
  return (dispatch) => {
    dispatch(setMeasurementTypeList(measurementTypeList, measurementTypeListLoaderStatus));
      return axios.get('/sg/measurementtypes')
      .then(response => {
        measurementTypeListLoaderStatus = false;
              for(let i = 0; i < response.data.data.length; i++){
                measurementtype = {};
                measurementtype['_id'] = response.data.data[i]._id;
                measurementtype['mm_measurement_types'] = response.data.data[i].mm_measurement_types;
                measurementtype['status'] = response.data.data[i].status;
                measurementTypeList.push(measurementtype);
              }
              dispatch(setMeasurementTypeList(measurementTypeList, measurementTypeListLoaderStatus));
      })
      .catch(error => {
        measurementTypeListLoaderStatus = false;
          if(error.response.status == 403){
              localStorage.clear();
              window.location.href = "/";
          }
          dispatch(setMeasurementTypeList(measurementTypeList, measurementTypeListLoaderStatus));
      })
  }
}

export const setMeasurementTypeList = (measurementTypeList, measurementTypeListLoaderStatus) => {
  return{
      type : SET_MEASUREMENTTYPE_LIST,
      measurementTypeList : measurementTypeList,
      measurementTypeListLoaderStatus : measurementTypeListLoaderStatus
  }
}

/************************************************************************************************************* */ 
export const editMeasurementType = (measurementTypeInfo, measurementtypesListrows) => {
  let reqURL = '/sg/measurementtypes/' + measurementTypeInfo._id;
  console.log("url here" + reqURL);
  let updateReq = {};
  updateReq['mm_measurement_types'] = measurementTypeInfo.mm_measurement_types;
  updateReq['status'] = measurementTypeInfo.status=="true"?true:false;
  return (dispatch) => {
      return axios.put(reqURL, updateReq)
      .then(response => {
              const updateIndex = measurementtypesListrows.findIndex(d => {
                  return d._id === measurementTypeInfo._id;
              });
              measurementtypesListrows[updateIndex].mm_measurement_types = measurementTypeInfo.mm_measurement_types;
              measurementtypesListrows[updateIndex].status = measurementTypeInfo.status;
              toast.success("Measurement Type Updated Successfully", {containerId: 'toastMsg'});
              dispatch(updateInfo(measurementtypesListrows));
      })
      .catch(error => {
           if(error.response.status == 403){
              localStorage.clear();
              toast.error(
                          "Session Expired ! Please login again", 
                            {
                              containerId: 'toastMsg',
                              onclose : () => window.location.href = "/"
                            }
                          );
           }else{
              toast.error(error.response.data.message, {containerId: 'toastMsg'});
           }

           dispatch(updateInfo(measurementtypesListrows));
      })
  }
}
export const updateInfo = (measurementtypesListrows) => {
  return{
      type : UPDATE_MEASUREMENTTYPE_INFO,
      measurementtypesListrows : measurementtypesListrows
  }
}