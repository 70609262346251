import React, { Component } from "react";
import MaterialTable from "material-table";
const moment = require('moment');

export default class NCReportTable extends Component {

    complianceColumns = [
        {
            title: 'Driver Name',
            field: 'driverName'
        },
        {
            title: 'License Number',
            field: 'driver_license'
        },
        {
            title: 'UID',
            field: 'driver_id'
        },
        {
            title: 'Vehicle',
            field: 'vehicle_id'
        },
        {
            title: 'Breach Condition',
            field: 'breach_type'
        },
        {
            title: 'DateTime',
            field: 'breach_createdtimeoffset',
            render: rowData => moment(rowData).format('YYYY-MM-DD HH:mm')
        },
        {
            title: 'Location',
            field: 'location'
        }
    ]; 

    render() {
        
        const { breachData } = this.props;
        
        return (
            <MaterialTable
                columns={this.complianceColumns}
                data={breachData}
                title="Potential breach list"
            />
        )
    }
}