import React, { Component } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import FormGroup from '@material-ui/core/FormGroup';
import CircularProgress from '@material-ui/core/CircularProgress';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import Input from "../../../../components/UI/Input/Input";

import {
    handleAddBusinessBillingFormField,
    addBusinessAdminOnSubmit,
    addBusinessAdminBillingBack,
    addBusinessAdminOnUpdate
} from '../../../../actions/Business';

class Billing extends Component{
    render(){
        let editBusinessSearchFoundStatus = (window.location.href).search('editbusiness');
        let editProfileSearchFoundStatus = (window.location.href).search('editprofile');
        let {
            billingForm,
            addBusinessReqParams,
            addBusinessAdminLoaderStatus
        } = this.props.business;
        let{
            menuItems
        } = this.props.sidebarState;
        const formElementArray = [];
        for(let key in billingForm){
            formElementArray.push({
                id : key,
                config : billingForm[key]
            })
        }
        let form = (
            <FormGroup row>
                <Grid container spacing={24}>
                    {
                        formElementArray.map(formElement => (
                            <Grid item xs={12} sm = {6} key = {formElement.id}>
                                <Input
                                    key = {formElement.id}
                                    name = {formElement.config.elementConfig.label}
                                    elementType={formElement.config.elementType}
                                    elementConfig={formElement.config.elementConfig}
                                    value={formElement.config.value}
                                    menuItems = {formElement.config.menuItems}
                                    error = {formElement.config.errorStatus}
                                    helperText = {formElement.config.errorMessage}
                                    changed={(event) => this.props.handleAddBusinessBillingFormField(formElement.id, event)}
                                    // touched = {(event) => this.props.handleTextFieldChange(formElement.id, event )}
                                    maxLength={formElement.config.maxLength}
                                />
                            </Grid>
                        ))
                    }
                </Grid>
                <Grid
                    container
                    spacing={24}
                    className = "pt-2"
                    direction="row"
                    justify="flex-end"
                    alignItems="center"
                >
                    {
                        editBusinessSearchFoundStatus != -1 || editProfileSearchFoundStatus != -1 ?
                        addBusinessAdminLoaderStatus ? <CircularProgress /> :
                        <Grid item className = "d-flex justify-content-end">
                            <Button
                                variant="contained"
                                color="default"
                                className = "mr-3"
                                disabled={false}
                                onClick = {() => {this.props.addBusinessAdminBillingBack()}}
                            >
                                Back
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                disabled={false}
                                onClick = {() => {this.props.addBusinessAdminOnUpdate(billingForm, addBusinessReqParams, this.props)}}
                            >
                                Update
                            </Button>
                        </Grid> :
                        addBusinessAdminLoaderStatus ? <CircularProgress /> :
                        <Grid item className = "d-flex justify-content-end">
                            <Button
                                variant="contained"
                                color="default"
                                className = "mr-3"
                                disabled={false}
                                onClick = {() => {this.props.addBusinessAdminBillingBack()}}
                            >
                                Back
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                disabled={false}
                                onClick = {() => {this.props.addBusinessAdminOnSubmit(billingForm, addBusinessReqParams, this.props)}}
                            >
                                Submit
                            </Button>
                        </Grid>
                    }
                </Grid>
            </FormGroup>
        )
        return(
            <Card  className = "pl-3 pr-3 pt-3 mb-3">
                <Grid container spacing={24} className = "pt-2">
                    <Grid item xs={12} sm = {10}>
                        <CardHeader title="Billing"/>
                    </Grid>
                </Grid>
                <CardContent>
                    {form}
                </CardContent>
            </Card>
        )
    }
}

const mapStateToProps = state => {
    return{
        business : state.business,
        sidebarState : state.sidebarState,
        profileState : state.profile
    }
}

const mapDispatchToProps = dispatch => {
    return{
        handleAddBusinessBillingFormField : (formFieldId, event) => {
            return dispatch(handleAddBusinessBillingFormField(formFieldId, event))
        },
        addBusinessAdminOnSubmit : (billingForm, addBusinessReqParams, props) => {
            return dispatch(addBusinessAdminOnSubmit(billingForm, addBusinessReqParams, props))
        },
        addBusinessAdminOnUpdate : (billingForm, addBusinessReqParams, props, url,editProfileSearchFoundStatus) => {
            return dispatch(addBusinessAdminOnUpdate(billingForm, addBusinessReqParams, props, url,editProfileSearchFoundStatus))
        },
        addBusinessAdminBillingBack : () => {
            return dispatch(addBusinessAdminBillingBack())
        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Billing));
