import { 
    SUBMIT_SFM_INFO ,
    SET_SFM_FILE_SELECTED,
    SET_DEFAULT_STATE_SFM
  } from "./ActionType";

  import axios from '../axios';
  import { toast } from 'react-toastify';
  import { convertToJSON } from "./Common";

  export const initializeState = () => {
      return (dispatch) => {
          return dispatch(setDefaultState())
      }
  }
  export const setDefaultState = () => {
      return{
          type : SET_DEFAULT_STATE_SFM
      }
  }
  export const inputHandler = (formElementId, event, sfmJSON) => {
    return (dispatch) => {
        let formData = (event.target.value).replace(/^.*\\/, "");
        if (formElementId === 'upload_sfm'){

            if(event.target.files != null){
                convertToJSON(event.target.files[0]).then(data => {
                    dispatch(setInputHandler(formElementId, formData, data));
                })
                .catch(error => {
                })
            }
        }
        dispatch(setInputHandler(formElementId, formData, sfmJSON)); 
        
    }
}
export const setInputHandler = (formElementId, formData, sfmJSON) => {
    return{
        type : SET_SFM_FILE_SELECTED,
        formElementId : formElementId,
        data : formData,
        sfmJSON : sfmJSON
    }
}

export const onBlurTextfield=(inputFieldId,event)=>{
  return (dispatch) => {
      return dispatch(setInputHandler(inputFieldId, event));
  }
}

  /************************************************************************************************** */
  export const onSubmitSFM = (uploadSFMForm, sfmJSON) => {
    return (dispatch) => {
        let sfmLoaderStatus = false;
        let uploadSFMFormVAlidationStatus = true;
        Object.keys(uploadSFMForm).map((formField) => {
          if(uploadSFMForm[formField].value === ''){
              uploadSFMFormVAlidationStatus = false;
          }
        })
        dispatch(submitData(sfmLoaderStatus));
        if(uploadSFMFormVAlidationStatus){
          sfmLoaderStatus = true;
          let reqURL;
          let driverType = uploadSFMForm['driver_type'].value;
          let vehicleType = uploadSFMForm['vehicle_category'].value;
          reqURL = "/sg/sfm/drivertype/"+driverType+"/vehicletype/"+vehicleType;
          dispatch(submitData(sfmLoaderStatus));
          return axios.post(reqURL, sfmJSON)
          .then(response => {
            sfmLoaderStatus = false;
              toast.success("SFM Document Uploaded Successfully", {
                  containerId: 'toastMsg',
              });
              dispatch(submitData(sfmLoaderStatus));
          })
          .catch(error => {
            sfmLoaderStatus = false;
            toast.error(error.response.data.message, {
                containerId: 'toastMsg',
            });
            dispatch(submitData(sfmLoaderStatus));
          })
      }
    }
}
export const submitData = (sfmLoaderStatus) => {
    return {
        type : SUBMIT_SFM_INFO,
        sfmLoaderStatus : sfmLoaderStatus
    }
}