import React, { Component } from "react";
import MaterialTable from "material-table";
import { connect } from "react-redux";
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { toast } from 'react-toastify';
import {
  editAccessLevel,
  getAccessLevel,
} from '../../actions/AccessLevel';

class ListAccessLevel extends Component {
  componentDidMount(){
    this.props.getAccessLevel();
}
  render() {
    let { accessLevelCols, accessLevelRows } = this.props.accessLevel;
    return (
       <div 
        style = {{marginTop : '2rem', marginBottom : "2rem"}}
        className = "container"
      >
        <Paper>
          <div className = "pt-1 pl-3">
              <span className = "d-flex">
                <div className = "mr-auto" style = {{paddingTop : "0"}}>
                    <h4 style = {{marginTop : "1rem", textTransform : "uppercase"}}>Access Level List</h4>
                </div>
                <div className = "d-flex flex-wrap align-content-center">
                    <Button 
                        variant="contained" 
                        color="primary"
                        onClick = {() => this.props.history.push("/sgadminportal/accesslevel/newaccesslevel")}
                        className = "mr-2"
                    >
                        <i className="material-icons" style = {{paddingRight : "0.5rem"}}>
                            add_circle_outline
                        </i>
                        Add Access Level
                    </Button>
                </div>
              </span>
            </div>
        {accessLevelRows.length == 0 ? 
          <div style = {{height : "20rem"}} className = "d-flex justify-content-center align-items-center">
            <CircularProgress color = "primary" />
          </div>:
            <MaterialTable
              columns={accessLevelCols}
              data={accessLevelRows}
              title=""
              editable={{
                onRowUpdate: (newData, oldData) =>
                  new Promise((resolve, reject) => {
                    if(newData.description !== ''){
                      resolve(this.props.editAccessLevel(newData, accessLevelRows));
                    }else{
                      toast.error("Description field should not be blank", {containerId: 'toastMsg'});
                      reject();
                    }
                  }),
              }}
              options={{ actionsColumnIndex: -1}}
            />
          }
          </Paper>
      </div>
      
    );
  }
  
}

const mapStateToProps = state => {
  return {
    accessLevel: state.accessLevel
  };
};
const mapDispatchToProps = dispatch => {
  return{
    getAccessLevel : () => {
          return dispatch(getAccessLevel())
      },
    editAccessLevel : (newData, accessLevelRows) => {
        return dispatch(editAccessLevel(newData, accessLevelRows))
      }
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ListAccessLevel);
