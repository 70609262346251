import React, { Component } from "react";
// import MaterialTable from "material-table";
import SGMateriaTable from "../../components/UI/Table/SGMaterialTable";
import Dialog from '@material-ui/core/Dialog';
import { connect } from "react-redux";
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { toast } from 'react-toastify';
import Typography from '@material-ui/core/Typography';
import AddLoadTypes from './AddLoadTypes';
import {
  // editloadType,
  getloadTypeList,
  handleClickOpenDialog,
  handleCloseDialog
} from '../../actions/LoadTypes';
import SGMaterialTable from '../../components/UI/Table/SGMaterialTable';
class ListLoadTypes extends Component {
  componentDidMount() {
    this.props.getloadTypeList();
  }
  render() {
    let {
      menuItems
    } = this.props.sidebarState;
    let {
      loadtypesListcols,
      loadtypesListrows,
      editLoadTypeDialogOpen,
      loadTypeListLoaderStatus
    } = this.props.loadtypesState;
    // console.log(editLoadTypeDialogOpen)
    return (
      <div
        style={{ marginTop: '2rem', marginBottom: "2rem" }}
        className="container"
      >
        <Paper>
          <div className="pt-1 pl-3">
            <span className="d-flex">
              <div className="mr-auto" style={{ paddingTop: "0" }}>
                <h4 style={{ marginTop: "1rem", textTransform: "uppercase" }}>Load Type List</h4>
              </div>
              <div className="d-flex flex-wrap align-content-center">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => this.props.history.push(menuItems['load_types_create'].urlpattern)}
                  className="mr-2"
                >
                  <i className="material-icons" style={{ paddingRight: "0.5rem" }}>
                    add_circle_outline
                        </i>
                  Add Load Type
                    </Button>
              </div>
            </span>
          </div>
          {
            loadTypeListLoaderStatus ?
              <div style={{ height: "20rem" }} className="d-flex justify-content-center align-items-center">
                <CircularProgress color="primary" />
              </div> :
              <SGMateriaTable
                columns={loadtypesListcols}
                data={loadtypesListrows}
                title=""
                actions={[
                  {
                    icon: 'edit',
                    tooltip: 'Edit',
                    onClick: (event, rowData) => this.props.handleClickOpenDialog(rowData)
                  }
                ]}
              />
            // <MaterialTable
            //   columns={loadtypesListcols}
            //   data={loadtypesListrows}
            //   title=""
            //   editable={{
            //     onRowUpdate: (newData, oldData) =>
            //       new Promise((resolve, reject) => {
            //         if(newData.mm_load_types !== ''){
            //           resolve(this.props.editloadType(newData, loadtypesListrows));
            //         }else{
            //           toast.error("Load Types field should not be blank", {containerId: 'toastMsg'});
            //           reject();
            //         }
            //       }),
            //   }}
            //   options={{ actionsColumnIndex: -1}}
            // />
          }
        </Paper>
        <Dialog
          open={editLoadTypeDialogOpen}
          onClose={() => { this.props.handleCloseDialog() }}
          maxWidth='md'
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <AddLoadTypes />
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    loadtypesState: state.loadTypes,
    sidebarState: state.sidebarState
  };
};
const mapDispatchToProps = dispatch => {
  return {
    handleClickOpenDialog: (rowData) => {
      return dispatch(handleClickOpenDialog(rowData))
    },
    handleCloseDialog: () => {
      return dispatch(handleCloseDialog())
    },
    getloadTypeList: () => {
      return dispatch(getloadTypeList())
    },
    // editloadType: (newData, loadtypesListrows) => {
    //   return dispatch(editloadType(newData, loadtypesListrows))
    // }
    
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ListLoadTypes);
