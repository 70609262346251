import React, { Component } from "react";
import MaterialTable from "material-table";
import { connect } from "react-redux";
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { toast } from 'react-toastify';
import Typography from '@material-ui/core/Typography';
import {
  editMassVariableType,
  getMassVariableTypeList,
} from '../../actions/MassVariableTypes';
import SGMaterialTable from '../../components/UI/Table/SGMaterialTable';
class ListMassVariableTypes extends Component {
  componentDidMount(){
    this.props.getMassVariableTypeList();
}
  render() {
    let { 
      massVariabletypesListcols,
      massVariabletypesListrows,
      massVariableTypeListLoaderStatus
    } = this.props.massVariabletypesState;
    console.log(this.props.massVariabletypesState)
    return (
       <div 
        style = {{marginTop : '2rem', marginBottom : "2rem"}}
        className = "container"
      >
        <Paper>
            <div className = "pt-1 pl-3">
              <span className = "d-flex">
                <div className = "mr-auto" style = {{paddingTop : "0"}}>
                    <h4 style = {{marginTop : "1rem", textTransform : "uppercase"}}>Mass Variable Type List</h4>
                </div>
                <div className = "d-flex flex-wrap align-content-center">
                    <Button 
                        variant="contained" 
                        color="primary"
                        onClick = {() => this.props.history.push("/sgadminportal/massvariabletypes/newmassvariabletype")}
                        className = "mr-2"
                    >
                        <i className="material-icons" style = {{paddingRight : "0.5rem"}}>
                            add_circle_outline
                        </i>
                        Add Mass Variable Type
                    </Button>
                </div>
              </span>
            </div>
            {
              massVariableTypeListLoaderStatus ? 
                <div style = {{height : "20rem"}} className = "d-flex justify-content-center align-items-center">
                    <CircularProgress color = "primary" />
                </div>:
                <MaterialTable
                  columns={massVariabletypesListcols}
                  data={massVariabletypesListrows}
                  title=""
                  editable={{
                    onRowUpdate: (newData, oldData) =>
                      new Promise((resolve, reject) => {
                        if(newData.mm_massvariable_types !== ''){
                          resolve(this.props.editMassVariableType(newData, massVariabletypesListrows));
                        }else{
                          toast.error("Mass Variable field should not be blank", {containerId: 'toastMsg'});
                          reject();
                        }
                      }),
                  }}
                  options={{ actionsColumnIndex: -1}}
                />
              }
          </Paper>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    massVariabletypesState: state.MassVariableTypes
  };
};
const mapDispatchToProps = dispatch => {
  return{
    getMassVariableTypeList : () => {
          return dispatch(getMassVariableTypeList())
      },
    editMassVariableType : (newData, massVariabletypesListrows) => {
        return dispatch(editMassVariableType(newData, massVariabletypesListrows))
      }
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ListMassVariableTypes);
