import { 
    SUBMIT_AFM_INFO ,
    SET_FILE_SELECTED,
    SET_DEFAULT_STATE_AFM
  } from "./ActionType";

  import axios from '../axios';
  import { toast } from 'react-toastify';
  import { convertToJSON } from "./Common";
  //import {ExcelRenderer, OutTable} from 'react-excel-renderer';

  /**************************************************************************************** */
  export const initializeState = () => {
      return (dispatch) => {
          return dispatch(setDefaultState())
      }
  }
  export const setDefaultState = () => {
      return{
          type : SET_DEFAULT_STATE_AFM
      }
  }
  export const inputHandler = (formElementId, event, afmJSON) => {
    return (dispatch) => {
        let formData = event.target.value;
        if(formElementId === 'abn'){
            formData = event.target.value.replace(/[^0-9]/g, '');
        }
        if (formElementId === 'upload_afm'){
            formData = (event.target.value).replace(/^.*\\/, "");
            if(event.target.files != null){
                convertToJSON(event.target.files[0]).then(data => {
                    dispatch(setInputHandler(formElementId, formData, data)); 
                })
                .catch(error => {
                })
            }
        }
        dispatch(setInputHandler(formElementId, formData, afmJSON)); 
        
    }
}
export const setInputHandler = (formElementId, formData, afmJSON) => {
    return{
        type : SET_FILE_SELECTED,
        formElementId : formElementId,
        data : formData,
        afmJSON : afmJSON
    }
}

export const onBlurTextfield=(inputFieldId,event)=>{
  return (dispatch) => {
      return dispatch(setInputHandler(inputFieldId, event));
  }
}

  /************************************************************************************************** */
  export const onSubmitAFM = (uploadAFMForm, afmJSON) => {
    return (dispatch) => {
        let afmLoaderStatus = false;
        let uploadAFMFormVAlidationStatus = true;
        Object.keys(uploadAFMForm).map((formField) => {
          if(uploadAFMForm[formField].value === ''){
              uploadAFMFormVAlidationStatus = false;
              uploadAFMForm[formField].errorStatus = true;
          }else{
            uploadAFMForm[formField].errorStatus = false;
          }
        })
        dispatch(submitData(afmLoaderStatus, uploadAFMForm));
        if(uploadAFMFormVAlidationStatus){
            afmLoaderStatus = true;
            let reqURL;
            let driverType = uploadAFMForm['driver_type'].value;
            let vehicleType = uploadAFMForm['vehicle_category'].value;
            reqURL = "/sg/afm/abn/"+uploadAFMForm['abn'].value+"/drivertype/"+driverType+"/vehicletype/"+vehicleType;
            dispatch(submitData(afmLoaderStatus, uploadAFMForm));
            return axios.post(reqURL, afmJSON)
            .then(response => {
                afmLoaderStatus = false;
                Object.keys(uploadAFMForm).map(inputField => {
                    uploadAFMForm[inputField].value = '';
                })
                toast.success("AFM Document Uploaded Successfully", {
                    containerId: 'toastMsg',
                });
                dispatch(submitData(afmLoaderStatus, uploadAFMForm));
            })
            .catch(error => {
            afmLoaderStatus = false;
            toast.error(error.response.data.message, {
                containerId: 'toastMsg',
            });
            dispatch(submitData(afmLoaderStatus, uploadAFMForm));
            })
      }
    }
}
export const submitData = (afmLoaderStatus, uploadAFMForm) => {
    return {
        type : SUBMIT_AFM_INFO,
        afmLoaderStatus : afmLoaderStatus,
        uploadAFMForm : uploadAFMForm
    }
}

/****************************************************************************************************/

// export const downloadJson(filename, text) {
//   var element = document.createElement('a');
//   element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
//   element.setAttribute('download', filename);

//   element.style.display = 'none';
//   document.body.appendChild(element);

//   element.click();

//   document.body.removeChild(element);
// }