import React, { Component } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import FormGroup from '@material-ui/core/FormGroup';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import Input from "../../../components/UI/Input/Input";
import SGLoader from "../../../components/UI/Loader/SGLoader";
import {
    savePartner,
    updatePartner,
    getPartners,
} from '../../../actions/Partner';
import { roles } from '../../../constants/roles';
import { isValidEmail } from '../../../utils/EmailValidator';

class AddPartner extends Component {
    
    formInitialized = false;
    state = {
        isEditPartner: false,
        isEditProfile: false,
        formIsValid: false,
        selectedPartner: null,
        addPartnerForm: {
            name: {
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    label: 'Partner Name'
                },
                value: '',
                errorStatus: false,
                errorMessage: "Enter Partner Name",
                maxLength: 60,
                validate: (value) => value && value.length > 1
            },
            phone: {
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    label: 'Phone'
                },
                value: '',
                errorStatus: false,
                errorMessage: "Enter Phone",
                maxLength: 11,
                validate: (value) => value && value.length > 1
            },
            email: {
                elementType: 'input',
                elementConfig: {
                    type: 'email',
                    label: 'Email',
                    required: true
                },
                value: '',
                errorStatus: false,
                errorMessage: "Enter a valid email address",
                maxLength: 60,
                validate: (value) => isValidEmail(value)
            },
            abn: {
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    label: 'ABN',
                },
                value: '',
                errorStatus: false,
                errorMessage: "Enter ABN",
                maxLength: 11,
                validate: (value) => !value || value.length === 11
            },
            contactEmail: {
                elementType: 'input',
                elementConfig: {
                    type: 'email',
                    label: 'Contact Email',
                },
                value: '',
                errorStatus: false,
                errorMessage: "Enter a valid email address",
                maxLength: 60,
                validate: (value) => !value || isValidEmail(value)
            },
            contactNumber: {
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    label: 'Contact Phone',
                },
                value: '',
                errorStatus: false,
                errorMessage: "Enter phone number",
                maxLength: 20
            },
            address: {
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    label: 'Address',
                },
                value: '',
                errorStatus: false,
                errorMessage: "Enter address",
                maxLength: 200
            },
            country: {
                elementType: 'select',
                elementConfig: {
                    type: 'text',
                    label: 'Country',
                    required: true,
                },
                value: 'AUS',
                errorStatus: false,
                errorMessage: "Select Country",
                menuItems: {
                    'aus': {
                        name: "Australia",
                        value: "AUS"
                    }
                }
            },
            state : {
                elementType : 'select',
                elementConfig : {
                    type : 'text',
                    label : 'State',
                    required: true,
                },
                value : '',
                errorStatus : false,
                errorMessage : "Enter State",
                menuItems : {
                    'select_state' : {
                        name : "Select State",
                        value : ''
                    },
                    'nsw' : {
                        name : "NSW",
                        value : "NSW"
                    },
                    'qld':{
                        name : "QLD",
                        value : "QLD"
                    },
                    'sa' : {
                        name : "SA",
                        value : "SA"
                    },
                    'tas':{
                        name : "TAS",
                        value : "TAS"
                    },
                    'vic' : {
                        name : "VIC",
                        value : "VIC"
                    },
                    'act' : {
                        name : 'ACT',
                        value : 'ACT'
                    },
                    'nt' : {
                        name : 'NT',
                        value : 'NT'
                    },
                    'wa':{
                        name : "WA",
                        value : "WA"
                    },
                    'ashmore island':{
                        name : "ASHMORE ISLAND",
                        value : "ASHMORE ISLAND"
                    },
                    'cartier island':{
                        name : "CARTIER ISLAND",
                        value : "CARTIER ISLAND"
                    },
                    'christmas island':{
                        name:'CHRISTMAS ISLAND',
                        value:'CHRISTMAS ISLAND'
                    },
                    'cocos island':{
                        name:'COCOS ISLAND',
                        value:'COCOS ISLAND'
                    },
                    'coral sea island':{
                        name:'CORAL SEA ISLAND',
                        value:'CORAL SEA ISLAND'
                    },
                    'heard island':{
                        name:'HEARD ISLAND',
                        value:'HEARD ISLAND'
                    },
                    'mcdonald island':{
                        name:'MCDONALD ISLAND',
                        value:'MCDONALD ISLAND'
                    },
                    'oth':{
                        name : "OTH",
                        value : "OTH"
                    }
                },
            },
            postCode: {
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    label: 'Postcode',
                },
                value: '',
                errorStatus: false,
                errorMessage: "Enter postcode",
                maxLength: 8,
                validate: (value) => !value || value.length > 2
            },
            isActive: {
                elementType: 'select',
                elementConfig: {
                    type: 'text',
                    label: 'Status',
                    disabled: true
                },
                value: true,
                errorStatus: false,
                errorMessage: "Select Status",
                menuItems: {
                    'active': {
                        name: "Active",
                        value: true
                    },
                    'notActive': {
                        name: "Not Active",
                        value: false
                    }
                }
            },
            accessLevel: {
                elementType : 'input',
                elementConfig : {
                    type : 'hidden',
                    label : 'User role',
                    hidden: true
                },
                value : roles.PARTNER_ADMIN,
                errorStatus : false
            }
        }
    }

    handleTextFieldChange(formElementId, value){
        const partnerForm = this.state.addPartnerForm;
        
        partnerForm[formElementId].value = value;

        let isValid = true;
        Object.keys(partnerForm).forEach(key => {
            if (partnerForm[key].validate && !partnerForm[key].validate(partnerForm[key].value)) {
                partnerForm[key].errorStatus = true
                isValid = false;
            } else {
                partnerForm[key].errorStatus = false
            }
        });
        
        this.setState({
            addPartnerForm: partnerForm,
            formIsValid: isValid
        });
    }
    
    componentDidMount() {
        let isEditPartner = (window.location.href).search('editpartner') > 0;
        let isEditProfile = (window.location.href).search('editprofile') > 0;

        if (isEditPartner){
            const path = this.props.history.location.pathname.split('/');
            const partnerId = path[path.length -1];
            this.setState({ selectedPartnerId: partnerId, formIsValid: true });
            this.props.getPartners();
        }

        if (isEditProfile){
            const path = this.props.history.location.pathname.split('/');
            const partnerId = path[path.length - 2];
            this.setState({ selectedPartnerId: partnerId, formIsValid: true });
            this.props.getPartners();
        }

        this.setState({
            isEditPartner,
            isEditProfile,
            isNewPartner: !isEditPartner && !isEditProfile
        })
    }

    populateFormValue(partner){
        if (!partner){
            return;
        }

        Object.keys(this.state.addPartnerForm).forEach(formKey => {
            let value = '';
            switch(formKey){
    
                case 'name':
                case 'email':
                case 'phone':
                    value = partner[`partner_${formKey}`]
                    break;

                case 'accesslevel':
                    break;
                
                case 'isActive':
                    value = partner[formKey];
                    this.state.addPartnerForm[formKey].disabledStatus = this.state.isEditProfile;
                    break;
                
                default:
                    value = partner[formKey];
                    break;
            }
            this.state.addPartnerForm[formKey].value = value;
        })
        this.formInitialized = true;
    }

    render() {
        let {
            partners,
            apiLoading
        } = this.props.partnerState;

        const populateForm = (this.state.isEditPartner || this.state.isEditProfile) && !this.formInitialized;
        if (populateForm){
            const selectedPartner = partners.find(x => x.partner_id === this.state.selectedPartnerId );
            this.populateFormValue(selectedPartner);
        }
        

        const formElementArray = [];
        for (let key in this.state.addPartnerForm) {
            formElementArray.push({
                id: key,
                config: this.state.addPartnerForm[key]
            })
        }
        let form = (
            <FormGroup row>
                <Grid container spacing={24}>
                    {
                        formElementArray.map(formElement => {

                            return (
                                <Grid item xs={12} sm={6} key={formElement.id}>
                                    <Input
                                        key={formElement.id}
                                        name={formElement.config.elementConfig.label}
                                        elementType={formElement.config.elementType}
                                        elementConfig={formElement.config.elementConfig}
                                        disabledStatus={formElement.config.disabledStatus}
                                        value={formElement.config.value }
                                        menuItems={formElement.config.menuItems}
                                        error={formElement.config.errorStatus}
                                        helperText={formElement.config.errorMessage}
                                        changed={(event) => this.handleTextFieldChange(formElement.id, event.target.value)}
                                        touched={(event) => this.handleTextFieldChange(formElement.id, event.target.value)}
                                        maxLength={formElement.config.maxLength}
                                    />
                                </Grid>
                            )
                        })
                    }
                </Grid>
                <Grid
                    container
                    spacing={24}
                    className="pt-2"
                    direction="row"
                    justify="flex-end"
                    alignItems="center"
                >
                    <Grid item className="d-flex justify-content-end">
                        <Button
                            variant="contained"
                            color="primary"
                            disabled={!this.state.formIsValid}
                            onClick={() => { this.props.savePartner(this.state.addPartnerForm, this.state.isNewPartner, this.state.selectedPartnerId) }}
                        >
                        { this.state.isNewPartner ? 'ADD' : 'SAVE' }
                        </Button>
                    </Grid>
                </Grid>
            </FormGroup>
        )

        return (
            <Card className="pl-3 pr-3 pt-3 mb-3">
                <Grid container spacing={24} className="pt-2">
                    <Grid item xs={12} sm={10}>
                        <CardHeader title={this.state.isEditPartner ? "Edit Partner" : this.state.isEditProfile ? "Edit Profile" : "Add Partner"} />
                    </Grid>
                </Grid>
                <CardContent>
                    {
                        apiLoading ? <SGLoader /> : form
                    }
                </CardContent>
            </Card>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        partnerState: state.partners
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getPartners: () =>{
            return dispatch(getPartners());
        },
        savePartner: (partnerForm, isNew, partnerId) => {
            
            const partnerObj = {
                name: partnerForm['name'].value,
                email: partnerForm['email'].value,
                phone: partnerForm['phone'].value,

                abn: partnerForm['abn'].value || '',
                contactEmail: partnerForm['contactEmail'].value || '',
                contactNumber: partnerForm['contactNumber'].value || '',
                address: partnerForm['address'].value || '',
                country: partnerForm['country'].value || '',
                state: partnerForm['state'].value || '',
                postCode: partnerForm['postCode'].value || '',
                
                isActive: partnerForm['isActive'].value,

                accesslevel: partnerForm['accessLevel'].value,
            };
            
            if (isNew){
                return dispatch(savePartner(partnerObj))
            } 
            return dispatch(updatePartner(partnerObj, partnerId))
        },
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AddPartner));
