import {
    SET_MASSEVENT_TEXT_FIELD,
    SET_MASSEVENT_LIST,
    SUBMIT_MASSEVENT_INFO,
    UPDATE_MASSEVENT_INFO,
    SET_DEFAULT_VALUE_TO_INITIALIZE_MASS_EVENT_COMPONENT
  } from "./ActionType";
  
  import axios from '../axios';
  import { toast } from 'react-toastify';

  export const initializeMassEventComponent = () => {
    return (dispatch) => {
      return dispatch(setDefaultValueToInitializeMassEventComponent())
    }
  }
  export const setDefaultValueToInitializeMassEventComponent = () => {
    return{
      type : SET_DEFAULT_VALUE_TO_INITIALIZE_MASS_EVENT_COMPONENT
    }
  }
  /************************************************************************/
  export const getMassEventList = () => {
    let massEvent = {};
    let massEventList = [];
    return (dispatch) => {
        return axios.get('/sg/massevents')
        .then(response => {
                for(let i = 0; i < response.data.data.length; i++){
                  massEvent = {};
                  massEvent['masseventsid'] = response.data.data[i].masseventsid;
                  massEvent['description'] = response.data.data[i].description;
                  massEvent['current'] = response.data.data[i].current;
                  massEventList.push(massEvent);
                }
                dispatch(setMassEventList(massEventList));
        })
        .catch(error => {
            if(error.response.status == 403){
                localStorage.clear();
                window.location.href = "/";
            }
        })
    }
  }
  
  export const setMassEventList = (massEventList) => {
    return{
        type : SET_MASSEVENT_LIST,
        massEventList : massEventList
    }
  }
  
  /************************************************************************************************************* */ 
  export const handleTextField = (textField, event) => {
        return (dispatch) => {
            return dispatch(setmassEventForm(textField, event));
        }
    }
  
  export const setmassEventForm = (textField, event) => {
    return {
      type: SET_MASSEVENT_TEXT_FIELD,
      textField: textField,
      data: event.target.value
    };
  };
  
  /**************************************************************************************** */
  
  export const onSubmit = (massEventForm, props) => {
    return (dispatch) => {
        let showLoaderStatus = false;
        let fieldValidationStatus = true;
        Object.keys(massEventForm).map((textField) => {
            if(massEventForm[textField].value  == ''){
                fieldValidationStatus = false;
            }
        })
        dispatch(setSubmittedData(showLoaderStatus)); 
        if(fieldValidationStatus){
            showLoaderStatus = true;
            let addMassEventReq = {};
            addMassEventReq["description"] = massEventForm['mass_events'].value;
            addMassEventReq["current"] = true;
            dispatch(setSubmittedData(showLoaderStatus));
            return axios.post('/sg/massevents', addMassEventReq)
            .then(response => {
                    showLoaderStatus = false;
                    toast.success("Mass Event Created Successfully", {
                                    containerId: 'toastMsg',
                                    onClose :  () => props.history.push('/sgadminportal/ausmassevents')
                                    }
                                );
                    dispatch(setSubmittedData(showLoaderStatus)); 
            })
            .catch(error => {
                if(error.response.status == 403){
                    showLoaderStatus = false;
                    localStorage.clear();
                    // toast.error("Session Expired ! Please login again", {containerId: 'toastMsg'});
                    window.location.href = "/";
                }else{
                    showLoaderStatus = false;
                    toast.error(error.response.data.message, {containerId: 'toastMsg'});
                    dispatch(setSubmittedData(showLoaderStatus)); 
                }
            })
        }
  
    }
  
  }
  
  export const setSubmittedData = (showLoaderStatus) => {
    return {
        type: SUBMIT_MASSEVENT_INFO,
        showLoaderStatus : showLoaderStatus
    }
  }
  
  /************************************************************************************************** */

  export const editMassEvent = (massEventInfo, massEventInformations) => {
    let reqURL = '/sg/massevents/' + massEventInfo.masseventsid;
    let updateReq = {};
    updateReq['description'] = massEventInfo.description;
    updateReq['current'] = massEventInfo.current=="true"?true:false;
    return (dispatch) => {
        return axios.put(reqURL, updateReq)
        .then(response => {
                const updateIndex = massEventInformations.findIndex(d => {
                    return d.masseventsid === massEventInfo.masseventsid;
                });
                massEventInformations[updateIndex].description = massEventInfo.description;
                massEventInformations[updateIndex].current = massEventInfo.current;
                toast.success("Mass Event Updated Successfully", {containerId: 'toastMsg'});
                dispatch(updateInfo(massEventInformations));
        })
        .catch(error => {
             if(error.response.status == 403){
                localStorage.clear();
                toast.error(
                            "Session Expired ! Please login again", 
                              {
                                containerId: 'toastMsg',
                                onclose : () => window.location.href = "/"
                              }
                            );
             }else{
                toast.error(error.response.data.message, {containerId: 'toastMsg'});
             }
  
             dispatch(updateInfo(massEventInformations));
        })
    }
  }
  export const updateInfo = (massEventInformations) => {
    return{
        type : UPDATE_MASSEVENT_INFO,
        massEventInformations : massEventInformations
    }
  }