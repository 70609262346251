import React, { Component } from 'react';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import './Signin.css';


import {
    changeUserPassword
} from "../../actions/ChangePassword";
class FirstTimeUser extends Component {
  
    state = {
        formIsValid: false,
        firstTimeChangePasswordForm: {
            currentPassword: {
                name: 'Current Password',
                type: 'password',
                icon: 'now-ui-icons text_caps-small',
                value: ''
            },
            newPassword : {
                name: 'New Password',
                type: 'password',
                icon: 'now-ui-icons text_caps-small',
                value: ''
            },
            confirmPassword : {
                name: 'Confirm Password',
                type: 'password',
                icon: 'now-ui-icons text_caps-small',
                value: ''
            },
        }
    }
  
    inputHandlerChangePassword(inputField, event) {
        this.state.firstTimeChangePasswordForm[inputField].value = event.target.value;
        
        this.setState({
            firstTimeChangePasswordForm: this.state.firstTimeChangePasswordForm
        })
    }
  
  
    render() {

        const { changepasswordLoaderStatus } = this.props.changePasswordState;

        return (
            <form className="d-flex align-items-center justify-content-center">
                <div className="card-body">
                    {
                        Object.keys(this.state.firstTimeChangePasswordForm).map(inputField => {
                            return(
                                <div className="input-group no-border input-lg">
                                    <input 
                                        type={this.state.firstTimeChangePasswordForm[inputField].type} 
                                        className="form-control" 
                                        placeholder={this.state.firstTimeChangePasswordForm[inputField].name}
                                        value = {this.state.firstTimeChangePasswordForm[inputField].value}
                                        onChange = {(event) => this.inputHandlerChangePassword(inputField, event)}
                                    />
                                </div>
                            )
                        })
                    }
                    {
                        changepasswordLoaderStatus ? 
                        <CircularProgress style = {{color : "#69bce6"}} />  :
                        <div className="text-center">
                            <button
                                type = "button"
                                onClick = {() => this.props.submitChangePassword(this.state.firstTimeChangePasswordForm)}
                                className="btn btn-info btn-round btn-lg btn-block btn-login">
                                Change Password
                            </button>
                        </div>
                    }
                </div>
            </form>
        );
  }
}

const mapStateToProps = state => {
    return {
        changePasswordState : state.changePassword
    }
}

const mapDispatchToProps = dispatch => {
    return{
        submitChangePassword : (inputForm) => {
            const changePwDetails = {
                current_password: inputForm.currentPassword,
                new_password: inputForm.newPassword,
                confirm_password: inputForm.confirmPassword,
            };
            return dispatch(changeUserPassword(changePwDetails, true));
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FirstTimeUser);
