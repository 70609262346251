import {
  SET_ENTRY_RULE_TEXT_FIELD,
  // SUBMIT_ENTRY_RULE_TEXT_FIELD,
  SET_ENTRYTYPES_LIST,
  SUBMIT_ENTRYTYPE_INFO,
  UPDATE_ENTRYTYPE_INFO,
  SET_DEFAULT_VALUE_TO_ENTRY_TYPE_COMPONENT
} from "./ActionType";

import axios from '../axios';
import { toast } from 'react-toastify';

export const initializeEntryTypesComponent = () => {
  return (dispatch) => {
    return dispatch(setDefaultValueToEntryTypeComponent())
  }
}
export const setDefaultValueToEntryTypeComponent = () => {
  return{
    type : SET_DEFAULT_VALUE_TO_ENTRY_TYPE_COMPONENT
  }
}
/************************************************************************/
export const getEntryTypeList = () => {
  let entrytype = {};
  let entryTypeList = [];
  return (dispatch) => {
      return axios.get('/sg/entrytypes')
      .then(response => {
              for(let i = 0; i < response.data.data.length; i++){
                entrytype = {};
                entrytype['entrytypeid'] = response.data.data[i].entrytypeid;
                entrytype['description'] = response.data.data[i].description;
                entrytype['current'] = response.data.data[i].current;
                entryTypeList.push(entrytype);
              }
              dispatch(setEntryTypeList(entryTypeList));
      })
      .catch(error => {
          if(error.response.status == 403){
              localStorage.clear();
              window.location.href = "/";
          }
      })
  }
}

export const setEntryTypeList = (entryTypeList) => {
  return{
      type : SET_ENTRYTYPES_LIST,
      entryTypeList : entryTypeList
  }
}

/************************************************************************************************************* */ 
export const handleEntryRuleTextFields = (textField, event) => {
      return (dispatch) => {
          return dispatch(setentryTypesForm(textField, event));
      }
  }

export const setentryTypesForm = (textField, event) => {
  return {
    type: SET_ENTRY_RULE_TEXT_FIELD,
    textField: textField,
    data: event.target.value
  };
};

/**************************************************************************************** */

export const onSubmit = (entryTypesForm, props) => {
  return (dispatch) => {
      let showLoaderStatus = false;
      let fieldValidationStatus = true;
      Object.keys(entryTypesForm).map((textField) => {
          if(entryTypesForm[textField].value  == ''){
              fieldValidationStatus = false;
          }
      })
      dispatch(setSubmittedData(showLoaderStatus));
      if(fieldValidationStatus){
        showLoaderStatus = true;
          dispatch(setSubmittedData(showLoaderStatus));
          let addEntryTypeReq = {};
          addEntryTypeReq["description"] = entryTypesForm['entry_types'].value;
          addEntryTypeReq["current"] = true;
          return axios.post('/sg/entrytypes', addEntryTypeReq)
          .then(response => {
                  showLoaderStatus = false;
                  toast.success("EntryType Created Successfully", {
                                    containerId: 'toastMsg',
                                    onClose :  () => props.history.push('/sgadminportal/entrytypes')
                                    }
                                );
                  dispatch(setSubmittedData(showLoaderStatus));
          })
          .catch(error => {
              if(error.response.status == 403){
                  showLoaderStatus = false;
                  localStorage.clear();
                  // toast.error("Session Expired ! Please login again", {containerId: 'toastMsg'});
                  window.location.href = "/";
              }else{
                  showLoaderStatus = false;
                  toast.error(error.response.data.message, {containerId: 'toastMsg'});
                  dispatch(setSubmittedData(showLoaderStatus));
              }
          })
      }

  }

}

export const setSubmittedData = (showLoaderStatus) => {
  return {
      type: SUBMIT_ENTRYTYPE_INFO,
      showLoaderStatus : showLoaderStatus
  }
}

/************************************************************************************************** */
export const editEntryType = (entryTypeInfo, entryTypeInformations) => {
  let reqURL = '/sg/entrytypes/' + entryTypeInfo.entrytypeid;
  let updateEntryTypeReq = {};
  updateEntryTypeReq['description'] = entryTypeInfo.description;
  updateEntryTypeReq['current'] = entryTypeInfo.current=="true"?true:false;
  return (dispatch) => {
      return axios.put(reqURL, updateEntryTypeReq)
      .then(response => {
              const updatedEntryTypeIndex = entryTypeInformations.findIndex(d => {
                  return d.entrytypeid === entryTypeInfo.entrytypeid;
              });
              entryTypeInformations[updatedEntryTypeIndex].description = entryTypeInfo.description;
              entryTypeInformations[updatedEntryTypeIndex].current = entryTypeInfo.current;
              toast.success("Entry Type Updated Successfully", {containerId: 'toastMsg'});
              dispatch(updateEntryTypeInfo(entryTypeInformations));
      })
      .catch(error => {
           if(error.response.status == 403){
              localStorage.clear();
              toast.error(
                          "Session Expired ! Please login again", 
                            {
                              containerId: 'toastMsg',
                              onclose : () => window.location.href = "/"
                            }
                          );
           }else{
              toast.error(error.response.data.message, {containerId: 'toastMsg'});
           }

           dispatch(updateEntryTypeInfo(entryTypeInformations));
      })
  }
}
export const updateEntryTypeInfo = (entryTypeInformations) => {
  return{
      type : UPDATE_ENTRYTYPE_INFO,
      entryTypeInformations : entryTypeInformations
  }
}