import React, { Component } from 'react';
import { connect } from 'react-redux';
import CardContent from '@material-ui/core/CardContent';
import FormGroup from '@material-ui/core/FormGroup';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CircularProgress from '@material-ui/core/CircularProgress';


import Input from "../../../components/UI/Input/Input";
import{
    businessDetailsInputFieldChange,
    getBusinessDetail
} from '../../../actions/Business';

class BusinessDetails extends Component{
    componentDidMount(){
        this.props.getBusinessDetail();
    }
    render(){
        let{
            businessDetailsForm,
            businessDetailsLoaderStatus
        } = this.props.businessState;
        const formElementArrayBusinesDetails = [];
        for(let key in businessDetailsForm){
            formElementArrayBusinesDetails.push({
                id : key,
                config : businessDetailsForm[key]
            })
        }
        let formBusinesDetails = (
            <FormGroup row>
                <Grid container spacing={24}>
                    {
                        formElementArrayBusinesDetails.map(formElement => (
                            <Grid item xs={12} sm = {6} key = {formElement.id}>
                                <Input 
                                    key = {formElement.id}
                                    name = {formElement.config.elementConfig.label}
                                    elementType={formElement.config.elementType}
                                    elementConfig={formElement.config.elementConfig}
                                    value={formElement.config.value}
                                    menuItems = {formElement.config.menuItems}
                                    error = {formElement.config.errorStatus}
                                    helperText = {formElement.config.errorMessage}
                                    changed={(event) => this.props.businessDetailsInputFieldChange(formElement.id, event )}
                                    touched = {(event) => this.props.businessDetailsInputFieldChange(formElement.id, event )}
                                    maxLength={formElement.config.maxLength}
                                    disabledStatus={formElement.config.disabledStatus}
                                />
                            </Grid>
                        )
                    )
                    }
                </Grid>
            </FormGroup>
        )
        return(
            <div style = {{height : "500px", overflow : "auto"}}>
                <Card className = "pl-3 pr-3 pt-3">
                    <CardContent>
                        {
                            formBusinesDetails
                        }
                    </CardContent>
                </Card>
            </div>
        )

    }
}

const mapStateToProps = state => {
    return{
        businessState : state.business
    }
}
const mapDispatchToProps = dispatch => {
    return{
        businessDetailsInputFieldChange : (formElementId, event) => {
            return dispatch(businessDetailsInputFieldChange(formElementId, event))
        },
        getBusinessDetail : () => {
            return dispatch(getBusinessDetail())
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BusinessDetails);