import { 
    SUBMIT_BFM_INFO,
    SET_BFM_FILE_SELECTED,
    SET_DEFAULT_STATE_BFM
  } from "./ActionType";

  import axios from '../axios';
  import { toast } from 'react-toastify';
  import { convertToJSON } from "./Common";
  //import {ExcelRenderer, OutTable} from 'react-excel-renderer';

  /**************************************************************************************** */
  export const initializeState = () => {
      return (dispatch) => {
          return dispatch(setDefaultState())
      }
  }
  export const setDefaultState = () => {
      return{
          type : SET_DEFAULT_STATE_BFM
      }
  }
  export const inputHandler = (formElementId, event, bfmJSON) => {
    return (dispatch) => {
        let formData = event.target.value.replace(/^.*\\/, "");
        if (formElementId === 'upload_bfm'){
            if(event.target.files != null){
                convertToJSON(event.target.files[0]).then(data => {
                    dispatch(setInputHandler(formElementId, formData, data)); 
                })
                .catch(error => {
                })
            }
        }
        dispatch(setInputHandler(formElementId, formData, bfmJSON)); 
        
    }
}
export const setInputHandler = (formElementId, formData, bfmJSON) => {
    return{
        type : SET_BFM_FILE_SELECTED,
        formElementId : formElementId,
        data : formData,
        bfmJSON : bfmJSON
    }
}

export const onBlurTextfield=(inputFieldId,event)=>{
  return (dispatch) => {
      return dispatch(setInputHandler(inputFieldId, event));
  }
}

  /************************************************************************************************** */
  export const onSubmitBFM = (uploadBFMForm, bfmJSON) => {
    return (dispatch) => {
        let bfmLoaderStatus = false;
        let uploadBFMFormVAlidationStatus = true;
        Object.keys(uploadBFMForm).map((formField) => {
          if(uploadBFMForm[formField].value === ''){
              uploadBFMFormVAlidationStatus = false;
          }
        })
        dispatch(submitBFMData(bfmLoaderStatus));
        if(uploadBFMFormVAlidationStatus){
          bfmLoaderStatus = true;
          let reqURL;
          let driverType = uploadBFMForm['driver_type'].value;
          let vehicleType = uploadBFMForm['vehicle_category'].value;
          reqURL = "/sg/bfm/drivertype/"+driverType+"/vehicletype/"+vehicleType;
          dispatch(submitBFMData(bfmLoaderStatus));
          return axios.post(reqURL, bfmJSON)
          .then(response => {
            bfmLoaderStatus = false;
              toast.success("BFM Document Uploaded Successfully", {
                  containerId: 'toastMsg',
              });
              dispatch(submitBFMData(bfmLoaderStatus));
          })
          .catch(error => {
            bfmLoaderStatus = false;
            toast.error(error.response.data.message, {
                containerId: 'toastMsg',
            });
            dispatch(submitBFMData(bfmLoaderStatus));
          })
      }
    }
}
export const submitBFMData = (bfmLoaderStatus) => {
    return {
        type : SUBMIT_BFM_INFO,
        bfmLoaderStatus : bfmLoaderStatus
    }
}
