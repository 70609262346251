import React, { Component } from "react";
import Logo from "../images/StepGlobal-250x65.jpg";
import "./layout.css";

class Help extends Component {
    render() {
        return (
            <div>
                <div className="container-fluid" style={{ margin: "0", padding: "0" }}>
                    <div>
                        <nav className="navbar fixed-top navbar-default " role="navigation">
                            <img
                                src={Logo}
                                alt={"logo"}
                                className="rounded"
                                className="mx-auto d-block"
                            />
                        </nav>
                    </div>
                    <div
                        className="pl-5 pr-5 ml-5 mr-5 mt-5 pt-5"
                        style={{ backgroundColor: "#ffffff" }}
                    >
                        <div>
                            <h2 id="header1">Help</h2>
                        </div>
                        <div>
                            <p>Step Global is a developer of “applications specific” systems based on GNSS, RFID and wireless communications technologies, which is just a fancy way of saying we demistify technology, enabling you to improve your business!
                               For over 20 years we’ve been supporting our customers through providing engineering know-how. Whether it be application knowledge and systems design, to off-the-shelf products or a full turn-key design of bespoke systems (hardware and software), our focus has been to either build, create or integrate to meet our customer’s needs.
                               Our customers range from large multi-national companies, through to top tiered systems integrators and our strength is in taking on difficult non-standard requirements, whilst being conscious of cost, effectiveness and performance.
                            </p>
                        </div>
                        <div>
                            <h2 id="header1">Our History</h2>
                        </div>
                        <div>
                            <p>
                                In 2009 David Lloyd acquired Step Global and with his extensive experience quickly added R&D, Systems Integration, Logistics Services capabilities as well as expanding the product portfolio to include Telematics, Sub-metre Positioning, Wireless Communications, RFID and Personal Safety solutions.
                                Our growth is focused around our engineering solutions approach. We have extensive applications know-how from complex RFID requirements through to developing autonomous vehicle systems in agriculture. We will design and implement a system solution that provides the best value for our customers.
                            </p><br />
                            <h2 id="header1">Contact us</h2>
                            <p>
                                Step Global is a developer of “applications specific” systems based on GNSS, RFID and wireless communications technologies, which is just a fancy way of saying we demistify technology, enabling you to improve your business! For over 20 years we’ve been supporting our customers through providing engineering know-how. Whether it be application knowledge and systems design, to off-the-shelf products or a full turn-key design of bespoke systems (hardware and software), our focus has been to either build, create or integrate to meet our customer’s needs. Our customers range from large multi-national companies, through to top tiered systems integrators and our strength is in taking on difficult non-standard requirements, whilst being conscious of cost, effectiveness and performance.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Help;
