import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import FormGroup from "@material-ui/core/FormGroup";
import Button from "@material-ui/core/Button";

import { connect } from "react-redux";

import {
  handlemassCertificateTextFields,
  onSubmit,
  initializemassCertificateComponent,
  handleUploadInputChange,
} from "../../actions/MassCertificate";
import Input from "../../components/UI/Input/Input";
import CircularProgress from '@material-ui/core/CircularProgress';

class AddMassCertificate extends Component {
  componentDidMount() {
    this.props.initializemassCertificateComponent()
  }
  render() {
    let {
      massCertificateForm,
      showLoaderStatus,
      base64_string

    } = this.props.massCertificate;
    const formElementArray = [];
    for (let key in massCertificateForm) {
      formElementArray.push({
        id: key,
        config: massCertificateForm[key]
      })
    }
    let form = (
      <FormGroup row>
        <Grid container spacing={24}>
          {
            formElementArray.map(formElement => {
              if (formElement.id === 'certificate_image') {
                return (
                  <Grid item xs={12} sm={6} key={formElement.id}>
                    <Input
                      key={formElement.id}
                      name={formElement.config.elementConfig.label}
                      elementType={formElement.config.elementType}
                      elementConfig={formElement.config.elementConfig}
                      value={formElement.config.value}
                      error={formElement.config.errorStatus}
                      helperText={formElement.config.errorMessage}
                      changed={(event) => this.props.handleUploadInputChange(formElement.id, event)}
                      maxLength={formElement.config.maxLength}
                    //touched = {(event) => this.props.handleTextFieldChange(formElement.id, event )}
                    />
                  </Grid>
                )
              }
              else {
                return (
                  <Grid item xs={12} sm={6} key={formElement.id}>
                    <Input
                      key={formElement.id}
                      elementType={formElement.config.elementType}
                      elementConfig={formElement.config.elementConfig}
                      value={formElement.config.value}
                      error={formElement.config.errorStatus}
                      helperText={formElement.config.errorMessage}
                      changed={(event) => this.props.handlemassCertificateTextFields(formElement.id, event)}
                      touched={(event) => this.props.handlemassCertificateTextFields(formElement.id, event)}
                      maxLength={formElement.config.maxLength}
                    />
                  </Grid>
                )
              }
            })
          }
        </Grid>
      </FormGroup>

    )
    return (
      <Grid container spacing={24}>
        <Grid item xs={1} sm={2} />
        <Grid item xs={12} sm={8}>
          <Card className="pl-3 pr-3 pt-3">
            <CardHeader title="Add Mass Certificate" />
            <CardContent>
              <FormGroup row className="pl-3 pr-1">
                <Grid container spacing={24}>
                  <Grid item xs={10} sm={10}>
                    {form}
                  </Grid>
                </Grid>
                <Grid container spacing={24}>
                  <Grid item xs={4} sm={4}>
                    {
                      showLoaderStatus ? <CircularProgress color="primary" /> :
                        <Button
                          variant="contained"
                          color="primary"
                          className="mt-4"
                          onClick={() => { this.props.onSubmit(this.props.massCertificate, base64_string, this.props) }}>
                          Submit
                          </Button>
                    }
                  </Grid>
                </Grid>
              </FormGroup>
            </CardContent>
            <CardActions />
          </Card>
        </Grid>
        <Grid item xs={1} sm={2} />
      </Grid>
    );
  }
}

const mapStateToProps = state => {
  return {
    massCertificate: state.massCertificate
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onSubmit: (massCertificateInfo, base64_string, props) => {
      return dispatch(onSubmit(massCertificateInfo, base64_string, props))
    },
    handlemassCertificateTextFields: (textField, event) => {
      return dispatch(handlemassCertificateTextFields(textField, event));
    },
    initializemassCertificateComponent: () => {
      return dispatch(initializemassCertificateComponent())
    },
    handleUploadInputChange: (textField, event) => {
      return dispatch(handleUploadInputChange(textField, event))
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AddMassCertificate);
