import React, { Component } from 'react';
import Logo from "../images/StepGlobal-250x65.jpg";

class PrivacyPolicy extends Component {
    render() {
        return (
            <div className="container-fluid" style={{ margin: "0", padding: "0" }}>
                <div>
                    <nav className="navbar fixed-top navbar-default " role="navigation">
                        <img src={Logo} alt={"logo"} className="mx-auto d-block"></img>
                    </nav>
                </div>
                <div className="pl-5 pr-5 ml-5 mr-5 mt-5 pt-5" style={{ backgroundColor: "#ffffff", }}>
                    <div>
                        <h2 id="header1">Privacy Notice</h2>
                    </div>
                    <div>
                        <p>This Privacy Notice describes how we collect and use your personal information in relation to Step Global websites, applications, products, services, events, and experiences that reference this Privacy Notice (together, “Step Global Offerings”).<br /></p>
                        <p>This Privacy Notice does not apply to the “content” processed, stored, or hosted by our customers using Step Global Offerings in connection with a Step Global account. See the agreement governing your access to your Step Global account for more information about how we handle content and how our customers can control their content through Step Global Offerings. This Privacy Notice also does not apply to any products, services, websites, or content that are offered by third parties or have their own privacy notice.<br /></p>
                    </div>
                    <h4>Personal Information We Collect<br /></h4>
                    <p>We collect your personal information in the course of providing Step Global Offerings to you.<br /></p>
                    <p>Here are the types of information we gather:<br /></p>
                    <div>
                        <ul>
                            <li>Information You Give Us: We collect any information you provide in relation to Step Global Offerings. </li>
                            <li>Automatic Information: We automatically collect certain types of information when you interact with Step Global Offerings.</li>
                            <li>Information from Other Sources: We might collect information about you from other sources, including service providers, partners, and publicly available sources.</li>
                        </ul>
                    </div>
                    <h4>How We Use Personal Information <br /></h4>
                    <p>We use your personal information to operate, provide, and improve Step Global Offerings.<br /></p>
                    <p>purposes for using personal information include:<br /></p>
                    <div>
                        <ul>
                            <li>Provide Step Global Offerings: We use your personal information to provide and deliver Step Global Offerings and process transactions related to Step Global Offerings, including registrations, subscriptions, purchases, and payments.</li>
                            <li>Measure, Support, and Improve Step Global Offerings: We use your personal information to measure use of, analyse performance of, fix errors in, provide support for, improve, and develop Step Global Offerings.</li>
                            <li>Recommendations and Personalization: We use your personal information to recommend Step Global Offerings that might be of interest to you, identify your preferences, and personalize your experience with Step Global Offerings</li>
                            <li>Comply with Legal Obligations: In certain cases, we have a legal obligation to collect, use, or retain your personal information. For example, we collect bank account information from Step Global Marketplace sellers for identity verification.</li>
                            <li>Communicate with You: We use your personal information to communicate with you in relation to Step Global Offerings via different channels (e.g., by phone, email, chat) and to respond to your requests.</li>
                            <li>Marketing: We use your personal information to market and promote Step Global Offerings. We might display interest-based ads for Step Global Offerings.</li>
                            <li>Fraud and Abuse Prevention and Credit Risks: We use your personal information to prevent and detect fraud and abuse in order to protect the security of our customers, Step Global, and others. We may also use scoring methods to assess and manage credit risks</li>
                            <li>Purposes for Which We Seek Your Consent: We may also ask for your consent to use your personal information for a specific purpose that we communicate to you.</li>
                        </ul>
                    </div>
                    <h4>Cookies<br /></h4>
                    <p>To enable our systems to recognize your browser or device and to provide Step Global Offerings to you, we use cookies.<br /></p>
                    <h4>How We Share Personal Information<br /></h4>
                    <p>Information about our customers is an important part of our business and we are not in the business of selling our customers’ personal information to others. We share personal information only as described below and with Step Global and the subsidiaries that Step Global controls that are either subject to this Privacy Notice or follow practices at least as protective as those described in this Privacy Notice.<br /></p>
                    <div>
                        <ul>
                            <li>Transactions Involving Third Parties: We make available to your services, software, and content provided by third parties for use on or through Step Global Offerings. You can tell when a third party is involved in your transactions, and we share information related to those transactions with that third party. For example, you can order services, software, and content from sellers using the Step Global Marketplace and we provide those sellers information to facilitate your subscription, purchases, or support.</li>
                            <li>Third-Party Service Providers: We employ other companies and individuals to perform functions on our behalf. Examples include delivering Step Global hardware, sending communications, processing payments, assessing credit and compliance risks, analysing data, providing marketing and sales assistance (including advertising and event management), conducting customer relationship management, and providing training. These third-party service providers have access to personal information needed to perform their functions but may not use it for other purposes. Further, they must process that information in accordance with this Privacy Notice and as permitted by applicable data protection law.</li>
                            <li>Business Transfers: As we continue to develop our business, we might sell or buy businesses or services. In such transactions, personal information generally is one of the transferred business assets but remains subject to the promises made in any pre-existing Privacy Notice (unless, of course, the individual consents otherwise). Also, in the unlikely event that Step Global or substantially all of its assets are acquired, your information will of course be one of the transferred assets.</li>
                            <li>Protection of Us and Others: We release account and other personal information when we believe release is appropriate to comply with the law, enforce or apply our terms and other agreements, or protect the rights, property, or security of Step Global, our customers, or others. This includes exchanging information with other companies and organizations for fraud prevention and detection and credit risk reduction.</li>
                            <li>At Your Option: Other than as set out above, you will receive notice when personal information about you might be shared with third parties, and you will have an opportunity to choose not to share the information.</li>
                        </ul>
                    </div>
                    <h4>Location of Personal Information<br /></h4>
                    <p>Step Global is located in Australia, and our affiliated companies are located throughout the world. Depending on the scope of your interactions with Step Global Offerings, your personal information may be stored in or accessed from multiple countries, including Australia. Whenever we transfer personal information to other jurisdictions, we will ensure that the information is transferred in accordance with this Privacy Notice and as permitted by applicable data protection laws.<br /></p>
                    <h4>How We Secure Information <br /></h4>
                    <p>At Step Global, security is our highest priority. We design our systems with your security and privacy in mind.</p>
                    <div>
                        <ul>
                            <li>We maintain a wide variety of compliance programs that validate our security controls.</li>
                            <li>We protect the security of your information during transmission to or from step Global websites, applications, products, or services by using encryption protocols and software.</li>
                            <li>We follow the Payment Card Industry Data Security Standard (PCI DSS) when handling credit card data.</li>
                            <li>We maintain physical, electronic, and procedural safeguards in connection with the collection, storage, and disclosure of personal information. Our security procedures mean that we may request proof of identity before we disclose personal information to you.</li>
                        </ul>
                    </div>
                    <h4>Internet Advertising and Third Parties <br /></h4>
                    <p>Step Global Offerings may include third-party advertising and links to other websites and applications. Third party advertising partners may collect information about you when you interact with their content, advertising, or services.</p>
                    <h4>Access and Choice</h4>
                    <p>You can view, update, and delete certain information about your account and your interactions with Step Global Offerings. Click here for a list of examples of information that you can access. If you cannot access or update your information yourself, you can always contact us for assistance.</p>
                    <p>You have choices about the collection and use of your personal information. Many Step Global Offerings include settings that provide you with options as to how your information is being used. You can choose not to provide certain information, but then you might not be able to take advantage of certain Step Global Offerings</p>
                    <div>
                        <ul>
                            <li>Account Information: If you want to add, update, or delete information related to your account, please go to the Step Global solution where the data is stored. When you update or delete any information, we usually keep a copy of the prior version for our records.</li>
                            <li>Communications: If you do not want to receive promotional messages from us, please unsubscribe or adjust your communication preferences in the Step Global Solution. If you do not want to receive in-app notifications from us, please adjust your notification settings in the app or your device</li>
                            <li>Advertising: If you don’t want to see interest-based ads, please adjust your advertising preferences within the Step Global Solution.</li>
                            <li>Browser and Devices: The Help feature on most browsers and devices will tell you how to prevent your browser or device from accepting new cookies, how to have the browser notify you when you receive a new cookie, or how to disable cookies altogether.</li>
                            <li>Sellers and Step Global Partners: Sellers and Step Global Partner Network members can add, update, or delete information in the Step Global Market Place.</li>
                        </ul>
                    </div>
                    <h4>Children’s Personal Information</h4>
                    <p>We don’t provide Step Global Offerings for purchase by children. If you’re under 18, you may use Step Global Offerings only with the involvement of a parent or guardian.</p>
                    <h4>Retention of Personal Information</h4>
                    <p>We keep your personal information to enable your continued use of Step Global Offerings, for as long as it is required in order to fulfil the relevant purposes described in this Privacy Notice, as may be required by law (including for tax and accounting purposes), or as otherwise communicated to you. How long we retain specific personal information varies depending on the purpose for its use, and we will delete your personal information in accordance with applicable law.</p>
                    <h4>Contacts, Notices, and Revisions</h4>
                    <p>If you have any concern about privacy at Step Global or want to contact one of our data controllers, please contact us at <a href="info@stepglobal.com">info@stepglobal.com</a> with a thorough description, and we will try to resolve it. You may also contact us at the addresses below:</p>
                    <div>
                        <ul>
                            <li>For any prospective or current customers of Step Global., our mailing address is: <i><b>Step Global, 7/444 Warrigal Rd, Heatherton, Victoria 3202, Australia, ATTN</b></i>: Step Global Legal<br /><br />
                                If you interact with Step Global Offerings on behalf of or through your organization, then your personal information may also be subject to your organization’s privacy practices, and you should direct privacy inquiries to your organization.<br /><br />
                                Our business changes constantly, and our Privacy Notice may also change. You should check our website frequently to see recent changes. You can see the date on which the latest version of this Privacy Notice was posted. Unless stated otherwise, our current Privacy Notice applies to all personal information we have about you and your account. We stand behind the promises we make, however, and will never materially change our policies and practices to make them less protective of personal information collected in the past without informing affected customers and giving them a choice.
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        )
    }
}


export default PrivacyPolicy