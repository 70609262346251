import {
    POST_HANDLE_OPEN_EDIT_CHECKLIST_ITEM_DIALOG,
    POST_HANDLE_CHECK_LIST_ITEM_DIALOG_CLOSE_STATUS,
    POST_HANDLE_ADD_CHECKLIST_ITEM_TEXTFIELD_CHANGE,
    POST_INITIALIZE_ADD_CHECKLIST_ITEM_FORM,
    POST_HANDLE_ADD_TEMPLATE_TEXTFIELDS,
    POST_GET_VEHICLES,
    POST_GET_TEMPLATES,
    POST_UPDATE_TEMPLATE,
    POST_ON_SUBMIT_TEMPLATE_CREATE,
    POST_GET_CHECK_ITEMS,
    POST_ON_SUBMIT_ADD_CHECKLIST_ITEM,
    POST_ON_UPDATE_CHECK_ITEM,
    POST_INITIALIZE_ADD_TEMPLATE_FORM,
    POST_GET_EXAMPLE_IMAGE,
    POST_GET_TRIGGERS,
    POST_HANDLE_TRIGGER_DROPDOWN,
    POST_HANDLE_CREATE_CHECKLIST_TEXFIELD,
    POST_GET_TEMPLATES_FOR_EXPANSION_PANEL,
    POST_HANDLE_TEMPLATE_EXPANSION_COLLAPSE,
    POST_MAP_TEMPLATE_TO_CHECKLIST,
    POST_HANDLE_CHECKITEM_CHECKBOX,
    POST_INITIALIZE_CREATE_CHECKLIST,
    POST_ON_SUBMIT_CREATE_CHECKLIST,
    POST_GET_VEHICLE_BY_ID,
    POST_HANDLE_ASSIGNMENTLIST_EXPANSION_COLLAPSE,
    POST_MAP_CHECKLIST_TO_CHECKITEMS,
    POST_INITIALIZE_LIST_ASSIGNED_CHECKLIST,
    POST_ON_UPDATE_CHECKLIST,
    POST_ACTIVE_DELETE_CHECK_ITEM_OPTION,
    POST_DEACTIVE_DELETE_CHECKITEM_OPTION,
    POST_HANDLE_CHECKITEM_CHECKBOX_FOR_DELETE,
    POST_DELETE_CHECKITEMS,
    POST_GET_NOTIFICATION_DETAILS,
    POST_HANDLE_NOTIFICATION_SETTING_FORMFIELD,
    POST_ON_SUBMIT_NOTIFICATION_SETTING
} from '../actions/ActionType';
import {
    isObjectEmpty,
    findFromArray
} from "../utils/Helper";
const initialState = {
    notificationSettingsForm : {
        fast_prestart : {
            elementType : 'input',
            elementConfig : {
                type : 'text',
                label : 'Fast Prestart'
            },
            value : '',
            errorStatus : false,
            errorMessage : "Enter Fast Prestart",
            maxLength : 2,
            disabledStatus : false
        },
        failed_check : {
            elementType : 'select',
            elementConfig : {
                type : 'text',
                label : 'Failed Check'
            },
            value : '',
            disabledStatus : false,
            errorStatus : false,
            errorMessage : "Select Failed Check",
            menuItems : {
                'select_failed_check' : {
                    name : "Select Failed Check",
                    value : ''
                },
                'yes' : {
                    name : "Yes",
                    value : true
                },
                'no' : {
                    name : "No",
                    value : false
                }
            }
        }
    },
    createcheckListTextFields : {
        trigger_name : {
            elementType : 'input',
            elementConfig : {
                type : 'text',
                label : 'Trigger Name'
            },
            value : '',
            errorStatus : false,
            errorMessage : "Enter Trigger Name",
            maxLength : 60,
            disabledStatus : true
        },
        checklist_name : {
            elementType : 'input',
            elementConfig : {
                type : 'text',
                label : 'Checklist Name'
            },
            value : '',
            errorStatus : false,
            errorMessage : "Enter Checklist Name",
            maxLength : 60
        }
    },
    addTemplateForm :{
        template_name : {
            elementType : 'input',
            elementConfig : {
                type : 'text',
                label : 'Template Name'
            },
            value : '',
            errorStatus : false,
            errorMessage : "Enter Template Name",
            maxLength : 60
        },
        template_description : {
            elementType : 'input',
            elementConfig : {
                type : 'text',
                label : 'Template Description'
            },
            value : '',
            errorStatus : false,
            errorMessage : "Enter Template Description",
            maxLength : 256
        }
    },
    addTemplateLoaderStatus : false,
    addCheckListItemForm : {
        checklist_item_name : {
            elementType : 'input',
            elementConfig : {
                type : 'text',
                label : 'Check Item Name'
            },
            value : '',
            disabledStatus : false,
            errorStatus : false,
            errorMessage : "Enter Check Item Name",
            maxLength:60
        },
        checklist_item_description : {
            elementType : 'input',
            elementConfig : {
                type : 'text',
                label : 'Description'
            },
            value : '',
            disabledStatus : false,
            errorStatus : false,
            errorMessage : "Enter Description",
            maxLength:100
        },
        checklist_item_image : {
            elementType : 'upload_image',
            elementConfig : {
                type : 'text',
                label : 'Image'
            },
            value : '',
            disabledStatus : false,
            errorStatus : false,
            errorMessage : "Upload Image",
            maxLength:100
        },
        checklist_item_estimatedtime : {
            elementType : 'input',
            elementConfig : {
                type : 'text',
                label : 'Estimated Time( In Min )'
            },
            value : '',
            disabledStatus : false,
            errorStatus : false,
            errorMessage : "Enter Estimated Time",
            maxLength:2
        },
        checklist_item_default_state : {
            elementType : 'select',
            elementConfig : {
                type : 'text',
                label : 'Default State'
            },
            value : '',
            disabledStatus : false,
            errorStatus : false,
            errorMessage : "Select Default State",
            menuItems : {
                'select_default_state' : {
                    name : "Select Default State",
                    value : ''
                },
                'true' : {
                    name : "Pass",
                    value : 'Pass'
                },
                'false' : {
                    name : "Fail",
                    value : 'Fail'
                },
                'neutral' : {
                    name : "Neutral",
                    value : 'Neutral'
                },
            }
        },
        checklist_item_comment_required : {
            elementType : 'select',
            elementConfig : {
                type : 'text',
                label : 'Comment Required'
            },
            value : '',
            disabledStatus : false,
            errorStatus : false,
            errorMessage : "Select Comment Required",
            menuItems : {
                'select_comment_required' : {
                    name : "Select Comment Required Status",
                    value : ''
                },
                'always' : {
                    name : "Always",
                    value : 'Always'
                },
                'failure' : {
                    name : "Failure",
                    value : 'Failure'
                },
                'never' : {
                    name : "Never",
                    value : 'Never'
                },
            }
        },
        checklist_item_image_required : {
            elementType : 'select',
            elementConfig : {
                type : 'text',
                label : 'Image Required Status'
            },
            value : '',
            disabledStatus : false,
            errorStatus : false,
            errorMessage : "Select Image Required Status",
            menuItems : {
                'select_image_required_status' : {
                    name : "Select Image Required Status",
                    value : ''
                },
                'always' : {
                    name : "Always",
                    value : 'Always'
                },
                'failure' : {
                    name : "Failure",
                    value : 'Failure'
                },
                'never' : {
                    name : "Never",
                    value : 'Never'
                },
            }
        },
        checklist_item_status : {
            elementType : 'select',
            elementConfig : {
                type : 'text',
                label : 'Status'
            },
            value : '',
            errorStatus : false,
            errorMessage : "Select Status",
            menuItems : {
                'select_status' : {
                    name : "Select Status",
                    value : ''
                },
                'active' : {
                    name : "Active",
                    value : 'active'
                },
                'inactive' : {
                    name : "Inactive",
                    value : 'inactive'
                }
            }
        }
    },
    triggerDropdown : {
        trigger_dropdown : {
            elementType : 'select',
            elementConfig : {
                type : 'text',
                label : 'Select Trigger'
            },
            value : '',
            errorStatus : false,
            errorMessage : "Select Trigger",
            menuItems : {
                'select_trigger' : {
                    name : "Select Trigger",
                    value : ''
                }
            }
        }
    },
    checkListItemInformation : [],
    templateColumn : [
        { 
            title: 'Template Name', 
            field: 'template_name' ,
            
        },
        { 
            title: 'Template Description', 
            field: 'template_description' 
        },
        { 
            title : 'Status', 
            field : 'status', 
            lookup: { 
                'active': 'Active', 
                'inactive': 'Inactive' 
            }
        }
    ],
   templateInformations : [],
   getTemplateLoaderStatus : false,
   vehicleListColumn : [
    {title : 'Vehicle Id', field : "vehicle_id"},
    {title : 'Vehicle Plate', field: 'vehicle_plate'},
    {title : 'State', field: 'vehicle_state'}
   ],
   vehicleInformations : [],
   editChecklistItemDialogStatus : false,
   templateExpansionPanelDetails : [],
   checkItemImageBase64 : '',
   checkitemSubmitLoaderStatus : false,
   listCheckItemLoaderStatus : false,
   editDialogOpenLoaderStatus  : false,
   checkitemUpdateLoaderStatus : false,
   activeCheckListItemForEditId : '',
   templateExpansionPanelExpandedStatus : '',
   selectedCreateCheckItemArray : [],
   getVehicleInformationLoaderStatus : false,
   getTemplatesForExpansionPanelLoaderStatus : false,
   onSubmitCreateChecklistLoaderStatus : false,
   checklistArray : [],
   checklistExpansionPanelExpandedStatus : '',
   getCheckListloaderStatus : false,
   vehicleInfoForAssignChecklist : {},
   activeDeleteCheckItemOptionStatus : false,
   checkItemArrayForDelete : [],
   deleteCheckitemLoaderStatus : false,
   getNotificationSettingLoaderStatus : false,
   onSubmitNotificationSettingLoaderStatus : false
}

//POST_HANDLE_OPEN_EDIT_CHECKLIST_ITEM_DIALOG
const postHandleOpenEditChecklistItemDialog = (state, action) => {
    state.editChecklistItemDialogStatus = true;
    state.editDialogOpenLoaderStatus = action.editDialogOpenLoaderStatus;
    state.activeCheckListItemForEditId = action.activeCheckListItemForEditId;
    if(!isObjectEmpty(action.checkItemDetails)){
        state.addCheckListItemForm['checklist_item_name'].value = action.checkItemDetails.content.check_item_name;
        state.addCheckListItemForm['checklist_item_description'].value = action.checkItemDetails.content.check_item_description;
        state.addCheckListItemForm['checklist_item_image'].value = "CheckItem Image";
        state.addCheckListItemForm['checklist_item_estimatedtime'].value = action.checkItemDetails.content.check_item_estimated_time;
        state.addCheckListItemForm['checklist_item_default_state'].value = action.checkItemDetails.content.check_item_default_state;
        state.addCheckListItemForm['checklist_item_comment_required'].value = action.checkItemDetails.content.check_item_comments_required;
        state.addCheckListItemForm['checklist_item_image_required'].value = action.checkItemDetails.content.check_item_image_required;
        if(action.checkItemDetails.content.check_status){
            state.addCheckListItemForm['checklist_item_status'].value = 'active';
            state.addCheckListItemForm['checklist_item_description'].disabledStatus = false;
            state.addCheckListItemForm['checklist_item_image'].disabledStatus = false;
            state.addCheckListItemForm['checklist_item_estimatedtime'].disabledStatus = false;
            state.addCheckListItemForm['checklist_item_default_state'].disabledStatus = false;
            state.addCheckListItemForm['checklist_item_comment_required'].disabledStatus = false;
            state.addCheckListItemForm['checklist_item_image_required'].disabledStatus = false;
        }else{
            state.addCheckListItemForm['checklist_item_status'].value = 'inactive';
            state.addCheckListItemForm['checklist_item_name'].disabledStatus = true;
            state.addCheckListItemForm['checklist_item_description'].disabledStatus = true;
            state.addCheckListItemForm['checklist_item_image'].disabledStatus = true;
            state.addCheckListItemForm['checklist_item_estimatedtime'].disabledStatus = true;
            state.addCheckListItemForm['checklist_item_default_state'].disabledStatus = true;
            state.addCheckListItemForm['checklist_item_comment_required'].disabledStatus = true;
            state.addCheckListItemForm['checklist_item_image_required'].disabledStatus = true;
        }
    }

    return state;
}

//POST_HANDLE_CHECK_LIST_ITEM_DIALOG_CLOSE_STATUS
const postHandleChecklistitemDialogCloseStatus = (state, action) => {
    state.editChecklistItemDialogStatus = false;
    return state;
}

//POST_HANDLE_ADD_CHECKLIST_ITEM_TEXTFIELD_CHANGE
const postHandleAddChecklistitemTextFieldChange = (state, action) => {
    if(action.fieldId === 'checklist_item_estimatedtime'){
        action.data = (action.data).replace(/[^0-9]/g, '');
    }
    state.addCheckListItemForm[action.fieldId].value = action.data;
    if(action.fieldId === 'checklist_item_status'){
        if(state.addCheckListItemForm['checklist_item_status'].value === 'inactive'){
            state.addCheckListItemForm['checklist_item_name'].disabledStatus = true;
            state.addCheckListItemForm['checklist_item_description'].disabledStatus = true;
            state.addCheckListItemForm['checklist_item_image'].disabledStatus = true;
            state.addCheckListItemForm['checklist_item_estimatedtime'].disabledStatus = true;
            state.addCheckListItemForm['checklist_item_default_state'].disabledStatus = true;
            state.addCheckListItemForm['checklist_item_comment_required'].disabledStatus = true;
            state.addCheckListItemForm['checklist_item_image_required'].disabledStatus = true;
        }else{
            state.addCheckListItemForm['checklist_item_name'].disabledStatus = false;
            state.addCheckListItemForm['checklist_item_description'].disabledStatus = false;
            state.addCheckListItemForm['checklist_item_image'].disabledStatus = false;
            state.addCheckListItemForm['checklist_item_estimatedtime'].disabledStatus = false;
            state.addCheckListItemForm['checklist_item_default_state'].disabledStatus = false;
            state.addCheckListItemForm['checklist_item_comment_required'].disabledStatus = false;
            state.addCheckListItemForm['checklist_item_image_required'].disabledStatus = false;
        }
    }
    if(action.checkItemImageBase64){
        state.checkItemImageBase64 = action.checkItemImageBase64;
    }
    if(state.addCheckListItemForm[action.fieldId].value === ''){
        state.addCheckListItemForm[action.fieldId].errorStatus = true;
    }else{
        state.addCheckListItemForm[action.fieldId].errorStatus = false;
    }
    return state;
}

//POST_INITIALIZE_ADD_CHECKLIST_ITEM_FORM
const postInitializeAddChecklistItemForm = (state, action) => {
    Object.keys(state.addCheckListItemForm).map(inputField => {
        state.addCheckListItemForm[inputField].value = '';
        state.addCheckListItemForm[inputField].errorStatus = false;
        state.addCheckListItemForm[inputField].disabledStatus = false;
        state.checkItemImageBase64='';
    })
    return state;
}

//POST_HANDLE_ADD_TEMPLATE_TEXTFIELDS
const postHandleAddTemplateTextFields = (state, action) => {
    state.addTemplateForm[action.formFieldId].value = action.data;
    if(state.addTemplateForm[action.formFieldId].value === ''){
        state.addTemplateForm[action.formFieldId].errorStatus = true;
    }else{
        state.addTemplateForm[action.formFieldId].errorStatus = false;
    }
    return state;
}

//POST_GET_VEHICLES
const postGetVehicles = (state, action) => {
    state.vehicleInformations = action.vehicleInformations;
    state.getVehicleInformationLoaderStatus = action.getVehicleInformationLoaderStatus;
    return state;
}

// POST_GET_TEMPLATES
const postGetTemplates = (state, action) => {
    state.templateInformations = action.templateInformations;
    state.getTemplateLoaderStatus = action.getTemplateLoaderStatus;
    return state;
}

//POST_UPDATE_TEMPLATE
const postUpdateTemplate = (state, action) => {
    state.templateInformations = action.templateInformations;
    return state;
}

// /POST_ON_SUBMIT_TEMPLATE_CREATE
const postOnSubmitTemplateCreate = (state, action) => {
    Object.keys(state.addTemplateForm).map(inputField => {
        if(state.addTemplateForm[inputField].value === ''){
            state.addTemplateForm[inputField].errorStatus = true;
        }else{
            state.addTemplateForm[inputField].errorStatus = false;
        }
    })
    state.addTemplateLoaderStatus = action.addTemplateLoaderStatus;
    return state;
}

// /POST_GET_CHECK_ITEMS
const postGetCheckItems = (state, action) => {
    state.checkListItemInformation = action.checkListItemInformation;
    state.listCheckItemLoaderStatus = action.listCheckItemLoaderStatus;
    return state;
}

//POST_ON_SUBMIT_ADD_CHECKLIST_ITEM
const postOnSubmitAddChecklistItem = (state, action) => {
    state.checkitemSubmitLoaderStatus = action.checkitemSubmitLoaderStatus;
    return state;
}

// /POST_ON_UPDATE_CHECK_ITEM
const postOnUpdateCheckItem = (state, action) => {
    state.checkitemUpdateLoaderStatus = action.checkitemUpdateLoaderStatus;
    state.editChecklistItemDialogStatus = action.editChecklistItemDialogStatus;
    return state;
}

//POST_INITIALIZE_ADD_TEMPLATE_FORM
const postInitializeAddTemplateForm = (state, action) => {
    Object.keys(state.addTemplateForm).map(inputField => {
        state.addTemplateForm[inputField].value = '';
        state.addTemplateForm[inputField].errorStatus = false;
    })
    return state;
}

//POST_GET_EXAMPLE_IMAGE
const postGetExampleImage = (state, action) => {
    return state;
} 

//POST_GET_TRIGGERS
const postGetTriggers = (state, action) => {
    let triggerDropdownList = state.triggerDropdown.trigger_dropdown.menuItems;
    let trigerInfo = {};
    for(let i = 0; i < action.triggerData.length; i++){
        trigerInfo = {};
        trigerInfo['name'] = action.triggerData[i].trigger_name;
        trigerInfo['value'] = action.triggerData[i]._id;
        triggerDropdownList[action.triggerData[i].trigger_name] = trigerInfo;
    }
    state.triggerDropdown.trigger_dropdown.menuItems = triggerDropdownList;
    return state;
}

//POST_HANDLE_TRIGGER_DROPDOWN
const postHandleTriggerDropdown = (state, action) => {
    Object.keys(state.triggerDropdown[action.formElementId].menuItems).map(menuItem => {
        if(state.triggerDropdown[action.formElementId].menuItems[menuItem].value === action.data){
            localStorage.setItem('trigger_name', state.triggerDropdown[action.formElementId].menuItems[menuItem].name);
        }
    })
    state.triggerDropdown[action.formElementId].value = action.data;
    state.checklistArray = action.checklistArray;
    state.getCheckListloaderStatus = action.getCheckListloaderStatus
    return state;
}

// /POST_HANDLE_CREATE_CHECKLIST_TEXFIELD
const postHandleCreateChecklistTextfield = (state, action) => {
    state.createcheckListTextFields[action.formFieldId].value = action.data;
    if(state.createcheckListTextFields[action.formFieldId].value === ''){
        state.createcheckListTextFields[action.formFieldId].errorStatus = true;
    }else{
        state.createcheckListTextFields[action.formFieldId].errorStatus = false;
    }
    return state;
}

//POST_GET_TEMPLATES_FOR_EXPANSION_PANEL
const postGetTemplatesForExpansionPanel = (state, action) => {
    state.templateExpansionPanelDetails = action.templates;
    state.getTemplatesForExpansionPanelLoaderStatus = action.getTemplatesForExpansionPanelLoaderStatus;
    return state;
}

//POST_HANDLE_TEMPLATE_EXPANSION_COLLAPSE
const postHandleTemplateExpansionCollapse = (state, action) => {
    let expanded = action.expansionDetail;
    if(expanded === state.templateExpansionPanelExpandedStatus){
        expanded = false;
    }
    state.templateExpansionPanelExpandedStatus = expanded;
    return state;
}

//POST_MAP_TEMPLATE_TO_CHECKLIST
const postMapTemplateToChecklist = (state, action) => {
    const expandedTemplateIndex = state.templateExpansionPanelDetails.findIndex(d => {
        return d.id == action.expansionDetailId;
    });
    state.templateExpansionPanelDetails[expandedTemplateIndex].checkItems = action.checkItems;
    if(state.templateExpansionPanelDetails[expandedTemplateIndex].checkItems.length > 0){
        let selectAllStatus = true;
        for(let i = 1; i < state.templateExpansionPanelDetails[expandedTemplateIndex].checkItems.length; i++){
            if(!state.templateExpansionPanelDetails[expandedTemplateIndex].checkItems[i].checkedStatus){
                selectAllStatus = false;
            }
        }
    
        if(selectAllStatus){
            state.templateExpansionPanelDetails[expandedTemplateIndex].checkItems[0].checkedStatus = true;
        }else{
            state.templateExpansionPanelDetails[expandedTemplateIndex].checkItems[0].checkedStatus = false;
        }
    }

    return state;
}

// /POST_HANDLE_CHECKITEM_CHECKBOX
const postHandleCheckItemCheckbox = (state, action) => {
    const expandedTemplateIndex = state.templateExpansionPanelDetails.findIndex(d => {
        return d.id == action.templateID;
    });
    const expandedTemplatesCheckItemIndex = state.templateExpansionPanelDetails[expandedTemplateIndex].checkItems.findIndex(d => {
        return d.id == action.checkItemId;
    });
    if(state.templateExpansionPanelDetails[expandedTemplateIndex].checkItems[expandedTemplatesCheckItemIndex].id === 'select_all'){
        state.templateExpansionPanelDetails[expandedTemplateIndex].checkItems[0].checkedStatus = ! state.templateExpansionPanelDetails[expandedTemplateIndex].checkItems[0].checkedStatus;
        if(state.templateExpansionPanelDetails[expandedTemplateIndex].checkItems[0].checkedStatus){
            for(let i = 1 ; i < state.templateExpansionPanelDetails[expandedTemplateIndex].checkItems.length; i++){
                state.templateExpansionPanelDetails[expandedTemplateIndex].checkItems[i].checkedStatus = true;
                state.selectedCreateCheckItemArray.push(state.templateExpansionPanelDetails[expandedTemplateIndex].checkItems[i].id);
            }
        }else{
            state.selectedCreateCheckItemArray = [];
            for(let i = 1 ; i < state.templateExpansionPanelDetails[expandedTemplateIndex].checkItems.length; i++){
                state.templateExpansionPanelDetails[expandedTemplateIndex].checkItems[i].checkedStatus = false;
            }
        }
    }else{
        let selectAllCheckedStatus = true;
        state.templateExpansionPanelDetails[expandedTemplateIndex].checkItems[expandedTemplatesCheckItemIndex].checkedStatus = !state.templateExpansionPanelDetails[expandedTemplateIndex].checkItems[expandedTemplatesCheckItemIndex].checkedStatus;
        if(state.templateExpansionPanelDetails[expandedTemplateIndex].checkItems[expandedTemplatesCheckItemIndex].checkedStatus){
            if(!findFromArray(state.selectedCreateCheckItemArray, state.templateExpansionPanelDetails[expandedTemplateIndex].checkItems[expandedTemplatesCheckItemIndex].checkedStatus)){
                state.selectedCreateCheckItemArray.push(state.templateExpansionPanelDetails[expandedTemplateIndex].checkItems[expandedTemplatesCheckItemIndex].id);
            }
        }else{
            state.selectedCreateCheckItemArray.splice(state.selectedCreateCheckItemArray.indexOf(state.templateExpansionPanelDetails[expandedTemplateIndex].checkItems[expandedTemplatesCheckItemIndex].id), 1);
        }
        for(let i = 1 ; i < state.templateExpansionPanelDetails[expandedTemplateIndex].checkItems.length; i++){
            if(!state.templateExpansionPanelDetails[expandedTemplateIndex].checkItems[i].checkedStatus){
                selectAllCheckedStatus = false;
            }
        }
        if(selectAllCheckedStatus){
            state.templateExpansionPanelDetails[expandedTemplateIndex].checkItems[0].checkedStatus = true;
        }else{
            state.templateExpansionPanelDetails[expandedTemplateIndex].checkItems[0].checkedStatus = false;
        }
    }
    return state;
}


// /POST_INITIALIZE_CREATE_CHECKLIST
const postInitializeCreateChecklist = (state, action) => {
    state.selectedCreateCheckItemArray = [];
    let url = window.location.href;
    url = url.split('/')[url.split('/').length - 1];
    if(url === "editchecklist"){
        state.createcheckListTextFields['checklist_name'].value = localStorage.getItem('checklist_name');
    }else{
        state.createcheckListTextFields['checklist_name'].value = '';
    }
    
    state.createcheckListTextFields['trigger_name'].value = localStorage.getItem('trigger_name');
    return state;
}

//POST_ON_SUBMIT_CREATE_CHECKLIST
const postOnSubmitCreateChecklist = (state, action) => {
    state.onSubmitCreateChecklistLoaderStatus = action.onSubmitCreateChecklistLoaderStatus;
    if(state.createcheckListTextFields['checklist_name'].value === ''){
        state.createcheckListTextFields['checklist_name'].errorStatus = true;
    } 
    return state;
}

//POST_GET_VEHICLE_BY_ID
const postGetVehicleById = (state, action) => {
    state.vehicleInfoForAssignChecklist = action.vehicleInfoForAssignChecklist
    return state;
}

//POST_HANDLE_ASSIGNMENTLIST_EXPANSION_COLLAPSE
const postHandleAssignmentListExpansionCollapse = (state, action) => {
    let expanded = action.expansionDetail;
    if(expanded === state.checklistExpansionPanelExpandedStatus){
        expanded = false;
    }
    if(state.activeDeleteCheckItemOptionStatus){
        if(expanded){
            state.checklistExpansionPanelExpandedStatus = expanded;
        }
    }
    else{
        state.checklistExpansionPanelExpandedStatus = expanded;
    }
    return state;
}


//POST_MAP_CHECKLIST_TO_CHECKITEMS
const postMapChecklistToCheckItems = (state, action) => {
    const expandedChecklistIndex = state.checklistArray.findIndex(d => {
        return d.id == action.expansionDetail.id;
    });
    state.checklistArray[expandedChecklistIndex].checkItems = action.checklistCheckitems;
    return state;
}

// /POST_INITIALIZE_LIST_ASSIGNED_CHECKLIST
const postInitializeListAssignedChecklist = (state, action) => {
    state.triggerDropdown['trigger_dropdown'].value = '';
    state.checklistArray = [];
    return state;
}


//POST_ON_UPDATE_CHECKLIST
const postOnUpdateChecklist = (state, action) => {
    state.onSubmitCreateChecklistLoaderStatus = action.onSubmitCreateChecklistLoaderStatus;
    return state;
}

// /POST_ACTIVE_DELETE_CHECK_ITEM_OPTION
const postActiveDeleteCheckItemOption = (state, action) => {
    state.activeDeleteCheckItemOptionStatus = true;
    return state;
}

// /POST_DEACTIVE_DELETE_CHECKITEM_OPTION
const postDeactiveDeleteCheckitemOption = (state, action) => {
    state.activeDeleteCheckItemOptionStatus = false;
    return state;
}


// /POST_HANDLE_CHECKITEM_CHECKBOX_FOR_DELETE
const postHandleCheckItemCheckboxForDelete = (state, action) => {
    const selectedChecklistArrayIndex = state.checklistArray.findIndex(d => {
        return d.id == action.checklistId;
    });
    const selectedChecklistitemArrayIndex = state.checklistArray[selectedChecklistArrayIndex].checkItems.findIndex(d => {
        return d.id == action.checklistItemId;
    });
    state.checklistArray[selectedChecklistArrayIndex].checkItems[selectedChecklistitemArrayIndex].checkedStatus = !state.checklistArray[selectedChecklistArrayIndex].checkItems[selectedChecklistitemArrayIndex].checkedStatus;
    if(!findFromArray(state.checkItemArrayForDelete, action.checklistItemId)){
        state.checkItemArrayForDelete.push(action.checklistItemId);
    }else{
        state.checkItemArrayForDelete.splice(state.checkItemArrayForDelete.indexOf(action.checklistItemId), 1);
    }
    return state;
}

// /POST_DELETE_CHECKITEMS
const postDeleteCheckitems = (state, action) => {
    state.deleteCheckitemLoaderStatus = action.deleteCheckitemLoaderStatus;
    if(!state.deleteCheckitemLoaderStatus){
        state.activeDeleteCheckItemOptionStatus = false;
        const checklistArrayIndex = state.checklistArray.findIndex(d => {
            return d.id == action.checkListId;
        });
        state.checklistArray[checklistArrayIndex].checkItems = action.updateCheckItems;
        if(action.updateCheckItems.length === 0){
            state.checklistArray.splice(checklistArrayIndex, 1);
        }
        state.checkItemArrayForDelete = [];
    }
    return state;
}

const postGetNotificationDetails = (state, action) => {
    if(action.notificationData){
        if(action.notificationData.length > 0){
            state.notificationSettingsForm['fast_prestart'].value = (action.notificationData[0].percentage).toString();
            state.notificationSettingsForm['failed_check'].value = action.notificationData[0].notify;
        }
    }
    state.getNotificationSettingLoaderStatus = action.getNotificationSettingLoaderStatus;
    return state;
}

const postHandleNotificationSettingFormField = (state, action) => {
    state.notificationSettingsForm[action.formElementId].value = action.data;
    if(state.notificationSettingsForm[action.formElementId].value === ''){
        state.notificationSettingsForm[action.formElementId].errorStatus = true;
    }else{
        state.notificationSettingsForm[action.formElementId].errorStatus = false;
    }
    return state;
}

const postOnSubmitNotificationSetting = (state, action) => {
    state.onSubmitNotificationSettingLoaderStatus = action.onSubmitNotificationSettingLoaderStatus
    return state;
}

const reducer = (state = initialState, action) => {
    let localState = Object.assign({}, state);
    switch (action.type) {
        case POST_HANDLE_OPEN_EDIT_CHECKLIST_ITEM_DIALOG : return postHandleOpenEditChecklistItemDialog(localState, action);
        case POST_HANDLE_CHECK_LIST_ITEM_DIALOG_CLOSE_STATUS : return postHandleChecklistitemDialogCloseStatus(localState, action);
        case POST_HANDLE_ADD_CHECKLIST_ITEM_TEXTFIELD_CHANGE : return postHandleAddChecklistitemTextFieldChange(localState, action);
        case POST_INITIALIZE_ADD_CHECKLIST_ITEM_FORM : return postInitializeAddChecklistItemForm(localState, action);
        case POST_HANDLE_ADD_TEMPLATE_TEXTFIELDS : return postHandleAddTemplateTextFields(localState, action);
        case POST_GET_VEHICLES : return postGetVehicles(localState, action);
        case POST_GET_TEMPLATES : return postGetTemplates(localState, action);
        case POST_UPDATE_TEMPLATE : return postUpdateTemplate(localState, action);
        case POST_ON_SUBMIT_TEMPLATE_CREATE : return postOnSubmitTemplateCreate(localState, action);
        case POST_GET_CHECK_ITEMS : return postGetCheckItems(localState, action);
        case POST_ON_SUBMIT_ADD_CHECKLIST_ITEM : return postOnSubmitAddChecklistItem(localState, action);
        case POST_ON_UPDATE_CHECK_ITEM : return postOnUpdateCheckItem(localState, action);
        case POST_INITIALIZE_ADD_TEMPLATE_FORM : return postInitializeAddTemplateForm(localState, action);
        case POST_GET_EXAMPLE_IMAGE : return postGetExampleImage(localState, action);
        case POST_GET_TRIGGERS : return postGetTriggers(localState, action);
        case POST_HANDLE_TRIGGER_DROPDOWN : return postHandleTriggerDropdown(localState, action);
        case POST_HANDLE_CREATE_CHECKLIST_TEXFIELD : return postHandleCreateChecklistTextfield(localState, action);
        case POST_GET_TEMPLATES_FOR_EXPANSION_PANEL : return postGetTemplatesForExpansionPanel(localState, action);
        case POST_HANDLE_TEMPLATE_EXPANSION_COLLAPSE : return postHandleTemplateExpansionCollapse(localState, action);
        case POST_MAP_TEMPLATE_TO_CHECKLIST : return postMapTemplateToChecklist(localState, action);
        case POST_HANDLE_CHECKITEM_CHECKBOX : return postHandleCheckItemCheckbox(localState, action);
        case POST_INITIALIZE_CREATE_CHECKLIST : return postInitializeCreateChecklist(localState, action);
        case POST_ON_SUBMIT_CREATE_CHECKLIST : return postOnSubmitCreateChecklist(localState, action);
        case POST_GET_VEHICLE_BY_ID : return postGetVehicleById(localState, action);
        case POST_HANDLE_ASSIGNMENTLIST_EXPANSION_COLLAPSE : return postHandleAssignmentListExpansionCollapse(localState, action);
        case POST_MAP_CHECKLIST_TO_CHECKITEMS : return postMapChecklistToCheckItems(localState, action);
        case POST_INITIALIZE_LIST_ASSIGNED_CHECKLIST : return postInitializeListAssignedChecklist(localState, action);
        case POST_ON_UPDATE_CHECKLIST : return postOnUpdateChecklist(localState, action);
        case POST_ACTIVE_DELETE_CHECK_ITEM_OPTION : return postActiveDeleteCheckItemOption(localState, action);
        case POST_DEACTIVE_DELETE_CHECKITEM_OPTION : return postDeactiveDeleteCheckitemOption(localState, action);
        case POST_HANDLE_CHECKITEM_CHECKBOX_FOR_DELETE : return postHandleCheckItemCheckboxForDelete(localState, action);
        case POST_DELETE_CHECKITEMS : return postDeleteCheckitems(localState, action);
        case POST_GET_NOTIFICATION_DETAILS : return postGetNotificationDetails(localState, action);
        case POST_HANDLE_NOTIFICATION_SETTING_FORMFIELD : return postHandleNotificationSettingFormField(localState, action);
        case POST_ON_SUBMIT_NOTIFICATION_SETTING : return postOnSubmitNotificationSetting(localState, action);
        default:
            return state;
    }
}

export default reducer;