import {
    MANAGE_LOGIN_STATUS,
    LOGIN_TEXTFIELD_CHANGE,
    ENABLE_DISABLE_SIGNIN,
    SET_CHANGE_PASSWORD_INPUT_FIELD,
    POST_NOT_A_USER,
    POST_SUBMIT_NOT_A_USER_DETAILS,
    POST_HANDLE_NOT_A_USER_INPUT_FIELDS
} from '../actions/ActionType'

const initialState = {
    authenticationStatus: false,
    disableSignin:true,
    showLoaderStatus : false,
    
    resetPasswordPasswordForm :{
        'new_password': {
            name: 'New Password',
            type: 'password',
            icon: 'now-ui-icons text_caps-small',
            value: ''
        },
        'confirm_password': {
            name: 'Confirm Password',
            type: 'password',
            icon: 'now-ui-icons text_caps-small',
            value: ''
        },
    },
    loginTextFields: {
        'email': {
            name: "email",
            type: 'text',
            icon: 'now-ui-icons users_circle-08',
            value: ''
        },
        'password': {
            name: 'password',
            type: 'password',
            icon: 'now-ui-icons text_caps-small',
            value: ''
        },
    },
    notAUsertextFields : {
        'name' : {
            name: "Name",
            type: 'text',
            icon: '',
            value: '',
            maxlength : "60"
        },
        'email' : {
            name: "Email",
            type: 'email',
            icon: '',
            value: '',
            maxlength : "60"
        },
        'phone' : {
            name: "Phone",
            type: 'text',
            icon: '',
            value: '',
            maxlength : "10"
        },
        'message' : {
            name: "Your Message",
            type: 'text',
            icon: '',
            value: '',
            maxlength : "256"
        },
    },
    forgotPasswordTextFields: {
        'email': {
            name: "email",
            type: 'text',
            icon: 'now-ui-icons users_circle-08',
            value: ''
        }
    },
    resetPasswordTextFields: {
        'password': {
            name: 'password',
            type: 'password',
            icon: 'now-ui-icons text_caps-small',
            value: ''
        }
    },
    submitNotAUserDetailsLoaderStatus : false
   
}

const manageLoginStatus = (state, action) => {
    state.authenticationStatus = action.data;
    state.showLoaderStatus = action.showLoaderStatus;
    return state;
}

const loginTextFieldChange  = (state, action) => {
    state.loginTextFields[action.textFieldId].value = action.textFieldData;
    return state
}

const enableDisableSignin = (state, action) => {
    state.disableSignin = action.data
    return state;
}

const setChangePasswordInputField = (state, action) => {
    state.firstTimeChangePasswordForm[action.inputField].value = action.data;
    return state;
}

const postNotAUser = (state, action) => {
    return state;
}

const reducer = (state = initialState, action) => {
    let localState = Object.assign({}, state);
    switch (action.type) {
        case POST_HANDLE_NOT_A_USER_INPUT_FIELDS :
            localState.notAUsertextFields[action.inputFieldId].value = action.data;
            return localState;
        case POST_SUBMIT_NOT_A_USER_DETAILS : 
            localState.submitNotAUserDetailsLoaderStatus = action.submitNotAUserDetailsLoaderStatus
            return localState;
        case POST_NOT_A_USER : return postNotAUser(localState, action);
        case SET_CHANGE_PASSWORD_INPUT_FIELD : return setChangePasswordInputField(localState, action);
        case MANAGE_LOGIN_STATUS: return manageLoginStatus(localState, action);
        case LOGIN_TEXTFIELD_CHANGE: return loginTextFieldChange(localState, action);
        case ENABLE_DISABLE_SIGNIN: return enableDisableSignin(localState, action);
        default:
            return state;
    }
}

export default reducer;