import React, { Component } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import CardHeader from '@material-ui/core/CardHeader';
import Typography from '@material-ui/core/Typography';
import Button from "@material-ui/core/Button";
import { withRouter } from "react-router-dom";
import Dialog from '@material-ui/core/Dialog';

import { connect } from 'react-redux';

import CircularProgress from '@material-ui/core/CircularProgress';

import{
    getLoginUserInfo
} from "../../actions/Profile";
import{
    getActulaURL
} from "../../utils/Helper";
import ChangePassword from "../../components/ChangePassword/ChangePassword";
class ProfileDetails extends Component{
    componentDidMount(){
        this.props.getLoginUserInfo();
    }
    render(){
        let{
            logedinUserinfo,
            profileDetailsLoaderStatus
        } = this.props.profileState;
    
        let{
            menuItems
        } = this.props.sidebarState;
      
        let{
            userFirstLoginStatus
        } = this.props.changePasswordState;
    
        return(
            <div>
                <Card className = "pl-3 pr-2">
                    <Grid container spacing={24}>
                            <Grid item xs={6} sm = {6}>
                                <CardHeader
                                    title="Profile Details"
                                />
                            </Grid>
                            <Grid item xs={6} sm = {6} style = {{marginTop : "-1rem", display : "flex", justifyContent : "flex-end"}}>
                                <Button 
                                    variant="outlined" 
                                    color = "default"
                                    className = "mt-4 mb-4"
                                    onClick = {() => this.props.history.push(getActulaURL(menuItems['change_password'].urlpattern))}
                                >
                                    <i className="material-icons" style = {{paddingRight : "0.6rem"}}>
                                        vpn_key </i>
                                    Change Password
                                </Button>
                            </Grid>
                    </Grid>

                    <CardContent>
                        {
                            profileDetailsLoaderStatus ? 
                            <Grid
                                container
                                direction="row"
                                justify="center"
                                alignItems="center"
                            >
                                <Grid item>
                                    <CircularProgress color = "primary" />
                                </Grid>
                            </Grid>
                                :
                            Object.keys(logedinUserinfo).map(userInfo => {
                                return(
                                    logedinUserinfo[userInfo].role === localStorage.getItem('role') ?
                                    <Grid container wrap="nowrap" spacing={16}>
                                        <Grid item xs = {12}>
                                            <Typography variant="subtitle2" gutterBottom>
                                                <Grid container wrap="nowrap" spacing={16}>
                                                    <Grid item xs = {4} style = {{textTransform: "none", }}>
                                                        <span><b>{logedinUserinfo[userInfo].fieldName}</b> </span>
                                                    </Grid>
                                                    <Grid item xs = {8} style = {{textTransform: "none"}}>
                                                        <span> : </span>
                                                        <span className = "ml-3">{logedinUserinfo[userInfo].value}</span>
                                                    </Grid>
                                                </Grid>
                                            </Typography>
                                        </Grid>
                                    </Grid> : null
                                )
                            })
                        }
                        <Grid
                            container 
                            spacing={24} 
                            className = "pt-2"
                            direction="row" 
                            justify="flex-end"
                            alignItems="center"
                        >
                            <Grid item>
                                <Button 
                                    variant="contained" 
                                    color = "primary"
                                    className = "mt-4"
                                    type = "button"
                                    onClick = {() => this.props.history.push(menuItems['edit_profile'].urlpattern)}
                                    >
                                    <i className="material-icons mr-2" style = {{fontSize : "1.2rem"}}>
                                        edit
                                    </i>
                                    Edit Profile
                                </Button>
                            </Grid>  
                        </Grid>
                    </CardContent>
                </Card>
                <Dialog
                    open={localStorage.getItem('role') !== '5' && userFirstLoginStatus === 'true'}
                    maxWidth = 'sm'
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <ChangePassword/>
                </Dialog>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return{
        profileState : state.profile,
        sidebarState : state.sidebarState,
        changePasswordState : state.changePassword
    }
}

const mapDispatchToProps = dispatch => {
    return{
        getLoginUserInfo : () => {
            return dispatch(getLoginUserInfo())
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ProfileDetails));