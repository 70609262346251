import React, { Component } from 'react'
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import FormGroup from '@material-ui/core/FormGroup';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

import Input from "../../components/UI/Input/Input";


import { connect } from 'react-redux';

import {
    handleFatigueRuleTextFields,
    onSubmit,
    initializeFatigueRuleComponent
} from "../../actions/FatigueRule";



class AddFatigueRule extends Component{
  componentDidMount(){
    this.props.initializeFatigueRuleComponent();
  }
    render(){
        let { 
          fatigueRuleForm, 
          showLoaderStatus
        } = this.props.fatigueRule;
        const formElementArray = [];
        for(let key in fatigueRuleForm){
          formElementArray.push({
            id : key,
            config : fatigueRuleForm[key]
          })
        }
        let form = (
          <form>
            {
              formElementArray.map(formElement => (
                <Input 
                  key = {formElement.id}
                  elementType={formElement.config.elementType}
                  elementConfig={formElement.config.elementConfig}
                  value={formElement.config.value}
                  error = {formElement.config.errorStatus}
                  helperText = {formElement.config.errorMessage}
                  changed={(event) => this.props.handleFatigueRuleTextFields(formElement.id, event )}
                  touched = {(event) => this.props.handleFatigueRuleTextFields(formElement.id, event )}
                  maxLength={formElement.config.maxLength}
                />
              ))
            }
          </form>
        )
        return(
          <Grid container spacing={24}>
          <Grid item xs={1} sm={2} />
          <Grid item xs={11} sm={8}>
          <Card className = "pl-3 pr-3 pt-3">
              <CardHeader
                  title="Add Fatigue Rule Group"
              />
              <CardContent>
                  <FormGroup row className = "pl-3 pr-1">
                      <Grid container spacing={24}>
                          <Grid item xs={10} sm = {10}>
                              {form}
                          </Grid> 
                          <Grid item xs={2} sm = {2}>
                          {
                            showLoaderStatus ? <CircularProgress color = "primary" /> :
                              <Button 
                                  variant="contained" 
                                  color = "primary"
                                  className = "mt-4"
                                  onClick = {() => this.props.onSubmit(fatigueRuleForm, this.props)}
                                  >
                                  ADD
                              </Button>
                          }
                          </Grid>     
                      </Grid>
                  </FormGroup>
              </CardContent>
              <CardActions>
                  
              </CardActions>
          </Card>
          </Grid>
          <Grid item xs={1} sm={2} />
        </Grid>
        );
    }
}

const mapStateToProps = (state) => {
    return{
        fatigueRule : state.fatigueRule
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
      onSubmit: (fatigueRuleForm, props) => {
        return dispatch(onSubmit(fatigueRuleForm, props));
      },
      handleFatigueRuleTextFields: (textField, event) => {
        return dispatch(handleFatigueRuleTextFields(textField, event));
      },
      initializeFatigueRuleComponent : () => {
        return dispatch(initializeFatigueRuleComponent())
      }
    };
}
export default connect(mapStateToProps,mapDispatchToProps)(AddFatigueRule);