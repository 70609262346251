import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import FormGroup from "@material-ui/core/FormGroup";
import Button from "@material-ui/core/Button";
import CircularProgress from '@material-ui/core/CircularProgress';


import { connect } from "react-redux";

import { 
  handleEntryRuleTextFields,
  onSubmit,
  initializeEntryTypesComponent
} from "../../actions/EntryTypes";
import Input from "../../components/UI/Input/Input";


class AddEntryTypes extends Component {
  componentDidMount(){
    this.props.initializeEntryTypesComponent()
  }
  render() {
    let { 
      entryTypesForm, 
      showLoaderStatus 
    } = this.props.entryTypes;
    const formElementArray = [];
    for(let key in entryTypesForm){
      formElementArray.push({
        id : key,
        config : entryTypesForm[key]
      })
    }
    let form = (
      <form>
        {
          formElementArray.map(formElement => (
            <Input 
              key = {formElement.id}
              elementType={formElement.config.elementType}
              elementConfig={formElement.config.elementConfig}
              value={formElement.config.value}
              error = {formElement.config.errorStatus}
              helperText = {formElement.config.errorMessage}
              maxLength = {formElement.config.maxLength}
              changed={(event) => this.props.handleEntryRuleTextFields(formElement.id, event )}
              touched = {(event) => this.props.handleEntryRuleTextFields(formElement.id, event )}
            />
          ))
        }
      </form>
    )
    
    return (
        <Grid container spacing={24}>
          <Grid item xs={1} sm={2} />
          <Grid item xs={11} sm={8}>
            <Card className="pl-3 pr-3 pt-3">
              <CardHeader title="Add Entry Types" />
              <CardContent>
                <FormGroup row className="pl-3 pr-1">
                  <Grid container spacing={24}>
                    <Grid item xs={10} sm={10}>
                      {form}
                    </Grid>

                    <Grid item xs={2} sm={2}>
                    {
                      showLoaderStatus ? <CircularProgress color = "primary" /> :
                      <Button
                        variant="contained"
                        color="primary"
                        className="mt-4"
                        onClick = {() => {this.props.onSubmit(entryTypesForm, this.props)}}>
                        ADD
                      </Button>
                    }
                    </Grid>
                  </Grid>
                </FormGroup>
              </CardContent>
              <CardActions />
            </Card>
          </Grid>
          <Grid item xs={1} sm={2} />
        </Grid>
    );
  }
}

const mapStateToProps = (state) => {
    return {
      entryTypes: state.entryTypes
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
      onSubmit : (entryTypesForm, props) => { 
        return dispatch(onSubmit(entryTypesForm, props))
    },
      handleEntryRuleTextFields: (textField, event) => {
        return dispatch(handleEntryRuleTextFields(textField, event));
      },
      initializeEntryTypesComponent : () => {
        return dispatch(initializeEntryTypesComponent())
      }
    };
}
export default connect(mapStateToProps,mapDispatchToProps)(AddEntryTypes);