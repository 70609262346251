const initialState = {
    addBusinessUsersTextFields : {
        "user_name" : {
            name : "Enter User name",
            value : ""
        },
        maxLength:256
    },
    businessUsersListcols: [
        { title: "Id", field: "id" },
        { title: "User Name", field: "user_name" },
        {
            title: 'User Role',
            field: 'user_role',
            lookup: { 100: 'Reseller Editor', 101: 'Reseller Reporter' },
        },
      ],
      businessUsersListrows: [
        { id: "user_1", user_name: "User 1", user_role : 100 },
        { id: "user_2", user_name: "User 2", user_role : 100},
        { id: "user_3", user_name: "User 3", user_role : 101 },
        { id: "user_4", user_name: "User 4", user_role : 100},
        { id: "user_5", user_name: "User 5", user_role : 101 },
        { id: "user_6", user_name: "User 6", user_role : 100 },
        { id: "user_7", user_name: "User 7", user_role : 100 },
        { id: "user_8", user_name: "User 8", user_role : 101 },
      ]
}

const reducerBusinessUsers = (state = initialState, action) => {
    let localState = Object.assign({}, state);
    switch(action.type){
        default : 
            return localState;
    }
}

export default reducerBusinessUsers;