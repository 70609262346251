import React, { Component } from 'react';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import FormGroup from '@material-ui/core/FormGroup';
import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux';
import SGLoader from "../../../../components/UI/Loader/SGLoader";
import Input from "../../../../components/UI/Input/Input";
import {
	addBusinessNext,
	handleTextFieldChange,
	onBlurTextfield,
	initializeAddDistributorForm,
	handleEditDriverDialogClose,
	onUpdateBusiness,
	getBusinessInfoById
} from '../../../../actions/Business';

const queryString = require('query-string');

class AddBusiness extends Component {
	componentDidMount() {

		let editBusinessSearchFoundStatus = (window.location.href).search('editbusiness') > 0;
		let editProfileSearchFoundStatus = (window.location.href).search('editprofile') > 0;
		if (this.props.business.businessAdminEditStatus && !this.props.business.addBusinessTabDisableStatus) {
			this.props.initializeAddDistributorForm();
		}

		if (editBusinessSearchFoundStatus || editProfileSearchFoundStatus ) {
			this.props.getBusinessInfoById()
		}
	}

	render() {
		let {
			addBusinessAdminLoaderStatus,
			addBusinessForm,
			mode,
			myob_account_number,
			addBusinessLogoBase64,
			getBusinessAdminByIdLoaderStatus,
			searchBusinessForm
		} = this.props.business;

		const _queryString = queryString.parse(window.location.search);
		const abn = _queryString.abn;
		let editBusinessSearchFoundStatus = (window.location.href).search('editbusiness') > 0;
		let editProfileSearchFoundStatus = (window.location.href).search('editprofile') > 0;
		const myob_account_num = myob_account_number;
		const formElementArray = [];

		for (let key in addBusinessForm) {
			if (editProfileSearchFoundStatus && key === 'integrationId'){
				continue;
			}
			if(key==="abn"){
				addBusinessForm[key].value = abn;
			}
			formElementArray.push({
				id: key,
				config: addBusinessForm[key]
			})
		}
		if (mode === 'edit') {
			const contactEmailIndex = formElementArray.findIndex(d => {
				return d.id === 'contact_email';
			});
			formElementArray.splice(contactEmailIndex, 1);
		}
		let form = (
			<FormGroup row>
				<Grid container spacing={24}>
					{
						formElementArray.map(formElement => (

							(mode || formElement.id != "isDeleted") &&
								(formElement.id !== 'abn' && formElement.id !== 'contact_email') ?
								<Grid item xs={12} sm={6} key={formElement.id}>
									<Input
										key={formElement.id}
										name={formElement.config.elementConfig.label}
										elementType={formElement.config.elementType}
										elementConfig={formElement.config.elementConfig}
										value={formElement.config.value}
										menuItems={formElement.config.menuItems}
										error={formElement.config.errorStatus}
										helperText={formElement.config.errorMessage}
										changed={(event) => this.props.handleTextFieldChange(formElement.id, event)}
										touched={(event) => this.props.handleTextFieldChange(formElement.id, event)}
										disabledStatus={formElement.config.disabledStatus}
										maxLength={formElement.config.maxLength}
									/>
								</Grid>
								:
								<Grid item xs={12} sm={6} key={formElement.id}>
									<Input
										key={formElement.id}
										name={formElement.config.elementConfig.label}
										elementType={formElement.config.elementType}
										elementConfig={formElement.config.elementConfig}
										value={(formElement.config.value)}
										menuItems={formElement.config.menuItems}
										error={formElement.config.errorStatus}
										helperText={formElement.config.errorMessage}
										changed={(event) => this.props.handleTextFieldChange(formElement.id, event)}
										touched={(event) => this.props.handleTextFieldChange(formElement.id, event)}
										disabledStatus={(editProfileSearchFoundStatus  || editBusinessSearchFoundStatus) ? true : false}
										maxLength={formElement.config.maxLength}
									/>
								</Grid>
						)
						)
					}
				</Grid>

				<Grid
					container
					spacing={24}
					className="pt-2"
					direction="row"
					justify="flex-end"
					alignItems="center"
				>
					<Grid item className="d-flex justify-content-end">
						<Button
							variant="contained"
							color="primary"
							disabled={false}
							onClick={() => { this.props.addBusinessNext(addBusinessForm, this.props, addBusinessLogoBase64) }}
						>
							Next
                        </Button>
					</Grid>
				</Grid>
			</FormGroup>
		)
		return (
			<Card className="pl-3 pr-3 pt-3 mb-3">
				<Grid container spacing={24} className="pt-2">
					<Grid item xs={12} sm={10}>
						<CardHeader title={editBusinessSearchFoundStatus ? "Edit Business" : editProfileSearchFoundStatus ? "Edit Profile" : "Add Business"} />
						{editBusinessSearchFoundStatus ?
							<span style={{ textAlign: "center", marginLeft: 17, paddingTop: 25 }}>MyOb Account Number:
							<input style={{ marginLeft: 15, textAlign: "center" }} value={myob_account_num} disabled /></span> :
							null}
					</Grid>
				</Grid>
				<CardContent>
					{
						getBusinessAdminByIdLoaderStatus ? <SGLoader /> : form
					}
				</CardContent>
			</Card>
		);
	}
}

const mapStateToProps = state => {
	return {
		business: state.business,
		sidebarState: state.sidebarState
	}
};

const mapDispatchToProps = (dispatch) => {
	return {
		getBusinessInfoById: () => {
			return dispatch(getBusinessInfoById())
		},

		handleTextFieldChange: (textField, event) => {
			if (textField === 'abn') {
				event.target.value = (event.target.value).replace(/[^0-9]/g, '');
			}
			return dispatch(handleTextFieldChange(textField, event))
		},
		onBlurTextfield: (textField, event) => {
			return dispatch(onBlurTextfield(textField, event))
		},
		addBusinessNext: (addBusinessForm, props, addBusinessLogoBase64) => {
			return dispatch(addBusinessNext(addBusinessForm, props, addBusinessLogoBase64))
		},
		initializeAddDistributorForm: () => {
			return dispatch(initializeAddDistributorForm())
		},
		handleEditDriverDialogClose: () => {
			return dispatch(handleEditDriverDialogClose())
		},
		onUpdateBusiness: (editRecordsBusinessID, addBusinessForm, addBusinessLogoBase64, businessInformations) => {
			return dispatch(onUpdateBusiness(editRecordsBusinessID, addBusinessForm, addBusinessLogoBase64, businessInformations))
		}
	}
};
export default connect(mapStateToProps, mapDispatchToProps)(AddBusiness);
