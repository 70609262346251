import React, {Component} from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import FormGroup from '@material-ui/core/FormGroup';
import Grid from '@material-ui/core/Grid';
import {withRouter} from "react-router-dom";
import {connect} from 'react-redux';

import Input from "../../components/UI/Input/Input";
import {
    initializeAddDepotForm,
    onSubmitDeport,
    depotInputHandler,
    toggleDepotEditDialog
} from '../../actions/Depot';

import {
    getDepotManagers
} from '../../actions/Business';
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";

class AddDepot extends Component {
    componentDidMount() {
        if (!this.props.depotState.showDepotEditDialog) {
            this.props.initializeAddDepotForm();
        }
        this.props.getDepotManagers();
    }

    render() {
        let {
            depotFormFields,
            showDepotEditDialog
        } = this.props.depotState;
        let {
            businessUserInformations
        } = this.props.businessState;
        let {
            menuItems
        } = this.props.sidebarState;
        let managers = {}

        for (let index in businessUserInformations) {
            let user = businessUserInformations[index];
            managers[index] = {
                name: user.business_first_name + " " + user.business_last_name,
                value: user._id
            }
        }
        const formElementArray = [];
        for (let key in depotFormFields) {
            formElementArray.push({
                id: key,
                config: depotFormFields[key]
            })
        }
        let form = (
            <FormGroup row>
                <Grid container spacing={24}>
                    {
                        formElementArray.map(formElement => (
                            <Grid item xs={12} sm={6} key={formElement.id}>
                                <Input
                                    key={formElement.id}
                                    name={formElement.config.elementConfig.label}
                                    elementType={formElement.config.elementType}
                                    elementConfig={formElement.config.elementConfig}
                                    value={formElement.config.value}
                                    menuItems={formElement.id==="manager_id"?managers:formElement.config.menuItems}
                                    error={formElement.config.errorStatus}
                                    helperText={formElement.config.errorMessage}
                                    changed={(event) => this.props.depotInputHandler(formElement.id, event)}
                                    touched={(event) => this.props.depotInputHandler(formElement.id, event)}
                                    maxLength={formElement.config.maxLength}
                                />
                            </Grid>

                        ))
                    }
                </Grid>
                <Grid container spacing={24} className="pt-2">
                    <Grid item xs={10}>
                    </Grid>
                    <Grid item xs={2} className="d-flex justify-content-end">
                        {
                                <Button
                                    variant="contained"
                                    color="primary"
                                    disabled={false}
                                    className="mr-3"
                                    onClick={() => this.props.onSubmitDeport(depotFormFields, this.props, menuItems['depots'].urlpattern,showDepotEditDialog)}
                                >
                                    Submit
                                </Button>
                        }
                        {
                            showDepotEditDialog &&
                            <Button
                                variant="contained"
                                color="default"
                                disabled={false}
                                className="mr-3"
                                onClick={() => {
                                    this.props.toggleDepotEditDialog({}, false)
                                }}
                            >
                                Cancel
                            </Button>
                        }


                    </Grid>
                </Grid>
            </FormGroup>
        )
        return (

                <Card className="pl-3 pr-3 pt-3" >
                    <div>
                        <Grid container spacing={24} className="pt-2">
                            <Grid item xs={12} sm={10}>
                                {!showDepotEditDialog ? <CardHeader title="Add Depot"/> :
                                    <CardHeader title="Edit Depot"/>}
                            </Grid>
                        </Grid>
                    </div>
                    <CardContent >
                        {form}
                    </CardContent>
                </Card>

        )
    }
}

const mapStateToProps = state => {
    return {
        depotState: state.depots,
        sidebarState: state.sidebarState,
        businessState: state.business
    }
}

const mapDispatchToProps = dispatch => {
    return {
        initializeAddDepotForm: () => {
            return dispatch(initializeAddDepotForm())
        },
        depotInputHandler: (inputFiledId, event) => {
            return dispatch(depotInputHandler(inputFiledId, event))
        },
        getDepotManagers: () => {
            return dispatch(getDepotManagers());
        },
        onSubmitDeport: (depotFormFields, props, url,showDepotEditDialog) => {
            return dispatch(onSubmitDeport(depotFormFields, props, url,showDepotEditDialog))
        },
        toggleDepotEditDialog:(depotObj,showDepotEditDialog)=>{
            return dispatch(toggleDepotEditDialog(depotObj,showDepotEditDialog));
        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AddDepot));