import React, { Component } from 'react';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import CardHeader from '@material-ui/core/CardHeader';
import FormGroup from '@material-ui/core/FormGroup';
import Grid from '@material-ui/core/Grid';
import Input from "../../components/UI/Input/Input";
import CircularProgress from '@material-ui/core/CircularProgress';
import { connect } from 'react-redux';
import Typography from '@material-ui/core/Typography';

import {
	onSubmit,
	onUpdate,
	handleTextFieldChange,
	handleAxleTextFieldChange,
	handleCheckboxChange,
	handleCloseDialog,
	onBlurTextfield,
	handleCheckBoxChange,
	handleUploadInputChange,
	getVehicleTypes,
	onUpdateVehicle,
	initializeState,
	handleVehicleLicensingCheckBoxChange,
	getaxlegroup
} from '../../actions/vehicle';

import { getmeasurementTypes } from '../../actions/LoadTypes'

class AddVehicle extends Component {
	componentDidMount() {
		this.props.getaxlegroup();
		this.props.getmeasurementTypes();
		this.props.getVehicleTypes(this.props.vehicle.addVehicleForm);
		if (this.props.vehicle.mode !== 'edit') {
			this.props.initializeState();
		}
	}
	render() {
		let {
			addVehicleForm,
			massEventBase64String,
			mode,
			addVehicleLoaderStatus,
			currentlyEditableVehicleId,
			vehicleInformations,
			updateVehicleLoaderStatus,
			// vehicleCheckBoxes,
			licensingcheckBoxes,
			axleGroupForm,
			getLicenseFieldStatusLoader
		} = this.props.vehicle;
		let {
			menuItems
		} = this.props.sidebarState;
		const formElementArray = [];
		const checkboxElementArray = [];
		const licensingElementArray = [];
		const axleGroupElementArray = [];
		for (let key in licensingcheckBoxes) {
			licensingElementArray.push({
				id: key,
				config: licensingcheckBoxes[key]
			})
		}
		// for(let key in vehicleCheckBoxes){
		//     checkboxElementArray.push({
		//         id : key,
		//         config : vehicleCheckBoxes[key]
		//     })
		// }
		for (let key in addVehicleForm) {
			formElementArray.push({
				id: key,
				config: addVehicleForm[key]
			})
		}
		for (let key in axleGroupForm) {
			axleGroupElementArray.push({
				id: key,
				config: axleGroupForm[key]
			})
		}
		let licensingCheckBoxForm = (
			<FormGroup row className="mt-4">
				<Grid container spacing={24}>
					{
						licensingElementArray.map(formElement => {
							if (formElement.id === 'select_all') {
								return (
									<Grid item xs={12} sm={12} key={formElement.id}>
										<Input
											key={formElement.id}
											name={formElement.config.elementConfig.label}
											elementType={formElement.config.elementType}
											elementConfig={formElement.config.elementConfig}
											value={formElement.config.value}
											menuItems={formElement.config.menuItems}
											error={formElement.config.errorStatus}
											helperText={formElement.config.errorMessage}
											changed={() => this.props.handleVehicleLicensingCheckBoxChange(formElement.id)}
										/>
									</Grid>
								)
							} else {
								return (
									<Grid item xs={4} sm={3} key={formElement.id}>
										<Input
											key={formElement.id}
											name={formElement.config.elementConfig.label}
											elementType={formElement.config.elementType}
											elementConfig={formElement.config.elementConfig}
											value={formElement.config.value}
											menuItems={formElement.config.menuItems}
											error={formElement.config.errorStatus}
											helperText={formElement.config.errorMessage}
											changed={(event) => this.props.handleVehicleLicensingCheckBoxChange(formElement.id)}
											maxLength={formElement.config.maxLength}
										/>
									</Grid>
								)
							}

						})
					}
				</Grid>
			</FormGroup>
		)
		let vehicleCheckBoxForm = (
			<FormGroup row className="mt-4">
				<Grid container spacing={24}>
					{
						checkboxElementArray.map(formElement => (
							<Grid item xs={4} sm={3} key={formElement.id}>
								<Input
									key={formElement.id}
									name={formElement.config.elementConfig.label}
									elementType={formElement.config.elementType}
									elementConfig={formElement.config.elementConfig}
									value={formElement.config.value}
									menuItems={formElement.config.menuItems}
									error={formElement.config.errorStatus}
									helperText={formElement.config.errorMessage}
									changed={(event) => this.props.handleCheckBoxChange(formElement.id)}
									maxLength={formElement.config.maxLength}
								/>
							</Grid>
						))
					}
				</Grid>
			</FormGroup>
		)
		let form = (
			<FormGroup row>
				<Grid container spacing={24}>
					{
						formElementArray.map(formElement => {
							if (formElement.id === 'mass_certification') {
								return (
									<Grid item xs={12} sm={6} key={formElement.id}>
										<Input
											key={formElement.id}
											name={formElement.config.elementConfig.label}
											elementType={formElement.config.elementType}
											elementConfig={formElement.config.elementConfig}
											value={formElement.config.value}
											menuItems={formElement.config.menuItems}
											error={formElement.config.errorStatus}
											helperText={formElement.config.errorMessage}
											disabledStatus={formElement.config.disabledStatus}
											changed={(event) => this.props.handleUploadInputChange(formElement.id, event)}
											maxLength={formElement.config.maxLength}
										//touched = {(event) => this.props.handleTextFieldChange(formElement.id, event )}
										/>
									</Grid>
								)

							} else if (mode !== 'edit') {
								if (formElement.id !== 'vehicle_status') {
									return (
										<Grid item xs={12} sm={6} key={formElement.id}>
											<Input
												key={formElement.id}
												name={formElement.config.elementConfig.label}
												elementType={formElement.config.elementType}
												elementConfig={formElement.config.elementConfig}
												value={formElement.config.value}
												menuItems={formElement.config.menuItems}
												error={formElement.config.errorStatus}
												helperText={formElement.config.errorMessage}
												disabledStatus={formElement.config.disabledStatus}
												changed={(event) => this.props.handleTextFieldChange(formElement.id, event)}
												//touched = {(event) => this.props.handleTextFieldChange(formElement.id, event )}
												maxLength={formElement.config.maxLength}
											/>
										</Grid>
									)
								}
							} else {
								return (
									<Grid item xs={12} sm={6} key={formElement.id}>
										<Input
											key={formElement.id}
											name={formElement.config.elementConfig.label}
											elementType={formElement.config.elementType}
											elementConfig={formElement.config.elementConfig}
											value={formElement.config.value}
											menuItems={formElement.config.menuItems}
											error={formElement.config.errorStatus}
											helperText={formElement.config.errorMessage}
											disabledStatus={formElement.config.disabledStatus}
											changed={(event) => this.props.handleTextFieldChange(formElement.id, event)}
											touched={(event) => this.props.handleTextFieldChange(formElement.id, event)}
											maxLength={formElement.config.maxLength}
										/>
									</Grid>
								)
							}
						}
						)
					}
				</Grid>
			</FormGroup>
		)
		let addAxleGroupForm = (
			<FormGroup row>
				<Grid container spacing={24}>
					{
						axleGroupElementArray.map(formElement => {
							return (
								<Grid item xs={12} sm={4} key={formElement.id}>
									<Input
										key={formElement.id}
										name={formElement.config.elementConfig.label}
										elementType={formElement.config.elementType}
										elementConfig={formElement.config.elementConfig}
										value={formElement.config.value}
										menuItems={formElement.config.menuItems}
										error={formElement.config.errorStatus}
										helperText={formElement.config.errorMessage}
										disabledStatus={formElement.config.disabledStatus}
										changed={(event) => this.props.handleAxleTextFieldChange(formElement.id, event)}
										touched={(event) => this.props.handleAxleTextFieldChange(formElement.id, event)}
										maxLength={formElement.config.maxLength}
									/>
								</Grid>
							)
						}
						)
					}
				</Grid>
			</FormGroup>
		)
		return (
			<div className="pl-3 pr-3 mb-3 container">
				<Card>
					{
						mode == 'edit' ?
							<CardHeader
								title="Edit Vehicle"
							/> :
							<CardHeader
								title="Add Vehicle"
							/>
					}
					<CardContent>
						{
							getLicenseFieldStatusLoader ?
								<div style={{ height: "20rem", width: "900px" }} className="d-flex justify-content-center align-items-center">
									<CircularProgress color="primary" />
								</div>
								:
								<div>
									{form}
									{vehicleCheckBoxForm}
									<Typography variant="h6" style={{ paddingTop: 20 }} gutterBottom>
										LICENSE
                                </Typography>
									{licensingCheckBoxForm}

									<Typography variant="h6" style={{ paddingTop: 20 }} gutterBottom>
										Select Axle Group
                                </Typography>
									{addAxleGroupForm}

									<Grid container spacing={24} className="pt-4">
										<Grid item xs={10}>
										</Grid>
										<Grid item xs={2} className="d-flex justify-content-end">
											{
												mode === 'edit' ?
													<div>
														{
															(updateVehicleLoaderStatus || updateVehicleLoaderStatus == undefined) ? <CircularProgress color="primary" /> :
																<div className="d-flex flex-row">
																	<Button
																		variant="contained"
																		color="primary"
																		className="mr-3"
																		onClick={() => this.props.onUpdateVehicle(addVehicleForm, axleGroupForm, licensingcheckBoxes, currentlyEditableVehicleId, massEventBase64String, vehicleInformations)}
																	>
																		Update
                                                        </Button>
																	<Button
																		variant="contained"
																		onClick={() => { this.props.handleCloseDialog() }}
																	>
																		Cancel
                                                        </Button>
																</div>
														}
													</div> :
													addVehicleLoaderStatus ? <CircularProgress color="primary" /> :
														<Button
															variant="contained"
															color="primary"
															disabled={false}
															onClick={() => this.props.onSubmit(addVehicleForm, axleGroupForm, licensingcheckBoxes, massEventBase64String, this.props, menuItems['vehicle_list'].urlpattern)}>
															Submit
                                            </Button>
											}
										</Grid>
									</Grid>
								</div>
						}
					</CardContent>
					<CardActions>

					</CardActions>
				</Card>
			</div>

		);
	}
}

const mapStateToProps = state => {
	return {
		vehicle: state.vehicle,
		sidebarState: state.sidebarState
	}
};

const mapDispatchToProps = (dispatch) => {
	return {
		handleVehicleLicensingCheckBoxChange: (formElementId) => {
			return dispatch(handleVehicleLicensingCheckBoxChange(formElementId))
		},
		onSubmit: (addVehicleForm, axleGroupForm, licensingcheckBoxes, massEventBase64String, props, url) => {
			return dispatch(onSubmit(addVehicleForm, axleGroupForm, licensingcheckBoxes, massEventBase64String, props, url))
		},
		handleTextFieldChange: (textField, event) => {
			return dispatch(handleTextFieldChange(textField, event))
		},
		handleAxleTextFieldChange: (textField, event) => {
			return dispatch(handleAxleTextFieldChange(textField, event))
		},
		handleUploadInputChange: (textField, event) => {
			return dispatch(handleUploadInputChange(textField, event))
		},
		handleCheckboxChange: (id, checkedStatus) => {
			return dispatch(handleCheckboxChange(id, checkedStatus))
		},
		handleCloseDialog: () => {
			return dispatch(handleCloseDialog())
		},
		onupdate: (vehicleControls) => {
			return dispatch(onUpdate(vehicleControls))
		},
		onBlurTextfield: (textField, event) => {
			return dispatch(onBlurTextfield(textField, event))
		},
		handleCheckBoxChange: (checkboxId) => {
			return dispatch(handleCheckBoxChange(checkboxId))
		},
		getVehicleTypes: (addVehicleForm) => {
			return dispatch(getVehicleTypes(addVehicleForm))
		},
		onUpdateVehicle: (addVehicleForm, axleGroupForm, licensingcheckBoxes, currentlyEditableVehicleId, massEventBase64String, vehicleInformations) => {
			return dispatch(onUpdateVehicle(addVehicleForm, axleGroupForm, licensingcheckBoxes, currentlyEditableVehicleId, massEventBase64String, vehicleInformations))
		},
		initializeState: () => {
			return dispatch(initializeState())
		},
		getmeasurementTypes: () => {
			return dispatch(getmeasurementTypes())
		},
		getaxlegroup: () => {
			return dispatch(getaxlegroup())
		},

	}
};
export default connect(mapStateToProps, mapDispatchToProps)(AddVehicle);