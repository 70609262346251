import React, { Component } from "react";
import ListTemplate from "./ListTemplate";
import Grid from "@material-ui/core/Grid";
class Checklist extends Component{
    render(){
        return(
            <Grid container spacing={24}>
                <Grid item xs={1} sm={1} />
                <Grid item xs={12} sm={10}>
                    <ListTemplate/>
                </Grid>
                <Grid item xs={1} sm={1} />
            </Grid>
            
        )
    }
}

export default Checklist;