import React, { Component } from "react";
import MaterialTable from "material-table";
import { connect } from "react-redux";
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { toast } from 'react-toastify';
import Dialog from '@material-ui/core/Dialog';
import FormGroup from '@material-ui/core/FormGroup';
import Grid from '@material-ui/core/Grid';
import Input from "../../components/UI/Input/Input";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';

import ViewMaillog from "./ViewMaillog";

import SGMaterialTable from '../../components/UI/Table/SGMaterialTable';

import {
    handleTextFieldChange,
    handleDateFieldChange,
    getMaillogsList,
    handleViewMaillogOpenDialog,
    handleViewMaillogDialogClose,
    clearAllSearchMaillogField,
    initializeSearchMaillog
} from '../../actions/Maillogs';

class ListMaillogs extends Component {
  componentDidMount(){
    this.props.initializeSearchMaillog()
  }
  render() {
    let { 
      openViewMaillogDialogStatus,
      maillogRowData,
      maillogColumns,
      searchMaillogForm,
      searchMaillogLoaderStatus,
      searchFoundStatus

    } = this.props.maillogs;
    const formElementArray = [];
    for(let key in searchMaillogForm){
        formElementArray.push({
            id : key,
            config : searchMaillogForm[key]
        })
    }

    let form = (
        <div>
            <FormGroup row className = "pl-3 pr-1">
                <Grid container spacing={24}>
                    {
                        formElementArray.map((formElement) => {
                          if (formElement.config.elementType === 'date') {
                            return (
                              <Grid item xs={12} sm={6} key={formElement.id}>
                                <Input
                                  key={formElement.id}
                                  name={formElement.config.elementConfig.label}
                                  elementType={formElement.config.elementType}
                                  elementConfig={formElement.config.elementConfig}
                                  value={formElement.config.value}
                                  menuItems={formElement.config.menuItems}
                                  error={formElement.config.errorStatus}
                                  helperText={formElement.config.errorMessage}
                                  changed={(date) => this.props.handleDateFieldChange(formElement.id, date)}
                                />
                              </Grid>
                            )
                          } else {
                            return(
                                <Grid item md={6} key = {formElement}>
                                    <Input 
                                        key = {formElement.id}
                                        name = {formElement.config.elementConfig.label}
                                        elementType={formElement.config.elementType}
                                        elementConfig={formElement.config.elementConfig}
                                        value={formElement.config.value}
                                        menuItems = {formElement.config.menuItems}
                                        error = {formElement.config.errorStatus}
                                        helperText = {formElement.config.errorMessage}
                                        changed={(event) => this.props.handleTextFieldChange(formElement.id,event)}
                                        maxLength = {formElement.config.maxLength}
                                    />
                                </Grid> 
                            )
                          }
                        })
                    }     
                </Grid>
            </FormGroup>
            <FormGroup row className = "pl-3 pr-1">
                <Grid 
                    container 
                    spacing={24} 
                    direction="row" 
                    justify="flex-end"
                    alignItems="center"
                >
                    <Grid item>
                    {
                        searchMaillogLoaderStatus ? <CircularProgress color = "primary" /> :
                        <div>
                            <Button 
                                disabled = {searchMaillogForm['from_date'].value || searchMaillogForm['to_date'].value || searchMaillogForm['mail_state'].value?false:true}
                                variant="contained" 
                                color = "primary"
                                className = "mr-3"
                                onClick = {() => this.props.clearAllSearchMaillogField()}
                                >
                                <i className="material-icons" style = {{marginRight : "0.3rem", marginLeft : "-0.5rem", fontSize : "1.2rem"}}>
                                    clear_all
                                </i>
                                Clear All
                            </Button>
                                
                            <Button 
                                disabled = {searchMaillogForm['from_date'].value || searchMaillogForm['to_date'].value || searchMaillogForm['mail_state'].value?false:true}
                                variant="contained" 
                                color = "primary"
                                onClick = {() => this.props.getMaillogsList(searchMaillogForm)}
                                >
                                <i className="material-icons" style = {{marginRight : "0.3rem", marginLeft : "-0.5rem", fontSize : "1.2rem"}}>
                                    search
                                </i>
                                Search
                            </Button>
                        </div>
                    }


                    </Grid>     
                </Grid>
            </FormGroup>
        </div>

    )
    return(
        <div className = "container">
            <Card className = "pl-3 pr-3 pt-3 mb-3">
                <CardHeader
                    title="Search Mail logs"
                />
                <CardContent>
                    {form}
                </CardContent>
            </Card>

            <Dialog
              open={openViewMaillogDialogStatus}
              onClose={() => {this.props.handleViewMaillogDialogClose()}}
              maxWidth = 'md'
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
            <ViewMaillog/>
            </Dialog>
            {
                (maillogRowData && maillogRowData.length > 0) ?
                <SGMaterialTable
                    columns={maillogColumns}
                    data={maillogRowData}
                    title=""
                    actions={[
                        {
                          icon: 'call_made',
                          tooltip: 'View Mail Log',
                          onClick: (event, rowData) => this.props.handleViewMaillogOpenDialog(rowData)
                        },
                    ]}
                />
                : null
            }
        </div>
    )
  }
}

const mapStateToProps = state => {
    return{
      maillogs : state.maillogs
    }
};

const mapDispatchToProps = dispatch => {
  return {
/*    
    navigateToViewMailLog : () => {
      return dispatch(navigateToViewMailLog())
    },
    */
    handleViewMaillogOpenDialog : (rowData) => {
      return dispatch(handleViewMaillogOpenDialog(rowData))
    },
    handleViewMaillogDialogClose : () => {
        return dispatch(handleViewMaillogDialogClose())
    },
    initializeSearchMaillog : () => {
        return dispatch(initializeSearchMaillog())
    },
    clearAllSearchMaillogField : () => {
      return dispatch(clearAllSearchMaillogField())
    },
    getMaillogsList : (searchMaillogForm) => { 
        return dispatch(getMaillogsList(searchMaillogForm))
    },
    handleTextFieldChange : (textField, event) => {
        return dispatch(handleTextFieldChange(textField, event))
    },
    handleDateFieldChange: (inputFieldId, date) => {
      return dispatch(handleDateFieldChange(inputFieldId, date))
    },

  }
}
export default connect(mapStateToProps,mapDispatchToProps)(ListMaillogs);
