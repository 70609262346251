import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import FormGroup from "@material-ui/core/FormGroup";
import Button from "@material-ui/core/Button";

import { connect } from "react-redux";

import {
  handleaxelConfigTextFields,
  onSubmit,
  initializeaxelConfigComponent,
  handleUploadInputChange,
} from "../../actions/AxelConfig";
import Input from "../../components/UI/Input/Input";
import CircularProgress from '@material-ui/core/CircularProgress';

class AddAxelConfig extends Component {
  componentDidMount() {
    this.props.initializeaxelConfigComponent()
  }
  render() {
    let {
      axelConfigForm,
      showLoaderStatus,
      base64_string

    } = this.props.axelConfig;
    const formElementArray = [];
    for (let key in axelConfigForm) {
      formElementArray.push({
        id: key,
        config: axelConfigForm[key]
      })
    }
    let form = (
      <FormGroup row>
        <Grid container spacing={24}>
          {
            formElementArray.map(formElement => {
              if (formElement.id === 'certificate_image') {
                return (
                  <Grid item xs={12} sm={6} key={formElement.id}>
                    <Input
                      key={formElement.id}
                      name={formElement.config.elementConfig.label}
                      elementType={formElement.config.elementType}
                      elementConfig={formElement.config.elementConfig}
                      value={formElement.config.value}
                      error={formElement.config.errorStatus}
                      helperText={formElement.config.errorMessage}
                      changed={(event) => this.props.handleUploadInputChange(formElement.id, event)}
                      maxLength={formElement.config.maxLength}
                    //touched = {(event) => this.props.handleTextFieldChange(formElement.id, event )}
                    />
                  </Grid>
                )
              }
              else {
                return (
                  <Grid item xs={12} sm={6} key={formElement.id}>
                    <Input
                      key={formElement.id}
                      elementType={formElement.config.elementType}
                      elementConfig={formElement.config.elementConfig}
                      value={formElement.config.value}
                      error={formElement.config.errorStatus}
                      helperText={formElement.config.errorMessage}
                      changed={(event) => this.props.handleaxelConfigTextFields(formElement.id, event)}
                      touched={(event) => this.props.handleaxelConfigTextFields(formElement.id, event)}
                      maxLength={formElement.config.maxLength}
                    />
                  </Grid>
                )
              }
            })
          }
        </Grid>
      </FormGroup>

    )
    return (
      <Grid container spacing={24}>
        <Grid item xs={1} sm={2} />
        <Grid item xs={12} sm={8}>
          <Card className="pl-3 pr-3 pt-3">
            <CardHeader title="Add Axel Config" />
            <CardContent>
              <FormGroup row className="pl-3 pr-1">
                <Grid container spacing={24}>
                  <Grid item xs={10} sm={10}>
                    {form}
                  </Grid>
                </Grid>
                <Grid container spacing={24}>
                  <Grid item xs={4} sm={4}>
                    {
                      showLoaderStatus ? <CircularProgress color="primary" /> :
                        <Button
                          variant="contained"
                          color="primary"
                          className="mt-4"
                          onClick={() => { this.props.onSubmit(this.props.axelConfig, base64_string, this.props) }}>
                          Submit
                          </Button>
                    }
                  </Grid>
                </Grid>
              </FormGroup>
            </CardContent>
            <CardActions />
          </Card>
        </Grid>
        <Grid item xs={1} sm={2} />
      </Grid>
    );
  }
}

const mapStateToProps = state => {
  return {
    axelConfig: state.axelConfig
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onSubmit: (axelConfigInfo, base64_string, props) => {
      return dispatch(onSubmit(axelConfigInfo, base64_string, props))
    },
    handleaxelConfigTextFields: (textField, event) => {
      return dispatch(handleaxelConfigTextFields(textField, event));
    },
    initializeaxelConfigComponent: () => {
      return dispatch(initializeaxelConfigComponent())
    },
    handleUploadInputChange: (textField, event) => {
      return dispatch(handleUploadInputChange(textField, event))
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddAxelConfig);
