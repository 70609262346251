import {
  SET_MASSVARIABLE_TYPE_TEXT_FIELD,
  SET_MASSVARIABLETYPE_LIST,
  SUBMIT_MASSVARIABLETYPE_INFO,
  UPDATE_MASSVARIABLETYPE_INFO,
  SET_DEFAULT_VALUE_TO_MASSVARIABLE_TYPES
} from "../actions/ActionType";
import axios from '../axios';
import { toast } from 'react-toastify';

export const initializemassVariableTypes = () => {
  return (dispatch) => {
    return dispatch(setDefaultValueTomassVariableTypes())
  }
}
export const setDefaultValueTomassVariableTypes = () => {
  return{
    type : SET_DEFAULT_VALUE_TO_MASSVARIABLE_TYPES
  }
}
/********************************************************************************* */
export const inputHandlermassVariableType = (textField, event) => {
  return dispatch => {
    return dispatch(setmassVariabletypesTextFields(textField, event));
  };
};

export const setmassVariabletypesTextFields = (textField, event) => {
  return {
    type: SET_MASSVARIABLE_TYPE_TEXT_FIELD,
    textField: textField,
    data: event.target.value
  };
};

/********************************************************************************************** */ 
/**************************************************************************************** */

export const onSubmit = (massVariableTypeInfo, props) => {
return (dispatch) => {
  let showLoaderStatus = false;
  let fieldValidationStatus = true;

  Object.keys(massVariableTypeInfo.massVariableTypeForm).map((textField) => {
      if(massVariableTypeInfo.massVariableTypeForm[textField].value  == ''){
          fieldValidationStatus = false;
          massVariableTypeInfo.massVariableTypeForm[textField].errorStatus = true;
      }
  })
  dispatch(setSubmittedData(
    massVariableTypeInfo.massVariabletypesListrows,
    showLoaderStatus,
    massVariableTypeInfo.massVariableTypeForm
    )
  );  
  if(fieldValidationStatus){
    showLoaderStatus = true;
      dispatch(setSubmittedData(
          massVariableTypeInfo.massVariabletypesListrows,
          showLoaderStatus,
          massVariableTypeInfo.massVariableTypeForm
          )
      );
      let addMassVariableTypeReq = {};
      let createdInfo = {};
      addMassVariableTypeReq["mm_massvariable_types"] = massVariableTypeInfo.massVariableTypeForm['massVariable_types'].value;
      addMassVariableTypeReq["status"] = true;
      return axios.post('/sg/massvariabletypes', addMassVariableTypeReq)
      .then(response => {
              showLoaderStatus = false;
              createdInfo = {};
              createdInfo['_id'] = response.data.data._id;
              createdInfo['mm_massvariable_types'] = response.data.data.mm_massvariable_types;
              createdInfo['status'] = response.data.data.status;
              massVariableTypeInfo.massVariabletypesListrows.unshift(createdInfo);
              Object.keys(massVariableTypeInfo.massVariableTypeForm).map((textField) => {
                  massVariableTypeInfo.massVariableTypeForm[textField].value = '';
              })
              toast.success("MassVariableType Created Successfully", {
                              containerId: 'toastMsg',
                              onClose :  () => props.history.push('/sgadminportal/massvariabletypes')
                            }
                          );
              dispatch(setSubmittedData(
                          massVariableTypeInfo.massVariabletypesListrows,
                          showLoaderStatus,
                          massVariableTypeInfo.massVariableTypeForm
                          )
                      );
      })
      .catch(error => {
          if(error.response.status == 403){
              showLoaderStatus = false;
              localStorage.clear();
              // toast.error("Session Expired ! Please login again", {containerId: 'toastMsg'});
              window.location.href = "/";
          }else{
              showLoaderStatus = false;
              toast.error(error.response.data.message, {containerId: 'toastMsg'});
              dispatch(setSubmittedData(
                          massVariableTypeInfo.massVariabletypesListrows,
                          showLoaderStatus,
                          massVariableTypeInfo.massVariableTypeForm
                          )
                      ); 
          }
      })
  }

}

}

export const setSubmittedData = ( massVariabletypesListrows, showLoaderStatus, massVariableTypeForm) => {
return {
  type: SUBMIT_MASSVARIABLETYPE_INFO,
  massVariabletypesListrows : massVariabletypesListrows,
  showLoaderStatus : showLoaderStatus,
  massVariableTypeForm : massVariableTypeForm
}
}
/************************************************************************/
export const getMassVariableTypeList = () => {
  let massVariabletype = {};
  let massVariableTypeList = [];
  let massVariableTypeListLoaderStatus = true;
  return (dispatch) => {
    dispatch(setMassVariableTypeList(massVariableTypeList, massVariableTypeListLoaderStatus));
      return axios.get('/sg/massvariabletypes')
      .then(response => {
        massVariableTypeListLoaderStatus = false;
              for(let i = 0; i < response.data.data.length; i++){
                massVariabletype = {};
                massVariabletype['_id'] = response.data.data[i]._id;
                massVariabletype['mm_massvariable_types'] = response.data.data[i].mm_massvariable_types;
                massVariabletype['status'] = response.data.data[i].status;
                massVariableTypeList.push(massVariabletype);
              }
              dispatch(setMassVariableTypeList(massVariableTypeList, massVariableTypeListLoaderStatus));
      })
      .catch(error => {
        massVariableTypeListLoaderStatus = false;
          if(error.response.status == 403){
              localStorage.clear();
              window.location.href = "/";
          }
          dispatch(setMassVariableTypeList(massVariableTypeList, massVariableTypeListLoaderStatus));
      })
  }
}

export const setMassVariableTypeList = (massVariableTypeList, massVariableTypeListLoaderStatus) => {
  return{
      type : SET_MASSVARIABLETYPE_LIST,
      massVariableTypeList : massVariableTypeList,
      massVariableTypeListLoaderStatus : massVariableTypeListLoaderStatus
  }
}

/************************************************************************************************************* */ 
export const editMassVariableType = (massVariableTypeInfo, massVariabletypesListrows) => {
  let reqURL = '/sg/massvariabletypes/' + massVariableTypeInfo._id;
  let updateReq = {};
  updateReq['mm_massvariable_types'] = massVariableTypeInfo.mm_massvariable_types;
  updateReq['status'] = massVariableTypeInfo.status=="true"?true:false;
  return (dispatch) => {
      return axios.put(reqURL, updateReq)
      .then(response => {
              const updateIndex = massVariabletypesListrows.findIndex(d => {
                  return d._id === massVariableTypeInfo._id;
              });
              massVariabletypesListrows[updateIndex].mm_massvariable_types = massVariableTypeInfo.mm_massvariable_types;
              massVariabletypesListrows[updateIndex].status = massVariableTypeInfo.status;
              toast.success("MassVariable Type Updated Successfully", {containerId: 'toastMsg'});
              dispatch(updateInfo(massVariabletypesListrows));
      })
      .catch(error => {
           if(error.response.status == 403){
              localStorage.clear();
              toast.error(
                          "Session Expired ! Please login again", 
                            {
                              containerId: 'toastMsg',
                              onclose : () => window.location.href = "/"
                            }
                          );
           }else{
              toast.error(error.response.data.message, {containerId: 'toastMsg'});
           }

           dispatch(updateInfo(massVariabletypesListrows));
      })
  }
}
export const updateInfo = (massVariabletypesListrows) => {
  return{
      type : UPDATE_MASSVARIABLETYPE_INFO,
      massVariabletypesListrows : massVariabletypesListrows
  }
}