import React, {Component} from 'react';
import SGMateriaTable from '../../components/UI/Table/SGMaterialTable';
import {connect} from 'react-redux';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import CircularProgress from '@material-ui/core/CircularProgress';
import {withRouter} from 'react-router-dom';
import AddDepot from "./AddDepot";
import "react-datepicker/dist/react-datepicker.css";

import {getDepots, toggleDepotEditDialog,deleteDepot} from '../../actions/Depot'
import {
    getBusinessMenus
} from '../../actions/Sidebar';


class DepotList extends Component {
    // state = {
    // };

    // onChange = (date) => {
    //     let oldState = {...this.state}
    //     oldState.date = date;
    //     this.setState({...oldState})
    // }

    componentDidMount() {
        this.props.getBusinessMenus(this.props);
        this.props.getDepots();
    }

    createNewDepot = (url) => {
        this.props.history.push(url);
    }

    render() {
        let {
            depotSearchColumns,
            depots,
            showDataLoadSpinnerStatus,
            showDepotEditDialog
        } = this.props.depotState;
        let {
            menuItems
        } = this.props.sidebarState;

        let mainUrl;
        if (localStorage.getItem('role') === '1') {
            mainUrl = ((window.location.pathname).split('/'))[6];
        }
        if (localStorage.getItem('role') === '2' || localStorage.getItem('role') === '3' || localStorage.getItem('role') === '4') {
            mainUrl = ((window.location.pathname).split('/'))[5];
        }
        if (localStorage.getItem('role') === '5' || localStorage.getItem('role') === '6' || localStorage.getItem('role') === '7') {
            mainUrl = ((window.location.pathname).split('/'))[3];
        }
        return (
            <div className="container">
                <div className="mt-4 mb-5">
                    <Paper>
                        <Grid container spacing={24}
                              style={{paddingLeft: "1rem", paddingTop: "0", paddingRight: "1rem"}}>
                            <Grid item xs={6} style={{paddingTop: "0"}}>
                                <h4 style={{marginTop: "1rem", textTransform: "uppercase"}}>Depot List</h4>
                            </Grid>

                            <Grid item xs={6}
                                  style={{display: "flex", justifyContent: "flex-end", paddingBottom: "2rem"}}>
                                {
                                    mainUrl !== 'mtdriverlist' && mainUrl !== 'esdriverlist' ?
                                        <div>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={() => this.createNewDepot(menuItems['depot_create'].urlpattern)}
                                            >
                                                <i className="material-icons" style={{paddingRight: "0.5rem"}}>
                                                    add_circle_outline
                                                </i>
                                                Add Depot
                                            </Button>
                                        </div> : null
                                }
                            </Grid>
                        </Grid>
                        {
                            showDataLoadSpinnerStatus ?
                                <div style={{height: "30rem"}}
                                     className="d-flex justify-content-center align-items-center">
                                    <CircularProgress color="primary"/>
                                </div>
                                :
                                <SGMateriaTable
                                    title=""
                                    columns={depotSearchColumns}
                                    data={depots}
                                    actions={
                                        [
                                            {
                                                icon: 'edit',
                                                tooltip: 'Edit Depot',
                                                onClick: (event, rowData) => this.props.toggleDepotEditDialog(rowData, true)
                                            },
                                            {
                                                icon: 'delete',
                                                tooltip: 'Edit Delete',
                                                onClick: (event, rowData) => this.props.deleteDepot(rowData, true)
                                            }
                                        ]
                                    }
                                />
                        }
                    </Paper>
                </div>

                <Dialog
                    open={showDepotEditDialog}
                    onClose={() => {
                        this.props.toggleDepotEditDialog({}, false)
                    }}
                    maxWidth='md'
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">
                    <AddDepot />
                </Dialog>
            </div>
        )
    }

}

const mapStateToProps = state => {
    return {
        depotState: state.depots,
        sidebarState: state.sidebarState,
    }
}
const matDispatchToProps = (dispatch) => {
    return {
        getDepots: () => {
            return dispatch(getDepots())
        },
        getBusinessMenus: (props) => {
            return dispatch(getBusinessMenus(props))
        },
        toggleDepotEditDialog: (depot, showDialog) => {
            return dispatch(toggleDepotEditDialog(depot, showDialog))
        },
        deleteDepot: (depot, showDialog) => {
            if(window.confirm("Are you sure you want to delete this depot?")){
                return dispatch(deleteDepot(depot, showDialog))
            }
        }
    }
}
export default connect(mapStateToProps, matDispatchToProps)(withRouter(DepotList));
