import {
    SET_FATIGUE_RULE_TEXT_FIELD,
    SUBMIT_FATIGUE_RULE_TEXT_FIELD,
    SUBMIT_FATIGUE_RULE_INFO,
    UPDATE_FATIGUE_RULE_INFO,
    SET_FATIGUE_RULE_LIST,
    SET_DEFAULT_VALUE_TO_FATIGUE_RULE_COMPONENT
  } from "../actions/ActionType";
  import TextField from "@material-ui/core/TextField";
import React from 'react';
  const initialState = {
    fatigueRuleForm : {
      fatigue_rule : {
        elementType : 'input',
        elementConfig : {
          type : 'text',
          label : 'Enter Fatigue Rule Group Description'
        },
        value : '',
        errorStatus : false,
        errorMessage : "Enter Fatigue Rule",
        maxLength:50
      }
    },
    fatigueListcols: [
      { title: "Rule Group ID", field: "fatiguerulegroupid",
    editComponent: props => (
      <TextField
        type="text"
        value={props.value}
        disabled = {true}
      />
    ),
    cellStyle: {
      width : "18%",
    }, 
  },
    { title: "Description", 
      field: "description",
      editComponent: props => {
        initialState.activeAxelConfigDescription = props.value;
        return(
          <TextField
            type="text"
            multiline
            value={props.value}
            onChange = {e => props.onChange(e.target.value)}
            inputProps={{ maxLength: 50 }}
            style = {{width: "100%"}} 
          />
        )
      },
      cellStyle: {
        wordWrap: "break-word",
        maxWidth : '200px',
      },
    },
    {
      title: "Status",
      field: "current",
      lookup: {
        true: "Active",
        false: "Inactive"
      },
      cellStyle: {
        width : "10%",
      },
    }
    ],
    fatigueListrows: []
  };

  const setDefaultValueToFatigueRuleComponent = (state, action) => {
    Object.keys(state.fatigueRuleForm).map((inputField) => {
      state.fatigueRuleForm[inputField].value = '';
      state.fatigueRuleForm[inputField].errorStatus = false;
    })
    return state;
  }

  const setFatigueRuleTextField = (state, action) => {
    if(action.data === ''){
      state.fatigueRuleForm[action.textField].errorStatus = true;
    }
    state.fatigueRuleForm[action.textField].value = action.data;
    return state;
  }

  const submitFatigueRuleTextField = (state, action) => {
    return state;
  }

  const setFatigueRuleList = (state, action) => {
    state.fatigueListrows = action.fatigueRuleList;
    return state;
  }

  const submitFatigueRuleInfo = (state, action) => {
    Object.keys(state.fatigueRuleForm).map((textField) => {
      if(state.fatigueRuleForm[textField].value  == ''){
          state.fatigueRuleForm[textField].errorStatus = true;
      }else{
        state.fatigueRuleForm[textField].errorStatus = false;
      }
  })
    state.showLoaderStatus = action.showLoaderStatus;
    return state;
  }

  const updateFatigueRuleInfo = (state, action) => {
    state.fatigueListrows = action.fatigueListrows;
    return state;
  }

  
  const reducerFatigueRule = (state = initialState, action) => {
      let localState = Object.assign({}, state);
      switch (action.type) {
        case SET_DEFAULT_VALUE_TO_FATIGUE_RULE_COMPONENT : return setDefaultValueToFatigueRuleComponent(localState, action);
        case SET_FATIGUE_RULE_TEXT_FIELD: return setFatigueRuleTextField(localState, action);
        case SUBMIT_FATIGUE_RULE_TEXT_FIELD: return submitFatigueRuleTextField(localState, action);
        case SET_FATIGUE_RULE_LIST : return setFatigueRuleList(localState, action);
        case SUBMIT_FATIGUE_RULE_INFO: return submitFatigueRuleInfo(localState, action)
        case UPDATE_FATIGUE_RULE_INFO: return updateFatigueRuleInfo(localState, action);
        default:
          return localState;
      }
  }
  
  export default reducerFatigueRule