import {
    POST_GET_LOGIN_USER_INFO,
    POST_HANDLE_CHANGE_PASSWORD_INPUT_HANDLER,
    POST_CHANGE_PROFILE_PASSWORD,
    POST_GET_PROFILE_DETAILS_FOR_EDIT,
    POST_EDIT_PROFILE_INPUT_HANDLER,
    POST_ON_UPDATE_PROFILE_DETAILS,
    POST_INITIALIZE_CHAGNE_PASSWORD_FORM
} from "../actions/ActionType";
import {roles} from '../constants/roles';
import { isPartnerRole } from '../utils/RoleHelper';

  import {
    isObjectEmpty
  } from "../utils/Helper";
  const initialState = {
    changePasswordForm : {
      "current_password" : {
        elementType : 'input',
        elementConfig : {
          type : 'password',
          label : 'Current Password'
        },
        value : '',
        errorStatus : false,
        errorMessage : "Enter Current Password"
      },
      "new_password" : {
        elementType : 'input',
        elementConfig : {
          type : 'password',
          label : 'New Password'
        },
        value : '',
        errorStatus : false,
        errorMessage : "Enter New Password"
      },
      "confirm_password" : {
        elementType : 'input',
        elementConfig : {
          type : 'password',
          label : 'Confirm Password'
        },
        value : '',
        errorStatus : false,
        errorMessage : "Enter Confirm Password"
      }
    },
    profileForm : {
        "business_admin_name" : {
          elementType : 'input',
          elementConfig : {
            type : 'text',
            label : 'Name'
          },
          value : '',
          errorStatus : false,
          errorMessage : "Enter Name",
          role : "5",
          maxLength: 60
        },
        "business_admin_contact_name" : {
          elementType : 'input',
          elementConfig : {
            type : 'text',
            label : 'Contact Name'
          },
          value : '',
          errorStatus : false,
          errorMessage : "Enter Contact Name",
          role : "5",
          maxLength:60
        },
        "business_admin_contact_address" : {
          elementType : 'input',
          elementConfig : {
            type : 'text',
            label : 'Contact Address'
          },
          value : '',
          errorStatus : false,
          errorMessage : "Enter Contact Address",
          role : "5",
          maxLength:100
        },
        "business_admin_contact_email" : {
          elementType : 'input',
          elementConfig : {
            type : 'text',
            label : 'Contact Email'
          },
          value : '',
          errorStatus : false,
          errorMessage : "Enter Contact Email",
          role : "5",
          maxLength:60
        },
        "business_admin_abn" : {
          elementType : 'input',
          elementConfig : {
            type : 'text',
            label : 'ABN'
          },
          value : '',
          errorStatus : false,
          disabledStatus : true,
          errorMessage : "Enter ABN",
          role : "5",
          maxLength:11

        },
        "business_admin_contact_no" : {
          elementType : 'input',
          elementConfig : {
            type : 'text',
            label : 'Contact Number'
          },
          value : '',
          errorStatus : false,
          errorMessage : "Enter Contact Number",
          role : "5",
          maxLength:10
        },
        "business_admin_it_support_no" : {
          elementType : 'input',
          elementConfig : {
            type : 'text',
            label : 'IT Support Number'
          },
          value : '',
          errorStatus : false,
          errorMessage : "Enter IT Support Number",
          role : "5",
          maxLength:10
        },
        "business_admin_logo" : {
          elementType : 'upload_image',
          elementConfig : {
            type : 'text',
            label : 'Logo'
          },
          value : 'Logo',
          errorStatus : false,
          errorMessage : "Enter Logo",
          role : "5"
        },
        "business_admin_billing_option" : {
          elementType : 'select',
          elementConfig : {
              type : 'text',
              label : 'Billing Option'
          },
          value : '',
          errorStatus : false,
          errorMessage : "Select Country",
          menuItems : {
              'select_billing_option' : {
                  name : "Select Billing Option",
                  value : ""
              },
              'sg_bill' : {
                  name : "SG Bill",
                  value : "sg_bill"
              },
              'distributor_bill' : {
                  name : "Reseller Bill",
                  value : "distributor_bill"
              }
          },
          role : "5"
      },
      "business_admin_country" : {
        elementType : 'select',
        elementConfig : {
            type : 'text',
            label : 'Country'
        },
        value : 'AUS',
        errorStatus : false,
        errorMessage : "Select Country",
        menuItems : {
            'select_country' : {
                name : "Select Country",
                value : ''
            },
            'aus' : {
                name : "Australia",
                value : "AUS"
            }
        },
        role : "5"
    },
    "business_admin_select_invoice" : {
      elementType : 'select',
      elementConfig : {
          type : 'text',
          label : 'Select Invoice'
      },
      value : '',
      errorStatus : false,
      errorMessage : "Select Invoice",
      menuItems : {
          'select_invoice' : {
              name : 'Select Invoice',
              value : ''
          }
      },
      role : "5"
  },


        "business_editor_firstname" : {
          elementType : 'input',
          elementConfig : {
            type : 'text',
            label : 'First Name'
          },
          value : '',
          errorStatus : false,
          errorMessage : "Enter First Name",
          role : "6",
          maxLength:60
        },
        "business_editor_lastname" : {
          elementType : 'input',
          elementConfig : {
            type : 'text',
            label : 'Last Name'
          },
          value : '',
          errorStatus : false,
          errorMessage : "Enter Last Name",
          role : "6",
          maxLength:60
        },
        "business_editor_email" : {
          elementType : 'input',
          elementConfig : {
            type : 'text',
            label : 'Email'
          },
          value : '',
          errorStatus : false,
          errorMessage : "Enter Email",
          disabledStatus : true,
          role : "6",
          maxLength:60
        },
        "business_editor_role" : {
          elementType : 'input',
          elementConfig : {
            type : 'text',
            label : 'Role'
          },
          value : '',
          errorStatus : false,
          errorMessage : "Enter Role",
          disabledStatus : true,
          role : "6"
        },
        "business_reporter_firstname" : {
          elementType : 'input',
          elementConfig : {
            type : 'text',
            label : 'First Name'
          },
          value : '',
          errorStatus : false,
          errorMessage : "Enter First Name",
          role : "7",
          maxLength:60
        },
        "business_reporter_lastname" : {
          elementType : 'input',
          elementConfig : {
            type : 'text',
            label : 'Last Name'
          },
          value : '',
          errorStatus : false,
          errorMessage : "Enter Last Name",
          role : "7",
          maxLength:60
        },
        "business_reporter_email" : {
          elementType : 'input',
          elementConfig : {
            type : 'text',
            label : 'Email'
          },
          value : '',
          errorStatus : false,
          errorMessage : "Enter Email",
          disabledStatus : true,
          role : "7"
        },
        "business_reporter_role" : {
          elementType : 'input',
          elementConfig : {
            type : 'text',
            label : 'Role'
          },
          value : '',
          errorStatus : false,
          errorMessage : "Enter Role",
          disabledStatus : true,
          role : "7"
        },

        "distributor_user_firstname" : {
          elementType : 'input',
          elementConfig : {
            type : 'text',
            label : 'First Name'
          },
          value : '',
          errorStatus : false,
          errorMessage : "Enter First Name",
          role : "3",
          maxLength:60
        },
        "distributor_user_lastname" : {
          elementType : 'input',
          elementConfig : {
            type : 'text',
            label : 'Last Name'
          },
          value : '',
          errorStatus : false,
          errorMessage : "Enter Last Name",
          role : "3",
          maxLength:60
        },
        "distributor_user_email" : {
          elementType : 'input',
          elementConfig : {
            type : 'text',
            label : 'Email'
          },
          value : '',
          errorStatus : false,
          errorMessage : "Enter Email",
          disabledStatus : true,
          role : "3"
        },
        "distributor_user_role" : {
          elementType : 'input',
          elementConfig : {
            type : 'text',
            label : 'Role'
          },
          value : '',
          errorStatus : false,
          errorMessage : "Enter Role",
          disabledStatus : true,
          role : "3"
        },
        "distributor_user_reporter_firstname" : {
          elementType : 'input',
          elementConfig : {
            type : 'text',
            label : 'First Name'
          },
          value : '',
          errorStatus : false,
          errorMessage : "Enter First Name",
          role : "4",
          maxLength:60
        },
        "distributor_user_reporter_lastname" : {
          elementType : 'input',
          elementConfig : {
            type : 'text',
            label : 'Last Name'
          },
          value : '',
          errorStatus : false,
          errorMessage : "Enter Last Name",
          role : "4",
          maxLength:60
        },
        "distributor_user_reporter_email" : {
          elementType : 'input',
          elementConfig : {
            type : 'text',
            label : 'Email'
          },
          value : '',
          errorStatus : false,
          errorMessage : "Enter Email",
          disabledStatus : true,
          role : "4"
        },
        "distributor_user_reporter_role" : {
          elementType : 'input',
          elementConfig : {
            type : 'text',
            label : 'Role'
          },
          value : '',
          errorStatus : false,
          errorMessage : "Enter Role",
          disabledStatus : true,
          role : "4"
        },
        "name" : {
          elementType : 'input',
          elementConfig : {
            type : 'text',
            label : 'Name'
          },
          value : '',
          errorStatus : false,
          errorMessage : "Enter Name",
          role : "2",
          maxLength:60
        },
        "country" : {
            elementType : 'select',
            elementConfig : {
                type : 'number',
                label : 'Country'
            },
            value : 'AUS',
            errorStatus : false,
            errorMessage : "Select Country",
            menuItems : {
                'select_country' : {
                    name : "Select Country",
                    value : ''
                },
                'aus' : {
                    name : "Australia",
                    value : "AUS"
                }
            },
            role : "2"
        },
        "email" : {
            elementType : 'input',
            elementConfig : {
              type : 'text',
              label : 'Email'
            },
            value : '',
            errorStatus : false,
            errorMessage : "Enter Email",
            disabledStatus : true,
            role : "2",
            maxLength:60
          },
          "abn" : {
            elementType : 'input',
            elementConfig : {
              type : 'text',
              label : 'ABN'
            },
            value : '',
            errorStatus : false,
            errorMessage : "Enter ABN",
            disabledStatus : true,
            role : "2",
            maxLength:11
          },
          "myob" : {
            elementType : 'input',
            elementConfig : {
              type : 'text',
              label : 'MYOB'
            },
            value : '',
            errorStatus : false,
            errorMessage : "Enter MYOB",
            role : "2",
            maxLength:20
          },
          "distributor_address" : {
            elementType : 'input',
            elementConfig : {
              type : 'text',
              label : 'Address'
            },
            value : '',
            errorStatus : false,
            errorMessage : "Enter Address",
            role : "2",
            maxLength:100
          },
          "distributor_support_contactnumber" : {
            elementType : 'input',
            elementConfig : {
              type : 'text',
              label : 'Support Contact Number'
            },
            value : '',
            errorStatus : false,
            errorMessage : "Enter Support Contact Number",
            role : "2",
            maxLength:10
          },
          "distributor_support_email" : {
            elementType : 'input',
            elementConfig : {
              type : 'text',
              label : 'Support Email'
            },
            value : '',
            errorStatus : false,
            errorMessage : "Enter Support Email",
            role : "2",
            maxLength:60
          },
          "distributor_logo" : {
            elementType : 'upload_image',
            elementConfig : {
              type : 'text',
              label : 'Logo'
            },
            value : '',
            errorStatus : false,
            errorMessage : "Enter Logo",
            role : "2"
          },
        "user_first_name" : {
          elementType : 'input',
          elementConfig : {
            type : 'text',
            label : 'First Name'
          },
          value : '',
          errorStatus : false,
          errorMessage : "Enter Name",
          role : "2",
          maxLength:60
        },
        "user_last_name" : {
          elementType : 'input',
          elementConfig : {
            type : 'text',
            label : 'Last Name'
          },
          value : '',
          errorStatus : false,
          errorMessage : "Enter Name",
          role : "2",
          maxLength:60
        },
        "phone" : {
          elementType : 'input',
          elementConfig : {
            type : 'text',
            label : 'Phone No'
          },
          value : '',
          errorStatus : false,
          errorMessage : "Enter Phone number",
          role : "2",
          maxLength:20
        },
        "partner_name" : {
          elementType : 'input',
          elementConfig : {
            type : 'text',
            label : 'Name'
          },
          value : '',
          errorStatus : false,
          errorMessage : "Enter Name",
          maxLength:60
        },
        "partner_email" : {
          elementType : 'input',
          elementConfig : {
            type : 'text',
            label : 'Partner Email'
          },
          value : '',
          errorStatus : false,
          errorMessage : "Enter Email",
          disabledStatus : true,
          maxLength:128
        },
        "partner_phone" : {
          elementType : 'input',
          elementConfig : {
            type : 'text',
            label : 'Partner Phone No.'
          },
          value : '',
          errorStatus : false,
          errorMessage : "Enter Phone No.",
          maxLength:20
        },
    },
      logedinUserinfo : {
        "business_admin_name" : {
          fieldName : "Name",
          value : "",
          role : "5"
        },
        "business_admin_abn" : {
          fieldName : "ABN",
          value : "",
          role : "5"
        },
        // "business_admin_contact_name" : {
        //   fieldName : "Contact Name",
        //   value : "",
        //   role : "5"
        // },
        "business_admin_contact_address" : {
          fieldName : "Contact Address",
          value : "",
          role : "5"
        },
        "business_admin_country" : {
          fieldName : "Country",
          value : "",
          role : "5"
        },
        "name" : {
          fieldName : "Name",
          value : "",
          role : '2'
        },
        "country" : {
          fieldName : "Country",
          value : "",
          role : '2'
        },
        "email" : {
          fieldName : "Email",
          value : "",
          role : '2'
        },
        "abn" : {
          fieldName : "ABN",
          value : "",
          role : '2'
        },
        // "myob" : {
        //   fieldName : "MYOB Number",
        //   value : "",
        //   role : '2'
        // },
        // "distributor_address" : {
        //   fieldName : "Address",
        //   value : "",
        //   role : '2'
        // },
        // "distributor_support_contactnumber" : {
        //   fieldName : "Support Contact Number",
        //   value : "",
        //   role : '2'
        // },
        // "distributor_support_email" : {
        //   fieldName : "Support Email",
        //   value : "",
        //   role : '2'
        // },
        "distributor_user_firstname" : {
          fieldName : "First Name",
          value : "",
          role : '3'
        },
        "distributor_user_lastname" : {
          fieldName : "Last Name",
          value : "",
          role : '3'
        },
        "distributor_user_email" : {
          fieldName : "Email",
          value : "",
          role : '3'
        },
        "distributor_user_role" : {
          fieldName : "Role",
          value : "",
          role : '3'
        },
        "distributor_user_reporter_firstname" : {
          fieldName : "First Name",
          value : "",
          role : '4'
        },
        "distributor_user_reporter_lastname" : {
          fieldName : "Last Name",
          value : "",
          role : '4'
        },
        "distributor_user_reporter_email" : {
          fieldName : "Email",
          value : "",
          role : '4'
        },
        "distributor_user_reporter_role" : {
          fieldName : "Role",
          value : "",
          role : '4'
        },


        "business_editor_firstname" : {
          fieldName : "First Name",
          value : "",
          role : '6'
        },
        "business_editor_lastname" : {
          fieldName : "Last Name",
          value : "",
          role : '6'
        },
        "business_editor_email" : {
          fieldName : "Email",
          value : "",
          role : '6'
        },
        "business_editor_role" : {
          fieldName : "Role",
          value : "",
          role : '6'
        },
        "business_reporter_firstname" : {
          fieldName : "First Name",
          value : "",
          role : '7'
        },
        "business_reporter_lastname" : {
          fieldName : "Last Name",
          value : "",
          role : '7'
        },
        "business_reporter_email" : {
          fieldName : "Email",
          value : "",
          role : '7'
        },
        "business_reporter_role" : {
          fieldName : "Role",
          value : "",
          role : '7'
        },
        "user_first_name" : {
          fieldName : "First Name",
          value : "",
        },
        "user_last_name" : {
          fieldName : "Last Name",
          value : "",
        },
        "partner_phone" : {
          fieldName : "Partner Phone",
        },
        "partner_email" : {
          fieldName : "Email",
        },
        "partner_name" : {
          fieldName : "Partner Name",
        }        
      },
      changePasswordLoaderStatus : false,
      profileDetailsLoaderStatus : false,
      updateProfileLoaderStatus : false,
      getProfileDetailsForEditLoaderStatus : false,
      profileDistributorLogoBase64 : '',
      profileBusinessLogoBase64 : ''
  };
  
  const profileReducer = (state = initialState, action) => {
    let localState = Object.assign({}, state);
    const role = localStorage.getItem('role');
    
    switch (action.type) {
      case POST_INITIALIZE_CHAGNE_PASSWORD_FORM :
        Object.keys(localState.changePasswordForm).map(inputField => {
          localState.changePasswordForm[inputField].errorStatus = false;
          localState.changePasswordForm[inputField].value = '';
        })
        return localState;
      case POST_ON_UPDATE_PROFILE_DETAILS:
        if(localStorage.getItem('role', '2')){
          if(state.profileForm['name'].value === ''){
            state.profileForm['name'].errorStatus = true;
          }else{
            state.profileForm['name'].errorStatus = false;
          }
          if(state.profileForm['country'].value === ''){
            state.profileForm['country'].errorStatus = true;
          }else{
            state.profileForm['country'].errorStatus = false;
          }
          if(state.profileForm['email'].value === ''){
            state.profileForm['email'].errorStatus = true;
          }else{
            state.profileForm['email'].errorStatus = false;
          }
          if(state.profileForm['abn'].value === ''){
            state.profileForm['abn'].errorStatus = true;
          }else{
            state.profileForm['abn'].errorStatus = false;
          }
          if(state.profileForm['myob'].value === ''){
            state.profileForm['myob'].errorStatus = true;
          }else{
            state.profileForm['myob'].errorStatus = false;
          }
        }

        if(localStorage.getItem('role', '3')){
          if(state.profileForm['distributor_user_firstname'].value === ''){
            state.profileForm['distributor_user_firstname'].errorStatus = true;
          }else{
            state.profileForm['distributor_user_firstname'].errorStatus = false;
          }
          if(state.profileForm['distributor_user_lastname'].value === ''){
            state.profileForm['distributor_user_lastname'].errorStatus = true;
          }else{
            state.profileForm['distributor_user_lastname'].errorStatus = false;
          }
        }

        if(localStorage.getItem('role', '4')){
          if(state.profileForm['distributor_user_reporter_firstname'].value === ''){
            state.profileForm['distributor_user_reporter_firstname'].errorStatus = true;
          }else{
            state.profileForm['distributor_user_reporter_firstname'].errorStatus = false;
          }
          if(state.profileForm['distributor_user_reporter_lastname'].value === ''){
            state.profileForm['distributor_user_reporter_lastname'].errorStatus = true;
          }else{
            state.profileForm['distributor_user_reporter_lastname'].errorStatus = false;
          }
        }

        if(localStorage.getItem('role', '5')){
          if(state.profileForm['business_admin_name'].value === ''){
            state.profileForm['business_admin_name'].errorStatus = true;
          }else{
            state.profileForm['business_admin_name'].errorStatus = false;
          }
          if(state.profileForm['business_admin_contact_address'].value === ''){
            state.profileForm['business_admin_contact_address'].errorStatus = true;
          }else{
            state.profileForm['business_admin_contact_address'].errorStatus = false;
          }
          if(state.profileForm['business_admin_country'].value === ''){
            state.profileForm['business_admin_country'].errorStatus = true;
          }else{
            state.profileForm['business_admin_country'].errorStatus = false;
          }
          if(state.profileForm['business_admin_abn'].value === ''){
            state.profileForm['business_admin_abn'].errorStatus = true;
          }else{
            state.profileForm['business_admin_abn'].errorStatus = false;
          }
        }

        if(localStorage.getItem('role', '6')){
          if(state.profileForm['business_editor_firstname'].value === ''){
            state.profileForm['business_editor_firstname'].errorStatus = true;
          }else{
            state.profileForm['business_editor_firstname'].errorStatus = false;
          }
          if(state.profileForm['business_editor_lastname'].value === ''){
            state.profileForm['business_editor_lastname'].errorStatus = true;
          }else{
            state.profileForm['business_editor_lastname'].errorStatus = false;
          }
        }
        if(localStorage.getItem('role', '7')){
          if(state.profileForm['business_reporter_firstname'].value === ''){
            state.profileForm['business_reporter_firstname'].errorStatus = true;
          }else{
            state.profileForm['business_reporter_firstname'].errorStatus = false;
          }
          if(state.profileForm['business_reporter_lastname'].value === ''){
            state.profileForm['business_reporter_lastname'].errorStatus = true;
          }else{
            state.profileForm['business_reporter_lastname'].errorStatus = false;
          }
        }
        localState.updateProfileLoaderStatus = action.updateProfileLoaderStatus;
        return localState;
        
      case POST_EDIT_PROFILE_INPUT_HANDLER :
        localState.profileForm[action.inputFieldId].value = action.data;
        if(localState.profileForm[action.inputFieldId].value !== ''){
          localState.profileForm[action.inputFieldId].errorStatus = false;
        }
        if(action.inputFieldId === 'business_admin_billing_option'){
          let menuItems = {};
          if(action.data === 'sg_bill'){
            menuItems = {
              'select_invoice' : {
                  name : 'Select Invoice',
                  value : ''
              },
              'sg_invoice' : {
                  name : 'SG Invoice',
                  value : 'sg_invoice'
              },
              'credit_card' : {
                  name : 'Credit Card',
                  value : 'credit_card'
              }
            }
          }else{
            menuItems = {
              'select_invoice' : {
                  name : 'Select Invoice',
                  value : ''
              },
              'dist_invoice' : {
                  name : 'Dist Invoice',
                  value : 'dist_invoice'
              },
              'direct_debit' : {
                  name : 'Direct Debit',
                  value : 'direct_debit'
              }
            }
          }
          localState.profileForm['business_admin_select_invoice'].menuItems = menuItems;
        }
        if(action.profileBusinessLogoBase64){
          localState.profileBusinessLogoBase64 = action.profileBusinessLogoBase64;
        }
        if(action.profileDistributorLogoBase64){
            localState.profileDistributorLogoBase64 = action.profileDistributorLogoBase64;
        }
        return localState;
      case POST_GET_PROFILE_DETAILS_FOR_EDIT :
        
        if (isPartnerRole(role)){
          Object.keys(action.profileDetailsForEdit).forEach(key => {
            localState.profileForm[key].value = action.profileDetailsForEdit[key];
            localState.profileForm[key].role = role;
          });
        }

        if(role === roles.DISTRIBUTOR_ADMIN){
          localState.profileForm['name'].value = action.profileDetailsForEdit['distributor_name'];
          localState.profileForm['email'].value = localStorage.getItem('Platform-User');
          localState.profileForm['country'].value = 'AUS';
          localState.profileForm['abn'].value = action.profileDetailsForEdit['abn'];
          localState.profileForm['myob'].value = action.profileDetailsForEdit['myob_account_number'];
          localState.profileForm['distributor_address'].value = action.profileDetailsForEdit['distributor_address'];
          localState.profileForm['distributor_support_contactnumber'].value = action.profileDetailsForEdit['distributor_support_contactnumber'];
          localState.profileForm['distributor_support_email'].value = action.profileDetailsForEdit['distributor_support_email'];
          localState.profileForm['distributor_logo'].value = "Logo";
        }
        if(role === roles.DISTRIBUTOR_EDITOR){
          if(!isObjectEmpty(action.profileDetailsForEdit)){
            localState.profileForm['distributor_user_firstname'].value = action.profileDetailsForEdit[0].user_first_name;
            localState.profileForm['distributor_user_lastname'].value = action.profileDetailsForEdit[0].user_last_name;
            localState.profileForm['distributor_user_email'].value = localStorage.getItem('Platform-User');
            localState.profileForm['distributor_user_role'].value = 'Reseller Editor';
          }
        }
        if(role === roles.DISTRIBUTOR_REPORTER){
          if(!isObjectEmpty(action.profileDetailsForEdit)){
            localState.profileForm['distributor_user_reporter_firstname'].value = action.profileDetailsForEdit[0].user_first_name;
            localState.profileForm['distributor_user_reporter_lastname'].value = action.profileDetailsForEdit[0].user_last_name;
            localState.profileForm['distributor_user_reporter_email'].value = localStorage.getItem('Platform-User');
            localState.profileForm['distributor_user_reporter_role'].value = 'Reseller Reporter';
            
          }
        }
        if(role === roles.BUSINESS_ADMIN){
          if(!isObjectEmpty(action.profileDetailsForEdit)){
            Object.keys(localState.profileForm).keys(inputField => {
              localState.profileForm[inputField].value = '';
            })
            if(action.profileDetailsForEdit.business_name){
              localState.profileForm['business_admin_name'].value = action.profileDetailsForEdit.business_name;
            }
            if(action.profileDetailsForEdit.business_contact_name){
              localState.profileForm['business_admin_contact_name'].value = action.profileDetailsForEdit.business_contact_name;
            }
            if(action.profileDetailsForEdit.business_contact_address){
              localState.profileForm['business_admin_contact_address'].value = action.profileDetailsForEdit.business_contact_address;
            }
            if(action.profileDetailsForEdit.abn){
              localState.profileForm['business_admin_abn'].value = action.profileDetailsForEdit.abn;
            }
            if(action.profileDetailsForEdit.business_billing_option){
              localState.profileForm['business_admin_billing_option'].value = action.profileDetailsForEdit.business_billing_option;
              let menuItems = {};
              if(action.profileDetailsForEdit.business_billing_option === 'sg_bill'){
                menuItems = {
                  'select_invoice' : {
                      name : 'Select Invoice',
                      value : ''
                  },
                  'sg_invoice' : {
                      name : 'SG Invoice',
                      value : 'sg_invoice'
                  },
                  'credit_card' : {
                      name : 'Credit Card',
                      value : 'credit_card'
                  }
                }
              }else{
                menuItems = {
                  'select_invoice' : {
                      name : 'Select Invoice',
                      value : ''
                  },
                  'dist_invoice' : {
                      name : 'Dist Invoice',
                      value : 'dist_invoice'
                  },
                  'direct_debit' : {
                      name : 'Direct Debit',
                      value : 'direct_debit'
                  }
                }
              }
              localState.profileForm['business_admin_select_invoice'].menuItems = menuItems;
              
            }
            if(action.profileDetailsForEdit.business_it_suport_number){
              localState.profileForm['business_admin_it_support_no'].value = action.profileDetailsForEdit.business_it_suport_number;
            }
            if(action.profileDetailsForEdit.business_contact_phone_number){
              localState.profileForm['business_admin_contact_no'].value = action.profileDetailsForEdit.business_contact_phone_number;
            }
            if(action.profileDetailsForEdit.business_sg_bill_type){
              localState.profileForm['business_admin_select_invoice'].value = action.profileDetailsForEdit.business_sg_bill_type;
            }
          }
        }
        if(role === roles.BUSINESS_EDITOR){
          if(!isObjectEmpty(action.profileDetailsForEdit)){
            localState.profileForm['business_editor_firstname'].value = action.profileDetailsForEdit[0].user_first_name;
            localState.profileForm['business_editor_lastname'].value = action.profileDetailsForEdit[0].user_last_name;
            localState.profileForm['business_editor_email'].value = localStorage.getItem('Platform-User');
            localState.profileForm['business_editor_role'].value = 'Business Editor';
          }
        }
        if(role === roles.BUSINESS_REPORTER){
          if(!isObjectEmpty(action.profileDetailsForEdit)){
            localState.profileForm['business_reporter_firstname'].value = action.profileDetailsForEdit[0].user_first_name;
            localState.profileForm['business_reporter_lastname'].value = action.profileDetailsForEdit[0].user_last_name;
            localState.profileForm['business_reporter_email'].value = localStorage.getItem('Platform-User');
            localState.profileForm['business_reporter_role'].value = 'Business Reporter';
          }
        }
        return localState;

      case POST_CHANGE_PROFILE_PASSWORD :
        localState.changePasswordLoaderStatus = action.changePasswordLoaderStatus;
        Object.keys(localState.changePasswordForm).map(inputField => {
          if(localState.changePasswordForm[inputField].value === ''){
            localState.changePasswordForm[inputField].errorStatus = true;
          }
        })
        return localState;
      case POST_HANDLE_CHANGE_PASSWORD_INPUT_HANDLER :
        localState.changePasswordForm[action.inputFieldId].value = action.data;
        if(localState.changePasswordForm[action.inputFieldId].value === ''){
          localState.changePasswordForm[action.inputFieldId].errorStatus = true;
        }else{
          localState.changePasswordForm[action.inputFieldId].errorStatus = false;
        }
        return localState;
      case POST_GET_LOGIN_USER_INFO :
        localState.profileDetailsLoaderStatus = action.profileDetailsLoaderStatus;
        
        switch (role){

          case roles.PARTNER_EDITOR:
          case roles.PARTNER_REPORTER:
          case roles.PARTNER_ADMIN:
            Object.keys(action.userInfo).forEach(key => {
              localState.logedinUserinfo[key].value = action.userInfo[key];
              localState.logedinUserinfo[key].role = role;
            });
            break;

          case roles.DISTRIBUTOR_ADMIN:
            localState.logedinUserinfo['name'].value = action.userInfo['distributor_name'];
            localState.logedinUserinfo['country'].value = action.userInfo['country'];
            localState.logedinUserinfo['email'].value = localStorage.getItem('Platform-User');
            localState.logedinUserinfo['abn'].value = action.userInfo['abn'];
            break;
          
          case roles.DISTRIBUTOR_REPORTER:
            if(!isObjectEmpty(action.userInfo)){
              localState.logedinUserinfo['distributor_user_reporter_firstname'].value = action.userInfo[0].user_first_name;
              localState.logedinUserinfo['distributor_user_reporter_lastname'].value = action.userInfo[0].user_last_name;
              localState.logedinUserinfo['distributor_user_reporter_email'].value = localStorage.getItem('Platform-User');
              localState.logedinUserinfo['distributor_user_reporter_role'].value = "Reseller Reporter";
            }
            break;

          case roles.DISTRIBUTOR_EDITOR:
            if(!isObjectEmpty(action.userInfo)){
              localState.logedinUserinfo['distributor_user_firstname'].value = action.userInfo[0].user_first_name;
              localState.logedinUserinfo['distributor_user_lastname'].value = action.userInfo[0].user_last_name;
              localState.logedinUserinfo['distributor_user_email'].value = localStorage.getItem('Platform-User');
              localState.logedinUserinfo['distributor_user_role'].value = "Reseller Editor";
            }
            break;

          case roles.BUSINESS_ADMIN:
            if(!isObjectEmpty(action.userInfo)){
              localState.logedinUserinfo['business_admin_name'].value = action.userInfo.business_name;
              localState.logedinUserinfo['business_admin_abn'].value = action.userInfo.abn;
              localState.logedinUserinfo['business_admin_contact_address'].value = action.userInfo.business_contact_address;
              localState.logedinUserinfo['business_admin_country'].value = action.userInfo.country;
            }
            break;

          case roles.BUSINESS_EDITOR:
            if(!isObjectEmpty(action.userInfo)){
              localState.logedinUserinfo['business_editor_firstname'].value = action.userInfo[0].user_first_name;
              localState.logedinUserinfo['business_editor_lastname'].value = action.userInfo[0].user_last_name;
              localState.logedinUserinfo['business_editor_email'].value = localStorage.getItem('Platform-User');
              localState.logedinUserinfo['business_editor_role'].value = "Business Editor";
            }
            break;

          case roles.BUSINESS_REPORTER:
            if(!isObjectEmpty(action.userInfo)){
              localState.logedinUserinfo['business_reporter_firstname'].value = action.userInfo[0].user_first_name;
              localState.logedinUserinfo['business_reporter_lastname'].value = action.userInfo[0].user_last_name;
              localState.logedinUserinfo['business_reporter_email'].value = localStorage.getItem('Platform-User');
              localState.logedinUserinfo['business_reporter_role'].value = "Business Reporter";
            }
            break;
        }
      
        return localState;
      default:
        return state;
    }
  };
  
  export default profileReducer;