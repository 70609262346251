import {
  SET_ACCESSLEVEL_TEXT_FIELD,
  SET_ACCESSLEVEL_LIST,
  SUBMIT_ACCESSLEVEL_INFO,
  UPDATE_ACCESSLEVEL_INFO,
  SET_DEFAULT_VALUE_TO_ACCESS_LEVEL
} from "./ActionType";
import axios from '../axios';
import { toast } from 'react-toastify';

export const initializeAccessLevel = () => {
  return (dispatch) => {
    return dispatch(setDefaultValueToAccessLevel())
  }
}
export const setDefaultValueToAccessLevel = () => {
  return{
    type : SET_DEFAULT_VALUE_TO_ACCESS_LEVEL
  }
}
/********************************************************************************* */
export const inputHandler = (textField, event) => {
  return dispatch => {
    return dispatch(setTextField(textField, event));
  };
};

export const setTextField = (textField, event) => {
  return {
    type: SET_ACCESSLEVEL_TEXT_FIELD,
    textField: textField,
    data: event.target.value
  };
};

/********************************************************************************************** */ 
/**************************************************************************************** */

export const onSubmit = (accessLevelForm, props) => {
    return (dispatch) => {
    let showLoaderStatus = false;
    let fieldValidationStatus = true;
    Object.keys(accessLevelForm).map((textField) => {
        if(accessLevelForm[textField].value  == ''){
            fieldValidationStatus = false;
        }
    })
    dispatch(setSubmittedData(showLoaderStatus));
    if(fieldValidationStatus){
        showLoaderStatus = true;
        dispatch(setSubmittedData(showLoaderStatus));
        let addAccessLevelReq = {};
        addAccessLevelReq["description"] = accessLevelForm['access_level'].value;
        addAccessLevelReq["current"] = true;
        return axios.post('/sg/accesslevel', addAccessLevelReq)
        .then(response => {
            showLoaderStatus = false;
            toast.success("Access Level Created Successfully", {
                                containerId: 'toastMsg',
                                onClose :  () => props.history.push('/sgadminportal/accesslevel')
                            }
                        );
            dispatch(setSubmittedData(showLoaderStatus));
        })
        .catch(error => {
            if(error.response.status == 403){
                showLoaderStatus = false;
                localStorage.clear();
                // toast.error("Session Expired ! Please login again", {containerId: 'toastMsg'});
                window.location.href = "/";
            }else{
                showLoaderStatus = false;
                toast.error(error.response.data.message, {containerId: 'toastMsg'});
                dispatch(setSubmittedData(showLoaderStatus)); 
            }
        })
    }

    }

}

export const setSubmittedData = (showLoaderStatus) => {
return {
  type: SUBMIT_ACCESSLEVEL_INFO,
  showLoaderStatus : showLoaderStatus
}
}
/************************************************************************/
export const getAccessLevel = () => {
  let vehicletype = {};
  let vehicleTypeList = [];
  return (dispatch) => {
      return axios.get('/sg/accesslevel')
      .then(response => {
        for(let i = 0; i < response.data.data.length; i++){
        vehicletype = {};
        vehicletype['accesslevelid'] = response.data.data[i].accesslevelid;
        vehicletype['description'] = response.data.data[i].description;
        vehicletype['current'] = response.data.data[i].current;
        vehicleTypeList.push(vehicletype);
        }
        dispatch(setVehicleTypeList(vehicleTypeList));
      })
      .catch(error => {
          if(error.response.status == 403){
              localStorage.clear();
              window.location.href = "/";
          }
      })
  }
}

export const setVehicleTypeList = (vehicleTypeList) => {
  return{
      type : SET_ACCESSLEVEL_LIST,
      vehicleTypeList : vehicleTypeList
  }
}

/************************************************************************************************************* */ 
export const editAccessLevel = (accessLevelInfo, accessLevelRows) => {
  let reqURL = '/sg/accesslevel/' + accessLevelInfo.accesslevelid;
  let updateReq = {};
  updateReq['description'] = accessLevelInfo.description;
  updateReq['current'] = accessLevelInfo.current=="true"?true:false;
  return (dispatch) => {
      return axios.put(reqURL, updateReq)
      .then(response => {
        const updateIndex = accessLevelRows.findIndex(d => {
            return d.accesslevelid === accessLevelInfo.accesslevelid;
        });
        accessLevelRows[updateIndex].description = accessLevelInfo.description;
        accessLevelRows[updateIndex].current = accessLevelInfo.current;
        toast.success("Access Level Updated Successfully", {containerId: 'toastMsg'});
        dispatch(updateInfo(accessLevelRows));
      })
      .catch(error => {
           if(error.response.status == 403){
              localStorage.clear();
              toast.error(
                          "Session Expired ! Please login again", 
                            {
                              containerId: 'toastMsg',
                              onclose : () => window.location.href = "/"
                            }
                          );
           }else{
              toast.error(error.response.data.message, {containerId: 'toastMsg'});
           }

           dispatch(updateInfo(accessLevelRows));
      })
  }
}
export const updateInfo = (accessLevelRows) => {
  return{
      type : UPDATE_ACCESSLEVEL_INFO,
      accessLevelRows : accessLevelRows
  }
}