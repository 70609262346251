import React, { Component } from 'react';
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import FormGroup from "@material-ui/core/FormGroup";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import CardHeader from "@material-ui/core/CardHeader";
import MaterialTable from 'material-table';
import CircularProgress from '@material-ui/core/CircularProgress';
import {connect} from "react-redux";

import Input from "../../../components/UI/Input/Input";
import {
    inputHandlerUploadBusiness,
    onbusinessUploadSubmit,
    initializeBusinessBulkUploadForm
} from "../../../actions/Business";

import {
    convertArrayToCSVDownload,
} from "../../../utils/Helper";

import SGMaterialTable from "../../../components/UI/Table/SGMaterialTable";

class BusinessBulkUpload extends Component{
    componentDidMount(){
        this.props.initializeBusinessBulkUploadForm();
    }
    downloadCSVFile = () =>{
        let data = [
            [
                'userfirstname', 
                'userlastname', 
                'accesslevel', 
                'useremailaddress'
            ]
          ];
          convertArrayToCSVDownload(data, 'business_bulk');

    }
    render(){
        let {
            uploadBusinessForms,
            bulkUploadedBusinesses,
            businessUploadStatusList,
            businessUploadStatusTableColumns,
            businessBulkUploadSubmitLoaderStatus
        } = this.props.businessState;
        const formElementArray = [];
        for(let key in uploadBusinessForms){
          formElementArray.push({
            id : key,
            config : uploadBusinessForms[key]
          })
        }
        let form = (
            <FormGroup row className = "pl-3 pr-1">
                <Grid container spacing={24}>
                    <Grid item xs={10} sm = {10}>
                        <form>
                            {
                                formElementArray.map(formElement => (
                                    <Input 
                                    key = {formElement.id}
                                    elementType={formElement.config.elementType}
                                    elementConfig={formElement.config.elementConfig}
                                    value={formElement.config.value}
                                    error = {formElement.config.errorStatus}
                                    helperText = {formElement.config.errorMessage}
                                    changed={(event) => this.props.inputHandlerUploadBusiness(formElement.id, event)}
                                    //   touched = {(event) => this.props.inputHandlerVehicleType(formElement.id, event )}
                                    />
                                ))
                            }
                        </form>
                    </Grid> 
                    <Grid item xs={2} sm = {2}>
                    {
                        businessBulkUploadSubmitLoaderStatus ? <CircularProgress color = "primary" /> :
                        <Button 
                            variant="contained" 
                            color = "primary"
                            className = "mt-4"
                            onClick = {() => this.props.onbusinessUploadSubmit(bulkUploadedBusinesses, uploadBusinessForms)}
                            >
                            Submit
                        </Button>
                    }
                    </Grid>     
                </Grid>
            </FormGroup>
        )
        return(
            <div className = "container">

                <Grid container spacing={24}>
                    <Grid item xs={1} sm={2} />
                        <Grid item xs={12} sm={8}>
                        <Card className = "pl-3 pr-3 pt-3">
                            <Grid container spacing={24}>
                                <Grid item xs={6} sm = {6}>
                                    <CardHeader
                                        title="Upload Business"
                                    />
                                </Grid>                                
                                <Grid item xs={6} sm = {6} style = {{marginTop : "-1rem", display : "flex", justifyContent : "flex-end"}}>

                                    <Button 
                                        variant="contained" 
                                        color = "default"
                                        className = "mt-4 mb-4"
                                        onClick = {this.downloadCSVFile}
                                    >
                                        <i className="material-icons" style = {{paddingRight : "0.6rem"}}>
                                            cloud_download
                                        </i>
                                        Download sample csv
                                    </Button>
                                </Grid>
                            </Grid>

                            <CardContent>
                                {form}
                            </CardContent>
                            <CardActions>
                                
                            </CardActions>
                        </Card>
                        {
                            businessUploadStatusList.length > 0 ?
                            <div className = "mt-3">
                                <SGMaterialTable
                                    columns={businessUploadStatusTableColumns}
                                    data={businessUploadStatusList}
                                    title=""
                                />
                            </div>: null
                        }
                    </Grid>
                    <Grid item xs={1} sm={2} />
                </Grid>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return{
        businessState : state.business
    }
}

const mapDispatchToProps = dispatch => {
    return{
        inputHandlerUploadBusiness : (formElementId, event) => {
            return dispatch(inputHandlerUploadBusiness(formElementId, event))
        },
        onbusinessUploadSubmit : (bulkUploadedBusinesses, uploadBusinessForms) => {
            return dispatch(onbusinessUploadSubmit(bulkUploadedBusinesses, uploadBusinessForms))
        },
        initializeBusinessBulkUploadForm : () => {
            return dispatch(initializeBusinessBulkUploadForm())
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BusinessBulkUpload);