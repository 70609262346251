import {
    SET_SGADMINUSR_INPUT_FIELD,
    SET_SGADMINUSR_DEFAULT_VALUE,
    SET_SGADMINUSR_SUBMITTED_DATA,
    SET_SGADMINUSR_LIST_DEFAULT,
    UPDATE_SGADMINUSR_INFO,
    DELETE_SGADMINUSR_INFO
} from './ActionType';


import { toast } from 'react-toastify';
import {
    getActulaURL
} from "../utils/Helper";

import axios from "../axios";


export const initializeSGAdminUserComponent = () => {
    return (dispatch) => {
      return dispatch(setSGAdminUserDefault())
    }
}

export const initializeSGAdminUserListComponent = () => {
  return (dispatch) => {
    return dispatch(setSGAdminUserListDefault())
  }
}
  
export const setSGAdminUserDefault = () => {
    return {
      type: SET_SGADMINUSR_DEFAULT_VALUE
    }
}

export const onSubmit = (SGAdminUserForm, props) => {
  return (dispatch) => {
      let fieldValidationStatus = true;

      Object.keys(SGAdminUserForm).map((textField) => {
          if(SGAdminUserForm[textField].value  == ''){
              fieldValidationStatus = false;
          }
      })

      if(fieldValidationStatus) {
          dispatch(setSubmittedData(true));

          let addSGAdminUserReq = {};
          let showLoaderStatus = false;

          addSGAdminUserReq['user_id'] = SGAdminUserForm['sgadmin_user_name'].value;
          addSGAdminUserReq['password'] = SGAdminUserForm['sgadmin_user_password'].value;

          return axios.post('/sg/admin/user', addSGAdminUserReq)

          .then(response => {
                  toast.success("SG Admin User Created Successfully", {
                                    containerId: 'toastMsg',
                                    onClose :  () => props.history.push('/sgadminportal/sgadminuserlist')
                                    }
                                );
                  dispatch(setSubmittedData(showLoaderStatus));
          })
          .catch(error => {
            if(error.response.status == 403){
                  localStorage.clear();
                  window.location.href = "/";
              }else{
                  toast.error(error.response.data.message, {containerId: 'toastMsg'});
                  dispatch(setSubmittedData(false));
              }
          })
      }  
  }
}

export const setSubmittedData = (showLoaderStatus) => {
  return {
    type: SET_SGADMINUSR_SUBMITTED_DATA,
    showLoaderStatus: showLoaderStatus
  }
}

export const onFieldChange = (textField, event) => {
    return dispatch => {
      return dispatch(setFieldChange(textField, event));
    }
}

export const setFieldChange = (textField, event) => {
    return {
        type: SET_SGADMINUSR_INPUT_FIELD,
        textField: textField,
        data: event.target.value
    }
}

export const getSGAdminUsersList = () => {
    return(dispatch) => {
      let SGAdminUser = {};
      let sgAdminUserRowData = [];

      return axios.get('/sg/admin/users')
      .then(response => {
            for(let i = 0; i < response.data.data.length; i++){
              SGAdminUser = {};
              SGAdminUser['ID'] = response.data.data[i]._id;
              SGAdminUser['name'] = response.data.data[i].user_id;
              sgAdminUserRowData.push(SGAdminUser);
            }
            dispatch(setSGAdminUserListDefault(sgAdminUserRowData));
      })
      .catch(error => {
          if(error.response.status == 403){
              localStorage.clear();
              window.location.href = "/";
          }
      })
    }
}

export const setSGAdminUserListDefault = (sgAdminUserRowData) => {
  return {
    type: SET_SGADMINUSR_LIST_DEFAULT,
    sgAdminUserRowData: sgAdminUserRowData
  }
}

export const editSGAdminUser = (rowData, sgAdminUserRowData) => {
  let reqURL = '/sg/entrytypes/' + rowData.entrytypeid;
  let updateReq = {};
  updateReq['name'] = rowData.name;
  updateReq['password'] = rowData.password;
  return (dispatch) => {
      return axios.put('SGAdminUser/' + rowData.ID, updateReq)
      .then(response => {
              const index = sgAdminUserRowData.findIndex(d => {
                  return d.ID === rowData.ID;
              });
              sgAdminUserRowData[index].name = rowData.name;
              sgAdminUserRowData[index].password = rowData.password;
              toast.success("External System Updated Successfully", {containerId: 'toastMsg'});
              dispatch(updateSGAdminUserInfo(sgAdminUserRowData));
      })
      .catch(error => {
           if(error.response.status == 403){
              localStorage.clear();
              toast.error(
                          "Session Expired ! Please login again", 
                            {
                              containerId: 'toastMsg',
                              onclose : () => window.location.href = "/"
                            }
                          );
           }else{
              toast.error(error.response.data.message, {containerId: 'toastMsg'});
           }

           dispatch(updateSGAdminUserInfo(sgAdminUserRowData));
      })
  }
}

export const updateSGAdminUserInfo = (sgAdminUserRowData) => {
  return{
      type : UPDATE_SGADMINUSR_INFO,
      sgAdminUserRowData : sgAdminUserRowData
  }
}


export const deleteSGAdminUser = (rowData, sgAdminUserRowData) => {
  return (dispatch) => {
    let reqPath = "/sg/admin/user/" + rowData.name;
    return axios.delete(reqPath)
    .then(response => {
        const index = sgAdminUserRowData.findIndex(d => {
            return d.ID === rowData.ID;
        });
        if (index >= 0) {
          sgAdminUserRowData.splice(index, 1);
        }

        toast.success("SG Admin User has been removed", {
            containerId: 'toastMsg'
        });
        dispatch(deleteSGAdminUserInfo(sgAdminUserRowData))
    })
    .catch( error => {
        toast.error(error.message, {containerId: 'toastMsg'});
        dispatch(deleteSGAdminUserInfo(sgAdminUserRowData))
    })
  }
}

export const deleteSGAdminUserInfo = (sgAdminUserRowData) => {
  return{
      type : DELETE_SGADMINUSR_INFO,
      sgAdminUserRowData : sgAdminUserRowData
  }
}
