/* In the future we should use QENV lib to manage this kind of build profiles, right now just uncomment relevent env section. */

//LOCAL  

// export const apiUrl = "http://localhost:9080/stepglobal/v1";
// export const xAPIKey = "rFF59UovdI7x3ZmIs4GPi4kBLIyG7lOT6NltWQa9";
// export const reportUrl = "http://localhost:9080/";

//QA
/*
export const apiUrl = "http://qa-ipv4.smartedriver.com:9080/stepglobal/v1";
export const xAPIKey = "rFF59UovdI7x3ZmIs4GPi4kBLIyG7lOT6NltWQa9";
export const reportUrl = "http://qa-ipv4.smartedriver.com:8000/";
*/

//PROD
//
export const apiUrl = "https://ewd2.smartedriver.com";
export const xAPIKey = "rFF59UovdI7x3ZmIs4GPi4kBLIyG7lOT6NltWQa9";
export const reportUrl = "https://report.smartedriver.com/";

//COMMON
export const supportUrl = "http://support.smartedriver.com";