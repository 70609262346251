//Vehicle
export const SUBMIT_VEHICLE_INFO = 'SUBMIT_VEHICLE_INFO';
export const UPDATE_VEHICLE_INFO = 'UPDATE_VEHICLE_INFO';
export const VEHICLE_TEXTFIELD_CHANGE = 'VEHICLE_TEXTFIELD_CHANGE';
export const VEHICLE_AXLE_TEXTFIELD_CHANGE = 'VEHICLE_AXLE_TEXTFIELD_CHANGE';
export const VEHICLE_CHECKBOX_CHANGE = 'VEHICLE_CHECKBOX_CHANGE';
export const VEHICLE_OPEN_DIALOG = 'VEHICLE_OPEN_DIALOG';
export const VEHICLE_CLOSE_DIALOG = 'VEHICLE_CLOSE_DIALOG';
export const SUBMIT_VEHCILE_TYPE_RULE_TEXT_FIELD = 'SUBMIT_VEHCILE_TYPE_RULE_TEXT_FIELD';
export const SET_VEHCILE_TYPE_RULE_TEXT_FIELD = 'SET_VEHCILE_TYPE_RULE_TEXT_FIELD';
export const SET_CHECKBOX_VALUE = "SET_CHECKBOX_VALUE";
export const SET_UPLOAD_INPUT_CHANGE = "SET_UPLOAD_INPUT_CHANGE";
export const SET_VEHICLE_TYPES = "SET_VEHICLE_TYPES";
export const SET_VEHICLE_TYPES_FOR_LISTING = "SET_VEHICLE_TYPES_FOR_LISTING";
export const SET_VEHICLES = "SET_VEHICLES";
export const GET_ALL_AXEL_GROUP = "GET_ALL_AXEL_GROUP";
export const OPEN_MASS_CERTIFICATION = "OPEN_MASS_CERTIFICATION";
export const SET_UPDATE_VEHICLE_DATA = "SET_UPDATE_VEHICLE_DATA";
export const SET_ADD_VEHICLE_INITIAL_STATE = "SET_ADD_VEHICLE_INITIAL_STATE";
export const SET_INPUTHANDLER_UPLOAD_VEHICLE = "SET_INPUTHANDLER_UPLOAD_VEHICLE";
export const POST_VEHICLE_UPLOADED_DATA = "POST_VEHICLE_UPLOADED_DATA";
export const SET_DEFAULT_VALUE_TO_VEHICLE_UPLOAD_FORM = "SET_DEFAULT_VALUE_TO_VEHICLE_UPLOAD_FORM";
export const POST_HANDLE_VEHICLE_LICENSING_CHECKBOX_CHANGE = "POST_HANDLE_VEHICLE_LICENSING_CHECKBOX_CHANGE";

//Business
export const SUBMIT_BUSINESS_INFO = 'SUBMIT_BUSINESS_INFO';
export const UPDATE_BUSINESS_INFO = 'UPDATE_BUSINESS_INFO';
export const BUSINESS_TEXTFIELD_CHANGE = 'BUSINESS_TEXTFIELD_CHANGE';
export const BUSINESS_OPEN_DIALOG = 'BUSINESS_OPEN_DIALOG';
export const BUSINESS_CLOSE_DIALOG = 'BUSINESS_CLOSE_DIALOG';
export const BUSINESS_MODAL_OPEN = 'BUSINESS_MODAL_OPEN';
export const BUSINESS_MODAL_CLOSE = 'BUSINESS_MODAL_CLOSE';
export const SEARCH_BUSINESS_TEXTFIELD_CHANGE = 'SEARCH_BUSINESS_TEXTFIELD_CHANGE';
export const ON_SEARCH_BUSINESS = 'ON_SEARCH_BUSINESS';
export const GET_BUSINESS_DETAILS = 'GET_BUSINESS_DETAILS';
export const SET_SEARCH_BUSINESS_FOUND_STATUS_NULL = "SET_SEARCH_BUSINESS_FOUND_STATUS_NULL";
export const SET_INITIAL_VALUE_TO_ADD_DISTRIBUTOR_FORM = "SET_INITIAL_VALUE_TO_ADD_DISTRIBUTOR_FORM";
export const SET_EDIT_BUSINESS_OPEN_DIALOG_STATUS = "SET_EDIT_BUSINESS_OPEN_DIALOG_STATUS";
export const SET_EDIT_DRIVER_DIALOG_STATUS_TO_CLOSE = "SET_EDIT_DRIVER_DIALOG_STATUS_TO_CLOSE";
export const SET_BUSINESS_FIRST_LOGIN_STEPPER_VALUE = "SET_BUSINESS_FIRST_LOGIN_STEPPER_VALUE";
export const SET_BUSINESS_FIRST_LOGIN_STEPPER_BACK = "SET_BUSINESS_FIRST_LOGIN_STEPPER_BACK";
export const SET_BUSINESS_FIRST_LOGIN_CHANGE_PASSWORD_INPUT_FIELD = "SET_BUSINESS_FIRST_LOGIN_CHANGE_PASSWORD_INPUT_FIELD";
export const SET_BUSINESS_DETAILS_INPUT_FIELD = "SET_BUSINESS_DETAILS_INPUT_FIELD";
export const SET_BUSINESS_BANK_INFO_INPUT_FIELD = "SET_BUSINESS_BANK_INFO_INPUT_FIELD";
export const SET_DEFAULT_VALUE_TO_SEARCH_BUSINESS_COMPONENT = "SET_DEFAULT_VALUE_TO_SEARCH_BUSINESS_COMPONENT";
export const SET_BUSINESS_USER_INPUT_FIELD = "SET_BUSINESS_USER_INPUT_FIELD";
export const SET_SUBMITTED_BUSINESS_USER = "SET_SUBMITTED_BUSINESS_USER";
export const SET_DEFAULT_VALUE_TO_ADD_BUSINESS_USER = "SET_DEFAULT_VALUE_TO_ADD_BUSINESS_USER";
export const SET_DEFAULT_VALUE_FIRST_LOGIN_CHANGE_PASSWORD_FORM = "SET_DEFAULT_VALUE_FIRST_LOGIN_CHANGE_PASSWORD_FORM";
export const SET_FIRST_LOGIN_CHANGE_PASSWORD = "SET_FIRST_LOGIN_CHANGE_PASSWORD";
export const SET_TERM_N_CONDITION = "SET_TERM_N_CONDITION";
export const SET_BUSINESS_DETAILS = "SET_BUSINESS_DETAILS";
export const FINISH_BUSINESS_FIRST_LOGIN_STEPPER = "FINISH_BUSINESS_FIRST_LOGIN_STEPPER";
export const SET_BUSINESS_USER = "SET_BUSINESS_USER";
export const SET_BUSINESS_SELECT_MENU = "SET_BUSINESS_SELECT_MENU";
export const SET_BUSINESS_MENUS = "SET_BUSINESS_MENUS";
export const SET_UPDATED_BUSINESS_USER = "SET_UPDATED_BUSINESS_USER";
export const SET_INPUTHANDLER_UPLOAD_BUSINESS = "SET_INPUTHANDLER_UPLOAD_BUSINESS";
export const POST_BUSINESS_UPLOADED_DATA = "POST_BUSINESS_UPLOADED_DATA";
export const SET_DEFAULT_VALUE_TO_BUSINESS_BULK_UPLOAD_FORM = "SET_DEFAULT_VALUE_TO_BUSINESS_BULK_UPLOAD_FORM";
export const POST_TERM_N_CONDITION_REJECT = "POST_TERM_N_CONDITION_REJECT";
export const POST_ADD_BUSINESS_TAB_HANDLE_CHANGE="POST_ADD_BUSINESS_TAB_HANDLE_CHANGE";
export const POST_ADD_BUSINESS_CONTACT_NEXT="POST_ADD_BUSINESS_CONTACT_NEXT";
export const POST_ADD_BUSINESS_CONTACT_BACK="POST_ADD_BUSINESS_CONTACT_BACK";
export const POST_ADD_BUSINESS_ADMIN_BILLING_BACK="POST_ADD_BUSINESS_ADMIN_BILLING_BACK" ;
export const POST_ADD_BUSINESS_ADMIN_ON_SUBMIT_AFTER_CLOSE_TOASTR="POST_ADD_BUSINESS_ADMIN_ON_SUBMIT_AFTER_CLOSE_TOASTR";
export const POST_ADD_ADD_BUSINESS_CONTACT_INFO="POST_ADD_ADD_BUSINESS_CONTACT_INFO";
export const POST_HANDLE_ADD_BUSINESS_BILLING_FORM_FIELD = "POST_HANDLE_ADD_BUSINESS_BILLING_FORM_FIELD";
export const POST_ADD_BUSINESS_ADMIN_ON_SUBMIT="POST_ADD_BUSINESS_ADMIN_ON_SUBMIT"
export const POST_NAVIGATOR_TO_ADD_BUSINESS="POST_NAVIGATOR_TO_ADD_BUSINESS";
export const POST_HANDLER_EDIT_BUSINESS_DIALOG_OPEN="POST_HANDLER_EDIT_BUSINESS_DIALOG_OPEN"
export const POST_HANDLER_EDIT_BUSINESS_DIALOG_CLOSE="POST_HANDLER_EDIT_BUSINESS_DIALOG_CLOSE"
export const POST_NAVIGATE_TO_EDIT_BUSINESS_ADMIN="POST_NAVIGATE_TO_EDIT_BUSINESS_ADMIN";
export const POST_GET_BUSINESS_INFO_BY_ID="POST_GET_BUSINESS_INFO_BY_ID"
export const POST_ADD_BUSINESS_ADMIN_ON_UPDATE="POST_ADD_BUSINESS_ADMIN_ON_UPDATE";
export const POST_DELETE_ADD_BUSINESS_CONTACT_INFO = "POST_DELETE_ADD_BUSINESS_CONTACT_INFO";
export const POST_UPDATE_BUSINESS_CONTACT_INFO = "POST_UPDATE_BUSINESS_CONTACT_INFO";

// export const POST_ADD_BUSINESS_ADMIN_ON_SUBMIT_AFTER_CLOSE_TOASTR="POST_ADD_BUSINESS_ADMIN_ON_SUBMIT_AFTER_CLOSE_TOASTR"
//Login
export const MANAGE_LOGIN_STATUS = 'MANAGE_LOGIN_STATUS';
export const LOGIN_TEXTFIELD_CHANGE = 'LOGIN_TEXTFIELD_CHANGE';
export const ENABLE_DISABLE_SIGNIN='ENABLE_DISABLE_SIGNIN';
export const SET_CHANGE_PASSWORD_INPUT_FIELD = "SET_CHANGE_PASSWORD_INPUT_FIELD";
export const POST_NOT_A_USER = "POST_NOT_A_USER";
export const POST_SUBMIT_NOT_A_USER_DETAILS = "POST_SUBMIT_NOT_A_USER_DETAILS";
export const POST_HANDLE_NOT_A_USER_INPUT_FIELDS = "POST_HANDLE_NOT_A_USER_INPUT_FIELDS";

//Distributor Select
export const NAVIGATE_TO_BUSINESS = 'NAVIGATE_TO_BUSINESS';
export const NAVIGATE_TO_DRIVER = 'NAVIGATE_TO_DRIVER';
export const SET_DISTRIBUTOR_SELECT_DISTRIBUTOR_LIST = "SET_DISTRIBUTOR_SELECT_DISTRIBUTOR_LIST";
export const SET_ACTIVE_DISTRIBUTOR_INFO = "SET_ACTIVE_DISTRIBUTOR_INFO";

//Driver
export const SEARCH_DRIVER = 'SEARCH_DRIVER';
export const SEARCH_DRIVER_TEXTFIELD_CHANGE = 'SEARCH_DRIVER_TEXTFIELD_CHANGE';
export const SEARCH_DRIVER_DOB_CHANGE = 'SEARCH_DRIVER_DOB_CHANGE';
export const MANGE_LINK_DRIVER_DIALOG = 'MANGE_LINK_DRIVER_DIALOG';
export const MANAGE_LINK_DRIVER_DIALOG_CLOSE = 'MANAGE_LINK_DRIVER_DIALOG_CLOSE';
export const MANAGE_EDIT_DRIVER_OPEN_DIALOG = 'MANAGE_EDIT_DRIVER_OPEN_DIALOG';
export const MANAGE_EDIT_DRIVER_DIALOG_CLOSE = 'MANAGE_EDIT_DRIVER_DIALOG_CLOSE';
export const SUBMIT_DRIVER_INFO="SUBMIT_DRIVER_INFO";
export const DRIVER_SET_SEARCH_FOUND_STATUS_TO_NULL = 'DRIVER_SET_SEARCH_FOUND_STATUS_TO_NULL';
export const SET_ALL_DRIVER_LIST = "SET_ALL_DRIVER_LIST";
export const SET_ALL_TEXTFIELD_ENABLE = "SET_ALL_TEXTFIELD_ENABLE";
export const LINK_DRIVER_TO_BUSINESS = "LINK_DRIVER_TO_BUSINESS";
export const MAKE_DRIVER_TOAST_STATUS_NULL = 'MAKE_DRIVER_TOAST_STATUS_NULL';
export const SET_DEFAULT_VALUE_TO_ADD_DRIVER_COMPONENT = "SET_DEFAULT_VALUE_TO_ADD_DRIVER_COMPONENT";

export const SET_INPUTHANDLER_UPLOAD_DRIVER = "SET_INPUTHANDLER_UPLOAD_DRIVER";
export const POST_DRIVER_UPLOADED_DATA = "POST_DRIVER_UPLOADED_DATA";
export const SET_DEFAULT_VALUE_TO_SEARCH_DRIVER_COMPONENT = "SET_DEFAULT_VALUE_TO_SEARCH_DRIVER_COMPONENT";
export const SET_DEFAULT_VALUE_TO_DRIVER_BULK_UPLOAD_FORM = "SET_DEFAULT_VALUE_TO_DRIVER_BULK_UPLOAD_FORM";
export const SET_ADD_DRIVER_INPUT_FIELD = "SET_ADD_DRIVER_INPUT_FIELD";
export const SET_INPUT_DOB = "SET_INPUT_DOB";
export const SET_FATIGUE_TYPE_CHECKBOXES = "SET_FATIGUE_TYPE_CHECKBOXES";
export const SET_DRIVER_TYPE_CHECKBOXES = "SET_DRIVER_TYPE_CHECKBOXES";
export const POST_HANDLE_ADMIN_CHECKBOXES = "POST_HANDLE_ADMIN_CHECKBOXES";
export const POST_HANDLE_CONTRACTOR_CHECKBOXES = "POST_HANDLE_CONTRACTOR_CHECKBOXES";
export const SET_UPDATED_DRIVER_DATA = "SET_UPDATED_DRIVER_DATA";
export const POST_UNLINK_DRIVER = "POST_UNLINK_DRIVER";
export const POST_CLEAR_ALL_SEARCH_DRIVER_FIELD = "POST_CLEAR_ALL_SEARCH_DRIVER_FIELD";
export const POST_HANDLE_LICENSING_MODULE_CHECKBOXES = "POST_HANDLE_LICENSING_MODULE_CHECKBOXES";

//Entry Types
export const SET_ENTRY_RULE_TEXT_FIELD = 'SET_ENTRY_RULE_TEXT_FIELD';
export const SET_ENTRYTYPES_LIST = 'SET_ENTRYTYPES_LIST';
export const SUBMIT_ENTRYTYPE_INFO = 'SUBMIT_ENTRYTYPE_INFO';
export const UPDATE_ENTRYTYPE_INFO = 'UPDATE_ENTRYTYPE_INFO';
export const SET_DEFAULT_VALUE_TO_ENTRY_TYPE_COMPONENT = "SET_DEFAULT_VALUE_TO_ENTRY_TYPE_COMPONENT";

// Axel Config
export const SET_AXEL_CONFIG_TEXT_FIELD = 'SET_AXEL_CONFIG_TEXT_FIELD';
export const SUBMIT_AXEL_CONFIG = 'SUBMIT_AXEL_CONFIG';
export const SET_AXELCONFIG_LIST = 'SET_AXELCONFIG_LIST';
export const UPDATE_AXELCONFIG_INFO = 'UPDATE_AXELCONFIG_INFO';
export const SET_DEFAULT_VALUE_TO_AXEL_CONFIG_COMPONENT = "SET_DEFAULT_VALUE_TO_AXEL_CONFIG_COMPONENT";
export const SET_UPLOAD_INPUT_CHANGE_AXLE = "SET_UPLOAD_INPUT_CHANGE_AXLE";
// Access LEVEL
export const SET_ACCESSLEVEL_TEXT_FIELD = 'SET_ACCESSLEVEL_TEXT_FIELD';
export const SUBMIT_ACCESSLEVEL_TEXT_FIELD = 'SUBMIT_ACCESSLEVEL_TEXT_FIELD';
export const SET_ACCESSLEVEL_LIST = 'SET_ACCESSLEVEL_LIST';
export const UPDATE_ACCESSLEVEL_INFO = 'UPDATE_ACCESSLEVEL_INFO';
export const SUBMIT_ACCESSLEVEL_INFO = 'SUBMIT_ACCESSLEVEL_INFO';
export const SET_DEFAULT_VALUE_TO_ACCESS_LEVEL = "SET_DEFAULT_VALUE_TO_ACCESS_LEVEL";

//Fatigue Rules
export const SET_FATIGUE_RULE_TEXT_FIELD = 'SET_FATIGUE_RULE_TEXT_FIELD';
export const SUBMIT_FATIGUE_RULE_TEXT_FIELD = 'SUBMIT_FATIGUE_RULE_TEXT_FIELD';
export const SET_FATIGUE_RULE_LIST = 'SET_FATIGUE_RULE_LIST';
export const UPDATE_FATIGUE_RULE_INFO = 'UPDATE_FATIGUE_RULE_INFO';
export const SUBMIT_FATIGUE_RULE_INFO = 'SUBMIT_FATIGUE_RULE_INFO';
export const SET_DEFAULT_VALUE_TO_FATIGUE_RULE_COMPONENT = "SET_DEFAULT_VALUE_TO_FATIGUE_RULE_COMPONENT";

//Vehicle Types
export const SET_VEHCILE_TYPE_TEXT_FIELD = 'SET_VEHCILE_TYPE_TEXT_FIELD';
export const SET_VEHICLETYPE_LIST = 'SET_VEHICLETYPE_LIST';
export const SUBMIT_VEHICLETYPE_INFO = 'SUBMIT_VEHICLETYPE_INFO';
export const UPDATE_VEHICLETYPE_INFO = 'UPDATE_VEHICLETYPE_INFO';
export const SET_DEFAULT_VALUE_TO_VEHICLE_TYPES = "SET_DEFAULT_VALUE_TO_VEHICLE_TYPES";

//Measurement Types
export const SET_MEASUREMENT_TYPE_TEXT_FIELD = 'SET_MEASUREMENT_TYPE_TEXT_FIELD';
export const SET_MEASUREMENTTYPE_LIST = 'SET_MEASUREMENTTYPE_LIST';
export const SUBMIT_MEASUREMENTTYPE_INFO = 'SUBMIT_MEASUREMENTTYPE_INFO';
export const UPDATE_MEASUREMENTTYPE_INFO = 'UPDATE_MEASUREMENTTYPE_INFO';
export const SET_DEFAULT_VALUE_TO_MEASUREMENT_TYPES = "SET_DEFAULT_VALUE_TO_MEASUREMENT_TYPES";

//Mass Variable Types
export const SET_MASSVARIABLE_TYPE_TEXT_FIELD = 'SET_MASSVARIABLE_TYPE_TEXT_FIELD';
export const SET_MASSVARIABLETYPE_LIST = 'SET_MASSVARIABLETYPE_LIST';
export const SUBMIT_MASSVARIABLETYPE_INFO = 'SUBMIT_MASSVARIABLETYPE_INFO';
export const UPDATE_MASSVARIABLETYPE_INFO = 'UPDATE_MASSVARIABLETYPE_INFO';
export const SET_DEFAULT_VALUE_TO_MASSVARIABLE_TYPES = "SET_DEFAULT_VALUE_TO_MASSVARIABLE_TYPES";

//LOAD TYPES
export const SET_LOAD_TYPE_TEXT_FIELD = 'SET_LOAD_TYPE_TEXT_FIELD';
export const SET_LOADTYPE_LIST = 'SET_LOADTYPE_LIST';
export const SUBMIT_LOADTYPE_INFO = 'SUBMIT_LOADTYPE_INFO';
export const UPDATE_LOADTYPE_INFO = 'UPDATE_LOADTYPE_INFO';
export const SET_DEFAULT_VALUE_TO_LOAD_TYPES = "SET_DEFAULT_VALUE_TO_LOAD_TYPES";
export const MEASUREMENT_TYPE_LIST = "MEASUREMENT_TYPE_LIST";
export const MASSVARIABLETYPE_LIST = "MASSVARIABLETYPE_LIST";
export const LOADTYPE_OPEN_DIALOG = 'LOADTYPE_OPEN_DIALOG';
export const LOADTYPE_CLOSE_DIALOG = 'LOADTYPE_CLOSE_DIALOG';
export const SET_DEFAULT_VALUE_TO_ADD_LOADTYPE_FORM = "SET_DEFAULT_VALUE_TO_ADD_LOADTYPE_FORM";


//CONTRACT
export const SET_ITEM_CHECKBOX_LIST = "SET_ITEM_CHECKBOX_LIST";
export const SET_CONTRACT_ID = "SET_CONTRACT_ID";
export const SUBMIT_CONTRACT_INFO = 'SUBMIT_CONTRACT_INFO';
export const POST_HANDLE_ITEM_CHECKBOX_CHANGE = "POST_HANDLE_ITEM_CHECKBOX_CHANGE";

//MASS CERTIFICATE
export const SET_MASSCERTIFICATE_TEXT_FIELD = 'SET_MASSCERTIFICATE_TEXT_FIELD';
export const SET_MASSCERTIFICATE_LIST = 'SET_MASSCERTIFICATE_LIST';
export const SUBMIT_MASSCERTIFICATE_INFO = 'SUBMIT_MASSCERTIFICATE_INFO';
export const UPDATE_MASSCERTIFICATE_INFO = 'UPDATE_MASSCERTIFICATE_INFO';
export const SET_DEFAULT_VALUE_TO_MASSCERTIFICATES = "SET_DEFAULT_VALUE_TO_MASSCERTIFICATES";
export const SET_UPLOAD_INPUT_CHANGE_MC = "SET_UPLOAD_INPUT_CHANGE_MC";

//MassEvent
export const SET_MASSEVENT_TEXT_FIELD = 'SET_MASSEVENT_TEXT_FIELD';
export const SET_MASSEVENT_LIST = 'SET_MASSEVENT_LIST';
export const SUBMIT_MASSEVENT_INFO = 'SUBMIT_MASSEVENT_INFO';
export const UPDATE_MASSEVENT_INFO = 'UPDATE_MASSEVENT_INFO';
export const SET_DEFAULT_VALUE_TO_INITIALIZE_MASS_EVENT_COMPONENT = "SET_DEFAULT_VALUE_TO_INITIALIZE_MASS_EVENT_COMPONENT";

//AFM
export const SUBMIT_AFM_INFO = 'SUBMIT_AFM_INFO';
export const SET_FILE_SELECTED = "SET_FILE_SELECTED";
export const SET_DEFAULT_STATE_AFM = "SET_DEFAULT_STATE_AFM";

//BFM
export const SUBMIT_BFM_INFO = "SUBMIT_BFM_INFO";
export const SET_DEFAULT_STATE_BFM = "SET_DEFAULT_STATE_BFM";
export const SET_BFM_FILE_SELECTED = "SET_BFM_FILE_SELECTED";

//SFM
export const SUBMIT_SFM_INFO = "SUBMIT_SFM_INFO";
export const SET_SFM_FILE_SELECTED = "SET_SFM_FILE_SELECTED";
export const SET_DEFAULT_STATE_SFM = "SET_DEFAULT_STATE_SFM";

//Confirmation
export const MANAGE_CONFIRMATION_STATUS = 'MANAGE_CONFIRMATION_STATUS';
export const CONFIRMATION_TEXTFIELD_CHANGE = 'CONFIRMATION_TEXTFIELD_CHANGE';
export const ENABLE_DISABLE_CONFIRM='ENABLE_DISABLE_CONFIRM';

//Medical Treatment
export const SET_MEDICAL_TREATMENT_INPUT_FIELD = "SET_MEDICAL_TREATMENT_INPUT_FIELD";
export const SET_DEFAULT_VALUE_TO_SEARCH_MEDICAL_TREATMENT = "SET_DEFAULT_VALUE_TO_SEARCH_MEDICAL_TREATMENT";
export const SET_SUBMITED_MT_RECORD = "SET_SUBMITED_MT_RECORD";
export const SET_ADD_MT_RECORD_FORM_DATE_HANDLER = "SET_ADD_MT_RECORD_FORM_DATE_HANDLER";
export const SET_MEDICAL_TREATMENT_RECORDS = "SET_MEDICAL_TREATMENT_RECORDS";
export const SET_ADD_MT_RECORD_INPUT_HANDLER = "SET_ADD_MT_RECORD_INPUT_HANDLER";
export const SET_MT_LIST = "SET_MT_LIST";
export const SET_MT_TYPES = "SET_MT_TYPES";
export const SET_MT_RECORD_OPEN_DIALOG_STATUS = "SET_MT_RECORD_OPEN_DIALOG_STATUS";
export const SET_EDIT_MT_RECORD_CLOSE_DIALOG = "SET_EDIT_MT_RECORD_CLOSE_DIALOG";
export const SET_UPDATE_MT_RECORD = "SET_UPDATE_MT_RECORD";
export const OPEN_MEDICAL_TREATMENT_RECORD = "OPEN_MEDICAL_TREATMENT_RECORD";
export const SET_DEFAULT_VALUE_TO_ADD_MT_FORM = "SET_DEFAULT_VALUE_TO_ADD_MT_FORM";

//External Drivers
export const SET_EXTERNAL_DRIVER_INPUT_FIELD = "SET_EXTERNAL_DRIVER_INPUT_FIELD";
export const SET_DEFAULT_VALUE_TO_SEARCH_EXTERNAL_DRIVER = "SET_DEFAULT_VALUE_TO_SEARCH_EXTERNAL_DRIVER";
export const SET_SUBMITED_ED_RECORD = "SET_SUBMITED_ED_RECORD";
export const SET_EXTERNAL_DRIVER_RECORDS = "SET_EXTERNAL_DRIVER_RECORDS";
export const SET_ADD_ED_RECORD_INPUT_HANDLER = "SET_ADD_ED_RECORD_INPUT_HANDLER";
export const SET_ES_LIST = "SET_ES_LIST";
export const SET_ES_TYPES = "SET_ES_TYPES";
export const SET_ED_RECORD_OPEN_DIALOG_STATUS = "SET_ED_RECORD_OPEN_DIALOG_STATUS";
export const SET_ED_RECORD_DELETE = "SET_ED_RECORD_DELETE";
export const SET_EDIT_ED_RECORD_CLOSE_DIALOG = "SET_EDIT_ED_RECORD_CLOSE_DIALOG";
export const SET_UPDATE_ED_RECORD = "SET_UPDATE_ED_RECORD";
export const OPEN_EXTERNAL_DRIVER_RECORD = "OPEN_EXTERNAL_DRIVER_RECORD";
export const SET_DEFAULT_VALUE_TO_ADD_ED_FORM = "SET_DEFAULT_VALUE_TO_ADD_ED_FORM";

//Sidebar
export const SET_MENU_ITEMS = "SET_MENU_ITEMS";

//Profile
export const POST_GET_LOGIN_USER_INFO = "POST_GET_LOGIN_USER_INFO";
export const POST_HANDLE_CHANGE_PASSWORD_INPUT_HANDLER = "POST_HANDLE_CHANGE_PASSWORD_INPUT_HANDLER";
export const POST_CHANGE_PROFILE_PASSWORD = "POST_CHANGE_PROFILE_PASSWORD";
export const POST_GET_PROFILE_DETAILS_FOR_EDIT = "POST_GET_PROFILE_DETAILS_FOR_EDIT";
export const POST_EDIT_PROFILE_INPUT_HANDLER = "POST_EDIT_PROFILE_INPUT_HANDLER";
export const POST_ON_UPDATE_PROFILE_DETAILS = "POST_ON_UPDATE_PROFILE_DETAILS";
export const POST_INITIALIZE_CHAGNE_PASSWORD_FORM = "POST_INITIALIZE_CHAGNE_PASSWORD_FORM";

//Change Password
export const POST_HANDLE_PASSWORD_TEXTFIELD = "POST_HANDLE_PASSWORD_TEXTFIELD";
export const POST_CHANGE_USER_PASSWORD = "POST_CHANGE_USER_PASSWORD";

//Checklist
export const POST_HANDLE_OPEN_EDIT_CHECKLIST_ITEM_DIALOG = "POST_HANDLE_OPEN_EDIT_CHECKLIST_ITEM_DIALOG";
export const POST_HANDLE_CHECK_LIST_ITEM_DIALOG_CLOSE_STATUS = "POST_HANDLE_CHECK_LIST_ITEM_DIALOG_CLOSE_STATUS";
export const POST_HANDLE_ADD_CHECKLIST_ITEM_TEXTFIELD_CHANGE = "POST_HANDLE_ADD_CHECKLIST_ITEM_TEXTFIELD_CHANGE";
export const POST_INITIALIZE_ADD_CHECKLIST_ITEM_FORM = "POST_INITIALIZE_ADD_CHECKLIST_ITEM_FORM";
export const POST_HANDLE_ADD_TEMPLATE_TEXTFIELDS = "POST_HANDLE_ADD_TEMPLATE_TEXTFIELDS";
export const POST_GET_VEHICLES = "POST_GET_VEHICLES";
export const POST_GET_TEMPLATES = "POST_GET_TEMPLATES";
export const POST_UPDATE_TEMPLATE = "POST_UPDATE_TEMPLATE";
export const POST_ON_SUBMIT_TEMPLATE_CREATE = "POST_ON_SUBMIT_TEMPLATE_CREATE";
export const POST_GET_CHECK_ITEMS = "POST_GET_CHECK_ITEMS";
export const POST_ON_SUBMIT_ADD_CHECKLIST_ITEM = "POST_ON_SUBMIT_ADD_CHECKLIST_ITEM";
export const POST_ON_UPDATE_CHECK_ITEM = "POST_ON_UPDATE_CHECK_ITEM";
export const POST_INITIALIZE_ADD_TEMPLATE_FORM = "POST_INITIALIZE_ADD_TEMPLATE_FORM";
export const POST_GET_EXAMPLE_IMAGE = "POST_GET_EXAMPLE_IMAGE";
export const POST_GET_TRIGGERS = "POST_GET_TRIGGERS";
export const POST_HANDLE_TRIGGER_DROPDOWN = "POST_HANDLE_TRIGGER_DROPDOWN";
export const POST_HANDLE_CREATE_CHECKLIST_TEXFIELD = "POST_HANDLE_CREATE_CHECKLIST_TEXFIELD";
export const POST_GET_TEMPLATES_FOR_EXPANSION_PANEL = "POST_GET_TEMPLATES_FOR_EXPANSION_PANEL";
export const POST_HANDLE_TEMPLATE_EXPANSION_COLLAPSE = "POST_HANDLE_TEMPLATE_EXPANSION_COLLAPSE";
export const POST_MAP_TEMPLATE_TO_CHECKLIST = "POST_MAP_TEMPLATE_TO_CHECKLIST";
export const POST_HANDLE_CHECKITEM_CHECKBOX = "POST_HANDLE_CHECKITEM_CHECKBOX";
export const POST_INITIALIZE_CREATE_CHECKLIST = "POST_INITIALIZE_CREATE_CHECKLIST";
export const POST_ON_SUBMIT_CREATE_CHECKLIST = "POST_ON_SUBMIT_CREATE_CHECKLIST";
export const POST_GET_VEHICLE_BY_ID = "POST_GET_VEHICLE_BY_ID";
export const POST_HANDLE_ASSIGNMENTLIST_EXPANSION_COLLAPSE = "POST_HANDLE_ASSIGNMENTLIST_EXPANSION_COLLAPSE";
export const POST_MAP_CHECKLIST_TO_CHECKITEMS = "POST_MAP_CHECKLIST_TO_CHECKITEMS";
export const POST_INITIALIZE_LIST_ASSIGNED_CHECKLIST = "POST_INITIALIZE_LIST_ASSIGNED_CHECKLIST";
export const POST_ON_UPDATE_CHECKLIST = "POST_ON_UPDATE_CHECKLIST";
export const POST_ACTIVE_DELETE_CHECK_ITEM_OPTION = "POST_ACTIVE_DELETE_CHECK_ITEM_OPTION";
export const POST_DEACTIVE_DELETE_CHECKITEM_OPTION = "POST_DEACTIVE_DELETE_CHECKITEM_OPTION";
export const POST_HANDLE_CHECKITEM_CHECKBOX_FOR_DELETE = "POST_HANDLE_CHECKITEM_CHECKBOX_FOR_DELETE";
export const POST_DELETE_CHECKITEMS = "POST_DELETE_CHECKITEMS";
export const POST_GET_NOTIFICATION_DETAILS = "POST_GET_NOTIFICATION_DETAILS";
export const POST_HANDLE_NOTIFICATION_SETTING_FORMFIELD = "POST_HANDLE_NOTIFICATION_SETTING_FORMFIELD";
export const POST_ON_SUBMIT_NOTIFICATION_SETTING = "POST_ON_SUBMIT_NOTIFICATION_SETTING";

//Distributors
export const DISTRIBUTOR_TEXTFIELD_CHANGE = 'DISTRIBUTOR_TEXTFIELD_CHANGE';
export const SUBMIT_DISTRIBUTOR_INFO = 'SUBMIT_DISTRIBUTOR_INFO';
export const SET_DISTRIBUTOR_LIST = "SET_DISTRIBUTOR_LIST";
export const DISTRIBUTOR_CLOSE_DIALOG = 'DISTRIBUTOR_CLOSE_DIALOG';
export const UPDATE_DISTRIBUTOR_INFO = 'UPDATE_DISTRIBUTOR_INFO';
export const SELECT_DISTRIBUTOR_COUNTRY = 'SELECT_DISTRIBUTOR_COUNTRY';
export const SET_DEFAULT_VALUE_TO_ALL_FORM_FIELD = "SET_DEFAULT_VALUE_TO_ALL_FORM_FIELD";
export const SET_DISTRIBUTOR_USER_INPUT_FIELD = "SET_DISTRIBUTOR_USER_INPUT_FIELD";
export const SET_SUBMITTED_DISTRIBUTOR_USER = "SET_SUBMITTED_DISTRIBUTOR_USER";
export const SET_DEFAULT_VALUE_TO_ADD_DISTRIBUTOR_USER = "SET_DEFAULT_VALUE_TO_ADD_DISTRIBUTOR_USER";
export const SET_DISTRIBUTOR_USER_LIST = "SET_DISTRIBUTOR_USER_LIST";
export const SET_MENU_ITEMS_FOR_DISTRIBUTOR_SELECT = "SET_MENU_ITEMS_FOR_DISTRIBUTOR_SELECT";
export const SET_DISTRIBUTOR_MENU = "SET_DISTRIBUTOR_MENU";
export const SET_SG_MENUS = "SET_SG_MENUS";
export const SET_UPDATED_DISTRIBUTOR_USER = "SET_UPDATED_DISTRIBUTOR_USER"
export const SET_INPUTHANDLER_UPLOAD_DISTRIBUTOR = "SET_INPUTHANDLER_UPLOAD_DISTRIBUTOR";
export const POST_DISTRIBUTOR_UPLOADED_DATA = "POST_DISTRIBUTOR_UPLOADED_DATA";
export const SET_DEFAULT_VALUE_TO_DISTRIBUTOR_UPLOAD_FORM = "SET_DEFAULT_VALUE_TO_DISTRIBUTOR_UPLOAD_FORM";
export const POST_HANDLER_EDIT_DISTRIBUTOR_DIALOG_OPEN = "POST_HANDLER_EDIT_DISTRIBUTOR_DIALOG_OPEN";
export const POST_HANDLER_EDIT_DISTRIBUTOR_DIALOG_CLOSE = "POST_HANDLER_EDIT_DISTRIBUTOR_DIALOG_CLOSE";
export const POST_ADD_DISTRIBUTOR_TAB_HANDLE_CHANGE = "POST_ADD_DISTRIBUTOR_TAB_HANDLE_CHANGE";
export const POST_ADD_ADD_DISTRIBUTOR_CONTACT_INFO = "POST_ADD_ADD_DISTRIBUTOR_CONTACT_INFO";
export const POST_UPDATE_DISTRIBUTOR_CONTACT_INFO = "POST_UPDATE_DISTRIBUTOR_CONTACT_INFO";
export const POST_DELETE_ADD_DISTRIBUTOR_CONTACT_INFO = "POST_DELETE_ADD_DISTRIBUTOR_CONTACT_INFO";
export const POST_ADD_DISTRIBUTOR_CONTACT_NEXT = "POST_ADD_DISTRIBUTOR_CONTACT_NEXT";
export const POST_HANDLE_ADD_DISTRIBUTOR_BILLING_FORM_FIELD = "POST_HANDLE_ADD_DISTRIBUTOR_BILLING_FORM_FIELD";
export const POST_ADD_DISTRIBUTOR_ADMIN_ON_SUBMIT = "POST_ADD_DISTRIBUTOR_ADMIN_ON_SUBMIT";
export const POST_ADD_DISTRIBUTOR_CONTACT_BACK = "POST_ADD_DISTRIBUTOR_CONTACT_BACK";
export const POST_ADD_DISTRIBUTOR_ADMIN_BILLING_BACK = "POST_ADD_DISTRIBUTOR_ADMIN_BILLING_BACK";
export const POST_ADD_DISTRIBUTOR_ADMIN_ON_SUBMIT_AFTER_CLOSE_TOASTR = "POST_ADD_DISTRIBUTOR_ADMIN_ON_SUBMIT_AFTER_CLOSE_TOASTR";
export const POST_GET_DISTRIBUTOR_INFO_BY_ID = "POST_GET_DISTRIBUTOR_INFO_BY_ID";
export const POST_ADD_DISTRIBUTOR_ADMIN_ON_UPDATE = "POST_ADD_DISTRIBUTOR_ADMIN_ON_UPDATE";
export const POST_NAVIGATOR_TO_ADD_DISTRIBUTOR = "POST_NAVIGATOR_TO_ADD_DISTRIBUTOR";
export const POST_NAVIGATE_TO_EDIT_DISTRIBUTOR_ADMIN = "POST_NAVIGATE_TO_EDIT_DISTRIBUTOR_ADMIN";

// External System
export const EXTERNAL_SYSTEM_CLOSE_DIALOG = "EXTERNAL_SYSTEM_CLOSE_DIALOG";
export const SET_EXTERNAL_SYS_INPUT_FIELD = "SET_EXTERNAL_SYS_INPUT_FIELD";
export const SET_EXTERNAL_SYS_DEFAULT_VALUE = "SET_EXTERNAL_SYS_DEFAULT_VALUE";
export const SET_EXTERNAL_SYS_SUBMITTED_DATA = "SET_EXTERNAL_SYS_SUBMITTED_DATA";
export const SET_EXTERNAL_SYS_LIST_DEFAULT = "SET_EXTERNAL_SYS_LIST_DEFAULT";
export const UPDATE_EXTERNAL_SYS_INFO = "UPDATE_EXTERNAL_SYS_INFO";
export const DELETE_EXTERNAL_SYS_INFO = "DELETE_EXTERNAL_SYS_INFO";

// SG Admin User
export const SET_SGADMINUSR_INPUT_FIELD = "SET_SGADMINUSR_INPUT_FIELD";
export const SET_SGADMINUSR_DEFAULT_VALUE = "SET_SGADMINUSR_DEFAULT_VALUE";
export const SET_SGADMINUSR_SUBMITTED_DATA = "SET_SGADMINUSR_SUBMITTED_DATA";
export const SET_SGADMINUSR_LIST_DEFAULT = "SET_SGADMINUSR_LIST_DEFAULT";
export const UPDATE_SGADMINUSR_INFO = "UPDATE_SGADMINUSR_INFO";
export const DELETE_SGADMINUSR_INFO = "DELETE_SGADMINUSR_INFO";

// Maillog
export const SET_MAILLOG_DEFAULT_VALUE = "SET_MAILLOG_DEFAULT_VALUE";
export const SET_MAILLOG_LIST_DEFAULT = "SET_MAILLOG_LIST_DEFAULT";
export const MANAGE_VIEW_MAILLOG_DIALOG_CLOSE = "MANAGE_VIEW_MAILLOG_DIALOG_CLOSE";
export const MANAGE_VIEW_MAILLOG_OPEN_DIALOG = "MANAGE_VIEW_MAILLOG_OPEN_DIALOG";
export const SET_DEFAULT_VALUE_TO_SEARCH_MAILLOG_COMPONENT = "SET_DEFAULT_VALUE_TO_SEARCH_MAILLOG_COMPONENT";
export const POST_CLEAR_ALL_SEARCH_MAILLOG_FIELD = "POST_CLEAR_ALL_SEARCH_MAILLOG_FIELD";
export const SEARCH_MAILLOG_TEXTFIELD_CHANGE = "SEARCH_MAILLOG_TEXTFIELD_CHANGE";
export const SEARCH_MAILLOG_DATEFIELD_CHANGE = "SEARCH_MAILLOG_DATEFIELD_CHANGE";


// Potential Non Compliance
export const MANAGE_EDIT_POTENTIAL_NON_COMPLIANCE_OPEN_DIALOG = 'MANAGE_EDIT_POTENTIAL_NON_COMPLIANCE_OPEN_DIALOG';
export const MANAGE_EDIT_POTENTIAL_NON_COMPLIANCE_CLOSE_DIALOG = 'MANAGE_EDIT_POTENTIAL_NON_COMPLIANCE_CLOSE_DIALOG';

//Partners
export const GET_PARTNERS = 'GET_PARTNERS';
export const GET_PARTNERS_SUCCESS = 'GET_PARTNERS_SUCCESS';
export const GET_PARTNERS_FAIL = 'GET_PARTNERS_FAIL';

export const SAVE_PARTNER = 'SAVE_PARTNER';
export const SAVE_PARTNER_SUCCESS = 'SAVE_PARTNER_SUCCESS';
export const SAVE_PARTNER_FAIL = 'SAVE_PARTNER_FAIL';

export const GET_PARTNER_USERS_SUCCESS = 'GET_PARTNER_USERS_SUCCESS';
export const GET_PARTNER_USERS_FAIL = 'GET_PARTNER_USERS_FAIL';
export const GET_PARTNER_USERS = 'GET_PARTNER_USERS';


export const CREATE_PARTNER_USERS = 'CREATE_PARTNER_USERS';
export const CREATE_PARTNER_USERS_SUCCESS = 'CREATE_PARTNER_USERS_SUCCESS';
export const CREATE_PARTNER_USERS_FAIL = 'CREATE_PARTNER_USERS_FAIL';

export const SET_MENU_ITEMS_FOR_PARTNER_SELECT = "SET_MENU_ITEMS_FOR_PARTNER_SELECT";


export const SET_EMPTY_DEPOT_FORM_FIELDS = "SET_EMPTY_DEPOT_FORM_FIELDS";
export const SET_DEPOT_INPUT_FIELD = "SET_DEPOT_INPUT_FIELD";
export const LOAD_DEPOTS = "LOAD_DEPOTS";
export const EDIT_DEPOTS = "EDIT_DEPOTS";
export const DEPOT_CREATE = "DEPOT_CREATE";
export const DATA_LOAD_SPINNER = "DATA_LOAD_SPINNER";
export const DELETE_DEPOT = "DELETE_DEPOT";
export const TOGGLE_VIEW_DEPORT_DIALOG = "TOGGLE_VIEW_DEPORT_DIALOG";
export const TOGGLE_ADD_DEPORT_DIALOG = "TOGGLE_ADD_DEPORT_DIALOG";
export const SET_DEPOT_MAP_FIELD = "SET_DEPOT_MAP_FIELD";


