import {
  SET_MASSCERTIFICATE_TEXT_FIELD,
  SET_MASSCERTIFICATE_LIST,
  SUBMIT_MASSCERTIFICATE_INFO,
  UPDATE_MASSCERTIFICATE_INFO,
  SET_DEFAULT_VALUE_TO_MASSCERTIFICATES,
  SET_UPLOAD_INPUT_CHANGE_MC
} from "../actions/ActionType";
import TextField from "@material-ui/core/TextField";
import React from 'react';
import { apiUrl } from "../config";
import {
  downloadCert
} from "../utils/Helper";
import {
  getmassCertificateList,
} from '../actions/MassCertificate';
const initialState = {
  massCertificateForm: {
    mass_certificate_name: {
      elementType: 'input',
      elementConfig: {
        type: 'text',
        label: 'Certificate Name'
      },
      value: '',
      errorStatus: false,
      errorMessage: "Enter Certificate Name",
      maxLength: 256
    },
    certificate_image: {
      elementType: 'upload_image',
      elementConfig: {
        type: 'text',
        label: 'Certificate Image'
      },
      value: '',
      errorStatus: false,
      errorMessage: "Certificate Image not added",
      maxLength: 256
    }
  },
  activemassCertificateName: '',
  activemassCertificateImage: '',
  base64_string: '',
  massCertificateListcols: [
    {
      title: "Certificate Id",
      field: "_id",
      editComponent: props => (
        <TextField
          type="text"
          value={props.value}
          disabled={true}
        />
      ),
      cellStyle: {
        width: "15%",
      },
    },
    {
      title: "Certificate Name",
      field: "certificatename",
      editComponent: props => {
        initialState.activemassCertificateName = props.value;
        return (
          <TextField
            type="text"
            multiline
            value={props.value}
            onChange={e => props.onChange(e.target.value)}
            inputProps={{ maxLength: 256 }}
            style={{ width: "100%" }}
          />
        )
      },
      cellStyle: {
        wordWrap: "break-word",
        maxWidth: '200px',
      },
    },
    {
      title: "Certificate Image",
      field: "certificatedata",
      render: rowData =>
        <a
          href="javascript:void(0);"
          style={{ color: "blue" }}
          onClick={() => downloadCert(apiUrl + "/businesses/" + localStorage.getItem('business_id') + "/getobject?bucket=" + rowData.bucket + '&key=' + rowData.key)}
        >
          Download
        </a>,
      editComponent: props => (
        <TextField
            type="text"
            value="Download"
            // onChange={e => props.onChange(e.target.value)}
            inputProps={{ maxLength: 256 }}
            style={{ width: "100%" }}
            disabled={true}
          />
      ),
      cellStyle: {
        wordWrap: "break-word",
        maxWidth: '200px',
      },
    },
    {
      title: "Status",
      field: "status",
      lookup: {
        "true": "Active",
        "false": "Inactive"
      },
      cellStyle: {
        width: "10%",
      },
    }
  ],
  massCertificateListrows: [],

  //Loader Status
  listmassCertificateLoaderStatus: false,
  editMCDialogOpen: false,
  showMCUpdateLoaderStatus: false,
};
const setUploadInputChanges = (state, action) => {
  if (action.data === '' && action.textField === 'certificate_image') {
    state.massCertificateForm[action.textField].errorStatus = true;
  } else {
    state.massCertificateForm[action.textField].errorStatus = false;
  }
  state.massCertificateForm[action.textField].value = action.data;
  state.base64_string = action.base64;
  return state;
}
const handleDescription = (initialState, value) => {
  let localState = Object.assign({}, initialState);
  localState.activemassCertificateName = value;
  return localState;
}

const setDefaultValueTomassCertificateComponent = (state, action) => {
  Object.keys(state.massCertificateForm).map((inputField) => {
    state.massCertificateForm[inputField].value = '';
    state.massCertificateForm[inputField].errorStatus = false;
  })
  return state;
}

const setmassCertificateTextField = (state, action) => {
  if (action.data === '') {
    state.massCertificateForm[action.textField].errorStatus = true;
  }
  state.massCertificateForm[action.textField].value = action.data;
  return state;
}

const setmassCertificateList = (state, action) => {
  state.listmassCertificateLoaderStatus = action.listmassCertificateLoaderStatus;
  state.massCertificateListrows = action.massCertificateList;
  return state;
}

const submitmassCertificate = (state, action) => {
  state.massCertificateForm = action.massCertificateForm;
  state.showLoaderStatus = action.showLoaderStatus;
  state.massCertificateListrows = action.massCertificateListrows;
  return state;
}

const updateInfo = (state, action) => {
  state.massCertificateListrows = action.massCertificateListrows;
  return state;
}
const massCertificate = (state = initialState, action) => {
  let localState = Object.assign({}, state);
  switch (action.type) {
    case SET_DEFAULT_VALUE_TO_MASSCERTIFICATES: return setDefaultValueTomassCertificateComponent(localState, action);
    case SET_MASSCERTIFICATE_TEXT_FIELD: return setmassCertificateTextField(localState, action);
    case SET_MASSCERTIFICATE_LIST: return setmassCertificateList(localState, action);
    case SUBMIT_MASSCERTIFICATE_INFO: return submitmassCertificate(localState, action);
    case UPDATE_MASSCERTIFICATE_INFO: return updateInfo(localState, action);
    case SET_UPLOAD_INPUT_CHANGE_MC: return setUploadInputChanges(localState, action);
    default:
      return localState;
  }
};

export default massCertificate;
