import React, { Component } from 'react';
import MaterialTable from 'material-table';
import SGPaper from '../../../../components/UI/PaperContainer/PaperContainer';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { withRouter } from "react-router-dom";
import { toast } from 'react-toastify';
import {
    addAddDistributorContactInfo,
    deleteAddDistributorContactInfo,
    addDistributorContactBack, 
    addNewDistributor,
    submitUpdateDistributor
} from '../../../../actions/Distributor';

class Contact extends Component{
      render() {
        let {
          addDistributorContactinformations,
          addDistributorContactinfoColumns,
          addDistributorReqParams
        } = this.props.distributorState;

        let editDistributorSearchFoundStatus = (window.location.href).search('editdistributor');
        let editProfileSearchFoundStatus = (window.location.href).search('editprofile');

        return (
          <SGPaper
              title = "Contact List"
          >
            <MaterialTable
              title=""
              columns={addDistributorContactinfoColumns}
              data={addDistributorContactinformations}
              editable={{
                onRowAdd: newData =>
                  new Promise((resolve, reject) => {
                      let contactValidationStatus = true;
                      const data = addDistributorContactinformations;
                      if(!newData.first_name || !newData.last_name || !newData.contact_phone || !newData.contact_email){
                        contactValidationStatus = false;
                        toast.error("Please fill all the fields!", {containerId: 'toastMsg'});
                        reject();
                      }
                      if(newData.email){
                        if(!(newData.email).match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)){
                          contactValidationStatus = false;
                          toast.error("Please enter a valid email!", {containerId: 'toastMsg'});
                          reject();
                        }
                      }
                      if(contactValidationStatus){
                        data.push(newData);
                        resolve(this.props.addAddDistributorContactInfo(data))
                      }
                  }),
                onRowUpdate: (newData, oldData) =>
                  new Promise((resolve, reject) => {
                    setTimeout(() => {
                      {
                        const data = addDistributorContactinformations;
                        const index = data.indexOf(oldData);
                        data[index] = newData;
                        resolve(this.props.updateDistributorContactInfo(data))
                      }
                      resolve()
                    }, 1000)
                  }),
                onRowDelete: oldData =>
                  new Promise((resolve, reject) => {
                    setTimeout(() => {
                      {
                        let data = addDistributorContactinformations;
                        const index = data.indexOf(oldData);
                        data.splice(index, 1);
                        resolve(this.props.deleteAddDistributorContactInfo(data))
                      }
                      resolve()
                    }, 1000)
                  }),
              }}
            />
              <Grid
                  container
                  spacing={24}
                  className = "pt-2"
                  direction="row"
                  justify="flex-end"
                  alignItems="center"
              >
              <Grid item className = "d-flex justify-content-end">
                  {
                      editDistributorSearchFoundStatus != -1 || editProfileSearchFoundStatus != -1 ?
                              <Grid item className = "d-flex justify-content-end">
                                  <Button
                                      variant="contained"
                                      color="default"
                                      className = "mr-3"
                                      disabled={false}
                                      onClick = {() => {this.props.addDistributorContactBack()}}
                                  >
                                      Back
                                  </Button>
                                  <Button
                                      variant="contained"
                                      color="primary"
                                      disabled={false}
                                      onClick = {() => {this.props.addDistributorAdminOnUpdate(addDistributorReqParams, this.props, editProfileSearchFoundStatus)}}
                                  >
                                      Update
                                  </Button>
                              </Grid> :
                              <Grid item className = "d-flex justify-content-end">
                                  <Button
                                      variant="contained"
                                      color="default"
                                      className = "mr-3"
                                      disabled={false}
                                      onClick = {() => {this.props.addDistributorContactBack()}}
                                  >
                                      Back
                                  </Button>
                                  <Button
                                      variant="contained"
                                      color="primary"
                                      disabled={false}
                                      onClick = {() => {this.props.addDistributorAdminOnSubmit(addDistributorReqParams, this.props)}}
                                  >
                                      Submit
                                  </Button>
                              </Grid>
                  }
              </Grid>

            </Grid>
          </SGPaper>
        )
      }
}

const mapStateToProps = state => {
  return{
    distributorState : state.distributor
  }
}

const mapDispatchToProps = dispatch => {
  return{
    addAddDistributorContactInfo : (addDistributorContactinformations) => {
      return dispatch(addAddDistributorContactInfo(addDistributorContactinformations))
    },
    addDistributorAdminOnUpdate : (addDistributorReqParams) => {
       dispatch(submitUpdateDistributor(addDistributorReqParams));
    },
    deleteAddDistributorContactInfo : (addDistributorContactinformations) => {
      return dispatch(deleteAddDistributorContactInfo(addDistributorContactinformations))
    },
    addDistributorAdminOnSubmit : (addDistributorReqParams) => {
        return dispatch(addNewDistributor(addDistributorReqParams));
    },
    addDistributorContactBack : () => {
      return dispatch(addDistributorContactBack())
    }
  }
}
export default connect(mapStateToProps,mapDispatchToProps)(withRouter(Contact));
