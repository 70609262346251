import {
  SET_VEHCILE_TYPE_TEXT_FIELD,
  SET_VEHICLETYPE_LIST,
  SUBMIT_VEHICLETYPE_INFO,
  UPDATE_VEHICLETYPE_INFO,
  SET_DEFAULT_VALUE_TO_VEHICLE_TYPES
} from "../actions/ActionType";
import axios from '../axios';
import { toast } from 'react-toastify';

export const initializeVehicleTypes = () => {
  return (dispatch) => {
    return dispatch(setDefaultValueToVehicleTypes())
  }
}
export const setDefaultValueToVehicleTypes = () => {
  return{
    type : SET_DEFAULT_VALUE_TO_VEHICLE_TYPES
  }
}
/********************************************************************************* */
export const inputHandlerVehicleType = (textField, event) => {
  return dispatch => {
    return dispatch(setvehicletypesTextFields(textField, event));
  };
};

export const setvehicletypesTextFields = (textField, event) => {
  return {
    type: SET_VEHCILE_TYPE_TEXT_FIELD,
    textField: textField,
    data: event.target.value
  };
};

/********************************************************************************************** */ 
/**************************************************************************************** */

export const onSubmit = (vehicleTypeInfo, props) => {
return (dispatch) => {
  let showLoaderStatus = false;
  let fieldValidationStatus = true;

  Object.keys(vehicleTypeInfo.vehicleTypeForm).map((textField) => {
      if(vehicleTypeInfo.vehicleTypeForm[textField].value  == ''){
          fieldValidationStatus = false;
          vehicleTypeInfo.vehicleTypeForm[textField].errorStatus = true;
      }
  })
  dispatch(setSubmittedData(
    vehicleTypeInfo.vehicletypesListrows,
    showLoaderStatus,
    vehicleTypeInfo.vehicleTypeForm
    )
  );  
  if(fieldValidationStatus){
    showLoaderStatus = true;
      dispatch(setSubmittedData(
          vehicleTypeInfo.vehicletypesListrows,
          showLoaderStatus,
          vehicleTypeInfo.vehicleTypeForm
          )
      );
      let addVehicleTypeReq = {};
      let createdInfo = {};
      addVehicleTypeReq["description"] = vehicleTypeInfo.vehicleTypeForm['vechile_types'].value;
      addVehicleTypeReq["current"] = true;
      return axios.post('/sg/vehicletypes', addVehicleTypeReq)
      .then(response => {
              showLoaderStatus = false;
              createdInfo = {};
              createdInfo['vehicletypeid'] = response.data.data.vehicletypeid;
              createdInfo['description'] = response.data.data.description;
              createdInfo['current'] = response.data.data.current;
              vehicleTypeInfo.vehicletypesListrows.unshift(createdInfo);
              Object.keys(vehicleTypeInfo.vehicleTypeForm).map((textField) => {
                  vehicleTypeInfo.vehicleTypeForm[textField].value = '';
              })
              toast.success("VehicleType Created Successfully", {
                              containerId: 'toastMsg',
                              onClose :  () => props.history.push('/sgadminportal/vehicletypes')
                            }
                          );
              dispatch(setSubmittedData(
                          vehicleTypeInfo.vehicletypesListrows,
                          showLoaderStatus,
                          vehicleTypeInfo.vehicleTypeForm
                          )
                      );
      })
      .catch(error => {
          if(error.response.status == 403){
              showLoaderStatus = false;
              localStorage.clear();
              // toast.error("Session Expired ! Please login again", {containerId: 'toastMsg'});
              window.location.href = "/";
          }else{
              showLoaderStatus = false;
              toast.error(error.response.data.message, {containerId: 'toastMsg'});
              dispatch(setSubmittedData(
                          vehicleTypeInfo.vehicletypesListrows,
                          showLoaderStatus,
                          vehicleTypeInfo.vehicleTypeForm
                          )
                      ); 
          }
      })
  }

}

}

export const setSubmittedData = ( vehicletypesListrows, showLoaderStatus, vehicleTypeForm) => {
return {
  type: SUBMIT_VEHICLETYPE_INFO,
  vehicletypesListrows : vehicletypesListrows,
  showLoaderStatus : showLoaderStatus,
  vehicleTypeForm : vehicleTypeForm
}
}
/************************************************************************/
export const getVehicleTypeList = () => {
  let vehicletype = {};
  let vehicleTypeList = [];
  let vehicleTypeListLoaderStatus = true;
  return (dispatch) => {
    dispatch(setVehicleTypeList(vehicleTypeList, vehicleTypeListLoaderStatus));
      return axios.get('/sg/vehicletypes')
      .then(response => {
        vehicleTypeListLoaderStatus = false;
              for(let i = 0; i < response.data.data.length; i++){
                vehicletype = {};
                vehicletype['vehicletypeid'] = response.data.data[i].vehicletypeid;
                vehicletype['description'] = response.data.data[i].description;
                vehicletype['current'] = response.data.data[i].current;
                vehicleTypeList.push(vehicletype);
              }
              dispatch(setVehicleTypeList(vehicleTypeList, vehicleTypeListLoaderStatus));
      })
      .catch(error => {
        vehicleTypeListLoaderStatus = false;
          if(error.response.status == 403){
              localStorage.clear();
              window.location.href = "/";
          }
          
          dispatch(setVehicleTypeList(vehicleTypeList, vehicleTypeListLoaderStatus));
      })
  }
}

export const setVehicleTypeList = (vehicleTypeList, vehicleTypeListLoaderStatus) => {
  return{
      type : SET_VEHICLETYPE_LIST,
      vehicleTypeList : vehicleTypeList,
      vehicleTypeListLoaderStatus : vehicleTypeListLoaderStatus
  }
}

/************************************************************************************************************* */ 
export const editVehicleType = (vehicleTypeInfo, vehicletypesListrows) => {
  let reqURL = '/sg/vehicletypes/' + vehicleTypeInfo.vehicletypeid;
  let updateReq = {};
  updateReq['description'] = vehicleTypeInfo.description;
  updateReq['current'] = vehicleTypeInfo.current=="true"?true:false;
  return (dispatch) => {
      return axios.put(reqURL, updateReq)
      .then(response => {
              const updateIndex = vehicletypesListrows.findIndex(d => {
                  return d.vehicletypeid === vehicleTypeInfo.vehicletypeid;
              });
              vehicletypesListrows[updateIndex].description = vehicleTypeInfo.description;
              vehicletypesListrows[updateIndex].current = vehicleTypeInfo.current;
              toast.success("Vehicle Type Updated Successfully", {containerId: 'toastMsg'});
              dispatch(updateInfo(vehicletypesListrows));
      })
      .catch(error => {
           if(error.response.status == 403){
              localStorage.clear();
              toast.error(
                          "Session Expired ! Please login again", 
                            {
                              containerId: 'toastMsg',
                              onclose : () => window.location.href = "/"
                            }
                          );
           }else{
              toast.error(error.response.data.message, {containerId: 'toastMsg'});
           }

           dispatch(updateInfo(vehicletypesListrows));
      })
  }
}
export const updateInfo = (vehicletypesListrows) => {
  return{
      type : UPDATE_VEHICLETYPE_INFO,
      vehicletypesListrows : vehicletypesListrows
  }
}