import React, { Component } from "react";
import DateFnsUtils from "@date-io/date-fns";
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import NCReportTable from './NCReportTable';

import {
    MuiPickersUtilsProvider, DatePicker
} from 'material-ui-pickers';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    TimeScale,
    TimeSeriesScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import 'chartjs-adapter-moment';
const moment = require('moment');

ChartJS.register(
    CategoryScale,
    LinearScale,
    TimeScale,
    TimeSeriesScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

export default class NCReportChart extends Component {

    chartColours = {};

    chartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            x: {
                stacked: true,
                type: 'time',
                time: {
                    displayFormats: {
                        day: 'DD MMM',
                        hour: 'ddd h a'
                    },
                    tooltipFormat: 'DD MMM YYYY'
                }
            },
            y: {
                stacked: true,
            }
        },
        options: {
            interaction: {
                intersect: false,
                mode: 'index',
            },
        },
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'Potential non compliance events',
            },
        },
    }

    dateRange = { toDate: '', fromDate: '' };

    datePresets = [
        { name: 'Last 7 days', value: 'last7' },
        { name: 'Last 14 days', value: 'last14' },
        { name: 'Last 30 days', value: 'last30' },
        { name: 'Last 90 days', value: 'last90' },
        { name: 'Last year', value: 'lastY' },
        { name: 'Custom', value: 'custom' },
    ];

    state = {
        driverId: 'All',
        selectedDriverName: 'All',
        breachType: 'All',
        dateRangePreset: 'last30'
    }

    breachConditions = [
        'All',
        '5:30',
        '6:15',
        '8:00',
        '9:00',
        '11:00',
        '12:00',
        '24 hours',
        '52 hours',
        '82 hours',
        '7 Days',
        '14 Days',
        '28 Days'
    ]

    constructor(props) {
        super(props);
        this.chartColours = {};

        this.breachConditions.forEach((bc, i) => {
            const red = (50 + ( (i+1) * 25)) % 255;
            const green = Math.abs( (255 - (i * 25)) % 255);
            const blue = (1 + (((i*7) * 20))) % 255;
            this.chartColours[bc] = `rgba(${red}, ${green}, ${blue}, 0.6)`
        })
    }

    refreshData = (dateRange) => { };

    componentDidMount() {
        if (this.props.refreshData) {
            this.refreshData = this.props.refreshData;
        }
        let { fromDate, toDate } = this.props;
        this.dateRange.fromDate = fromDate;
        this.dateRange.toDate = toDate;
    }

    buildChartData() {
        const { breachData } = this.props;

        const breachesByDay = {};
        const labels = [];
        let chartDataSet = [];

        breachData.filter(x => this.state.driverId === 'All' || this.state.driverId === x.driver_id)
            .forEach(breach => {
                const date = moment(breach.breach_createdtime).format('YYYY-MM-DD');
                if (!breachesByDay[date] || !breachesByDay[date][breach.breach_type]) {
                    breachesByDay[date] = breachesByDay[date] || {};
                    breachesByDay[date] = { ...breachesByDay[date], [breach.breach_type]: 1 };
                    labels.push(date);
                } else {
                    breachesByDay[date][breach.breach_type]++;
                }
            });

        this.breachConditions.filter(x => this.state.breachType === 'All' || x === this.state.breachType)
            .forEach(bc => {
                if (bc === 'All') {
                    return;
                }
                let data = [];
                labels.forEach(l => {
                    data.push(breachesByDay[l][bc] || 0)
                });
                chartDataSet.push({
                    label: bc,
                    data: data,
                    backgroundColor: this.chartColours[bc]
                });
            });

        return {
            labels: labels,
            datasets: chartDataSet
        }
    }


    onChangeDate(key, value) {
        this.dateRange[key] = value;
        this.refreshData(this.dateRange);
    }

    handleTypeChange(key, value) {
        this.setState({ [key]: value });
    }

    handleDriverChange = (event) => {
        const driverId = event.target.value;
        const { breachData } = this.props;
        const driver = breachData.find(x => x.driver_id === driverId);
        const driverName = driver?.driverName || 'All';
        this.setState({ selectedDriverName: driverName, driverId: driverId });
    }

    handlePresetChange = (event) => {
        const selected = event.target.value;
        this.dateRange = { fromDate: '', toDate: moment() };
        switch (selected) {
            case 'last7':
                this.dateRange.fromDate = moment().add(-7, 'days');
                break;
            case 'last14':
                this.dateRange.fromDate = moment().add(-14, 'days');
                break;
            case 'last30':
                this.dateRange.fromDate = moment().add(-30, 'days');
                break;
            case 'last90':
                this.dateRange.fromDate = moment().add(-90, 'days');
                break;
            case 'lastY':
                this.dateRange.fromDate = moment().add(-1, 'years');
                break;
            default:
                this.dateRange.fromDate = moment().add(-30, 'days');
                break;
        }
        if (selected !== 'custom') {
            this.refreshData(this.dateRange);
        }
        this.setState({ dateRangePreset: selected });
    }

    render() {
        const chartData = this.buildChartData();
        const { breachData, driverList } = this.props;

        if (this.dateRange.toDate && this.dateRange.fromDate) {
            this.chartOptions.scales.x.min = this.dateRange.fromDate.toISOString();
            this.chartOptions.scales.x.max = this.dateRange.toDate.toISOString();
        }

        const driverIds = new Set();
        const driverNames = new Set();
        const duplicateNames = new Set();
        const drivers = [{ driverId: 'All', driverName: 'All drivers' }];
        driverList.forEach(b => {
            if (!driverIds.has(b.driver_id)) {
                driverIds.add(b.driver_id);
                let dName = b.driverName
                if (driverNames.has(b.driverName)){
                    duplicateNames.add(b.driverName);
                }
                driverNames.add(b.driverName);
                drivers.push({ driverId: b.driver_id, driverName: dName });
            }
        });
        
        // If multiple drivers exist with the same name - add their licence number to help differentiate
        Array.from(duplicateNames).forEach(dup => {
            driverList.filter(x => x.driverName === dup).forEach(detail =>{
                const driver = drivers.find(d => d.driverId === detail.driver_id);
                driver.driverName = `${detail.driverName} (License: ${detail.driver_license})`;
            });
        })

        const tableData = breachData
            .filter(x => (this.state.driverId === 'All' || this.state.driverId === x.driver_id) && (this.state.breachType === 'All' || x.breach_type === this.state.breachType));

        return (
            <div style={{ padding: '2rem' }}>
                <div style={{ 'paddingTop': '1rem', display: 'flex' }}>
                    <FormControl margin="normal" style={{ width: '180px', height: '63px', marginLeft: '1rem', marginRight: '1rem' }}>
                        <InputLabel htmlFor="dateRangePreset">Data period</InputLabel>
                        <Select
                            value={this.state.dateRangePreset}
                            input={<Input onChange={this.handlePresetChange} id="dateRangePreset" />}
                        >
                            {this.datePresets.map(preset => <MenuItem value={preset.value}>{preset.name}</MenuItem>)}
                        </Select>
                    </FormControl>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        {this.state.dateRangePreset === 'custom' ?
                            <div>
                                <DatePicker
                                    style={{ 'marginRight': '1rem' }}
                                    margin="normal"
                                    label="From"
                                    value={this.dateRange.fromDate}
                                    onChange={(v) => this.onChangeDate('fromDate', v)}
                                    format="dd/MM/yyyy"
                                    helperText="Data start"
                                />
                                <DatePicker
                                    margin="normal"
                                    label="To"
                                    value={this.dateRange.toDate}
                                    onChange={(v) => this.onChangeDate('toDate', v)}
                                    format="dd/MM/yyyy"
                                    helperText="Data end"
                                />
                            </div> : null}
                        <FormControl margin="normal" style={{ width: '180px', height: '63px', marginLeft: '1rem' }}>
                            <InputLabel htmlFor="breachType">Breach condition</InputLabel>
                            <Select
                                value={this.state.breachType}

                                input={<Input onChange={(e) => this.handleTypeChange('breachType', e.target.value)} id="breachType" />}
                            >
                                {this.breachConditions.map(breach => <MenuItem value={breach}>{breach}</MenuItem>)}
                            </Select>
                        </FormControl>
                        <FormControl margin="normal" style={{ width: '180px', height: '63px', marginLeft: '1rem' }}>
                            <InputLabel htmlFor="driverId">Driver name</InputLabel>
                            <Select
                                value={this.state.driverId}
                                input={<Input onChange={this.handleDriverChange} id="driverId" />}
                            >
                                {drivers.map(driver => <MenuItem value={driver.driverId}>{driver.driverName}</MenuItem>)}
                            </Select>
                        </FormControl>
                    </MuiPickersUtilsProvider>
                </div>
                <div class="chart-container" style={{ position: 'relative', height: '50vh', width: '100%', marginBottom: '2rem' }}>
                    { !chartData?.labels?.length ? <span style={{ top: '45%', left:'calc(50% - 130px)', position: 'absolute', fontSize: '2rem'}}>No data available</span> : null }
                    <Bar options={this.chartOptions} data={chartData} />
                </div>
                <NCReportTable breachData={tableData} />
            </div>
        )
    }
}