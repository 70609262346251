import React, { Component } from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import AddBusiness from './AddBusiness';
import Contact from './Contact';
import Billing from './Billing';

import { connect } from 'react-redux';

import {
  addBusinessTabHandleChange
} from "../../../../actions/Business";
const styles = theme => ({
    active_tabStyle:{
    color: 'white',
    fontWeight : 'bold',
    backgroundColor: '#3b3c3c'
   }
  });
  function TabContainer(props) {
    return (
      <Typography component="div" style={{ padding: 8 * 3 }}>
        {props.children}
      </Typography>
    );
  }

  TabContainer.propTypes = {
    children: PropTypes.node.isRequired,
  };
class AddBusinessAdmin extends Component{

      render() {

        const { classes } = this.props;
        let {
          addBusinessTabValue,
          addBusinessTabDisableStatus,
          businessAdminEditStatus,
        } = this.props.business;

        let editBusinessSearchFoundStatus = (window.location.href).search('editbusiness');
        let editProfileSearchFoundStatus = (window.location.href).search('editprofile');
        return (
          <div className = "container">
            <AppBar position="static">
              <Tabs
                value={addBusinessTabValue}
                onChange={this.props.addBusinessTabValueHandleChange}
                variant="fullWidth"
              >
                <Tab
                  label={editBusinessSearchFoundStatus != -1 ? "Edit Business" : editProfileSearchFoundStatus != -1 ? "Edit Profile" : "Add Business"}
                  className = {addBusinessTabValue === 0 ? classes.active_tabStyle :null}
                />
                <Tab
                  label="Contacts"
                  disabled = {addBusinessTabDisableStatus}
                  className = {addBusinessTabValue === 1 ? classes.active_tabStyle :null}
                />
                {/*<Tab*/}
                {/*  label="Billing"*/}
                {/*  disabled = {addBusinessTabDisableStatus}*/}
                {/*  className = {addBusinessTabValue === 2 ? classes.active_tabStyle :null}*/}
                {/*/>*/}
              </Tabs>
            </AppBar>
            {
                addBusinessTabValue === 0 && <AddBusiness />
            }
            {
                addBusinessTabValue === 1 && <Contact />
            }
            {
                addBusinessTabValue === 2 && <Billing />
            }
          </div>
        );
      }
}
AddBusiness.propTypes = {
    classes: PropTypes.object.isRequired,
  };
const mapStateToProps = state => {
  return{
    business : state.business
  }
}

const mapDispatchToprops = dispatch => {
    return{
        addBusinessTabHandleChange : (event, value) => {
          return dispatch(addBusinessTabHandleChange(event, value))
        }
  }
}

export default connect(mapStateToProps, mapDispatchToprops)(withStyles(styles)(AddBusinessAdmin));
