import {
    POST_GET_LOGIN_USER_INFO,
    POST_HANDLE_CHANGE_PASSWORD_INPUT_HANDLER,
    POST_CHANGE_PROFILE_PASSWORD,
    POST_GET_PROFILE_DETAILS_FOR_EDIT,
    POST_EDIT_PROFILE_INPUT_HANDLER,
    POST_ON_UPDATE_PROFILE_DETAILS,
    POST_INITIALIZE_CHAGNE_PASSWORD_FORM
} from "./ActionType";
import axios from "../axios";
import { toast } from 'react-toastify';
import {removeFakePath, convertToBase64, getFileExtension, getActulaURL, isObjectEmpty} from "../utils/Helper";
import { isPartnerRole } from '../utils/RoleHelper';
import { roles } from "../constants/roles";
import { history } from "../index";

export const initializechangePasswordForm = () => {
    return (dispatch) => {
        dispatch(postInitializechangePasswordForm());
    }
}
export const postInitializechangePasswordForm = () => {
    return{
        type : POST_INITIALIZE_CHAGNE_PASSWORD_FORM
    }
}
export const onUpdateProfileDetails = (profileForm, props, url, profileDistributorLogoBase64, profileBusinessLogoBase64) => {
    return (dispatch) => {
        let updateProfileLoaderStatus = true;
        let profileValidationStatus = true;
        let reqUrl = "";
        let reqParams = {};
        const role = localStorage.getItem('role');
        if (isPartnerRole(role)){
            reqUrl = getProfileUrl(role);
            dispatch(postOnUpdateProfileDetails(updateProfileLoaderStatus));
            if (role === roles.PARTNER_ADMIN){
                reqParams = {
                    name: profileForm['partner_name'].value,
                    email: profileForm['partner_email'].value,
                    phone: profileForm['partner_phone'].value
                };
            } else {
                reqParams = {
                    firstName: profileForm['user_first_name'].value,
                    lastName: profileForm['user_last_name'].value,
                    email: profileForm['email'].value,
                    accesslevel: Number(role)
                }
            }
        }

        if(role === roles.DISTRIBUTOR_EDITOR){
            if(profileForm['distributor_user_firstname'].value === '' || profileForm['distributor_user_lastname'].value === ''){
                profileValidationStatus = false;
                updateProfileLoaderStatus = false;
            }
            dispatch(postOnUpdateProfileDetails(updateProfileLoaderStatus))
            if(profileValidationStatus){
                reqUrl = "/distributor/"+localStorage.getItem('distributor_id')+"/users/"+localStorage.getItem('Platform-User');
                reqParams['userfirstname'] = profileForm['distributor_user_firstname'].value;
                reqParams['userlastname'] = profileForm['distributor_user_lastname'].value;
                reqParams['accesslevel'] = 3;
                reqParams['useremailaddress'] = localStorage.getItem('Platform-User');
                dispatch(postOnUpdateProfileDetails(updateProfileLoaderStatus))
            }
        }
        if(role === roles.DISTRIBUTOR_REPORTER){
            if(profileForm['distributor_user_reporter_firstname'].value === '' || profileForm['distributor_user_reporter_lastname'].value === ''){
                profileValidationStatus = false;
                updateProfileLoaderStatus = false;
            }
            dispatch(postOnUpdateProfileDetails(updateProfileLoaderStatus))
            if(profileValidationStatus){
                reqUrl = "/distributor/"+localStorage.getItem('distributor_id')+"/users/"+localStorage.getItem('Platform-User');
                reqParams['userfirstname'] = profileForm['distributor_user_reporter_firstname'].value;
                reqParams['userlastname'] = profileForm['distributor_user_reporter_lastname'].value;
                reqParams['accesslevel'] = 4;
                reqParams['useremailaddress'] = localStorage.getItem('Platform-User');
                dispatch(postOnUpdateProfileDetails(updateProfileLoaderStatus))
            }

        }
        if(role === roles.BUSINESS_ADMIN){
            if(
                profileForm['business_admin_name'].value === '' ||
                profileForm['business_admin_contact_address'].value === '' ||
                profileForm['business_admin_country'].value === '' ||
                profileForm['business_admin_abn'].value === ''
            ){
                profileValidationStatus = false;
                updateProfileLoaderStatus = false;
            }
            dispatch(postOnUpdateProfileDetails(updateProfileLoaderStatus))
            if(profileValidationStatus){
                reqUrl = "/businesses/"+localStorage.getItem('business_id');
                let businessLogoDetails = {};
                reqParams = {};
                if(profileBusinessLogoBase64 != ''){
                    let fileType = getFileExtension(profileForm['business_admin_logo'].value);
                    let mimeType = '';
                    if(fileType.toLowerCase() === 'jpeg'){
                        mimeType = 'application/jpeg';
                    }
                    if(fileType.toLowerCase() === 'jpg'){
                        mimeType = 'application/jpeg';
                    }
                    if(fileType.toLowerCase() === 'png'){
                        mimeType = 'image/png';
                    }
                    businessLogoDetails['base64_string'] = profileBusinessLogoBase64;
                    businessLogoDetails['file_type'] = mimeType;
                }
                reqParams['businessname'] = profileForm['business_admin_name'].value;
                reqParams['country'] = profileForm["business_admin_country"].value;
                reqParams['businesscontactname'] = profileForm['business_admin_contact_name'].value;
                reqParams['businesscontactaddress'] = profileForm['business_admin_contact_address'].value;
                //reqParams['businessbillingoption'] = profileForm['business_admin_billing_option'].value;
                reqParams['businesssgbilltype'] = profileForm['business_admin_select_invoice'].value;
                reqParams['businessitsupportno'] = profileForm['business_admin_it_support_no'].value;
                reqParams['businesscontactno'] = profileForm['business_admin_contact_no'].value;
                if(profileBusinessLogoBase64 != ''){
                    reqParams['businesslogo'] = businessLogoDetails;
                }
                dispatch(postOnUpdateProfileDetails(updateProfileLoaderStatus))
            }
        }
        if(role === roles.DISTRIBUTOR_ADMIN){
            if(
                profileForm['name'].value === '' ||
                profileForm["country"].value === '' ||
                profileForm["email"].value === '' ||
                profileForm["abn"].value === '' ||
                profileForm["myob"].value === ''
            ){
                profileValidationStatus = false;
                updateProfileLoaderStatus = false;
            }
            dispatch(postOnUpdateProfileDetails(updateProfileLoaderStatus))
            if(profileValidationStatus){
                reqUrl = "/dist/"+localStorage.getItem('distributor_id');
                let distributorLogoDetails = {};
                if(profileDistributorLogoBase64 != ''){
                    let fileType = getFileExtension(profileForm['distributor_logo'].value);
                    let mimeType = '';
                    if(fileType.toLowerCase() === 'jpeg'){
                        mimeType = 'application/jpeg';
                    }
                    if(fileType.toLowerCase() === 'jpg'){
                        mimeType = 'application/jpeg';
                    }
                    if(fileType.toLowerCase() === 'png'){
                        mimeType = 'image/png';
                    }
                    distributorLogoDetails['base64_string'] = profileDistributorLogoBase64;
                    distributorLogoDetails['file_type'] = mimeType;
                }
                reqParams['distributorname'] = profileForm['name'].value;
                reqParams['country'] = "AUS";
                reqParams['myobaccountnumber'] = profileForm['myob'].value;
                reqParams['distributorsupportnumber'] = profileForm['distributor_support_contactnumber'].value;
                reqParams['distributorsupportemail'] = profileForm['distributor_support_email'].value;
                reqParams['distributoraddress'] = profileForm['distributor_address'].value;
                if(profileDistributorLogoBase64 != ''){
                    reqParams['distributorlogo'] = distributorLogoDetails;
                }
                dispatch(postOnUpdateProfileDetails(updateProfileLoaderStatus))
            }
        }
        if(role === roles.BUSINESS_EDITOR){
            if(profileForm['business_editor_firstname'].value === '' || profileForm['business_editor_lastname'].value === ''){
                profileValidationStatus = false;
                updateProfileLoaderStatus = false;
            }
            dispatch(postOnUpdateProfileDetails(updateProfileLoaderStatus))
            if(profileValidationStatus){
                reqUrl = "/businesses/"+localStorage.getItem('business_id')+"/users/"+localStorage.getItem('Platform-User');
                reqParams['userfirstname'] = profileForm['business_editor_firstname'].value;
                reqParams['userlastname'] = profileForm['business_editor_lastname'].value;
                reqParams['accesslevel'] = 6;
                reqParams['useremailaddress'] = localStorage.getItem('Platform-User');
                dispatch(postOnUpdateProfileDetails(updateProfileLoaderStatus))
            }
        }
        if(role === roles.BUSINESS_REPORTER){
            if(profileForm['business_reporter_firstname'].value === '' || profileForm['business_reporter_lastname'].value === ''){
                profileValidationStatus = false;
                updateProfileLoaderStatus = false;
            }
            dispatch(postOnUpdateProfileDetails(updateProfileLoaderStatus))
            if(profileValidationStatus){
                reqUrl = "/businesses/"+localStorage.getItem('business_id')+"/users/"+localStorage.getItem('Platform-User');
                reqParams['userfirstname'] = profileForm['business_reporter_firstname'].value;
                reqParams['userlastname'] = profileForm['business_reporter_lastname'].value;
                reqParams['accesslevel'] = 7;
                reqParams['useremailaddress'] = localStorage.getItem('Platform-User');
                dispatch(postOnUpdateProfileDetails(updateProfileLoaderStatus))
            }
        }
        if(!isObjectEmpty(reqParams)){
            return axios.put(reqUrl, reqParams)
            .then(response => {
                updateProfileLoaderStatus = false;
                toast.success("Profile Updated successfully", {
                    containerId: 'toastMsg',
                });
                dispatch(postOnUpdateProfileDetails(updateProfileLoaderStatus));
                const updatedUrl = getActulaURL(url);
                history.push(updatedUrl);
            })
            .catch(error => {
                updateProfileLoaderStatus = false;
                toast.error(error.response.data.message, {
                    containerId: 'toastMsg',
                });
                dispatch(postOnUpdateProfileDetails(updateProfileLoaderStatus))
            })
        }
    }
}
export const postOnUpdateProfileDetails = (updateProfileLoaderStatus) => {
    return{
        type : POST_ON_UPDATE_PROFILE_DETAILS,
        updateProfileLoaderStatus : updateProfileLoaderStatus
    }
}
export const editProfileInputHandler = (inputFieldId, event) => {
    return (dispatch) => {
        let profileDistributorLogoBase64 = '';
        let profileBusinessLogoBase64 = '';
        let textFieldValue = event.target.value;
        if(inputFieldId === "business_admin_abn" || inputFieldId === "business_admin_contact_no" || inputFieldId === "business_admin_it_support_no" || inputFieldId === "abn" || inputFieldId === "myob" || inputFieldId === "distributor_support_contactnumber"){
            textFieldValue = (event.target.value).replace(/[^0-9]/g, '');
        }

        if(inputFieldId === 'business_admin_logo'){
            textFieldValue = removeFakePath(event.target.value);
            if(event.target.files !== null){
                convertToBase64(event.target.files[0]).then(data =>{
                    profileBusinessLogoBase64 = data;
                    if((((new Blob([profileBusinessLogoBase64]).size)/1024)/1024) > 1.5){
                        toast.error("File size too large", {containerId: 'toastMsg'});
                    }
                    return dispatch(postEditProfileInputHandler(inputFieldId, textFieldValue, profileDistributorLogoBase64, profileBusinessLogoBase64))
                });
            }
        }
        if(inputFieldId === 'distributor_logo'){
            textFieldValue = removeFakePath(event.target.value);
            if(event.target.files !== null){
                convertToBase64(event.target.files[0]).then(data =>{
                    profileDistributorLogoBase64 = data;
                    if((((new Blob([profileDistributorLogoBase64]).size)/1024)/1024) > 1.5){
                        toast.error("File size too large", {containerId: 'toastMsg'});
                    }
                    return dispatch(postEditProfileInputHandler(inputFieldId, textFieldValue, profileDistributorLogoBase64, profileBusinessLogoBase64))
                });
            }
        }else{
            return dispatch(postEditProfileInputHandler(inputFieldId, textFieldValue, profileDistributorLogoBase64, profileBusinessLogoBase64))
        }
    }
}
export const postEditProfileInputHandler = (inputFieldId, textFieldValue, profileDistributorLogoBase64, profileBusinessLogoBase64) => {
    return{
        type : POST_EDIT_PROFILE_INPUT_HANDLER,
        inputFieldId : inputFieldId,
        data : textFieldValue,
        profileDistributorLogoBase64 : profileDistributorLogoBase64,
        profileBusinessLogoBase64 : profileBusinessLogoBase64
    }
}

const getProfileUrl = (role) => {
    let reqUrl = '';
    if(role === roles.DISTRIBUTOR_ADMIN){
        reqUrl = "/dist/" + localStorage.getItem('distributor_id');
    }
    if(role === roles.DISTRIBUTOR_EDITOR || role === roles.DISTRIBUTOR_REPORTER){
        reqUrl = "/distributor/" + localStorage.getItem('distributor_id') + "/users/" + localStorage.getItem('Platform-User');
    }
    if(role === roles.BUSINESS_ADMIN){
        reqUrl = "/businesses/" + localStorage.getItem('business_id');
    }
    if(role === roles.BUSINESS_EDITOR || role === roles.BUSINESS_REPORTER){
        reqUrl = "/businesses/"+localStorage.getItem('business_id')+"/users/"+localStorage.getItem('Platform-User');
    }
    if (isPartnerRole(role)){
        if (role === roles.PARTNER_ADMIN){
            reqUrl = '/partner/admin/profile';
        } else {
            reqUrl = '/partner/users/profile';
        }
    }
    return reqUrl;
}

export const getProfileDetailsForEdit = () => {
    return (dispatch) => {
        const role = localStorage.getItem('role');
        let profileDetailsForEdit = {};
        const reqUrl = getProfileUrl(role);
        if(reqUrl){
            return axios.get(reqUrl)
            .then(response => {
                profileDetailsForEdit = response.data.data;
                dispatch(postGetProfileDetailsForEdit(profileDetailsForEdit))
            })
            .catch(error => {
                dispatch(postGetProfileDetailsForEdit(profileDetailsForEdit))
            })
        }

    }
}

export const postGetProfileDetailsForEdit = (profileDetailsForEdit) => {
    return{
        type : POST_GET_PROFILE_DETAILS_FOR_EDIT,
        profileDetailsForEdit : profileDetailsForEdit
    }
}

export const changeProfilePassword = (changePasswordForm,props, url) => {
    return (dispatch) => {
        let changePasswordFormValidationStatus = true;
        let changePasswordLoaderStatus = false
        Object.keys(changePasswordForm).map(inputField => {
            if(changePasswordForm[inputField].value === ''){
                changePasswordFormValidationStatus = false;
                changePasswordForm[inputField].errorStatus = true;
            }
        })
        if(changePasswordForm['new_password'].value !== changePasswordForm['confirm_password'].value){
            changePasswordFormValidationStatus = false;
            toast.error("New Password and Confirm Password did not match", {
                containerId: 'toastMsg',
            });
        }
        dispatch(postChangeProfilePassword(changePasswordLoaderStatus));
        if(changePasswordFormValidationStatus){
            changePasswordLoaderStatus = true
            let reqUrl = "/users/"+localStorage.getItem('Platform-User')+"/changepwd";
            let reqParams = {};
            reqParams['username'] = localStorage.getItem('Platform-User');
            reqParams['oldpassword'] = changePasswordForm['current_password'].value;
            reqParams['newpassword'] = changePasswordForm['new_password'].value;
            dispatch(postChangeProfilePassword(changePasswordLoaderStatus))
            return axios.post(reqUrl, reqParams)
            .then(response => {
                changePasswordLoaderStatus = false;
                toast.success("Password changed successfully ! Redirecting you to login page", {
                    containerId: 'toastMsg',
                    onClose : () => window.location.href = "/"
                });
                dispatch(postChangeProfilePassword(changePasswordLoaderStatus));
            })
            .catch(error => {
                changePasswordLoaderStatus = false;
                toast.error(error.response.data.message, {
                    containerId: 'toastMsg',
                });
                dispatch(postChangeProfilePassword(changePasswordLoaderStatus));
            })
        }
    }
}
export const postChangeProfilePassword = (changePasswordLoaderStatus) => {
    return{
        type : POST_CHANGE_PROFILE_PASSWORD,
        changePasswordLoaderStatus : changePasswordLoaderStatus
    }
}
export const handleChangePasswordInputHandler = (inputFieldId, event) => {
    return (dispatch) => {
        return dispatch(postHandleChangePasswordInputHandler(inputFieldId, event))
    }
}
export const postHandleChangePasswordInputHandler = (inputFieldId, event) => {
    return{
        type : POST_HANDLE_CHANGE_PASSWORD_INPUT_HANDLER,
        inputFieldId : inputFieldId,
        data : event.target.value
    }
}
export const getLoginUserInfo = () => {
    return (dispatch) => {
        let userInfo = {};
        let profileDetailsLoaderStatus = true;
        
        const role = localStorage.getItem('role');
        
        const reqUrl = getProfileUrl(role);
       
        dispatch(postGetLoginUserInfo(userInfo, profileDetailsLoaderStatus));
        if(reqUrl){
            return axios.get(reqUrl)
            .then(response => {
                profileDetailsLoaderStatus = false;
                userInfo = response.data.data;
                dispatch(postGetLoginUserInfo(userInfo, profileDetailsLoaderStatus));
            })
            .catch(error => {
                profileDetailsLoaderStatus = false;
                dispatch(postGetLoginUserInfo(userInfo, profileDetailsLoaderStatus));
            })
        }

    }
}

export const postGetLoginUserInfo = (userInfo, profileDetailsLoaderStatus) => {
    return{
        type : POST_GET_LOGIN_USER_INFO,
        userInfo : userInfo,
        profileDetailsLoaderStatus : profileDetailsLoaderStatus
    }
}
