import React, { Component } from 'react';
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import FormGroup from "@material-ui/core/FormGroup";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import CardHeader from "@material-ui/core/CardHeader";
import CircularProgress from '@material-ui/core/CircularProgress';
import {connect} from "react-redux";

import Input from "../../components/UI/Input/Input";
import {
    inputHandler,
    onSubmitAFM,
    onBlurTextfield,
    initializeState
} from "../../actions/AFM";

import {
  downloadAFMBFMSFMJSON,
} from "../../actions/Common";

class AFM extends Component{
    componentDidMount(){
        this.props.initializeState()
    }
    render(){
        let {
            uploadAFMForm,
            afmJSON,
            afmLoaderStatus
        } = this.props.AFM;
        const formElementArray = [];
        for(let key in uploadAFMForm){
          formElementArray.push({
            id : key,
            config : uploadAFMForm[key]
          })
        }
        let form = (
            <FormGroup row className = "pl-3 pr-1">
                <Grid container spacing={24}>
                    <Grid item xs={12} sm = {12}>
                        <form>
                            {
                                formElementArray.map(formElement => {
                                    if(formElement.id === "upload_afm"){
                                        return(
                                            <Input 
                                                key = {formElement.id}
                                                name = {formElement.config.elementConfig.label}
                                                elementType={formElement.config.elementType}
                                                elementConfig={formElement.config.elementConfig}
                                                value={formElement.config.value}
                                                menuItems = {formElement.config.menuItems}
                                                error = {formElement.config.errorStatus}
                                                helperText = {formElement.config.errorMessage}
                                                maxLength={formElement.config.maxLength}
                                                changed={(event) => this.props.inputHandler(formElement.id, event, afmJSON )}
                                            />
                                        )
                                    
                                    }else{
                                        return( <Input 
                                            key = {formElement.id}
                                            name = {formElement.config.elementConfig.label}
                                            elementType={formElement.config.elementType}
                                            elementConfig={formElement.config.elementConfig}
                                            value={formElement.config.value}
                                            menuItems = {formElement.config.menuItems}
                                            error = {formElement.config.errorStatus}
                                            helperText = {formElement.config.errorMessage}
                                            maxLength={formElement.config.maxLength}
                                            changed={(event) => this.props.inputHandler(formElement.id, event, afmJSON )}
                                            touched = {(event) => this.props.inputHandler(formElement.id, event, afmJSON )}
                                            /> )
                                    }
                                    
                            })
                            }
                            
                        </form>  
                    </Grid> 
                </Grid>
            </FormGroup>
        )
        return(
            <Grid container spacing={24}>
            <Grid item xs={1} sm={2} />
            <Grid item xs={11} sm={8}>
                <Card className = "pl-3 pr-3 pt-3 container">
                    <Grid container spacing={24}>
                        <Grid item xs={6} sm = {6}>
                            <CardHeader
                                title="Upload AFM"
                            />
                        </Grid>
                        <Grid item xs={6} sm = {6} style = {{marginTop : "-1rem", display : "flex", justifyContent : "flex-end"}}>
                            <Button 
                                variant="outlined" 
                                color = "default"
                                className = "mt-4 mb-4"
                                onClick = {() => downloadAFMBFMSFMJSON('AFM')} >
                                <i className="material-icons" style = {{paddingRight : "0.6rem"}}>
                                    cloud_download </i>
                                Download sample JSON
                            </Button>
                        </Grid>
                    </Grid>
                <CardContent>
                    <FormGroup row className = "pl-3 pr-1">
                        <Grid container spacing={24}>
                            <Grid item xs={12} sm = {12}>
                                {form}
                            </Grid>      
                        </Grid>
                        <Grid container spacing={24}>
                            <Grid item xs={10} sm = {10}>
                            </Grid> 
                            <Grid item xs={2} sm = {2}>
                                {
                                    afmLoaderStatus ? <CircularProgress color = "primary" /> :
                                    <Button 
                                        variant="contained" 
                                        color = "primary"
                                        className = "mt-4"
                                        type = "button"
                                        onClick = {() => this.props.onSubmitAFM(uploadAFMForm, afmJSON)}
                                        >
                                        Submit
                                    </Button>
                                }

                            </Grid>     
                        </Grid>
                    </FormGroup>
                </CardContent>
            </Card>
            </Grid>
            <Grid item xs={1} sm={2} />
          </Grid>

        )
    }
}

const mapStateToProps = state => {
    return{
        AFM : state.AFM
    }
}

const mapDispatchToProps = dispatch => {
    return{
        inputHandler : (formElementId, event, afmJSON) => {
            return dispatch(inputHandler(formElementId, event, afmJSON))
        },
        onSubmitAFM : (uploadAFMForm, afmJSON) => {
            return dispatch(onSubmitAFM(uploadAFMForm, afmJSON))
        },
        onBlurTextfield : (formElementId, event) => {
            return dispatch(onBlurTextfield(formElementId, event))
        },
        initializeState : () => {
            return dispatch(initializeState())
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AFM);