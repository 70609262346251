export const roles = Object.freeze({
        SG_ADMIN: '1',
        DISTRIBUTOR_ADMIN: '2',
        DISTRIBUTOR_EDITOR: '3',
        DISTRIBUTOR_REPORTER: '4',
        BUSINESS_ADMIN: '5',
        BUSINESS_EDITOR: '6',
        BUSINESS_REPORTER: '7',
        COMPLIANCE_OFFICER: '8',
        PARTNER_ADMIN: '23',
        PARTNER_EDITOR: '24',
        PARTNER_REPORTER: '25',
        DEPOT_MANAGER: '28',
});
