import {
    MANAGE_CONFIRMATION_STATUS,
    CONFIRMATION_TEXTFIELD_CHANGE,
    ENABLE_DISABLE_CONFIRM
} from '../actions/ActionType'

const initialState = {
    authenticationStatus: false,
    disableConfirm:true,
    showLoaderStatus : false,
    confirmTextField: {
        'email': {
            name: "email",
            type: 'text',
            icon: 'now-ui-icons users_circle-08',
            value: ''
        }
    },
   
}

const reducer = (state = initialState, action) => {
    let localState = Object.assign({}, state);
    switch (action.type) {
        case MANAGE_CONFIRMATION_STATUS:
            localState.authenticationStatus = action.data;
            localState.showLoaderStatus = action.showLoaderStatus;
            return localState;
        case CONFIRMATION_TEXTFIELD_CHANGE:
            localState.confirmTextField[action.textFieldId].value = action.textFieldData;
            return localState;
        case ENABLE_DISABLE_CONFIRM:
            localState.disableConfirm = action.data
            return localState
        default:
            return state;
    }
}

export default reducer;