import{
    POST_HANDLE_PASSWORD_TEXTFIELD,
    POST_CHANGE_USER_PASSWORD
} from "./ActionType";
import { toast } from 'react-toastify';
import axios from "../axios";
import { history  } from "..";

export const changeUserPassword = (changePasswordForm, redirectOnSuccess = false) => {
    return (dispatch) => {
        let changepasswordLoaderStatus = false;
        let changePasswordFormDisabledStatus = true;
        let userFirstLoginStatus = true;
        Object.keys(changePasswordForm).map(inputField => {
            if(changePasswordForm[inputField].value === ''){
                changePasswordFormDisabledStatus = false;
            }
        })
        if(changePasswordForm['new_password'].value !== changePasswordForm['confirm_password'].value){
            changePasswordFormDisabledStatus = false;
            toast.error("New Password and Confirm Password does not match", {
                containerId: 'toastMsg'
            });
        }
        dispatch(postChangeUserPassword(changepasswordLoaderStatus, userFirstLoginStatus))
        if(changePasswordFormDisabledStatus){
            changepasswordLoaderStatus = true;
            let reqUrl = '/users/'+localStorage.getItem('Platform-User')+'/changepwd';
            let reqParams = {};
            reqParams['username'] = localStorage.getItem('Platform-User');
            reqParams['oldpassword'] = changePasswordForm['current_password'].value;
            reqParams['newpassword'] = changePasswordForm['new_password'].value;
            dispatch(postChangeUserPassword(changepasswordLoaderStatus))
            return axios.post(reqUrl, reqParams)
            .then(response => {
                changepasswordLoaderStatus = false;
                userFirstLoginStatus = false;
                toast.success("Password changed successfully", {
                    containerId: 'toastMsg',
                    onClose : () => localStorage.setItem('userfirstlogin', false)
                });
                dispatch(postChangeUserPassword(changepasswordLoaderStatus, userFirstLoginStatus))
                if (redirectOnSuccess){
                    history.push('/');
                }
            })
            .catch(error => {
                dispatch(postChangeUserPassword(changepasswordLoaderStatus, userFirstLoginStatus))
            })
            
        }
    }
}
export const postChangeUserPassword = (changepasswordLoaderStatus, userFirstLoginStatus) => {
    return{
        type : POST_CHANGE_USER_PASSWORD,
        changepasswordLoaderStatus : changepasswordLoaderStatus,
        userFirstLoginStatus : userFirstLoginStatus
    }
}
export const handlePasswordtextField = (formElementId, event) => {
    return (dispatch) => {
        return dispatch(postHandlePasswordTextField(formElementId, event))
    }
}
export const postHandlePasswordTextField = (formElementId, event) => {
    return{
        type : POST_HANDLE_PASSWORD_TEXTFIELD,
        formElementId : formElementId,
        data : event.target.value
    }
}