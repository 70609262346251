import {
  SET_MEDICAL_TREATMENT_INPUT_FIELD,
  SET_MEDICAL_TREATMENT_SUBMITED_DATA,
  SET_DEFAULT_VALUE_TO_SEARCH_MEDICAL_TREATMENT,
  SET_ADD_MT_RECORD_INPUT_HANDLER,
  SET_SUBMITED_MT_RECORD,
  SET_ADD_MT_RECORD_FORM_DATE_HANDLER,
  SET_MEDICAL_TREATMENT_RECORDS,
  SET_MT_LIST,
  SET_MT_TYPES,
  SET_MT_RECORD_OPEN_DIALOG_STATUS,
  SET_EDIT_MT_RECORD_CLOSE_DIALOG,
  SET_UPDATE_MT_RECORD,
  OPEN_MEDICAL_TREATMENT_RECORD,
  SET_DEFAULT_VALUE_TO_ADD_MT_FORM
  } from "../actions/ActionType";
  import {
    formatDate,
    convertFormDDMMYYYToDateObject
  } from '../utils/Helper';
  const initialState = {
    addMTRecordForm : {
      mt_type : {
        elementType : 'select',
        elementConfig : {
          type : 'text',
          label : 'MT Types'
        },
        value : '',
        errorStatus : false,
        errorMessage : "Enter MT Type",
        menuItems : {
          'select_mt_type' : {
              name : "Select MT Type",
              value : ''
          }
        }
      },
      treatment_date : {
          elementType : 'date',
          elementConfig : {
              type : 'number',
              label : 'Treatment Date'
          },
          value : null,
          errorStatus : false,
          errorMessage : "Enter Treatment Date"
      },
      next_treatment_date : {
          elementType : 'date',
          elementConfig : {
              type : 'number',
              label : 'Next Treatment Date'
          },
          value : null,
          errorStatus : false,
          errorMessage : "Enter Next Treatment Date",
          disabledStatus : false
      },
      notification_date : {
          elementType : 'date',
          elementConfig : {
              type : 'number',
              label : 'Notification Date'
          },
          value : null,
          errorStatus : false,
          errorMessage : "Enter Notification Date",
          disabledStatus : false
      },
      medical_certificate : {
        elementType : 'upload_file',
        elementConfig : {
            type : 'text',
            label : 'Medical Certificate'
        },
        value : '',
        errorStatus : false,
        errorMessage : "Enter Medical Certificate"
      },
      mt_require : {
        elementType : 'select',
        elementConfig : {
            type : 'number',
            label : 'MT Require Status'
        },
        value : '',
        errorStatus : false,
        errorMessage : "Select MT Require Status",
        menuItems : {
            'mt_require' : {
                name : "Select MT Require Status",
                value : ''
            },
            'yes' : {
                name : "Yes",
                value : true
            },
            'no' : {
              name : "No",
              value : false
          },
        }
      }
    },
    mtInformations : [],
    medicalTreatMentBase64 : '',
    editMTRecordDialogStatus : false,
    activeMTRecordForEdit : '',
    showMTRecordListLoaderStatus : false,
    showMTRecordSubmitLoaderStatus : false,
    showMTRecordUpdateLoaderStatus : false,
    mtTypeLookUp : {}
};

  const setDefaultValueToAddMTForm = (state, action) => {
    state.addMTRecordForm['mt_type'].value = '';
    state.addMTRecordForm['treatment_date'].value = null;
    state.addMTRecordForm['next_treatment_date'].value = null;
    state.addMTRecordForm['notification_date'].value = null;
    state.addMTRecordForm['medical_certificate'].value = '';
    state.addMTRecordForm['mt_require'].value = '';

    state.addMTRecordForm['next_treatment_date'].disabledStatus = true;
    state.addMTRecordForm['notification_date'].disabledStatus = true;

    Object.keys(state.addMTRecordForm).map(inputField => {
      state.addMTRecordForm[inputField].errorStatus = false;
    })

    return state;
  }

  const openMedicalTreatmentRecord = (state, action) => {
    return state;
  }

  const setUpdateMTRecord = (state, action) => {
    let updatedRecord = action.updatedData;
    if(updatedRecord !== undefined){
      const updatedMTRecordIndex = state.mtInformations.findIndex(d => {
          return d.recordId === updatedRecord.recordId;
      });
      state.mtInformations[updatedMTRecordIndex].mt_type = updatedRecord.mt_type;
      state.mtInformations[updatedMTRecordIndex].treatment_date = updatedRecord.treatment_date;
      state.mtInformations[updatedMTRecordIndex].next_treatment_date = updatedRecord.next_treatment_date;
      state.mtInformations[updatedMTRecordIndex].notification_date = updatedRecord.notification_date;
      state.mtInformations[updatedMTRecordIndex].medical_certificate = updatedRecord.document_contents;
      state.mtInformations[updatedMTRecordIndex].mt_requires = updatedRecord.mt_require;
      state.mtInformations[updatedMTRecordIndex].recordId = updatedRecord.recordId;
    }

    if(!action.showMTRecordUpdateLoaderStatus){
      state.editMTRecordDialogStatus = false;
    }
    state.showMTRecordUpdateLoaderStatus = action.showMTRecordUpdateLoaderStatus;
    return state;
  }

  const setEditMTRecordCloseDialog = (state, action) => {
    state.editMTRecordDialogStatus = false;
    return state;
  }

  const setMTRecordOpenDialogStatus = (state, action) => {
    Object.keys(state.addMTRecordForm).map(inputField => {
      state.addMTRecordForm[inputField].errorStatus = false;
    })
    state.addMTRecordForm['mt_type'].value = action.mtRecordData['mt_type'];
    state.addMTRecordForm['treatment_date'].value = new Date(convertFormDDMMYYYToDateObject(action.mtRecordData['treatment_date']));
    state.addMTRecordForm['next_treatment_date'].value = new Date(convertFormDDMMYYYToDateObject(action.mtRecordData['next_treatment_date']));
    state.addMTRecordForm['notification_date'].value = new Date(convertFormDDMMYYYToDateObject(action.mtRecordData['notification_date']));
    state.addMTRecordForm['medical_certificate'].value = "Medical Certificate";
    state.addMTRecordForm['mt_require'].value = action.mtRecordData['mt_requires'];
    state.activeMTRecordForEdit = action.mtRecordData['recordId']
    state.editMTRecordDialogStatus = true;
    return state;
  }

  const setMTTypes = (state, action) => {
    state.mtTypeLookUp = action.lookUp;
    return state;
  }

  const setMTList = (state, action) => {
    state.addMTRecordForm['mt_type'].menuItems = action.mtTypesMenuItems;
    return state;
  }

  const setMedicalTreatmentRecords = (state, action) => {
    state.showMTRecordListLoaderStatus = action.showMTRecordListLoaderStatus;
    state.mtInformations = action.mtInformations;
    return state;
  }
  
  const setAddMTRecordFormDateHandler = (state, action) => {
    state.addMTRecordForm[action.dateElementId].value = action.date;
    if(state.addMTRecordForm[action.dateElementId].value === null && state.addMTRecordForm[action.dateElementId].disabledStatus === false){
      state.addMTRecordForm[action.dateElementId].errorStatus = true;
    }else{
      state.addMTRecordForm[action.dateElementId].errorStatus = false;
    }
    if(state.addMTRecordForm['treatment_date'].value !== null){
      state.addMTRecordForm['next_treatment_date'].disabledStatus = false;
      state.addMTRecordForm['notification_date'].disabledStatus = true;
    }else{
      state.addMTRecordForm['notification_date'].disabledStatus = true;
      state.addMTRecordForm['next_treatment_date'].disabledStatus = true;
    }

    if(state.addMTRecordForm['treatment_date'].value !== null && state.addMTRecordForm['next_treatment_date'].value !== null){
      state.addMTRecordForm['notification_date'].disabledStatus = false;
    }else{
      state.addMTRecordForm['notification_date'].disabledStatus = true;
    }
    return state;
  }

  const setSubmitedMTRecord = (state, action) => {
    state.showMTRecordSubmitLoaderStatus = action.showMTRecordSubmitLoaderStatus;
    Object.keys(state.addMTRecordForm).map((inputField) => {
      if((state.addMTRecordForm[inputField].value === '' || state.addMTRecordForm[inputField].value === null) && !state.addMTRecordForm[inputField].disabledStatus){
        state.addMTRecordForm[inputField].errorStatus = true;
      }else{
        state.addMTRecordForm[inputField].errorStatus = false;
      }
    })
    return state;
  }
  const setAddMTRecordInputHandler = (state, action) => {
    state.addMTRecordForm[action.inputFieldId].value = action.data;
    if(action.medicalTreatMentBase64 !== ''){
      state.medicalTreatMentBase64 = action.medicalTreatMentBase64; 
    }
    if(state.addMTRecordForm[action.inputFieldId].value === ''){
      state.addMTRecordForm[action.inputFieldId].errorStatus = true;
    }else{
      state.addMTRecordForm[action.inputFieldId].errorStatus = false;
    } 
    return state;
  }
  const medicalTreatmentReducer = (state = initialState, action) => {
    let localState = Object.assign({}, state);
    switch (action.type) {
      case SET_DEFAULT_VALUE_TO_ADD_MT_FORM : return setDefaultValueToAddMTForm(localState, action);
      case OPEN_MEDICAL_TREATMENT_RECORD : return openMedicalTreatmentRecord(localState, action);
      case SET_UPDATE_MT_RECORD : return setUpdateMTRecord(localState, action);
      case SET_EDIT_MT_RECORD_CLOSE_DIALOG: return setEditMTRecordCloseDialog(localState, action);
      case SET_MT_RECORD_OPEN_DIALOG_STATUS: return setMTRecordOpenDialogStatus(localState, action);
      case SET_MT_TYPES : return setMTTypes(localState, action);
      case SET_MT_LIST: return setMTList(localState, action);
      case SET_MEDICAL_TREATMENT_RECORDS : return setMedicalTreatmentRecords(localState, action);
      case SET_ADD_MT_RECORD_FORM_DATE_HANDLER : return setAddMTRecordFormDateHandler(localState, action);
      case SET_SUBMITED_MT_RECORD : return setSubmitedMTRecord(localState, action);
      case SET_ADD_MT_RECORD_INPUT_HANDLER : return setAddMTRecordInputHandler(localState, action);
      default:
        return localState;
    }
  };
  
  export default medicalTreatmentReducer;