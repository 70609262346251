import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import FormGroup from "@material-ui/core/FormGroup";
import Button from "@material-ui/core/Button";

import { connect } from "react-redux";

import { 
  inputHandlerVehicleType, 
  onSubmit,
  initializeVehicleTypes
} from "../../actions/vehicleTypes";
import Input from "../../components/UI/Input/Input";
import CircularProgress from '@material-ui/core/CircularProgress';

class AddVehicleTypes extends Component {
  componentDidMount(){
    this.props.initializeVehicleTypes()
  }
  render() {
    let { 
      vehicleTypeForm, 
      showLoaderStatus,
      
    } = this.props.VehicleTypes;
    const formElementArray = [];
    for(let key in vehicleTypeForm){
      formElementArray.push({
        id : key,
        config : vehicleTypeForm[key]
      })
    }
    let form = (
      <form>
        {
          formElementArray.map(formElement => (
            <Input 
              key = {formElement.id}
              elementType={formElement.config.elementType}
              elementConfig={formElement.config.elementConfig}
              value={formElement.config.value}
              error = {formElement.config.errorStatus}
              helperText = {formElement.config.errorMessage}
              changed={(event) => this.props.inputHandlerVehicleType(formElement.id, event )}
              touched = {(event) => this.props.inputHandlerVehicleType(formElement.id, event )}
              maxLength={formElement.config.maxLength}

            />
          ))
        }
      </form>
    )
    return (
      <Grid container spacing={24}>
        <Grid item xs={1} sm={2} />
        <Grid item xs={11} sm={8}>
        <Card className="pl-3 pr-3 pt-3 container">
          <CardHeader title="Add Vehicle Types" />
            <CardContent>
              <FormGroup row className="pl-3 pr-1">
                <Grid container spacing={24}>
                <Grid item xs={10} sm={10}>
                  {form}
                </Grid>
                <Grid item xs={2} sm={2}>
                {
                    showLoaderStatus ? <CircularProgress color = "primary" /> :
                  <Button
                    variant="contained"
                    color="primary"
                    className="mt-4"
                    onClick = {() => {this.props.onSubmit(this.props.VehicleTypes, this.props)}}>
                    ADD
                  </Button>
                }
                </Grid>
                </Grid>
              </FormGroup>
            </CardContent>
            <CardActions />
          </Card>
        </Grid>
        <Grid item xs={1} sm={2} />
      </Grid>

    );
  }
}

const mapStateToProps = state => {
  return {
    VehicleTypes: state.VehicleTypes
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onSubmit : (vehicleTypeInfo, props) => { 
      return dispatch(onSubmit(vehicleTypeInfo, props))
  },
    inputHandlerVehicleType: (textField, event) => {
      return dispatch(inputHandlerVehicleType(textField, event));
    },
    initializeVehicleTypes : () => {
      return dispatch(initializeVehicleTypes())
    }
  };
};
export default connect( mapStateToProps, mapDispatchToProps )(AddVehicleTypes);
