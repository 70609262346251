import React, { Component } from 'react';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import AddVehicle from '../Vehicle/AddVehicle';
import CircularProgress from '@material-ui/core/CircularProgress';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';

import SGMaterialTable from "../../components/UI/Table/SGMaterialTable";

import { 
    handleClickOpenDialog,
    handleCloseDialog,
    getVehicleTypesForListing,
    getVehicles,
    redirectToDocument
} from '../../actions/vehicle';
class ListVehicle extends Component{
    state = {
        columns : [
            { 
                title: 'Vehicle Plate', 
                field: 'vehicle_plate' 
            },
            { 
                title: 'State', 
                field: 'vehicle_state', 
                lookup : {
                    "NSW" : "NSW",
                    "QLD" : "QLD",
                    "SA" :  "SA",
                    "TAS" : "TAS",
                    "VIC" : "VIC",
                    "WA" : "WA",
                    "OTH" : "OTH",
                    "NT" : "NT",
                    "ACT" : "ACT",
                    "ASHMORE ISLAND": "ASHMORE ISLAND",
                    "CARTIER ISLAND": "CARTIER ISLAND",
                    "CHRISTMAS ISLAND": "CHRISTMAS ISLAND",
                    "COCOS ISLAND": "COCOS ISLAND",
                    "CORAL SEA ISLAND": "CORAL SEA ISLAND",
                    "HEARD ISLAND": "HEARD ISLAND",
                    "MCDONALD ISLAND": "MCDONALD ISLAND"
                } 
            },
            { 
                title: 'Vehicle Type', 
                field: 'vehicle_type',
                lookup : {}
            },
            // { 
            //     title: 'Mass Certification', 
            //     field: 'mass_certification',
            //     render : rowData => <a href = "javascript:void(0);" style = {{color : "blue"}} onClick = {() => this.props.redirectToDocument(rowData.vehicle_id)}>{rowData.document_extension ? rowData.mass_certification : null}</a>
            // },
            { 
                title: 'Status', 
                field: 'vehicle_status', 
                lookup : {
                    true : 'Active',
                    false : "Inactive"
                } 
            },
        ],
    }
    componentDidMount(){
        this.props.getVehicleTypesForListing(this.props.vehicleState.columns);
        this.props.getVehicles();
    }
    render(){
        let {
            vehicleTypeLookup,
            vehicleInformations,
            editVehicleDialogOpen,
            listVehicleLoaderStatus
        } = this.props.vehicleState;
        let{
            menuItems
        } = this.props.sidebarState;
        for(let i = 0 ; i < this.state.columns.length; i++){
            if(this.state.columns[i].field === "vehicle_type"){
                this.state.columns[i].lookup = vehicleTypeLookup;
            }
        }

        return(
            <div className = "mt-5 mb-4 container">
                <Paper>
                    <Grid container spacing = {24} style = {{paddingLeft : "1rem", paddingTop : "0", paddingRight : "1rem"}}>
                        <Grid item xs = {6} style = {{paddingTop : "0"}}>
                            <h4 style = {{marginTop : "1rem", textTransform : "uppercase"}}>Vehicle List</h4>
                        </Grid>

                        <Grid item xs = {6} style = {{display : "flex", justifyContent : "flex-end", paddingBottom : "2rem"}}>

                                {/* <Button 
                                    variant="contained" 
                                    color="default"
                                    className = "mr-3"
                                    onClick = {() => this.props.history.push(menuItems['vehicle_upload'].urlpattern)}
                                >
                                    <i className="material-icons" style = {{paddingRight : "0.5rem"}}>
                                        cloud_upload
                                    </i>
                                    Upload Vehicles
                                </Button> */}
                                <Button 
                                    variant="contained" 
                                    color="primary"
                                    onClick = {() => this.props.history.push(menuItems['vehicle_create'].urlpattern)}
                                >
                                    <i className="material-icons" style = {{paddingRight : "0.5rem"}}>
                                        add_circle_outline
                                    </i>
                                    Add Vehicle
                                </Button>
                        </Grid>
                    </Grid>
                    {      
                        listVehicleLoaderStatus ? 
                        <div style = {{height : "20rem"}} className = "d-flex justify-content-center align-items-center">
                            <CircularProgress color = "primary" />
                        </div> :               
                        <SGMaterialTable
                            columns={this.state.columns}
                            data={vehicleInformations}
                            title=""
                            actions={[
                                {
                                icon: 'edit',
                                tooltip: 'Edit',
                                onClick: (event, rowData) => this.props.handleClickOpenDialog(rowData)
                                }
                            ]}
                        /> 
                    }                 
                </Paper>

                <Dialog
                    open={editVehicleDialogOpen}
                    onClose={this.props.handleCloseDialog}
                    maxWidth = 'md'
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    >
                    <AddVehicle/>
                </Dialog>
            </div>
        )
    }
}
const mapStateToProps = state => {
    return{
        vehicleState : state.vehicle,
        sidebarState : state.sidebarState
    }
}
const mapDispatchToProps = dispatch => {
    return{
        handleClickOpenDialog : (rowData) => {
            return dispatch(handleClickOpenDialog(rowData))
        },
        handleCloseDialog : () => {
            return dispatch(handleCloseDialog())
        },
        getVehicleTypesForListing : (columns) => {
            return dispatch(getVehicleTypesForListing(columns))
        },
        getVehicles : () => {
            return dispatch(getVehicles())
        },
        redirectToDocument : (vehicle_id) => {
            return dispatch(redirectToDocument(vehicle_id))
        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ListVehicle);