import {
  SET_ENTRY_RULE_TEXT_FIELD,
  SET_ENTRYTYPES_LIST,
  SUBMIT_ENTRYTYPE_INFO,
  UPDATE_ENTRYTYPE_INFO,
  SET_DEFAULT_VALUE_TO_ENTRY_TYPE_COMPONENT
} from "../actions/ActionType";
import TextField from "@material-ui/core/TextField";
import React from 'react';

const initialState = {
  entryTypesForm : {
    entry_types : {
      elementType : 'input',
      elementConfig : {
        type : 'text',
        label : 'Enter Entry Type'
      },
      value : '',
      errorStatus : false,
      maxLength : 256,
      errorMessage : "Enter Entry Type"
    }
  },

  entryTypeListcols: [
    { 
      title: "Entry Type ID", 
      field: "entrytypeid" ,
      editComponent: props => (
      <TextField
        type="text"
        value={props.value}
        disabled = {true}
      />
    ), 
    cellStyle: {
      width : "18%",
    },  
  },
    { 
      title: "Description", 
      field: "description",
      editComponent: props => {
        return(
          <TextField
            type="text"
            multiline
            value={props.value}
            onChange = {e => props.onChange(e.target.value)}
            style = {{width: "100%"}}
            inputProps={{ maxLength: 256 }}
          />
        )
      },
      cellStyle: {
        wordWrap: "break-word",
        maxWidth : '200px',
        
      },
    },
    {
      title : "Status", 
      field : "current", 
        lookup : {
            true : "Active",
            false : "Inactive"
      },
      cellStyle: {
        width : "10%",
      },
    }
  ],
  entryTypeInformations: []
};

const setDefaultValueToEntryTypeComponent = (state, action) => {
  Object.keys(state.entryTypesForm).map((inputField) => {
    state.entryTypesForm[inputField].value = '';
    state.entryTypesForm[inputField].errorStatus = false;
  })
  return state;
}

const setEntryRuleTextField = (state, action) => {
  if(action.data === ''){
    state.entryTypesForm[action.textField].errorStatus = true;
  }else{
    state.entryTypesForm[action.textField].errorStatus = false;
  }
  state.entryTypesForm[action.textField].value = action.data;
  return state;
}

const setEntryTypesList = (state, action) => {
  state.entryTypeInformations = action.entryTypeList;
  return state;
}

const submitEntryTypeInfo = (state, action) => {
  Object.keys(state.entryTypesForm).map((textField) => {
      if(state.entryTypesForm[textField].value  == ''){
        state.entryTypesForm[textField].errorStatus = true;
      }else{
        state.entryTypesForm[textField].errorStatus = false;
      }
  })
  state.showLoaderStatus = action.showLoaderStatus
  return state;
}

const updateEntryTypeInfo = (state, action) => {
  state.entryTypeInformations = action.entryTypeInformations;
  return state;
}

const reducerEntryRule = (state = initialState, action) => {
    let localState = Object.assign({}, state);
    switch (action.type) {
        case SET_DEFAULT_VALUE_TO_ENTRY_TYPE_COMPONENT : return setDefaultValueToEntryTypeComponent(localState, action);
        case SET_ENTRY_RULE_TEXT_FIELD : return setEntryRuleTextField(localState, action);
        case SET_ENTRYTYPES_LIST : return setEntryTypesList(localState, action);
        case SUBMIT_ENTRYTYPE_INFO: return submitEntryTypeInfo(localState, action);
        case UPDATE_ENTRYTYPE_INFO: return updateEntryTypeInfo(localState, action);
        default:
            return localState;
    }
}

export default reducerEntryRule;