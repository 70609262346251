import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

const sgLoader = (props) => {
    return(
        <div style = {{height : "30rem"}} className = "d-flex justify-content-center align-items-center">
            <CircularProgress color = "primary" />
        </div>
    )
}
export default sgLoader;