import {
    SUBMIT_VEHICLE_INFO,
    UPDATE_VEHICLE_INFO,
    VEHICLE_TEXTFIELD_CHANGE,
    VEHICLE_AXLE_TEXTFIELD_CHANGE,
    VEHICLE_CHECKBOX_CHANGE,
    VEHICLE_OPEN_DIALOG,
    VEHICLE_CLOSE_DIALOG,
    SET_CHECKBOX_VALUE,
    SET_UPLOAD_INPUT_CHANGE,
    SET_VEHICLE_TYPES,
    SET_VEHICLE_TYPES_FOR_LISTING,
    SET_VEHICLES,
    OPEN_MASS_CERTIFICATION,
    SET_UPDATE_VEHICLE_DATA,
    SET_ADD_VEHICLE_INITIAL_STATE,
    SET_INPUTHANDLER_UPLOAD_VEHICLE,
    POST_VEHICLE_UPLOADED_DATA,
    SET_DEFAULT_VALUE_TO_VEHICLE_UPLOAD_FORM,
    POST_HANDLE_VEHICLE_LICENSING_CHECKBOX_CHANGE,
    GET_ALL_AXEL_GROUP
} from '../actions/ActionType';
import { convertVehicleCSVToJSON } from './Common';
import { toast } from 'react-toastify';
import {
    convertToBase64, getActulaURL
} from "../utils/Helper";
import axios from '../axios';
import { apiUrl, xAPIKey } from "../config";


export const handleVehicleLicensingCheckBoxChange = (formElementId) => {
    return (dispatch) => {
        return dispatch(postHandleVehicleLicensingCheckBoxChange(formElementId))
    }
}
export const postHandleVehicleLicensingCheckBoxChange = (formElementId) => {
    return {
        type: POST_HANDLE_VEHICLE_LICENSING_CHECKBOX_CHANGE,
        formElementId: formElementId
    }
}
export const initializeVehicleUploadForm = () => {
    return (dispatch) => {
        return dispatch(setDefaultValueToVehicleUploadForm())
    }
}
export const setDefaultValueToVehicleUploadForm = () => {
    return {
        type: SET_DEFAULT_VALUE_TO_VEHICLE_UPLOAD_FORM
    }
}
export const onvehicleUploadSubmit = (bulkUploadVehicles, uploadVehicleForm) => {
    return (dispatch) => {
        let vehicleBulkUploadVehicleLoaderStatus = false;
        let vehicleUploadStatusList = [];
        let reqPath = "/businesses/" + localStorage.getItem('business_id') + "/vehicles/bulk";
        let uploadVehicleValidationStatus = true;
        Object.keys(uploadVehicleForm).map(inputField => {
            if (uploadVehicleForm[inputField].value === '') {
                uploadVehicleValidationStatus = false;
                uploadVehicleForm[inputField].errorStatus = true;
            } else {
                uploadVehicleForm[inputField].errorStatus = false;
            }
        })
        if (bulkUploadVehicles.length === 0 && uploadVehicleValidationStatus === true) {
            uploadVehicleValidationStatus = false;
            toast.error("No record Found", {
                containerId: 'toastMsg'
            });
        }
        dispatch(postVehicleUploadedData(vehicleUploadStatusList, vehicleBulkUploadVehicleLoaderStatus, uploadVehicleForm));
        if (uploadVehicleValidationStatus) {
            vehicleBulkUploadVehicleLoaderStatus = true;
            dispatch(postVehicleUploadedData(vehicleUploadStatusList, vehicleBulkUploadVehicleLoaderStatus, uploadVehicleForm));
            return axios.post(reqPath, bulkUploadVehicles)
                .then(response => {
                    vehicleBulkUploadVehicleLoaderStatus = false;
                    toast.success("Vehicles Uploaded Successfully", {
                        containerId: 'toastMsg'
                    });
                    vehicleUploadStatusList = response.data.data;
                    uploadVehicleForm['upload_vehicle'].value = '';
                    uploadVehicleForm['upload_vehicle'].errorStatus = false;
                    dispatch(postVehicleUploadedData(vehicleUploadStatusList, vehicleBulkUploadVehicleLoaderStatus, uploadVehicleForm));
                })
                .catch(error => {
                    vehicleBulkUploadVehicleLoaderStatus = false;
                    if (error.response.status == 403) {
                        localStorage.clear();
                        window.location.href = "/";
                    } else {
                        toast.error(error.response.data.message, {
                            containerId: 'toastMsg',
                        });
                    }
                    dispatch(postVehicleUploadedData(vehicleUploadStatusList, vehicleBulkUploadVehicleLoaderStatus, uploadVehicleForm));
                })
        }
    }
}
export const postVehicleUploadedData = (vehicleUploadStatusList, vehicleBulkUploadVehicleLoaderStatus, uploadVehicleForm) => {
    return {
        type: POST_VEHICLE_UPLOADED_DATA,
        vehicleUploadStatusList: vehicleUploadStatusList,
        vehicleBulkUploadVehicleLoaderStatus: vehicleBulkUploadVehicleLoaderStatus,
        uploadVehicleForm: uploadVehicleForm
    }
}

//===========================Get Axle Group=================
export const getaxlegroup = (axleGroupForm) => {
    return (dispatch) => {
        let axleTypeMenuItemsKey = {};
        let axleTypeMenuItems = {};
        let businessID = localStorage.getItem('business_id');
        let reqURL = "/businesses/" + businessID + "/mmaxelconfig";
        return axios.get(reqURL)
            .then(response => {
                for (let i = 0; i < response.data.data.length; i++) {
                    axleTypeMenuItems[response.data.data[i].name] = { name: response.data.data[i].name, value: response.data.data[i].value }

                }
                dispatch(setaxleTypes(axleTypeMenuItems))
            })
            .catch(error => {
                console.log('Error occurred in catch', error)
                if (error.response.status == 403) {
                    localStorage.clear();
                    window.location.href = "/";
                }
                dispatch(setaxleTypes(axleTypeMenuItems))
            })
    }
}

export const setaxleTypes = (axleTypeMenuItems) => {
    return {
        type: GET_ALL_AXEL_GROUP,
        axlelist: axleTypeMenuItems
    }
}

export const inputHandlerUploadVehicle = (formElementId, event) => {
    let bulkUploadVehicles;
    let formData = event.target.value.replace(/^.*\\/, "");
    return (dispatch) => {
        convertVehicleCSVToJSON(event.target.files[0]).then(data => {
            bulkUploadVehicles = data;
            let licensingModule = {};
            let axel_group = {};
            for (let i = 0; i < bulkUploadVehicles.length; i++) {
                licensingModule = {};
                licensingModule['MM'] = bulkUploadVehicles[i].MM;
                licensingModule['FM'] = bulkUploadVehicles[i].FM;
                licensingModule['VD'] = bulkUploadVehicles[i].VD;
                licensingModule['CL'] = bulkUploadVehicles[i].CL;
                licensingModule['TP'] = bulkUploadVehicles[i].TP;
                axel_group['steer'] = bulkUploadVehicles[i].steer
                axel_group['front'] = bulkUploadVehicles[i].front
                axel_group['rear'] = bulkUploadVehicles[i].rear
                delete bulkUploadVehicles[i].MM;
                delete bulkUploadVehicles[i].FM;
                delete bulkUploadVehicles[i].VD;
                delete bulkUploadVehicles[i].CL;
                delete bulkUploadVehicles[i].TP;
                delete bulkUploadVehicles[i].steer;
                delete bulkUploadVehicles[i].front;
                delete bulkUploadVehicles[i].rear;
                bulkUploadVehicles[i].licenseModules = licensingModule;
                bulkUploadVehicles[i].axel_group = axel_group;
            }
            dispatch(setInputHandlerUploadVehicle(formElementId, formData, bulkUploadVehicles));
        })
            .catch(error => {
                toast.error(error, {
                    containerId: 'toastMsg'
                });
                formData = '';
                dispatch(setInputHandlerUploadVehicle(formElementId, formData, bulkUploadVehicles));
            })
    }
}
export const setInputHandlerUploadVehicle = (formElementId, formData, bulkUploadVehicles) => {
    return {
        type: SET_INPUTHANDLER_UPLOAD_VEHICLE,
        formElementId: formElementId,
        data: formData,
        bulkUploadVehicles: bulkUploadVehicles
    }
}
export const initializeState = () => {
    return (dispatch) => {
        return dispatch(setInitialState())
    }
}
export const setInitialState = () => {
    return {
        type: SET_ADD_VEHICLE_INITIAL_STATE
    }
}
export const onUpdateVehicle = (updateVehicleForm, axleGroupForm, licensingcheckBoxes, currentlyEditableVehicleId, massEventBase64String, vehicleInformations) => {
    return (dispatch) => {
        let updateVehicleFormValidStatus = true;
        let updateVehicleLoaderStatus = false;
        let editVehicleDialogOpen = true;
        Object.keys(updateVehicleForm).map((textField) => {
            if (updateVehicleForm[textField].value == '' && textField !== 'mass_certification') {
                updateVehicleForm[textField].errorStatus = true;
                updateVehicleFormValidStatus = false;
            }
        })
        dispatch(setUpdateData(vehicleInformations, updateVehicleLoaderStatus, editVehicleDialogOpen));
        if (updateVehicleFormValidStatus) {
            updateVehicleLoaderStatus = true;
            let licensingModule = {};
            licensingModule['MM'] = licensingcheckBoxes['mass_management'].elementConfig.checked;
            licensingModule['FM'] = licensingcheckBoxes['fleet_management'].elementConfig.checked;
            licensingModule['VD'] = licensingcheckBoxes['vehicle_doc'].elementConfig.checked;
            licensingModule['CL'] = licensingcheckBoxes['check_list'].elementConfig.checked;
            licensingModule['TP'] = licensingcheckBoxes['trip_purpose'].elementConfig.checked;
            let businessId = localStorage.getItem('business_id');
            let reqURL = "/businesses/" + businessId + "/vehicles/" + currentlyEditableVehicleId;
            let updateVehicleReq = {};

            dispatch(setUpdateData(vehicleInformations, updateVehicleLoaderStatus, editVehicleDialogOpen));

            updateVehicleReq['vehicle_plate'] = updateVehicleForm['vehicle_plate'].value;
            updateVehicleReq['vehicle_state'] = updateVehicleForm['vehicle_state'].value;
            updateVehicleReq['vehicle_type'] = updateVehicleForm['vehicle_types'].value;
            updateVehicleReq["measurement_type"] = updateVehicleForm['measurement_type'].value;
            updateVehicleReq["axel_group"] = {
                "steer": axleGroupForm['vehicle_steer'].value,
                "front": axleGroupForm['vehicle_front'].value,
                "rear": axleGroupForm['vehicle_rear'].value,
            }

            if (updateVehicleForm['vehicle_status'].value === 'active') {
                updateVehicleReq['vehicle_status'] = true;
            } else {
                updateVehicleReq['vehicle_status'] = false;
            }

            // updateVehicleReq['is_mass_cert_exp'] = vehicleCheckBoxes['mass_exp'].elementConfig.checked;
            // updateVehicleReq['is_pre_start_exp'] = vehicleCheckBoxes['pre_exp'].elementConfig.checked;
            // updateVehicleReq['is_asset_exp'] = vehicleCheckBoxes['asset_exp'].elementConfig.checked;
            // updateVehicleReq['is_trip_exp'] = vehicleCheckBoxes['trip_exp'].elementConfig.checked;
            updateVehicleReq['licenseModules'] = licensingModule;
            return axios.put(reqURL, updateVehicleReq)
                .then(response => {
                    editVehicleDialogOpen = false;
                    updateVehicleLoaderStatus = false;
                    const updatedVehicleIndex = vehicleInformations.findIndex(d => {
                        return d.vehicle_id === currentlyEditableVehicleId;
                    });
                    // vehicleInformations[updatedVehicleIndex].asset_exp = response.data.data['is_asset_exp'];
                    // vehicleInformations[updatedVehicleIndex].mass_certification = response.data.data.mass_cert_doc['document_name'];
                    // vehicleInformations[updatedVehicleIndex].document_extension = response.data.data.mass_cert_doc['document_extension'];
                    // vehicleInformations[updatedVehicleIndex].mass_exp = response.data.data['is_mass_cert_exp'];
                    // vehicleInformations[updatedVehicleIndex].pre_exp = response.data.data['is_pre_start_exp'];
                    // vehicleInformations[updatedVehicleIndex].trip_exp = response.data.data['is_trip_exp'];
                    vehicleInformations[updatedVehicleIndex].vehicle_id = response.data.data['vehicle_id'];
                    vehicleInformations[updatedVehicleIndex].vehicle_plate = response.data.data['vehicle_plate'];
                    vehicleInformations[updatedVehicleIndex].vehicle_state = response.data.data['vehicle_state'];
                    vehicleInformations[updatedVehicleIndex].vehicle_status = response.data.data['vehicle_status'];
                    vehicleInformations[updatedVehicleIndex].vehicle_type = response.data.data.vehicle_type['vehicletypeid'];

                    toast.success("Vehicle Updated Successfully", { containerId: 'toastMsg' });
                    dispatch(setUpdateData(vehicleInformations, updateVehicleLoaderStatus, editVehicleDialogOpen));


                })
                .catch(error => {
                    editVehicleDialogOpen = false;
                    updateVehicleLoaderStatus = false;
                    if (error.response.status == 403) {
                        localStorage.clear();
                        toast.error("Session Expired ! Please login again", { containerId: 'toastMsg' });
                        window.location.href = "/";
                    } else {
                        toast.error(error.response.data.message, { containerId: 'toastMsg' });
                    }
                    dispatch(setUpdateData(vehicleInformations, updateVehicleLoaderStatus, editVehicleDialogOpen));

                })
        }
    }
}
export const setUpdateData = (vehicleInformations, updateVehicleLoaderStatus, editVehicleDialogOpen) => {
    return {
        type: SET_UPDATE_VEHICLE_DATA,
        vehicleInformations: vehicleInformations,
        updateVehicleLoaderStatus: updateVehicleLoaderStatus,
        editVehicleDialogOpen: editVehicleDialogOpen
    }
}
export const redirectToDocument = (vehicle_id) => {
    return (dispatch) => {
        var request = new XMLHttpRequest();
        let reqURL = apiUrl + "/businesses/" + localStorage.getItem('business_id') + "/vehicles/" + vehicle_id + "/document";
        request.open("GET", reqURL, true);
        request.setRequestHeader("Authorization", localStorage.getItem('token'));
        request.setRequestHeader("Platform-User", localStorage.getItem('Platform-User'));
        request.setRequestHeader("x-api-key", xAPIKey);
        request.responseType = "blob";

        request.onload = function (e) {
            let mimeType = request.getResponseHeader('content-type').split('/');

            let fileName = 'mass_certificate';
            mimeType = mimeType[1];
            if (mimeType === 'vnd.openxmlformats-officedocument.wordprocessingml.document') {
                fileName = 'mass_certificate.docx';
            }
            if (mimeType === 'msword') {
                fileName = 'mass_certificate.doc';
            }
            if (mimeType === 'pdf') {
                fileName = 'mass_certificate.pdf';
            }
            if (mimeType === 'jpeg') {
                fileName = 'mass_certificate.jpeg';
            }
            if (mimeType === 'jpeg') {
                fileName = 'mass_certificate.jpeg';
            }

            var file = window.URL.createObjectURL(this.response);
            var a = document.createElement("a");
            a.href = file;
            a.download = this.response.name || fileName;
            document.body.appendChild(a);
            a.click();
            window.onfocus = function () {
                document.body.removeChild(a)
            }
        };
        request.send();
        dispatch(openMassCertification());
    }
}
export const openMassCertification = () => {
    return {
        type: OPEN_MASS_CERTIFICATION
    }
}
export const getVehicles = () => {
    let listVehicleLoaderStatus = true;
    let reqURL = "/businesses/" + localStorage.getItem('business_id') + "/vehicles";
    let vehicleInformations = [];
    let singleVehicleInfo = {};
    return (dispatch) => {
        dispatch(setVehicles(vehicleInformations, listVehicleLoaderStatus));
        return axios.get(reqURL)
            .then(response => {
                listVehicleLoaderStatus = false;
                if (response.data.data) {
                    for (let i = 0; i < response.data.data.length; i++) {
                        singleVehicleInfo = {};
                        singleVehicleInfo['vehicle_plate'] = response.data.data[i].vehicle_plate;
                        singleVehicleInfo['vehicle_type'] = response.data.data[i].vehicle_type.vehicletypeid;
                        singleVehicleInfo['vehicle_state'] = response.data.data[i].vehicle_state;
                        singleVehicleInfo['measurement_type'] = response.data.data[i].measurement_type;
                        singleVehicleInfo['mass_exp'] = response.data.data[i].is_mass_cert_exp;
                        singleVehicleInfo['pre_exp'] = response.data.data[i].is_pre_start_exp;
                        singleVehicleInfo['trip_exp'] = response.data.data[i].is_trip_exp;
                        singleVehicleInfo['asset_exp'] = response.data.data[i].is_asset_exp;
                        singleVehicleInfo['vehicle_id'] = response.data.data[i].vehicle_id;
                        singleVehicleInfo['vehicle_status'] = response.data.data[i].vehicle_status;
                        singleVehicleInfo['axel_group'] = response.data.data[i].axel_group;
                        vehicleInformations.push(singleVehicleInfo);
                    }
                }
                dispatch(setVehicles(vehicleInformations, listVehicleLoaderStatus));
            })
            .catch(error => {
                listVehicleLoaderStatus = false;
                if (error.response.status == 403) {
                    localStorage.clear();
                    window.location.href = "/";
                }
                dispatch(setVehicles(vehicleInformations, listVehicleLoaderStatus));
            })
    }
}

export const setVehicles = (vehicleInformations, listVehicleLoaderStatus) => {
    return {
        type: SET_VEHICLES,
        vehicleInformations: vehicleInformations,
        listVehicleLoaderStatus: listVehicleLoaderStatus
    }
}

export const getVehicleTypesForListing = (columns) => {
    let lookUp = {};
    let businessID = localStorage.getItem('business_id');
    let reqURL = "/businesses/" + businessID + "/vehicletypes";
    return (dispatch) => {
        return axios.get(reqURL)
            .then(response => {
                for (let i = 0; i < response.data.data.length; i++) {
                    lookUp[response.data.data[i].vehicletypeid] = response.data.data[i].description;
                }
                dispatch(setVehicleTypesForListing(lookUp));
            })
            .catch(error => {
                if (error.response.status == 403) {
                    localStorage.clear();
                    window.location.href = "/";
                }
                dispatch(setVehicleTypesForListing(lookUp));
            })
    }
}
export const setVehicleTypesForListing = (vehicleTypeLookup) => {
    return {
        type: SET_VEHICLE_TYPES_FOR_LISTING,
        vehicleTypeLookup: vehicleTypeLookup
    }
}
export const getVehicleTypes = (addVehicleForm) => {
    return (dispatch) => {
        let vehicleTypeMenuItemsKey = {};
        let vehicleTypeMenuItems = {};
        let businessID = localStorage.getItem('business_id');
        let reqURL = "/businesses/" + businessID + "/vehicletypes";
        return axios.get(reqURL)
            .then(response => {
                vehicleTypeMenuItems = addVehicleForm['vehicle_types'].menuItems;
                for (let i = 0; i < response.data.data.length; i++) {
                    vehicleTypeMenuItemsKey = {};
                    vehicleTypeMenuItemsKey['name'] = response.data.data[i].description;
                    vehicleTypeMenuItemsKey['value'] = response.data.data[i].vehicletypeid;
                    vehicleTypeMenuItems[response.data.data[i].vehicletypeid] = vehicleTypeMenuItemsKey;
                }
                dispatch(setVehicleTypes(vehicleTypeMenuItems))
            })
            .catch(error => {
                if (error.response.status == 403) {
                    localStorage.clear();
                    window.location.href = "/";
                }
                dispatch(setVehicleTypes(vehicleTypeMenuItems))
            })
    }
}
export const setVehicleTypes = (vehicleTypeMenuItems) => {
    return {
        type: SET_VEHICLE_TYPES,
        vehicleTypeMenuItems: vehicleTypeMenuItems
    }
}
export const handleUploadInputChange = (textField, event) => {
    let textFieldValue = event.target.value.replace(/^.*\\/, "");
    return (dispatch) => {
        convertToBase64(event.target.files[0]).then(data => {
            let base64 = data;
            if ((((new Blob([base64]).size) / 1024) / 1024) > 1.5) {
                textFieldValue = '';
                toast.error("File size should be less than 1 MB", { containerId: 'toastMsg' });
            }
            dispatch(setUploadInputChanges(base64, textField, textFieldValue))
        });

    }
}

export const setUploadInputChanges = (base64, textField, textFieldValue) => {
    return {
        type: SET_UPLOAD_INPUT_CHANGE,
        base64: base64,
        textField: textField,
        data: textFieldValue
    }
}

export const handleCheckBoxChange = (checkBoxId) => {
    return (dispatch) => {
        return dispatch(setCheckBoxValue(checkBoxId))
    }
}

export const setCheckBoxValue = (checkBoxId) => {
    return {
        type: SET_CHECKBOX_VALUE,
        checkBoxId: checkBoxId
    }
}

export const onSubmit = (addVehicleForm, axleGroupForm, licensingcheckBoxes, massEventBase64String, props, url) => {
    return (dispatch) => {
        let addVehicleFormValidStatus = true;
        let addVehicleLoaderStatus = false;
        let addedVehicleInformation = {};
        Object.keys(addVehicleForm).map((textField) => {
            if (addVehicleForm[textField].value == '' && textField !== 'vehicle_status' && textField !== 'mass_certification') {
                addVehicleFormValidStatus = false;
            }
        })
        dispatch(setSubmittedData(addVehicleLoaderStatus, addedVehicleInformation));
        if (addVehicleFormValidStatus) {
            let licensingModule = {};
            licensingModule['MM'] = licensingcheckBoxes['mass_management'].elementConfig.checked;
            licensingModule['FM'] = licensingcheckBoxes['fleet_management'].elementConfig.checked;
            licensingModule['VD'] = licensingcheckBoxes['vehicle_doc'].elementConfig.checked;
            licensingModule['CL'] = licensingcheckBoxes['check_list'].elementConfig.checked;
            licensingModule['TP'] = licensingcheckBoxes['trip_purpose'].elementConfig.checked;
            addVehicleLoaderStatus = true;
            let businessId = localStorage.getItem('business_id');
            let reqURL = "/businesses/" + businessId + "/vehicles";
            let addVehicleReq = {};
            dispatch(setSubmittedData(addVehicleLoaderStatus));
            addVehicleReq['vehicle_plate'] = addVehicleForm['vehicle_plate'].value;
            addVehicleReq['vehicle_state'] = addVehicleForm['vehicle_state'].value;
            addVehicleReq["measurement_type"] = addVehicleForm['measurement_type'].value;
            addVehicleReq["axel_group"] = {
                "steer": axleGroupForm['vehicle_steer'].value,
                "front": axleGroupForm['vehicle_front'].value,
                "rear": axleGroupForm['vehicle_rear'].value,
            }

            // loadTypeInfo.addVehicleForm['measurement_type'].value;
            addVehicleReq['vehicle_type'] = addVehicleForm['vehicle_types'].value;
            // addVehicleReq['is_mass_cert_exp'] = vehicleCheckBoxes['mass_exp'].elementConfig.checked;
            // addVehicleReq['is_pre_start_exp'] = vehicleCheckBoxes['pre_exp'].elementConfig.checked;
            // addVehicleReq['is_asset_exp'] = vehicleCheckBoxes['asset_exp'].elementConfig.checked;
            // addVehicleReq['is_trip_exp'] = vehicleCheckBoxes['trip_exp'].elementConfig.checked;
            addVehicleReq['licenseModules'] = licensingModule;
            return axios.post(reqURL, addVehicleReq)
                .then(response => {
                    addVehicleLoaderStatus = false;
                    toast.success("Vehicle Created Successfully", {
                        containerId: 'toastMsg',
                        onClose: () => props.history.push(getActulaURL(url))
                    });
                    dispatch(setSubmittedData(addVehicleLoaderStatus, addedVehicleInformation));
                })
                .catch(error => {
                    addVehicleLoaderStatus = false;
                    if (error.response.status == 403) {
                        localStorage.clear();
                        toast.error("Session Expired ! Please login again", { containerId: 'toastMsg' });
                        window.location.href = "/";
                    } else {
                        toast.error(error.response.data.message, { containerId: 'toastMsg' });
                    }
                    dispatch(setSubmittedData(addVehicleLoaderStatus, addedVehicleInformation));

                })
        }
    }

}

export const setSubmittedData = (addVehicleLoaderStatus, addedVehicleInformation) => {
    return {
        type: SUBMIT_VEHICLE_INFO,
        addVehicleLoaderStatus: addVehicleLoaderStatus,
        addedVehicleInformation: addedVehicleInformation
    }
}

export const onUpdate = (vehicleControls) => {
    let vehicleInformations = {};
    vehicleInformations.id = vehicleControls.activeRecordForEdit.id;
    vehicleInformations.vehicle_plate = vehicleControls.vehicle_plate.value;
    vehicleInformations.vehicle_state = vehicleControls.vehicle_state.value;
    vehicleInformations.vehicle_type = vehicleControls.vehicle_type.value;
    vehicleInformations.mass_certification = vehicleControls.mass_certification.value;

    // if (vehicleControls.vehicleCheckBoxes.fleet.checkedStatus === true) {
    //     vehicleInformations.fleet_status = 'yes';
    // } else {
    //     vehicleInformations.fleet_status = 'no';
    // }
    return (dispatch) => {
        return dispatch(setUpdatedData(vehicleInformations));
    }
}

export const handleTextFieldChange = (textField, event) => {
    return (dispatch) => {
        return dispatch(setTextFieldData(textField, event));
    }
}
export const handleAxleTextFieldChange = (textField, event) => {
    return (dispatch) => {
        return dispatch(setAxleTextFieldData(textField, event));
    }
}

export const setAxleTextFieldData = (textField, event) => {
    return {
        type: VEHICLE_AXLE_TEXTFIELD_CHANGE,
        textFieldId: textField,
        textFieldData: event.target.value
    }
}

export const onBlurTextfield = (textField, event) => {
    return (dispatch) => {
        return dispatch(setTextFieldData(textField, event));
    }
}
export const setTextFieldData = (textField, event) => {
    return {
        type: VEHICLE_TEXTFIELD_CHANGE,
        textFieldId: textField,
        textFieldData: event.target.value
    }
}
export const handleCheckboxChange = (id, checkedStatus) => {
    return (dispatch) => {
        return dispatch(setCheckboxes(id, checkedStatus));
    }
}

export const handleClickOpenDialog = (rowData) => {
    return (dispatch) => {
        let getLicenseFieldStatusLoader = true;
        let reqUrl = "/businesses/" + localStorage.getItem('business_id') + "/licenses";
        let licensingStatus = {};
        let headers = {};
        headers['businessId'] = localStorage.getItem('business_id');
        headers['vehicleId'] = rowData.vehicle_id;
        licensingStatus['mass_management'] = false;
        licensingStatus['fleet_management'] = false;
        licensingStatus['vehicle_doc'] = false;
        licensingStatus['check_list'] = false;
        licensingStatus['trip_purpose'] = false;
        dispatch(managaeOpenDialog(rowData, licensingStatus, getLicenseFieldStatusLoader));
        return axios.get(reqUrl, { headers })
            .then(response => {
                getLicenseFieldStatusLoader = false;
                for (let i = 0; i < response.data.data.length; i++) {
                    if (response.data.data[i] === 'MM') {
                        licensingStatus['mass_management'] = true;
                    }
                    if (response.data.data[i] === 'FM') {
                        licensingStatus['fleet_management'] = true;
                    }
                    if (response.data.data[i] === 'VD') {
                        licensingStatus['vehicle_doc'] = true;
                    }
                    if (response.data.data[i] === 'CL') {
                        licensingStatus['check_list'] = true;
                    }
                    if (response.data.data[i] === 'TP') {
                        licensingStatus['trip_purpose'] = true;
                    }
                }
                dispatch(managaeOpenDialog(rowData, licensingStatus, getLicenseFieldStatusLoader));
            })
            .catch(error => {
                dispatch(managaeOpenDialog(rowData, licensingStatus, getLicenseFieldStatusLoader));
            })
    }
}
export const managaeOpenDialog = (rowData, licensingStatus, getLicenseFieldStatusLoader) => {
    return {
        type: VEHICLE_OPEN_DIALOG,
        data: rowData,
        licensingStatus: licensingStatus,
        getLicenseFieldStatusLoader: getLicenseFieldStatusLoader
    }
}

export const handleCloseDialog = () => {
    return (dispatch) => {
        return dispatch(manageCloseDialog());
    }
}
export const setUpdatedData = (vehicleInformations) => {
    return {
        type: UPDATE_VEHICLE_INFO,
        data: vehicleInformations
    }
}
export const setCheckboxes = (id, checkedStatus) => {
    return {
        type: VEHICLE_CHECKBOX_CHANGE,
        checkBoxId: id,
        checkedStatus: checkedStatus
    }
}

export const manageCloseDialog = () => {
    return {
        type: VEHICLE_CLOSE_DIALOG
    }
}