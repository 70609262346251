import React, { Component } from "react";
import {
  withRouter
} from 'react-router-dom';
import axios from "../../axios";
import NCReportChart from "./NCReportChart/NCReportChart"
const moment = require('moment');

/**
 * This component should not rely on any app state being available. 
 * It is intended to be loaded in an external iframe as part of the geotab integration
 */
class NCReport extends Component {

    token = '';
    businessId = '';
    
    buildDriverName = (element) => {
        element.driverName = element['driver_firstname'] + ' ' + element['driver_lastname'];                    
        return element;
    }

    getNonComplianceBreachList =  async (fromDate = '', toDate = '') => {      

        let reqUrl = `/businesses/${this.businessId}/potentialcompliancebreachlist`;
        let headers = {'Authorization':  this.token };
        let query = {
            fromDate, 
            toDate
        };

        await axios.get(reqUrl, { headers, params: query })
            .then(response => {
                let data = response.data.data;
                const breachJsonData = data.map(this.buildDriverName);
                this.setState({nonComplianceData: breachJsonData});
                
            }).catch(error => {
                this.setState({hasError: true, errorMessage: 'Unable to load chart data'})
            });
    }

    getDriverList = async () => {
        let reqUrl = `/businesses/${this.businessId}/drivers`;
        let headers = {'Authorization':  this.token };

        await axios.get(reqUrl, { headers })
            .then(response => {
                const driverList = response.data.data.map(d => this.buildDriverName(d.driver_id));
                this.setState({driverList: driverList });
                
            }).catch(error => {
                this.setState({hasError: true, errorMessage: 'Unable to load driver data'})
            });
    };
    
    state = {
        hasError: false,
        errorMessage: '',
        nonComplianceData: [],
        driverList: [],
        fromDate: moment().add(-30, 'days'), 
        toDate: moment(),
    }
    
    urlParams = new Proxy(new URLSearchParams(window.location.search), {
        get: (searchParams, prop) => searchParams.get(prop),
    });
    
    componentDidMount() {
        this.token = this.urlParams.token;
        this.businessId = this.urlParams.businessId;

        if (!this.token || !this.businessId) {
            console.error('Token or businessId is mmissing, cannot load NC chart');
            this.setState({hasError: true, errorMessage: 'Unable to load page'});
        }

        this.getDriverList();
        this.getNonComplianceBreachList(this.state.fromDate.toISOString(), this.state.toDate.toISOString());
    }

    refreshData = (dateRange) => {
        this.setState({fromDate: dateRange.fromDate, toDate: dateRange.toDate});
        this.getNonComplianceBreachList(dateRange.fromDate.toISOString(), dateRange.toDate.toISOString());
    }

    render() {
        return ( this.state.hasError ? 
            <p>{this.state.errorMessage}</p> : 
            <NCReportChart  toDate={this.state.toDate} fromDate={this.state.fromDate} breachData={this.state.nonComplianceData} driverList={this.state.driverList} refreshData={this.refreshData}/>
            )
    }
}

export default withRouter(NCReport)