import React, { Component } from 'react';
import Logo from "../images/StepGlobal-250x65.jpg";
import "./layout.css";

class TermNCondtion extends Component {
    render() {
        return (

            <div className="container-fluid" style={{ margin: "0", padding: "0" }}>
                <div>
                    <nav className="navbar fixed-top navbar-default " role="navigation">
                        <img src={Logo} alt={"logo"} className="rounded" className="mx-auto d-block"></img>
                    </nav>
                </div>
                <div className="pl-5 pr-5 ml-5 mr-5 mt-5 pt-5" style={{ backgroundColor: "#ffffff", }}>
                    <div>
                        <h2 id="header1">MOBILE APP TERMS AND CONDITIONS OF USE </h2>
                    </div>
                    <div>
                        <ol>
                            <li>About the Application</li>
                            <ol type="i">
                                <li>Welcome to Smart eDriver (the 'Application'). The Application provides you with an opportunity to browse and purchase various products that have been listed for sale through the Application (the 'Products'). The Application provides this service by way of granting you access to the content on the Application (the 'Purchase Services'). </li>
                                <li> The Application is operated by Step Global PTY. LTD. (ABN 11119921578). Access to and use of the Application, or any of its associated Products or Purchase Services, is provided by Step Global. Please read these terms and conditions (the 'Terms') carefully. By using, browsing and/or reading the Application, this signifies that you have read, understood and agree to be bound by the Terms. If you do not agree with the Terms, you must cease usage of the Application, or any of its Purchase Services, immediately. </li>
                                <li>Step Global reserves the right to review and change any of the Terms by updating this page at its sole discretion. When Step Global updates the Terms, it will use reasonable endeavours to provide you with notice of updates to the Terms. Any changes to the Terms take immediate effect from the date of their publication. Before you continue, we recommend you keep a copy of the Terms for your records. </li>
                            </ol>
                            <br />
                            <li>Acceptance of the Terms You accept the Terms by using or browsing the Application. You may also accept the Terms by clicking to accept or agree to the Terms where this option is made available to you by Step Global in the user interface.</li>
                            <br />
                            <li>Registration to use the Purchase Services</li>
                            <ol type="i">
                                <li>In order to access the Purchase Services, you must first register as a user of the Application. As part of the registration process, or as part of your continued use of the Purchase Services, you may be required to provide personal information about yourself (such as identification or contact details), including: </li>
                                <ol type="a">
                                    <li>Email address</li>
                                    <li>Preferred username</li>
                                    <li>Telephone number</li>
                                    <li>Password</li>
                                    <li>License Number</li>
                                    <li>Date of Birth</li>
                                    <li>Resident State</li>
                                </ol>
                                <li>You warrant that any information you give to Step Global in the course of completing the registration process will always be accurate, correct and up to date.</li>
                                <li>Once you have completed the registration process, you will be a registered member of the Application ('Member') and agree to be bound by the Terms. As a Member you will be granted immediate access to the Purchase Services.</li>
                                <li>You may not use the Purchase Services and may not accept the Terms if:</li>
                                <ol type="a">
                                    <li>you are not of legal age to form a binding contract with Step Global</li>
                                    <li> you are a person barred from receiving the Purchase Services under the laws of Australia or other countries including the country in which you are resident or from which you use the Purchase Services.</li>
                                </ol>
                            </ol>
                            <br />
                            <li>Your obligations as a Member</li>
                            <ol type="i">
                                <li>As a Member, you agree to comply with the following: You will use the Purchase Services only for purposes that are permitted by:</li>
                                <ol type="a">
                                    <li> The Terms;</li>
                                    <li>Any applicable law, regulation or generally accepted practices or guidelines in the relevant jurisdictions</li>
                                    <li>You have the sole responsibility for protecting the confidentiality of your password and/or email address. Use of your password by any other person may result in the immediate cancellation of the Purchase Services.</li>
                                    <li>Any use of your registration information by any other person, or third parties, is strictly prohibited. You agree to immediately notify Step Global of any unauthorised use of your password or email address or any breach of security of which you have become aware.</li>
                                    <li>Access and use of the Application is limited, non-transferable and allows for the sole use of the Application by you for the purposes of Step Global providing the Purchase Services.</li>
                                    <li>You will not use the Purchase Services or Application for any illegal and/or unauthorised use which includes collecting email addresses of Members by electronic or other means for the purpose of sending unsolicited email or unauthorised framing of or linking to the Application.</li>
                                    <li>You agree that commercial advertisements, affiliate links, and other forms of solicitation may be removed from the Application without notice and may result in termination of the Purchase Services. Appropriate legal action will be taken by Step Global for any illegal or unauthorised use of the Application</li>
                                    <li>You acknowledge and agree that any automated use of the Application or its Purchase Services is prohibited.</li>
                                </ol>
                            </ol>
                            <br />
                            <li>Purchase of Products and Returns Policy</li>
                            <ol type="i">
                                <li>In using the Purchase Services to purchase the Product through the Application, you will agree to the payment of the purchase price listed on the Application for the Product (the 'Purchase Price').</li>
                                <li>Payment of the Purchase Price may be made through one of the following third party providers: Authorize.net (the 'Payment Gateway Providers') In using the Purchase Services, you warrant that you have familiarised yourself with, and agree to be bound by, the applicable Terms and Conditions of Use, Privacy Policy and other relevant legal documentation provided by the Payment Gateway Providers.</li>
                                <li>Following payment of the Purchase Price being confirmed by Step Global, you will be issued with a receipt to confirm that the payment has been received and Step Global may record your purchase details for future use.</li>
                                <li>Step Global may, at their sole discretion, provide a refund on the return of the Products within 30 days where the Product packaging is unopened and remains in a saleable condition. You acknowledge and agree that you are liable for any postage and shipping costs associated with any refund pursuant to this clause.</li>
                            </ol>
                            <br />
                            <li>Warranty</li>
                            <ol type="i">
                                <li>Step Global's Products come with guarantees that cannot be excluded under the Australian Consumer Law. You are entitled to a replacement or refund for a major failure of the Product and compensation for any other reasonably foreseeable loss or damage. You are also entitled to have the Products repaired or replaced if the Products fail to be of acceptable quality and the failure does not amount to a major failure (the 'Warranty').</li>
                                <li>You may make a claim under this clause (the 'Warranty Claim ') for material defects and workmanship in the Products within 1 from the date of purchase (the 'Warranty Period').</li>
                                <li>In order to make a Warranty Claim during the Warranty Period, you must provide proof of purchase to Step Global showing the date of purchase of the Products, provide a description of the Products and the price paid for the Products by sending written notice to Step Global at 7/444 Warrigal Road, Heatherton, Victoria, 3202 or by email at admin@smartedriver.com.</li>
                                <li>Where the Warranty Claim is accepted then Step Global will, at its sole discretion, either repair or replace any defective Products or part thereof with a new or remanufactured equivalent during the Warranty Period at no charge to you for parts or labour. You acknowledge and agree that you will be solely liable for any postage or shipping costs incurred in facilitating the Warranty Claim.</li>
                                <li>The Warranty shall be the sole and exclusive warranty granted by Step Global and shall be the sole and exclusive remedy available to you in addition to other rights and under a law in relation to the Products to which this warranty relates.</li>
                                <li>All implied warranties including the warranties of merchantability and fitness for use are limited to the Warranty Period.</li>
                                <li>The Warranty does not apply to any appearance of the supplied Products nor to the additional excluded items set forth below nor to any supplied Products where the exterior of which has been damaged or defaced, which has been subjected to misuse, abnormal service or handling, or which has been altered or modified in design or construction.</li>
                            </ol>
                            <br />
                            <li>Delivery</li>
                            <ol type="i">
                                <li>You acknowledge that the Purchase Services offered by Step Global integrate delivery (the 'Delivery Services') through the use of third-party delivery companies (the 'Delivery Service Providers').</li>
                                <li>In providing the Purchase Services, Step Global may provide you with a variety of delivery and insurance options offered as part of the Delivery Services by the Delivery Service Providers. You acknowledge and agree that Step Global is not the provider of these delivery and insurance options and merely facilitates your interaction with the Delivery Service Providers in respect to providing the Delivery Services.</li>
                                <li>In the event that an item is lost or damaged in the course of the Delivery Services, Step Global asks that you:</li>
                                <ol type="a">
                                    <li>contact the Delivery Service Provider directly to request a refund or to claim on any insurance options available; and</li>
                                    <li>contact us by sending an email to admin@smartedriver.com outlining in what way the Products were damaged in transit so we are able to determine if the Delivery Service Provider should be removed from the Purchase Services.</li>
                                </ol>
                            </ol>
                            <br />
                            <li>Copyright and Intellectual Property</li>
                            <ol type="i">
                                <li>The Application, the Purchase Services and all of the related products of Step Global are subject to copyright. The material on the Application is protected by copyright under the laws of Australia and through international treaties. Unless otherwise indicated, all rights (including copyright) in the site content and compilation of the Application (including text, graphics, logos, button icons, video images, audio clips and software) (the 'Content') are owned or controlled for these purposes, and are reserved by Step Global or its contributors.</li>
                                <li>Step Global retains all rights, title and interest in and to the Application and all related content. Nothing you do on or in relation to the Application will transfer to you:</li>
                                <ol type="a">
                                    <li>The business name, trading name, domain name, trademark, industrial design, patent, registered design or copyright of Step Global; or</li>
                                    <li>the right to use or exploit a business name, trading name, domain name, trademark or industrial design; or</li>
                                    <li>a system or process that is the subject of a patent, registered design or copyright (or an adaptation or modification of such a system or process).</li>
                                </ol>
                                <li>You may not, without the prior written permission of Step Global and the permission of any other relevant rights owners: broadcast, republish, up-load to a third party, transmit, post, distribute, show or play in public, adapt or change in any way the Content or third party content for any purpose. This prohibition does not extend to materials on the Application, which are freely available for re-use or are in the public domain.</li>
                            </ol>
                            <br />
                            <li>Privacy Step Global takes your privacy seriously and any information provided through your use of the Application and/or the Purchase Services are subject to Step Global's Privacy Policy, which is available on the Application and at <a href="/privacypolicy" target="_blank">www.smartedriver.com/privacypolicy.</a></li>
                            <br />
                            <li>General Disclaimer</li>
                            <ol type="i">
                                <li>You acknowledge that Step Global does not make any terms, guarantees, warranties, representations or conditions whatsoever regarding the Products other than provided for pursuant to these Terms.</li>
                                <li>Step Global will make every effort to ensure a Product is accurately depicted on the Application, however, you acknowledge that sizes, colours and packaging may differ from what is displayed on the Application.</li>
                                <li>Nothing in these Terms limits or excludes any guarantees, warranties, representations or conditions implied or imposed by law, including the Australian Consumer Law (or any liability under them) which by law may not be limited or excluded.</li>
                                <li>Subject to this clause, and to the extent permitted by law:</li>
                                <ol type="a">
                                    <li>all terms, guarantees, warranties, representations or conditions which are not expressly stated in these Terms are excluded; and</li>
                                    <li>Step Global will not be liable for any special, indirect or consequential loss or damage (unless such loss or damage is reasonably foreseeable resulting from our failure to meet an applicable Consumer Guarantee), loss of profit or opportunity, or damage to goodwill arising out of or in connection with the Purchase Services or these Terms (including as a result of not being able to use the Purchase Services or the late supply of the Purchase Services), whether at common law, under contract, tort (including negligence), in equity, pursuant to statute or otherwise.</li>
                                </ol>
                                <li>Use of the Application, the Purchase Services, and any of the products of Step Global (including the Delivery Services), is at your own risk. Everything on the Application, the Purchase Services, and the Products of Step Global, are provided to you on an "as is" and "as available" basis, without warranty or condition of any kind. None of the affiliates, directors, officers, employees, agents, contributors, third party content providers or licensors of Step Global (including any third party where the Delivery Services are made available to you) make any express or implied representation or warranty about its Content or any products or Purchase Services (including the products or Purchase Services of Step Global) referred to on the Application. This includes (but is not restricted to) loss or damage you might suffer as a result of any of the following:</li>
                                <ol type="a">
                                    <li>failure of performance, error, omission, interruption, deletion, defect, failure to correct defects, delay in operation or transmission, computer virus or other harmful component, loss of data, communication line failure, unlawful third-party conduct, or theft, destruction, alteration or unauthorised access to records; </li>
                                    <li>the accuracy, suitability or currency of any information on the Application, the Purchase Service, or any of its Content related products (including third party material and advertisements on the Application); </li>
                                    <li>costs incurred as a result of you using the Application, the Purchase Services or any of the Products;</li>
                                    <li>the Content or operation in respect to links which are provided for the User's convenience;</li>
                                    <li>any failure to complete a transaction, or any loss arising from e-commerce transacted on the Application; or</li>
                                    <li>any defamatory, threatening, offensive or unlawful conduct of third parties or publication of any materials relating to or constituting such conduct.</li>
                                </ol>
                            </ol>
                            <br />
                            <li> Competitors If you are in the business of providing similar Services for the purpose of providing them to users for a commercial gain, whether business users or domestic users, then you are a competitor of Step Global. Competitors are not permitted to use or access any information or content on our Application. If you breach this provision, Step Global will hold you fully responsible for any loss that we may sustain and hold you accountable for all profits that you might make from such a breach.</li>
                            <br />
                            <li>Limitation of Liability</li>
                            <ol type="i">
                                <li>Step Global's total liability arising out of or in connection with the Purchase Services or these Terms, however arising, including under contract, tort (including negligence), in equity, under statute or otherwise, will not exceed the most recent Purchase Price paid by you under these Terms or where you have not paid the Purchase Price, then the total liability of Step Global is the resupply of information or Purchase Services to you.</li>
                                <li>You expressly understand and agree that Step Global, its affiliates, employees, agents, contributors, third party content providers and licensors shall not be liable to you for any direct, indirect, incidental, special consequential or exemplary damages which may be incurred by you, however caused and under any theory of liability. This shall include, but is not limited to, any loss of profit (whether incurred directly or indirectly), any loss of goodwill or business reputation and any other intangible loss.</li>
                                <li>Step Global is not responsible or liable in any manner for any site content (including the Content and Third Party Content) posted on the Application or in connection with the Purchase Services, whether posted or caused by users of the Application of Step Global, by third parties or by any of the Purchase Services offered by Step Global.</li>
                                <li>You acknowledge that Step Global does not provide the Delivery Services to you and you agree that Step Global will not be liable to you for any special, indirect or consequential loss or damage, loss of profit or opportunity, or damage to goodwill arising out of or in connection with the Delivery Services</li>
                            </ol>
                            <br />
                            <li>Termination of Contract</li>
                            <ol type="i">
                                <li>The Terms will continue to apply until terminated by either you or by Step Global as set out below.</li>
                                <li> If you want to terminate the Terms, you may do so by: Your notice should be sent, in writing, to Step Global via the 'Contact Us' link on our homepage.</li>
                                <ol type="a">
                                    <li>notifying Step Global at any time; and</li>
                                    <li>closing your accounts for all of the Purchase Services which you use, where Step Global has made this option available to you.</li>
                                </ol>
                                <li>Step Global may at any time, terminate the Terms with you if:</li>
                                <ol type="a">
                                    <li>ou have breached any provision of the Terms or intend to breach any provision;</li>
                                    <li>Step Global is required to do so by law;</li>
                                    <li>the partner with whom Step Global offered the Purchase Services to you has terminated its relationship with Step Global or ceased to offer the Purchase Services to you;</li>
                                    <li>Step Global is transitioning to no longer providing the Purchase Services to Users in the country in which you are resident or from which you use the service; or</li>
                                    <li>the provision of the Purchase Services to you by Step Global is, in the opinion of Step Global, no longer commercially viable</li>
                                </ol>
                                <li>Subject to local applicable laws, Step Global reserves the right to discontinue or cancel your membership to the Application at any time and may suspend or deny, in its sole discretion, your access to all or any portion of the Application or the Purchase Services without notice if you breach any provision of the Terms or any applicable law or if your conduct impacts Step Global's name or reputation or violates the rights of those of another party. </li>
                                <li>When the Terms come to an end, all of the legal rights, obligations and liabilities that you and Step Global have benefited from, been subject to (or which have accrued over time whilst the Terms have been in force) or which are expressed to continue indefinitely, shall be unaffected by this cessation, and the provisions of this clause shall continue to apply to such rights, obligations and liabilities indefinitely.</li>
                            </ol>
                            <br />
                            <li>Indemnity </li>
                            <ol type="i">
                                <li>You agree to indemnify Step Global, its affiliates, employees, agents, contributors, third party content providers and licensors from and against:</li>
                                <ol type="a">
                                    <li>all actions, suits, claims, demands, liabilities, costs, expenses, loss and damage (including legal fees on a full indemnity basis) incurred, suffered or arising out of or in connection with any Content you post through the Application;</li>
                                    <li> any direct or indirect consequences of you accessing, using or transacting on the Application or attempts to do so and any breach by you or your agents of these Terms; and/or (c) any breach of the Terms.</li>
                                </ol>
                            </ol>
                            <br />
                            <li>Dispute Resolution</li>
                            <ol type="i">
                                <li>Compulsory: If a dispute arises out of or relates to the Terms, either party may not commence any Tribunal or Court proceedings in relation to the dispute, unless the following clauses have been complied with (except where urgent interlocutory relief is sought)</li>
                                <li>Notice: A party to the Terms claiming a dispute ('Dispute') has arisen under the Terms, must give written notice to the other party detailing the nature of the dispute, the desired outcome and the action required to settle the Dispute</li>
                                <li>Resolution: On receipt of that notice ('Notice') by that other party, the parties to the Terms ('Parties') must:</li>
                                <ol type="a">
                                    <li>Within 15 days of the Notice endeavour in good faith to resolve the Dispute expeditiously by negotiation or such other means upon which they may mutually agree;</li>
                                    <li>If for any reason whatsoever, 30 days after the date of the Notice, the Dispute has not been resolved, the Parties must either agree upon selection of a mediator or request that an appropriate mediator be appointed by the President of the Conflict Resolution Service or his or her nominee</li>
                                    <li>The Parties are equally liable for the fees and reasonable expenses of a mediator and the cost of the venue of the mediation and without limiting the foregoing undertake to pay any amounts requested by the mediator as a pre-condition to the mediation commencing. The Parties must each pay their own costs associated with the mediation; </li>
                                    <li>The mediation will be held in 7/444 Warrigal Road, Heatherton, Victoria, 3202, Australia.</li>
                                </ol>
                                <li> Confidential All communications concerning negotiations made by the Parties arising out of and in connection with this dispute resolution clause are confidential and to the extent possible, must be treated as "without prejudice" negotiations for the purpose of applicable laws of evidence.</li>
                                <li>Termination of Mediation: If 15 days have elapsed after the start of a mediation of the Dispute and the Dispute has not been resolved, either Party may ask the mediator to terminate the mediation and the mediator must do so.</li>
                            </ol>
                            <br />
                            <li>Venue and Jurisdiction the Purchase Services offered by Step Global is intended to be viewed by residents of Australia. In the event of any dispute arising out of or in relation to the Application, you agree that the exclusive venue for resolving any dispute shall be in the courts of Victoria, Australia.</li>
                            <br />
                            <li> Governing Law the Terms are governed by the laws of Victoria, Australia. Any dispute, controversy, proceeding or claim of whatever nature arising out of or in any way relating to the Terms and the rights created hereby shall be governed, interpreted and construed by, under and pursuant to the laws of Victoria, Australia, without reference to conflict of law principles, notwithstanding mandatory rules. The validity of this governing law clause is not contested. The Terms shall be binding to the benefit of the parties hereto and their successors and assigns. </li>
                            <br />
                            <li>Independent Legal Advice Both parties confirm and declare that the provisions of the Terms are fair and reasonable and both parties having taken the opportunity to obtain independent legal advice and declare the Terms are not against public policy on the grounds of inequality or bargaining power or general grounds of restraint of trade.</li>
                            <br />
                            <li>Severance If any part of these Terms is found to be void or unenforceable by a Court of competent jurisdiction, that part shall be severed, and the rest of the Terms shall remain in force</li>
                        </ol>
                    </div>
                </div>

            </div>

        )
    }
}

export default TermNCondtion;