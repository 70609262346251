import React, { Component } from "react";
import MaterialTable from "material-table";
import { connect } from "react-redux";
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { toast } from 'react-toastify';
import Typography from '@material-ui/core/Typography';
import {
  editMeasurementType,
  getMeasurementTypeList,
} from '../../actions/MeasurementTypes';
import SGMaterialTable from '../../components/UI/Table/SGMaterialTable';
class ListMeasurementTypes extends Component {
  componentDidMount(){
    this.props.getMeasurementTypeList();
}
  render() {
    let { 
      measurementtypesListcols,
      measurementtypesListrows,
      measurementTypeListLoaderStatus
    } = this.props.measurementtypesState;
    console.log(this.props.measurementtypesState)
    return (
       <div 
        style = {{marginTop : '2rem', marginBottom : "2rem"}}
        className = "container"
      >
        <Paper>
            <div className = "pt-1 pl-3">
              <span className = "d-flex">
                <div className = "mr-auto" style = {{paddingTop : "0"}}>
                    <h4 style = {{marginTop : "1rem", textTransform : "uppercase"}}>Measurement Type List</h4>
                </div>
                <div className = "d-flex flex-wrap align-content-center">
                    <Button 
                        variant="contained" 
                        color="primary"
                        onClick = {() => this.props.history.push("/sgadminportal/measurementtypes/newmeasurementtype")}
                        className = "mr-2"
                    >
                        <i className="material-icons" style = {{paddingRight : "0.5rem"}}>
                            add_circle_outline
                        </i>
                        Add Measurement Type
                    </Button>
                </div>
              </span>
            </div>
            {
              measurementTypeListLoaderStatus ? 
                <div style = {{height : "20rem"}} className = "d-flex justify-content-center align-items-center">
                    <CircularProgress color = "primary" />
                </div>:
                <MaterialTable
                  columns={measurementtypesListcols}
                  data={measurementtypesListrows}
                  title=""
                  editable={{
                    onRowUpdate: (newData, oldData) =>
                      new Promise((resolve, reject) => {
                        if(newData.mm_measurement_types !== ''){
                          resolve(this.props.editMeasurementType(newData, measurementtypesListrows));
                        }else{
                          toast.error("Measurement Types field should not be blank", {containerId: 'toastMsg'});
                          reject();
                        }
                      }),
                  }}
                  options={{ actionsColumnIndex: -1}}
                />
              }
          </Paper>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    measurementtypesState: state.MeasurementTypes
  };
};
const mapDispatchToProps = dispatch => {
  return{
    getMeasurementTypeList : () => {
          return dispatch(getMeasurementTypeList())
      },
    editMeasurementType : (newData, measurementtypesListrows) => {
        return dispatch(editMeasurementType(newData, measurementtypesListrows))
      }
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ListMeasurementTypes);
