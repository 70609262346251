import React, { Component } from "react";
import axios from '../../axios';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { toast } from 'react-toastify';
import MaterialTable, { MTableAction, MTableActions } from 'material-table'

import AutoComplete from "../../components/AutoComplete";


class MassSetup extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            mobileOpen: false,
            anchorEl: null,
            themeDialog: false,
            toolkitOpen: true,
            fatigue_type: '',
            solo_or_twoup: '',
            vehicle_type: '',
            time_duration: '',
            breach_type: '',
            upload_ewd_file: '',
            columns: [
                { title: 'Id', field: 'id', editable: 'never' },
                { title: 'Configuration Id', field: 'configurationId', editable: 'never' },
                { title: 'Combinations', field: 'combinations', editable: 'onUpdate' },
            ],
            data: [
                // { id: '1', combinations: 'Trailer 1, trailer 3,  trailer 4' },
                // { id: '2', combinations: ' trailer 1, trailer 2' },
            ],
            vehicleOptions: [],
            configurations:[]
        }
    }

    componentDidMount = () => {
        if(window.location.search!=""){
            let vehicleId = window.location.search.split('=')[1]
            this.state.vehicle={label:vehicleId,value:vehicleId}
            this.getConfigurations(vehicleId)
        }
        this.getVehicles(true)
    }

    handleAutoComplete = name => (options) => {
        this.setState({ [name]: options })
        this.getConfigurations(options.value)
    }

    deleteConfiguration = (oldData,resolve, reject) => {
        this.setState({ loading: true })
        axios.delete(`businesses/${window.location.pathname.split('/')[2]}/configs/${oldData._id}`)
            .then(response => {
                this.setState({
                    loading: false
                })
                toast.success("Configuration Deleted Successfully", {
                    containerId: 'toastMsg',
                })
                let data = this.state.data;
                const index = data.indexOf(oldData);
                data.splice(index, 1);
                this.setState({ data }, () => resolve());
            })
            .catch(error => {
                console.log('error', error)
                this.setState({ loading: false })
                toast.error("Failed To Delete Configuration", {
                    containerId: 'toastMsg',
                })
                reject()
            })
    }

    getVehicles = (flag) => {
        this.setState({ loading: true })
        axios.get(`businesses/${window.location.pathname.split('/')[2]}/mmvehicles/${flag}`)
            .then(response => {
                this.setState({
                    loading: false,
                    vehicleOptions: response.data.data.map(data => {
                        return { label: data.vehicle_id, value: data.vehicle_id }
                    })
                })
            })
            .catch(error => {
                console.log('error', error)
                this.setState({ loading: false })
            })
    }

    getConfigurations = (vehicleId) => {
        this.setState({ loading: true })
        axios.get(`businesses/${window.location.pathname.split('/')[2]}/vehicles/${vehicleId}/configurations`)
            .then(response => {
                let configurations = []
                response.data.data.map((data,index)=>{
                    configurations.push( {_id:data._id, id:index+1,configurationId:data._id, combinations: data.mm_gml_data.map(data => {
                        return data.vehicle_id.vehicle_id
                    }).join() })
                })
                this.setState({
                    loading: false,
                    data: configurations
                })
            })
            .catch(error => {
                console.log('error', error)
                this.setState({ loading: false })
            })
    }

    render() {
        return (
            <div style={{ marginTop: '2rem', marginBottom: "2rem" }} className="container">
                <Paper className="p-3">
                    <div className="pt-1 pl-3">
                        <span className="d-flex">
                            <AutoComplete
                                selectedOption={this.state.vehicle}
                                options={this.state.vehicleOptions}
                                placeholder="Select Vehicle"
                                label="Select Vehicle"
                                handleAutoComplete={this.handleAutoComplete("vehicle")}
                                isSearchable={true}
                                isClearable={true}
                            />
                        </span>
                    </div>
                </Paper><br />
                <MaterialTable
                    title="Vehicle Configurations List"
                    columns={this.state.columns}
                    data={this.state.data}
                    options={{ headerStyle: { zIndex: 0 }, actionsColumnIndex: -1 }}
                    actions={[
                        {
                            icon: 'edit',
                            onClick: (event, rowData) => {
                                this.props.history.push(`${window.location.pathname}/combination/${rowData.configurationId}/edit`)
                            }
                        }
                    ]}
                    editable={{
                        onRowDelete: oldData =>
                            new Promise((resolve, reject) => {
                                this.deleteConfiguration(oldData,resolve, reject)
                            }),
                    }}
                    components={{
                        Actions: props => {
                            if (props.data) {
                                return (<MTableActions {...props} />)
                            } else {
                                return (
                                    <Button disabled={!this.state.vehicle} onClick={() => { this.props.history.push(`${window.location.pathname}/combination/${this.state.vehicle.value}/create`) }} className="ml-3" size="small" variant="contained" color="primary">
                                        Add Vehicle Configuration
                                    </Button>
                                )
                            }
                        }
                    }}
                />
            </div>
        );
    }
}

export default MassSetup;
