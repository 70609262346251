import {
  POST_HANDLE_PASSWORD_TEXTFIELD,
  POST_CHANGE_USER_PASSWORD
  } from "../actions/ActionType";
  const initialState = {
    changePasswordForm : {
        "current_password" : {
          elementType : 'input',
          elementConfig : {
            type : 'password',
            label : 'Current Password'
          },
          value : '',
          errorStatus : false,
          errorMessage : "Enter Current Password"
        },
        "new_password" : {
          elementType : 'input',
          elementConfig : {
            type : 'password',
            label : 'New Password'
          },
          value : '',
          errorStatus : false,
          errorMessage : "Enter New Password"
        },
        "confirm_password" : {
          elementType : 'input',
          elementConfig : {
            type : 'password',
            label : 'Confirm Password'
          },
          value : '',
          errorStatus : false,
          errorMessage : "Enter Confirm Password"
        }
      },
      changepasswordLoaderStatus : false,
      userFirstLoginStatus : localStorage.getItem('userfirstlogin')
  };
  
  const SFM = (state = initialState, action) => {
    let localState = Object.assign({}, state);
    switch (action.type) {
      case POST_HANDLE_PASSWORD_TEXTFIELD :
        localState.changePasswordForm[action.formElementId].value = action.data;
        if(localState.changePasswordForm[action.formElementId].value === ''){
          localState.changePasswordForm[action.formElementId].errorStatus = true;
        }else{
          localState.changePasswordForm[action.formElementId].errorStatus = false;
        }
        return localState;
      case POST_CHANGE_USER_PASSWORD :
        localState.changepasswordLoaderStatus = action.changepasswordLoaderStatus;
        localState.userFirstLoginStatus = action.userFirstLoginStatus;
        Object.keys(localState.changePasswordForm).map(inputField => {
          if(localState.changePasswordForm[inputField].value === ''){
            localState.changePasswordForm[inputField].errorStatus = true;
          }else{
            localState.changePasswordForm[inputField].errorStatus = false;
          }
        })
        return localState;
      default:
        return localState;
    }
  };
  export default SFM;