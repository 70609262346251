import React, { Component } from "react";
import axios from '../../axios';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import { toast } from 'react-toastify';
import MaterialTable from 'material-table'
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import AutoComplete from "../../components/AutoComplete";

class Combination extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            loadingLoadType: false,
            mobileOpen: false,
            anchorEl: null,
            themeDialog: false,
            toolkitOpen: true,
            fatigue_type: '',
            solo_or_twoup: '',
            vehicle_type: '',
            trailers: [],
            time_duration: '',
            breach_type: '',
            upload_ewd_file: '',
            vehicle: null,
            load: null,
            vehicleOptions: [],
            trailerOptions: [],
            massCertificateOptions: [],
            GML: [],
            CML: [],
            HML: [],
            loadTypes: {
                GML: [],
                CML: [],
                HML: []
            },
            configuration: [{ vehicle: null }]
        }
    }

    componentDidMount = () => {
        this.getVehicles(true)
        this.getVehicles(false)
        this.getMassCertificates()
        this.getLoadTypes()
        let urls = window.location.pathname.split('/')
        if (urls[6] == 'edit') {
            this.getConfigurations(urls[5])
        }
        if (urls[5] && urls[6] == 'create') {
            this.state.configuration[0].vehicle = { label: urls[5], value: urls[5] }
            this.forceUpdate()
        }
    }

    componentWillReceiveProps = () => {
        this.getVehicles(true)
        this.getVehicles(false)
        this.getMassCertificates()
        this.getLoadTypes()
        let urls = window.location.pathname.split('/')
        if (urls[6] == 'edit') {
            this.getConfigurations(urls[5])
        }
        if (urls[5] && urls[6] == 'create') {
            this.state.configuration[0].vehicle = { label: urls[5], value: urls[5] }
            this.forceUpdate()
        }
    }

    getMassCertificates = () => {
        this.setState({ loading: true })
        let urls = window.location.pathname.split('/')
        axios.get(`businesses/${urls[2]}/masscertificate`)
            .then(response => {
                this.setState({
                    loading: false,
                    massCertificateOptions: response.data.data.length > 0 ? response.data.data[0].mass_certifcate_data.filter(data=>{
                        return data.status
                    }).map(data => {
                        return { label: data.masscertificate_name, value: data.masscertificate_name }
                    }) : []
                })
            })
            .catch(error => {
                console.log('error', error)
                this.setState({ loading: false })
            })
    }

    getLoadTypes = () => {
        if (!this.state.loadingLoadType) {
            this.setState({ loadingLoadType: true })
            let urls = window.location.pathname.split('/')
            axios.get(`businesses/${urls[2]}/LoadTypes`)
                .then(response => {
                    response.data.data.filter(data=>{
                        return data.status
                    }).map(data => {
                        this.state.loadTypes[data.mass_limit].push({ label: data.loadType, value: data._id, checked: false })
                    })
                    this.setState({
                        loading: false
                    })
                })
                .catch(error => {
                    console.log('error', error)
                    this.setState({ loading: false })
                })
        }
    }

    getVehicles = (flag) => {
        this.setState({ loading: true })
        let urls = window.location.pathname.split('/')
        axios.get(`businesses/${urls[2]}/mmvehicles/${flag}`)
            .then(response => {
                this.setState({
                    loading: false,
                    [flag ? 'vehicleOptions' : 'trailerOptions']: response.data.data.map(data => {
                        return { label: data.vehicle_id, value: data.vehicle_id }
                    })
                })
            })
            .catch(error => {
                console.log('error', error)
                this.setState({ loading: false })
            })
    }

    getConfigurations = (configId) => {
        this.setState({ loading: true })
        let urls = window.location.pathname.split('/')
        axios.get(`businesses/${urls[2]}/configs/${configId}`)
            .then(response => {
                this.state.callibrationDuration = response.data.data.mm_callibration_duration
                this.state.pbs = { label: `PBS ${response.data.data.mm_pbs_level}`, value: Number(response.data.data.mm_pbs_level) }
                this.state.massCertificate = { label: response.data.data.mm_certificate, value: response.data.data.mm_certificate }
                this.state.totalGML = response.data.data.mm_gml_total
                this.state.totalCML = response.data.data.mm_cml_total
                this.state.totalHML = response.data.data.mm_hml_total
                this.state.configuration[0].vehicle = { label: response.data.data.mm_vehicle_id.vehicle_id, value: response.data.data.mm_vehicle_id.vehicle_id }
                let configuration = []
                response.data.data.mm_gml_data.map(data => {
                    if (configuration[data.vehicle_id.vehicle_id]) {
                        configuration[data.vehicle_id.vehicle_id].vehicle = { label: data.vehicle_id.vehicle_id, value: data.vehicle_id.vehicle_id }
                    } else {
                        configuration[data.vehicle_id.vehicle_id] = { vehicle: { label: data.vehicle_id.vehicle_id, value: data.vehicle_id.vehicle_id } }
                    }
                    Object.keys(data).map(key => {
                        if (["front", "rear", "steer"].includes(key) && data[key] && data[key] != "") {
                            if (configuration[data.vehicle_id.vehicle_id][key]) {
                                configuration[data.vehicle_id.vehicle_id][key]['GML'] = data[key]
                            } else {
                                configuration[data.vehicle_id.vehicle_id][key] = {}
                                configuration[data.vehicle_id.vehicle_id][key]['GML'] = data[key]
                            }
                        }
                    })
                })
                response.data.data.mm_cml_data.map(data => {
                    if (configuration[data.vehicle_id.vehicle_id]) {
                        configuration[data.vehicle_id.vehicle_id].vehicle = { label: data.vehicle_id.vehicle_id, value: data.vehicle_id.vehicle_id }
                    } else {
                        configuration[data.vehicle_id.vehicle_id] = { vehicle: { label: data.vehicle_id.vehicle_id, value: data.vehicle_id.vehicle_id } }
                    }
                    Object.keys(data).map(key => {
                        if (["front", "rear", "steer"].includes(key) && data[key] && data[key] != "") {
                            if (configuration[data.vehicle_id.vehicle_id][key]) {
                                configuration[data.vehicle_id.vehicle_id][key]['CML'] = data[key]
                            } else {
                                configuration[data.vehicle_id.vehicle_id][key] = {}
                                configuration[data.vehicle_id.vehicle_id][key]['CML'] = data[key]
                            }
                        }
                    })
                })
                response.data.data.mm_hml_data.map(data => {
                    if (configuration[data.vehicle_id.vehicle_id]) {
                        configuration[data.vehicle_id.vehicle_id].vehicle = { label: data.vehicle_id.vehicle_id, value: data.vehicle_id.vehicle_id }
                    } else {
                        configuration[data.vehicle_id.vehicle_id] = { vehicle: { label: data.vehicle_id.vehicle_id, value: data.vehicle_id.vehicle_id } }
                    }
                    Object.keys(data).map(key => {
                        if (["front", "rear", "steer"].includes(key) && data[key] && data[key] != "") {
                            if (configuration[data.vehicle_id.vehicle_id][key]) {
                                configuration[data.vehicle_id.vehicle_id][key]['HML'] = data[key]
                            } else {
                                configuration[data.vehicle_id.vehicle_id][key] = {}
                                configuration[data.vehicle_id.vehicle_id][key]['HML'] = data[key]
                            }
                        }
                    })
                })
                this.state.configuration = Object.keys(configuration).map(key => {
                    return configuration[key]
                })
                response.data.data.mm_loadtype = response.data.data.mm_loadtype.map(loadType => {
                    return loadType._id
                })
                Object.keys(this.state.loadTypes).map(key => {
                    this.state.loadTypes[key].map((loadType, index) => {
                        if (response.data.data.mm_loadtype.includes(this.state.loadTypes[key][index].value)) {
                            this.state.loadTypes[key][index].checked = true
                        }
                    })
                })
                this.forceUpdate()
            })
            .catch(error => {
                console.log('error', error)
                this.setState({ loading: false })
            })
    }

    postConfigurations = () => {
        if (this.state.callibrationDuration&&this.state.pbs&&this.state.pbs.value&&this.state.massCertificate&&this.state.massCertificate.value&&this.state.configuration[0].vehicle&&this.state.configuration[0].vehicle.value&&!this.state.configuration.find(configuration=>{return configuration.vehicle==null})) {
            this.setState({ loading: true })
            let urls = window.location.pathname.split('/')
            let loadTypes = []
            Object.keys(this.state.loadTypes).map(key => {
                this.state.loadTypes[key].map(loadType => {
                    if (loadType.checked) {
                        loadTypes.push(loadType.value)
                    }

                })
            })
            let reqParams = {
                "mm_callibration_duration": this.state.callibrationDuration,
                "mm_hml_data": this.state.mm_hml_data,
                "mm_cml_data": this.state.mm_cml_data,
                "mm_gml_data": this.state.mm_gml_data,
                "mm_pbs_level": Number(this.state.pbs.value),
                "mm_masscertificate_ref": this.state.massCertificate.value,
                "mm_hml_total": this.state.totalHML,
                "mm_cml_total": this.state.totalCML,
                "mm_gml_total": this.state.totalGML,
                "mm_vehicle_id": this.state.configuration[0].vehicle.value,
                "mm_loadtype": loadTypes
            }
            if (urls[6] == 'edit') {
                axios.put(`businesses/${urls[2]}/configs/${urls[5]}`, reqParams)
                    .then(response => {
                        this.setState({
                            loading: false
                        })
                        toast.success("Configuration Successfully Updated", {
                            containerId: 'toastMsg',
                            onClose: () => this.props.history.push('/' + urls[1] + '/' + urls[2] + '/masssetup?vehicle=' + this.state.configuration[0].vehicle.value)
                        })
                    })
                    .catch(error => {
                        console.log('error', error)
                        this.setState({ loading: false })
                        toast.error("Failed To Update Configuration", {
                            containerId: 'toastMsg',
                        })
                    })
            }
            else {
                axios.post(`businesses/${urls[2]}/configurations`, reqParams)
                    .then(response => {
                        this.setState({
                            loading: false
                        })
                        toast.success("Configuration Successfully Created", {
                            containerId: 'toastMsg',
                            onClose: () => this.props.history.push('/' + urls[1] + '/' + urls[2] + '/masssetup?vehicle=' + this.state.configuration[0].vehicle.value)
                        })
                    }).catch(error => {
                        console.log('error', error)
                        this.setState({ loading: false })
                        toast.error("Failed To Create Configuration", {
                            containerId: 'toastMsg',
                        })
                    })
            }
        } else {
            toast.warn("Star marked fields are required", {
                containerId: 'toastMsg',
            })
        }

    }

    handleAutoComplete = name => (options) => {
        this.setState({ [name]: options })
    }

    handleChange = name => event => {
        this.setState({ [name]: event.target.value })
    }

    render() {
        let urls = window.location.pathname.split('/')
        let data = { GML: {}, CML: {}, HML: {} }
        this.state.configuration.map(configuration => {
            if (configuration.vehicle) {
                this.state.trailerOptions.map((trailer, index) => {
                    if (trailer.value == configuration.vehicle.value) {
                        this.state.trailerOptions.splice(index, 1)
                    }
                })
                data.GML[configuration.vehicle.value] = { vehicle_id: configuration.vehicle.value }
                data.CML[configuration.vehicle.value] = { vehicle_id: configuration.vehicle.value }
                data.HML[configuration.vehicle.value] = { vehicle_id: configuration.vehicle.value }
                Object.keys(configuration).map(key => {
                    if (['steer', 'front', 'rear'].includes(key)) {
                        data.GML[configuration.vehicle.value][key] = configuration[key]['GML']
                        data.CML[configuration.vehicle.value][key] = configuration[key]['CML']
                        data.HML[configuration.vehicle.value][key] = configuration[key]['HML']
                    }
                })
            }
        })
        this.state.totalGML = 0
        this.state.totalCML = 0
        this.state.totalHML = 0
        this.state.mm_hml_data = Object.keys(data.HML).map(key => {
            if (data.HML[key].steer != null) {
                this.state.totalHML = this.state.totalHML + Number(data.HML[key].steer)
            }
            if (data.HML[key].front != null) {
                this.state.totalHML = this.state.totalHML + Number(data.HML[key].front)
            }
            if (data.HML[key].rear != null) {
                this.state.totalHML = this.state.totalHML + Number(data.HML[key].rear)
            }
            return data.HML[key]
        })
        this.state.mm_cml_data = Object.keys(data.CML).map(key => {
            if (data.CML[key].steer != null) {
                this.state.totalCML = this.state.totalCML + Number(data.CML[key].steer)
            }
            if (data.CML[key].front != null) {
                this.state.totalCML = this.state.totalCML + Number(data.CML[key].front)
            }
            if (data.CML[key].rear != null) {
                this.state.totalCML = this.state.totalCML + Number(data.CML[key].rear)
            }
            return data.CML[key]
        })
        this.state.mm_gml_data = Object.keys(data.GML).map(key => {
            if (data.GML[key].steer != null) {
                this.state.totalGML = this.state.totalGML + Number(data.GML[key].steer)
            }
            if (data.GML[key].front != null) {
                this.state.totalGML = this.state.totalGML + Number(data.GML[key].front)
            }
            if (data.GML[key].rear != null) {
                this.state.totalGML = this.state.totalGML + Number(data.GML[key].rear)
            }
            return data.GML[key]
        })

        return (
            <div style={{ marginTop: '2rem', marginBottom: "2rem" }} className="container">
                <Paper className="p-3 mb-3">
                    <h3>Add Vehicle Configuration</h3>
                    <div className="row p-3">
                        <div className="col-md-4">
                            <AutoComplete
                                label="PBS *"
                                placeholder="Select PBS"
                                selectedOption={this.state.pbs}
                                options={[{ label: 'PBS 0', value: 0 }, { label: 'PBS 1', value: 1 }, { label: 'PBS 2', value: 2 }, { label: 'PBS 3', value: 3 }]}
                                handleAutoComplete={this.handleAutoComplete("pbs")}
                                isSearchable={true}
                                isClearable={true}
                                fullWidth
                            />
                        </div>
                        <div className="col-md-4">
                            <TextField
                                label="Callibration Duration *"
                                placeholder="Callibration Duration Value"
                                id="component-error"
                                value={this.state.callibrationDuration}
                                onChange={this.handleChange("callibrationDuration")}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                fullWidth
                            />
                        </div>
                        <div className="col-md-4">
                            <AutoComplete
                                label="Mass Certificate *"
                                placeholder="Select Certificate"
                                selectedOption={this.state.massCertificate}
                                options={this.state.massCertificateOptions}
                                handleAutoComplete={this.handleAutoComplete("massCertificate")}
                                isSearchable={true}
                                isClearable={true}
                                fullWidth
                            />
                        </div>
                    </div>
                    <h4>Load Types</h4>
                    <div className="p-3">
                        {Object.keys(this.state.loadTypes).map(key => {
                            return <ExpansionPanel>
                                <ExpansionPanelSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography>{key}</Typography>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails>
                                    {this.state.loadTypes[key].map(data => {
                                        return <FormControlLabel
                                            style={{ marginBottom: '0rem' }}
                                            control={
                                                <Checkbox
                                                    checked={data.checked}
                                                    onChange={event => {
                                                        this.state.loadTypes[key][this.state.loadTypes[key].findIndex(data => { return data.value == event.target.value })].checked = event.target.checked
                                                        this.forceUpdate()
                                                    }}
                                                    value={data.value}
                                                    color="primary"
                                                />
                                            }
                                            label={data.label}
                                        />
                                    })}
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                        })}
                    </div>
                </Paper>
                <Paper className="p-3">
                    <div className="pt-3">
                        <span className="d-flex">
                            <div className="col-md-6">
                                <AutoComplete
                                    label="Vehicle *"
                                    placeholder="Select Vehicle"
                                    selectedOption={this.state.configuration[0].vehicle}
                                    options={this.state.vehicleOptions}
                                    handleAutoComplete={value => {
                                        this.state.configuration[0].vehicle = value
                                        this.forceUpdate()
                                    }}
                                    isSearchable={true}
                                    isClearable={true}
                                    fullWidth
                                    disabled={urls[6]=='edit'?true:false}
                                />
                            </div>
                            <div className="col-md-2">
                                <FormControlLabel
                                    style={{ marginBottom: '0rem' }}
                                    control={
                                        <Checkbox
                                            checked={this.state.configuration[0].steer != undefined}
                                            onChange={event => {
                                                if (event.target.checked) {
                                                    this.state.configuration[0].steer = { GML: "", CML: "", HML: "" }
                                                } else {
                                                    delete this.state.configuration[0]['steer']
                                                }
                                                this.forceUpdate()
                                            }}
                                            value=""
                                            color="primary"
                                        />
                                    }
                                    label="Steer"
                                />
                            </div>
                            <div className="col-md-2">
                                <FormControlLabel
                                    style={{ marginBottom: '0rem' }}
                                    control={
                                        <Checkbox
                                            checked={this.state.configuration[0].front != undefined}
                                            onChange={event => {
                                                if (event.target.checked) {
                                                    this.state.configuration[0].front = { GML: "", CML: "", HML: "" }
                                                } else {
                                                    delete this.state.configuration[0]['front']
                                                }
                                                this.forceUpdate()
                                            }}
                                            value=""
                                            color="primary"
                                        />
                                    }
                                    label="Front"
                                />
                            </div>
                            <div className="col-md-2">
                                <FormControlLabel
                                    style={{ marginBottom: '0rem' }}
                                    control={
                                        <Checkbox
                                            checked={this.state.configuration[0].rear != undefined}
                                            onChange={event => {
                                                if (event.target.checked) {
                                                    this.state.configuration[0].rear = { GML: "", CML: "", HML: "" }
                                                } else {
                                                    delete this.state.configuration[0]['rear']
                                                }
                                                this.forceUpdate()
                                            }}
                                            value=""
                                            color="primary"
                                        />
                                    }
                                    label="Rear"
                                />
                            </div>
                        </span>
                        {Object.keys(this.state.configuration[0]).map((key, keyIndex) => {
                            if (!keyIndex) {
                                return null;
                            }
                            return <span className="d-flex mt-4">
                                <div className="col-md-4">
                                    <TextField
                                        label={`${key.replace(/^[a-z]/, function (m) { return m.toUpperCase() })} GML *`}
                                        placeholder={`${key.replace(/^[a-z]/, function (m) { return m.toUpperCase() })} GML Value`}
                                        id="component-error"
                                        value={this.state.configuration[0][key].GML}
                                        onChange={event => {
                                            this.state.configuration[0][key].GML = event.target.value
                                            this.forceUpdate()
                                        }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        fullWidth
                                    />
                                </div>
                                <div className="col-md-4">
                                    <TextField
                                        label={`${key.replace(/^[a-z]/, function (m) { return m.toUpperCase() })} CML *`}
                                        placeholder={`${key.replace(/^[a-z]/, function (m) { return m.toUpperCase() })} CML Value`}
                                        id="component-error"
                                        value={this.state.configuration[0][key].CML}
                                        onChange={event => {
                                            this.state.configuration[0][key].CML = event.target.value
                                            this.forceUpdate()
                                        }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        fullWidth
                                    />
                                </div>
                                <div className="col-md-4">
                                    <TextField
                                        label={`${key.replace(/^[a-z]/, function (m) { return m.toUpperCase() })} HML *`}
                                        placeholder={`${key.replace(/^[a-z]/, function (m) { return m.toUpperCase() })} HML Value`}
                                        id="component-error"
                                        value={this.state.configuration[0][key].HML}
                                        onChange={event => {
                                            this.state.configuration[0][key].HML = event.target.value
                                            this.forceUpdate()
                                        }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        fullWidth
                                    />
                                </div>
                            </span>
                        })}
                    </div>
                    <div className="row p-3 ml-0">
                        <Button onClick={() => {
                            this.state.configuration.push({ vehicle: null })
                            this.forceUpdate()
                        }}
                            className="mt-3 pull-right" variant="contained" color="primary">
                            Add Trailer Configuration
                        </Button>
                    </div>
                    {this.state.configuration.map((configuration, index) => {
                        if (!index) {
                            return null;
                        }
                        return <div className="pt-3">
                            <span className="d-flex mb-4">
                                <div className="col-md-6">
                                    <AutoComplete
                                        label="Trailer *"
                                        placeholder="Select Trailer"
                                        selectedOption={this.state.configuration[index].vehicle}
                                        options={this.state.trailerOptions}
                                        handleAutoComplete={value => {
                                            this.state.configuration[index].vehicle = value
                                            this.forceUpdate()
                                        }}
                                        isSearchable={true}
                                        isClearable={true}
                                        fullWidth
                                    />
                                </div>
                                <div className="col-md-2">
                                    <FormControlLabel
                                        style={{ marginBottom: '0rem' }}
                                        control={
                                            <Checkbox
                                                checked={this.state.configuration[index].front != undefined}
                                                onChange={event => {
                                                    if (event.target.checked) {
                                                        this.state.configuration[index].front = { GML: "", CML: "", HML: "" }
                                                    } else {
                                                        delete this.state.configuration[index]['front']
                                                    }
                                                    this.forceUpdate()
                                                }}
                                                value=""
                                                color="primary"
                                            />
                                        }
                                        label="Front"
                                    />
                                </div>
                                <div className="col-md-2">
                                    <FormControlLabel
                                        style={{ marginBottom: '0rem' }}
                                        control={
                                            <Checkbox
                                                checked={this.state.configuration[index].rear != undefined}
                                                onChange={event => {
                                                    if (event.target.checked) {
                                                        this.state.configuration[index].rear = { GML: "", CML: "", HML: "" }
                                                    } else {
                                                        delete this.state.configuration[index]['rear']
                                                    }
                                                    this.forceUpdate()
                                                }}
                                                value=""
                                                color="primary"
                                            />
                                        }
                                        label="Rear"
                                    />
                                </div>
                                <div className="col-md-2 mt-2">
                                    <center><Icon style={{ cursor: 'pointer' }} onClick={() => {
                                        this.state.configuration.splice(index, 1)
                                        this.setState({ true: true })
                                    }}>delete</Icon></center>
                                </div>
                            </span>
                            {Object.keys(configuration).map((key, keyIndex) => {
                                if (!keyIndex) {
                                    return null;
                                }
                                return <span className="d-flex mb-4">
                                    <div className="col-md-4">
                                        <TextField
                                            label={`${key.replace(/^[a-z]/, function (m) { return m.toUpperCase() })} GML *`}
                                            placeholder={`${key.replace(/^[a-z]/, function (m) { return m.toUpperCase() })} GML Value`}
                                            id="component-error"
                                            value={this.state.configuration[index][key].GML}
                                            onChange={event => {
                                                this.state.configuration[index][key].GML = event.target.value
                                                this.forceUpdate()
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            fullWidth
                                        />
                                    </div>
                                    <div className="col-md-4">
                                        <TextField
                                            label={`${key.replace(/^[a-z]/, function (m) { return m.toUpperCase() })} CML *`}
                                            placeholder={`${key.replace(/^[a-z]/, function (m) { return m.toUpperCase() })} CML Value`}
                                            id="component-error"
                                            value={this.state.configuration[index][key].CML}
                                            onChange={event => {
                                                this.state.configuration[index][key].CML = event.target.value
                                                this.forceUpdate()
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            fullWidth
                                        />
                                    </div>
                                    <div className="col-md-4">
                                        <TextField
                                            label={`${key.replace(/^[a-z]/, function (m) { return m.toUpperCase() })} HML *`}
                                            placeholder={`${key.replace(/^[a-z]/, function (m) { return m.toUpperCase() })} HML Value`}
                                            id="component-error"
                                            value={this.state.configuration[index][key].HML}
                                            onChange={event => {
                                                this.state.configuration[index][key].HML = event.target.value
                                                this.forceUpdate()
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            fullWidth
                                        />
                                    </div>
                                </span>
                            })}
                            <hr className="mt-4" />
                        </div>
                    })}

                    <div className="row p-3">
                        <div className="col-md-4">
                            <span><b>Total GML: </b> {this.state.totalGML}</span>
                        </div>
                        <div className="col-md-4">
                            <span><b>Total CML: </b> {this.state.totalCML}</span>
                        </div>
                        <div className="col-md-4">
                            <span><b>Total HML: </b> {this.state.totalHML}</span>
                        </div>
                    </div>
                    <br />
                </Paper>
                <center>
                    <Button onClick={this.postConfigurations} className="mt-4" variant="contained" color="primary">
                        Save Vehicle Configuration
                    </Button>
                </center>
            </div>
        );
    }
}

export default Combination;
