import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import { withRouter } from 'react-router-dom';

import AddDistributor from "./AddDistributor/index";

import {
  getDistributors,
  handleEditDistributorDialogOpen,
  handleEditDistributorDialogClose,
  navigateToAddDistributor,
  navigateToEditDistributorAdmin
} from '../../../actions/Distributor';
import {
  distributorSelect,
  setPartnerMenus
} from '../../../actions/Sidebar';
import SGMaterialTable from '../../../components/UI/Table/SGMaterialTable';
import axios from "../../../axios";
import {toast} from "react-toastify";

import { roles } from '../../../constants/roles';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { BillGeneratorService } from '../../../utils/BillGeneratorService';

const styles = theme => ({
    root: {
        width : '100%',
        flexGrow: 1,
    },
    table: {
        minWidth: 500,
    },
    paper: {
        padding: theme.spacing.unit * 2,
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
  card: {
    minWidth: 275,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  button: {
    margin: theme.spacing.unit,
  },
  input: {
    display: 'none',
  },
  tableWrapper: {
    overflowX: 'auto',
  },
});
class ListDistributor extends Component{
    componentDidMount(){
        this.props.setPartnerMenus()
        this.props.getDistributors();
    }
 
    state = {     
      csvData:[],
      partnerArray: [],
      moveResellerDialogOpen: false,
      selectedTargetPartnerId: null,
      currentResellerToMove: null
   }

  billingService = new BillGeneratorService((status) => this.setState({apiLoading: status}));

  selectResellerForMove = (rowData) => {

    axios.get(`sg/partners`)
        .then(response => {
            // sort ascending
            let partners = response.data.data;
            partners.sort((a, b) => a.partner_name.localeCompare(b.partner_name) );
            this.setState({partnerArray: partners});
        });
    this.setState({currentResellerToMove: rowData});
  }
   
  moveResellerToPartner = () => {
    const partnerId = localStorage.getItem('partner_id');
    let resellerToUpdate = this.state.currentResellerToMove;
    const distributorId = resellerToUpdate.distributor_id;
    const url = `/partners/${partnerId}/distributors/${distributorId}`;

    let targetPartner = this.state.partnerArray.find(x => x._id === this.state.selectedTargetPartnerId);
    resellerToUpdate.partner_id = targetPartner._id;

    axios.put(url, resellerToUpdate)
      .then(response => {
        toast.success('Reseller moved successfully', {containerId: 'toastMsg'});
        this.setState({
          partnerArray: [],
          moveResellerDialogOpen: false,
          selectedTargetPartnerId: null,
          currentResellerToMove: null
        });
        this.props.getDistributors();
      })
      .catch(error => {
        toast.error('Error moving reseller!', {containerId: 'toastMsg'});
      });
   }

    render(){
        let {
           distributorInformations,
           editDistributorDialogOpenStatus,
           showDistributorListLoaderStatus
          } = this.props.distributor;
        let{
          menuItems
        } = this.props.sidebarState;
             
        showDistributorListLoaderStatus = showDistributorListLoaderStatus || this.state.apiLoading;
        const role = localStorage.getItem('role');
        const isReporter = role === roles.PARTNER_REPORTER;

        let actionDisArrays = [];
        if (!isReporter){
          actionDisArrays = actionDisArrays.concat([
            {
              icon: 'call_made',
              tooltip: 'Distributor Select',
              onClick: (event, rowData) => this.props.distributorSelect(rowData.distributor_id, menuItems['business_list'].urlpattern)
          },
          {
              icon: 'edit',
              tooltip: 'Edit',
              onClick: (event, rowData) => {
                  localStorage.setItem('distributor_id', rowData.distributor_id);
                  this.props.navigateToEditDistributorAdmin(menuItems['distributor_edit'].urlpattern);
              }
          },
          ])
        }
        
        if (role === roles.SG_ADMIN) {
          actionDisArrays.push({
              icon: 'rv_hookup',
              tooltip: 'Move to another partner',
              onClick: (event, rowData) => {
                  this.setState({ moveResellerDialogOpen: true})
                  this.selectResellerForMove(rowData)
              }
          })
        }
        
        actionDisArrays.push({
          icon: 'monetization_on',
          iconProps: { color: "red" },
          tooltip: 'Billing Report',
          onClick: (event, rowData) => {  
            this.billingService.calculateBillForDistributor(rowData); 
            localStorage.setItem('distributor_id', rowData.distributor_id);              
          }
        }
        );

        return(
            <div className = "container" style = {{marginTop : '2rem', marginBottom : "2rem"}}>
              <Paper>
              <div className = "pt-1 pl-3">
                <span className = "d-flex">
                  <div className = "mr-auto" style = {{paddingTop : "0"}}>
                      <h4 style = {{marginTop : "1rem", textTransform : "uppercase"}}>
                      Reseller List
                      </h4>
                  </div>
                  <div className = "d-flex flex-wrap align-content-center">
                    { isReporter || <Button
                        variant="contained"
                        color="primary"
                        onClick = {() => {
                          this.props.navigateToAddDistributor(menuItems['distributor_create'].urlpattern);
                          
                        }}
                        className = "mr-2"
                      >
                        <i className="material-icons" style = {{paddingRight : "0.5rem"}}>
                            add_circle_outline
                        </i>
                        Add Reseller
                    </Button> }
                  </div>
                </span>
              </div>
                {
                  showDistributorListLoaderStatus ?
                  <div style = {{height : "20rem"}} className = "d-flex justify-content-center align-items-center">
                      <CircularProgress color = "primary" />
                  </div> :
                  <SGMaterialTable
                    columns={this.props.distributor.cols}
                    data={distributorInformations}
                    title=""
                    actions={actionDisArrays}
                  />
                }
              </Paper>
              <Dialog
                  open={editDistributorDialogOpenStatus}
                  onClose={() => {this.props.handleEditDistributorDialogClose()}}
                  maxWidth = 'md'
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
              >
                  <AddDistributor/>
              </Dialog>
              <Dialog
                    open={this.state.moveResellerDialogOpen}
                    maxWidth = 'sm'
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">
                    <DialogTitle id="form-dialog-title">Move {this.state.currentResellerToMove?.distributor_name || ''} to a new partner?</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Select the New Partner:<br/>
                            <Select onChange={(event) => {this.setState({selectedTargetPartnerId: event.target.value})}}>
                                { this.state.partnerArray.map(partner => <MenuItem value={partner._id}>{partner.partner_name}</MenuItem> ) }
                            </Select>
                        </DialogContentText>
                        <Button
                            variant="contained"
                            onClick = {() => this.moveResellerToPartner()}
                            className = "mr-2"
                            color="primary"
                        >
                            Move Reseller
                        </Button>&nbsp;
                        <Button
                            variant="contained"
                            onClick = {() => {this.setState({moveResellerDialogOpen: false})}}
                            className = "mr-2"
                        >
                            Close
                        </Button>
                    </DialogContent>
                </Dialog>
            </div>
            
        )
    }
}

const mapStateToProps = (state) => {
  return{
      distributor : state.distributor,
      sidebarState : state.sidebarState
  }
}

const mapDispatchToProps = (dispatch) => {
  return{
    getDistributors : () =>{
        return dispatch(getDistributors());
    },
    distributorSelect : (distributorId, urlpattern) => {
      return dispatch(distributorSelect(distributorId, urlpattern))
    },
    setPartnerMenus : () => {
      return dispatch(setPartnerMenus())
    },
    handleEditDistributorDialogOpen : (rowData) => {
      return dispatch(handleEditDistributorDialogOpen(rowData))
    },
    handleEditDistributorDialogClose : () => {
      return dispatch(handleEditDistributorDialogClose())
    },
    navigateToAddDistributor : (url) => {
      return dispatch(navigateToAddDistributor(url))
    },
    navigateToEditDistributorAdmin : (url) => {
      return dispatch(navigateToEditDistributorAdmin(url))
    }
  }
}
export default connect(mapStateToProps, mapDispatchToProps) (withStyles(styles)(withRouter(ListDistributor)));
