import React, { Component } from "react";
import MaterialTable from "material-table";
import Dialog from '@material-ui/core/Dialog';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { toast } from 'react-toastify';
import {
  getEntryTypeList,
  editEntryType
} from '../../actions/EntryTypes';

class ListEntryTypes extends Component {
  componentDidMount(){
    this.props.getEntryTypeList();
}
  render() {
    let {
      entryTypeInformations,
      entryTypeListcols ,
    } = this.props.entryTypes
    return (
       <div style = {{marginTop : '2rem', marginBottom : "2rem"}} className = "container">
      <Paper>
        <div className = "pt-1 pl-3">
          <span className = "d-flex">
            <div className = "mr-auto" style = {{paddingTop : "0"}}>
                <h4 style = {{marginTop : "1rem", textTransform : "uppercase"}}>Entry Type List</h4>
            </div>
            <div className = "d-flex flex-wrap align-content-center">
                <Button 
                    variant="contained" 
                    color="primary"
                    onClick = {() => this.props.history.push("/sgadminportal/entrytypes/newentrytype")}
                    className = "mr-2"
                >
                    <i className="material-icons" style = {{paddingRight : "0.5rem"}}>
                        add_circle_outline
                    </i>
                    Add Entry Type
                </Button>
            </div>
          </span>
        </div>
      {entryTypeInformations.length == 0 ? 
        <div style = {{height : "20rem"}} className = "d-flex justify-content-center align-items-center">
            <CircularProgress color = "primary" />
        </div>:
          <MaterialTable
            columns={entryTypeListcols}
            data={entryTypeInformations}
            title=""
            editable={{
              onRowUpdate: (newData, oldData) =>
                new Promise((resolve, reject) => {
                  if(newData.description != ''){
                    resolve(this.props.editEntryType(newData, entryTypeInformations));
                  }else{
                    toast.error("Description field should not be blank", {containerId: 'toastMsg'});
                    reject();
                  }
                }),
            }}
            options={{ actionsColumnIndex: -1}}
          />
      }
      </Paper>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return{
    entryTypes : state.entryTypes
  }
}

const mapDispatchToProps = dispatch => {
  return{
    getEntryTypeList : () => {
          return dispatch(getEntryTypeList())
      },
      editEntryType : (newData, entryTypeInformations) => {
        return dispatch(editEntryType(newData, entryTypeInformations))
      }
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(ListEntryTypes);