import React, { Component } from 'react';
import { connect } from 'react-redux';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import CardHeader from '@material-ui/core/CardHeader';
import FormGroup from '@material-ui/core/FormGroup';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';


import Input from "../../../components/UI/Input/Input";

import {
    businessFirstLoginChangePasswordInputFieldChange,
    initializeFirstLoginChangePassword
} from '../../../actions/Business';
class ChangePassword extends Component{
    componentDidMount(){
        this.props.initializeFirstLoginChangePassword();
    }
    render(){
        let{
            firstLoginBusinessChangePasswordForm
        } = this.props.businessState;
        const formElementArray = [];
        for(let key in firstLoginBusinessChangePasswordForm){
            formElementArray.push({
                id : key,
                config : firstLoginBusinessChangePasswordForm[key]
            })
        }
        let form = (
            <FormGroup row>
                <Grid container spacing={24}>
                    {
                        formElementArray.map(formElement => (
                            <Grid item xs={12} sm = {12} key = {formElement.id}>
                                <Input 
                                    key = {formElement.id}
                                    name = {formElement.config.elementConfig.label}
                                    elementType={formElement.config.elementType}
                                    elementConfig={formElement.config.elementConfig}
                                    value={formElement.config.value}
                                    menuItems = {formElement.config.menuItems}
                                    error = {formElement.config.errorStatus}
                                    helperText = {formElement.config.errorMessage}
                                    changed={(event) => this.props.businessFirstLoginChangePasswordInputFieldChange(formElement.id, event )}
                                    touched = {(event) => this.props.businessFirstLoginChangePasswordInputFieldChange(formElement.id, event )}
                                />
                            </Grid>
                        )
                    )
                    }
                </Grid>
            </FormGroup>
        )
        return(
            <div>
                <Card className = "pl-3 pr-3 pt-3">
                    <CardContent>
                        <FormGroup row>
                            <Grid container spacing={24}>
                                {form}
                            </Grid>
                        </FormGroup>
                    </CardContent>
                </Card>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return{
        businessState : state.business
    }
}

const mapDispatchToProps = dispatch => {
    return{
        businessFirstLoginChangePasswordInputFieldChange : (inputFieldID, event) => {
            return dispatch(businessFirstLoginChangePasswordInputFieldChange(inputFieldID, event))
        },
        initializeFirstLoginChangePassword  :() => {
            return dispatch(initializeFirstLoginChangePassword())
        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);