import React, { Component } from 'react';
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import FormGroup from "@material-ui/core/FormGroup";
import Button from "@material-ui/core/Button";
import { connect } from 'react-redux';
import Input from "../../../components/UI/Input/Input";
import SGLoader from "../../../components/UI/Loader/SGLoader";
import CircularProgress from '@material-ui/core/CircularProgress';

import {
    getNotificationDetails,
    handleNotificationSettingFormField,
    onSubmitNotificationSetting
} from "../../../actions/Checklist";

class NotificationSetting extends Component{
    componentDidMount(){
        this.props.getNotificationDetails()
    }
    render() {
        let {
            notificationSettingsForm,
            getNotificationSettingLoaderStatus,
            onSubmitNotificationSettingLoaderStatus
        } = this.props.checkListState;
        const formElementArray = [];
        for(let key in notificationSettingsForm){
          formElementArray.push({
            id : key,
            config : notificationSettingsForm[key]
          })
        }
        let form = (
            <form>
                <FormGroup row className="pl-3 pr-1">
                    <Grid container spacing={24}>
                        {
                            formElementArray.map(formElement => (
                                <Grid item xs={5} sm={5}>
                                    <Input 
                                        key = {formElement.id}
                                        name = {formElement.config.elementConfig.label}
                                        elementType={formElement.config.elementType}
                                        elementConfig={formElement.config.elementConfig}
                                        value={formElement.config.value}
                                        menuItems = {formElement.config.menuItems}
                                        error = {formElement.config.errorStatus}
                                        helperText = {formElement.config.errorMessage}
                                        changed = {(event) => this.props.handleNotificationSettingFormField(formElement.id, event)}
                                        disabledStatus={formElement.config.disabledStatus}
                                        maxLength={formElement.config.maxLength}
                                    />
                                </Grid>
                            ))
                        }
                    <Grid item xs={2} sm={2}>
                        {
                            onSubmitNotificationSettingLoaderStatus ? <CircularProgress color = "primary" /> :
                            <Button
                                variant="contained"
                                color="primary"
                                className="mt-4"
                                onClick = {() => {this.props.onSubmitNotificationSetting(notificationSettingsForm)}}
                            >
                            Submit
                            </Button>
                        }
                    </Grid>
                </Grid>
              </FormGroup>
            </form>
          )
        return(
            <div>
                <Grid container spacing={24}>
                <Grid item xs={1} sm={2} />
                    <Grid item xs={10} sm={8}>
                        <Card className="pl-3 pr-3 pt-3">
                            <CardHeader title="Notification Setting" />
                                <CardContent>
                                    {
                                        getNotificationSettingLoaderStatus ? 
                                            <div className = "d-flex justify-content-center">
                                                <CircularProgress />
                                            </div>
                                            :form
                                    }
                                </CardContent>
                                <CardActions />
                            </Card>
                    </Grid>
                    <Grid item xs={1} sm={2} />
                </Grid>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return{
        checkListState : state.checkList
    }
}

const mapDispatchToProps = dispatch => {
    return{
        getNotificationDetails : () => {
            return dispatch(getNotificationDetails())
        },
        handleNotificationSettingFormField : (formElementId, event) => {
            return dispatch(handleNotificationSettingFormField(formElementId, event))
        },
        onSubmitNotificationSetting : (notificationSettingsForm) => {
            return dispatch(onSubmitNotificationSetting(notificationSettingsForm))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NotificationSetting);