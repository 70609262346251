import {
    SET_EXTERNAL_SYS_INPUT_FIELD,
    SET_EXTERNAL_SYS_DEFAULT_VALUE,
    SET_EXTERNAL_SYS_SUBMITTED_DATA,
    SET_EXTERNAL_SYS_LIST_DEFAULT,
    UPDATE_EXTERNAL_SYS_INFO,
    DELETE_EXTERNAL_SYS_INFO
} from './ActionType';


import { toast } from 'react-toastify';
import {
    getActulaURL
} from "../utils/Helper";

import axios from "../axios";


export const initializeExternalSysComponent = () => {
    return (dispatch) => {
      return dispatch(setExternalSystemDefault())
    }
}

export const initializeExternalSysListComponent = () => {
  return (dispatch) => {
    return dispatch(setExternalSystemListDefault())
  }
}

export const setExternalSystemDefault = () => {
    return {
      type: SET_EXTERNAL_SYS_DEFAULT_VALUE
    }
}

export const onSubmit = (externalSystemForm, props) => {
  return (dispatch) => {
      let fieldValidationStatus = true;

      Object.keys(externalSystemForm).map((textField) => {
          if(externalSystemForm[textField].value  == ''){
              fieldValidationStatus = false;
          }
      })

      if(fieldValidationStatus) {
          dispatch(setSubmittedData(true));

          let addExternalSystemReq = {};
          let showLoaderStatus = false;

          addExternalSystemReq['name'] = externalSystemForm['external_system_name'].value;
          addExternalSystemReq['endpoint'] = externalSystemForm['external_system_url'].value;
          addExternalSystemReq['key'] = externalSystemForm['external_system_key'].value;

          return axios.post('/externalsystem', addExternalSystemReq)

          .then(response => {
                  toast.success("External System Created Successfully", {
                                    containerId: 'toastMsg',
                                    onClose :  () => props.history.push('/sgadminportal/extsyslist')
                                    }
                                );
                  dispatch(setSubmittedData(showLoaderStatus));
          })
          .catch(error => {
            if(error.response.status == 403){
                  localStorage.clear();
                  window.location.href = "/";
              }else{
                  toast.error(error.response.data.message, {containerId: 'toastMsg'});
                  dispatch(setSubmittedData(false));
              }
          })
      }
  }
}

export const setSubmittedData = (showLoaderStatus) => {
  return {
    type: SET_EXTERNAL_SYS_SUBMITTED_DATA,
    showLoaderStatus: showLoaderStatus
  }
}

export const onFieldChange = (textField, event) => {
    return dispatch => {
      return dispatch(setFieldChange(textField, event));
    }
}

export const setFieldChange = (textField, event) => {
    return {
        type: SET_EXTERNAL_SYS_INPUT_FIELD,
        textField: textField,
        data: event.target.value
    }
}

export const getExternalSystemsList = () => {
    return(dispatch) => {
      let externalSystem = {};
      let externalSystemRowData = [];

      return axios.get('/externalsystems')
      .then(response => {
            for(let i = 0; i < response.data.data.length; i++){
              externalSystem = {};
              externalSystem['ID'] = response.data.data[i]._id;
              externalSystem['name'] = response.data.data[i].name;
              externalSystem['endpoint'] = response.data.data[i].endpoint;
              externalSystem['key'] = response.data.data[i].key.toString();
              externalSystemRowData.push(externalSystem);
            }
            dispatch(setExternalSystemListDefault(externalSystemRowData));
      })
      .catch(error => {
          if(error.response.status == 403){
              localStorage.clear();
              window.location.href = "/";
          }
      })
    }
}

export const setExternalSystemListDefault = (externalSystemRowData) => {
  return {
    type: SET_EXTERNAL_SYS_LIST_DEFAULT,
    externalSystemRowData: externalSystemRowData
  }
}

export const editExternalSystem = (rowData, externalSystemRowData) => {
  let reqURL = '/sg/entrytypes/' + rowData.entrytypeid;
  let updateReq = {};
  updateReq['name'] = rowData.name;
  updateReq['endpoint'] = rowData.endpoint;
  updateReq['key'] = rowData.key;
  return (dispatch) => {
      return axios.put('externalsystem/' + rowData.ID, updateReq)
      .then(response => {
              const index = externalSystemRowData.findIndex(d => {
                  return d.ID === rowData.ID;
              });
              externalSystemRowData[index].name = rowData.name;
              externalSystemRowData[index].endpoint = rowData.endpoint;
              externalSystemRowData[index].key = rowData.key;
              toast.success("External System Updated Successfully", {containerId: 'toastMsg'});
              dispatch(updateExternalSystemInfo(externalSystemRowData));
      })
      .catch(error => {
           if(error.response.status == 403){
              localStorage.clear();
              toast.error(
                          "Session Expired ! Please login again",
                            {
                              containerId: 'toastMsg',
                              onclose : () => window.location.href = "/"
                            }
                          );
           }else{
              toast.error(error.response.data.message, {containerId: 'toastMsg'});
           }

           dispatch(updateExternalSystemInfo(externalSystemRowData));
      })
  }
}

export const updateExternalSystemInfo = (externalSystemRowData) => {
  return{
      type : UPDATE_EXTERNAL_SYS_INFO,
      externalSystemRowData : externalSystemRowData
  }
}


export const deleteExternalSystem = (rowData, externalSystemRowData) => {
  return (dispatch) => {
    let reqPath = "/externalsystem/" + rowData.ID;
    return axios.delete(reqPath)
    .then(response => {
        const index = externalSystemRowData.findIndex(d => {
            return d.ID === rowData.ID;
        });
        if (index >= 0) {
          externalSystemRowData.splice(index, 1);
        }

        toast.success("External system has been removed", {
            containerId: 'toastMsg'
        });
        dispatch(deleteExternalSystemInfo(externalSystemRowData))
    })
    .catch( error => {
        toast.error(error.message, {containerId: 'toastMsg'});
        dispatch(deleteExternalSystemInfo(externalSystemRowData))
    })
  }
}

export const deleteExternalSystemInfo = (externalSystemRowData) => {
  return{
      type : DELETE_EXTERNAL_SYS_INFO,
      externalSystemRowData : externalSystemRowData
  }
}
