import {
  SET_AXEL_CONFIG_TEXT_FIELD,
  SET_AXELCONFIG_LIST,
  SUBMIT_AXEL_CONFIG,
  UPDATE_AXELCONFIG_INFO,
  SET_DEFAULT_VALUE_TO_AXEL_CONFIG_COMPONENT,
  SET_UPLOAD_INPUT_CHANGE_AXLE
} from "./ActionType";
import axios from '../axios';
import { toast } from 'react-toastify';
import {
  convertToBase64, getActulaURL
} from "../utils/Helper";

export const initializeaxelConfigComponent = () => {
  return (dispatch) => {
    return dispatch(setDefaultValueToaxelConfigComponent())
  }
}
export const setDefaultValueToaxelConfigComponent = () => {
  return {
    type: SET_DEFAULT_VALUE_TO_AXEL_CONFIG_COMPONENT
  }
}

export const handleaxelConfigTextFields = (textField, event) => {
  return dispatch => {
    return dispatch(setaxelConfigTextFields(textField, event));
  };
};

export const setaxelConfigTextFields = (textField, event) => {
  return {
    type: SET_AXEL_CONFIG_TEXT_FIELD,
    textField: textField,
    data: event.target.value
  };
};


export const getaxelConfigList = () => {
  let axelConfig = {};
  let listaxelConfigLoaderStatus = true;
  let axelConfigList = [];
  // let businessId = localStorage.getItem('business_id');
  let reqURL = "/sg/axelconfig";
  return (dispatch) => {
    dispatch(setaxelConfigList(axelConfigList, listaxelConfigLoaderStatus));
    return axios.get(reqURL)
      .then(response => {
        listaxelConfigLoaderStatus = false;
        if (response.data!== null) {
          let newdata = response.data.data
          console.log(response.data.data)
          for (let i = 0; i < newdata.length; i++) {
            // console.log(newdata.mass_certifcate_data[i])
            axelConfig = {};
            axelConfig['_id'] = newdata[i]._id;
            axelConfig['axelGroupType'] = newdata[i].AxelGroupType;
            // axelConfig['axelConfigObjectData'] = newdata.mass_certifcate_data[i].
            axelConfig['status'] = newdata[i].Status;
            axelConfig['bucket'] = newdata[i].AxelConfigImageObject.bucket_name;
            axelConfig['key'] = newdata[i].AxelConfigImageObject.bucket_key;
            axelConfigList.push(axelConfig);
          }
        }
        dispatch(setaxelConfigList(axelConfigList, listaxelConfigLoaderStatus));
      })
      .catch(error => {
        listaxelConfigLoaderStatus = false;
        //if (error.response.status == 403) {
        //  localStorage.clear();
        //  window.location.href = "/";
        //}
        dispatch(setaxelConfigList(axelConfigList, listaxelConfigLoaderStatus));
      })
  }
}

export const setaxelConfigList = (axelConfigList, listaxelConfigLoaderStatus) => {
  return {
    type: SET_AXELCONFIG_LIST,
    axelConfigList: axelConfigList,
    listaxelConfigLoaderStatus: listaxelConfigLoaderStatus
  }
}

/**************************************************************************************** */

export const onSubmit = (axelConfigInfo, base64_string, props) => {
  return (dispatch) => {
    let showLoaderStatus = false;
    let fieldValidationStatus = true;
    // let businessId = localStorage.getItem('business_id');
    let fileExtension;
    let mimeType = '';
    let reqURL = "/sg/axelconfig";
    let axelConfigObjectData = {}
    let addaxelConfigReq = {};
    let createdInfo = {};

    Object.keys(axelConfigInfo.axelConfigForm).map((textField) => {
      if (axelConfigInfo.axelConfigForm[textField].value == '' && textField !== 'axel_image') {
        fieldValidationStatus = false;
        axelConfigInfo.axelConfigForm[textField].errorStatus = true;
      }
    })
    dispatch(setSubmittedData(
      axelConfigInfo.axelConfigListrows,
      showLoaderStatus,
      axelConfigInfo.axelConfigForm
    )
    );
    if (fieldValidationStatus) {
      showLoaderStatus = true;

      let fileName = (axelConfigInfo.axelConfigForm['axel_image'].value)
      fileName = fileName.split('.');
      // console.log(fileName)
      fileExtension = fileName.pop();
      // console.log("chek" +fileExtension)
      dispatch(setSubmittedData(
        axelConfigInfo.axelConfigListrows,
        showLoaderStatus,
        axelConfigInfo.axelConfigForm
      )
      );

      if (fileExtension === 'docx') {
        mimeType = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
      }
      if (fileExtension === 'doc') {
        mimeType = 'application/msword';
      }
      if (fileExtension === 'pdf') {
        mimeType = 'application/pdf';
      }
      if (fileExtension === 'jpeg') {
        mimeType = 'application/jpeg';
      }
      if (fileExtension === 'jpg') {
        mimeType = 'application/jpeg';
      }
      addaxelConfigReq["axelConfigObjectData"] = { 'file_type': mimeType, 'base64_string': base64_string }
      addaxelConfigReq["axelGroupType"] = axelConfigInfo.axelConfigForm['axelGroupType'].value;
      addaxelConfigReq["status"] = true;
      console.log("inserted data" + mimeType)
      return axios.post(reqURL, addaxelConfigReq)

        .then(response => {
          showLoaderStatus = false;
          toast.success("axelConfig Created Successfully", {
            containerId: 'toastMsg',
            onClose: () => props.history.push('/sgadminportal/axelconfig')
          }
            //   createdInfo = {};
            //   createdInfo['axelConfigid'] = response.data.data.axelConfigid;
            //   createdInfo['description'] = response.data.data.description;
            //   createdInfo['current'] = response.data.data.current;
            //   axelConfigInfo.axelConfigListrows.unshift(createdInfo);
            //   Object.keys(axelConfigInfo.axelConfigForm).map((textField) => {
            //     axelConfigInfo.axelConfigForm[textField].value = '';
            //   })
            //   
          );
          dispatch(setSubmittedData(
            axelConfigInfo.axelConfigListrows,
            showLoaderStatus,
            axelConfigInfo.axelConfigForm
          )

          );

        })
        .catch(error => {
          if (error.response.status == 403) {
            showLoaderStatus = false;
            localStorage.clear();
            // toast.error("Session Expired ! Please login again", {containerId: 'toastMsg'});
            window.location.href = "/";
          } else {
            showLoaderStatus = false;
            toast.error(error.response.data.message, { containerId: 'toastMsg' });
            dispatch(setSubmittedData(
              axelConfigInfo.axelConfigListrows,
              showLoaderStatus,
              axelConfigInfo.axelConfigForm
            )
            );
          }
        })
    }

  }

}

export const handleUploadInputChange = (textField, event) => {
  let textFieldValue = event.target.value.replace(/^.*\\/, "");
  return (dispatch) => {
    convertToBase64(event.target.files[0]).then(data => {
      let base64 = data;
      if ((((new Blob([base64]).size) / 1024) / 1024) > 1.5) {
        textFieldValue = '';
        toast.error("File size should be less than 1 MB", { containerId: 'toastMsg' });
      }
      dispatch(setUploadInputChanges(base64, textField, textFieldValue))
    });

  }
}
export const setUploadInputChanges = (base64, textField, textFieldValue) => {
  return {
    type: SET_UPLOAD_INPUT_CHANGE_AXLE,
    base64: base64,
    textField: textField,
    data: textFieldValue
  }
}

export const setSubmittedData = (axelConfigListrows, showLoaderStatus, axelConfigForm) => {
  return {
    type: SUBMIT_AXEL_CONFIG,
    axelConfigListrows: axelConfigListrows,
    showLoaderStatus: showLoaderStatus,
    axelConfigForm: axelConfigForm
  }
}

/************************************************************************************************** */
export const editaxelConfig = (axelConfigInfo, axelConfigListrows) => {
  // let businessId = localStorage.getItem('business_id');
  let reqURL = "/sg/axelconfig/"+ axelConfigInfo._id ;
  let updateReq = {};
  console.log("check" , axelConfigInfo);
  updateReq['axelGroupType'] = axelConfigInfo.axelGroupType;
  updateReq['status'] = axelConfigInfo.status == "true" ? true : false;
  updateReq['axelConfigObjectData'] = { 'file_type': "", 'base64_string': "" };
  return (dispatch) => {
    return axios.put(reqURL, updateReq)
      .then(response => {
        const updateIndex = axelConfigListrows.findIndex(d => {
          return d._id === axelConfigInfo._id;
        });
        axelConfigListrows[updateIndex].axelGroupType = axelConfigInfo.axelGroupType;
        axelConfigListrows[updateIndex].status = axelConfigInfo.status;
        toast.success("axel Updated Successfully", { containerId: 'toastMsg' });
        dispatch(updateInfo(axelConfigListrows));
      })
      .catch(error => {
        if (error.response.status == 403) {
          localStorage.clear();
          toast.error(
            "Session Expired ! Please login again",
            {
              containerId: 'toastMsg',
              onclose: () => window.location.href = "/"
            }
          );
        } else {
          toast.error(error.response.data.message, { containerId: 'toastMsg' });
        }

        dispatch(updateInfo(axelConfigListrows));
      })
  }
}
export const updateInfo = (axelConfigListrows) => {
  return {
    type: UPDATE_AXELCONFIG_INFO,
    axelConfigListrows: axelConfigListrows
  }
}