import {
    SUBMIT_BUSINESS_INFO,
    UPDATE_BUSINESS_INFO,
    BUSINESS_TEXTFIELD_CHANGE,
    BUSINESS_OPEN_DIALOG,
    BUSINESS_CLOSE_DIALOG,
    SEARCH_BUSINESS_TEXTFIELD_CHANGE,
    ON_SEARCH_BUSINESS,
    GET_BUSINESS_DETAILS,
    SET_INITIAL_VALUE_TO_ADD_DISTRIBUTOR_FORM,
    SET_EDIT_BUSINESS_OPEN_DIALOG_STATUS,
    SET_EDIT_DRIVER_DIALOG_STATUS_TO_CLOSE,
    SET_BUSINESS_FIRST_LOGIN_STEPPER_VALUE,
    SET_BUSINESS_FIRST_LOGIN_STEPPER_BACK,
    SET_BUSINESS_FIRST_LOGIN_CHANGE_PASSWORD_INPUT_FIELD,
    SET_BUSINESS_DETAILS_INPUT_FIELD,
    SET_DEFAULT_VALUE_TO_SEARCH_BUSINESS_COMPONENT,
    SET_BUSINESS_USER_INPUT_FIELD,
    SET_SUBMITTED_BUSINESS_USER,
    SET_DEFAULT_VALUE_TO_ADD_BUSINESS_USER,
    SET_DEFAULT_VALUE_FIRST_LOGIN_CHANGE_PASSWORD_FORM,
    SET_FIRST_LOGIN_CHANGE_PASSWORD,
    SET_TERM_N_CONDITION,
    SET_BUSINESS_DETAILS,
    FINISH_BUSINESS_FIRST_LOGIN_STEPPER,
    SET_BUSINESS_USER,
    SET_UPDATED_BUSINESS_USER,
    SET_INPUTHANDLER_UPLOAD_BUSINESS,
    POST_BUSINESS_UPLOADED_DATA,
    SET_DEFAULT_VALUE_TO_BUSINESS_BULK_UPLOAD_FORM,
    POST_TERM_N_CONDITION_REJECT,
    POST_ADD_BUSINESS_TAB_HANDLE_CHANGE,
    POST_ADD_BUSINESS_CONTACT_NEXT,
    POST_ADD_BUSINESS_CONTACT_BACK,
    POST_ADD_BUSINESS_ADMIN_BILLING_BACK,
    POST_ADD_BUSINESS_ADMIN_ON_SUBMIT_AFTER_CLOSE_TOASTR,
    POST_ADD_ADD_BUSINESS_CONTACT_INFO,
    POST_HANDLE_ADD_BUSINESS_BILLING_FORM_FIELD,
    POST_ADD_BUSINESS_ADMIN_ON_SUBMIT,
    POST_NAVIGATOR_TO_ADD_BUSINESS,
    POST_HANDLER_EDIT_BUSINESS_DIALOG_OPEN,
    POST_HANDLER_EDIT_BUSINESS_DIALOG_CLOSE,
    POST_NAVIGATE_TO_EDIT_BUSINESS_ADMIN,
    POST_GET_BUSINESS_INFO_BY_ID,
    POST_ADD_BUSINESS_ADMIN_ON_UPDATE
} from '../actions/ActionType';
import {
    isObjectEmpty
} from '../utils/Helper';
const initialState = {
    uploadBusinessForms: {
        upload_business: {
            elementType: 'upload_csv',
            elementConfig: {
                type: 'text',
                label: 'Upload Business User CSV'
            },
            value: '',
            errorStatus: false,
            errorMessage: "Upload Business User CSV"
        }
    },
    businessUserForm: {
        business_user_fname: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                label: 'First Name'
            },
            value: '',
            errorStatus: false,
            errorMessage: "Enter First Name",
            maxLength: 100
        },
        business_user_lname: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                label: 'Last Name'
            },
            value: '',
            errorStatus: false,
            errorMessage: "Enter Last Name",
            maxLength: 100
        },
        business_user_email: {
            elementType: 'input',
            elementConfig: {
                type: 'email',
                label: 'Email'
            },
            value: '',
            errorStatus: false,
            errorMessage: "Enter a valid email address",
            maxLength: 60
        },
        business_user_role: {
            elementType: 'select',
            elementConfig: {
                type: 'text',
                label: 'Role'
            },
            value: '',
            errorStatus: false,
            errorMessage: "Select Role",
            menuItems: {
                'select_business_role': {
                    name: "Select Business Role",
                    value: ''
                },
                'business_editor': {
                    name: "Business Editor",
                    value: '6'
                },
                'business_reporter': {
                    name: "Business Reporter",
                    value: '7'
                },
                'business_depot_manager': {
                    name: "Depot Manager",
                    value: '28'
                },
            }
        }
    },
    myob_account_number: '',
    businessFirstLoginActiveStep: 0,
    businessFirstLoginSteps: [
        {
            id: 'term_n_condition',
            label: 'Term & Condition'
        },
        {
            id: 'change_password',
            label: 'Change Password'
        },
        {
            id: 'business_details',
            label: 'Business Details'
        }
    ],
    firstLoginBusinessChangePasswordForm: {
        current_password: {
            elementType: 'input',
            elementConfig: {
                type: 'password',
                label: 'Current Password'
            },
            value: '',
            errorStatus: false,
            errorMessage: "Enter Current Password"
        },
        new_password: {
            elementType: 'input',
            elementConfig: {
                type: 'password',
                label: 'New Password'
            },
            value: '',
            errorStatus: false,
            errorMessage: "Enter New Password"
        },
        confirm_password: {
            elementType: 'input',
            elementConfig: {
                type: 'password',
                label: 'Confirm Password'
            },
            value: '',
            errorStatus: false,
            errorMessage: "Confirm Password Your Password"
        },
    },
    businessDetailsForm: {
        businessname: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                label: 'Business Name'
            },
            value: '',
            errorStatus: false,
            errorMessage: "Enter Business Name",
            maxLength: 60
        },
        contact_name: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                label: 'Contact Name'
            },
            value: '',
            errorStatus: false,
            errorMessage: "Enter Contact Name",
            maxLength: 60
        },
        contact_address: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                label: 'Contact Address'
            },
            value: '',
            errorStatus: false,
            errorMessage: "Enter Contact Address",
            maxLength: 100
        },
        contact_email: {
            elementType: 'input',
            elementConfig: {
                type: 'email',
                label: 'Contact Email',
                disabled: true
            },
            value: '',
            errorStatus: false,
            errorMessage: "Enter a valid contact email",
            maxLength: 60
        },
        abn: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                label: 'ABN',
                disabled: true
            },
            value: '',
            errorStatus: false,
            errorMessage: "Enter ABN",
            max: 11,
            disabledStatus: true
        },
        country: {
            elementType: 'select',
            elementConfig: {
                type: 'text',
                label: 'Country'
            },
            value: '',
            errorStatus: false,
            errorMessage: "Select Country",
            menuItems: {
                'select_country': {
                    name: "Select Country",
                    value: ''
                },
                'aus': {
                    name: "Australia",
                    value: "AUS"
                }
            }
        },
        /*
        billing_option: {
            elementType: 'select',
            elementConfig: {
                type: 'text',
                label: 'Billing Option'
            },
            value: '',
            errorStatus: false,
            errorMessage: "Select Country",
            menuItems: {
                'select_billing_option': {
                    name: "Select Billing Option",
                    value: ""
                },
                'sg_bill': {
                    name: "SG Bill",
                    value: "sg_bill"
                },
                'distributor_bill': {
                    name: "Distributor Bill",
                    value: "distributor_bill"
                }
            }
        }, */
        business_contact_no: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                label: 'Business Contact Number'
            },
            value: '',
            errorStatus: false,
            errorMessage: "Enter Business Contact Number",
            maxLength: 10
        },
        business_it_support_no: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                label: 'Business IT Support Number'
            },
            value: '',
            errorStatus: false,
            errorMessage: "Enter Business IT Support Number",
            maxLength: 10
        },
        business_logo: {
            elementType: 'upload_image',
            elementConfig: {
                type: 'text',
                label: 'Business Logo'
            },
            value: '',
            errorStatus: false,
            base64String: '',
            errorMessage: "Enter Business Logo"
        },
        select_invoice: {
            elementType: 'select',
            elementConfig: {
                type: 'text',
                label: 'Select Invoice'
            },
            value: '',
            errorStatus: false,
            errorMessage: "Select Invoice",
            menuItems: {
                'select_invoice': {
                    name: 'Select Invoice',
                    value: ''
                },
                'sg_invoice': {
                    name: 'SG Invoice',
                    value: 'sg_invoice'
                },
                'credit_card': {
                    name: 'Credit Card',
                    value: 'credit_card'
                }
            }
        },
    },
    addBusinessForm: {
        businessname: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                label: 'Business Name'
            },
            value: '',
            errorStatus: false,
            errorMessage: "Enter Business Name",
            maxLength: 60
        },
        contact_name: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                label: 'Contact Name'
            },
            value: '',
            errorStatus: false,
            errorMessage: "Enter Contact Name",
            maxLength: 60
        },
        contact_address: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                label: 'Contact Address'
            },
            value: '',
            errorStatus: false,
            errorMessage: "Enter Contact Address",
            maxLength: 100
        },
        contact_email: {
            elementType: 'input',
            elementConfig: {
                type: 'email',
                label: 'Contact Email'
            },
            value: '',
            errorStatus: false,
            errorMessage: "Enter a valid contact email",
            maxLength: 60
        },
        abn: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                label: 'ABN'
            },
            value: '',
            errorStatus: false,
            errorMessage: "Enter ABN",
            maxLength: 11,
        },
        country: {
            elementType: 'select',
            elementConfig: {
                type: 'number',
                label: 'Country'
            },
            value: 'aus',
            errorStatus: false,
            errorMessage: "Select Country",
            menuItems: {
                'select_country': {
                    name: "Select Country",
                    value: ''
                },
                'aus': {
                    name: "Australia",
                    value: "AUS"
                }
            }
        },
        state: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                label: 'State'
            },
            value: '',
            errorStatus: false,
            errorMessage: "Enter State",
            maxLength: 60
        },
        postalcode: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                label: 'Postal Code '
            },
            value: '',
            errorStatus: false,
            errorMessage: "Enter Postal Code",
            maxLength: 4
        },
        phone: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                label: 'Phone'
            },
            value: '',
            errorStatus: false,
            errorMessage: "Enter Phone",
            maxLength: 11,
            disabledStatus: false
        },
        support_email: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                label: 'Support Email'
            },
            value: '',
            errorStatus: false,
            errorMessage: "Enter Support Email",
            maxLength: 60
        },

        support_contact_number: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                label: 'Support Contact Number'
            },
            value: '',
            errorStatus: false,
            errorMessage: "Enter Support Contact Number",
            maxLength: 10
        },
        business_logo: {
            elementType: 'upload_image',
            elementConfig: {
                type: 'text',
                label: 'Logo'
            },
            value: '',
            errorStatus: false,
            errorMessage: "Enter Logo",
        },
        isDeleted: {
            elementType: 'select',
            elementConfig: {
                type: 'text',
                label: 'Select Status'
            },
            value: '',
            errorStatus: false,
            errorMessage: "Select Status",
            menuItems: {
                "select_status": {
                    name: "Select Status",
                    value: ''
                },
                "active": {
                    name: "Active",
                    value: "active"
                },
                "inactive": {
                    name: "Inactive",
                    value: "inactive"
                }
            },
            disabledStatus: false
        },
        integrationId: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                label: 'Integration ID',
                required: false
            },
            value: '',
            errorStatus: false,
            errorMessage: "Enter ID",
            maxLength: 60,
        },
    },

    billingForm: {
        address_line: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                label: 'Address Line'
            },
            value: '',
            errorStatus: false,
            errorMessage: "Enter Address Line",
            maxLength: 60
        },
        city: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                label: 'City'
            },
            value: '',
            errorStatus: false,
            errorMessage: "Enter City",
            maxLength: 60
        },
        state: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                label: 'State'
            },
            value: '',
            errorStatus: false,
            errorMessage: "Enter State",
            maxLength: 60
        },
        postalcode: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                label: 'Postal Code '
            },
            value: '',
            errorStatus: false,
            errorMessage: "Enter Postal Code",
            maxLength: 4
        },
        country: {
            elementType: 'select',
            elementConfig: {
                type: 'text',
                label: 'Country'
            },
            value: 'AUS',
            errorStatus: false,
            errorMessage: "Select Country",
            menuItems: {
                'select_country': {
                    name: "Select Country",
                    value: ''
                },
                'aus': {
                    name: "Australia",
                    value: "AUS"
                }
            }
        },/*
        company_name: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                label: 'Company Name'
            },
            value: '',
            errorStatus: false,
            errorMessage: "Enter Company Name",
            maxLength: 60
        },*/
        phone: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                label: 'Phone'
            },
            value: '',
            errorStatus: false,
            errorMessage: "Enter Phone",
            maxLength: 10
        },
        email: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                label: 'Email Address'
            },
            value: '',
            errorStatus: false,
            errorMessage: "Enter Email Address",
            maxLength: 60
        },
        currency: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                label: 'Currency'
            },
            value: '',
            errorStatus: false,
            errorMessage: "Enter Currency",
            maxLength: 60
        }
    },
    searchBusinessForm: {
        'abn': {
            errorStatus: false,
            type: "text",
            errorMessage: "Please enter ABN",
            name: "Enter ABN Number",
            value: "",
            maxLength: 11
        }
    },
    columns: [
        { title: 'Business ID', field: 'business_id' },
        { title: 'Business Name', field: 'business_name' },
        { title: 'ABN', field: 'abn' },
        //{ title: 'EWD', field: 'hasEWDLicence'}render: rowData => <input type={text/input}></input>}
    ],

    //Business User Table Information
    businessUserColumns: [
        {
            title: "First Name",
            field: "business_first_name"
        },
        {
            title: "Last Name",
            field: "business_last_name"
        },
        {
            title: "Email",
            field: "business_email"
        },
        {
            title: "Role",
            field: "business_role",
            lookup: {
                6: "Business Editor",
                7: "Business Reporter",
                28: "Depot Manager"
            }
        }
    ],
    businessUserInformations: [],
    editDistributorDialogStatus: false,
    businessInformations: [],
    dialogOpen: false,
    activeRecordForEdit: {},
    mode: '',
    searchBusinessFoundStatus: null,
    addBusinessToastMessage: '',
    addBusinessAdminLoaderStatus: false,
    editRecordsBusinessID: '',
    businessFirstLoginStepperLoaderStatus: false,
    businessDetailsLoaderStatus: false,
    businessUserLoaderStatus: false,
    listBusinessUserLoaderStatus: false,
    bulkUploadedBusiness: [],
    bulkUploadedBusinesses: [],
    businessUploadStatusTableColumns: [
        {
            title: 'Firstname',
            field: 'userfirstname'
        },
        {
            title: 'Lastname',
            field: 'userlastname'
        },
        {
            title: 'Status',
            field: 'status'
        }
    ],
    businessUploadStatusList: [],
    businessBulkUploadSubmitLoaderStatus: false,
    addBusinessLogoBase64: '',
    getBusinessAdminByIdLoaderStatus: false,
    addBusinessTabValue: 0,
    addBusinessContactinfoColumns: [
        { title: 'First Name', field: 'first_name' },
        { title: 'Last Name', field: 'last_name' },
        { title: 'Email', field: 'contact_email' },
        { title: 'Phone', field: 'contact_phone', type: 'numeric' }
    ],
    addBusinessContactinformations: [],
    addBusinessReqParams: {},
    businessIdForEdit: '',
    updateBusinessLoaderStatus: false,
    businessAdminEditStatus : false,
    addBusinessTabDisableStatus: true,
}

const getBusinessDetails = (state, action) => {
    state.addBusinessAdminLoaderStatus = action.addBusinessAdminLoaderStatus;
    state.businessInformations = action.businessList
    return state;
}

const setDefaultValueToSearchBusinessComponent = (state, action) => {
    Object.keys(state.searchBusinessForm).map((inputField) => {
        state.searchBusinessForm[inputField].value = '';
        state.searchBusinessForm[inputField].errorStatus = false;
    })
    return state;
}

const setBusinessDetailsInputField = (state, action) => {
    state.businessDetailsForm[action.formElementId].value = action.data;
    if (action.logoBase64String !== '') {
        state.businessDetailsForm['business_logo'].base64String = action.logoBase64String;
    }
    if (state.businessDetailsForm[action.formElementId].value === '') {
        state.businessDetailsForm[action.formElementId].errorStatus = true;
    } else {
        state.businessDetailsForm[action.formElementId].errorStatus = false;
    }
    return state;
}

const setBusinessFirstLoginChangePasswordInputField = (state, action) => {
    state.firstLoginBusinessChangePasswordForm[action.inputFieldID].value = action.data;
    if (state.firstLoginBusinessChangePasswordForm[action.inputFieldID].value === '') {
        state.firstLoginBusinessChangePasswordForm[action.inputFieldID].errorStatus = true;
    } else {
        state.firstLoginBusinessChangePasswordForm[action.inputFieldID].errorStatus = false;
    }
    return state;
}
const setBusinessFirstLoginStepperValueBack = (state, action) => {
    state.businessFirstLoginActiveStep = state.businessFirstLoginActiveStep - 1;
    return state;
}

const setBusinessFirstLoginStepperValue = (state, action) => {
    state.businessFirstLoginActiveStep = state.businessFirstLoginActiveStep + 1;
    return state;
}

const setEditDriverDialogStatusToClose = (state, action) => {
    state.mode = '';
    state.editDistributorDialogStatus = false;
    return state;
}



const setEditBusinessOpenDialogStatus = (state, action) => {
    Object.keys(state.addBusinessForm).map(inputField => {
        state.addBusinessForm[inputField].errorStatus = false;
    })
    state.addBusinessForm['businessname'].value = action.recordToEdit.businessname;
    state.addBusinessForm['contact_name'].value = action.recordToEdit.contact_name;
    state.addBusinessForm['country'].value = action.recordToEdit.country;
    //state.addBusinessForm['billing_option'].value = action.recordToEdit.billing_option;
    state.addBusinessForm['contact_address'].value = action.recordToEdit.contact_address;
    state.addBusinessForm['abn'].value = action.recordToEdit.abn;
    state.addBusinessForm['isDeleted'].value = action.recordToEdit.status;
    if (action.recordToEdit.status === 'inactive') {
        state.addBusinessForm['businessname'].disabledStatus = true;
        state.addBusinessForm['contact_name'].disabledStatus = true;
        state.addBusinessForm['country'].disabledStatus = true;
        //state.addBusinessForm['billing_option'].disabledStatus = true;
        state.addBusinessForm['contact_address'].disabledStatus = true;
        state.addBusinessForm['abn'].disabledStatus = true;
        state.addBusinessForm['isDeleted'].disabledStatus = false;
    } else {
        state.addBusinessForm['businessname'].disabledStatus = false;
        state.addBusinessForm['contact_name'].disabledStatus = false;
        state.addBusinessForm['country'].disabledStatus = false;
        //state.addBusinessForm['billing_option'].disabledStatus = false;
        state.addBusinessForm['contact_address'].disabledStatus = false;
        state.addBusinessForm['abn'].disabledStatus = false;
        state.addBusinessForm['isDeleted'].disabledStatus = false;
    }
    if (!action.recordToEdit.business_ack) {
        state.addBusinessForm['isDeleted'].value = '';
        state.addBusinessForm['isDeleted'].disabledStatus = true;
    }
    state.editRecordsBusinessID = action.recordToEdit.business_id;
    state.editDistributorDialogStatus = true;
    state.mode = 'edit';
    return state;
}

const setInitialValueToAddDistributorForm = (state, action) => {

    // if(state.mode !== 'edit'){
    Object.keys(state.addBusinessForm).map((textField) => {
        state.addBusinessForm[textField].value = '';
        state.addBusinessForm[textField].errorStatus = false;
        // state.addBusinessForm[textField].disabledStatus = false;
    })
    // }
    state.businessAdminEditStatus = false;
    return state;
}

const onSearchBusiness = (state, action) => {
    state.searchBusinessFoundStatus = null;
    state.addBusinessAdminLoaderStatus = action.addBusinessAdminLoaderStatus;
    state.searchBusinessFoundStatus = action.searchBusinessFoundStatus;
    state.searchBusinessForm = action.searchBusinessForm;
    return state;
}

const searchBusinessTextfieldChange = (state, action) => {
    state.searchBusinessForm[action.textFieldId].value = action.textFieldData;
    state.searchBusinessFoundStatus = null;
    if (state.searchBusinessForm[action.textFieldId].value == '') {
        state.searchBusinessForm[action.textFieldId].errorStatus = true;
    } else {
        state.searchBusinessForm[action.textFieldId].errorStatus = false;
    }

    return state;
}

const submitBusinessInfo = (state, action) => {

    state.addBusinessTabValue = action.addBusinessTabValue;
    // state.addBusinessAdminLoaderStatus = action.addBusinessAdminLoaderStatus;
    // state.addBusinessForm = action.addBusinessForm;
    state.addBusinessReqParams = action.addBusinessReqParams;
    state.tabSwitchStatus = action.tabSwitchStatus;
    state.addBusinessTabDisableStatus = action.addBusinessTabDisableStatus;
    return state;
}

const updateBusinessInfo = (state, action) => {
    if (state.updateBusinessLoaderStatus === false) {
        state.editBusinessDialogOpenStatus = false;
    }
    state.mode = '';
    state.updateBusinessLoaderStatus = action.updateBusinessLoaderStatus;
    state.businessInformations = action.businessInformations;
    return state;
    // if(!action.addBusinessAdminLoaderStatus){
    //     state.editDistributorDialogStatus = false;
    // }
    // state.mode = '';
    // state.addBusinessAdminLoaderStatus = action.addBusinessAdminLoaderStatus;
    // state.businessInformations = action.businessInformations;
    // return state;
}

const businessTextFieldChange = (state, action) => {

    state.addBusinessForm[action.textFieldId].value = action.textFieldData;
    if (action.textFieldData || action.textFieldId == 'isDeleted' || action.textFieldId === 'integrationId') {

        state.addBusinessForm[action.textFieldId].errorStatus = false;
    }
    else {

        state.addBusinessForm[action.textFieldId].errorStatus = true;
    }
    if (action.addBusinessLogoBase64 !== '') {

        state.addBusinessLogoBase64 = action.addBusinessLogoBase64;
    }
    if (action.textFieldId === 'isDeleted') {

        if (action.textFieldData === 'active') {

            state.addBusinessForm['businessname'].disabledStatus = false;
            state.addBusinessForm['contact_name'].disabledStatus = false;
            state.addBusinessForm['contact_address'].disabledStatus = false;
            state.addBusinessForm['contact_email'].disabledStatus = false;
            state.addBusinessForm['abn'].disabledStatus = false;
            state.addBusinessForm['country'].disabledStatus = false;
            //state.addBusinessForm['billing_option'].disabledStatus = false;
        } else {

            state.addBusinessForm['businessname'].disabledStatus = true;
            state.addBusinessForm['contact_name'].disabledStatus = true;
            state.addBusinessForm['contact_address'].disabledStatus = true;
            state.addBusinessForm['contact_email'].disabledStatus = true;
            state.addBusinessForm['abn'].disabledStatus = true;
            state.addBusinessForm['country'].disabledStatus = true;
            //state.addBusinessForm['billing_option'].disabledStatus = true;
        }
    }
    return state;
}

const businessOpenDialog = (state, action) => {
    state.dialogOpen = true;
    state.mode = 'edit';
    state.addBusinessForm.businessname.value = action.data.businessname;
    state.addBusinessForm.contact_name.value = action.data.contact_name;
    state.addBusinessForm.contact_email.value = action.data.contact_email;
    //state.addBusinessForm.billing_option.value = action.data.billing_option;

    state.activeRecordForEdit.id = action.data.id;
    state.activeRecordForEdit.business_id = action.data.business_id
    state.activeRecordForEdit.businessname = action.data.businessname;
    state.activeRecordForEdit.country_name = action.data.country_name;
    state.activeRecordForEdit.business_no = action.data.business_no;
    return state;
}

const businessCloseDialog = (state, action) => {
    state.dialogOpen = true;
    state.mode = '';
    return state;
}

//Handle SET_BUSINESS_USER_INPUT_FIELD Reducer
const setBusinessUserInputField = (state, action) => {
    state.businessUserForm[action.inputFiledId].value = action.data;
    if (state.businessUserForm[action.inputFiledId].value === '') {
        state.businessUserForm[action.inputFiledId].errorStatus = true;
    } else {
        state.businessUserForm[action.inputFiledId].errorStatus = false;
    }
    return state;
}

//Handle SET_SUBMITTED_BUSINESS_USER Reducer
const setSubmittedBusinessUser = (state, action) => {
    state.businessUserLoaderStatus = action.businessUserLoaderStatus;
    return state;
}

//Handle SET_DEFAULT_VALUE_TO_ADD_BUSINESS_USER Reducer
const setDefaultValueToAddBusinessUser = (state, action) => {
    Object.keys(state.businessUserForm).map((inputField) => {
        state.businessUserForm[inputField].value = '';
        state.businessUserForm[inputField].errorStatus = false;
    })
    return state;
}

export const setDefaultValueFirstLoginChangePasswordForm = (state, action) => {
    Object.keys(state.firstLoginBusinessChangePasswordForm).map(inputField => {
        state.firstLoginBusinessChangePasswordForm[inputField].value = '';
        state.firstLoginBusinessChangePasswordForm[inputField].errorStatus = false;
    })
    return state;
}

export const setFirstLoginChangePassword = (state, action) => {
    state.businessFirstLoginStepperLoaderStatus = action.businessFirstLoginStepperLoaderStatus;
    if (action.changePasswordSuccessStatus) {
        state.businessFirstLoginActiveStep = state.businessFirstLoginActiveStep + 1;
    } else {
        state.businessFirstLoginActiveStep = state.businessFirstLoginActiveStep;
    }

    Object.keys(state.firstLoginBusinessChangePasswordForm).map(inputField => {
        if (state.firstLoginBusinessChangePasswordForm[inputField].value === '') {
            state.firstLoginBusinessChangePasswordForm[inputField].errorStatus = true;
        } else {
            state.firstLoginBusinessChangePasswordForm[inputField].errorStatus = false;
        }

    })
    return state;
}

export const setTermNCondition = (state, action) => {
    state.businessFirstLoginStepperLoaderStatus = action.businessFirstLoginStepperLoaderStatus;
    if (action.termNConditionAcceptStatus) {
        state.businessFirstLoginActiveStep = state.businessFirstLoginActiveStep + 1;
    } else {
        state.businessFirstLoginActiveStep = state.businessFirstLoginActiveStep;
    }
    return state;
}
export const setBusinessDetails = (state, action) => {
    state.businessDetailsLoaderStatus = action.businessDetailsLoaderStatus;
    state.businessDetailsForm['businessname'].value = action.businessInfo['businessname'];
    state.businessDetailsForm['contact_name'].value = action.businessInfo['business_contact_name'];
    state.businessDetailsForm['contact_address'].value = action.businessInfo['business_contact_address'];
    state.businessDetailsForm['abn'].value = action.businessInfo['abn'];
    state.businessDetailsForm['country'].value = action.businessInfo['country'];
    //state.businessDetailsForm['billing_option'].value = action.businessInfo['business_billing_option'];
    state.businessDetailsForm['contact_email'].value = localStorage.getItem('Platform-User');
    state.businessDetailsForm['businessname'].value = action.businessInfo['businessname'];
    state.businessDetailsForm['businessname'].value = action.businessInfo['businessname'];
    return state;
}

export const finishBusinessFirstLoginStepper = (state, action) => {
    localStorage.setItem('userfirstlogin', "false");
    return state;
}
export const setBusinessUser = (state, action) => {
    state.businessUserInformations = action.businessUsers;
    state.listBusinessUserLoaderStatus = action.listBusinessUserLoaderStatus;
    return state;
}
const setUpdatedBusinessUser = (state, action) => {
    state.businessUserInformations = action.businessUserInformations;
    return state;
}

//POST_ADD_BUSINESS_TAB_HANDLE_CHANGE
const postAddBusinessTabhandleChange = (state, action) => {
    state.addBusinessTabValue = action.addBusinessTabValue;
    return state;
}

// POST_ADD_Business_CONTACT_NEXT
const postAddBusinessContactNext = (state, action) => {
    state.addBusinessReqParams['business_contacts'] = action.addBusinessContactinformations;
    state.addBusinessTabValue = 2;
    return state;
}


//POST_ADD_BUSINESS_CONTACT_BACK
const postAddBusinessContactBack = (state, action) => {
    state.addBusinessTabValue = 0;
    return state;
}


// POST_ADD_BUSINESS_ADMIN_BILLING_BACK
const postAddBusinessAdminBillingBack = (state, action) => {
    state.addBusinessTabValue = 1;
    return state;
}

const postAddBusinessAdminOnSubmitAfterCloseToastr = (state, action) => {
    state.addBusinessTabValue = 0;
    state.tabSwitchStatus = false;
    let oldState = {...state.addBusinessForm};
    for(let key in oldState){
        oldState[key].value = "";
    }
    state.addBusinessForm = oldState;
    return state;
}

// /POST_ADD_ADD_BUSINESS_CONTACT_INFO
const postAddAddBusinessContactInfo = (state, action) => {
    state.addBusinessContactinformations = action.addBusinessContactinformations;
    return state;
}


//POST_HANDLE_ADD_BUSINESS_BILLING_FORM_FIELD
const postHandleAddBusinessBillingFormField = (state, action) => {
    state.billingForm[action.formFieldId].value = action.data;
    return state;
}

// /POST_ADD_BUSINESS_ADMIN_ON_SUBMIT
const postAddBusinessAdminOnSubmit = (state, action) => {
    state.addBusinessAdminLoaderStatus = action.addBusinessAdminLoaderStatus;
    return state;
}

//POST_NAVIGATOR_TO_ADD_BUSINESS
const postNavigatorToAddBusiness = (state, action) => {
    state.businessAdminEditStatus = false;
    state.tabSwitchStatus = false;
    state.addBusinessTabDisableStatus = true;
    return state;
}

//POST_UPDATE_BUSINESS_CONTACT_INFO
const postUpdateBusinessContactInfo = (state, action) => {
    state.addBusinessContactinformations = action.addBusinessContactinformations;
    return state;
}


const postNavigateToEditBusinessAdmin = (state, action) => {
    state.businessAdminEditStatus = true;
    return state;
}

//POST_ADD_BUSINESS_ADMIN_ON_UPDATE
const postAddBusinessAdminOnUpdate = (state, action) => {
    state.addBusinessAdminLoaderStatus = action.addBusinessAdminLoaderStatus;
    return state;
}

const postGetBusinessInfoById = (state, action) => {

    state.getBusinessAdminByIdLoaderStatus = action.getBusinessAdminByIdLoaderStatus;
    state.businessAdminEditStatus = true;
    let contactInfo = {};
    state.addBusinessContactinformations = [];
    Object.keys(state.addBusinessForm).map(inputField => {
        state.addBusinessForm[inputField].errorStatus = false;
    })
    Object.keys(state.billingForm).map(inputField => {
        state.billingForm[inputField].errorStatus = false;
    })
    if (!isObjectEmpty(action.businessInfo)) {
        state.myob_account_number = action.businessInfo.myob_account_number
        //state.addBusinessForm['company_name'].value = action.businessInfo.main_attributes['company_name']?action.businessInfo.main_attributes['company_name']:'';
        state.addBusinessForm['businessname'].value = action.businessInfo['business_name']?action.businessInfo['business_name']:'';
        //state.addBusinessForm['attention'].value = action.businessInfo.main_attributes['attention']?action.businessInfo.main_attributes['attention']:'';
        state.addBusinessForm['phone'].value = action.businessInfo.main_attributes['phone']?action.businessInfo.main_attributes['phone']:'';
        state.addBusinessForm['abn'].value = action.businessInfo['abn']?action.businessInfo['abn']:'';


        state.addBusinessForm['contact_email'].value = action.businessInfo.main_attributes['email']?action.businessInfo.main_attributes['email']:'';
        state.addBusinessForm['contact_name'].value=action.businessInfo['business_contact_name']?action.businessInfo['business_contact_name']:'';
        state.addBusinessForm['support_email'].value = action.businessInfo['businesssupportemail']?action.businessInfo['businesssupportemail']:'';
        state.addBusinessForm['support_contact_number'].value = action.businessInfo['businesssupportnumber']?action.businessInfo['businesssupportnumber']:'';
        state.addBusinessForm['contact_address'].value = action.businessInfo['business_contact_address']?action.businessInfo['business_contact_address']:'';
        state.addBusinessForm['business_logo'].value = 'Logo';
        state.addBusinessForm['country'].value = action.businessInfo['country']?action.businessInfo['country']:'';
        state.addBusinessForm['integrationId'].value = action.businessInfo['integrationId'];

        state.addBusinessForm['state'].value = action.businessInfo['state']?action.businessInfo['state']:'';
        state.addBusinessForm['postalcode'].value = action.businessInfo['postalcode']?action.businessInfo['postalcode']:'';
        //state.addBusinessForm['billing_option'].value = action.businessInfo['business_billing_option']?action.businessInfo['business_billing_option']:''
        //state.addBusinessForm['payment_method'].value = action.businessInfo.main_attributes['payment_method']?action.businessInfo.main_attributes['payment_method']:'';
        for (let i = 0; i < (action.businessInfo.business_contacts).length; i++) {
            contactInfo = {};
            contactInfo['first_name'] = action.businessInfo.business_contacts[i].first_name;
            contactInfo['last_name'] = action.businessInfo.business_contacts[i].last_name;
            contactInfo['contact_email'] = action.businessInfo.business_contacts[i].contact_email;
            contactInfo['contact_phone'] = action.businessInfo.business_contacts[i].contact_phone;
            state.addBusinessContactinformations.push(contactInfo);
        }

        // state.billingForm['address_line'].value = action.businessInfo.business_billing_data['billing_address']?action.businessInfo.business_billing_data['billing_address']:'';
        // state.billingForm['city'].value = action.businessInfo.business_billing_data['billing_city']?action.businessInfo.business_billing_data['billing_city']:'';
        // state.billingForm['state'].value = action.businessInfo.business_billing_data['billing_state']?action.businessInfo.business_billing_data['billing_state']:'';
        // state.billingForm['postalcode'].value = action.businessInfo.business_billing_data['billing_postalcode']?action.businessInfo.business_billing_data['billing_postalcode']:'';
        // state.billingForm['country'].value = action.businessInfo.business_billing_data['billing_country']?action.businessInfo.business_billing_data['billing_country']:'';
        // //state.billingForm['company_name'].value = action.businessInfo.business_billing_data['billing_company_name']?action.businessInfo.business_billing_data['billing_company_name']:'';
        // state.billingForm['phone'].value = action.businessInfo.business_billing_data['billing_phone']?action.businessInfo.business_billing_data['billing_phone']:'';
        // state.billingForm['email'].value = action.businessInfo.business_billing_data['billing_email']?action.businessInfo.business_billing_data['billing_email']:'';
        // state.billingForm['currency'].value = action.businessInfo.business_billing_data['billing_currency']?action.businessInfo.business_billing_data['billing_currency']:'';
    }
    return state;
}

const reducerBusiness = (state = initialState, action) => {

    let localState = Object.assign({}, state);
    switch (action.type) {

        case POST_HANDLER_EDIT_BUSINESS_DIALOG_OPEN:
            localState.addBusinessForm['business_logo'].value = "Logo";
            localState.businessIdForEdit = action.data['business_id'];
            localState.addBusinessForm['businessname'].value = action.data['business_name'];
            localState.addBusinessForm['country'].value = action.data['country_name'];
            localState.addBusinessForm['myob_account_number'].value = action.data['myob_account_number'];
            localState.addBusinessForm['abn'].value = action.data['abn'];
            localState.addBusinessForm['support_contact_number'].value = action.data['business_support_contactnumber'];
            // localState.addBusinessForm['business_address'].value = action.data['business_address'];
            localState.addBusinessForm['support_email'].value = action.data['business_support_email'];
            Object.keys(localState.addBusinessForm).map(inputField => {
                localState.addBusinessForm[inputField].errorStatus = false;
            })
            localState.editDistributorDialogOpenStatus = true;
            localState.addBusinessForm['abn'].disabledStatus = true;
            return localState;
        case POST_HANDLER_EDIT_BUSINESS_DIALOG_CLOSE:
            localState.addBusinessForm['abn'].disabledStatus = false;
            localState.editDistributorDialogOpenStatus = false;
            return localState;
        case POST_TERM_N_CONDITION_REJECT:
            return localState;
        case SET_DEFAULT_VALUE_TO_BUSINESS_BULK_UPLOAD_FORM:
            Object.keys(localState.uploadBusinessForms).map(inputField => {
                localState.uploadBusinessForms[inputField].errorStatus = false;
                localState.uploadBusinessForms[inputField].value = ''
            })
            localState.businessUploadStatusList = [];
            return localState;
        case POST_BUSINESS_UPLOADED_DATA:
            Object.keys(localState.uploadBusinessForms).map(inputField => {
                if (localState.uploadBusinessForms[inputField].value === '') {
                    localState.uploadBusinessForms[inputField].errorStatus = true;
                }
            })
            localState.businessBulkUploadSubmitLoaderStatus = action.businessBulkUploadSubmitLoaderStatus;
            localState.businessUploadStatusList = action.businessUploadStatusList;
            return localState;
        case SET_INPUTHANDLER_UPLOAD_BUSINESS:
            localState.uploadBusinessForms[action.formElementId].value = action.data;
            if (action.bulkUploadedBusinesses === undefined) {
                localState.bulkUploadedBusinesses = [];
            } else {
                localState.bulkUploadedBusinesses = action.bulkUploadedBusinesses;
            }
            return localState;
        case SET_UPDATED_BUSINESS_USER: return setUpdatedBusinessUser(localState, action);
        case SET_BUSINESS_USER: return setBusinessUser(localState, action);
        case FINISH_BUSINESS_FIRST_LOGIN_STEPPER: return finishBusinessFirstLoginStepper(localState, action);
        case SET_BUSINESS_DETAILS: return setBusinessDetails(localState, action);
        case SET_TERM_N_CONDITION: return setTermNCondition(localState, action);
        case SET_FIRST_LOGIN_CHANGE_PASSWORD: return setFirstLoginChangePassword(localState, action);
        case SET_DEFAULT_VALUE_FIRST_LOGIN_CHANGE_PASSWORD_FORM: return setDefaultValueFirstLoginChangePasswordForm(localState, action);
        case SET_DEFAULT_VALUE_TO_ADD_BUSINESS_USER: return setDefaultValueToAddBusinessUser(localState, action);
        case SET_SUBMITTED_BUSINESS_USER: return setSubmittedBusinessUser(localState, action);
        case SET_BUSINESS_USER_INPUT_FIELD: return setBusinessUserInputField(localState, action);
        case SET_DEFAULT_VALUE_TO_SEARCH_BUSINESS_COMPONENT: return setDefaultValueToSearchBusinessComponent(localState, action);
        case SET_BUSINESS_DETAILS_INPUT_FIELD: return setBusinessDetailsInputField(localState, action);
        case SET_BUSINESS_FIRST_LOGIN_CHANGE_PASSWORD_INPUT_FIELD: return setBusinessFirstLoginChangePasswordInputField(localState, action);
        case SET_BUSINESS_FIRST_LOGIN_STEPPER_BACK: return setBusinessFirstLoginStepperValueBack(localState, action);
        case SET_BUSINESS_FIRST_LOGIN_STEPPER_VALUE: return setBusinessFirstLoginStepperValue(localState, action);
        case SET_EDIT_DRIVER_DIALOG_STATUS_TO_CLOSE: return setEditDriverDialogStatusToClose(localState, action);
        case SET_EDIT_BUSINESS_OPEN_DIALOG_STATUS: return setEditBusinessOpenDialogStatus(localState, action);
        case SET_INITIAL_VALUE_TO_ADD_DISTRIBUTOR_FORM: return setInitialValueToAddDistributorForm(localState, action);
        case GET_BUSINESS_DETAILS: return getBusinessDetails(localState, action);
        case ON_SEARCH_BUSINESS: return onSearchBusiness(localState, action);
        case SEARCH_BUSINESS_TEXTFIELD_CHANGE: return searchBusinessTextfieldChange(localState, action);
        case SUBMIT_BUSINESS_INFO: return submitBusinessInfo(localState, action);
        case UPDATE_BUSINESS_INFO: return updateBusinessInfo(localState, action);
        case BUSINESS_TEXTFIELD_CHANGE: return businessTextFieldChange(localState, action);
        case BUSINESS_OPEN_DIALOG: return businessOpenDialog(localState, action);
        case BUSINESS_CLOSE_DIALOG: return businessCloseDialog(localState, action);
        case POST_ADD_BUSINESS_TAB_HANDLE_CHANGE: return postAddBusinessTabhandleChange(localState, action);
        case POST_ADD_BUSINESS_CONTACT_NEXT: return postAddBusinessContactNext(localState, action);
        case POST_ADD_BUSINESS_CONTACT_BACK: return postAddBusinessContactBack(localState, action);
        case POST_ADD_BUSINESS_ADMIN_BILLING_BACK: return postAddBusinessAdminBillingBack(localState, action);
        case POST_ADD_BUSINESS_ADMIN_ON_SUBMIT_AFTER_CLOSE_TOASTR: return postAddBusinessAdminOnSubmitAfterCloseToastr(localState, action);
        case POST_ADD_ADD_BUSINESS_CONTACT_INFO: return postAddAddBusinessContactInfo(localState, action);
        case POST_HANDLE_ADD_BUSINESS_BILLING_FORM_FIELD: return postHandleAddBusinessBillingFormField(localState, action);
        case POST_ADD_BUSINESS_ADMIN_ON_SUBMIT: return postAddBusinessAdminOnSubmit(localState, action);
        case POST_NAVIGATOR_TO_ADD_BUSINESS: return postNavigatorToAddBusiness(localState, action);
        case POST_NAVIGATE_TO_EDIT_BUSINESS_ADMIN: return postNavigateToEditBusinessAdmin(localState, action);
        case POST_GET_BUSINESS_INFO_BY_ID: return postGetBusinessInfoById(localState, action);

        case POST_ADD_BUSINESS_ADMIN_ON_UPDATE :return postAddBusinessAdminOnUpdate(localState, action);

        default:
            return state;
    }
}

export default reducerBusiness;
