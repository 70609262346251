import React, { Component } from 'react';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withRouter } from 'react-router-dom';
import {
    handleResetPassword,
    handleTextFieldChange
} from '../../actions/Login';
import './Signin.css';
class ResetPassword extends Component {
  render() {
      let {showLoaderStatus} = this.props.loginState;
      let loginTextFields = {...this.props.loginState.loginTextFields}
      delete loginTextFields['email']
      let disableForgot = loginTextFields.password.value?false:true
    return (
        <div className="d-flex align-items-center justify-content-center">
            <div className="card-body">
                <form>
                    {
                        Object.keys(loginTextFields).map((textFields) => {
                            return(
                            <div className="input-group no-border input-lg" key = {loginTextFields[textFields].name}>
                                <div className="input-group-prepend">
                                    <span className="input-group-text">
                                        <i className={loginTextFields[textFields].icon}></i>
                                    </span>
                                </div>
                                <input 
                                    type={loginTextFields[textFields].type}
                                    className="form-control" 
                                    placeholder={loginTextFields[textFields].name}
                                    value = {loginTextFields[textFields].value}
                                    onChange = {(event) => {this.props.handleTextFieldChange(textFields, event,loginTextFields)}}
                                />
                            </div>
                            
                            )
                        })
                    }

                        {
                            showLoaderStatus ? <CircularProgress style = {{color : "#69bce6"}} /> : 
                                <div className="text-center">
                                    <button
                                        // type = "submit"
                                        onClick = {() => this.props.handleResetPassword(loginTextFields, this.props)} 
                                        className="btn btn-info btn-round btn-lg btn-block btn-login"
                                        disabled={disableForgot}
                                        style={{cursor:disableForgot?'not-allowed':''}}
                                    >
                                        Reset Password
                                    </button>
                                </div>
                        }

            </form>
        </div>
    </div>
    );
  }
}

const mapStateToProps = state => {
    return {
        loginState : state.login
    }
}

const mapDispatchToProps = dispatch => {
    return{
        handleResetPassword : (loginControls, props) => {
            return dispatch(handleResetPassword(loginControls, props))
        },
        handleTextFieldChange : (textField, event,loginTextField) => {
            return dispatch(handleTextFieldChange(textField, event,loginTextField))
        },
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ResetPassword));
