import React, { Component } from 'react';
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import FormGroup from "@material-ui/core/FormGroup";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import CardHeader from "@material-ui/core/CardHeader";
import {connect} from "react-redux";
import CircularProgress from '@material-ui/core/CircularProgress';


import Input from "../../components/UI/Input/Input";
import {
    inputHandler,
    onSubmitSFM,
    onBlurTextfield,
    initializeState
} from "../../actions/SFM";

import {
  downloadAFMBFMSFMJSON
} from "../../actions/Common";

class SFM extends Component{
    componentDidMount(){
        this.props.initializeState()
    }
    render(){
        let {
            uploadSFMForm,
            sfmJSON,
            sfmLoaderStatus
        } = this.props.sfmState;
        const formElementArray = [];
        for(let key in uploadSFMForm){
          formElementArray.push({
            id : key,
            config : uploadSFMForm[key]
          })
        }
        let form = (
            <FormGroup row className = "pl-3 pr-1">
                <Grid container spacing={24}>
                    <Grid item xs={12} sm = {12}>
                        <form>
                            {
                                formElementArray.map(formElement => {
                                    if(formElement.id === 'upload_sfm'){
                                        return( <Input
                                            key = {formElement.id}
                                            name = {formElement.config.elementConfig.label}
                                            elementType={formElement.config.elementType}
                                            elementConfig={formElement.config.elementConfig}
                                            value={formElement.config.value}
                                            menuItems = {formElement.config.menuItems}
                                            error = {formElement.config.errorStatus}
                                            helperText = {formElement.config.errorMessage}
                                            changed={(event) => this.props.inputHandler(formElement.id, event, sfmJSON )}
                                            />)
                                    }else{
                                        return(
                                            <Input
                                            key = {formElement.id}
                                            name = {formElement.config.elementConfig.label}
                                            elementType={formElement.config.elementType}
                                            elementConfig={formElement.config.elementConfig}
                                            value={formElement.config.value}
                                            menuItems = {formElement.config.menuItems}
                                            error = {formElement.config.errorStatus}
                                            helperText = {formElement.config.errorMessage}
                                            changed={(event) => this.props.inputHandler(formElement.id, event, sfmJSON )}
                                            touched = {(event) => this.props.inputHandler(formElement.id, event, sfmJSON )}
                                            />)
                                    }
                                   
                                })
                            }
                            
                        </form>  
                    </Grid> 
                </Grid>
            </FormGroup>
        )
        return(
            <Grid container spacing={24}>
            <Grid item xs={1} sm={2} />
            <Grid item xs={11} sm={8}>
                <Card className = "pl-3 pr-3 pt-3 container">
                    <Grid container spacing={24}>
                        <Grid item xs={6} sm = {6}>
                            <CardHeader
                                title="Upload SFM"
                            />
                        </Grid>
                        <Grid item xs={6} sm = {6} style = {{marginTop : "-1rem", display : "flex", justifyContent : "flex-end"}}>
                            <Button 
                                variant="outlined" 
                                color = "default"
                                className = "mt-4 mb-4"
                                onClick = {() => downloadAFMBFMSFMJSON('SFM')} >
                                <i className="material-icons" style = {{paddingRight : "0.6rem"}}>
                                    cloud_download </i>
                                Download sample JSON
                            </Button>
                        </Grid>
                    </Grid>
                <CardContent>
                    <FormGroup row className = "pl-3 pr-1">
                        <Grid container spacing={24}>
                            <Grid item xs={12} sm = {12}>
                                {form}
                            </Grid>      
                        </Grid>
                        <Grid container spacing={24}>
                            <Grid item xs={10} sm = {10}>
                            </Grid> 
                            <Grid item xs={2} sm = {2}>
                                {
                                    sfmLoaderStatus ? <CircularProgress color = "primary" /> :
                                    <Button 
                                        variant="contained" 
                                        color = "primary"
                                        className = "mt-4"
                                        type = "button"
                                        onClick = {() => this.props.onSubmitSFM(uploadSFMForm, sfmJSON)}
                                        >
                                        Submit
                                    </Button>
                                }

                            </Grid>     
                        </Grid>
                    </FormGroup>
                </CardContent>
            </Card>
            </Grid>
            <Grid item xs={1} sm={2} />
          </Grid>

        )
    }
}

const mapStateToProps = state => {
    return{
        sfmState : state.SFM
    }
}

const mapDispatchToProps = dispatch => {
    return{
        inputHandler : (formElementId, event, sfmJSON) => {
            return dispatch(inputHandler(formElementId, event, sfmJSON))
        },
        onSubmitSFM : (uploadSFMForm, sfmJSON) => {
            return dispatch(onSubmitSFM(uploadSFMForm, sfmJSON))
        },
        onBlurTextfield : (formElementId, event) => {
            return dispatch(onBlurTextfield(formElementId, event))
        },
        initializeState : () => {
            return dispatch(initializeState())
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SFM);