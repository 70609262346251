import React, { Component } from 'react';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import FormGroup from '@material-ui/core/FormGroup';
import { connect } from 'react-redux';
import MuiExpansionPanel from '@material-ui/core/ExpansionPanel';
import MuiExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import MuiExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Checkbox from '@material-ui/core/Checkbox';
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

import SGLoader from "../../../components/UI/Loader/SGLoader";
import Input from "../../../components/UI/Input/Input";


import {
    handleCreateChecklistTexfield,
    getTemplatesForExpansionPanel,
    handleTemplateExpansionCollapse,
    handleCheckItemCheckbox,
    initializeCreateChecklist,
    onSubmitCreateChecklist,
    onUpdateChecklist
} from "../../../actions/Checklist";

const ExpansionPanel = withStyles({
    root: {
      border: '1px solid rgba(0,0,0,.125)',
      boxShadow: 'none',
      '&:not(:last-child)': {
        borderBottom: 0,
      },
      '&:before': {
        display: 'none',
      },
    },
    expanded: {
      margin: 'auto',
    },
  })(MuiExpansionPanel);
const ExpansionPanelSummary = withStyles({
    root: {
      backgroundColor: 'rgba(0,0,0,.03)',
      borderBottom: '1px solid rgba(0,0,0,.125)',
      marginBottom: -1,
      minHeight: 56,
      '&$expanded': {
        minHeight: 56,
      },
    },
    content: {
      '&$expanded': {
        margin: '12px 0',
      },
    },
    expanded: {},
  })(props => <MuiExpansionPanelSummary {...props} />);
ExpansionPanelSummary.muiName = 'ExpansionPanelSummary';
const ExpansionPanelDetails = withStyles(theme => ({
    root: {
      padding: theme.spacing.unit * 2,
    },
  }))(MuiExpansionPanelDetails);


class CreateChecklist extends Component{
    componentDidMount(){
        this.props.getTemplatesForExpansionPanel();
        this.props.initializeCreateChecklist();
    }
    render(){
        let {
            templateExpansionPanelExpandedStatus,
            templateExpansionPanelDetails,
            createcheckListTextFields,
            selectedCreateCheckItemArray,
            getTemplatesForExpansionPanelLoaderStatus,
            onSubmitCreateChecklistLoaderStatus
        } = this.props.checkListState;
        let{
            menuItems
        } = this.props.sidebarState;
        let url = window.location.href;
        url = url.split('/')[url.split('/').length - 1];
        const formElementArray = [];
        for(let key in createcheckListTextFields){
            formElementArray.push({
                id : key,
                config : createcheckListTextFields[key]
            })
        }

        let selectionExpansionPanelWidget = (
            templateExpansionPanelDetails.map(expansionDetail => {
                return(
                    <ExpansionPanel
                        square
                        expanded={templateExpansionPanelExpandedStatus === expansionDetail}
                        onChange={(event) => this.props.handleTemplateExpansionCollapse(event, expansionDetail, selectedCreateCheckItemArray)}
                    >
                    <ExpansionPanelSummary>
                        <i className="material-icons mr-2">
                            chrome_reader_mode
                        </i>
                        <Typography style = {{paddingTop : "0.15rem"}}>
                            {expansionDetail.template_name}
                        </Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails style = {{flexDirection: "column", padding : "0"}}>
                        {
                            expansionDetail.checkItems ?
                                expansionDetail.checkItems.length === 0 ?
                                <div 
                                    style = {{paddingLeft : "1rem", height : "30vh"}} 
                                    className = "d-flex justify-content-center align-items-center"
                                >
                                    <Typography style = {{paddingTop : "0.15rem"}}>
                                        No Checkitem Found
                                    </Typography>
                                </div> :
                                expansionDetail.checkItems.map(checklistItem => {
                                    return(
                                        <div>
                                            <ListItem>
                                                <ListItemIcon>
                                                    <Checkbox
                                                        checked={checklistItem.checkedStatus}
                                                        onChange= {() => this.props.handleCheckItemCheckbox(expansionDetail.id, checklistItem.id)}
                                                        value={checklistItem.id}
                                                    />
                                                </ListItemIcon>
                                                <ListItemText inset primary={checklistItem.name} style = {{padding : "0"}}/>
                                            </ListItem>
                                            <Divider />
                                        </div>
                                    )
                                }) :
                                <div 
                                    style = {{paddingLeft : "1rem", height : "30vh"}} 
                                    className = "d-flex justify-content-center align-items-center"
                                >
                                        <CircularProgress />
                                </div>
                        }
                    </ExpansionPanelDetails>
                </ExpansionPanel>
                )
            })
        );
        let createcheckListTextFieldWidget = (
            <FormGroup row>
                <Grid 
                    container 
                    spacing = {24}
                    justify = "center"
                    alignItems = "center"
                >
                    {
                        formElementArray.map(formElement => {
                                return(
                                    <Grid item xs = {12} sm = {4} key = {formElement.id}>
                                        <Input 
                                            key = {formElement.id}
                                            name = {formElement.config.elementConfig.label}
                                            elementType={formElement.config.elementType}
                                            elementConfig={formElement.config.elementConfig}
                                            value={formElement.config.value}
                                            menuItems = {formElement.config.menuItems}
                                            error = {formElement.config.errorStatus}
                                            helperText = {formElement.config.errorMessage}
                                            changed = {(event) => this.props.handleCreateChecklistTexfield(formElement.id, event)}
                                            disabledStatus={formElement.config.disabledStatus}
                                            maxLength={formElement.config.maxLength}
                                        />
                                    </Grid>
                                )
                        }
                        )
                    }
                </Grid>
            </FormGroup>
        );
        let elementConfig = {};
        elementConfig['type'] = 'text';
        elementConfig['label'] = 'Enter Checklist Name';
        let createChecklistWidget = (
            <Paper className = "pt-0 mt-3 mb-3">
                <Card>
                    <Grid 
                        container 
                        spacing = {24} 
                        justify = "space-between"
                        alignItems = "center"
                        className = "pt-2 pb-3"
                    >
                        <Grid item>
                        <Typography variant="title" gutterBottom className = "ml-4 pt-2">
                            {
                                url === "editchecklist"  ? "Edit Checklist" : "Create Checklist"
                            }
                            
                        </Typography>
                        </Grid>
                    </Grid>
                    <Divider />
                    {createcheckListTextFieldWidget}
                    <Grid 
                        container 
                        spacing = {24} 
                        justify = "center"
                        alignItems = "center"
                        className = "pt-2 pb-3"
                    >
                        <Grid item sm = {8}>
                            {
                                getTemplatesForExpansionPanelLoaderStatus ? <SGLoader />  : selectionExpansionPanelWidget
                            }
                            
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        direction="row"
                        justify="flex-end"
                        alignItems="center"
                        className = "pr-3 mb-3"
                    >
                        <Grid item>
                        {
                            url === "editchecklist" ?
                            onSubmitCreateChecklistLoaderStatus ? <CircularProgress /> : 
                            <Button 
                                variant="contained" 
                                color="primary"
                                onClick = {() => this.props.onUpdateChecklist(selectedCreateCheckItemArray,createcheckListTextFields, this.props, menuItems['business_checklist'].submenu['assigned_checklitem_list'].urlpattern)}
                            >
                                Update
                            </Button>
                            : 
                            onSubmitCreateChecklistLoaderStatus ? <CircularProgress /> : 
                            <Button 
                                variant="contained" 
                                color="primary"
                                onClick = {() => this.props.onSubmitCreateChecklist(selectedCreateCheckItemArray,createcheckListTextFields, this.props, menuItems['business_checklist'].submenu['assigned_checklitem_list'].urlpattern)}
                            >
                                Submit
                            </Button>
                        }
                        </Grid>
                    </Grid>
                </Card>
            </Paper>        
        )
        return(
            <div className = "container">
                {createChecklistWidget}
            </div>
        )
    }
}
const mapStateToProps = state => {
    return{
        checkListState : state.checkList,
        sidebarState : state.sidebarState
    }
}

const mapDispatchToProps = dispatch => {
    return{
        handleCreateChecklistTexfield : (formFieldId, event) => {
            return dispatch(handleCreateChecklistTexfield(formFieldId, event))
        },
        getTemplatesForExpansionPanel : () => {
            return dispatch(getTemplatesForExpansionPanel())
        },
        handleTemplateExpansionCollapse : (event, expandionDetails, selectedCreateCheckItemArray) => {
            return dispatch(handleTemplateExpansionCollapse(event, expandionDetails, selectedCreateCheckItemArray))
        },
        handleCheckItemCheckbox : (templateID, checkItemId) => {
            return dispatch(handleCheckItemCheckbox(templateID, checkItemId))
        },
        initializeCreateChecklist : () => {
            return dispatch(initializeCreateChecklist())
        },
        onSubmitCreateChecklist : (selectedCreateCheckItemArray, createcheckListTextFields, props, url) => {
            return dispatch(onSubmitCreateChecklist(selectedCreateCheckItemArray,createcheckListTextFields, props, url))
        },
        onUpdateChecklist : (selectedCreateCheckItemArray, createcheckListTextFields, props, url) => {
            return dispatch(onUpdateChecklist(selectedCreateCheckItemArray, createcheckListTextFields, props, url))
        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(CreateChecklist);
