import React, { Component } from "react";
import MaterialTable from "material-table";
import { connect } from "react-redux";
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { toast } from 'react-toastify';

import {
    getExternalSystemsList,
    editExternalSystem,
    deleteExternalSystem
} from '../../actions/ExternalSystems';

class ListExternalSystems extends Component {
  componentDidMount(){
      this.props.getExternalSystemsList();
  }
  render() {
    let { 
      externalSystemRowData,
      showLoaderStatus,
      extsysColumns
    } = this.props.externalSystems;
    return (
       <div 
        style = {{marginTop : '2rem', marginBottom : "2rem"}}
        className = "container"
      >
        <Paper>
            <div className = "pt-1 pl-3">
              <span className = "d-flex">
                <div className = "mr-auto" style = {{paddingTop : "0"}}>
                    <h4 style = {{marginTop : "1rem", textTransform : "uppercase"}}>External Systems List</h4>
                </div>
                <div className = "d-flex flex-wrap align-content-center">
                    <Button 
                        variant="contained" 
                        color="primary"
                        onClick = {() => this.props.history.push("/sgadminportal/extsys")}
                        className = "mr-2"
                    >
                        <i className="material-icons" style = {{paddingRight : "0.5rem"}}>
                            add_circle_outline
                        </i>
                        Add External System
                    </Button>
                </div>
              </span>
            </div>
            {
              showLoaderStatus ? 
                <div style = {{height : "20rem"}} className = "d-flex justify-content-center align-items-center">
                    <CircularProgress color = "primary" />
                </div>:
                <MaterialTable
                  columns={extsysColumns}
                  data={externalSystemRowData}
                  title=""
                  editable={{
                    onRowUpdate: (rowData, oldData) =>
                      new Promise((resolve, reject) => {
                        if(rowData.name !== ''){
                          resolve(this.props.editExternalSystem(rowData, externalSystemRowData));
                        }else{
                          toast.error("External System name should not be blank", {containerId: 'toastMsg'});
                          reject();
                        }
                      }),
                  }}
                  actions={[
                    {
                      icon: 'delete',
                      tooltip: 'Delete External System',
                      onClick: (event, rowData) => this.props.deleteExternalSystem(rowData, externalSystemRowData)
                    }
                  ]}
                  options={{ actionsColumnIndex: -1}}
                />
              }
          </Paper>
      </div>
    );
  }
}

const mapStateToProps = state => {
    return{
      externalSystems : state.externalSystems
    }
};

const mapDispatchToProps = dispatch => {
  return {
    getExternalSystemsList : () => {
        return dispatch(getExternalSystemsList())
    },
    editExternalSystem : (rowData, externalSystemRowData) => {
      return dispatch(editExternalSystem(rowData, externalSystemRowData))
    },
    deleteExternalSystem : (rowData, externalSystemRowData) => {
      return dispatch(deleteExternalSystem(rowData, externalSystemRowData))
    }

  }
}
export default connect(mapStateToProps,mapDispatchToProps)(ListExternalSystems);
