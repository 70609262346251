import{
    POST_HANDLE_OPEN_EDIT_CHECKLIST_ITEM_DIALOG,
    POST_HANDLE_CHECK_LIST_ITEM_DIALOG_CLOSE_STATUS,
    POST_HANDLE_ADD_CHECKLIST_ITEM_TEXTFIELD_CHANGE,
    POST_INITIALIZE_ADD_CHECKLIST_ITEM_FORM,
    POST_HANDLE_ADD_TEMPLATE_TEXTFIELDS,
    POST_GET_VEHICLES,
    POST_GET_TEMPLATES,
    POST_ON_SUBMIT_TEMPLATE_CREATE,
    POST_GET_CHECK_ITEMS,
    POST_ON_SUBMIT_ADD_CHECKLIST_ITEM,
    POST_ON_UPDATE_CHECK_ITEM,
    POST_INITIALIZE_ADD_TEMPLATE_FORM,
    POST_GET_EXAMPLE_IMAGE,
    POST_GET_TRIGGERS,
    POST_HANDLE_TRIGGER_DROPDOWN,
    POST_HANDLE_CREATE_CHECKLIST_TEXFIELD,
    POST_GET_TEMPLATES_FOR_EXPANSION_PANEL,
    POST_HANDLE_TEMPLATE_EXPANSION_COLLAPSE,
    POST_MAP_TEMPLATE_TO_CHECKLIST,
    POST_HANDLE_CHECKITEM_CHECKBOX,
    POST_INITIALIZE_CREATE_CHECKLIST,
    POST_ON_SUBMIT_CREATE_CHECKLIST,
    POST_GET_VEHICLE_BY_ID,
    POST_HANDLE_ASSIGNMENTLIST_EXPANSION_COLLAPSE,
    POST_MAP_CHECKLIST_TO_CHECKITEMS,
    POST_INITIALIZE_LIST_ASSIGNED_CHECKLIST,
    POST_ON_UPDATE_CHECKLIST,
    POST_ACTIVE_DELETE_CHECK_ITEM_OPTION,
    POST_DEACTIVE_DELETE_CHECKITEM_OPTION,
    POST_HANDLE_CHECKITEM_CHECKBOX_FOR_DELETE,
    POST_DELETE_CHECKITEMS,
    POST_GET_NOTIFICATION_DETAILS,
    POST_HANDLE_NOTIFICATION_SETTING_FORMFIELD,
    POST_ON_SUBMIT_NOTIFICATION_SETTING,
    POST_UPDATE_TEMPLATE
} from "./ActionType";
import { toast } from 'react-toastify';
import axios from "../axios";
import{
    getIndex,
    getActulaURL
} from "../utils/Helper";
import {removeFakePath, convertToBase64, getMimeType} from "../utils/Helper";
import {apiUrl, xAPIKey} from "../config";

export const onSubmitNotificationSetting = (notificationSettingsForm) => {
    return (dispatch) => {
        let reqURL = "/businesses/"+localStorage.getItem('business_id')+"/noficationsettings";
        let reqParams = {};
        reqParams['percentage'] = notificationSettingsForm['fast_prestart'].value;
        reqParams['notify'] = notificationSettingsForm['failed_check'].value;
        let onSubmitNotificationSettingLoaderStatus = false;
        let onSubmitNotificationSettingValidationStatus = true;
        Object.keys(notificationSettingsForm).map(inputField => {
            if(notificationSettingsForm[inputField].value === ''){
                notificationSettingsForm[inputField].errorStatus = true;
                onSubmitNotificationSettingValidationStatus = false;
            }else{
                notificationSettingsForm[inputField].errorStatus = false;
            }
        })
        dispatch(postOnSubmitNotificationSetting(onSubmitNotificationSettingLoaderStatus))
        if(onSubmitNotificationSettingValidationStatus){
            onSubmitNotificationSettingLoaderStatus = true;
            dispatch(postOnSubmitNotificationSetting(onSubmitNotificationSettingLoaderStatus))
            return axios.put(reqURL, reqParams)
            .then(response => {
                onSubmitNotificationSettingLoaderStatus = false;
                toast.success("Notification Settings Updated Successfully", {
                    containerId: 'toastMsg'
                });
                dispatch(postOnSubmitNotificationSetting(onSubmitNotificationSettingLoaderStatus))
            })
            .catch(error => {
                onSubmitNotificationSettingLoaderStatus = false;
                dispatch(postOnSubmitNotificationSetting(onSubmitNotificationSettingLoaderStatus))
            })
        }
    }
}
export const postOnSubmitNotificationSetting = (onSubmitNotificationSettingLoaderStatus) => {
    return{
        type : POST_ON_SUBMIT_NOTIFICATION_SETTING,
        onSubmitNotificationSettingLoaderStatus : onSubmitNotificationSettingLoaderStatus
    }
}
export const handleNotificationSettingFormField = (formElementId, event) => {
    return (dispatch) => {
        let formFiedValue = event.target.value;
        if(formElementId === 'fast_prestart'){
            formFiedValue = (event.target.value).replace(/[^0-9]/g, '');
        }
        
        return dispatch(postHandleNotificationSettingFormField(formElementId, formFiedValue))
    }
}
export const postHandleNotificationSettingFormField = (formElementId, formFiedValue) => {
    return{
        type : POST_HANDLE_NOTIFICATION_SETTING_FORMFIELD,
        formElementId : formElementId,
        data : formFiedValue
    }
}
export const getNotificationDetails = () => {
    return (dispatch) => {
        let getNotificationSettingLoaderStatus = true;
        let notificationData;
        let reqURL = "/businesses/"+localStorage.getItem('business_id')+"/noficationsettings";
        dispatch(postGetNotificationDetails(notificationData, getNotificationSettingLoaderStatus))
        return axios.get(reqURL)
        .then(response => {
            getNotificationSettingLoaderStatus = false;
            notificationData = response.data.data;
            dispatch(postGetNotificationDetails(notificationData, getNotificationSettingLoaderStatus))
        })
        .catch(error => {
            getNotificationSettingLoaderStatus = false;
            dispatch(postGetNotificationDetails(notificationData, getNotificationSettingLoaderStatus))
        })
    }
}
export const postGetNotificationDetails = (notificationData, getNotificationSettingLoaderStatus) => {
    return{
        type : POST_GET_NOTIFICATION_DETAILS,
        notificationData : notificationData,
        getNotificationSettingLoaderStatus : getNotificationSettingLoaderStatus
    }
}
export const deleteCheckItems = (checkItemArrayForDelete, checkListId, checkListName) => {
    return (dispatch) => {
        let reqURL = "/businesses/"+localStorage.getItem('business_id')+"/vehicles/"+localStorage.getItem('vehicle_id')+"/checklists/"+checkListId;
        let reqParams = {};
        let reqCheckitemArrayForDelete = [];
        let deleteCheckitemLoaderStatus = true;
        let deletedcheckitemInfo = {};

        let updateCheckItems = [];
        let updateCheckItemInfo = {};
        for(let i = 0 ; i< checkItemArrayForDelete.length; i++){
            deletedcheckitemInfo = {};
            deletedcheckitemInfo['checkid'] = checkItemArrayForDelete[i];
            deletedcheckitemInfo['checkstatus'] = false;
            reqCheckitemArrayForDelete.push(deletedcheckitemInfo);
        }
        reqParams['checklist_name'] = checkListName;
        reqParams['trigger_name'] = localStorage.getItem('trigger_id');
        reqParams['checklist_status'] = true;
        reqParams['checklists'] = reqCheckitemArrayForDelete;
        dispatch(postDeleteCheckitems(deleteCheckitemLoaderStatus, updateCheckItems, checkListId))
        return axios.put(reqURL, reqParams)
        .then(response => {
            deleteCheckitemLoaderStatus = false;
            for(let i = 0 ; i < response.data.data.checklists.length; i++){
                if(response.data.data.checklists[i].checkstatus){
                    updateCheckItemInfo = {};
                    updateCheckItemInfo['id'] = response.data.data.checklists[i].checkid._id;
                    updateCheckItemInfo['check_item_satus'] = response.data.data.checklists[i].checkid.check_status;
                    updateCheckItemInfo['check_item_name'] = response.data.data.checklists[i].checkid.check_item_name;
                    updateCheckItems.push(updateCheckItemInfo);
                }
            }
            dispatch(postDeleteCheckitems(deleteCheckitemLoaderStatus, updateCheckItems, checkListId))
        })
        .catch(error => {
            deleteCheckitemLoaderStatus = false;
            dispatch(postDeleteCheckitems(deleteCheckitemLoaderStatus, updateCheckItems, checkListId))
        })
    }
}
export const postDeleteCheckitems = (deleteCheckitemLoaderStatus, updateCheckItems, checkListId) => {
    return{
        type : POST_DELETE_CHECKITEMS,
        deleteCheckitemLoaderStatus : deleteCheckitemLoaderStatus,
        updateCheckItems : updateCheckItems,
        checkListId : checkListId
    }
}
export const handleCheckItemCheckboxForDelete = (checklistId, checklistItemId) => {
    return (dispatch) => {
        return dispatch(postHandleCheckItemCheckboxForDelete(checklistId, checklistItemId))
    }
}
export const postHandleCheckItemCheckboxForDelete = (checklistId, checklistItemId) => {
    return{
        type : POST_HANDLE_CHECKITEM_CHECKBOX_FOR_DELETE,
        checklistItemId : checklistItemId,
        checklistId : checklistId
    }
}
export const deactiveDeleteCheckitemOption = () => {
    return (dispatch) => {
        return dispatch(postDeactiveDeleteCheckitemOption())
    }
}

export const postDeactiveDeleteCheckitemOption = () => {
    return{
        type : POST_DEACTIVE_DELETE_CHECKITEM_OPTION
    }
}

export const activeDeleteCheckitemOption = () => {
    return (dispatch) => {
        return dispatch(postActiveDeleteCheckItemOption())
    }
}
export const postActiveDeleteCheckItemOption = () => {
    return{
        type : POST_ACTIVE_DELETE_CHECK_ITEM_OPTION
    }
}
export const onUpdateChecklist = (selectedCreateCheckItemArray, createcheckListTextFields, props, url) => {
    return (dispatch) => {
        let reqURL = "/businesses/"+localStorage.getItem('business_id')+"/vehicles/"+localStorage.getItem('vehicle_id')+"/checklists/"+localStorage.getItem('checklist_id');
        let reqParams = {};
        let createChecklistValidationStatus = true;
        let onSubmitCreateChecklistLoaderStatus = false;
        let checklistItem = {};
        let cheklistitemListArray = [];
        for(let i = 0; i < selectedCreateCheckItemArray.length; i++){
            checklistItem = {};
            checklistItem['checkid'] = selectedCreateCheckItemArray[i];
            checklistItem['checkstatus'] = true;
            cheklistitemListArray.push(checklistItem);
        }
        reqParams['checklist_name'] = createcheckListTextFields['checklist_name'].value;
        reqParams['trigger_name'] = localStorage.getItem('trigger_id');
        reqParams['checklist_status'] = true;
        reqParams['checklists'] = cheklistitemListArray;
        if(createcheckListTextFields['checklist_name'].value === ''){
            createChecklistValidationStatus = false;
        }else{
            if(selectedCreateCheckItemArray.length === 0){
                toast.error("Please select atleast one checkitem", {
                    containerId: 'toastMsg'
                });
                createChecklistValidationStatus = false;
            }
        }
        dispatch(postOnUpdateChecklist(onSubmitCreateChecklistLoaderStatus))
        if(createChecklistValidationStatus){
            onSubmitCreateChecklistLoaderStatus = true;
            dispatch(postOnUpdateChecklist(onSubmitCreateChecklistLoaderStatus))
            return axios.put(reqURL, reqParams)
            .then(response => {
                onSubmitCreateChecklistLoaderStatus = false;
                toast.success("Checklist Updated Successfully", {
                    containerId: 'toastMsg',
                    onClose : () => props.history.push(url)
                });
                dispatch(postOnUpdateChecklist(onSubmitCreateChecklistLoaderStatus))
            })
            .catch(error => {
                onSubmitCreateChecklistLoaderStatus = false;
                dispatch(postOnUpdateChecklist(onSubmitCreateChecklistLoaderStatus))
            })
        }
    }
}
export const postOnUpdateChecklist = (onSubmitCreateChecklistLoaderStatus) => {
    return{
        type : POST_ON_UPDATE_CHECKLIST,
        onSubmitCreateChecklistLoaderStatus : onSubmitCreateChecklistLoaderStatus
    }
}
export const initializeListAssignedCheckList = () => {
    return (dispatch) => {
        return dispatch(postInitializListAssignedChecklist())
    }
}

export const postInitializListAssignedChecklist = () => {
    return{
        type : POST_INITIALIZE_LIST_ASSIGNED_CHECKLIST
    }
}

export const handleAssignmentListExpansionCollapse = (event, expansionDetail) => {
    return (dispatch) => {
        let checklistCheckitems = [];
        let checklistItemInfo = {};
        let reqURL = "/businesses/"+localStorage.getItem('business_id')+"/vehicles/"+localStorage.getItem('vehicle_id')+"/checklists/"+expansionDetail.id;
        dispatch(postHandleAssignmentListExpansionCollapse(checklistCheckitems, expansionDetail))
        return axios.get(reqURL)
        .then(response => {
            for(let i = 0; i < response.data.data.checklists.length; i++){
                if(response.data.data.checklists[i].checkstatus){
                    checklistItemInfo = {};
                    checklistItemInfo['id'] = response.data.data.checklists[i].checkid._id;
                    checklistItemInfo['check_item_satus'] = response.data.data.checklists[i].checkstatus;
                    checklistItemInfo['check_item_name'] = response.data.data.checklists[i].checkid.check_item_name;
                    checklistItemInfo['checkedStatus'] = false;
                    checklistCheckitems.push(checklistItemInfo);
                }
            }
            dispatch(postMapChecklistToCheckItems(checklistCheckitems, expansionDetail))
        })
        .catch(error => {
            dispatch(postMapChecklistToCheckItems(checklistCheckitems, expansionDetail))
        })
    }
}

export const postMapChecklistToCheckItems = (checklistCheckitems, expansionDetail) => {
    return{
        type : POST_MAP_CHECKLIST_TO_CHECKITEMS,
        checklistCheckitems : checklistCheckitems,
        expansionDetail : expansionDetail
    }
}

export const postHandleAssignmentListExpansionCollapse = (checklistCheckitems, expansionDetail) => {
    return{
        type : POST_HANDLE_ASSIGNMENTLIST_EXPANSION_COLLAPSE,
        checklistCheckitems : checklistCheckitems,
        expansionDetail : expansionDetail
    }
}

export const getVehicleById = () => {
    return (dispatch) => {
        let vehicleInfoForAssignChecklist = {};
        let reqURL = "/businesses/"+localStorage.getItem("business_id")+"/vehicles/"+localStorage.getItem('vehicle_id');
        return axios.get(reqURL)
        .then(response => {
            vehicleInfoForAssignChecklist['vehicle_id'] = response.data.data.vehicle_id;
            vehicleInfoForAssignChecklist['vehicle_plate'] = response.data.data.vehicle_plate;
            vehicleInfoForAssignChecklist['vehicle_state'] = response.data.data.vehicle_state;
            dispatch(postGetVehicleById(vehicleInfoForAssignChecklist))
        })
        .catch(error => {
            dispatch(postGetVehicleById(vehicleInfoForAssignChecklist))
        })
    }
}
export const postGetVehicleById = (vehicleInfoForAssignChecklist) => {
    return{
        type : POST_GET_VEHICLE_BY_ID,
        vehicleInfoForAssignChecklist : vehicleInfoForAssignChecklist
    }
}
export const onSubmitCreateChecklist = (selectedCreateCheckItemArray, createcheckListTextFields, props, url) => {
    return (dispatch) => {
        let reqURL = "/businesses/"+localStorage.getItem('business_id')+"/vehicles/"+localStorage.getItem('vehicle_id')+"/checklists";
        let reqParams = {};
        let createChecklistValidationStatus = true;
        let onSubmitCreateChecklistLoaderStatus = false;
        let checklistItem = {};
        let cheklistitemListArray = [];
        for(let i = 0; i < selectedCreateCheckItemArray.length; i++){
            checklistItem = {};
            checklistItem['checkid'] = selectedCreateCheckItemArray[i];
            cheklistitemListArray.push(checklistItem);
        }
        reqParams['checklist_name'] = createcheckListTextFields['checklist_name'].value;
        reqParams['trigger_name'] = localStorage.getItem('trigger_id');
        reqParams['checklists'] = cheklistitemListArray;
        if(createcheckListTextFields['checklist_name'].value === ''){
            createChecklistValidationStatus = false;
        }else{
            if(selectedCreateCheckItemArray.length === 0){
                toast.error("Please select atleast one checkitem", {
                    containerId: 'toastMsg'
                });
                createChecklistValidationStatus = false;
            }
        }
        dispatch(postOnSubmitCreateChecklist(onSubmitCreateChecklistLoaderStatus));
        if(createChecklistValidationStatus){
            onSubmitCreateChecklistLoaderStatus = true;
            dispatch(postOnSubmitCreateChecklist(onSubmitCreateChecklistLoaderStatus));
            return axios.post(reqURL, reqParams)
            .then(response => {
                onSubmitCreateChecklistLoaderStatus = false;
                toast.success("Checklist Created Successfully", {
                    containerId: 'toastMsg',
                    onClose : () => props.history.push(url)
                });
                dispatch(postOnSubmitCreateChecklist(onSubmitCreateChecklistLoaderStatus));
            })
            .catch(error => {
                onSubmitCreateChecklistLoaderStatus = false;
                dispatch(postOnSubmitCreateChecklist(onSubmitCreateChecklistLoaderStatus));
            })
        }
    }
}
export const postOnSubmitCreateChecklist = (onSubmitCreateChecklistLoaderStatus) => {
    return{
        type : POST_ON_SUBMIT_CREATE_CHECKLIST,
        onSubmitCreateChecklistLoaderStatus : onSubmitCreateChecklistLoaderStatus
    }
}
export const initializeCreateChecklist = () => {
    return (dispatch) => {
        return dispatch(postInitializeCreateChecklist())
    }
}
export const postInitializeCreateChecklist = () => {
    return{
        type : POST_INITIALIZE_CREATE_CHECKLIST
    }
}
export const handleCheckItemCheckbox = (templateID, checkItemId) => {
    return(dispatch) => {
        dispatch(postHandleCheckItemCheckbox(templateID, checkItemId))
    }
}
export const postHandleCheckItemCheckbox = (templateID, checkItemId) => {
    return{
        type : POST_HANDLE_CHECKITEM_CHECKBOX,
        templateID : templateID,
        checkItemId : checkItemId
    }
}
export const handleTemplateExpansionCollapse = (event, expansionDetail, selectedCreateCheckItemArray) => {
    return (dispatch) => {
        let reqURL = "/businesses/"+localStorage.getItem('business_id')+"/templates/"+expansionDetail.id+"/checks";
        let checkItems = [];
        let checkIteminfo = {};
        dispatch(postHandleTemplateExpansionCollapse(event, expansionDetail, checkItems))
        return axios.get(reqURL)
        .then(response => {
            for(let i = 0; i < response.data.data.length; i++){
                if(response.data.data[i].check_status){
                    checkIteminfo = {};
                    checkIteminfo['id'] = response.data.data[i]._id;
                    checkIteminfo['name'] = response.data.data[i].check_item_name;
                    checkIteminfo['checkedStatus'] = false;
                    checkItems.push(checkIteminfo);
                }
            }
            if(checkItems.length > 0){
                checkIteminfo = {};
                checkIteminfo['id'] = "select_all";
                checkIteminfo['name'] = "Select All";
                checkIteminfo['checkedStatus'] = false;
                checkItems.unshift(checkIteminfo);

                if(selectedCreateCheckItemArray.length > 0){
                    for(let i = 0 ; i < selectedCreateCheckItemArray.length; i++){
                        for(let j = 0; j < checkItems.length; j++){
                            if(checkItems[j].id === selectedCreateCheckItemArray[i]){
                                checkItems[j].checkedStatus = true;
                            }
                        }
                    }
                }
            }
            dispatch(postMapTemplateToChecklist(expansionDetail.id, checkItems))
        })
        .catch(error => {
            dispatch(postHandleTemplateExpansionCollapse(event, expansionDetail, checkItems))
        })
    }
}
export const postMapTemplateToChecklist = (expansionDetailId, checkItems) => {
    return{
        type : POST_MAP_TEMPLATE_TO_CHECKLIST,
        expansionDetailId : expansionDetailId,
        checkItems : checkItems
    }
}
export const postHandleTemplateExpansionCollapse = (event, expansionDetail, checkItems) => {
    return{
        type : POST_HANDLE_TEMPLATE_EXPANSION_COLLAPSE,
        expansionDetail : expansionDetail,
        checkItems : checkItems
    }
}
export const getTemplatesForExpansionPanel = () => {
    return (dispatch) => {
        let reqURL = "/businesses/"+localStorage.getItem('business_id')+"/templates";
        let getTemplatesForExpansionPanelLoaderStatus = true;
        let templates = [];
        let templateInfo = {};
        dispatch(postGetTemplatesForExpansionPanel(templates, getTemplatesForExpansionPanelLoaderStatus))
        return axios.get(reqURL)
        .then(response => {
            getTemplatesForExpansionPanelLoaderStatus = false;
            for(let i = 0; i < response.data.data.length; i++){
                if(response.data.data[i].template_status){
                    templateInfo = {};
                    templateInfo['id'] = response.data.data[i]._id;
                    templateInfo['template_name'] = response.data.data[i].template_name;
                    templates.push(templateInfo)
                }
            }
            dispatch(postGetTemplatesForExpansionPanel(templates, getTemplatesForExpansionPanelLoaderStatus))
        })
        .catch(error => {
            getTemplatesForExpansionPanelLoaderStatus = false;
            dispatch(postGetTemplatesForExpansionPanel(templates, getTemplatesForExpansionPanelLoaderStatus))
        })
    }
}

export const postGetTemplatesForExpansionPanel = (templates, getTemplatesForExpansionPanelLoaderStatus) => {
    return{
        type : POST_GET_TEMPLATES_FOR_EXPANSION_PANEL,
        templates : templates,
        getTemplatesForExpansionPanelLoaderStatus : getTemplatesForExpansionPanelLoaderStatus
    }
}

export const handleCreateChecklistTexfield = (formFieldId, event) => {
    return (dispatch) => {
        return dispatch(postHandleCreateChecklistTextfield(formFieldId, event))
    }
}
export const postHandleCreateChecklistTextfield = (formFieldId, event) => {
    return{
        type : POST_HANDLE_CREATE_CHECKLIST_TEXFIELD,
        formFieldId : formFieldId,
        data : event.target.value
    }
}
export const handleTriggerDropdown = (formElementId, event) => {
    return (dispatch) => {
        if(event.target.value){
            let getCheckListloaderStatus = true;
            let checklistArray = [];
            let checklistInfo = {};
            localStorage.setItem('trigger_id', event.target.value);
            let reqURL = "/businesses/"+localStorage.getItem('business_id')+"/vehicles/"+localStorage.getItem('vehicle_id')+"/trigger/"+event.target.value;
            dispatch(postHandleTriggerDropdown(formElementId, event, checklistArray, getCheckListloaderStatus))
            return axios.get(reqURL)
            .then(response => {
                getCheckListloaderStatus = false;
                for(let i = 0; i < response.data.data.length; i++){
                    checklistInfo = {};
                    checklistInfo['checklist_name'] = response.data.data[i].checklist_name;
                    checklistInfo['checklist_status'] = response.data.data[i].checklist_status;
                    checklistInfo['id'] = response.data.data[i]._id;
                    checklistArray.push(checklistInfo);
                }
                dispatch(postHandleTriggerDropdown(formElementId, event, checklistArray, getCheckListloaderStatus))
            })
            .catch(error => {
                getCheckListloaderStatus = false;
                dispatch(postHandleTriggerDropdown(formElementId, event, checklistArray, getCheckListloaderStatus))
            })
        }
    }
}
export const postHandleTriggerDropdown = (formElementId, event, checklistArray, getCheckListloaderStatus) => {
    return{
        type : POST_HANDLE_TRIGGER_DROPDOWN,
        formElementId : formElementId,
        data : event.target.value,
        checklistArray : checklistArray,
        getCheckListloaderStatus : getCheckListloaderStatus
    }
}
export const getTriggers = () => {
    return (dispatch) => {
        let reqURL = "/triggers";
        let triggerData = [];
        return axios.get(reqURL)
        .then(response => {
            triggerData = response.data.data;
            dispatch(postGetTriggers(triggerData))
        })
        .catch(error => {
            dispatch(postGetTriggers(triggerData))
        })
    }
}

export const postGetTriggers = (triggerData) => {
    return{
        type : POST_GET_TRIGGERS,
        triggerData : triggerData
    }
}

export const getExampleImage = (checkItemId) => {
    return (dispatch) => {
        var request = new XMLHttpRequest();
        let reqURL = apiUrl + "/businesses/"+localStorage.getItem('business_id')+"/templates/"+localStorage.getItem('template_id')+"/checks/"+checkItemId+"/exampleCheckImage";
        request.open("GET", reqURL, true); 
        request.setRequestHeader("Authorization", localStorage.getItem('token'));
        request.setRequestHeader("Platform-User", localStorage.getItem('Platform-User'));
        request.setRequestHeader("x-api-key", xAPIKey);
        request.responseType = "blob";
        request.onload = function (e) {
            if (this.status === 200) {
                let mimeType = request.getResponseHeader('content-type').split('/');
                let fileName = 'sample_image';
                mimeType = mimeType[1];
                if(mimeType === 'jpeg'){
                    fileName = 'sample_image.jpeg';
                }
                if(mimeType === 'jpeg'){
                    fileName = 'sample_image.jpeg';
                }
                if(mimeType === 'png'){
                    fileName = 'sample_image.png';
                }
                var file = window.URL.createObjectURL(this.response);
                var a = document.createElement("a");
                a.href = file;
                a.download = this.response.name || fileName;
                document.body.appendChild(a);
                a.click();
                window.onfocus = function () {                     
                document.body.removeChild(a)
                }
            }
        };
        request.send();
        return dispatch(postGetExampleImage())
    }
}

export const postGetExampleImage = () => {
    return{
        type : POST_GET_EXAMPLE_IMAGE
    }
}

export const initializeAddTemplateForm = () => {
    return (dispatch) => {
        return dispatch(postInitializeAddTemplateForm())
    }
}
export const postInitializeAddTemplateForm = () => {
    return{
        type : POST_INITIALIZE_ADD_TEMPLATE_FORM
    }
}
export const onUpdateCheckItem = (addCheckListItemForm, checkItemImageBase64, activeCheckListItemForEditId, checkListItemInformation) => {
    return (dispatch) => {
        let checkitemUpdateLoaderStatus = false;
        let updateChecklistItemFormvalidationStatus = true;
        let editChecklistItemDialogStatus = true;
        Object.keys(addCheckListItemForm).map(inputField => {
            if(addCheckListItemForm[inputField].value === ''){
                addCheckListItemForm[inputField].errorStatus = true;
                updateChecklistItemFormvalidationStatus = false;
            }else{
                addCheckListItemForm[inputField].errorStatus = false;
            }
        })
        dispatch(postOnUpdateCheckItem(checkitemUpdateLoaderStatus, editChecklistItemDialogStatus))
        if(updateChecklistItemFormvalidationStatus){
            checkitemUpdateLoaderStatus = true;
            let reqURL = "/businesses/"+localStorage.getItem('business_id')+"/templates/"+localStorage.getItem('template_id')+"/checks/"+activeCheckListItemForEditId;
            let reqParams = {};
            let checkImage = {};
            if(checkItemImageBase64){
                checkImage['file_type'] = getMimeType(addCheckListItemForm['checklist_item_image'].value);
                checkImage['base64_string'] = checkItemImageBase64;
            }else{
                checkImage['file_type'] = "";
                checkImage['base64_string'] = "";
            }

            if(addCheckListItemForm['checklist_item_status'].value === 'active'){
                reqParams['check_status'] = true;
            }else{
                reqParams['check_status'] = false;
            }
            reqParams['check_item_name'] = addCheckListItemForm['checklist_item_name'].value;
            reqParams['check_item_description'] = addCheckListItemForm['checklist_item_description'].value;
            reqParams['check_item_estimated_time'] = addCheckListItemForm['checklist_item_estimatedtime'].value;
            reqParams['check_item_default_state'] = addCheckListItemForm['checklist_item_default_state'].value;
            reqParams['check_item_comments_required'] = addCheckListItemForm['checklist_item_comment_required'].value;
            reqParams['check_item_image_required'] = addCheckListItemForm['checklist_item_image_required'].value;
            if(checkItemImageBase64){
                reqParams['check_image'] = checkImage;
            }
            dispatch(postOnUpdateCheckItem(checkitemUpdateLoaderStatus, editChecklistItemDialogStatus))
            return axios.put(reqURL, reqParams)
            .then(response => {
                checkitemUpdateLoaderStatus = false;
                editChecklistItemDialogStatus = false;
                let getCheckListItemIndex = checkListItemInformation.findIndex(d => {
                    return d.id == activeCheckListItemForEditId;
                });
                checkListItemInformation[getCheckListItemIndex].check_item_name = response.data.data.check_item_name;
                checkListItemInformation[getCheckListItemIndex].check_item_description = response.data.data.check_item_description;
                if(response.data.data.check_status){
                    checkListItemInformation[getCheckListItemIndex].status = 'active';
                }else{
                    checkListItemInformation[getCheckListItemIndex].status = 'inactive';
                }
                toast.success("Check Item Updated Successfully", {
                    containerId: 'toastMsg'
                });
                dispatch(postOnUpdateCheckItem(checkitemUpdateLoaderStatus, editChecklistItemDialogStatus))
            })
            .catch(error => {
                checkitemUpdateLoaderStatus = false;
                editChecklistItemDialogStatus = false;
                dispatch(postOnUpdateCheckItem(checkitemUpdateLoaderStatus, editChecklistItemDialogStatus))
            })
        }
    }
}
export const postOnUpdateCheckItem = (checkitemUpdateLoaderStatus, editChecklistItemDialogStatus) => {
    return{
        type : POST_ON_UPDATE_CHECK_ITEM,
        checkitemUpdateLoaderStatus : checkitemUpdateLoaderStatus,
        editChecklistItemDialogStatus : editChecklistItemDialogStatus
    }
}
export const onSubmitAddChecklistItem = (addCheckListItemForm, checkItemImageBase64, url, props) => {
    return (dispatch) => {
        let checkitemSubmitLoaderStatus = false;
        let addChecklistItemFormvalidationStatus = true;
        Object.keys(addCheckListItemForm).map(inputField => {
            if(addCheckListItemForm[inputField].value === '' && inputField !== 'checklist_item_status' && inputField !== 'checklist_item_image'){
                addCheckListItemForm[inputField].errorStatus = true;
                addChecklistItemFormvalidationStatus = false;
            }else{
                addCheckListItemForm[inputField].errorStatus = false;
            }
        })
        dispatch(postOnSubmitAddChecklistItem(checkitemSubmitLoaderStatus))
        if(addChecklistItemFormvalidationStatus){
            checkitemSubmitLoaderStatus = true;
            let reqURL = "/businesses/"+localStorage.getItem('business_id')+"/templates/"+localStorage.getItem('template_id')+"/checks";
            let reqParams = {};
            let checkImage = {};
            checkImage['file_type'] = getMimeType(addCheckListItemForm['checklist_item_image'].value);
            checkImage['base64_string'] = checkItemImageBase64;
            reqParams['check_status'] = true;
            reqParams['check_item_name'] = addCheckListItemForm['checklist_item_name'].value;
            reqParams['check_item_description'] = addCheckListItemForm['checklist_item_description'].value;
            reqParams['check_item_estimated_time'] = addCheckListItemForm['checklist_item_estimatedtime'].value;
            reqParams['check_item_default_state'] = addCheckListItemForm['checklist_item_default_state'].value;
            reqParams['check_item_comments_required'] = addCheckListItemForm['checklist_item_comment_required'].value;
            reqParams['check_item_image_required'] = addCheckListItemForm['checklist_item_image_required'].value;
            if(checkItemImageBase64){
                reqParams['check_image'] = checkImage;
            }
            dispatch(postOnSubmitAddChecklistItem(checkitemSubmitLoaderStatus))
            return axios.post(reqURL, reqParams)
            .then(response => {
                checkitemSubmitLoaderStatus = false;
                toast.success("Check Item Created Successfully", {
                    containerId: 'toastMsg',
                    onClose : () => props.history.push(getActulaURL(url))
                });
                dispatch(postOnSubmitAddChecklistItem(checkitemSubmitLoaderStatus))
            })
            .catch(error => {
                checkitemSubmitLoaderStatus = false;
                dispatch(postOnSubmitAddChecklistItem(checkitemSubmitLoaderStatus))
            })
        }
    }
}
export const postOnSubmitAddChecklistItem = (checkitemSubmitLoaderStatus) => {
    return{
        type : POST_ON_SUBMIT_ADD_CHECKLIST_ITEM,
        checkitemSubmitLoaderStatus : checkitemSubmitLoaderStatus
    }
}
export const getCheckItems = () => {
    return (dispatch) => {
        let listCheckItemLoaderStatus = true;
        let reqURL = "/businesses/"+localStorage.getItem('business_id')+"/templates/"+localStorage.getItem('template_id')+"/checks";
        let checkListItemInformation = [];
        let checkListItemInfo = {};
        dispatch(postGetCheckItems(checkListItemInformation, listCheckItemLoaderStatus))
        return axios.get(reqURL)
        .then(response => {
            listCheckItemLoaderStatus = false;
            for(let i = 0; i < response.data.data.length; i++){
                checkListItemInfo = {};
                checkListItemInfo['id'] = response.data.data[i]._id;
                checkListItemInfo['check_item_name'] = response.data.data[i].check_item_name;
                checkListItemInfo['check_item_description'] = response.data.data[i].check_item_description;
                if(response.data.data[i].check_item_example_image){
                    checkListItemInfo['check_item_image'] = response.data.data[i].check_item_example_image.document_name;
                }else{
                    checkListItemInfo['check_item_image'] = "No Image";
                }
                
                if(response.data.data[i].check_status){
                    checkListItemInfo['status'] = "active";
                }else{
                    checkListItemInfo['status'] = "inactive";
                }
                checkListItemInformation.push(checkListItemInfo);
            }
            dispatch(postGetCheckItems(checkListItemInformation, listCheckItemLoaderStatus))
        })
        .catch(error => {
            listCheckItemLoaderStatus = false;
            dispatch(postGetCheckItems(checkListItemInformation, listCheckItemLoaderStatus))
        })
    }
}
export const postGetCheckItems = (checkListItemInformation, listCheckItemLoaderStatus) => {
    return{
        type : POST_GET_CHECK_ITEMS,
        checkListItemInformation : checkListItemInformation,
        listCheckItemLoaderStatus : listCheckItemLoaderStatus
    }
}
export const onSubmitTemplateCreate = (addTemplateForm, url, props) => {
    return (dispatch) => {
        let createTemplateValidationStatus = true;
        let addTemplateLoaderStatus = false;
        Object.keys(addTemplateForm).map(inputField => {
            if(addTemplateForm[inputField].value === ''){
                createTemplateValidationStatus = false;
            }
        })
        dispatch(postOnSubmitTemplateCreate(addTemplateLoaderStatus))
        if(createTemplateValidationStatus){
            addTemplateLoaderStatus = true;
            let reqUrl = "/businesses/"+localStorage.getItem('business_id')+"/templates";
            let reqParams = {};
            reqParams['template_name'] = addTemplateForm['template_name'].value;
            reqParams['template_description'] = addTemplateForm['template_description'].value;
            reqParams['template_status'] = true;
            dispatch(postOnSubmitTemplateCreate(addTemplateLoaderStatus))
            return axios.post(reqUrl, reqParams)
            .then(response => {
                addTemplateLoaderStatus = false;
                toast.success("Template Created Successfully", 
                    {
                        containerId: 'toastMsg',
                        onClose : () => props.history.push(url)
                });
                dispatch(postOnSubmitTemplateCreate(addTemplateLoaderStatus))
            })
            .catch(error => {
                addTemplateLoaderStatus = false;
                dispatch(postOnSubmitTemplateCreate(addTemplateLoaderStatus))
            })
        }
    }
}
export const postOnSubmitTemplateCreate = (addTemplateLoaderStatus) => {
    return{
        type : POST_ON_SUBMIT_TEMPLATE_CREATE,
        addTemplateLoaderStatus : addTemplateLoaderStatus
    }
}
export const updateTemplate = (templateInfo, templateInformations) => {
    return (dispatch) => {
        let reqUrl = "/businesses/"+localStorage.getItem('business_id')+"/templates";
        let reqParams = {};
        reqParams['template_id'] = templateInfo.template_id;
        reqParams['template_name'] = templateInfo.template_name;
        reqParams['template_description'] = templateInfo.template_description;
        if(templateInfo.status === 'inactive'){
            reqParams['template_status'] = false;
        }else{
            reqParams['template_status'] = true;
        }
        
        return axios.put(reqUrl,reqParams)
        
        .then(response => {
            // console.log(reqParams)
            let getTemplateInfoIndex = templateInformations.findIndex(d => {
                return d.template_id == templateInfo.template_id;
            });
            templateInformations[getTemplateInfoIndex].template_description= response.data.data.template_description;
            templateInformations[getTemplateInfoIndex].template_name= response.data.data.template_name;
            if(response.data.data.template_status === true){
                // console.log(response.data.data.template_status)
                templateInformations[getTemplateInfoIndex].status = "active";
            }
            else{
                // console.log(response.data.data.template_status)
                templateInformations[getTemplateInfoIndex].status = "inactive";
            }
            toast.success("Template Updated Successfully", {containerId: 'toastMsg', 
             },
            //  console.log("toast recived")
            //  window.location.href = "/businessportal/" + localStorage.getItem('business_id') + "/template"
            );
            
            dispatch(postUpdateTemplate(templateInformations))
        })
        .catch(error => {
            toast.error(error.response.data.message, {containerId: 'toastMsg'});
            dispatch(postUpdateTemplate(templateInformations))
        })
    }
}
export const postUpdateTemplate = (templateInformations) => {
    return{
        type : POST_UPDATE_TEMPLATE,
        templateInformations : templateInformations
    }
}
export const getTemplates = () => {
    return (dispatch) => {
        let getTemplateLoaderStatus = true;
        let templateInformations = [];
        let templateInfo = {};
        let reqURL = "/businesses/"+localStorage.getItem('business_id')+"/templates";
        dispatch(postGetTemplates(templateInformations, getTemplateLoaderStatus))
        return axios.get(reqURL)
        .then(response => {
            getTemplateLoaderStatus = false;
            for(let i = 0; i < response.data.data.length; i++){
                templateInfo = {};
                templateInfo['template_name'] = response.data.data[i].template_name;
                templateInfo['template_description'] = response.data.data[i].template_description;
                templateInfo['template_id'] = response.data.data[i]._id;
                if(response.data.data[i].template_status){
                    templateInfo['status'] = 'active';
                }else{
                    templateInfo['status'] = 'inactive';
                }
                templateInformations.push(templateInfo);
            }
            dispatch(postGetTemplates(templateInformations, getTemplateLoaderStatus))
        })
        .catch(error => {
            getTemplateLoaderStatus = false;
            dispatch(postGetTemplates(templateInformations, getTemplateLoaderStatus))
        })
    }
}
export const postGetTemplates = (templateInformations, getTemplateLoaderStatus) => {
    return{
        type : POST_GET_TEMPLATES,
        templateInformations : templateInformations,
        getTemplateLoaderStatus : getTemplateLoaderStatus
    }
}
export const handleOpenEditChecklistItemDialog = (checklistItem) => {
    return (dispatch) => {
        let editDialogOpenLoaderStatus = true;
        let checkItemDetails = {};
        let activeCheckListItemForEditId = checklistItem.id;
        let reqURL = "/businesses/"+localStorage.getItem('business_id')+"/templates/"+localStorage.getItem('template_id')+"/checks/"+checklistItem.id;
        dispatch(postHandleOpenEditChecklistItemDialog(editDialogOpenLoaderStatus, checkItemDetails,activeCheckListItemForEditId))
        return axios.get(reqURL)
        .then(response => {
            editDialogOpenLoaderStatus = false;
            checkItemDetails = response.data.data;
            dispatch(postHandleOpenEditChecklistItemDialog(editDialogOpenLoaderStatus, checkItemDetails, activeCheckListItemForEditId))
        })
        .catch(error => {
            editDialogOpenLoaderStatus = false;
            dispatch(postHandleOpenEditChecklistItemDialog(editDialogOpenLoaderStatus, checkItemDetails, activeCheckListItemForEditId))
        })
    }
}

export const postHandleOpenEditChecklistItemDialog = (editDialogOpenLoaderStatus, checkItemDetails, activeCheckListItemForEditId) => {
    return{
        type : POST_HANDLE_OPEN_EDIT_CHECKLIST_ITEM_DIALOG,
        editDialogOpenLoaderStatus : editDialogOpenLoaderStatus,
        checkItemDetails : checkItemDetails,
        activeCheckListItemForEditId : activeCheckListItemForEditId
    }
}

export const handleChecklistitemDialogCloseStatus = () => {
    return (dispatch) => {
        return dispatch(postHandleChecklistitemDialogCloseStatus())
    }
}

export const postHandleChecklistitemDialogCloseStatus = () => {
    return{
        type : POST_HANDLE_CHECK_LIST_ITEM_DIALOG_CLOSE_STATUS
    }
}

export const handleAddChecklistitemTextFieldChange = (fieldId, event) => {
    return (dispatch) => {
        let textFieldValue = event.target.value;
        let checkItemImageBase64 = '';
        if(fieldId === 'checklist_item_image'){
            textFieldValue = removeFakePath(event.target.value);
            if(event.target.files !== null){
                convertToBase64(event.target.files[0]).then(data =>{
                    checkItemImageBase64 = data;
                    if((((new Blob([checkItemImageBase64]).size)/1024)/1024) > 1.5){
                        textFieldValue = '';
                        toast.error("File size should be less than 1 MB", {containerId: 'toastMsg'});
                    }
                    dispatch(postHandleAddChecklistitemTextFieldChange(checkItemImageBase64, fieldId, textFieldValue))
                });
            }
        }
        return dispatch(postHandleAddChecklistitemTextFieldChange(checkItemImageBase64, fieldId, textFieldValue))
    }
}

export const postHandleAddChecklistitemTextFieldChange = (checkItemImageBase64, fieldId, textFieldValue) => {
    return{
        type : POST_HANDLE_ADD_CHECKLIST_ITEM_TEXTFIELD_CHANGE,
        fieldId : fieldId,
        data : textFieldValue,
        checkItemImageBase64 : checkItemImageBase64
    }
}

export const initializeAddChecklistItemForm = () => {
    return (dispatch) => {
        return dispatch(postInitializeAddChecklistItemForm())
    }
}

export const postInitializeAddChecklistItemForm = () => {
    return{
        type : POST_INITIALIZE_ADD_CHECKLIST_ITEM_FORM
    }
}

export const handleAddTemplateTextFields = (formFieldId, event) => {
    return (dispatch) => {
        return dispatch(postHandleAddTemplateTextFields(formFieldId, event))
    }
}

export const postHandleAddTemplateTextFields = (formFieldId, event) => {
    return{
        type : POST_HANDLE_ADD_TEMPLATE_TEXTFIELDS,
        formFieldId : formFieldId,
        data : event.target.value
    }
}

export const getVehicles = () => {
    return (dispatch) => {
        let getVehicleInformationLoaderStatus = true;
        let reqURL = "/businesses/"+localStorage.getItem('business_id')+"/checklistlicensedvehicles";
        let vehicleInformations = [];
        let vehicleInfo = {};
        dispatch(postGetVehicles(vehicleInformations, getVehicleInformationLoaderStatus))
        return axios.get(reqURL)
        .then(response => {
            getVehicleInformationLoaderStatus = false;
            for(let i = 0; i < response.data.data.length; i++){
                vehicleInfo = {};
                vehicleInfo['vehicle_plate'] = response.data.data[i].vehicle_plate;
                vehicleInfo['vehicle_state'] = response.data.data[i].vehicle_state;
                vehicleInfo['vehicle_id'] = response.data.data[i].vehicle_id;
                vehicleInformations.push(vehicleInfo);
            }
            dispatch(postGetVehicles(vehicleInformations, getVehicleInformationLoaderStatus))
        })
        .catch(err => {
            getVehicleInformationLoaderStatus = false;
            dispatch(postGetVehicles(vehicleInformations, getVehicleInformationLoaderStatus))
        })
        
    }
}

export const postGetVehicles = (vehicleInformations, getVehicleInformationLoaderStatus) => {
    return{
        type : POST_GET_VEHICLES,
        vehicleInformations : vehicleInformations,
        getVehicleInformationLoaderStatus : getVehicleInformationLoaderStatus
    }
}