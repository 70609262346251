import React from 'react'
import axios from '../axios';
import { Redirect } from 'react-router-dom'
import {
    MANAGE_CONFIRMATION_STATUS,
    CONFIRMATION_TEXTFIELD_CHANGE,
    ENABLE_DISABLE_CONFIRM,
} from '../actions/ActionType';
import { toast } from 'react-toastify';

/**************************************** LOGIN *********************************************************** */
export const confirm = (confirmControls, confirmtoken) => {
    let confirmStatus = true;
    let showLoaderStatus = true;
    return (dispatch) => {
        let email = confirmControls.email.value;
        dispatch(setConfirmStatus(confirmStatus, showLoaderStatus));
        let confirmationReq = {};
        confirmationReq['email'] = confirmControls.email.value;
        confirmationReq['token'] = confirmtoken;
        return axios.post('/business/confirmation', confirmationReq)
            .then(response => {
                confirmStatus = true;

                toast.success("Verification successful. Redirecting to login page...", {
                    containerId: 'toastMsg',
                    onClose :  () => window.location.href = "/"
                });
                localStorage.setItem('token', response.headers['x-token']);
                dispatch(setConfirmStatus(confirmStatus, showLoaderStatus))
            })
            .catch(error => {
                confirmStatus = false;
                showLoaderStatus = false;
                toast.error(error.response.data.message, {containerId: 'toastMsg'})
                dispatch(setConfirmStatus(confirmStatus, showLoaderStatus))
            })
            
    }
    
}
export const setConfirmStatus = (confirmStatus, showLoaderStatus) => {
    return {
        type: MANAGE_CONFIRMATION_STATUS,
        data: confirmStatus,
        showLoaderStatus : showLoaderStatus
    }
}
/****************************************** ENDS ****************************** */

/********************************** DISABLE ENABLE SIGIN BUTTON ON FIELD VALUES ***************************** */
export const handleTextFieldChange = (textField, event, confirmTextField) => {

    return (dispatch) => {
        dispatch(setTextFieldData(textField, event));

        if (confirmTextField.email.value == '') {
            dispatch(setTextFieldData(textField, event))
            dispatch(enableDisableConfirm(true));
        }
        else {
            if (confirmTextField.email.value != "" && (confirmTextField.email.value).match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
                dispatch(setTextFieldData(textField, event))
                dispatch(enableDisableConfirm(false))
            }
            else {
                dispatch(setTextFieldData(textField, event))
                dispatch(enableDisableConfirm(true))
            }

        }

    }
}
/****************************************** ENDS *************************************** */


export const setTextFieldData = (textField, event) => {
    return {
        type: CONFIRMATION_TEXTFIELD_CHANGE,
        textFieldId: textField,
        textFieldData: event.target.value
    }
}



export const enableDisableConfirm = (value) => {
    return {
        type: ENABLE_DISABLE_CONFIRM,
        data: value
    }
}
