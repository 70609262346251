import {
    DATA_LOAD_SPINNER, DELETE_DEPOT,
    EDIT_DEPOTS,
    LOAD_DEPOTS,
    SET_DEPOT_INPUT_FIELD,
    SET_EMPTY_DEPOT_FORM_FIELDS
} from '../actions/ActionType';

const initialState = {
    depotFormFields: {
        name: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                label: 'Name'
            },
            value: '',
            errorStatus: false,
            errorMessage: "Enter Depot Name",
            maxLength: 100
        },
        "state": {
            elementType: 'select',
            elementConfig: {
                type: 'text',
                label: 'State'
            },
            value: '',
            errorStatus: false,
            errorMessage: "Enter State",
            menuItems: {
                'select_state': {
                    name: "Select State",
                    value: ''
                },
                'nsw': {
                    name: "NSW",
                    value: "NSW"
                },
                'qld': {
                    name: "QLD",
                    value: "QLD"
                },
                'sa': {
                    name: "SA",
                    value: "SA"
                },
                'tas': {
                    name: "TAS",
                    value: "TAS"
                },
                'vic': {
                    name: "VIC",
                    value: "VIC"
                },
                'act': {
                    name: 'ACT',
                    value: 'ACT'
                },
                'nt': {
                    name: 'NT',
                    value: 'NT'
                },
                'wa': {
                    name: "WA",
                    value: "WA"
                },
                'ashmore island': {
                    name: "ASHMORE ISLAND",
                    value: "ASHMORE ISLAND"
                },
                'cartier island': {
                    name: "CARTIER ISLAND",
                    value: "CARTIER ISLAND"
                },
                'christmas island': {
                    name: 'CHRISTMAS ISLAND',
                    value: 'CHRISTMAS ISLAND'
                },
                'cocos island': {
                    name: 'COCOS ISLAND',
                    value: 'COCOS ISLAND'
                },
                'coral sea island': {
                    name: 'CORAL SEA ISLAND',
                    value: 'CORAL SEA ISLAND'
                },
                'heard island': {
                    name: 'HEARD ISLAND',
                    value: 'HEARD ISLAND'
                },
                'mcdonald island': {
                    name: 'MCDONALD ISLAND',
                    value: 'MCDONALD ISLAND'
                },
                'oth': {
                    name: "OTH",
                    value: "OTH"
                }
            },
            disabledStatus: false
        },
        region: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                label: 'Region'
            },
            value: '',
            errorStatus: false,
            errorMessage: "Enter Region of the Depot",
            maxLength: 100
        },
        suburb: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                label: 'Suburb'
            },
            value: '',
            errorStatus: false,
            errorMessage: "Enter Suburb of the Depot",
            maxLength: 100
        },
        address: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                label: 'Address'
            },
            value: '',
            errorStatus: false,
            errorMessage: "Enter Address of the Depot",
            maxLength: 100
        },
        geocode: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                label: 'Geo Coordinates'
            },
            value: '',
            errorStatus: false,
            errorMessage: "Enter Geo Coordinates",
            maxLength: 60
        },
        manager_id: {
            elementType: 'select',
            elementConfig: {
                type: 'text',
                label: 'Manager'
            },
            value: '',
            errorStatus: false,
            errorMessage: "Select Manager",
            menuItems: []

        }
    },
    depotSearchColumns: [
        {title: 'Name', field: 'name'},
        {title: 'Manager', field: 'manager[0].user_first_name'},
        {title: 'State', field: 'state'},
        {title: 'Region', field: 'region'},
        {title: 'Suburb', field: 'suburb'},
        {title: 'Address', field: 'address'},
        {title: 'Geo Code', field: 'geocode'},
    ],
    depots: [],
    showDataLoadSpinnerStatus: false,
    showDepotEditDialog: false,
    editedDepotId: ""
}

//Handle SET_DEFAULT_VALUE_TO_ADD_BUSINESS_USER Reducer
const setDefaultFieldsToNewDepotForm = (state, action) => {
    let local = {...state};
    Object.keys(local.depotFormFields).map((inputField) => {
        local.depotFormFields[inputField].value = '';
        local.depotFormFields[inputField].errorStatus = false;
    })
    return local;
}

const setDepotFormField = (state, action) => {
    let local = {...state};
    local.depotFormFields[action.inputFiledId].value = action.data;
    local.depotFormFields[action.inputFiledId].errorStatus = local.depotFormFields[action.inputFiledId].value === '';
    return local;
}

const setDepotData = (state, action) => {
    let local = {...state};
    local.depots = action.depots;
    local.showDataLoadSpinnerStatus = action.showDataLoadSpinnerStatus;
    return local;
}

const setDepotEdit = (state, action) => {
    let local = {...state};
    local.showDepotEditDialog = action.showDepotEditDialog;
    local.depotFormFields["name"].value = action.depot.name;
    local.depotFormFields["manager_id"].value = action.depot.manager_id;
    local.depotFormFields["geocode"].value = action.depot.geocode;
    local.depotFormFields["suburb"].value = action.depot.suburb;
    local.depotFormFields["address"].value = action.depot.address;
    local.depotFormFields["region"].value = action.depot.region;
    local.depotFormFields["state"].value = action.depot.state;
    local.showDataLoadSpinnerStatus = !!action.showDepotEditDialog;
    local.editedDepotId = action.depot._id;
    return local;
}

const setSpinnerStatus = (state, action) => {
    return {...state, showDataLoadSpinnerStatus: action.showDataLoadSpinnerStatus}
}

const reducerDepots = (state = initialState, action) => {
    let localState = Object.assign({}, state);
    switch (action.type) {
        case SET_EMPTY_DEPOT_FORM_FIELDS:
            return setDefaultFieldsToNewDepotForm(localState, action);
        case SET_DEPOT_INPUT_FIELD:
            return setDepotFormField(localState, action);
        case LOAD_DEPOTS:
            return setDepotData(localState, action);
        case EDIT_DEPOTS:
            return setDepotEdit(localState, action);
        case DATA_LOAD_SPINNER:
            return setSpinnerStatus(localState, action);
        default:
            return state;
    }
}


export default reducerDepots;
