import React, { Component } from 'react';
import { connect } from 'react-redux';

import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import FormGroup from '@material-ui/core/FormGroup';
import Button from '@material-ui/core/Button';
import { withRouter } from "react-router-dom";

import Input from "../../components/UI/Input/Input";
import CircularProgress from '@material-ui/core/CircularProgress';
import {
    handleChangePasswordInputHandler,
    changeProfilePassword,
    initializechangePasswordForm
} from "../../actions/Profile";
class ChangePassword extends Component{
    componentDidMount(){
        this.props.initializechangePasswordForm()
    }
    render(){
        let{
            changePasswordForm,
            changePasswordLoaderStatus
        } = this.props.profileState;
        let{
            menuItems
        } = this.props.sidebarState;
        const formElementArray = [];
        for(let key in changePasswordForm){
            formElementArray.push({
                id : key,
                config : changePasswordForm[key]
            })
        }
        let form = (
            <FormGroup row>
                <Grid container spacing={24}>
                    {
                        formElementArray.map(formElement => {
                            return(
                                <Grid item xs={12} sm = {12} key = {formElement.id}>
                                    <Input 
                                        key = {formElement.id}
                                        name = {formElement.config.elementConfig.label}
                                        elementType={formElement.config.elementType}
                                        elementConfig={formElement.config.elementConfig}
                                        value={formElement.config.value}
                                        menuItems = {formElement.config.menuItems}
                                        error = {formElement.config.errorStatus}
                                        helperText = {formElement.config.errorMessage}
                                        changed={(event) => this.props.handleChangePasswordInputHandler(formElement.id, event)}
                                    />
                                </Grid>
                            )
                        })
                    }
                </Grid>
            </FormGroup>
        )
        return(
            <div>
                <Grid container spacing={24}>
                    <Grid item xs={1} sm = {3}>
                    </Grid>
                    <Grid item xs={11} sm = {6}>
                    <Card className = "">
                        <CardHeader
                            title="Change Password"
                        />
                        <CardContent>
                            <FormGroup row style = {{paddingLeft : "3rem", paddingRight : "1rem"}}>
                                <Grid container spacing={24}>
                                    {form}
                                </Grid>
                                <Grid 
                                    container 
                                    spacing={24} 
                                    className = "pt-2"
                                    direction="row" 
                                    justify="flex-end"
                                    alignItems="center"
                                >
                                    <Grid item>
                                    {
                                        changePasswordLoaderStatus ? <CircularProgress color = "primary" /> :
                                        <div>
                                            <Button
                                                variant="contained" 
                                                color="primary" 
                                                disabled={false}
                                                className = "mr-2 mt-2"
                                                
                                                onClick = {() => this.props.changeProfilePassword(changePasswordForm,this.props, menuItems['my_profile'].urlpattern)}
                                            >
                                                Change Password
                                            </Button>
                                            <Button
                                                variant="contained" 
                                                color="default" 
                                                disabled={false}
                                                className = "mt-2"
                                                onClick = {() => this.props.history.push(menuItems['my_profile'].urlpattern)}
                                            >
                                                Cancel
                                            </Button>
                                        </div>

                                    }
                                    

                                    </Grid>
                                </Grid>
                            </FormGroup>
                        </CardContent>
                        <CardActions>
                            
                        </CardActions>
                    </Card>
                    </Grid>
                    <Grid item xs={1} sm = {3}>
                    </Grid>
                </Grid>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return{
        profileState : state.profile,
        sidebarState : state.sidebarState
    }
}

const mapDispatchToProps = dispatch => {
    return{
        initializechangePasswordForm : () => {
            return dispatch(initializechangePasswordForm())
        },
        handleChangePasswordInputHandler : (inputFieldId, event) => {
            return dispatch(handleChangePasswordInputHandler(inputFieldId, event))
        },
        changeProfilePassword : (changePasswordForm, props, url) => {
            return dispatch(changeProfilePassword(changePasswordForm, props, url))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ChangePassword))