import {
    SET_FILE_SELECTED,
    SUBMIT_AFM_INFO,
    SET_DEFAULT_STATE_AFM
  } from "../actions/ActionType";

  //AFM Stat
  const initialState = {
    uploadAFMForm : {
        abn : {
            elementType : 'input',
            elementConfig : {
                type : 'text',
                label : 'Enter ABN'
            },
            value : '',
            errorStatus : false,
            errorMessage : "ABN required",
            maxLength:11
        },
        driver_type : {
            elementType : 'select',
            elementConfig : {
                type : 'number',
                label : 'Select Driver Type'
            },
            value : '',
            errorStatus : false,
            errorMessage : "Select Driver Type",
            menuItems : {
                'select_type' : {
                    name : "Select Driver Type",
                    value : ''
                },
                'driver_type' : {
                    name : "Solo",
                    value : "solo"
                },
                'vehicle_category' : {
                name : "Two UP",
                value : "two_up"
            }
            }
        },
        vehicle_category : {
        elementType : 'select',
        elementConfig : {
            type : 'number',
            label : 'Select Vehicle Category'
        },
        value : '',
        errorStatus : false,
        errorMessage : "Select Vehicle Category",
        menuItems : {
            'select_type' : {
                name : "Select Vehicle Category",
                value : ''
            },
            'driver_type' : {
                name : "Heavy Vehicle",
                value : "heavy_vehicle"
            },
            'vehicle_category' : {
                name : "Bus",
                value : "bus"
            }
        }
        },
        upload_afm : {
            elementType : 'upload_json',
            elementConfig : {
                type : 'text',
                label : 'Upload AFM Json'
            },
            value : '',
            errorStatus : false,
            errorMessage : "Upload AFM Json"
        },
    },
  afmJSON : {},
  afmLoaderStatus : false
  };
  
  //SET_DEFAULT_STATE_AFM Reducer
  const setDefaultStateAFM = (state, action) => {
    Object.keys(state.uploadAFMForm).map((formField) => {
        state.uploadAFMForm[formField].value = '';
        state.uploadAFMForm[formField].errorStatus = false;
    })
    return state;
  }

  //SUBMIT_AFM_INFO Reducer
  const submitAFMInfo = (state, action) => {
    state.afmLoaderStatus = action.afmLoaderStatus;
    state.uploadAFMForm = action.uploadAFMForm;
    return state;
  }

  //SET_FILE_SELECTED Reducer
  const setFileSelected = (state, action) => {
    if(action.data === ''){
        state.uploadAFMForm[action.formElementId].errorStatus = true;
    }else{
        state.uploadAFMForm[action.formElementId].errorStatus = false;
    }
    if(action.formElementId === 'upload_afm'){
        state.afmJSON = action.afmJSON;
    }
    
    state.uploadAFMForm[action.formElementId].value = action.data;
    return state;
  }

  const AFM = (state = initialState, action) => {
    let localState = Object.assign({}, state);
    switch (action.type) {
        case SET_DEFAULT_STATE_AFM : return setDefaultStateAFM(localState, action);
        case SUBMIT_AFM_INFO : return submitAFMInfo(localState, action);
        case SET_FILE_SELECTED : return setFileSelected(localState, action);
      default:
        return state;
    }
  };
  
  export default AFM;