import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import FormGroup from "@material-ui/core/FormGroup";
import Button from "@material-ui/core/Button";
import { connect } from "react-redux";
import Typography from '@material-ui/core/Typography';

import {
  onSubmit,
  getContractItems,
  getContractId,
  handleitemsCheckBoxChange
} from "../../actions/Contracts";
import Input from "../../components/UI/Input/Input";
import CircularProgress from '@material-ui/core/CircularProgress';

class ContractSetup extends Component {
  componentDidMount() {
    this.props.getContractId()
    this.props.getContractItems()
  }
  render() {
    let {
      ContractSetupLoaderStatus,
      showLoaderStatus,
      itemsCheckbox,
      contractId
    } = this.props.ContractSetup;

    const itemElementArray = [];

    const contractIddata = contractId;
    
    for (let key in itemsCheckbox) {
      itemElementArray.push({
        id: key,
        config: itemsCheckbox[key]
      })
    }

    let ItemCheckBoxForm = (
      <FormGroup row className="mt-4">
        <Grid container spacing={24}>
          {
            itemElementArray.map(formElement => {
              if (formElement.id === 'select_all') {
                return (
                  <Grid item xs={12} sm={12} key={formElement.id}>
                    <Input
                      key={formElement.id}
                      name={formElement.config.elementConfig.label}
                      elementType={formElement.config.elementType}
                      elementConfig={formElement.config.elementConfig}
                      value={formElement.config.value}
                      menuItems={formElement.config.menuItems}
                      error={formElement.config.errorStatus}
                      helperText={formElement.config.errorMessage}
                      changed={() => this.props.handleitemsCheckBoxChange(formElement.id)}
                    />
                  </Grid>
                )
              } else {
                return (
                  <Grid item xs={4} sm={3} key={formElement.id}>
                    <Input
                      key={formElement.id}
                      name={formElement.config.elementConfig.label}
                      elementType={formElement.config.elementType}
                      elementConfig={formElement.config.elementConfig}
                      value={formElement.config.value}
                      menuItems={formElement.config.menuItems}
                      error={formElement.config.errorStatus}
                      helperText={formElement.config.errorMessage}
                      changed={(event) => this.props.handleitemsCheckBoxChange(formElement.id)}
                      maxLength={formElement.config.maxLength}
                    />
                  </Grid>
                )
              }

            })
          }
        </Grid>
        <Grid container spacing={24} className="pt-2">
          <Grid item xs={8}>
          </Grid>
          <Grid item xs={4} className="d-flex justify-content-end">
            {
              showLoaderStatus ? <CircularProgress color="primary" /> :
                <Button
                  variant="contained"
                  color="primary"
                  className="mt-4"
                  onClick={() => { this.props.onSubmit(this.props.ContractSetup, this.props) }}>
                  SAVE
                  </Button>
            }
          </Grid>
        </Grid>
      </FormGroup>
    )

    return (
      <div className="container">
        <Card className="pl-3 pr-3 pt-3">
          <div>
            <Grid container spacing={24} className="pt-2">
              <Grid item xs={12} sm={10}>
                <CardHeader title="Contract Setup" />
              </Grid>
            </Grid>
          </div>
          {
            ContractSetupLoaderStatus ?
              <div style={{ height: "20rem" }} className="d-flex justify-content-center align-items-center">
                <CircularProgress color="primary" />
              </div> :
              <CardContent>
              <span style={{ fontSize: 16 }}>Contract Id: <input style={{ marginLeft: 10, textAlign: "center" }} value={contractIddata} disabled></input></span>
              <Typography variant="h6" style={{ paddingTop: 30 }} gutterBottom>
                Items
              </Typography>
              {ItemCheckBoxForm}
              {/* {form} */}
            </CardContent>
          }
          
        </Card>
      </div>
    )
  }
}


const mapStateToProps = state => {
  return {
    ContractSetup: state.ContractSetup
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onSubmit: (ContractSetupInfo, props) => {
      return dispatch(onSubmit(ContractSetupInfo, props))
    },
    handleitemsCheckBoxChange: (formElementId) => {
      return dispatch(handleitemsCheckBoxChange(formElementId))
    },
    getContractItems: () => {
      return dispatch(getContractItems())
    },
    getContractId: () => {
      return dispatch(getContractId())
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ContractSetup);
