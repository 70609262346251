import { combineReducers } from 'redux';
import Vehicle from './vehicle';
import Business from './Business';
import Distributor from './Distributor';
import Login from './Login';
// import DistributorSelect from './DistributorSelect';
import Driver from './Driver';
import PotentialNonCompliance from './PotentialNonCompliance';
import FatigueRule from './FatigueRule';
import EntryTypes from "./EntryTypes";
import BusinessUsers from "./BusinessUsers";
import AxelConfig from "./AxelConfig";
import MassCertificate from "./MassCertificate";
import AccessLevel from './AccessLevel';
import VehicleTypes from './vehicleTypes';
import MeasurementTypes from './MeasurementType';
import MassVariableTypes from './MassVariableType';
import LoadType from './LoadType';
import MassEvent from './MassEvents';
import AFM from './AFM';
import BFM from './BFM';
import SFM from './SFM';
import ContractSetup from './Contract'
import Confirmation from './confirmation';
import MedicalTreatment from './MedicalTreatment';
import ExternalDriver from './ExternalDriver';
import Sidebar from "./Sidebar";
import Profile from "./Profile";
import ChangePassword from "./ChangePassword";
import Checklist from "./Checklist";
import NHVRCompliance from "./NHVRCompliance";
import ExternalSystems from "./ExternalSystems";
import SGAdminUsers from "./SGAdminUsers";
import Maillogs from "./Maillogs";

import { partnerReducer } from './Partner';
import { partnerUserReducer } from './PartnerUser';
import Depot from "./Depot";

const reducer = combineReducers({
  business : Business,
  depots: Depot,
  vehicle : Vehicle,
  distributor : Distributor,
  login : Login,
  changePassword : ChangePassword,
  driver : Driver,
  fatigueRule : FatigueRule,
  entryTypes: EntryTypes,
  businessUsers : BusinessUsers,
  axelConfig: AxelConfig,
  massCertificate: MassCertificate,
  accessLevel: AccessLevel,
  VehicleTypes: VehicleTypes,
  MeasurementTypes: MeasurementTypes,
  MassVariableTypes: MassVariableTypes,
  massEvents : MassEvent,
  loadTypes : LoadType,
  AFM : AFM,
  BFM : BFM,
  SFM : SFM,
  ContractSetup: ContractSetup,
  confirm : Confirmation,
  medicalTreatment : MedicalTreatment,
  externalDriver : ExternalDriver,
  sidebarState : Sidebar,
  profile : Profile,
  checkList : Checklist,
  nhvrCompliance : NHVRCompliance,
  externalSystems: ExternalSystems,
  sgAdminUsers: SGAdminUsers,
  maillogs: Maillogs,
  potentialNonCompliance:PotentialNonCompliance,
  partners: partnerReducer,
  partnerUser: partnerUserReducer
})

export default reducer