import {
    SET_SFM_FILE_SELECTED,
    SUBMIT_SFM_INFO,
    SET_DEFAULT_STATE_SFM
  } from "../actions/ActionType";
  const initialState = {
    uploadSFMForm : {
      driver_type : {
        elementType : 'select',
        elementConfig : {
            type : 'number',
            label : 'Select Driver Type'
        },
        value : '',
        errorStatus : false,
        errorMessage : "Select Driver Type",
        menuItems : {
            'select_type' : {
                name : "Select Driver Type",
                value : ''
            },
            'driver_type' : {
                name : "Solo",
                value : "solo"
            },
            'vehicle_category' : {
              name : "Two UP",
              value : "two_up"
          }
        }
    },
    vehicle_category : {
      elementType : 'select',
      elementConfig : {
          type : 'number',
          label : 'Select Vehicle Category'
      },
      value : '',
      errorStatus : false,
      errorMessage : "Select Vehicle Category",
      menuItems : {
          'select_type' : {
              name : "Select Vehicle Category",
              value : ''
          },
          'driver_type' : {
              name : "Heavy Vehicle",
              value : "heavy_vehicle"
          },
          'vehicle_category' : {
            name : "Bus",
            value : "bus"
        }
      }
  },
      upload_sfm : {
          elementType : 'upload_json',
          elementConfig : {
              type : 'text',
              label : 'Upload SFM Json'
          },
          value : '',
          errorStatus : false,
          errorMessage : "Upload SFM Json"
      },
  },
  sfmJSON : {},
  sfmLoaderStatus : false
  };
  
  const SFM = (state = initialState, action) => {
    let localState = Object.assign({}, state);
    switch (action.type) {
        case SET_DEFAULT_STATE_SFM :
            Object.keys(localState.uploadSFMForm).map((formField) => {
                localState.uploadSFMForm[formField].value = '';
                localState.uploadSFMForm[formField].errorStatus = false;
            })
            return localState;
      case SUBMIT_SFM_INFO : 
            Object.keys(localState.uploadSFMForm).map((formField) => {
                if(localState.uploadSFMForm[formField].value === ''){
                    localState.uploadSFMForm[formField].errorStatus = true;
                }else{
                    localState.uploadSFMForm[formField].errorStatus = false;
                }
            })
            localState.sfmLoaderStatus = action.sfmLoaderStatus;
            return localState;
        case SET_SFM_FILE_SELECTED : 
            if(action.data === ''){
                localState.uploadSFMForm[action.formElementId].errorStatus = true;
            }else{
                localState.uploadSFMForm[action.formElementId].errorStatus = false;
            }
            if(action.formElementId === 'upload_sfm'){
                localState.sfmJSON = action.sfmJSON;
            }
            
            localState.uploadSFMForm[action.formElementId].value = action.data;
            return localState;
      default:
        return state;
    }
  };
  
  export default SFM;