import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import FormGroup from "@material-ui/core/FormGroup";
import Button from "@material-ui/core/Button";
import Input from "../../components/UI/Input/Input";
import CircularProgress from '@material-ui/core/CircularProgress';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';

import { 
  handleTextField,
  onSubmit,
  initializeMassEventComponent
} from "../../actions/MassEvent";

class AddMassEvent extends Component {
  componentDidMount(){
    this.props.initializeMassEventComponent()
  }
  render() {
    let { 
      massEventForm, 
      showLoaderStatus 
    } = this.props.massEvents;
    const formElementArray = [];
    for(let key in massEventForm){
      formElementArray.push({
        id : key,
        config : massEventForm[key]
      })
    }
    let form = (
      <form>
        {
          formElementArray.map(formElement => (
            <Input 
              key = {formElement.id}
              elementType={formElement.config.elementType}
              elementConfig={formElement.config.elementConfig}
              value={formElement.config.value}
              error = {formElement.config.errorStatus}
              helperText = {formElement.config.errorMessage}
              changed={(event) => this.props.handleTextField(formElement.id, event )}
              touched = {(event) => this.props.handleTextField(formElement.id, event )}
              maxLength={formElement.config.maxLength}
            />
          ))
        }
      </form>
    )
    
    return (
      <div>
              <Grid container spacing={24}>
      <Grid item xs={1} sm={2} />
      <Grid item xs={12} sm={8}>
        <Card className="pl-3 pr-3 pt-3">
          <CardHeader title="Add Mass Event" />
          <CardContent>
            <FormGroup row className="pl-3 pr-1">
              <Grid container spacing={24}>
                <Grid item xs={10} sm={10}>
                  {form}
                </Grid>

                <Grid item xs={2} sm={2}>
                {
                  showLoaderStatus ? <CircularProgress color = "primary" /> :
                  <Button
                    variant="contained"
                    color="primary"
                    className="mt-4"
                    onClick = {() => {this.props.onSubmit(massEventForm, this.props)}}>
                    ADD
                  </Button>
                }
                </Grid>
              </Grid>
            </FormGroup>
          </CardContent>
          <CardActions />
        </Card>
      </Grid>
      <Grid item xs={1} sm={2} />
    </Grid>

      </div>
    );
  }
}

const mapStateToProps = (state) => {
    return {
      massEvents: state.massEvents
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
      onSubmit : (massEventForm, props) => { 
        return dispatch(onSubmit(massEventForm, props))
    },
      handleTextField: (textField, event) => {
        return dispatch(handleTextField(textField, event));
      },
      initializeMassEventComponent : () => {
        return dispatch(initializeMassEventComponent())
      }
    };
}
export default connect(mapStateToProps,mapDispatchToProps)(withRouter(AddMassEvent));